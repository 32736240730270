import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { BsEye, BsFillCaretDownFill, BsFillCaretUpFill, BsFillFileEarmarkTextFill, BsFillGrid3X3GapFill } from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Button } from 'react-bootstrap';

import useHttp from "../../hook/use-http";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";


const OpportunitiesApproved = ({ history }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)


    // this variable will be save the opportunity selected
    const [opportunitySelected, setOpportunitySelected] = useState([])
    // this variable contain the name of the group that we wanna create
    const [nameGroup, setNameGroup] = useState("")
    // this variable will be used to add a description of the group
    const [descriptionGroup, setDescriptionGroup] = useState("")

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [productData, setProductData] = useState([]);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();


    // this variable and function will be used to control the modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // this function will control the data save in opportunitySelected
    const handleSelectOpportunity = (row) => {
        // first check if the row is selected or not
        const opportunity = opportunitySelected.filter(item => {
            return item.cod_solicictud === row.cod_solicictud
        });

        try {
            // if the opportunity is not in the array, then add it 
            if (opportunity?.length === 0 ||
                opportunity === undefined ||
                opportunity === null
            ) {
                setOpportunitySelected((prevState) => [...prevState, row])
            } else {
                // if the opportunity is in the array, then remove it
                setOpportunitySelected((prevState) => [...prevState.filter(item => item.cod_solicictud !== row.cod_solicictud)])
            }
        } catch (error) {
            // show a message error if something goes wrong
            console.log(error)
        }
    }

    // this function will process the data response after call the server method handleCreateGroup
    const handleCreateGroupDataResponse = (response) => {

        if (response.status) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

        } else {
            localStorage.setItem('nameGroupCreated', response?.codigoGrupo)
            history.push("/group-created")
        }

    }
    // this function will be used to create a new group of opportunities
    const handleCreateGroup = () => {
        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            let setOtherFormantOpportunity = opportunitySelected.map(item => {
                return {
                    "codigo": item.cod_solicictud
                }
            });

            request({
                url: `/auth/oportunidad/group/register`,
                method: 'POST',
                headers: myHeaders,
                body: {
                    "oportunidades": setOtherFormantOpportunity,
                    "descripcionGrupo": descriptionGroup
                }
            }, handleCreateGroupDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    // this function will be used to redirect to the product detail page
    const onClickRegisterTable = (code) => {
        history.push(`opportunity-details/${code}`)
    }

    // this function will be process the result of the method getListChannel
    const handleGetListChannelDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here

            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            const result = [];

            // add and extra field to products
            products.map((item, index) => {


                

                item.id = index + 1
                // set the data to the variable request
                item.cod_solicictud = item.codigo
                item.razon_social = item.descripcion
                item.tipo_solicitud = item.tipo
                item.estado_solicitud = item.estado
                item.tipo_canal = item.tipo
                item.observaciones = item.observaciones

                item.entityName = item?.informacionEntidad?.entidad

                let d = new Date(item.fechaAprobacion)
                let dformat = [d.getMonth() + 1,
                d.getDate(),
                d.getFullYear()].join('/') + ' ' +
                    [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');


                item.fechaAprobacion = dformat

                item.fechaDeclaracion = dformat

                item.canal_name = item.canal?.razonSocial

                // entity information
                item.nombreEntidad = item.informacionEntidad?.nombreEntidad
                item.nombreContactoEntidad = item.informacionEntidad?.nombreContactoEntidad
                item.emailEntidad = item.informacionEntidad?.emailEntidad
                item.telefonoEntidad = item.informacionEntidad?.telefonoEntidad
                item.presupuestoEntidad = item.informacionEntidad?.presupuestoEntidad + "$"

                item.descripcion = item.descripcion?.length > 20 ? item.descripcion?.substring(0, 20) + "..." : item.descripcion

                item.change =
                    <button
                        className="btn btn-info" onClick={() => onClickRegisterTable(item.cod_solicictud)}
                        style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}
                    >
                        <div className="icon-eyes" > <BsFillFileEarmarkTextFill />
                        </div>
                    </button>

                item.checkbox = <input type="checkbox" onClick={() => handleSelectOpportunity(item)} />

                result.push(item)
            });

            const res = result.sort((a, b) => {
                const dateA = new Date(a.fechaAprobacion)
                const dateB = new Date(b.fechaAprobacion)
                if (dateA < dateB) return 1
                if (dateA > dateB) return -1
                return 0
            })

            setProductData(res);

        }
    }

    // this function will be used to request the data from the server
    const getListChannel = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            //  url: `/auth/oportunidad/list/estado?estado=PENDIENTE`,
            request({
                url: `/auth/oportunidad/list/estado?estado=APROBADA`,
                method: 'GET',
                headers: myHeaders
            }, handleGetListChannelDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getListChannel();
    }, [])

    const columns = [
        /** 
        {
            dataField: 'checkbox',
            text: `${''}`,
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        */
        {
            dataField: 'entityName',
            text: 'ENTIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {

            dataField: 'canal_name',
            text: 'CANAL',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {
            dataField: 'tipo',
            text: 'TIPO OPORTUNIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'codigo',
            text: 'CODIGO OPORTUNIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {

            dataField: 'fechaAprobacion',
            text: 'APROBACIÓN',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {

            dataField: 'estado',
            text: 'ESTADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {

            dataField: 'change',
            text: 'DETALLES',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        /*
        {

            dataField: 'fechaDeclaracion',
            text: 'FECHA DECLARACIÓN OPORTUNIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {

            dataField: 'createdDate',
            text: 'FECHA DE APROBACIÓN',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {
            dataField: 'nombreEntidad',
            text: 'ENTIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'nombreContactoEntidad',
            text: 'NOMBRE CONTACTO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'emailEntidad',
            text: 'EMAIL',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'telefonoEntidad',
            text: 'TELEFONO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'presupuestoEntidad',
            text: 'PRESUPUESTO ESTIMADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        */
        /*
        {
            dataField: 'change',
            text: 'PRESUPUESTO ESTIMADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        }
        */

    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }

    const { SearchBar } = Search;

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true
    };

    // this function will be used to set a new format the date opportunity created
    const setDateOpportunityCreated = (date = new Date()) => {
        let d = new Date(date)
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        return dformat
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={productData}
                                        selectRow={selectRow}

                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={productData}
                                                selectRow={{ mode: 'checkbox', clickToSelect: true }}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <Row className="mb-2">
                                                            <Col md="12"
                                                                className="d-flex flex-row justify-content-between p-0"
                                                            >
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                                
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>AGRUPAR OPORTUNIDADES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AvForm className="needs-validation" onValidSubmit={(error, values) => handleCreateGroup(error, values)}  >
                        <Row>
                            <Col md="12">



                                {opportunitySelected.length > 0 ?
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Canal</th>
                                                <th scope="col">Oportunidad</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Fecha Creación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(opportunitySelected).map((item, index) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.canal?.razonSocial}</td>
                                                        <td>{item.codigo}</td>
                                                        <td>{item.descripcion}</td>
                                                        <td>{setDateOpportunityCreated(item.createdDate)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <h6
                                        style={{
                                            fontWeight: 'bold',
                                            marginTop: 10,
                                            marginBottom: 20
                                        }}>
                                        No hay oportunidades seleccionadas
                                    </h6>
                                }

                            </Col>
                            <Col xl="12">
                                <div className="mb-3 ">
                                    <Label
                                        className="form-label"
                                        htmlFor="validationCustom01"
                                        style={{ fontWeight: 'bold' }}
                                    >Descripción del Grupo</Label>
                                    <AvField
                                        name="grupo"
                                        type="text"
                                        errorMessage="Ingrese la descripción del grupo"
                                        className="form-control"
                                        id="validationCustom01"
                                        validate={{ required: { value: true }, minLength: { value: 3 } }}

                                        value={descriptionGroup}
                                        onChange={(e) => setDescriptionGroup(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Modal.Footer className="p-0">
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Agrupar
                            </Button>
                        </Modal.Footer>
                    </AvForm>
                </Modal.Body>

            </Modal>
        </React.Fragment>
    )

}

export default OpportunitiesApproved;