import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from 'react-redux'
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from 'react-bootstrap';


//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
    { id: 1, name: "SIGLO 21" },
    { id: 2, name: "TECNOMEGA" },
    { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
    { id: 1, name: "TRADICIONAL" },
    { id: 2, name: "CONNECT" },
]

const RenewalOfTradeAgreements = ({ history }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.DataJsonConsume.data)

    // this will be used to control de component modal  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleRedirectToListWhenTheChannelIsCreated = () => {
        history.push(`/channel-list`)
    }

    /***************************************************************/
    /***************************************************************/
    /*******  declare state variables to create a new channel ******/
    /***************************************************************/
    /***************************************************************/

    // this represent the data company
    const [razonSocial, setRazonSocial] = useState('');
    const [ruc, setRuc] = useState('');
    const [direccion, setDireccion] = useState('');

    // this represent the main contact
    const [nombreContacto, setNombreContacto] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [cargo, setCargo] = useState('');

    // this represent the legal represent 
    const [nombreRepresentante, setNombreRepresentante] = useState('');
    const [celularRepresentante, setCelularRepresentante] = useState('');
    const [emailRepresentante, setEmailRepresentante] = useState('');

    // this represent the type of channel
    const [tipoCanal, setTipoCanal] = useState('TRADICIONAL');

    // this represent the documents
    const [acuerdoComercialDocumento, setAcuerdoComercialDocumento] = useState('https://youtube.com');
    const [rucDocumento, seRucDocumento] = useState('http://cnn.com');

    // this represent the provider
    const [proveedor, setProveedor] = useState('SIGLO 21');

    // this represent the observaciones
    const [observaciones, setObservaciones] = useState('');


    /* Create a function to validate the number phone  */
    const validatePhone = (phone) => {
        const regex = /^[0-9]{0}$/;
        return regex.test(phone);
    }

    /* Create a function to validate the email  */
    const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /* Create a function to validate each input is not empty */
    const validateInput = (input) => {
        return input !== '';
    }

    /* Create a function to validate each input the form */
    const validateForm = () => {
        return validateInput(razonSocial)
            && validateInput(ruc)
            && validateInput(direccion)
            && validateInput(nombreContacto)
            && validateInput(celular)
            && validateEmail(email)
            && validateInput(cargo)
            && validateInput(nombreRepresentante)
            && validateInput(celularRepresentante)
            && validateEmail(emailRepresentante)
            && validateInput(tipoCanal)
            //&& validateInput(acuerdoComercialDocumento)
            //&& validateInput(rucDocumento)
            && validateInput(proveedor);
    }

    const handleSubmit = () => {

        try {

            // this variable will be used to get if the form is valid
            const validate = validateForm();
            console.log(validate);

            // if the form is not valid show a message 
            if (!validate) {
                throw new Error('The form is not valid');
            }

            // In this part we will send the data to the server
            // In this case we will create a new channel

            console.log('The form is valid');

            if (observaciones.trim() === '') {
                setObservaciones('Ok.');
            }

            // In this case we will create a new channel
            // I will be used a dispatch to send the data 

            console.log('proveedor = ', proveedor)
            console.log('tipoCanal = ', tipoCanal)

            const channel = {
                razon_social: razonSocial,
                ruc: ruc,
                direccion: direccion,
                nombre_contacto: nombreContacto,
                celular_contacto: celular,
                email_contacto: email,
                cargo_contacto: cargo,
                nombre_representante: nombreRepresentante,
                celular_representante: celularRepresentante,
                email_representante: emailRepresentante,
                tipo_canal: tipoCanal,
                acuerdo_comercial: acuerdoComercialDocumento,
                ruc_documento: rucDocumento,
                mayorista: proveedor,
                observaciones: observaciones,
                estado: 'INACTIVO'
            }

            dispatch(createDataJson(channel), {
                type: 'CREATE_CHANNEL',
            })

            // this will be used to show the modal
            handleShow();

            // set the state of the form to empty
            /*
            setRazonSocial('');
            setRuc('');
            setDireccion('');
            setNombreContacto('');
            setCelular('');
            setEmail('');
            setCargo('');
            setNombreRepresentante('');
            setCelularRepresentante('');
            setEmailRepresentante('');
            setTipoCanal('TRADICIONAL');
            setAcuerdoComercialDocumento('http://reddit.com');
            seRucDocumento('https://youtube.com');
            setProveedor('SIGLO 21');
            setObservaciones('');
            */

        } catch (error) {
            // show the message error
            console.log(error);
        }

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {/* 
                    <Breadcrumbs title="Crear Canal" />
                    */}
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col xl="12" style={{ width: '40%' }} >
                            <Card style={{ margin: 'auto auto', marginTop: 30 }}>
                                <CardBody>
                                    <div className="d-flex justify-content-center">
                                        <h4 className="card-title my-4">RENOVACIÓN ACUERDOS COMERCIALES </h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-12 d-flex d-flex justify-content-around mt-3">
                                            <div>
                                                CANALES ACTIVOS
                                            </div>
                                            <div style={{ fontWeight: 'bold' }}>
                                                43
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex d-flex justify-content-around mb-5 mt-4">
                                            <div>
                                                CANALES INACTIVOS
                                            </div>
                                            <div style={{ fontWeight: 'bold' }}>
                                                24
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-center">
                                            <button className="btn btn-primary-lexmark mx-2" style={{ width: 100 }}>SI</button>
                                            <button className="btn btn-danger-lexmark mx-2" style={{ width: 100 }}>NO</button>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div>
                        <M show={show} onHide={handleClose}>
                            <M.Header closeButton>
                                <M.Title>REGISTRO EXITOSO </M.Title>
                            </M.Header>
                            <M.Body>Se ha registrado exitosamente el canal <span style={{ fontWeight: 'bold' }}> {razonSocial} </span>  con el contacto <span style={{ fontWeight: 'bold' }}> {nombreContacto} </span>, se ha enviado un correo al email de contacto con la información de inicio de sesión.</M.Body>
                            <M.Footer>
                                < button className="btn btn-primary-lexmark" onClick={handleRedirectToListWhenTheChannelIsCreated}>
                                    CONTINUAR
                                </button>
                            </M.Footer>
                        </M>
                    </div>

                </Container>
            </div>
        </React.Fragment >
    );

}

export default RenewalOfTradeAgreements;
