import * as types from './actionType'
import ListSuppliesJson from './ListSuppliesJson'

const initialState = {
    supplies: ListSuppliesJson,
    backup: ListSuppliesJson,
    elementRemoved: [],
    elementSelected: [],
    isLoading: false,
    error: null
}

export const Supplies = (state = initialState, action) => {

    const { type } = action

    switch (type) {

        case types.GET_ALL_SUPPLIES:
            return {
                ...state,
                supplies: state.supplies
            }

        case types.RESET_SUPPLIES_LIST: {
            return {
                ...state,
                supplies: state.backup
            }
        }

        case types.REMOVE_SUPPLY: {
            const { item } = action.payload

            const saveElementRemoved = state.supplies.filter(
                supply => supply.np !== item.partno && supply.Model !== item.model && supply.Product !== item.descripcion)


            const newSupplies = state.supplies.filter(
                supply => supply.np !== item.partno && supply.Model !== item.model && supply.Product !== item.descripcion)

            return {
                ...state,
                supplies: newSupplies,
                elementRemoved: [
                    ...state.elementRemoved,
                    saveElementRemoved
                ],
                elementSelected: [
                    ...state.elementSelected,
                    saveElementRemoved
                ]
            }
        }

        case types.ADD_SUPPLY_TO_LIST: {
            const { sp } = action.payload
            if (sp.partno !== '' && sp.model !== '' && sp.descripcion !== '' && sp.cantidad !== '') {

                const updateSupplies = state.supplies.push({
                    "np": (sp.partno).toString(),
                    "Model": (sp.model).toString(),
                    "Product": (sp.descripcion).toString(),
                })

                return {
                    ...state,
                    supplies: [
                        ...state.supplies,
                        updateSupplies
                    ],
                }

            }

            return {
                ...state
            }
        }

        default:
            return state;
    }
}