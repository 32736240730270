import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { BsEye, BsFillFileEarmarkTextFill } from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import useHttp from '../../hook/use-http';

import Embed from 'react-embed';


import "./datatables.scss"
import { productsData } from "../../common/data";
import { toast } from "react-toastify";


const BannerList = ({ history }) => {



    // save the product after add and extra fields
    const [bannerData, setBannerData] = useState([]);
    const [amountItemsIntoList, setItemsIntoList] = useState(0);

    // this function will be used to request the server to login the user
    const { loading,  request } = useHttp();

    // this function will be used to redirect to the product detail page
    const onClickRegisterTable = (id) => {
        history.push(`/banner-detail/${id}`)
    }


    // this function will be process the result of the method getListBanner
    const handleGetListBannerDataResponse = (data) => {

        if (data.status === 401) {
            // show a error message here

            if (data.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(data.message, { type: 'error', autoClose: 10000 });

        } else {

            const result = data.map((item, index) => {

                item.index = index + 1
                item.image = <img src={`${process.env.REACT_APP_S3_URL}${item.path}`} alt='banner' className="img-thumbnail w-50-px" />
                item.statusES = item.status ? 'Activo' : 'Inactivo'
                item.change =
                <button
                    className="btn btn-info" onClick={() => onClickRegisterTable(item.id)}
                    style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}
                >
                    <div className="icon-eyes" > <BsFillFileEarmarkTextFill />
                    </div>
                </button>
                return item;
            });

            console.log(result)
            setItemsIntoList(result.length)
            setBannerData(result);

        }
    }

    // this function will be used to request the data from the server
    const getListBanner = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: '/noauth/banner',
                method: 'GET',
                headers: myHeaders,
            }, handleGetListBannerDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        getListBanner();

    }, [])


    const columns = [
        {
            dataField: 'index',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'title',
            text: 'TITULO',
            sort: true,
        }, {
            dataField: 'description',
            text: 'DESCRIPCION',
            sort: true
        }, {
            dataField: 'type',
            text: 'TIPO',
            sort: true
        }, {
            dataField: 'statusES',
            text: 'ESTADO',
            sort: true
        },/* {
            dataField: 'acuerdo_comercial',
            text: 'ACUERDO COMERCIAL',
            sort: true
        }, {
            dataField: 'ruc_documento',
            text: 'RUC DOCUMENTO',
            sort: true
        }, */{
            dataField: 'image',
            text: 'BANNER',
            sort: true
        }, {
            dataField: 'change',
            text: 'DETALLES',
            sort: false
        }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: amountItemsIntoList, // replace later with size(customers),
        custom: true,
    }

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={bannerData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={bannerData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>



                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}

export default BannerList