import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, Search as SE, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';


// import the redux
import { useSelector, useDispatch } from 'react-redux'

// import lib to export to excel 
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";


const OpportunitiesReport = ({ history }) => {

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.GeneralReportsJsonConsume.data)

    // this will be used to set the data in a variable 
    const [reports, setReports] = useState(dataJson)
    const [totalItems, setTotalItems] = useState(0)

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [reportsData, setReportsData] = useState([]);

    /*
    useEffect(() => {
        const result = []
 
        // add and extra field to products
        reports.map((item, index) => {
            item.id = index + 1
            result.push(item)
        });
 
        setReportsData(result);
 
    }, [])
    */

    // this function will be process the result of the method getListChannel
    const handleGetDetailReportDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here

            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            const result = [];

            // add and extra field to products
            products.forEach((item, index) => {

                item.id = index + 1
                // set the data to the variable request
                item.cod_solicictud = item.codigo
                item.razon_social = item.descripcion
                item.tipo_solicitud = item.tipo
                item.estado_solicitud = item.estado
                item.tipo_canal = item.tipo

                let d = new Date(item.createdDate)
                let dformat = [d.getMonth() + 1,
                d.getDate(),
                d.getFullYear()].join('/') + ' ' +
                    [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');

                item.createdDate = dformat

                item.fechaDeclaracion = dformat

                item.canal_name = item.canal?.razonSocial

                // entity information
                item.nombreEntidad = item.informacionEntidad?.nombreEntidad
                item.nombreContactoEntidad = item.informacionEntidad?.nombreContactoEntidad
                item.emailEntidad = item.informacionEntidad?.emailEntidad
                item.telefonoEntidad = item.informacionEntidad?.telefonoEntidad
                item.presupuestoEntidad = item.informacionEntidad?.presupuestoEntidad + "$"


                item.entidad = item.informacionEntidad?.entidad ? item.informacionEntidad?.entidad : ''

                item.email = item.informacionEntidad?.email
                item.contacto = item.informacionEntidad?.nombreContacto
                item.telefono = item.informacionEntidad?.telefono
                item.presupuesto = item.presupuesto + '$'
                item.rejectedBy = ''
                if(item.estado === 'RECHAZADO'){
                    item.rejectedBy = item.modifiedBy
                }

                result.push(item)
            });

            const res = result.sort((a, b) => {
                const dateA = new Date(a.createdDate);
                const dateB = new Date(b.createdDate);
                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                return 0;
            })

            setTotalItems(res.length)
            setReportsData(res);
        }
    }

    // this function will be used to request the data from the server
    const getDetailReport = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            //  url: `/auth/oportunidad/list/estado?estado=PENDIENTE`,
            request({
                url: `/auth/oportunidad/list/estado?estado=PENDIENTE,CONVALIDAR,CADUCADO,GANADA,PERDIDA,APROBADA,RECHAZADO`,
                method: 'GET',
                headers: myHeaders
            }, handleGetDetailReportDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        getDetailReport();

    }, [])

    const { ExportCSVButton } = CSVExport;

    const columns = [

        {
            dataField: 'codigo',
            text: 'CO-OP',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'canal_name',
            text: 'CANAL',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'entidad',
            text: 'ENTIDAD',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'estado',
            text: 'ESTADO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'motivoRechazo',
            text: 'MOTIVO DE RECHAZO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'rejectedBy',
            text: 'RECHAZADO POR',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'tipo',
            text: 'TIPO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'descripcion',
            text: 'DESCRIPTION',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'contacto',
            text: 'CONTACTO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'email',
            text: 'EMAIL',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'telefono',
            text: 'TELEFONO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'presupuesto',
            text: 'PRESUPUESTO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'createdDate',
            text: 'CREADO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: totalItems, // replace later with size(customers),
        custom: true,
    }


    const { SearchBar } = Search;
    const E = SE.SearchBar;

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={reportsData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={reportsData}
                                                search={{
                                                    searchText: '',
                                                }}
                                                exportCSV={{
                                                    fileName: 'reportsDetails.csv',
                                                    blobType: 'text/csv;charset=ansi'
                                                }}
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2 d-flex justify-content-between">
                                                            {/*
                                                            <Col md="12">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type="text"
                                                                            className="filter text-filter form-control"
                                                                            delay="500"
                                                                            value={searchByName}
                                                                            onChange={(e) => filterByNameValue(e.target.value)}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <label htmlFor="">CODIGO DEL PROCESO</label>
                                                                        <input type="text" class="form-control" />

                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <label htmlFor="">FECHA DE PUJA</label>
                                                                        <input type="datetime-local" class="form-control" />

                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                             */}
                                                            <Col className="d-flex justify-content-end" >
                                                                {/* 
                                                                < ReactHtmlTableToExcel
                                                                    id="buttonToExportExcel"
                                                                    className="btn btn-success"
                                                                    table="table-responsive"
                                                                    filename="reportsDetails"
                                                                    sheet="Page 1"
                                                                    buttonText="Export to Excel"
                                                                />
                                                                */}
                                                                <ExportCSVButton
                                                                    {...toolkitProps.csvProps}
                                                                    className="btn btn-success"
                                                                >Export to Excel</ExportCSVButton>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-responsive-custom-lexmark"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        filter={filterFactory()}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/*

                                                        <div className="d-flex justify-content-end" >
                                                            < ReactHtmlTableToExcel
                                                                id="buttonToExportExcel"
                                                                className="btn btn-success"
                                                                table="table-responsive"
                                                                filename="reportsDetails"
                                                                sheet="Page 1"
                                                                buttonText="Export to Excel"
                                                            />
                                                        </div>
                                                         */}

                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}

export default OpportunitiesReport;