
import { channel } from "redux-saga"
import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_DATA_JSON,
  UPDATE_DATA_JSON,
  CHANGE_STATUS_CHANNEL,
  CREATE_DATA_JSON
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  // In this part we include a data example to use in the app
  data: [
    /*
    { "qty": "150", "pn": "50F4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "10", "pn": "58D4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "28", "pn": "56F4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "2", "pn": "76C00K0", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "6", "pn": "58D0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "10", "pn": "56F0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "50", "pn": "50F0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "8", "pn": "50F4H00", "entidad": "UNIVERSIDAD TECNICA DE AMBATO", "codigo": "SIE-UTA-018-2021", "date_limit_question": "12/15/2021 13:00:00", "date_limit_answer": "12/20/2021 13:00:00", "date_limit_qualification": "01/06/2022 20:00:00", "date_puja": "01/07/2022 10:00:00", "canal_ajudicado": "SHUGULI GUACOLLANTE HECTOR RODRIGO", "valor_final_puja": "66490", "estado_proceso": "Por adjudicar", "mayorista": "No es canal", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "am.velastegui@uta.edu.ec" }
    ,
    { "qty": "7", "pn": "40X7743", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "5", "pn": "40X8420", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "10", "pn": "52D0Z00", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "40", "pn": "52D4X00", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
  */

    {
      "solicitud": "SOL129",
      "nombre": "TECNICOS EN MANTENIMIENTO Y ACCESORIOS TECMAN CIA. LTDA.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 18, 2021",
      "ruc_documento": "https://cnn.com",
      "estado_solicitud": "PENDIENTE",
      "tipo_solicitud": "SUMINISTRO",
      "nombre_contacto": "Mira Wilkerson",
      "celular_contacto": "1-378-578-2101",
      "email_contacto": "tellus@protonmail.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "William Willis",
      "celular_representante": "(252) 204-0874",
      "email_representante": "lorem.fringilla@aol.couk",
      "tipo_canal": "TECNOMEGA",
    },
    {
      "solicitud": "SOL002",
      "nombre": "MICROINFORMATICA MICROCOMP CIA. LTDA.",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 11, 2021",
      "ruc_documento": "http://guardian.co.uk",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Driscoll Norman",
      "celular_contacto": "1-266-243-6517",
      "email_contacto": "arcu.aliquam.ultrices@icloud.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Yasir Brown",
      "celular_representante": "1-463-737-6357",
      "email_representante": "odio.phasellus@outlook.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL003",
      "nombre": "COMPUFACIL COMPUTADORAS Y FACILIDADES CIA.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 9, 2021",
      "ruc_documento": "http://cnn.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Boris Stein",
      "celular_contacto": "(400) 287-8552",
      "email_contacto": "dapibus.quam.quis@hotmail.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Virginia Gay",
      "celular_representante": "(543) 778-8597",
      "email_representante": "vulputate@google.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL004",
      "nombre": "OFFICESOLUCIONES CIA. LTDA.",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 19, 2021",
      "ruc_documento": "https://bbc.co.uk",
      "estado_solicitud": "PENDIENTE",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Dominic Mcdowell",
      "celular_contacto": "1-286-427-2677",
      "email_contacto": "neque@yahoo.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Eve Good",
      "celular_representante": "(887) 554-2314",
      "email_representante": "ligula.donec.luctus@hotmail.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL005",
      "nombre": "PAPER COMPUT P-C S.A.",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 26, 2021",
      "ruc_documento": "http://naver.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Gary Morales",
      "celular_contacto": "(930) 619-3638",
      "email_contacto": "risus.odio.auctor@outlook.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Dean Todd",
      "celular_representante": "(780) 450-8588",
      "email_representante": "diam.dictum.sapien@icloud.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL006",
      "nombre": "CYBERBOX CIA. LTDA.",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 8, 2021",
      "ruc_documento": "http://ebay.com",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Elmo Oliver",
      "celular_contacto": "(412) 462-0398",
      "email_contacto": "lobortis.tellus@hotmail.org",
      "cargo_contacto": "Director",
      "nombre_representante": "Clare Bernard",
      "celular_representante": "1-310-866-8126",
      "email_representante": "suspendisse.ac@aol.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL007",
      "nombre": "TELCOMEXPERT EXPERTOS EN TELECOMUNICACIONES S A.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 25, 2021",
      "ruc_documento": "http://wikipedia.org",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Darrel Mccoy",
      "celular_contacto": "(272) 291-1133",
      "email_contacto": "ipsum@hotmail.org",
      "cargo_contacto": "Director",
      "nombre_representante": "Maryam Holden",
      "celular_representante": "1-858-386-9497",
      "email_representante": "ullamcorper.duis.cursus@aol.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL008",
      "nombre": "EQUIPOS Y SERVICIOS DE INGENIERIA ESERDING S.A.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 24, 2021",
      "ruc_documento": "https://walmart.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Garrett Lawrence",
      "celular_contacto": "(332) 444-5978",
      "email_contacto": "interdum@outlook.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Portia Gardner",
      "celular_representante": "1-538-154-9867",
      "email_representante": "sagittis.augue.eu@google.org",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL009",
      "nombre": "DINFORSYSMEGA S.A.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 26, 2021",
      "ruc_documento": "https://google.com",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Carol Kaufman",
      "celular_contacto": "1-437-659-8257",
      "email_contacto": "sagittis.semper@google.ca",
      "cargo_contacto": "Director",
      "nombre_representante": "Veronica Torres",
      "celular_representante": "1-147-698-1046",
      "email_representante": "dolor.sit@aol.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL010",
      "nombre": "DELIVERY SUMINISTROS & SUMINISTROS CIA. LTDA.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 24, 2021",
      "ruc_documento": "https://wikipedia.org",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Emerson Olson",
      "celular_contacto": "(616) 788-9249",
      "email_contacto": "ac@aol.couk",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Noble Villarreal",
      "celular_representante": "1-879-818-7508",
      "email_representante": "fermentum@outlook.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL011",
      "nombre": "MUNDOFFICE C. LTDA.",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 20, 2021",
      "ruc_documento": "http://naver.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Ferris Glass",
      "celular_contacto": "(951) 294-8176",
      "email_contacto": "elit.etiam@protonmail.ca",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Byron Combs",
      "celular_representante": "1-314-992-2452",
      "email_representante": "aenean.euismod.mauris@hotmail.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "solicitud": "SOL012",
      "nombre": "AGILIDER S.A. ",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 22, 2021",
      "ruc_documento": "https://naver.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Desiree Walls",
      "celular_contacto": "(654) 322-1027",
      "email_contacto": "a.enim.suspendisse@outlook.net",
      "cargo_contacto": "Director",
      "nombre_representante": "Kuame Foster",
      "celular_representante": "(945) 547-7292",
      "email_representante": "viverra@protonmail.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL013",
      "nombre": "TELCOMBAS S.A.",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 9, 2021",
      "ruc_documento": "http://reddit.com",
      "estado_solicitud": "PENDIENTE",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Erin Armstrong",
      "celular_contacto": "1-448-740-5240",
      "email_contacto": "dignissim.magna@icloud.edu",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Sydnee Christian",
      "celular_representante": "1-314-277-1561",
      "email_representante": "sodales@yahoo.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL014",
      "nombre": "HELPCOMP",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 18, 2021",
      "ruc_documento": "https://naver.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Tatum Sparks",
      "celular_contacto": "1-453-491-5232",
      "email_contacto": "dui.lectus@hotmail.net",
      "cargo_contacto": "Director",
      "nombre_representante": "Mohammad Hayes",
      "celular_representante": "(681) 775-4205",
      "email_representante": "lobortis@protonmail.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL015",
      "nombre": "SYSCOMP",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 10, 2021",
      "ruc_documento": "http://google.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Barry Oneil",
      "celular_contacto": "1-778-307-7896",
      "email_contacto": "penatibus.et@icloud.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Ria Gibson",
      "celular_representante": "(303) 279-5588",
      "email_representante": "auctor.velit@protonmail.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "solicitud": "SOL016",
      "nombre": "HELPCOMP",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 11, 2021",
      "ruc_documento": "https://pinterest.com",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Perry William",
      "celular_contacto": "(776) 186-3237",
      "email_contacto": "sem@google.com",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Olivia Johnston",
      "celular_representante": "1-813-770-2246",
      "email_representante": "et@icloud.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL017",
      "nombre": "FRINGILLA PC",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 13, 2021",
      "ruc_documento": "http://zoom.us",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Wyatt Mcguire",
      "celular_contacto": "1-851-755-0857",
      "email_contacto": "non.vestibulum@hotmail.org",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Porter Frye",
      "celular_representante": "1-482-633-7664",
      "email_representante": "semper.tellus@yahoo.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "solicitud": "SOL018",
      "nombre": "TCS",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 8, 2021",
      "ruc_documento": "https://whatsapp.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Ayanna Deleon",
      "celular_contacto": "(456) 652-0146",
      "email_contacto": "habitant.morbi@yahoo.com",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Declan Howell",
      "celular_representante": "1-978-367-2536",
      "email_representante": "auctor@icloud.org",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL019",
      "nombre": "CCC COMPUTERS",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 7, 2021",
      "ruc_documento": "http://pinterest.com",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Olivia Pennington",
      "celular_contacto": "1-216-231-5415",
      "email_contacto": "orci.sem@yahoo.couk",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Breanna Delacruz",
      "celular_representante": "1-695-368-8427",
      "email_representante": "nec.orci@protonmail.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL020",
      "nombre": "PROTOSCANA S.A.",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 12, 2021",
      "ruc_documento": "https://guardian.co.uk",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Farrah Hahn",
      "celular_contacto": "(283) 823-4748",
      "email_contacto": "sed@aol.couk",
      "cargo_contacto": "Director",
      "nombre_representante": "Chadwick Oneal",
      "celular_representante": "(108) 454-0116",
      "email_representante": "accumsan.laoreet@icloud.org",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL021",
      "nombre": "SUMEQOF",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 26, 2021",
      "ruc_documento": "http://nytimes.com",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Armando Mejia",
      "celular_contacto": "(915) 168-7614",
      "email_contacto": "fermentum.metus@icloud.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Karyn Frost",
      "celular_representante": "(812) 583-1678",
      "email_representante": "sed.libero@icloud.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL022",
      "nombre": "COMPUSOFT",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 15, 2021",
      "ruc_documento": "http://pinterest.com",
      "estado_solicitud": "PENDIENTE",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Allistair Clarke",
      "celular_contacto": "1-286-489-4882",
      "email_contacto": "enim@google.net",
      "cargo_contacto": "Director",
      "nombre_representante": "Chloe Peck",
      "celular_representante": "(226) 250-4633",
      "email_representante": "augue@aol.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL023",
      "nombre": "MICROINFORMATICA CIA. LTDA.",
      "mayorista": "TECNOMEGA",
      "fecha": "Apr 24, 2021",
      "ruc_documento": "https://nytimes.com",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Madonna Clark",
      "celular_contacto": "1-713-816-5111",
      "email_contacto": "donec@icloud.org",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Danielle Ware",
      "celular_representante": "1-731-868-6231",
      "email_representante": "egestas.duis@protonmail.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL024",
      "nombre": " PC SUMINISTROS",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 17, 2021",
      "ruc_documento": "http://youtube.com",
      "estado_solicitud": "APROBADO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Kameko Wright",
      "celular_contacto": "(122) 422-5288",
      "email_contacto": "tortor.nibh.sit@google.org",
      "cargo_contacto": "Director",
      "nombre_representante": "Alice Ayers",
      "celular_representante": "(725) 658-4567",
      "email_representante": "lacinia.mattis@aol.org",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL025",
      "nombre": "LIGULA RHONCUS LLP",
      "mayorista": "SIGLO 21",
      "fecha": "Apr 24, 2021",
      "ruc_documento": "http://facebook.com",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "EQUIPOS",
      "nombre_contacto": "Noah Battle",
      "celular_contacto": "1-146-812-8315",
      "email_contacto": "ac.tellus.suspendisse@hotmail.com",
      "cargo_contacto": "Director",
      "nombre_representante": "Ian Lopez",
      "celular_representante": "1-210-636-4837",
      "email_representante": "vulputate.mauris.sagittis@aol.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "solicitud": "SOL026",
      "nombre": "UT CONSULTING",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 27, 2021",
      "ruc_documento": "http://guardian.co.uk",
      "estado_solicitud": "REINGRESO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Norman Diaz",
      "celular_contacto": "(634) 572-1977",
      "email_contacto": "neque.sed@yahoo.net",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Ryder Gonzales",
      "celular_representante": "1-379-441-4142",
      "email_representante": "quis.turpis.vitae@icloud.couk",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "solicitud": "SOL027",
      "nombre": "INTERDUM SED LTD",
      "mayorista": "MEGAMICRO",
      "fecha": "Apr 26, 2021",
      "ruc_documento": "https://yahoo.com",
      "estado_solicitud": "RECHAZADO",
      "tipo_solicitud": "SUMINISTROS",
      "nombre_contacto": "Lillith Rowland",
      "celular_contacto": "(603) 618-8140",
      "email_contacto": "feugiat.non.lobortis@icloud.net",
      "cargo_contacto": "Desarrollador",
      "nombre_representante": "Britanney Oliver",
      "celular_representante": "1-567-253-7528",
      "email_representante": "porta@hotmail.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },

  ]
}

const GeneralReportsJsonConsume = (state = INIT_STATE, action) => {
  switch (action.type) {

    /* REPORT DETAILS */


    /* END REPORT DETAILS */

    // This will be used to get the data json
    case GET_DATA_JSON:
      return {
        ...state,
        data: action.payload
      }

    case CREATE_DATA_JSON:
      console.log(action.payload)
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case UPDATE_DATA_JSON:

      const id = action.payload.id
      const newChannel = action.payload.data
      const channels = state.data
      channels[id] = newChannel

      return {
        ...state,
        state: {
          data: channels
        }
      }

    case CHANGE_STATUS_CHANNEL:

      const index = action.payload.id
      const status = action.payload.status

      // this variable get all channels
      const chs = state.data
      // get the specific channel
      const ch = chs[index]
      ch.estado = status

      // save the ch in the array chns
      chs[index] = ch

      return {
        ...state,
        state: {
          data: chs
        }
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default GeneralReportsJsonConsume
