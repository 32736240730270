import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import Loading from "../../components/Loading";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
  { id: 1, name: "SIGLO 21" },
  { id: 2, name: "TECNOMEGA" },
  { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
  { id: 1, name: "TRADICIONAL" },
  { id: 2, name: "CONNECT" },
];

const ChannelRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRedirectToListWhenTheChannelIsCreated = () => {
    history.push(`/channel-list`);
  };

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/

  // this represent the data company
  const [razonSocial, setRazonSocial] = useState("");
  const [ruc, setRuc] = useState("");
  const [direccion, setDireccion] = useState("");

  // this represent the main contact
  const [nombreContacto, setNombreContacto] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [cargo, setCargo] = useState("");

  // this represent the legal represent
  const [nombreRepresentante, setNombreRepresentante] = useState("");
  const [celularRepresentante, setCelularRepresentante] =
    useState("0987766556");
  const [emailRepresentante, setEmailRepresentante] = useState(
    "waynermoya@hotmail.com"
  );

  // this represetnt the second contact
  const [nombreContacto2, setNombreContacto2] = useState("");
  const [celular2, setCelular2] = useState("");
  const [email2, setEmail2] = useState("");

  // this represent the type of channel
  const [tipoCanal, setTipoCanal] = useState("TRADICIONAL");

  // this represent the documents
  const [acuerdoComercialDocumento, setAcuerdoComercialDocumento] =
    useState(null);
  const [acuerdoComercialBase64, setAcuerdoComercialBase64] = useState("");
  const [rucDocumento, setRucDocumento] = useState("");
  const [rucBase64, setRucBase64] = useState("");

  // this represent the provider
  const [proveedor, setProveedor] = useState("SIGLO 21");

  // this represent the observaciones
  const [observaciones, setObservaciones] = useState("");
  const [loadingOwn, setLoading] = useState(false);

  /* Create a function to validate the number phone  */
  const validatePhone = (phone) => {
    const regex = /^[0-9]{0}$/;
    return regex.test(phone);
  };

  /* Create a function to validate the email  */
  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  /* Create a function to validate each input is not empty */
  const validateInput = (input) => {
    return input !== "";
  };

  /* Create a function to validate each input the form */
  const validateForm = () => {
    return (
      validateInput(razonSocial) &&
      validateInput(ruc) &&
      validateInput(direccion) &&
      validateInput(nombreContacto) &&
      validateInput(celular) &&
      validateEmail(email) &&
      validateInput(cargo) &&
      validateInput(nombreRepresentante) &&
      validateInput(celularRepresentante) &&
      validateEmail(emailRepresentante) &&
      validateInput(tipoCanal) &&
      //&& validateInput(acuerdoComercialDocumento)
      //&& validateInput(rucDocumento)
      validateInput(proveedor)
    );
  };

  const convertToBase64AcuerdoComercial = (file, f) => {
    try {
      // console.log('file', file);
      //console.log('f', f);

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      //console.log(reader)
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.log('ddddddd')
          //console.timeLog(reader)
          base64 = reader?.result;
          //console.log(base64);
          setAcuerdoComercialBase64(base64);
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const convertToBase64Ruc = (file) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      //console.log(reader)
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.log('eeeee')
          //console.timeLog(reader)
          base64 = reader?.result;
          //console.log(base64);
          setRucBase64(base64);
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    //console.log(acuerdoComercialDocumento)
  }, [acuerdoComercialDocumento]);

  const handleCreateChannelDataResponse = (response) => {
    if (response.status) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      let formData = new FormData();
      formData.append("rucDocumentoFile", rucBase64, rucBase64.name);
      formData.append(
        "acuerdoComercialFile",
        acuerdoComercialBase64,
        acuerdoComercialBase64.name
      );

      request(
        {
          url: `/auth/channel/upload/${response.ruc}`,
          method: "POST",
          headers: myHeaders,
          body: formData,
        }, history.push(`/channel-created`)
      );
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      let obj = {
        razonSocial: razonSocial,
        ruc: ruc,
        direccionEmpresa: direccion,
        contactoPrincipal: {
          nombreContacto: nombreContacto,
          celularContacto: celular,
          emailContacto: email,
          cargoContacto: cargo,
          emailContactoSecundario: nombreContacto2,
        },
        contactoSecundario: {
          nombreContacto: nombreContacto2,
          celularContacto: celular2,
          emailContacto: email2,
        },
        nombreRepresentante: nombreRepresentante,
        tipo: tipoCanal,

        mayorista: proveedor,
        observaciones: observaciones,
      };
        setLoading(true)
      request(
        {
          url: `/auth/channel/register`,
          method: "POST",
          headers: myHeaders,
          body: obj,
        },
        handleCreateChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-relative">
      <div className="page-content">
       {loading && <Loading />}
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title">DATOS EMPRESA</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom01"
                          >
                            Razón social
                          </Label>
                          <AvField
                            name="razonSocial"
                            type="text"
                            errorMessage="Ingrese la razón social"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                            value={razonSocial}
                            onChange={(e) => setRazonSocial(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom02"
                          >
                            RUC Empresa
                          </Label>
                          <AvField
                            name="ruc"
                            type="number"
                            errorMessage="Ingrese el RUC"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 13,
                                errorMessage:
                                  "El ruc debe contener 13 caracteres numéricos ",
                              },
                              maxLength: {
                                value: 13,
                                errorMessage:
                                  "El ruc debe contener 13 caracteres numéricos ",
                              },
                            }}
                            id="validationCustom02"
                            value={ruc}
                            onChange={(e) => setRuc(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom03"
                          >
                            Dirección Empresa
                          </Label>
                          <AvField
                            name="direccion"
                            type="text"
                            errorMessage="Ingrese la dirección"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={direccion}
                            onChange={(e) => setDireccion(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">CONTACTO PRINCIPAL</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom04"
                          >
                            Nombre Principal
                          </Label>
                          <AvField
                            name="nombreContacto"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={nombreContacto}
                            onChange={(e) => setNombreContacto(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom05"
                          >
                            Celular Principal
                          </Label>
                          <AvField
                            name="celular"
                            type="number"
                            errorMessage="Ingrese el celular del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom06"
                          >
                            Email Principal
                          </Label>
                          <AvField
                            name="email"
                            type="email"
                            errorMessage="Ingrese el email del contacto principal"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom06"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom07"
                          >
                            Cargo Principal
                          </Label>
                          <AvField
                            name="cargo"
                            type="text"
                            errorMessage="Ingrese el cargo del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom07"
                            value={cargo}
                            onChange={(e) => setCargo(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">CONTACTO SECUNDARIO</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom20"
                          >
                            Nombre Secundario
                          </Label>
                          <AvField
                            name="nombreContacto2"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto secundario"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom20"
                            value={nombreContacto2}
                            onChange={(e) => setNombreContacto2(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Celular Secundario
                          </Label>
                          <AvField
                            name="celular2"
                            type="number"
                            errorMessage="Ingrese el celular del contacto secundario"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom21"
                            value={celular2}
                            onChange={(e) => setCelular2(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom22"
                          >
                            Email Secundario
                          </Label>
                          <AvField
                            name="email2"
                            type="email"
                            errorMessage="Ingrese el email del contacto secundario"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom22"
                            value={email2}
                            onChange={(e) => setEmail2(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">REPRESENTANTE LEGAL</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom08"
                          >
                            Nombre Representante
                          </Label>
                          <AvField
                            name="nombreRepresentante"
                            type="text"
                            errorMessage="Ingrese el nombre del representante legal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom08"
                            value={nombreRepresentante}
                            onChange={(e) =>
                              setNombreRepresentante(e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      {/*}
                                            <Col md="6">
                                                <div className="mb-3 d-flex">
                                                    <Label className="form-label" htmlFor="validationCustom9">Celular Representante</Label>
                                                    <AvField
                                                        name="celularRepresentante"
                                                        type="number"
                                                        errorMessage="Ingrese el celular del representante legal"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom9"
                                                        value={celularRepresentante}
                                                        onChange={(e) => setCelularRepresentante(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3 d-flex">
                                                    <Label className="form-label" htmlFor="validationCustom10">Email Representante</Label>
                                                    <AvField
                                                        name="emailRepresentante"
                                                        type="email"
                                                        errorMessage=" Ingrese el email del representante legal"
                                                        className="form-control"
                                                        validate={{ required: { value: true }, email: { value: true } }}
                                                        id="validationCustom10"
                                                        value={emailRepresentante}
                                                        onChange={(e) => setEmailRepresentante(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            */}
                    </Row>

                    <Row>
                      <Col md="6" sm={12}>
                        <h4 className="card-title">TIPO CANAL</h4>
                        <div className="mb-3 d-flex col-12">
                          <Col md={12} sm={12} style={{ width: "100%" }}>
                            <select
                              className="form-control"
                              value={tipoCanal}
                              onChange={(e) => {
                                setTipoCanal(e.target.value);
                              }}
                            >
                              {Object.values(typesChannel).map(
                                (typeChannel) => (
                                  <option
                                    key={typeChannel.id}
                                    value={typeChannel.name}
                                  >
                                    {typeChannel.name}
                                  </option>
                                )
                              )}
                            </select>
                          </Col>
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <h4 className="card-title">SELECCIONE MAYORISTA</h4>
                        <div className="mb-3 d-flex">
                          <Col md={12} style={{ width: "100%" }}>
                            <select
                              className="form-control"
                              value={proveedor}
                              onChange={(e) => {
                                setProveedor(e.target.value);
                              }}
                            >
                              {Object.values(providers).map((provider) => (
                                <option key={provider.id} value={provider.name}>
                                  {provider.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <h4 className="card-title">DOCUMENTOS</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom11"
                          >
                            Acuerdo Comercial
                          </Label>
                          <AvField
                            accept="application/pdf"
                            name="acuerdoComercialDocumento"
                            type="file"
                            errorMessage="Ingrese el documento del acuerdo comercial"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom11"
                            value={acuerdoComercialDocumento}
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];
                              setAcuerdoComercialBase64(f);
                              //convertToBase64AcuerdoComercial(e.target.files, f)

                              //setAcuerdoComercialDocumento(e.target)
                              /*
                                                            setAcuerdoComercialDocumento(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom12"
                          >
                            Documento RUC
                          </Label>
                          <AvField
                            accept="application/pdf"
                            name="rucDocumento"
                            type="file"
                            errorMessage="Ingrese el documento del RUC"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom12"
                            value={rucDocumento}
                            onChange={(e) => {
                              const f = e.target.files[0];
                              setRucBase64(f);
                              //convertToBase64Ruc(e.target.files)
                              //setRucDocumento(e.target.files)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">OBSERVACIONES</h4>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <AvField
                            name="text"
                            type="textarea"
                            className="form-control"
                            value={observaciones}
                            onChange={(e) => setObservaciones(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={loading}
                    >
                      Crear
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente el canal{" "}
                <span style={{ fontWeight: "bold" }}> {razonSocial} </span> con
                el contacto{" "}
                <span style={{ fontWeight: "bold" }}> {nombreContacto} </span>,
                se ha enviado un correo al email de contacto con la información
                de inicio de sesión.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheChannelIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ChannelRegister;
