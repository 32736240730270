
import React, { Component, useState } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";

import { Carousel } from "react-bootstrap";

// import the use-http
import useHttp from '../../hook/use-http';

const ForgetPasswordPage = ({ forgetUser, message, history }) => {

    //this variable will be used to send email to user
    const [username, setUsername] = useState('');

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // this function will be used to process the data response from server
    const handleForgetPasswordDataResponse = (response) => {
        console.log(response)
        history.push('/reset-password/' + username);
    }

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        //this.props.forgetUser(values, this.props.history);
        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            console.log(username);
            request({
                url: `/noauth/user/forgotPassword`,
                method: 'POST',
                headers: myHeaders,
                body: {
                    email: username
                }
            }, handleForgetPasswordDataResponse)

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo"><img src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-logo-2x.svg" height="20" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Restablecer la contraseña</h4>
                                                    <p className="text-muted">Restablezca su contraseña a Lexmark.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    {/*}
                                                    {this.props.forgetError && this.props.forgetError ?
                                                        <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                                    {
                                                        this.props.message ?
                                                            <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                    }*/}
                                                    <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon" style={{ color: '#008945' }}></i>
                                                            <Label htmlFor="useremail">Email</Label>
                                                            <AvField
                                                                name="useremail"
                                                                value={username}
                                                                type="email"
                                                                validate={{ email: true, required: true }}
                                                                className="form-control"
                                                                id="useremail"
                                                                placeholder="Ingrese correo electrónico"
                                                                onChange={(e) => setUsername(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light btn btn-primary-lexmark" type="submit">Reiniciar</Button>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    {/* 
                                                        <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p>
                                                        */}
                                                    <nav class="legal-menu">
                                                        <div class="container d-flex flex-column justify-content-center align-items-center">
                                                            <ul class="legal-menu-brand">
                                                                <li class="legal-menu-brand-logo"><img
                                                                    src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-symbol-2x.svg"
                                                                    alt="Símbolo de Lexmark" />
                                                                </li>
                                                                <li>
                                                                    <ul class="legal-menu-brand-copyright">
                                                                        <li>Lexmark International, Inc.</li>
                                                                        <li>&copy;2022 All rights reserved.</li>

                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul class="legal-menu-notice mt-2" >
                                                                <li><a href="/es_xl/privacy-policy.html">Política de privacidad</a></li>
                                                            </ul>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <Carousel>
                                {/** 
                                <Carousel.Item interval={30000} >
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/1.jpg"
                                        alt="Second slide"

                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/2.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/3.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                */}
                                <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/4.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage)
);
