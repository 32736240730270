import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
// import the components bootstrap
import {  Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';


const EntityRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleRedirectToListWhenTheEntityIsCreated = () => {
    history.push(`/entity-list`);
  };

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    entidad: null,
    ruc: null
  });


  const handleCreateEntityDataResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push(`/entity-created`);
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/entity`,
          method: "POST",
          headers: myHeaders,
          body: {...form},
        },
        handleCreateEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateRuc = (value) => {
    if (!value) {
      return 'RUC no válido';
    }
  
    const rucPattern = /^[0-9]{13}$/;
  
    if (!rucPattern.test(value)) {
      return 'RUC no válido';
    }
  
    const provincia = Number(value.substring(0, 2));
    if (provincia < 1 || provincia > 24) {
      return 'RUC no válido';
    }
  
    const establecimiento = Number(value.substring(10, 13));
    if (establecimiento !== 1) {
      return 'RUC no válido';
    }
  
    return true;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title">DATOS ENTIDAD</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="entity-create-entidad"
                          >
                            Entidad
                          </Label>
                          <br />
                          <AvField
                            name="entidad"
                            type="text"
                            errorMessage="Ingrese la entidad"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="entity-create-entidad"
                            value={form.entidad}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="entity-create-ruc"
                          >
                            Ruc
                          </Label>
                          <br />

                          <AvField
                            name="ruc"
                            type="number"
                            errorMessage="Ingrese el ruc"
                            className="form-control"
                            validate={{ required: { value: true }, custom: validateRuc}}
                            id="entity-create-ruc"
                            value={form.ruc}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={loading}
                    >
                      Crear
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente la entidad{" "}
                <span style={{ fontWeight: "bold" }}> {form.entidad} </span>.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheEntityIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EntityRegister;