import * as types from './actionType'

export const getTeamCertificateList = () => {
    return {
        type: types.GET_TEAM_CERTIFICATE_LIST
    }
}

export const addItemIntoCertificateList = (idCertification, items, softwares = [], nameCertificate, actionType = 'ADD') => {
    return {
        type: types.ADD_ITEMS_CERTIFICATE_INTO_LIST,
        payload: {
            idCertification,
            items,
            softwares,
            nameCertificate,
            actionType
        }
    }
}

export const addCounterLineInCertificateById = (id_cert) => {
    return {
        type: types.ADD_COUNTER_LINE_CERTIFICATION_BY_ID,
        payload: {
            id_cert
        }
    }
}

export const deleteCounterLineInCertificateById = (id_cert) => {
    return {
        type: types.DELETE_COUNTER_IN_CERTIFICATE,
        payload: {
            id_cert
        }
    }
}

export const deleteAllCertificateSelected = () => {
    return {
        type: types.REMOVE_ALL_CERTIFICATE_SELECTED
    }
}

export const deleteCounterCertificateInitial = (title, counter) => {
    return {
        type: types.REMOVE_ALL_COUNTER_IN_CERTIFICATE_INITIAL,
        payload: {
            title: title,
            counter: counter
        }
    }
}

export const addSoftwareIntoCertificadoTecnicoSoftware = (id, softwares) => {
    return {
        type: types.ADD_SOFTWARE_INTO_LIST,
        payload: {
            id,
            softwares
        }
    }
}

export const addAllCertificationFromRequest = (certifications) => {
    return {
        type: types.ADD_ALL_CERTIFICATIONS_FROM_REQUEST,
        payload: {
            certifications
        }
    }
}

export const addStatusAdmin = (status) => {
    return {
        type: types.ADD_STATUS_ADMIN,
        payload: {
            status: status
        }
    }
}

export const addStatusCertifications = (status) => {
    return {
        type: types.ADD_STATUS_CERTIFICATION,
        payload: {
            statusCertification: status
        }
    }
}