import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  BsApp,
  BsArrowRight,
  BsCalendar2Date,
  BsCalendar3Event,
  BsCalendar4Event,
  BsCalendarMinus,
  BsCalendarRange,
  BsCursor,
  BsEnvelope,
  BsPerson,
} from "react-icons/bs";
import { Col, Row } from "reactstrap";

// this variable will be used to list all types contraction

const tipos_contratacion = [
  { id: 1, name: "SUBASTA INVERSA ELECTRÓNICA" },
  { id: 2, name: "LICITACIÓN" },
  { id: 2, name: "ÍNFIMA CUANTÍA" },
  { id: 3, name: "CATÁLOGO ELECTRÓNICO" },
];

// this function will be uses to show form about public sector
const PublicSectorAdmin = ({ handleChangeDataProcess, dataProcess }) => {
  const [tipoContratacion, setTipoContratacion] = useState("");

  // this variable will be used to create a data of process
  const [entidadContratante, setEntidadContratante] = useState("");
  const [emailEncargado, setEmailEncargado] = useState("");
  const [objectoDelProceso, setObjectoDelProceso] = useState("");
  const [codigoProceso, setCodigoProceso] = useState("");
  const [fechaLimitePregunta, setFechaLimitePregunta] = useState("");
  const [fechaLimiteRespuesta, setFechaLimiteRespuesta] = useState("");
  const [fechaLimiteEntregaOferta, setFechaLimiteEntregaOferta] = useState("");
  const [fechaLimiteCalificacion, setFechaLimiteCalificacion] = useState("");
  const [fechaInicioPuja, setFechaInicioPuja] = useState("");
  const [plazoEntrega, setPlazoEntrega] = useState("");

  useEffect(() => {
    try {
      setEntidadContratante(dataProcess.entidadContratante);
      setEmailEncargado(
        dataProcess.emailEncargado || dataProcess.emailFuncionario
      );
      setObjectoDelProceso(dataProcess.objetoProceso);
      setCodigoProceso(dataProcess.codigoProceso);
      setFechaLimitePregunta(dataProcess.fechaLimitePreguntas);
      setFechaLimiteRespuesta(dataProcess.fechaLimiteRespuestas);
      setFechaLimiteEntregaOferta(dataProcess.fechaLimiteEntregaOferta);
      setFechaLimiteCalificacion(dataProcess.fechaLimiteCalificacion);
      setFechaInicioPuja(dataProcess.fechaInicioPuja);
      setPlazoEntrega(dataProcess.plazoEntrega);
    } catch (error) {
      console.log("error");
    }

    /*
        "entidadContratante": entidadContratante,
                "emailFuncionario": emailEncargado,
                "objetoProceso": objectoDelProceso,
                "codigoProceso": codigoProceso,
                "tipoContratacion": tipoContratacion,
                "fechaLimitePreguntas": fechaLimitePregunta,
                "fechaLimiteRespuestas": fechaLimiteRespuesta,
                "fechaLimiteEntregaOferta": fechaLimiteEntregaOferta,
                "fechaLimiteCalificacion": fechaLimiteCalificacion,
                "fechaInicioPuja": fechaInicioPuja,
                "plazoEntrega": plazoEntrega
                */
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="form-label mb-3" style={{ fontWeight: "bold" }}>
          DATOS DEL PROCESO - SECTOR PUBLICO
        </h4>
      </div>
      <AvForm lassName="needs-validation">
        <Row style={{ marginTop: 20 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>ENTIDAD CONTRATANTE</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsPerson />
                </i>
                <AvField
                  name="entidad_contratante"
                  type="text"
                  errorMessage="Ingrese la entidad contratante"
                  value={entidadContratante}
                  onChange={(e) => setEntidadContratante(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  EMAIL ENCARGADO DEL PROCESO
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsEnvelope />
                </i>
                <AvField
                  name="email_encargado"
                  type="text"
                  errorMessage="Ingrese el email del encargado del proceso"
                  value={emailEncargado}
                  onChange={(e) => setEmailEncargado(e.target.value)}
                  className="form-control"
                  style={{ width: "100%" }}
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>OBJETO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsApp />
                </i>
                <AvField
                  name="objecto_proceso"
                  type="text"
                  errorMessage="Ingrese el objeto del proceso"
                  value={objectoDelProceso}
                  onChange={(e) => setObjectoDelProceso(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>CODIGO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCursor />
                </i>
                <AvField
                  name="codigo_proceso"
                  type="text"
                  errorMessage="Ingrese el codigo del proceso"
                  value={codigoProceso}
                  onChange={(e) => setCodigoProceso(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>TIPO DE CONTRATACION</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsArrowRight />
                </i>
                <select
                  className="form-control"
                  style={{ width: "100%" }}
                  value={tipoContratacion}
                  onChange={(e) => {
                    setTipoContratacion(e.target.value);
                  }}
                  disabled
                >
                  {Object.values(tipos_contratacion).map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE PREGUNTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <AvField
                  name="fecha_limite_preguntas"
                  type="datetime-local"
                  errorMessage="Ingrese la fecha limite de preguntas"
                  value={fechaLimitePregunta}
                  onChange={(e) => setFechaLimitePregunta(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE RESPUESTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarRange />
                </i>
                <AvField
                  name="fecha_limite_respuesta"
                  type="datetime-local"
                  errorMessage="Ingrese la fecha limite de respuestas"
                  value={fechaLimiteRespuesta}
                  onChange={(e) => setFechaLimiteRespuesta(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE ENTREGA OFERTA
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar2Date />
                </i>
                <AvField
                  name="fecha_limite_entrega_oferta"
                  type="datetime-local"
                  errorMessage="Ingrese la fecha limite de entrega de oferta"
                  value={fechaLimiteEntregaOferta}
                  onChange={(e) => setFechaLimiteEntregaOferta(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE CALIFICACIÓN
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <AvField
                  name="fecha_limite_calificacion"
                  type="datetime-local"
                  errorMessage="Ingrese la fecha limite de calificacion"
                  value={fechaLimiteCalificacion}
                  onChange={(e) => setFechaLimiteCalificacion(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA INICIO PUJA</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar3Event />
                </i>
                <AvField
                  name="fecha_inicio_puja"
                  type="datetime-local"
                  errorMessage="Ingrese la fecha de inicio de puja"
                  value={fechaInicioPuja}
                  onChange={(e) => setFechaInicioPuja(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  PLAZO DE ENTREGA ( dias )
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar4Event />
                </i>
                <AvField
                  style={{ width: "100%" }}
                  name="plazo_entrega"
                  type="number"
                  errorMessage="Ingrese el plazo de entrega"
                  value={plazoEntrega}
                  onChange={(e) => setPlazoEntrega(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};

export default PublicSectorAdmin;
