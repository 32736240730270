
import { channel } from "redux-saga"
import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_DATA_JSON,
  UPDATE_DATA_JSON,
  CHANGE_STATUS_CHANNEL,
  CREATE_DATA_JSON,
  UPDATE_CERTIFICATION_JSON
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  // In this part we include a data example to use in the app
  data: [

    {
      "_id": {
        "$oid": "623101d17dbb59434ee18cbe"
      },
      "observaciones": "",
      "cod_solicictud": "SOL129",
      "fecha_solicitud": "17/04/2022",
      "tipo_solicitud": "SUMINISTROS",
      "estado_solicitud": "PENDIENTE",
      "razon_social": "FESAECUADOR S.A.",
      "RUC": "1792094836001",
      "mayorista": "SIGLO 21",
      "sector": "PRIVADO",
      "entidad": "",
      "dirigido_a": "",
      "cargo": "",
      "objeto_proceso": "",
      "cod_proceso": "SIE-GADPMS-053-2021",
      "tipo_contratacion": "",
      "tipo_canal": "TRADICIONAL",
      "fecha_lim_preguntas": "04/18/2021 19:30:00",
      "fecha_lim_respuestas": "04/20/2021 20:00:00",
      "fecha_lim_entrega_oferta": "04/25/2021 20:00:00",
      "fecha_lim_califica": "05/12/2022 20:00:00",
      "fecha_ini_puja": "04/26/2022 09:00:00",
      "plazo_entrega": "60 dias",
      "certificados": [{
        "cod_cert": "CERT_0010",
        "cod_seq": "LEX2022-98264",
        "nombre": "Certificado Garantía técnica",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBhbWJpZW50YWw8L2gxPgoKPHA+TXkgZmlyc3QgcGFyYWdyYXBoLjwvcD4KCjwvYm9keT4KPC9odG1sPgo="
      }, {
        "cod_cert": "CERT_0001",
        "cod_seq": "LEX2022-98374",
        "nombre": "Certificado Distribuidor Autorizado Equipos",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBEaXN0cmlidWlkb3IgQXV0b3JpemFkbyBFcXVpcG9zPC9oMT4KCjxwPk15IGZpcnN0IHBhcmFncmFwaC48L3A+Cgo8L2JvZHk+CjwvaHRtbD4K"
      }, {
        "cod_cert": "CERT_0005",
        "cod_seq": "LEX2022-83746",
        "nombre": "Certificado ISO",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBFcXVpcG9zIE51ZXZvczwvaDE+Cgo8cD5NeSBmaXJzdCBwYXJhZ3JhcGguPC9wPgoKPC9ib2R5Pgo8L2h0bWw+Cg=="
      }],
      "especiales": "adsfalsdkfjasldkjflsakdjflsakdjflaskdfjlskdfjlskdfjlsakdfjlskdafjlaskjdf",
      "suministros": [{
        "partno": "55B4000",
        "cant": 8,
        "model": "MS331dn",
        "description": "3K -Standard Yield Toner"
      }, {
        "partno": "55B8480",
        "cant": 3,
        "model": "MS322dn",
        "description": "15K -High Yield Toner"
      }, {
        "partno": "55B8479",
        "cant": 8,
        "model": "MS331dn",
        "description": "MS331 1yr OSR  NBD"
      }, {
        "partno": "55B8477",
        "cant": 3,
        "model": "MS312dn",
        "description": "MS431 1yr OSR  NBD"
      }, {
        "partno": "55B8474",
        "cant": 1,
        "model": "MS332dn",
        "description": "MS431 3yr OSR  NBD"
      }],
      "equipos": []
    },
    {
      "_id": {
        "$oid": "623101d17dbb59434ee18cbe"
      },
      "observaciones": "NO CUMPLE LO ESTABLECIDO.",
      "cod_solicictud": "SOL028",
      "fecha_solicitud": "15/03/2022",
      "tipo_solicitud": "SUMINISTROS",
      "estado_solicitud": "RECHAZADO",
      "razon_social": "AKROS CIA. LTDA.",
      "RUC": "1791148800001",
      "mayorista": "TECNOMEGA",
      "sector": "PUBLICO",
      "entidad": "",
      "dirigido_a": "",
      "cargo": "",
      "objeto_proceso": "",
      "cod_proceso": "SIE-EECS-DT-128-2021",
      "tipo_contratacion": "",
      "tipo_canal": "CONNECT",
      "fecha_lim_preguntas": "12/13/2021 19:30:00",
      "fecha_lim_respuestas": "12/15/2021 20:00:00",
      "fecha_lim_entrega_oferta": "",
      "fecha_lim_califica": "01/12/2022 20:00:00",
      "fecha_ini_puja": "01/13/2022 09:00:00",
      "plazo_entrega": "60 dias",
      "certificados": [{
        "cod_cert": "CERT_0010",
        "cod_seq": "LEX2022-71972",
        "nombre": "Certificado ambiental",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBhbWJpZW50YWw8L2gxPgoKPHA+TXkgZmlyc3QgcGFyYWdyYXBoLjwvcD4KCjwvYm9keT4KPC9odG1sPgo="
      }, {
        "cod_cert": "CERT_0001",
        "cod_seq": "LEX2022-71975",
        "nombre": "Certificado Distribuidor Autorizado Equipos",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBEaXN0cmlidWlkb3IgQXV0b3JpemFkbyBFcXVpcG9zPC9oMT4KCjxwPk15IGZpcnN0IHBhcmFncmFwaC48L3A+Cgo8L2JvZHk+CjwvaHRtbD4K"
      }, {
        "cod_cert": "CERT_0005",
        "cod_seq": "LEX2022-71976",
        "nombre": "Certificado Equipos Nuevos",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBFcXVpcG9zIE51ZXZvczwvaDE+Cgo8cD5NeSBmaXJzdCBwYXJhZ3JhcGguPC9wPgoKPC9ib2R5Pgo8L2h0bWw+Cg=="
      }],
      "especiales": "adsfalsdkfjasldkjflsakdjflsakdjflaskdfjlskdfjlskdfjlsakdfjlskdafjlaskjdf",
      "suministros": [{
        "partno": "55B4000",
        "cant": 3,
        "model": "MS331dn",
        "description": "3K -Standard Yield Toner"
      }, {
        "partno": "55B3400",
        "cant": 4,
        "model": "MS322dn",
        "description": "15K -High Yield Toner"
      }, {
        "partno": "23B4000",
        "cant": 3,
        "model": "MS331dn",
        "description": "MS331 1yr OSR  NBD"
      }, {
        "partno": "5555000",
        "cant": 12,
        "model": "MS312dn",
        "description": "MS431 1yr OSR  NBD"
      }, {
        "partno": "55B4980",
        "cant": 11,
        "model": "MS332dn",
        "description": "MS431 3yr OSR  NBD"
      }],
      "equipos": []
    },

    {
      "_id": {
        "$oid": "623101d17dbb59434ee18cbe"
      },
      "observaciones": "",
      "cod_solicictud": "SOL030",
      "fecha_solicitud": "15/04/2022",
      "tipo_solicitud": "EQUIPOS",
      "estado_solicitud": "APROBADO",
      "razon_social": "PAPER COMPUT P-C S.A. ",
      "RUC": "0993114871001",
      "mayorista": "TECNOMEGA",
      "sector": "PUBLICO",
      "entidad": "",
      "dirigido_a": "",
      "cargo": "",
      "objeto_proceso": "",
      "cod_proceso": "SIE-GADPMS-090-2021",
      "tipo_contratacion": "",
      "tipo_canal": "CONNECT",
      "fecha_lim_preguntas": "12/13/2021 19:30:00",
      "fecha_lim_respuestas": "12/15/2021 20:00:00",
      "fecha_lim_entrega_oferta": "",
      "fecha_lim_califica": "01/12/2022 20:00:00",
      "fecha_ini_puja": "01/13/2022 09:00:00",
      "plazo_entrega": "60 dias",
      "certificados": [{
        "cod_cert": "CERT_0020",
        "cod_seq": "LEX2022-98234",
        "nombre": "Certificado de Vigencia Tecnológica",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBhbWJpZW50YWw8L2gxPgoKPHA+TXkgZmlyc3QgcGFyYWdyYXBoLjwvcD4KCjwvYm9keT4KPC9odG1sPgo="
      }, {
        "cod_cert": "CERT_0040",
        "cod_seq": "LEX2022-98335",
        "nombre": "Certificado de Equipos Nuevos",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBEaXN0cmlidWlkb3IgQXV0b3JpemFkbyBFcXVpcG9zPC9oMT4KCjxwPk15IGZpcnN0IHBhcmFncmFwaC48L3A+Cgo8L2JvZHk+CjwvaHRtbD4K"
      }, {
        "cod_cert": "CERT_0045",
        "cod_seq": "LEX2022-83743",
        "nombre": "Certificado Técnico Software",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBFcXVpcG9zIE51ZXZvczwvaDE+Cgo8cD5NeSBmaXJzdCBwYXJhZ3JhcGguPC9wPgoKPC9ib2R5Pgo8L2h0bWw+Cg=="
      }],
      "especiales": "adsfalsdkfjasldkjflsakdjflsakdjflaskdfjlskdfjlskdfjlsakdfjlskdafjlaskjdf",
      "suministros": [],
      "equipos": [
        {
          "partno": "55B4000",
          "cant": 8,
          "model": "MS331dn",
          "description": "3K -Standard Yield Toner"
        }, {
          "partno": "55B8480",
          "cant": 3,
          "model": "MS322dn",
          "description": "15K -High Yield Toner"
        }, {
          "partno": "55B8479",
          "cant": 8,
          "model": "MS331dn",
          "description": "MS331 1yr OSR  NBD"
        }, {
          "partno": "55B8477",
          "cant": 3,
          "model": "MS312dn",
          "description": "MS431 1yr OSR  NBD"
        }, {
          "partno": "55B8474",
          "cant": 1,
          "model": "MS332dn",
          "description": "MS431 3yr OSR  NBD"

        }
      ]
    },
    {
      "_id": {
        "$oid": "623101d17dbb59434ee18cbe"
      },
      "observaciones": "",
      "cod_solicictud": "SOL031",
      "fecha_solicitud": "19/04/2022",
      "tipo_solicitud": "EQUIPOS",
      "estado_solicitud": "PENDIENTE",
      "razon_social": "TELCOMBAS S.A.",
      "RUC": "0691741311001",
      "mayorista": "MEGAMICRO",
      "sector": "PUBLICO",
      "entidad": "",
      "dirigido_a": "",
      "cargo": "",
      "objeto_proceso": "",
      "cod_proceso": "SIE-GADPMS-100-2021",
      "tipo_contratacion": "",
      "tipo_canal": "TRADICIONAL",
      "fecha_lim_preguntas": "12/13/2021 19:30:00",
      "fecha_lim_respuestas": "12/15/2021 20:00:00",
      "fecha_lim_entrega_oferta": "",
      "fecha_lim_califica": "01/12/2022 20:00:00",
      "fecha_ini_puja": "01/13/2022 09:00:00",
      "plazo_entrega": "60 dias",
      "certificados": [{
        "cod_cert": "CERT_0030",
        "cod_seq": "LEX2022-98250",
        "nombre": "Certificado Distribuidor Autorizados De Equipos",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBhbWJpZW50YWw8L2gxPgoKPHA+TXkgZmlyc3QgcGFyYWdyYXBoLjwvcD4KCjwvYm9keT4KPC9odG1sPgo="
      }, {
        "cod_cert": "CERT_0060",
        "cod_seq": "LEX2022-98390",
        "nombre": "Certificado Distribuidor Autorizados De Software",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBEaXN0cmlidWlkb3IgQXV0b3JpemFkbyBFcXVpcG9zPC9oMT4KCjxwPk15IGZpcnN0IHBhcmFncmFwaC48L3A+Cgo8L2JvZHk+CjwvaHRtbD4K"
      }, {
        "cod_cert": "CERT_003100",
        "cod_seq": "LEX2022-83743",
        "nombre": "Certificado Técnico Software",
        "cert_b64": "PCFET0NUWVBFIGh0bWw+CjxodG1sPgo8Ym9keT4KCjxoMT5DZXJ0aWZpY2FkbyBFcXVpcG9zIE51ZXZvczwvaDE+Cgo8cD5NeSBmaXJzdCBwYXJhZ3JhcGguPC9wPgoKPC9ib2R5Pgo8L2h0bWw+Cg=="
      }],
      "especiales": "adsfalsdkfjasldkjflsakdjflsakdjflaskdfjlskdfjlskdfjlsakdfjlskdafjlaskjdf",
      "suministros": [],
      "equipos": [
        {
          "partno": "55B4030",
          "cant": 4,
          "model": "MS331dn",
          "description": "3K -Standard Yield Toner"
        }, {
          "partno": "55B4031",
          "cant": 8,
          "model": "MS322dn",
          "description": "15K -High Yield Toner"
        }, {
          "partno": "55B4032",
          "cant": 8,
          "model": "MS331dn",
          "description": "MS331 1yr OSR  NBD"
        }, {
          "partno": "55B4033",
          "cant": 10,
          "model": "MS312dn",
          "description": "MS431 1yr OSR  NBD"
        }
      ]
    }

  ]
}

const CertificationJsonConsume = (state = INIT_STATE, action) => {
  switch (action.type) {

    /* START CERTIFICATION JSON */

    case UPDATE_CERTIFICATION_JSON:

      // get the id of the request
      const id = action.payload.id

      // get all request 
      let requests = state.data
      console.log(requests)

      // declare the request variable
      // get the request by id, with the function indexOf
      let request = requests[id];

      // change the status of the request
      request.estado_solicitud = action.payload.status

      // add a comment for the request
      request.observaciones = action.payload.observation

      // add again the request into requests
      requests[id] = request

      return {
        ...state,
        state: {
          data: requests
        }
      }


    /* END CERTIFICATION JSON */

    // This will be used to get the data json
    case GET_DATA_JSON:
      return {
        ...state,
        data: action.payload
      }

    case CREATE_DATA_JSON:
      console.log(action.payload)
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case CHANGE_STATUS_CHANNEL:

      const index = action.payload.id
      const status = action.payload.status

      // this variable get all channels
      const chs = state.data
      // get the specific channel
      const ch = chs[index]
      ch.estado = status

      // save the ch in the array chns
      chs[index] = ch

      return {
        ...state,
        state: {
          data: chs
        }
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default CertificationJsonConsume
