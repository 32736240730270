import React, { Component, useEffect, useState } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

import { Carousel } from "react-bootstrap";


// import images
import logodark from "../../assets/images/logo-dark.png";

// import the use-http
import useHttp from '../../hook/use-http';

const ChangePasswordToAvailable = ({ forgetUser, message, history, match }) => {

    //this variable will be used to send email to user
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [againPassword, setAgainPassword] = useState('');

    // this variable will be used to control de error 
    const [resetError, setResetError] = useState(null);
    const [messageResetPassword, setMessageResetPassword] = useState(null);


    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    useEffect(() => {

        // get the email form url
        const { email } = match.params;
        // then set the email to the state variable
        setUsername(email);

    }, [])

    // this function will be used to process the data response from server
    const handleForgetPasswordDataResponse = (response) => {
        // if the response is ok show the message to the user
        if (response === true) {
            setMessageResetPassword("Contraseña cambiada con éxito");
            setResetError(false);
            setTimeout(() => {
                history.push("/login");
            }, 4000);
        } else {
            setResetError(true);
            setMessageResetPassword("Error al cambiar su contraseña");
        }
    }

    // handleValidSubmit
    const handleResetPassword = (event, values) => {
        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            console.log(username);
            request({
                url: `/noauth/user/confirmOnboard`,
                method: 'POST',
                headers: myHeaders,
                body: {
                    email: username,
                    password: password,
                }
            }, handleForgetPasswordDataResponse)

        } catch (error) {
            console.log(error);
        }
    }

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    console.log(windowDimension);

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo"><img src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-logo-2x.svg" height="20" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-16">Crear Contraseña</h4>
                                                    <p className="text-muted font-size-13">Por favor ingresar una nueva contraseña para activar su cuenta.</p>
                                                </div>

                                                <div className="p-2 mt-2">

                                                    {resetError === true && < Alert color="danger">{messageResetPassword}</Alert>}
                                                    {resetError === false && < Alert color="success">{messageResetPassword}</Alert>}

                                                    <AvForm className="form-horizontal" onValidSubmit={handleResetPassword}>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon" style={{ color: '#008945' }}></i>
                                                            <Label htmlFor="useremail">Email</Label>
                                                            <AvField
                                                                name="useremail"
                                                                value={username}
                                                                type="email"
                                                                validate={{ email: true, required: true }}
                                                                className="form-control"
                                                                id="useremail"
                                                                placeholder={`${windowDimension.winWidth > 364 ? 'Ingrese correo electrónico' : ''}`}
                                                                onChange={(e) => setUsername(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon" style={{ color: '#008945' }}></i>
                                                            <Label htmlFor="userpassword">Nueva Contraseña</Label>
                                                            <AvField
                                                                name="password"
                                                                value={password}
                                                                type="password"
                                                                validate={{
                                                                    required: true,
                                                                    minLength: { value: 8, errorMessage: 'La contraseña debe tener al menos 8 caracteres' },
                                                                    maxLength: { value: 20, errorMessage: 'La contraseña no debe tener mas de 20 caracteres' },
                                                                    pattern: { value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{8,}).*$', errorMessage: 'La contraseña debe tener al menos una mayuscula, una minuscula, un numero y un caracter especial' }
                                                                }}
                                                                className="form-control"
                                                                id="userpassword"
                                                                placeholder={`${windowDimension.winWidth > 364 ? 'Ingrese nueva contraseña' : ''}`}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-password-fill auti-custom-input-icon" style={{ color: '#008945' }}></i>
                                                            <Label htmlFor="useragainpass">Confirmar Contraseña</Label>
                                                            <AvField
                                                                name="useragainpass"
                                                                value={againPassword}
                                                                type="password"
                                                                validate={{
                                                                    required: true,
                                                                    match: { value: 'password', errorMessage: 'La contraseña no coincide' }
                                                                }}
                                                                className="form-control"
                                                                id="useragainpass"
                                                                placeholder={`${windowDimension.winWidth > 364 ? 'Por favor confirme su contraseña' : ''}`}
                                                                onChange={(e) => setAgainPassword(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className=" text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light btn btn-primary-lexmark" type="submit">Reiniciar</Button>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-2 text-center">
                                                    {/* 
                                                        <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p>
                                                        */}
                                                    <div class="container d-flex flex-column justify-content-center align-items-center">
                                                        <ul class="legal-menu-brand">
                                                            <li class="legal-menu-brand-logo"><img
                                                                src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-symbol-2x.svg"
                                                                alt="Símbolo de Lexmark" />
                                                            </li>
                                                            <li>
                                                                <ul class="legal-menu-brand-copyright">
                                                                    <li>Lexmark International, Inc.</li>
                                                                    <li>&copy;2022 All rights reserved.</li>

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul class="legal-menu-notice mt-2" >
                                                            <li><a href="/es_xl/privacy-policy.html">Política de privacidad</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col >
                        <Col lg={8}>
                            <Carousel>
                                {/* 
                                <Carousel.Item interval={30000} >
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="../assets/lexmark_principal/1.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="../assets/lexmark_principal/2.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="../assets/lexmark_principal/3.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                */}
                                <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="../assets/lexmark_principal/4.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>

                            </Carousel>
                        </Col>
                    </Row >
                </Container >
            </div >
        </React.Fragment >
    );

}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ChangePasswordToAvailable)
);