import { AvForm } from 'availity-reactstrap-validation';
import React, { useState, useEffect } from 'react'
import { BsCursor, BsMinecartLoaded, BsPencilSquare, BsPhone, BsRecord, BsRecycle } from 'react-icons/bs';
import { Col, Row } from 'reactstrap';

// this variable will represent different supplies
const sectors = [
    { id: 1, name: 'PUBLICO', title: 'PUBLICO_', icon: <BsMinecartLoaded /> },
    { id: 2, name: 'PRIVADO', title: 'PRIVADO_', icon: <BsPhone /> },
]

const SelectSectorAdmin = ({ handleChangeView, sector }) => {

    const [suppliesSelected, setSuppliesSelected] = useState({});

    useEffect(() => {

        let sectorSelected = ''
        if (sector === 'PRIVADO') {
            sectorSelected = 'PRIVADO'
        } else {
            sectorSelected = 'PUBLICO'
        }

        // now we need to change the color of the div component
        let container = document.getElementById(sectorSelected);
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        let title = document.getElementById(sectorSelected + '_')
        title.style.color = 'white'

        // add the first sector
        setSuppliesSelected(sectors[0])

    }, [])

    const renderedSupplies = Object.values(sectors).map((item, index) => {
        return (
            <div className='col-6' style={{
                paddingLeft: `${index === 0 ? '0px' : '5px'}`,
                paddingRight: `${index === 0 ? '5px' : '0px'}`,
            }}>
                <Col xl="12"
                    key={item.id}
                    id={item.name}
                    className="form-check"
                    style={{
                        border: '1px solid #CDCDCD',
                        marginTop: `${index === 0 ? '20px' : '20px'}`,
                    }}
                >
                    <div className='d-flex justify-content-between '>
                        <div className='d-flex'>
                            <i style={{ fontSize: 25, marginRight: 10 }}>
                                {item.icon}
                            </i>
                            <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                                {item.name}
                            </h6>
                        </div>
                    </div>
                </Col>
            </div>
        )
    })

    return (
        <div>
            <div className="d-flex justify-content-between" style={{ marginTop: 15 }}>
                <h4 className="form-label"
                    style={{ fontWeight: 'bold' }}
                >
                    SECTOR
                </h4>
            </div>
            <AvForm className="needs-validation"  >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </div>
    )

}

export default SelectSectorAdmin;