import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";

import {
  BsFillPencilFill,
  BsFillXCircleFill,
  BsFillCheckCircleFill,
  BsFillPersonFill,
  BsEnvelopeFill,
  BsFillCheckSquareFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { changeStatus, updateDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
  { id: 1, name: "SIGLO 21" },
  { id: 2, name: "TECNOMEGA" },
  { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
  { id: 1, name: "TRADICIONAL" },
  { id: 2, name: "CONNECT" },
];

const ChannelDetails = ({ match, history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this variable will be used to set the channel selected
  const [channel, setChannel] = useState({});

  // this variable will control the if the admin can change the channel or no
  const [disabled, setDisabled] = useState(true);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/

  // this represent the id of the channel
  const [id, setId] = useState("");

  // this represent the data company
  const [razonSocial, setRazonSocial] = useState("");
  const [ruc, setRuc] = useState("");
  const [direccion, setDireccion] = useState("");

  // this represent the main contact
  const [nombreContacto, setNombreContacto] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [cargo, setCargo] = useState("");

  // this represent the legal represent
  const [nombreRepresentante, setNombreRepresentante] = useState("");
  const [celularRepresentante, setCelularRepresentante] = useState("");
  const [emailRepresentante, setEmailRepresentante] = useState("");

  // this represetnt the second contact
  const [nombreContacto2, setNombreContacto2] = useState("");
  const [celular2, setCelular2] = useState("");
  const [email2, setEmail2] = useState("");

  // this represent the type of channel
  const [tipoCanal, setTipoCanal] = useState("");

  // this represent the documents
  // this represent the documents
  const [acuerdoComercialDocumento, setAcuerdoComercialDocumento] =
    useState(null);
  const [acuerdoComercialBase64, setAcuerdoComercialBase64] = useState("");
  const [rucDocumento, setRucDocumento] = useState(null);
  const [rucBase64, setRucBase64] = useState("");

  // this represent the provider
  const [proveedor, setProveedor] = useState("");

  // this represent the observaciones
  const [observaciones, setObservaciones] = useState("");

  // this will be used to know the status the channel
  const [estado, setEstado] = useState("");

  // this will be used to save the channel before update
  const [channelBeforeUpdate, setChannelBeforeUpdate] = useState({});

  // this function will process the data result of method getProfileChannel
  // this function will be process the result of the method getListChannel
  const handleGetProfileChannelDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        setId(response.id);

        // set the data from the channel
        setRazonSocial(response.razonSocial);
        setRuc(response.ruc);
        setDireccion(response.direccionEmpresa);

        // info of the main contact
        setNombreContacto(response.contactoPrincipal.nombreContacto);
        setCelular(response.contactoPrincipal.celularContacto);
        setEmail(response.contactoPrincipal.emailContacto);
        setCargo(response.contactoPrincipal.cargoContacto);

        // info of the legal represent
        setNombreRepresentante(response.nombreRepresentante);

        // set the second contact
        setNombreContacto2(response.contactoSecundario.nombreContacto);
        setCelular2(response.contactoSecundario.celularContacto);
        setEmail2(response.contactoSecundario.emailContacto);

        // set the type of channel
        setTipoCanal(response.tipo);

        // set the documents

        // set the provider
        setProveedor(response.mayorista);
        setEstado(response.estado);

        setChannelBeforeUpdate({
          razonSocial: response.razonSocial,
          ruc: response.ruc,
          direccion: response.direccionEmpresa,
          nombreContacto: response.contactoPrincipal.nombreContacto,
          celular: response.contactoPrincipal.celularContacto,
          email: response.contactoPrincipal.emailContacto,
          cargo: response.contactoPrincipal.cargoContacto,
          nombreRepresentante: response.representateLegal.nombreRepresentante,
          celularRepresentante: response.representateLegal.celularRepresentante,
          emailRepresentante: response.representateLegal.emailRepresentante,
          tipoCanal: response.tipo,
          acuerdoComercialDocumento: response.acuerdoComercialDocumento,
          acuerdoComercialBase64: response.acuerdoComercialBase64,
          rucDocumento: response.rucDocumento,
          rucBase64: response.rucBase64,
          proveedor: response.mayorista,
          observaciones: response.observaciones,
          estado: response.estado,
        });

        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  // this function will be used to get profile of channel by ruc
  const getProfileChannel = () => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        const { id } = match.params;

        request(
          {
            url: `/auth/channel/list/ruc/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          handleGetProfileChannelDataResponse
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // create a fucntion to convert the base64 to file pdf  to
  //insert that in input type file whit the format FileList
  const convertBase64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getDocumetsDataProcess = (response) => {
    if (!response.status) {
      try {
        let option = localStorage.getItem("download-item");
        let name, path;
        if (option === "acuerdo") {
          path = response.documentos.acuerdoComercialDocumento;
          name = response.documentos.acuerdoComercialDocumento.split("/").pop();
        } else {
          path = response.documentos.rucDocumento;
          name = response.documentos.rucDocumento.split("/").pop();
        }

        let link = document.createElement("a");
        if(path.includes('data:application')){
          let yourDate = new Date()
          name = `${option}_${yourDate.toISOString().split('T')[0]}.pdf`;
          link.href = path;
        }else{
          link.href = `${process.env.REACT_APP_S3_URL}/${path}`;
        }
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDocuments = () => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        const { id } = match.params;

        request(
          {
            url: `/auth/channel/list/documentos/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          getDocumetsDataProcess
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // i have different question here
    getProfileChannel();
    // these function will be used to get all documents of the channel
    //getDocuments();
  }, []);

  const convertToBase64AcuerdoComercial = (file) => {
    try {
      // console.log('file', file);
      //console.log('f', f);

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      //console.log(reader)
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.log('ddddddd')
          //console.timeLog(reader)
          base64 = reader?.result;
          //console.log(base64);
          setAcuerdoComercialBase64(base64);
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const convertToBase64Ruc = (file) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      //console.log(reader)
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.log('eeeee')
          //console.timeLog(reader)
          base64 = reader?.result;
          //console.log(base64);
          setRucBase64(base64);
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const cancelBeforeUpdate = () => {
    setRazonSocial(channelBeforeUpdate.razonSocial);
    setRuc(channelBeforeUpdate.ruc);
    setDireccion(channelBeforeUpdate.direccion);
    setNombreContacto(channelBeforeUpdate.nombreContacto);
    setCelular(channelBeforeUpdate.celular);
    setEmail(channelBeforeUpdate.email);
    setCargo(channelBeforeUpdate.cargo);
    setNombreRepresentante(channelBeforeUpdate.nombreRepresentante);
    setCelularRepresentante(channelBeforeUpdate.celularRepresentante);
    setEmailRepresentante(channelBeforeUpdate.emailRepresentante);
    setChannel(channelBeforeUpdate.tipoCanal);
    //setAcuerdoComercialDocumento(channelBeforeUpdate.acuerdoComercialDocumento)
    //setAcuerdoComercialBase64(channelBeforeUpdate.acuerdoComercialBase64)
    //setRucDocumento(channelBeforeUpdate.rucDocumento)
    //setRucBase64(channelBeforeUpdate.rucBase64)
    setProveedor(channelBeforeUpdate.proveedor);
    setObservaciones(channelBeforeUpdate.observaciones);
    setEstado(channelBeforeUpdate.estado);

    setDisabled(true);
  };

  const updateChannelDataResponse = (response) => {
    if (response.status) {
      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      if (rucBase64 || acuerdoComercialBase64) {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        let formData = new FormData();
        if (rucBase64) formData.append("rucDocumentoFile", rucBase64, rucBase64.name);
        if (acuerdoComercialBase64)
          formData.append(
            "acuerdoComercialFile",
            acuerdoComercialBase64,
            acuerdoComercialBase64.name
          );

        request(
          {
            url: `/auth/channel/upload/${ruc}`,
            method: "POST",
            headers: myHeaders,
            body: formData,
          },
          () => history.push(`/channel-edited`)
        );
      } else {
        history.push("/channel-edited");
      }
    }
  };

  const handleSubmit = () => {
    try {
      let firtDocument = acuerdoComercialBase64;
      let secondDocument = rucBase64;

      if (
        acuerdoComercialBase64 === "" ||
        acuerdoComercialBase64 === undefined
      ) {
        firtDocument = channelBeforeUpdate.acuerdoComercialDocumento;
      }

      if (rucBase64 === "" || rucBase64 === undefined) {
        secondDocument = channelBeforeUpdate.rucDocumento;
      }

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/channel/edit`,
          method: "PUT",
          headers: myHeaders,
          body: {
            id: id,
            razonSocial: razonSocial,
            ruc: ruc,
            direccionEmpresa: direccion,
            contactoPrincipal: {
              nombreContacto: nombreContacto,
              celularContacto: celular,
              emailContacto: email,
              cargoContacto: cargo,
            },
            contactoSecundario: {
              nombreContacto: nombreContacto2,
              celularContacto: celular2,
              emailContacto: email2,
            },
            nombreRepresentante: nombreRepresentante,
            tipo: tipoCanal,
            mayorista: proveedor,
            observaciones: observaciones,
          },
        },
        updateChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const onHandleOpenAcuerdoComercialDocument = (option) => {
    localStorage.setItem("download-item", option);
    getDocuments();
  };

  const onHandleOpenRucDocument = () => {
    const blob = base64toBlob(rucBase64);
    window.open(URL.createObjectURL(blob));
  };

  const changeStatusChannelDataResponse = (res) => {
    getProfileChannel();
    return;
  };

  const onHanleChangeSatusChannel = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    request(
      {
        url: `/auth/channel/batch`,
        method: "PUT",
        headers: myHeaders,
        body: {
          rucs: [ruc],
          activo: estado === "ACTIVO" ? false : true,
        },
      },
      changeStatusChannelDataResponse
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                  >
                    <h4 className="card-title">DATOS EMPRESA</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom01"
                          >
                            Razón social
                          </Label>
                          <AvField
                            name="razonSocial"
                            type="text"
                            errorMessage="Ingrese la razón social"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                            value={razonSocial}
                            disabled={disabled}
                            onChange={(e) => setRazonSocial(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom02"
                          >
                            RUC Empresa
                          </Label>
                          <AvField
                            name="ruc"
                            type="number"
                            errorMessage="Ingrese el RUC"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 13,
                                errorMessage:
                                  "El ruc debe contener 13 caracteres numéricos ",
                              },
                              maxLength: {
                                value: 13,
                                errorMessage:
                                  "El ruc debe contener 13 caracteres numéricos ",
                              },
                            }}
                            id="validationCustom02"
                            value={ruc}
                            disabled={disabled}
                            onChange={(e) => setRuc(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom03"
                          >
                            Dirección Empresa
                          </Label>
                          <AvField
                            name="direccion"
                            type="text"
                            errorMessage="Ingrese la dirección"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={direccion}
                            disabled={disabled}
                            onChange={(e) => setDireccion(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6" className="d-flex flex-row ">
                        <Label
                          className="form-label"
                          htmlFor="validationCustom98"
                        >
                          Estado Canal
                        </Label>
                        <AvField
                          name="status"
                          type="text"
                          errorMessage="Ingrese la dirección"
                          className="form-control container-fluid"
                          id="validationCustom98"
                          value={estado}
                          disabled
                        />
                        <div>
                          <button
                            className={`btn ${
                              estado === "ACTIVO" ? "btn-primary" : "btn-danger"
                            }`}
                            onClick={() => onHanleChangeSatusChannel()}
                            type="button"
                          >
                            {estado === "ACTIVO" ? (
                              <AiFillCheckCircle />
                            ) : (
                              <AiFillCloseCircle />
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">CONTACTO PRINCIPAL</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom04"
                          >
                            Nombre Principal
                          </Label>
                          <AvField
                            name="nombreContacto"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={nombreContacto}
                            disabled={disabled}
                            onChange={(e) => setNombreContacto(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom05"
                          >
                            Celular Principal
                          </Label>
                          <AvField
                            name="celular"
                            type="number"
                            errorMessage="Ingrese el celular del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={celular}
                            disabled={disabled}
                            onChange={(e) => setCelular(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom06"
                          >
                            Email Principal
                          </Label>
                          <AvField
                            name="email"
                            type="email"
                            errorMessage="Ingrese el email del contacto principal"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom06"
                            value={email}
                            disabled={disabled}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom07"
                          >
                            Cargo Principal
                          </Label>
                          <AvField
                            name="cargo"
                            type="text"
                            errorMessage="Ingrese el cargo del contacto principal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom07"
                            value={cargo}
                            disabled={disabled}
                            onChange={(e) => setCargo(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">CONTACTO SECUNDARIO</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom20"
                          >
                            Nombre Secundario
                          </Label>
                          <AvField
                            name="nombreContacto2"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto secundario"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom20"
                            value={nombreContacto2}
                            onChange={(e) => setNombreContacto2(e.target.value)}
                            disabled={disabled}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Celular Secundario
                          </Label>
                          <AvField
                            name="celular2"
                            type="number"
                            errorMessage="Ingrese el celular del contacto secundario"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom21"
                            value={celular2}
                            onChange={(e) => setCelular2(e.target.value)}
                            disabled={disabled}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom22"
                          >
                            Email Secundario
                          </Label>
                          <AvField
                            name="email2"
                            type="email"
                            errorMessage="Ingrese el email del contacto secundario"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom22"
                            value={email2}
                            onChange={(e) => setEmail2(e.target.value)}
                            disabled={disabled}
                          />
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">REPRESENTANTE LEGAL</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom08"
                          >
                            Nombre Representante
                          </Label>
                          <AvField
                            name="nombreRepresentante"
                            type="text"
                            errorMessage="Ingrese el nombre del representante legal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom08"
                            value={nombreRepresentante}
                            onChange={(e) =>
                              setNombreRepresentante(e.target.value)
                            }
                            disabled={disabled}
                          />
                        </div>
                      </Col>
                      {/*}
                                            <Col md="6">
                                                <div className="mb-3 d-flex">
                                                    <Label className="form-label" htmlFor="validationCustom9">Celular Representante</Label>
                                                    <AvField
                                                        name="celularRepresentante"
                                                        type="number"
                                                        errorMessage="Ingrese el celular del representante legal"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom9"
                                                        value={celularRepresentante}
                                                        onChange={(e) => setCelularRepresentante(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3 d-flex">
                                                    <Label className="form-label" htmlFor="validationCustom10">Email Representante</Label>
                                                    <AvField
                                                        name="emailRepresentante"
                                                        type="email"
                                                        errorMessage=" Ingrese el email del representante legal"
                                                        className="form-control"
                                                        validate={{ required: { value: true }, email: { value: true } }}
                                                        id="validationCustom10"
                                                        value={emailRepresentante}
                                                        onChange={(e) => setEmailRepresentante(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            */}
                    </Row>

                    <Row>
                      <Col md="6" sm={12}>
                        <h4 className="card-title">TIPO CANAL</h4>
                        <div className="mb-3 d-flex col-12">
                          <Col md={12} sm={12} style={{ width: "100%" }}>
                            <select
                              className="form-control"
                              disabled={disabled}
                              value={tipoCanal}
                              onChange={(e) => {
                                setTipoCanal(e.target.value);
                              }}
                            >
                              {Object.values(typesChannel).map(
                                (typeChannel) => (
                                  <option
                                    key={typeChannel.id}
                                    value={typeChannel.name}
                                  >
                                    {typeChannel.name}
                                  </option>
                                )
                              )}
                            </select>
                          </Col>
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <h4 className="card-title">SELECCIONE MAYORISTA</h4>
                        <div className="mb-3 d-flex">
                          <Col md={12} style={{ width: "100%" }}>
                            <select
                              className="form-control "
                              disabled={disabled}
                              value={proveedor}
                              onChange={(e) => {
                                setProveedor(e.target.value);
                              }}
                            >
                              {Object.values(providers).map((provider) => (
                                <option key={provider.id} value={provider.name}>
                                  {provider.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <h4 className="card-title">DOCUMENTOS</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom11"
                          >
                            Acuerdo Comercial
                          </Label>
                          <AvField
                            title="Acueredo Comercial"
                            accept="application/pdf"
                            name="acuerdoComercialDocumento"
                            type="file"
                            errorMessage="Ingrese el documento del acuerdo comercial"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom11"
                            value={acuerdoComercialDocumento}
                            disabled={disabled}
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];
                              setAcuerdoComercialBase64(f);
                              //convertToBase64AcuerdoComercial(e.target.files, f)

                              //setAcuerdoComercialDocumento(e.target)
                              /*
                                                            setAcuerdoComercialDocumento(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                          {disabled && (
                            <div style={{ marginLeft: 10 }}>
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() =>
                                  onHandleOpenAcuerdoComercialDocument(
                                    "acuerdo"
                                  )
                                }
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#3af23a",
                                }}
                              >
                                <div
                                  className="icon-eyes"
                                  style={{ margin: "auto auto" }}
                                >
                                  {" "}
                                  <BsFillArrowDownCircleFill />
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom12"
                          >
                            Documento RUC
                          </Label>
                          <AvField
                            title="Documento RUC"
                            accept="application/pdf"
                            name="rucDocumento"
                            type="file"
                            errorMessage="Ingrese el documento del RUC"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom12"
                            value={rucDocumento}
                            disabled={disabled}
                            onChange={(e) => {
                              const f = e.target.files[0];
                              setRucBase64(f);
                              //convertToBase64Ruc(e.target.files)
                              //setRucDocumento(e.target.files)
                            }}
                          />

                          {disabled && (
                            <div style={{ marginLeft: 10 }}>
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() =>
                                  onHandleOpenAcuerdoComercialDocument("ruc")
                                }
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#3af23a",
                                }}
                              >
                                <div
                                  className="icon-eyes"
                                  style={{ margin: "auto auto" }}
                                >
                                  {" "}
                                  <BsFillArrowDownCircleFill />
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <h4 className="card-title">OBSERVACIONES</h4>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <AvField
                            name="text"
                            type="textarea"
                            className="form-control"
                            value={observaciones}
                            disabled={disabled}
                            onChange={(e) => setObservaciones(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    {disabled ? (
                      <Button
                        className="btn btn-primary-lexmark"
                        type="button"
                        onClick={() => {
                          setDisabled(false);
                        }}
                      >
                        Editar
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="btn btn-primary-danger mx-2"
                          type="button"
                          onClick={cancelBeforeUpdate}
                        >
                          Cancelar
                        </Button>

                        <Button
                          className="btn btn-primary-lexmark"
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChannelDetails;
