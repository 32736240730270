import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Spinner } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { BsBarChartSteps, BsEye, BsFillCalendarCheckFill, BsFillCaretDownFill, BsFillCaretUpFill, BsFillCursorFill, BsFillEjectFill, BsFillEnvelopeFill, BsFillFileEarmarkTextFill, BsFillGrid1X2Fill, BsFillGrid3X3GapFill, BsFillPatchCheckFill, BsFillPencilFill, BsFillPersonCheckFill, BsFillStopCircleFill, BsFillTelephoneForwardFill, BsFillVinylFill, BsFillXCircleFill, BsFillXDiamondFill, BsHandThumbsUp, BsHandThumbsUpFill, BsInfoCircleFill, BsPatchCheckFill, BsReverseLayoutTextSidebarReverse } from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Button } from 'react-bootstrap';

import useHttp from "../../hook/use-http";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { result } from "lodash";


const OpportunityGroupDetails = ({ history, match }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)


    // this variable will be save the opportunity selected
    const [opportunitySelected, setOpportunitySelected] = useState('')
    // this variable will be save the reason the opportunity is selected
    const [reasonSelected, setReasonSelected] = useState('')
    // this variable contain the name of the group that we wanna create
    const [opportunitiesCodes, setOpportunityCodes] = useState([])

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [productData, setProductData] = useState([]);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();
    const [opportunitySelectedToDelete, setOpportunitySelectedToDelete] = useState(null)


    // this variable and function will be used to control the modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setOpportunitySelectedToDelete(null)
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow(false);
    const handleShow2 = () => setShow(true);



    // this function will be process the result of the method getListChannel
    const handleGetListChannelDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here

            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            // this variable will save all groups 
            const result = [];
            // this variable will save the opportunities codes
            const codes = [];

            // add and extra field to products
            products.map((item, index) => {

                // this is only id 
                item.id = index + 1

                // this variable will save the code of the group in the database
                item.code = item.codigo

                // this variable will save the name of the group
                item.group_name = item.codigoGrupo

                // this variable contain an array with all opportunities
                item.opportunities = item.oportunidades

                // this variable represent the maximum element in a group
                item.counter = item.opportunities?.length

                Object.values(item.opportunities).forEach(opp => {
                    // add into codes array the group_name the opportunity
                    codes.push({
                        id: opp.codigo,
                        name: opp.codigo,
                        group_code: item.code,
                        canal: opp.canal?.razonSocial,
                        descripcion: opp.descripcion
                    })
                })

                // finally save the element into an array with all groups
                result.push(item)
            });

            setOpportunityCodes(codes)
            setOpportunitySelected(codes[0]?.id)
            setProductData(result);
        }
    }

    // this function will be used to request the data from the server
    const getListChannel = () => {

        const { id } = match.params

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/oportunidad/list/group?codigoGrupo=${id}`,
                method: 'GET',
                headers: myHeaders
            }, handleGetListChannelDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    const approveOpportunityDataResponse = (response) => {
        if (response?.status) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

        } else {
            history.push("/opportunity-group-approve")
        }
    }

    const handleApproveOpportunity = () => {
        try {

            const elementSelected = opportunitiesCodes.find(opp => {
                return opp.id === opportunitySelected
            })

            if (!elementSelected) {
                return;
            }

            handleClose()

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/oportunidad/group/batch`,
                method: 'PUT',
                headers: myHeaders,
                body: {
                    "codigo": elementSelected?.group_code,
                    "oportunidad_aprobada": elementSelected?.id,
                    "detalleAprobacion": reasonSelected
                }
            }, approveOpportunityDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    const dateInNewFormat = (date) => {
        let d = new Date(date)
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        return dformat;
    }

    const deleteOpportunityIntoGroupDataResponse = (response) => {

        if (opportunitiesCodes?.length === 1) {
            history.push('/opportunity-list-admin')
            toast('Se ha desasociado la oportunidad del grupo eficientemente', { type: 'success', autoClose: 60000 });
            return;
        }

        if (response.status) {
            // show a error message here

            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            setOpportunitySelectedToDelete(null)
            getListChannel();
        }
    }

    // that will focus to delete one opportunity inside a group by code
    const deleteOpportunityIntoGroup = () => {

        
        const opportunity = opportunitySelectedToDelete

        let setInNewFormatOpportunities = Object.values(opportunitiesCodes).filter(op => {
            return op.id !== opportunity
        })

        setInNewFormatOpportunities = Object.values(setInNewFormatOpportunities).map(op => {
            return { "codigo": op.id }
        })

        //console.log(setInNewFormatOpportunities)
        //console.log(productData[0]?.codigoGrupo)

        handleClose()

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        request({
            // /auth/oportunidad/list/group/all
            url: `/auth/oportunidad/group/edit`,
            method: 'PUT',
            headers: myHeaders,
            body: {
                "codigo": productData[0]?.codigo,
                "oportunidades": setInNewFormatOpportunities
            }
        }, deleteOpportunityIntoGroupDataResponse)
    }

    useEffect(() => {
        getListChannel();
    }, [])

    const renderedOpportunities = productData.length > 0 ? Object.values(productData[0]?.oportunidades).map(element => {
        return (
            <>
                <Col xl="12" style={{ width: '100%' }} id="datos" className="mb-3" >
                    <Card style={{ height: '100%' }} >
                        <CardBody>
                            <AvForm className="needs-validation" >
                                <Row className="mb-3">
                                    <Col xl="12" style={{ width: '100%' }} id="datos"
                                        className="d-flex flex-row justify-content-between"
                                    >
                                        <h6 style={{ fontWeight: 'bold' }}>
                                            OPORTUNIDAD {element?.codigo}
                                        </h6>
                                        <div>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    //deleteOpportunityIntoGroup(element?.codigo)
                                                    setOpportunitySelectedToDelete(element?.codigo)
                                                    handleShow2()
                                                }}
                                            >
                                                -
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    className="d-flex justify-content-center align-content-center align-items-center flex-wrap mx-5 border-bottom border-light">

                                    <Col xl="4"
                                        className="d-flex flex-columns "
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >

                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                TIPO DE OPORTUNIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.tipo}
                                            </h6>
                                        </div>
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                CANAL:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.canal?.razonSocial}
                                            </h6>
                                        </div>

                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                ENTIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.informacionEntidad?.entidad}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xl="4"
                                        className="d-flex flex-column align-items-center"
                                    >
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                NOMBRE CONTACTO EN LA ENTIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {(element.informacionEntidad?.nombreContacto)?.toUpperCase()}
                                            </h6>
                                        </div>
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                EMAIL DE LA ENTIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {(element.informacionEntidad?.email)?.toUpperCase()}
                                            </h6>
                                        </div>
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                TELEFONO DE LA ENTIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.informacionEntidad?.telefono}
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xl="4"
                                        className="d-flex flex-column align-items-end"
                                    >
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                CREACIÓN:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {dateInNewFormat(element?.createdDate)}
                                            </h6>
                                        </div>
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                PRESUPUESTO:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.presupuesto + ' $'}
                                            </h6>
                                        </div>
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                ESTADO:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.estado}
                                            </h6>
                                        </div>

                                    </Col>
                                </Row>
                                <Row className="mx-5">
                                    <Col xl="12" >
                                        <div className="d-flex my-3">
                                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>
                                                DESCRIPCIÓN DEL TRABAJO REALIZADO EN LA ENTIDAD:{' '}
                                            </h6>
                                            <h6 style={{ margin: 0 }} classNamemt>
                                                {element.descripcion}
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>

                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }) : [];

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    {loading ? (
                        <Row>
                            <Col xl="12">
                                <Card style={{ height: '100%' }} >
                                    <CardBody>
                                        <AvForm className="needs-validation" >
                                            <Row className="mb-3">
                                                <Col xl="12" style={{ width: '100%', display: 'flex', justifyContent: 'center' }} id="datos"
                                                >
                                                    <Spinner animation="border" variant="success" />
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) :
                        <>
                            <Row className="my-3">
                                <Col xl="12" style={{ width: '100%' }}
                                    className="d-flex flex-row justify-content-between"
                                >
                                    <h3 style={{ fontWeight: 'bold' }}>
                                        GRUPO {productData[0]?.codigoGrupo}
                                    </h3>
                                    <div>
                                        {/* 
                                        <button
                                            className="btn btn-info mr-3"
                                            style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}
                                        >
                                            <div className="icon-eyes" >
                                                <BsPatchCheckFill />
                                            </div>
                                        </button>
                                        */}
                                        <Button
                                            variant="primary"
                                            onClick={handleShow}
                                            className="mr-3 d-flex justify-content-center align-items-center"
                                        >
                                            <BsPatchCheckFill className="mx-2" />
                                            Aprobar
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {renderedOpportunities}
                            </Row>
                        </>
                    }
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {opportunitySelectedToDelete !== null ? 'DESASOCIAR OPORTUNIDAD' : 'APROBAR OPORTUNIDAD'}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {opportunitySelectedToDelete !== null ?
                        (
                            <div style={{ fontWeight: 'bold' }}>
                                ¿Estas seguro que deseas descartar esta oportunidad ?
                            </div>
                        ) : (
                            <AvForm className="needs-validation" onValidSubmit={(error, values) => handleApproveOpportunity(error, values)}  >
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3 ">
                                            <Label className="form-label" htmlFor="validationCustom01">Por favor seleccione la oportunidad que desea aprobar</Label>

                                            <select className="form-control" value={opportunitySelected} onChange={
                                                (e) => {
                                                    let code = e.target.value
                                                    setOpportunitySelected(code)
                                                }
                                            } >
                                                {Object.values(opportunitiesCodes).map(item => (
                                                    <option key={item.id} value={item.name}>{item.canal} - {item.name} - {item?.descripcion.length > 70 ? item?.descripcion.slice(0, 70) + '...' : item?.descripcion}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3 ">
                                            <Label className="form-label" htmlFor="validationCustom12">Motivo de aprobación</Label>
                                            <AvField
                                                accept="text"
                                                name="motivo"
                                                type="text"
                                                errorMessage="Ingrese el motivo de aprobación"
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                    minLength: { value: 5, errorMessage: 'El motivo de aprobación debe tener al menos 5 caracteres' },
                                                }}
                                                id="validationCustom12"
                                                value={reasonSelected}
                                                onChange={(e) => {
                                                    setReasonSelected(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Modal.Footer className="p-0">
                                    <Button variant="secondary"
                                        onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={handleApproveOpportunity}
                                    >
                                        Aprobar
                                    </Button>
                                </Modal.Footer>
                            </AvForm>
                        )}
                </Modal.Body>

                {opportunitySelectedToDelete !== null && (
                    <Modal.Footer className="p-0 mb-3 mx-3">
                        <Button variant="secondary"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button
                            variant="danger"
                            type="button"
                            onClick={deleteOpportunityIntoGroup}
                        >
                            Desasociar
                        </Button>
                    </Modal.Footer>
                )}

            </Modal>

        </React.Fragment >
    )
}

export default OpportunityGroupDetails;