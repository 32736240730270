import { AvForm } from 'availity-reactstrap-validation';
import React, { useState, useEffect } from 'react'
import { BsCursor, BsMinecartLoaded, BsPencilSquare, BsPhone, BsRecord, BsRecycle } from 'react-icons/bs';
import { Col, Row } from 'reactstrap';

// this variable will represent different supplies
const sectors = [
    { id: 1, name: 'PUBLICO', title: 'PUBLICO_', icon: <BsMinecartLoaded /> },
    { id: 2, name: 'PRIVADO', title: 'PRIVADO_', icon: <BsPhone /> },
]

const SelectSectorChannel = ({ handleChangeView }) => {

    const [suppliesSelected, setSuppliesSelected] = useState({});

    useEffect(() => {

        // now we need to change the color of the div component
        let container = document.getElementById('PUBLICO');
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        let title = document.getElementById('PUBLICO_')
        title.style.color = 'white'

        // add the first sector
        setSuppliesSelected(sectors[0])

    }, [])

    // this function will be used to add or not supplies into list
    const addSuppliesToList = (selected) => {

        const lastSupply = suppliesSelected
        console.log(lastSupply)

        // change color the element selected
        let container = document.getElementById(selected.name);
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        let title = document.getElementById(selected.title)
        title.style.color = 'white'

        // change color the last sector selected
        container = document.getElementById(lastSupply.name);
        container.style.backgroundColor = 'white'
        container.style.color = '#433A40'

        title = document.getElementById(lastSupply.title)
        title.style.color = '#433A40'

        // add the new sector to list
        setSuppliesSelected(selected)

        // if this view is public we need to pass the value to component father
        if (selected.name === 'PUBLICO') {
            handleChangeView(true)
        } else {
            handleChangeView(false)
        }

    }

    const renderedSupplies = Object.values(sectors).map((item, index) => {
        return (
            <div className='col-6' style={{
                paddingLeft: `${index === 0 ? '0px' : '5px'}`,
                paddingRight: `${index === 0 ? '5px' : '0px'}`,
            }}>
                <Col xl="12"
                    key={item.id}
                    id={item.name}
                    className="form-check"
                    style={{
                        border: '1px solid #CDCDCD',
                        marginTop: `${index === 0 ? '20px' : '20px'}`,
                    }}
                    onClick={() => addSuppliesToList(item)}
                >
                    <div className='d-flex justify-content-between '>
                        <div className='d-flex'>
                            <i style={{ fontSize: 25, marginRight: 10 }}>
                                {item.icon}
                            </i>
                            <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                                {item.name}
                            </h6>
                        </div>
                    </div>
                </Col>
            </div>
        )
    })

    return (
        <div>
            <div className="d-flex justify-content-between" style={{ marginTop: 15 }}>
                <h4 className="form-label"
                    style={{ fontWeight: 'bold' }}
                >
                    SECTOR
                </h4>
            </div>
            <AvForm className="needs-validation"  >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </div>
    )

}

export default SelectSectorChannel;