import React from 'react'
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

const EntityEdited = ({ history }) => {

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={4}>
                            <Card outline color="success" className="border">
                                <CardHeader className="bg-transparent">
                                    <h5 className="my-0 text-success"><i className="mdi mdi-check-all me-3"></i>ENTIDAD EDITADO CON EXITO</h5>
                                </CardHeader>
                                <CardBody>
                                    {/*
                                    <CardTitle className="h4">card title</CardTitle>
                                     */}
                                    <CardText>En este momento su entidad se ha editado con exito, por favor regrese a la lista de entidades.</CardText>

                                    <Button
                                        className='btn btn-primary-lexmark'
                                        onClick={() => history.push('/entity-list')}
                                    >Lista Entidades</Button>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default EntityEdited;