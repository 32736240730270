import React, { useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { BsBarChartSteps, BsCalendarEvent, BsCursor, BsDashSquare, BsFillCursorFill, BsNewspaper, BsPatchCheck, BsPerson, BsPersonCircle } from 'react-icons/bs';
import { Col, Row } from 'reactstrap';

/* This array represent different provider */
const providers = [
    { id: 1, name: "ELECTRONICA SIGLO XXI ELECTROSIGLO S.A." },
    { id: 2, name: "TECNOMEGA C.A." },
    { id: 3, name: "MEGAMICRO S.A." },
];


const PrivateSector = ({ handleChangeDataProcess, onHandleDataProcess, dataProcess, mayorista }) => {

    // this represent the provider
    const [proveedor, setProveedor] = useState(mayorista ? mayorista : 'ELECTRONICA SIGLO XXI ELECTROSIGLO S.A.');

    // this variable will be used to create a data of process
    const [nombreEmpresa, setNombreEmpresa] = useState(dataProcess.nombreEmpresa ? dataProcess.nombreEmpresa : '');
    const [dirijido, setDirijido] = useState(dataProcess.dirigido ? dataProcess.dirigido : '');
    const [cargo, setCargo] = useState(dataProcess.cargo ? dataProcess.cargo : '');

    useEffect(() => {

        if (nombreEmpresa.trim() !== '' && dirijido.trim() !== '' && cargo.trim() !== '') {
            handleChangeDataProcess({
                "nombreEmpresa": nombreEmpresa,
                "dirigido": dirijido,
                "cargo": cargo
            })
        }

    }, [nombreEmpresa, dirijido, cargo, proveedor])

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    DATOS DEL PROCESO - SECTOR PRIVADO
                </h4>
            </div>
            <AvForm className="needs-validation" onValidSubmit={onHandleDataProcess} >
                <Row style={{ marginTop: 20 }}>
                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    NOMBRE EMPRESA
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsPerson />
                                </i>
                                <AvField
                                    name="nombre_empresa"
                                    type="text"
                                    errorMessage="Ingrese el nombre de la empresa"
                                    value={nombreEmpresa}
                                    onChange={(e) => setNombreEmpresa(e.target.value)}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="nombre_empresa"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    DIRIGIDA A
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsCursor />
                                </i>
                                <AvField
                                    name="dirigido"
                                    type="text"
                                    errorMessage="Ingrese el nombre del destinatario"
                                    value={dirijido}
                                    onChange={(e) => setDirijido(e.target.value)}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="dirigido"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    CARGO
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsDashSquare />
                                </i>
                                <AvField
                                    name="cargo"
                                    type="text"
                                    errorMessage="Ingrese el cargo"
                                    value={cargo}
                                    onChange={(e) => setCargo(e.target.value)}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="cargo"
                                />
                            </div>
                        </div>

                    </Col>
                    <Col md="6" sm="12">
                        <h4 className="card-title">SELECCIONE MAYORISTA</h4>
                        <div className="mb-3 d-flex">
                            <Col md={12} style={{ width: '100%' }}>
                                <select className="form-control" value={proveedor} onChange={
                                    (e) => {
                                        setProveedor(e.target.value)
                                    }
                                }>
                                    {Object.values(providers).map(provider => (
                                        <option key={provider.id} value={provider.name}>{provider.name}</option>
                                    ))}
                                </select>
                            </Col>
                        </div>
                    </Col>

                </Row>

            </AvForm>

        </>
    )
}

export default PrivateSector;