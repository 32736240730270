import React, { useCallback, useState } from "react";

const useHttp = () => {

    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // let url = process.env.REACT_APP_API_URL || "http://localhost:3001";

    const request = useCallback(async (requestConfig, applyData) => {
        let url = process.env.REACT_APP_API_URL + requestConfig.url;
        setIsLoading(true)
        setError(null)
        try {
            const response = await fetch(
                url,
                {
                    method: requestConfig.method ? requestConfig.method : 'GET',
                    headers: requestConfig.headers ? requestConfig.headers : {},
                    body: requestConfig.body ? ( requestConfig.body instanceof FormData ? requestConfig.body : JSON.stringify(requestConfig.body)) : null
                }
            )
            /*
            if (!response.ok) {
                throw new Error('Request failed!');
            }
            */
            try {

                if (requestConfig.url.includes('/auth/certificado/download/')) {
                    applyData(response)
                } else {
                    const data = await response.json();
                    applyData(data)
                }


            } catch (er) {
                applyData(null)
            }


        } catch (error) {
            console.log(error)
            setError(error.message || 'Something went wrong!')
        }
        setIsLoading(false)
    }, [])

    return {
        loading,
        error,
        request
    }

}

export default useHttp;