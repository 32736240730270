import * as types from './actionTypes'

const initialState = {
    profile: {},
    isLoading: false,
    error: null
}

export const profileReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: payload,
            }
        case types.GET_PROFILE_FAILURE:

            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('email')
            localStorage.removeItem('role')

            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}