import * as types from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects';

function api() {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    const url = process.env.REACT_APP_API_URL + "/auth/channel/list/user";

    return fetch(url, {
        method: 'GET',
        headers: myHeaders,
    }).then(response => response.json())
        .catch(error => { throw (error) });
}

function* getProfile() {
    try {
        const response = yield call(api)

        yield put({ type: types.GET_PROFILE_SUCCESS, payload: response });

    } catch (error) {
        yield put({ type: types.GET_PROFILE_FAILURE, payload: error });
    }
}

function* profileSaga() {
    yield takeEvery(types.GET_PROFILE_REQUEST, getProfile);
}

export default profileSaga;