import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  BsApp,
  BsArrowRight,
  BsCalendar2Date,
  BsCalendar3Event,
  BsCalendar4Event,
  BsCalendarMinus,
  BsCalendarRange,
  BsCursor,
  BsEnvelope,
  BsPerson,
} from "react-icons/bs";
import { Col, Input, Row } from "reactstrap";

// this variable will be used to list all types contraction

const tipos_contratacion = [
  { id: 1, name: "SUBASTA INVERSA ELECTRÓNICA" },
  { id: 2, name: "LICITACIÓN" },
  { id: 2, name: "ÍNFIMA CUANTÍA" },
  { id: 4, name: "COTIZACIÓN" },
  { id: 3, name: "CATÁLOGO ELECTRÓNICO" },
];

/* This array represent different provider */
const providers = [
  { id: 1, name: "ELECTRONICA SIGLO XXI ELECTROSIGLO S.A." },
  { id: 2, name: "TECNOMEGA C.A." },
  { id: 3, name: "MEGAMICRO S.A." },
];

// this function will be uses to show form about public sector
const PublicSector = ({ handleChangeDataProcess, dataProcess, mayorista }) => {
  const [tipoContratacion, setTipoContratacion] = useState(
    dataProcess.tipoContratacion
      ? dataProcess.tipoContratacion
      : "SUBASTA INVERSA ELECTRÓNICA"
  );

  // this represent the provider
  const [proveedor, setProveedor] = useState(
    mayorista ? mayorista : "ELECTRONICA SIGLO XXI ELECTROSIGLO S.A."
  );

  // this variable will be used to create a data of process
  const [entidadContratante, setEntidadContratante] = useState(
    dataProcess.entidadContratante ? dataProcess.entidadContratante : ""
  );
  const [emailEncargado, setEmailEncargado] = useState(
    dataProcess.emailFuncionario ? dataProcess.emailFuncionario : ""
  );
  const [objectoDelProceso, setObjectoDelProceso] = useState(
    dataProcess.objetoProceso ? dataProcess.objetoProceso : ""
  );
  const [codigoProceso, setCodigoProceso] = useState(
    dataProcess.codigoProceso ? dataProcess.codigoProceso : ""
  );
  const [fechaLimitePregunta, setFechaLimitePregunta] = useState(
    dataProcess.fechaLimitePreguntas ? dataProcess.fechaLimitePreguntas : ""
  );
  const [fechaLimiteRespuesta, setFechaLimiteRespuesta] = useState(
    dataProcess.fechaLimiteRespuestas ? dataProcess.fechaLimiteRespuestas : ""
  );
  const [fechaLimiteEntregaOferta, setFechaLimiteEntregaOferta] = useState(
    dataProcess.fechaLimiteEntregaOferta
      ? dataProcess.fechaLimiteEntregaOferta
      : ""
  );
  const [fechaLimiteCalificacion, setFechaLimiteCalificacion] = useState(
    dataProcess.fechaLimiteCalificacion
      ? dataProcess.fechaLimiteCalificacion
      : ""
  );
  const [fechaInicioPuja, setFechaInicioPuja] = useState(
    dataProcess.fechaInicioPuja ? dataProcess.fechaInicioPuja : ""
  );
  const [plazoEntrega, setPlazoEntrega] = useState(
    dataProcess.plazoEntrega ? dataProcess.plazoEntrega : ""
  );

  const getTodayDate = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return year + "-" + month + "-" + day + "T" + hour + ":" + minutes;
  };

  const [isParamsData, setIsParamsData] = useState(false);

  useEffect(() => {
    if (dataProcess.tipoContratacion && !isParamsData) {
      setEntidadContratante(dataProcess.entidadContratante);
      setEmailEncargado(dataProcess.emailFuncionario);
      setObjectoDelProceso(dataProcess.objetoProceso);
      setCodigoProceso(dataProcess.codigoProceso);
      setFechaLimitePregunta(dataProcess.fechaLimitePreguntas);
      setFechaLimiteRespuesta(dataProcess.fechaLimiteRespuestas);
      setFechaLimiteEntregaOferta(dataProcess.fechaLimiteEntregaOferta);
      setFechaLimiteCalificacion(dataProcess.fechaLimiteCalificacion);
      setFechaInicioPuja(dataProcess.fechaInicioPuja);
      setPlazoEntrega(dataProcess.plazoEntrega);
      setTipoContratacion(dataProcess.tipoContratacion);
      setIsParamsData(true);
      setProveedor(mayorista);
    }
  });

  useEffect(() => {
    if (
      entidadContratante.trim() !== "" &&
      emailEncargado.trim() !== "" &&
      objectoDelProceso.trim() !== "" &&
      codigoProceso.trim() !== "" &&
      fechaLimitePregunta.trim() !== "" &&
      fechaLimiteRespuesta.trim() !== "" &&
      fechaLimiteEntregaOferta.trim() !== "" &&
      fechaLimiteCalificacion.trim() !== "" &&
      fechaInicioPuja.trim() !== "" &&
      plazoEntrega.trim() !== ""
    ) {
      handleChangeDataProcess({
        entidadContratante: entidadContratante,
        emailFuncionario: emailEncargado,
        objetoProceso: objectoDelProceso,
        codigoProceso: codigoProceso,
        tipoContratacion: tipoContratacion,
        fechaLimitePreguntas: fechaLimitePregunta,
        fechaLimiteRespuestas: fechaLimiteRespuesta,
        fechaLimiteEntregaOferta: fechaLimiteEntregaOferta,
        fechaLimiteCalificacion: fechaLimiteCalificacion,
        fechaInicioPuja: fechaInicioPuja,
        plazoEntrega: plazoEntrega,
        proveedor: proveedor,
      });
    }
  }, [
    entidadContratante,
    emailEncargado,
    objectoDelProceso,
    codigoProceso,
    tipoContratacion,
    fechaLimitePregunta,
    fechaLimiteRespuesta,
    fechaLimiteEntregaOferta,
    fechaLimiteCalificacion,
    fechaInicioPuja,
    plazoEntrega,
    proveedor,
  ]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="form-label mb-3" style={{ fontWeight: "bold" }}>
          DATOS DEL PROCESO - SECTOR PUBLICO
        </h4>
      </div>
      <AvForm lassName="needs-validation">
        <Row style={{ marginTop: 20 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>ENTIDAD CONTRATANTE</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsPerson />
                </i>
                <AvField
                  name="entidad_contratante"
                  type="text"
                  errorMessage="Ingrese la entidad contratante"
                  value={entidadContratante}
                  onChange={(e) => setEntidadContratante(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="entidad_contratante"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  EMAIL ENCARGADO DEL PROCESO
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsEnvelope />
                </i>
                <AvField
                  name="email_encargado"
                  type="text"
                  errorMessage="Ingrese el email del encargado del proceso"
                  value={emailEncargado}
                  onChange={(e) => setEmailEncargado(e.target.value)}
                  className="form-control"
                  style={{ width: "100%" }}
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                  id="email_encargado"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>OBJETO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsApp />
                </i>
                <AvField
                  name="objecto_proceso"
                  type="text"
                  errorMessage="Ingrese el objeto del proceso"
                  value={objectoDelProceso}
                  onChange={(e) => setObjectoDelProceso(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="objecto_proceso"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>CODIGO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCursor />
                </i>
                <AvField
                  name="codigo_proceso"
                  type="text"
                  errorMessage="Ingrese el codigo del proceso"
                  value={codigoProceso}
                  onChange={(e) => setCodigoProceso(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="codigo_proceso"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>TIPO DE CONTRATACION</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsArrowRight />
                </i>
                <select
                  className="form-control"
                  style={{ width: "100%" }}
                  value={tipoContratacion}
                  onChange={(e) => {
                    setTipoContratacion(e.target.value);
                  }}
                >
                  {Object.values(tipos_contratacion).map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE PREGUNTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <Input
                  name="fecha_limite_preguntas"
                  id="fecha_limite_preguntas"
                  type="datetime-local"
                  value={fechaLimitePregunta + ":00"}
                  defaultValue={fechaLimitePregunta + ":00"}
                  min={getTodayDate() + ":00"}
                  onChange={(e) => setFechaLimitePregunta(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE RESPUESTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarRange />
                </i>
                <Input
                  name="fecha_limite_respuesta"
                  id="fecha_limite_respuesta"
                  type="datetime-local"
                  value={fechaLimiteRespuesta + ":00"}
                  defaultValue={fechaLimiteRespuesta + ":00"}
                  min={getTodayDate() + ":00"}
                  onChange={(e) => setFechaLimiteRespuesta(e.target.value)}
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE ENTREGA OFERTA
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar2Date />
                </i>
                <Input
                  name="fecha_limite_entrega_oferta"
                  id="fecha_limite_entrega_oferta"
                  type="datetime-local"
                  value={fechaLimiteEntregaOferta + ":00"}
                  defaultValue={fechaLimiteEntregaOferta + ":00"}
                  min={getTodayDate() + ":00"}
                  onChange={(e) => setFechaLimiteEntregaOferta(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE CALIFICACIÓN
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <Input
                  name="fecha_limite_calificacion"
                  id="fecha_limite_calificacion"
                  type="datetime-local"
                  value={fechaLimiteCalificacion + ":00"}
                  defaultValue={fechaLimiteCalificacion + ":00"}
                  min={getTodayDate() + ":00"}
                  onChange={(e) => setFechaLimiteCalificacion(e.target.value)}
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA INICIO PUJA</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar3Event />
                </i>
                <Input
                  name="fecha_inicio_puja"
                  id="fecha_inicio_puja"
                  type="datetime-local"
                  value={fechaInicioPuja + ":00"}
                  defaultValue={fechaInicioPuja + ":00"}
                  min={getTodayDate() + ":00"}
                  onChange={(e) => setFechaInicioPuja(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  PLAZO DE ENTREGA ( dias )
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar4Event />
                </i>
                <AvField
                  style={{ width: "100%" }}
                  name="plazo_entrega"
                  type="number"
                  errorMessage="Ingrese el plazo de entrega"
                  value={plazoEntrega}
                  onChange={(e) => setPlazoEntrega(e.target.value)}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="plazo_entrega"
                />
              </div>
            </div>
          </Col>
          <Col md="6" sm="12">
            <h4 className="card-title">SELECCIONE MAYORISTA</h4>
            <div className="mb-3 d-flex">
              <Col md={12} style={{ width: "100%" }}>
                <select
                  className="form-control"
                  value={proveedor}
                  onChange={(e) => {
                    setProveedor(e.target.value);
                  }}
                >
                  {Object.values(providers).map((provider) => (
                    <option key={provider.id} value={provider.name}>
                      {provider.name}
                    </option>
                  ))}
                </select>
              </Col>
            </div>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};

export default PublicSector;
