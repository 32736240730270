import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Spinner,
} from "reactstrap";

import {
  BsBarChartSteps,
  BsEye,
  BsFillCalendarCheckFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsFillCursorFill,
  BsFillEjectFill,
  BsFillEnvelopeFill,
  BsFillFileEarmarkTextFill,
  BsFillGrid1X2Fill,
  BsFillGrid3X3GapFill,
  BsFillPatchCheckFill,
  BsFillPencilFill,
  BsFillPersonCheckFill,
  BsFillStopCircleFill,
  BsFillTelephoneForwardFill,
  BsFillVinylFill,
  BsFillXCircleFill,
  BsFillXDiamondFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsInfoCircleFill,
  BsReverseLayoutTextSidebarReverse,
} from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from "react-redux";

import { Modal, Button } from "react-bootstrap";

import useHttp from "../../hook/use-http";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { result } from "lodash";
import Binnacle from "./binnacle";
import { prototype } from "react-transition-group/CSSTransition";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ModalCustom from "../../components/Modal";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const { SearchBar } = Search;

const OpportunityDetails = ({ history, match }) => {
  let counterItemIntoBinnacle = 0;

  // this variable will be used to get information of the channel
  const profile = useSelector((state) => state.profileReducer.profile);

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.CertificationJsonConsume.data);

  // this variable will be save the opportunity selected
  const [opportunitySelected, setOpportunitySelected] = useState([]);
  // this variable contain the name of the group that we wanna create
  const [nameGroup, setNameGroup] = useState("");

  // this variable will be used to control the settings of the table
  const page = 1;
  const sizePerPage = 10;
  // save the product after add and extra fields
  const [productData, setProductData] = useState({});

  const [historicData, setHistoricData] = useState([]);

  // this variable will save the comments of binnacle
  const [binnacleComments, setBinnacleComments] = useState([]);

  // this variable will be used to save a new item into binnacle
  const [newItemBinnacle, setNewItemBinnacle] = useState(null);

  // this variable wil be saved the channel name
  const [channelName, setChannelName] = useState("");

  // control if the view is admin or not
  const [isAdmin, setIsAdmin] = useState(false);

  // state fo the opportunity
  const [stateOpportunity, setStateOpportunity] = useState("");

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  const [isWinOpportunity, setIsWinOpportunity] = useState(null);

  const [reasonRejectionOpportunity, setReasonRejectionOpportunity] =
    useState(null);

  const [reasonConvalidateOpportunity, setReasonConvalidateOpportunity] =
    useState(null);
  const [reasonApproveOpportunity, setReasonApproveOpportunity] =
      useState(null);

  // this variable and function will be used to control the modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setIsWinOpportunity(null);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setShow2(false);
  };

  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => {
    setShow3(false);
  };
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => {
    setShow4(false);
  };
  const handleShow4 = () => setShow4(true);

  const columns = [
    // {
    //     dataField: 'checkbox',
    //     text: `${''}`,
    //     sort: true,
    //     sortCaret: (order, column) => {
    //         if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
    //         else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
    //         else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
    //         return null;
    //     }

    // },
    {
      dataField: "codigo",
      text: "CODIGO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "accion",
      text: "ACCION",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "responsable",
      text: "RESPONSABLE",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "mensaje",
      text: "MENSAJE",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "fecha",
      text: "FECHA",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
  ];

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <Row style={{ width: "100%" }}>
          <Col xl="12">
            <div>
              <h6 style={{ fontWeight: "bold" }}>Entidad</h6>
              <p>
                {row?.entidad.length > 260
                  ? row?.entidad.slice(0, 260) + "..."
                  : row?.entidad}
              </p>
            </div>
          </Col>
          <Col xl="12">
            <h6 style={{ fontWeight: "bold" }}>Descripción</h6>
            <p>
              {row?.allDesctiption.length > 260
                ? row?.allDesctiption.slice(0, 260) + "..."
                : row.allDesctiption}
            </p>
          </Col>
        </Row>
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  let valueExtra = {
    hoverIdx: null,
  };
  const rowEvents = {
    onMouseEnter: (e, row, rowIndex) => {
      valueExtra = { hoverIdx: rowIndex };
    },
    onMouseLeave: () => {
      valueExtra = { hoverIdx: null };
    },
  };

  const rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    if (rowIndex % 2 === 0) {
      //style.backgroundColor = 'transparent';
    } else {
      //style.backgroundColor = 'rgba(54, 163, 173, .10)';
    }
    //style.color = '#505D69'
    //style.borderTop = 'none';

    return style;
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: historicData.length, // replace later with size(customers),
    custom: true,
  };

  // this function will be used to save the date
  const getDateWithTheNewFormat = (date) => {
    let d = new Date(date);
    let dformat =
      [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
      " " +
      [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

    return dformat;
  };

  // this function will be process the result of the method getListChannel
  const handleGetListChannelDataResponse = (products) => {
    if (products?.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      setProductData(products);

      const nameChannel = products?.canal?.razonSocial;

      setChannelName(nameChannel);

      setStateOpportunity(products?.estado);

      if (products?.bitacoras) {
        const binnacle = Object.values(products?.bitacoras).map((item) => {
          const newFormatDate = getDateWithTheNewFormat(item.fechaRegistro);
          counterItemIntoBinnacle = counterItemIntoBinnacle + 1;
          return {
            id: counterItemIntoBinnacle,
            description: item.observacion,
            name: nameChannel,
            date: newFormatDate,
          };
        });

        const sortBinnacles = binnacle.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setBinnacleComments(sortBinnacles);
      }
    }
  };

  // this function will be used to request the data from the server
  const getListChannel = () => {
    const { id } = match.params;

    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/list/codigo/${id}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetListChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItemIntoBinnacleDataResponse = (response) => {
    if (response?.status === 401) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      counterItemIntoBinnacle = counterItemIntoBinnacle + 1;

      const result = {
        id: counterItemIntoBinnacle,
        description: newItemBinnacle?.observacion,
        name: channelName,
        date: newItemBinnacle?.fechaRegistro,
      };

      /*
            setBinnacleComments(prov => {
                return [
                    ...prov,
                    result
                ]
            })
            */

      getListChannel();
    }
  };

  const addNewItemIntoBinnacle = (item) => {
    try {
      // Here we get the code of the opportunity
      const { id } = match.params;

      // save the new item binnacle
      setNewItemBinnacle(item);

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/edit`,
          method: "PUT",
          headers: myHeaders,
          body: {
            codigo: id,
            bitacoras: [item],
          },
        },
        addNewItemIntoBinnacleDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const dateInNewFormat = (date) => {
    let d = new Date(date);
    let dformat =
      [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
      " " +
      [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

    return dformat;
  };

  const changeOpportunityStatusToWinOrLoseByChannelDataResponse = (
    response
  ) => {
    if (response?.status === 401) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      getListChannel();
    }
  };

  const handleChangeOpportunityStatusToWinOrLoseByChannel = () => {
    try {
      const status = isWinOpportunity;

      handleClose();

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/batch`,
          method: "PUT",
          headers: myHeaders,
          body: {
            codigos: [productData?.codigo],
            activo: status,
          },
        },
        changeOpportunityStatusToWinOrLoseByChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusOpportunityExpiredToApproveDataResponse = (response) => {
    if (response?.status === 401) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      getListChannel();
    }
  };

  const handleChangeStatusOpportunityExpiredToApprove = (status) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/oportunidad/batch/vigency`,
          method: "PUT",
          headers: myHeaders,
          body: {
            codigos: [productData?.codigo],
            activo: status,
          },
        },
        changeStatusOpportunityExpiredToApproveDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const compararFechas= (a, b)  => {
    const fechaA = new Date(a.fecha);
    const fechaB = new Date(b.fecha);
    return fechaB - fechaA;
  }
  const handleGetHistoricData = (data) => {
    if (data?.status === 401) {
      // show a error message here

      if (data.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(data.message, { type: "error", autoClose: 10000 });
    } else {
     
      const arrHistoric =  data.content.map((item) => {
        return {
          codigo: item.codigo,
          fecha: formatDateTime(item.createdDate),
          accion: item.accion,
          mensaje: item.mensaje,
          estado: item.estado,
          responsable: item.createdBy,
        };
      });
      arrHistoric.sort(compararFechas)
      setHistoricData(
        arrHistoric
      );
    }
  };

  const getHistoricData = async () => {
    const { id } = match.params;

    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/historico/list/codigo/${id}?size=100`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetHistoricData
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListChannel();
    getHistoricData();
  }, []);

  const knowRoleDataResponse = (response) => {
    if (typeof response === "boolean") {
      setIsAdmin(response);
    }
  };

  const handleKnowRole = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/user/verifyRole`,
          method: "GET",
          headers: myHeaders,
        },
        knowRoleDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleKnowRole();
  }, [profile]);

  // this function will process the response and process from handleDenyOpportunityForReason

  const denyOpportunityDataResponse = (response) => {
    if (response?.status) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      history.push("/opportunity-list-admin");
    }
  };

  // this function will deny the opportunity
  const handleDenyOpportunityForReason = () => {
    try {
      // Here we get the code of the opportunity
      const { id } = match.params;

      setShow2(false);

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/oportunidad/batch/pendiente`,
          method: "PUT",
          headers: myHeaders,
          body: {
            codigo: id,
            motivoRechazo: reasonRejectionOpportunity,
          },
        },
        denyOpportunityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const convalidateOpportunityDataResponse = (response) => {
    if (response?.status) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      history.push("/opportunity-list-admin");
    }
  };

  // this function will deny the opportunity
  const handleConvalidateOpportunityForReason = () => {
    try {
      // Here we get the code of the opportunity
      const { id } = match.params;

      setShow3(false);

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/oportunidad/status/` + id,
          method: "PUT",
          headers: myHeaders,
          body: {
            status: "CONVALIDAR",
            comentario: reasonConvalidateOpportunity,
          },
        },
        convalidateOpportunityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprovedOpportunityForReason = () => {
    try {
      if(!reasonApproveOpportunity){
        toast("El campo de aprobación es obligatorio", { type: "warning", autoClose: 3000 });
        return
      }
      // Here we get the code of the opportunity
      const { id } = match.params;

      setShow3(false);

      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      request(
        {
          url: `/auth/oportunidad/status/` + id,
          method: "PUT",
          headers: myHeaders,
          body: {
            status: "APROBADA",
            comentario: reasonApproveOpportunity
          },
        },
        convalidateOpportunityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {loading ? (
            <Row>
              <Col xl="12">
                <Card style={{ height: "100%" }}>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row className="mb-3">
                        <Col
                          xl="12"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          id="datos"
                        >
                          <Spinner animation="border" variant="success" />
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xl="12" style={{ width: "100%" }} id="datos">
                  <h3 style={{ fontWeight: "bold" }}>
                    OPORTUNIDAD {productData?.codigo}
                  </h3>
                </Col>
              </Row>

              {productData?.motivoRechazo && (
                <div className="alert alert-danger">
                  {productData?.motivoRechazo}
                </div>
              )}

              <Row>
                <Col xl="12" style={{ width: "100%" }} id="datos">
                  <Card style={{ height: "100%" }}>
                    <CardBody>
                      <AvForm className="needs-validation">
                        <Row className="d-flex justify-content-center align-content-center align-items-center flex-wrap mx-5 border-bottom border-light">
                          <Col
                            xl="4"
                            className="d-flex flex-columns "
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                TIPO DE OPORTUNIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.tipo}
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                CANAL:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.canal?.razonSocial}
                              </h6>
                            </div>

                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.informacionEntidad?.entidad}
                              </h6>
                            </div>

                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                NOMBRE DEL VENDEDOR:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.vendedor?.nombre}
                              </h6>
                            </div>
                            {productData.fechaCierre && 
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                FECHA DE CIERRE:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {new Date(productData.fechaCierre).toISOString().substring(0, 10)}
                              </h6>
                            </div>
                            }
                          </Col>
                          <Col
                            xl="4"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                NOMBRE CONTACTO EN LA ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.informacionEntidad?.nombreContacto?.toUpperCase()}
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                EMAIL DE LA ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.informacionEntidad?.email?.toUpperCase()}
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                TELEFONO DE LA ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.informacionEntidad?.telefono}
                              </h6>
                            </div>

                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                CARGO DEL CONTACTO DE LA ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.vendedor?.cargo}
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                MAYORISTA:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.mayorista}
                              </h6>
                            </div>
                          </Col>
                          <Col
                            xl="4"
                            className="d-flex flex-column align-items-end"
                          >
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                CREACIÓN:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {dateInNewFormat(productData?.createdDate)}
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                PRESUPUESTO:{" "}
                              </h6>

                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.presupuestoTotalEstimadoEntidad +
                                  " $"}
                              </h6>
                            </div>

                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                PRESUPUESTO IMPRESION:{" "}
                              </h6>

                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.presupuestoImpresion || 0} $
                              </h6>
                            </div>
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                ESTADO:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.estado}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mx-5">
                          <Col xl="12">
                            <div className="d-flex my-3">
                              <h6 style={{ fontWeight: "bold", margin: 0 }}>
                                DESCRIPCIÓN DEL TRABAJO REALIZADO EN LA ENTIDAD:{" "}
                              </h6>
                              <h6 style={{ margin: 0 }} classNamemt>
                                {productData.descripcion}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mx-5">
                          {productData.pathDeclaracion &&
                            productData.pathDeclaracion.map((item, index) => (
                              <Col xl="6" key={index}>
                                <div>
                                  <Label
                                    className="form-label"
                                    htmlFor="opportunity-preview-file"
                                  >
                                    Archivo de declaración #{index + 1}
                                  </Label>
                                  <br />
                                  {productData.pathDeclaracion.length === 1 &&
                                    [".pdf"].includes(
                                      item
                                        .substring(item.lastIndexOf("."))
                                        .toLowerCase()
                                    ) && (
                                      <iframe
                                        title="pdf-declaration"
                                        className="w-100 h-pdf"
                                        src={`https://docs.google.com/viewer?url=${process.env.REACT_APP_S3_URL}${item}&embedded=true`}
                                      />
                                    )}
                                  {productData.pathDeclaracion.length === 1 &&
                                    [".png", ".jpg", ".jpeg"].includes(
                                      item
                                        .substring(item.lastIndexOf("."))
                                        .toLowerCase()
                                    ) && (
                                      <div className="text-center">
                                        <img
                                          alt="declaration"
                                          className="w-50"
                                          src={`${process.env.REACT_APP_S3_URL}${item}`}
                                        />
                                      </div>
                                    )}
                                  {(productData.pathDeclaracion.length > 1 ||
                                    ![".pdf", ".png", ".jpg", ".jpeg"].includes(
                                      item
                                        .substring(item.lastIndexOf("."))
                                        .toLowerCase()
                                    )) && (
                                    <a
                                      href={`${process.env.REACT_APP_S3_URL}${item}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {process.env.REACT_APP_S3_URL}
                                      {item}
                                    </a>
                                  )}
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>

        {(productData?.estado === "PENDIENTE" ||
          productData?.estado === "CONVALIDAR") && (
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-primary-lexmark mx-3"
              type="button"
              onClick={handleShow4}
            >
              APROBAR
            </button>
            <button
              className="btn btn-second-lexmark mx-3"
              type="button"
              onClick={handleShow3}
            >
              CONVALIDAR
            </button>
            <button
              className="btn btn-danger-lexmark mx-3"
              type="button"
              onClick={handleShow2}
            >
              {" "}
              RECHAZAR
            </button>
          </div>
        )}

        {productData?.detalleAprobacion && isAdmin && (
          <div className="container-fluid mt-3">
            <Row>
              <Col xl="12">
                <Card style={{ height: "100%", marginBottom: 0 }}>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row className="mx-5 ">
                        <Col xl="12">
                          <h4 style={{ fontWeight: "bold" }}>
                            MOTIVO DE APROBACIÓN
                          </h4>
                        </Col>
                        <Col xl="12">{productData?.detalleAprobacion}</Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {productData?.estado === "APROBADA" && (
          <div className="container-fluid mt-3">
            <Row>
              <Col xl="12">
                <Card style={{ height: "100%", marginBottom: 0 }}>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row className="mx-5 ">
                        <Col xl="12">
                          <h4 style={{ fontWeight: "bold" }}>
                            TERMINAR OPORTUNIDAD
                          </h4>
                        </Col>
                        <Col xl="12">
                          Si terminas la oportunidad cambiara de estado GANADO o
                          PERDIDO, depende de los resultados del consurso, una
                          vez que realices el cambio de estado ya no te
                          permitira agregar más observaciones en la bitacora.
                        </Col>
                        <Col
                          xl="12"
                          className="d-flex flex-row justify-content-end"
                        >
                          <button
                            className="btn btn-danger mx-1"
                            onClick={() => {
                              setIsWinOpportunity(false);
                              handleShow();
                              //handleChangeOpportunityStatusToWinOrLoseByChannel(false)
                            }}
                          >
                            PERDIDA
                          </button>
                          <button
                            className="btn btn-success "
                            onClick={() => {
                              setIsWinOpportunity(true);
                              handleShow();
                              //handleChangeOpportunityStatusToWinOrLoseByChannel(true)
                            }}
                          >
                            GANADA
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {isAdmin && productData?.estado === "CADUCADO" && (
          <div className="container-fluid mt-3">
            <Row>
              <Col xl="12">
                <Card style={{ height: "100%", marginBottom: 0 }}>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row className="mx-5 ">
                        <Col xl="12">
                          <h4 style={{ fontWeight: "bold" }}>
                            ACTIVAR OPORTUNIDAD
                          </h4>
                        </Col>
                        <Col xl="12">
                          Debido a que el canal no ha registrado observaciones
                          en la bitácora durante el tiempo establecido de
                          actualización.
                          <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                            ¿Desea reactivar la oportunidad?
                          </span>
                        </Col>
                        <Col
                          xl="12"
                          className="d-flex flex-row justify-content-end"
                        >
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              handleChangeStatusOpportunityExpiredToApprove(
                                true
                              )
                            }
                          >
                            ACTIVAR
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {binnacleComments?.length > 0 && (
          <div className="container-fluid mt-3">
            <Row>
              <Col xl="12">
                <Card style={{ height: "100%" }}>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row className="mb-3 mx-5">
                        <Binnacle
                          comments={
                            binnacleComments?.length > 0 ? binnacleComments : []
                          }
                          addNewItemIntoBinnacle={addNewItemIntoBinnacle}
                          isAdmin={isAdmin}
                          stateOpportunity={stateOpportunity}
                        />
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={historicData}
                  // selectRow={selectRow}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={historicData}
                      // selectRow={{ mode: 'checkbox', clickToSelect: true }}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  id="table-responsive"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  rowStyle={rowStyle}
                                  rowEvents={rowEvents}
                                  expandRow={expandRow}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isWinOpportunity ? "GANAR OPORTUNIDAD" : "PERDER OPORTUNIDAD"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isWinOpportunity ? (
            <div style={{ fontWeight: "bold" }}>
              Esta opción se utiliza únicamente, en caso de haber ganado la
              oportunidad, por lo que ya no podrás registras más observaciones
              en la bitácora.
            </div>
          ) : (
            <div style={{ fontWeight: "bold" }}>
              Esta opción se utiliza únicamente, en caso de haber perdido la
              oportunidad, por lo que ya no podrás registras más observaciones
              en la bitácora.
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="p-0 mb-3 mx-3">
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            variant={isWinOpportunity ? "primary" : "danger"}
            type="button"
            onClick={handleChangeOpportunityStatusToWinOrLoseByChannel}
          >
            {isWinOpportunity ? "Ganar" : "Perder"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* We need to use the ModalCustom for all Modal we have in our component. */}

      <ModalCustom
        show={show4}
        handleClose={handleClose4}
        actionBtnLabel={"APROBAR"}
        title="APROBAR OPORTUNIDAD"
        customClass={"btn-primary-lexmark"}
        onClickEvent={handleApprovedOpportunityForReason}
      >
        <div className="modal-content-deny-opportunity">
          <p className="modal-deny-opportunity-title">
          Por favor ingrese el motivo por la cual aprobara la oportunidad.
          </p>
          <input
            type="text"
            class="form-control modal-deny-opportunity-input-reason"
            value={reasonApproveOpportunity}
            onChange={(e) => setReasonApproveOpportunity(e.target.value)}
          />
        </div>
      </ModalCustom>
      <ModalCustom
        show={show3}
        handleClose={handleClose3}
        actionBtnLabel={"CONVALIDAR"}
        title="CONVALIDAR OPORTUNIDAD"
        customClass={"btn-primary-lexmark"}
        onClickEvent={handleConvalidateOpportunityForReason}
      >
        <div className="modal-content-deny-opportunity">
          <p className="modal-deny-opportunity-title">
            Por favor ingrese el motivo por la cual convalidara la oportunidad.
          </p>

          <input
            type="text"
            class="form-control modal-deny-opportunity-input-reason"
            value={reasonConvalidateOpportunity}
            onChange={(e) => setReasonConvalidateOpportunity(e.target.value)}
          />
        </div>
      </ModalCustom>

      <ModalCustom
        show={show2}
        actionBtnLabel={"RECHAZAR"}
        handleClose={handleClose2}
        title="RECHAZAR OPORTUNIDAD"
        typeButton={"danger"}
        onClickEvent={handleDenyOpportunityForReason}
      >
        <div className="modal-content-deny-opportunity">
          <p className="modal-deny-opportunity-title">
            Por favor ingrese el motivo por la cual rechazará la oportunidad.
          </p>

          <input
            type="text"
            class="form-control modal-deny-opportunity-input-reason"
            value={reasonRejectionOpportunity}
            onChange={(e) => setReasonRejectionOpportunity(e.target.value)}
          />
        </div>
      </ModalCustom>
    </React.Fragment>
  );
};

export default OpportunityDetails;
