
import { channel } from "redux-saga"
import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_DATA_JSON,
  UPDATE_DATA_JSON,
  CHANGE_STATUS_CHANNEL,
  CREATE_DATA_JSON
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  // In this part we include a data example to use in the app
  data: [
    {
      "razon_social": "Torquent Per Industries",
      "ruc": "OAM21ZSK6KB",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://youtube.com",
      "ruc_documento": "https://reddit.com",
      "observaciones": "lectus. Cum sociis",
      "direccion": "P.O. Box 218, 9429 Nam Avenue",
      "nombre_contacto": "Dai Fitzgerald",
      "celular_contacto": "1-865-453-5440",
      "email_contacto": "ultrices.iaculis.odio@hotmail.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Quentin Moore",
      "celular_representante": "(516) 375-2467",
      "email_representante": "curabitur.dictum.phasellus@yahoo.couk",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Est Institute",
      "ruc": "OSI28VWL7NX",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://cnn.com",
      "ruc_documento": "https://zoom.us",
      "observaciones": "erat nonummy ultricies ornare, elit elit fermentum risus,",
      "direccion": "316-3588 Mi Road",
      "nombre_contacto": "Madaline Roth",
      "celular_contacto": "(777) 364-4015",
      "email_contacto": "massa@protonmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Fredericka Jenkins",
      "celular_representante": "(223) 326-5151",
      "email_representante": "praesent.eu.nulla@outlook.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Molestie Orci LLP",
      "ruc": "SME67PKB5II",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://naver.com",
      "ruc_documento": "http://ebay.com",
      "observaciones": "Fusce aliquet magna a neque. Nullam ut nisi a odio",
      "direccion": "P.O. Box 876, 9605 Tellus Ave",
      "nombre_contacto": "Ulysses Calhoun",
      "celular_contacto": "1-142-824-7370",
      "email_contacto": "primis@protonmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Colt Crosby",
      "celular_representante": "(656) 475-6161",
      "email_representante": "sed.sapien@google.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Fringilla Donec Limited",
      "ruc": "AQM42UZN7EG",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://netflix.com",
      "ruc_documento": "https://youtube.com",
      "observaciones": "sed pede nec ante blandit viverra. Donec tempus, lorem fringilla",
      "direccion": "294-7580 Massa Avenue",
      "nombre_contacto": "Yoshio Weeks",
      "celular_contacto": "(580) 478-7153",
      "email_contacto": "maecenas.libero@outlook.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Dane Wheeler",
      "celular_representante": "(586) 743-7170",
      "email_representante": "in.lorem@outlook.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Aliquet Corp.",
      "ruc": "EBE55CLK3NO",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://ebay.com",
      "ruc_documento": "http://walmart.com",
      "observaciones": "egestas a, dui. Cras pellentesque. Sed dictum. Proin eget",
      "direccion": "1098 Consectetuer, St.",
      "nombre_contacto": "Hilda Case",
      "celular_contacto": "(752) 765-2186",
      "email_contacto": "pellentesque@protonmail.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Cade Dale",
      "celular_representante": "1-486-813-9124",
      "email_representante": "pellentesque@outlook.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Velit Justo Nec Corporation",
      "ruc": "BOD58UAM2TI",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://pinterest.com",
      "ruc_documento": "http://netflix.com",
      "observaciones": "nascetur",
      "direccion": "Ap #467-966 Sodales Av.",
      "nombre_contacto": "Violet Gould",
      "celular_contacto": "1-521-411-8508",
      "email_contacto": "dui@hotmail.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Nyssa Scott",
      "celular_representante": "(605) 884-6358",
      "email_representante": "maecenas.mi@google.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Ornare Associates",
      "ruc": "MJF36SOH7UB",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://walmart.com",
      "ruc_documento": "https://ebay.com",
      "observaciones": "enim",
      "direccion": "114-787 Euismod Street",
      "nombre_contacto": "Colt Holloway",
      "celular_contacto": "1-508-849-1373",
      "email_contacto": "nascetur.ridiculus.mus@aol.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Branden Donaldson",
      "celular_representante": "1-343-456-6442",
      "email_representante": "et.rutrum.eu@google.net",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Montes Nascetur Industries",
      "ruc": "FQM38ZFP7OG",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://nytimes.com",
      "ruc_documento": "http://ebay.com",
      "observaciones": "auctor velit. Aliquam nisl. Nulla eu neque pellentesque",
      "direccion": "982-7415 Felis Ave",
      "nombre_contacto": "Bertha Barton",
      "celular_contacto": "1-204-762-5486",
      "email_contacto": "pede.suspendisse.dui@outlook.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Dexter Williamson",
      "celular_representante": "1-294-711-0446",
      "email_representante": "nam.interdum.enim@hotmail.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Ipsum Leo Elementum Limited",
      "ruc": "KOW16IVY4WL",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://reddit.com",
      "ruc_documento": "http://guardian.co.uk",
      "observaciones": "nisl. Quisque",
      "direccion": "P.O. Box 447, 8721 Neque. Rd.",
      "nombre_contacto": "Whitney Faulkner",
      "celular_contacto": "1-481-619-9936",
      "email_contacto": "nec.tempus@protonmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Grant Mcclain",
      "celular_representante": "1-865-687-6384",
      "email_representante": "erat.vitae@aol.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Duis Corporation",
      "ruc": "SOI23PVM5FL",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://walmart.com",
      "ruc_documento": "http://reddit.com",
      "observaciones": "fringilla, porttitor vulputate, posuere vulputate, lacus. Cras interdum. Nunc sollicitudin",
      "direccion": "669-3411 Et, St.",
      "nombre_contacto": "Signe Kennedy",
      "celular_contacto": "(161) 615-5218",
      "email_contacto": "orci.luctus@hotmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Elizabeth Bullock",
      "celular_representante": "1-147-647-0155",
      "email_representante": "inceptos@protonmail.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Ligula Aenean Corporation",
      "ruc": "PWS12DJE0ZJ",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://instagram.com",
      "ruc_documento": "http://netflix.com",
      "observaciones": "Nullam enim. Sed nulla",
      "direccion": "6590 Sed St.",
      "nombre_contacto": "Pearl Wood",
      "celular_contacto": "1-312-246-0403",
      "email_contacto": "semper.erat@aol.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Chelsea Dawson",
      "celular_representante": "1-851-388-9615",
      "email_representante": "adipiscing.elit@icloud.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Quisque Varius Nam PC",
      "ruc": "RHX32FPC2RC",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://twitter.com",
      "ruc_documento": "http://bbc.co.uk",
      "observaciones": "imperdiet dictum magna. Ut tincidunt orci quis",
      "direccion": "P.O. Box 509, 4838 Ultrices Road",
      "nombre_contacto": "Malik Palmer",
      "celular_contacto": "1-649-376-8521",
      "email_contacto": "quisque.varius@hotmail.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Gillian Pugh",
      "celular_representante": "(442) 585-6575",
      "email_representante": "felis.ullamcorper@aol.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Egestas Rhoncus Corporation",
      "ruc": "RCD57TQO5XT",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://twitter.com",
      "ruc_documento": "https://naver.com",
      "observaciones": "felis, adipiscing fringilla, porttitor vulputate, posuere vulputate, lacus. Cras",
      "direccion": "P.O. Box 144, 5589 Tempor Av.",
      "nombre_contacto": "Dustin Steele",
      "celular_contacto": "(342) 586-2845",
      "email_contacto": "in.mi.pede@google.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Melvin Roman",
      "celular_representante": "1-685-591-4635",
      "email_representante": "eu.euismod.ac@icloud.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Dolor Quam Elementum Institute",
      "ruc": "TJQ67YQG2PN",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://zoom.us",
      "ruc_documento": "https://zoom.us",
      "observaciones": "Vivamus molestie dapibus ligula. Aliquam erat volutpat. Nulla dignissim. Maecenas",
      "direccion": "4754 Diam St.",
      "nombre_contacto": "Mannix Morris",
      "celular_contacto": "(426) 417-1164",
      "email_contacto": "fusce.fermentum.fermentum@outlook.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Eric Guy",
      "celular_representante": "(578) 576-1787",
      "email_representante": "ac.eleifend@icloud.net",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Mollis Dui In Ltd",
      "ruc": "OOV21PGD9CM",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://bbc.co.uk",
      "ruc_documento": "http://instagram.com",
      "observaciones": "risus. Quisque libero lacus,",
      "direccion": "156-7579 Mauris. Ave",
      "nombre_contacto": "Jessica Henderson",
      "celular_contacto": "(727) 585-4617",
      "email_contacto": "lacus.quisque@yahoo.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Lenore Kelly",
      "celular_representante": "(720) 465-9762",
      "email_representante": "semper.pretium@google.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "At Velit Inc.",
      "ruc": "JIJ18CKY0RJ",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://pinterest.com",
      "ruc_documento": "https://facebook.com",
      "observaciones": "Aliquam",
      "direccion": "899-4943 In St.",
      "nombre_contacto": "Jerry Wilkinson",
      "celular_contacto": "1-724-672-9172",
      "email_contacto": "convallis@yahoo.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Chaney Hampton",
      "celular_representante": "1-774-716-6955",
      "email_representante": "dapibus.quam@protonmail.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Donec Elementum Lorem Ltd",
      "ruc": "SUT43RLD2JU",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://youtube.com",
      "ruc_documento": "https://facebook.com",
      "observaciones": "risus. In mi pede, nonummy ut,",
      "direccion": "Ap #501-1428 Enim, Rd.",
      "nombre_contacto": "Tucker Lindsey",
      "celular_contacto": "(161) 860-2913",
      "email_contacto": "turpis.nec@hotmail.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Ginger Price",
      "celular_representante": "(522) 517-6764",
      "email_representante": "augue.ut@hotmail.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Et Magna Ltd",
      "ruc": "PXL74OPG3KH",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://google.com",
      "ruc_documento": "https://baidu.com",
      "observaciones": "et, commodo at, libero. Morbi accumsan laoreet ipsum.",
      "direccion": "410-7623 Pede, Av.",
      "nombre_contacto": "Sylvia Hays",
      "celular_contacto": "1-137-430-5346",
      "email_contacto": "mollis.dui@outlook.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Chandler Mckinney",
      "celular_representante": "(588) 773-2477",
      "email_representante": "mauris.non@yahoo.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Odio Semper Cursus Institute",
      "ruc": "KLJ21NEN7OW",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://twitter.com",
      "ruc_documento": "http://ebay.com",
      "observaciones": "nec urna",
      "direccion": "6092 Leo. St.",
      "nombre_contacto": "Lee Hammond",
      "celular_contacto": "(822) 653-2828",
      "email_contacto": "aliquam.enim@yahoo.net",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Baker Meadows",
      "celular_representante": "1-334-855-9527",
      "email_representante": "metus.vivamus@protonmail.org",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Dignissim Pharetra Industries",
      "ruc": "ETJ91QAV5LC",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://youtube.com",
      "ruc_documento": "https://zoom.us",
      "observaciones": "auctor vitae, aliquet",
      "direccion": "5380 Est, St.",
      "nombre_contacto": "Beau Jacobs",
      "celular_contacto": "(354) 418-2682",
      "email_contacto": "mauris.a.nunc@google.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Craig Sutton",
      "celular_representante": "(372) 519-3358",
      "email_representante": "metus.aliquam@aol.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Orci In Inc.",
      "ruc": "FDO53KUI6KL",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://wikipedia.org",
      "ruc_documento": "https://instagram.com",
      "observaciones": "montes, nascetur ridiculus mus. Proin",
      "direccion": "Ap #658-9915 Ut, Avenue",
      "nombre_contacto": "Tyrone Shaffer",
      "celular_contacto": "(245) 126-1049",
      "email_contacto": "at@google.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Len Mcmillan",
      "celular_representante": "(437) 906-9776",
      "email_representante": "amet.risus.donec@google.couk",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Cras Company",
      "ruc": "VSI16VOP0BC",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://guardian.co.uk",
      "ruc_documento": "https://ebay.com",
      "observaciones": "vestibulum nec, euismod in, dolor. Fusce feugiat.",
      "direccion": "8943 Eu Ave",
      "nombre_contacto": "Hamilton Horn",
      "celular_contacto": "1-834-384-2763",
      "email_contacto": "egestas.fusce@icloud.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Lucas Bonner",
      "celular_representante": "(554) 746-2331",
      "email_representante": "neque.sed.eget@aol.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Mus Proin Vel Consulting",
      "ruc": "FUL28NLN7AU",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://instagram.com",
      "ruc_documento": "http://wikipedia.org",
      "observaciones": "Integer",
      "direccion": "Ap #951-3590 Curabitur Avenue",
      "nombre_contacto": "Xander Bowman",
      "celular_contacto": "1-645-681-9311",
      "email_contacto": "sed.dolor@hotmail.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Wanda Chambers",
      "celular_representante": "(585) 654-8362",
      "email_representante": "morbi.quis@google.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Aenean Eget LLP",
      "ruc": "CAY38REX7YE",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://whatsapp.com",
      "ruc_documento": "http://wikipedia.org",
      "observaciones": "lorem ipsum sodales purus, in molestie tortor nibh",
      "direccion": "494-1688 Aliquam Rd.",
      "nombre_contacto": "Jennifer Vance",
      "celular_contacto": "(646) 279-1619",
      "email_contacto": "at.sem.molestie@google.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Nita Ratliff",
      "celular_representante": "1-105-518-7456",
      "email_representante": "consectetuer@hotmail.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Elementum Purus Ltd",
      "ruc": "ULR39KDW2NR",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://nytimes.com",
      "ruc_documento": "https://ebay.com",
      "observaciones": "nec, euismod in, dolor.",
      "direccion": "196-768 Orci St.",
      "nombre_contacto": "Nash Pruitt",
      "celular_contacto": "(441) 462-6323",
      "email_contacto": "nibh.phasellus@google.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Darrel Thornton",
      "celular_representante": "1-561-855-5012",
      "email_representante": "fusce.fermentum@hotmail.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Est LLP",
      "ruc": "HLN83CNB3YO",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://bbc.co.uk",
      "ruc_documento": "http://yahoo.com",
      "observaciones": "scelerisque sed, sapien. Nunc pulvinar arcu et",
      "direccion": "P.O. Box 573, 7270 Est. St.",
      "nombre_contacto": "Lunea Dunlap",
      "celular_contacto": "(771) 746-7887",
      "email_contacto": "ante@yahoo.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Timothy Shaffer",
      "celular_representante": "(748) 836-3817",
      "email_representante": "aliquam@google.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Nunc Mauris PC",
      "ruc": "JWF71CQM4UG",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://nytimes.com",
      "ruc_documento": "http://facebook.com",
      "observaciones": "Nulla",
      "direccion": "765-824 Risus. Rd.",
      "nombre_contacto": "Alden Hale",
      "celular_contacto": "(348) 567-3058",
      "email_contacto": "integer.vulputate@outlook.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Anastasia Tyler",
      "celular_representante": "(681) 377-1395",
      "email_representante": "placerat.eget@protonmail.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Ut Tincidunt PC",
      "ruc": "DVR64POL8CW",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://ebay.com",
      "ruc_documento": "https://youtube.com",
      "observaciones": "id sapien. Cras dolor dolor,",
      "direccion": "Ap #804-3818 Ullamcorper. St.",
      "nombre_contacto": "Shelly Rollins",
      "celular_contacto": "(536) 668-8857",
      "email_contacto": "nunc@hotmail.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Amery Eaton",
      "celular_representante": "(883) 316-5916",
      "email_representante": "aenean.euismod.mauris@aol.couk",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Ultricies Ornare Ltd",
      "ruc": "SUC34PML8RZ",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://netflix.com",
      "ruc_documento": "http://ebay.com",
      "observaciones": "nunc sed pede. Cum sociis",
      "direccion": "4568 Mauris Av.",
      "nombre_contacto": "Lucy Faulkner",
      "celular_contacto": "(822) 605-9261",
      "email_contacto": "malesuada.fames.ac@hotmail.net",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Kibo David",
      "celular_representante": "1-998-188-6456",
      "email_representante": "suspendisse.sagittis@google.com",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Eleifend Egestas Sed Company",
      "ruc": "MWM55ZPT7EH",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://reddit.com",
      "ruc_documento": "http://google.com",
      "observaciones": "enim, gravida sit amet, dapibus id,",
      "direccion": "666-8216 Ac Rd.",
      "nombre_contacto": "Audra Dennis",
      "celular_contacto": "(454) 584-6546",
      "email_contacto": "in.magna@protonmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Barrett Shelton",
      "celular_representante": "(607) 255-2318",
      "email_representante": "facilisis.magna.tellus@protonmail.com",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Erat Incorporated",
      "ruc": "PXU00VYZ7TN",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://instagram.com",
      "ruc_documento": "https://wikipedia.org",
      "observaciones": "tellus lorem eu metus. In lorem. Donec",
      "direccion": "260-4500 Morbi Ave",
      "nombre_contacto": "Kenneth Porter",
      "celular_contacto": "1-265-375-3483",
      "email_contacto": "ultrices.iaculis@outlook.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Maryam Marks",
      "celular_representante": "1-607-963-2479",
      "email_representante": "quam.vel@yahoo.net",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Ipsum Dolor Sit LLP",
      "ruc": "KVE22ISZ8EG",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://naver.com",
      "ruc_documento": "https://cnn.com",
      "observaciones": "montes, nascetur ridiculus",
      "direccion": "Ap #742-7503 Nam Rd.",
      "nombre_contacto": "Tallulah Simmons",
      "celular_contacto": "1-445-954-3289",
      "email_contacto": "enim.etiam@hotmail.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Camilla Strong",
      "celular_representante": "(738) 951-7647",
      "email_representante": "venenatis@outlook.ca",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Lorem Ipsum Dolor LLP",
      "ruc": "BGN59ABK1BR",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://yahoo.com",
      "ruc_documento": "https://youtube.com",
      "observaciones": "lacus. Mauris non dui",
      "direccion": "Ap #485-2108 Neque. Av.",
      "nombre_contacto": "Dennis Miranda",
      "celular_contacto": "1-716-940-3421",
      "email_contacto": "id.magna@outlook.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Bianca Harvey",
      "celular_representante": "1-677-354-7698",
      "email_representante": "luctus@hotmail.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Vestibulum Ante Industries",
      "ruc": "ROF65YDI1YI",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://youtube.com",
      "ruc_documento": "http://instagram.com",
      "observaciones": "In mi pede, nonummy ut, molestie in, tempus eu, ligula.",
      "direccion": "195-3833 Penatibus Rd.",
      "nombre_contacto": "Kamal Howe",
      "celular_contacto": "1-803-775-2813",
      "email_contacto": "mi@hotmail.couk",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Christen Black",
      "celular_representante": "1-464-235-1318",
      "email_representante": "lobortis.quam.a@outlook.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Nisl PC",
      "ruc": "OKZ82FZT4CV",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://instagram.com",
      "ruc_documento": "http://yahoo.com",
      "observaciones": "sed consequat auctor, nunc nulla vulputate dui, nec",
      "direccion": "6884 Auctor Ave",
      "nombre_contacto": "Bradley Guy",
      "celular_contacto": "(445) 432-6440",
      "email_contacto": "orci.phasellus@outlook.net",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Moana Hensley",
      "celular_representante": "1-983-650-3735",
      "email_representante": "a@aol.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Vitae Incorporated",
      "ruc": "CBZ17GMM4XP",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://facebook.com",
      "ruc_documento": "https://nytimes.com",
      "observaciones": "at risus. Nunc ac sem ut dolor dapibus",
      "direccion": "798-3345 Orci Av.",
      "nombre_contacto": "MacKensie Warren",
      "celular_contacto": "(149) 863-2604",
      "email_contacto": "mauris.quis@outlook.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Philip Hoffman",
      "celular_representante": "(373) 217-7656",
      "email_representante": "quisque@google.org",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Adipiscing Non Luctus Limited",
      "ruc": "BIE17MKL7TU",
      "estado": "ACTIVO",
      "acuerdo_comercial": "http://youtube.com",
      "ruc_documento": "https://walmart.com",
      "observaciones": "non, lacinia at, iaculis quis, pede. Praesent",
      "direccion": "Ap #147-3080 Pede, St.",
      "nombre_contacto": "Aphrodite Hancock",
      "celular_contacto": "1-741-846-3516",
      "email_contacto": "molestie.tellus@icloud.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Cedric Kane",
      "celular_representante": "(296) 363-1335",
      "email_representante": "fusce.aliquet@hotmail.org",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Eget Ipsum Donec Company",
      "ruc": "HQD14TGV2SA",
      "estado": "INACTIVO",
      "acuerdo_comercial": "http://google.com",
      "ruc_documento": "http://facebook.com",
      "observaciones": "lobortis risus.",
      "direccion": "151-5632 Suspendisse Rd.",
      "nombre_contacto": "John Briggs",
      "celular_contacto": "1-544-616-7953",
      "email_contacto": "primis.in@aol.org",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Wesley White",
      "celular_representante": "(736) 331-3644",
      "email_representante": "fringilla@hotmail.com",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Gravida Mauris Ut Ltd",
      "ruc": "LGP38RQM4CB",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://instagram.com",
      "ruc_documento": "https://yahoo.com",
      "observaciones": "elit fermentum risus, at fringilla purus mauris a nunc. In",
      "direccion": "Ap #725-4958 Risus Street",
      "nombre_contacto": "Georgia Reeves",
      "celular_contacto": "1-464-821-3007",
      "email_contacto": "a.feugiat.tellus@yahoo.net",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Plato Conrad",
      "celular_representante": "1-843-870-5847",
      "email_representante": "feugiat.sed@icloud.edu",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Cum Sociis Natoque PC",
      "ruc": "YGE74NFE1KL",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://walmart.com",
      "ruc_documento": "https://twitter.com",
      "observaciones": "In at pede.",
      "direccion": "7728 Mi Ave",
      "nombre_contacto": "Lilah Tyson",
      "celular_contacto": "1-892-422-6434",
      "email_contacto": "ac@icloud.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Alma Riley",
      "celular_representante": "(711) 441-6865",
      "email_representante": "elit@google.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "TECNOMEGA"
    },
    {
      "razon_social": "Non Hendrerit Id LLC",
      "ruc": "KEE40HCC4XD",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://netflix.com",
      "ruc_documento": "https://baidu.com",
      "observaciones": "rhoncus. Proin nisl sem,",
      "direccion": "Ap #105-823 Nulla Street",
      "nombre_contacto": "Rana Mckee",
      "celular_contacto": "1-993-308-2578",
      "email_contacto": "aenean.eget@aol.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Lars Mcfadden",
      "celular_representante": "1-106-427-8227",
      "email_representante": "accumsan.convallis.ante@hotmail.ca",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    },
    {
      "razon_social": "Consectetuer Mauris Incorporated",
      "ruc": "IGX38QPX0BP",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://youtube.com",
      "ruc_documento": "https://zoom.us",
      "observaciones": "mus. Aenean eget magna. Suspendisse tristique",
      "direccion": "Ap #300-877 Natoque Road",
      "nombre_contacto": "Alden Gonzales",
      "celular_contacto": "(691) 453-4522",
      "email_contacto": "bibendum.sed.est@google.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Philip Gonzales",
      "celular_representante": "(458) 663-9746",
      "email_representante": "vestibulum@aol.couk",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Enim Mi Limited",
      "ruc": "RVY81DEO3QO",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://baidu.com",
      "ruc_documento": "http://google.com",
      "observaciones": "convallis convallis dolor. Quisque tincidunt pede ac urna.",
      "direccion": "815-1013 Ante. Rd.",
      "nombre_contacto": "Ian Bowen",
      "celular_contacto": "(589) 553-0823",
      "email_contacto": "nullam@icloud.ca",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Jaquelyn Pate",
      "celular_representante": "1-586-742-9164",
      "email_representante": "vehicula.risus@protonmail.edu",
      "tipo_canal": "CONNECT",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Morbi PC",
      "ruc": "ZUM09PWP1KH",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://youtube.com",
      "ruc_documento": "http://reddit.com",
      "observaciones": "ut lacus. Nulla tincidunt, neque",
      "direccion": "Ap #201-1241 Nunc St.",
      "nombre_contacto": "Cora Travis",
      "celular_contacto": "1-442-352-3452",
      "email_contacto": "cum.sociis@outlook.net",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Astra Cochran",
      "celular_representante": "(680) 768-4416",
      "email_representante": "vel.pede@aol.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Nibh Corporation",
      "ruc": "JPI43UGT7TF",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://baidu.com",
      "ruc_documento": "http://naver.com",
      "observaciones": "non enim. Mauris quis turpis vitae purus",
      "direccion": "P.O. Box 402, 7195 Erat. Av.",
      "nombre_contacto": "Colette Mullins",
      "celular_contacto": "1-828-674-7275",
      "email_contacto": "ac.mattis.velit@aol.com",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Lacota Mccray",
      "celular_representante": "1-487-435-9412",
      "email_representante": "nullam.lobortis@outlook.com",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Adipiscing Lacus Industries",
      "ruc": "EFX85AOR4EW",
      "estado": "ACTIVO",
      "acuerdo_comercial": "https://whatsapp.com",
      "ruc_documento": "https://bbc.co.uk",
      "observaciones": "eu neque pellentesque massa lobortis ultrices.",
      "direccion": "664-734 Interdum Road",
      "nombre_contacto": "Harrison Boyd",
      "celular_contacto": "(142) 302-7912",
      "email_contacto": "pellentesque@protonmail.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Reuben Yates",
      "celular_representante": "1-447-429-2255",
      "email_representante": "leo.elementum@icloud.net",
      "tipo_canal": "TRADICIONAL",
      "mayorista": "MEGAMICRO"
    },
    {
      "razon_social": "Et Rutrum Eu Inc.",
      "ruc": "EQP63EUM2RV",
      "estado": "INACTIVO",
      "acuerdo_comercial": "https://cnn.com",
      "ruc_documento": "http://naver.com",
      "observaciones": "Quisque tincidunt pede ac urna. Ut tincidunt vehicula",
      "direccion": "Ap #944-4480 Inceptos Road",
      "nombre_contacto": "Patience Stanton",
      "celular_contacto": "(561) 243-5466",
      "email_contacto": "nullam.enim@google.edu",
      "cargo_contacto": "Administrador",
      "nombre_representante": "Maxine George",
      "celular_representante": "(885) 776-0828",
      "email_representante": "quis.diam@yahoo.couk",
      "tipo_canal": "CONNECT",
      "mayorista": "SIGOLO 21"
    }
  ]
}

const DataJsonConsume = (state = INIT_STATE, action) => {
  switch (action.type) {

    // This will be used to get the data json
    case GET_DATA_JSON:
      return {
        ...state,
        data: action.payload
      }

    case CREATE_DATA_JSON:
      console.log(action.payload)
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case UPDATE_DATA_JSON:

      const id = action.payload.id
      const newChannel = action.payload.data
      const channels = state.data
      channels[id] = newChannel

      return {
        ...state,
        state: {
          data: channels
        }
      }

    case CHANGE_STATUS_CHANNEL:

      const index = action.payload.id
      const status = action.payload.status

      // this variable get all channels
      const chs = state.data
      // get the specific channel
      const ch = chs[index]
      ch.estado = status

      // save the ch in the array chns
      chs[index] = ch

      return {
        ...state,
        state: {
          data: chs
        }
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default DataJsonConsume
