import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";


import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";



const EntityDetails = ({ match, history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this variable will be used to set the entity selected
  const [entity, setEntity] = useState({});

  // this variable will control the if the admin can change the entity or no
  const [disabled, setDisabled] = useState(true);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new entity ******/
  /***************************************************************/
  /***************************************************************/

  // this represent the id of the entity
  const [id, setId] = useState("");

  const [form, setForm] = useState({
    entidad: null,
    ruc: null,
    status: null,
  });

  // this will be used to save the entity before update
  const [entityBeforeUpdate, setEntityBeforeUpdate] = useState({});

  // this function will process the data result of method getProfileEntity
  // this function will be process the result of the method getListEntity
  const handleGetProfileEntityDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        setId(response.id);
        setForm({
          ...response,
          preview: `${process.env.REACT_APP_S3_URL}${response.path}`,
        });

        setEntityBeforeUpdate({
            ...response,
            preview: `${process.env.REACT_APP_S3_URL}${response.path}`,
          });

        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  // this function will be used to get profile of entity by ruc
  const getProfileEntity = () => {
    try {
      try {
        // set the header of the request
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        const { id } = match.params;

        request(
          {
            url: `/auth/entity/${id}`,
            method: "GET",
            headers: myHeaders,
          },
          handleGetProfileEntityDataResponse
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // i have different question here
    getProfileEntity();
    // these function will be used to get all documents of the entity
    //getDocuments();
  }, []);

  const cancelBeforeUpdate = () => {
    setForm({ ...entityBeforeUpdate });

    setDisabled(true);
  };

  const updateEntityDataResponse = (response) => {
    if (response.status && response.errorCode) {
      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push("/entity-edited");
    }
  };

  const handleSubmit = () => {
    try {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/entity/${id}`,
          method: "PUT",
          headers: myHeaders,
          body: {...form},
        },
        updateEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusEntityDataResponse = (res) => {
    getProfileEntity();
    return;
  };

  const onHandleStatusChange = () => {
    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );


    request(
      {
        url: `/auth/entity/${id}/${!form.status}`,
        method: "PUT",
        headers: myHeaders,
        body: {...form},
      },
      changeStatusEntityDataResponse
    );
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  
  const validateRuc = (value) => {
    if (!value) {
      return 'RUC no válido';
    }
  
    const rucPattern = /^[0-9]{13}$/;
  
    if (!rucPattern.test(value)) {
      return 'RUC no válido';
    }
  
    const provincia = Number(value.substring(0, 2));
    if (provincia < 1 || provincia > 24) {
      return 'RUC no válido';
    }
  
    const establecimiento = Number(value.substring(10, 13));
    if (establecimiento !== 1) {
      return 'RUC no válido';
    }
  
    return true;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                  >
                    <h4 className="card-title">DATOS BANNER</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="entity-update-entidad"
                          >
                            Entidad
                          </Label> <br />
                          <AvField
                            name="entidad"
                            type="text"
                            errorMessage="Ingrese la razón social"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="entity-update-entidad"
                            value={form.entidad}
                            disabled={disabled}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="entity-update-ruc"
                          >
                            Ruc 
                          </Label> <br />
                          <AvField
                            name="ruc"
                            type="number"
                            errorMessage="Ingrese el ruc"
                            className="form-control"
                            validate={{ required: { value: true }, custom: validateRuc}}
                            id="entity-update-ruc"
                            value={form.ruc}
                            disabled={disabled}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mb-3 ">
                        <Label
                          className="form-label"
                          htmlFor="validationCustom98"
                        >
                          Estado Entidad 
                        </Label><br />
                        <div className="d-flex"> <AvField
                          name="status"
                          type="text"
                          className="form-control container-fluid"
                          id="validationCustom98"
                          value={form.status ? "Activo" : "Inactivo"}
                          disabled
                        />
                        <div>
                          <button
                            className={`btn ${
                              !form.status ? "btn-primary" : "btn-danger"
                            }`}
                            onClick={() => onHandleStatusChange()}
                            type="button"
                          >
                            {!form.status ? (
                              <AiFillCheckCircle />
                            ) : (
                              <AiFillCloseCircle />
                            )}
                          </button>
                        </div></div>
                      </Col>
                    </Row>

                    {disabled ? (
                      <Button
                        className="btn btn-primary-lexmark"
                        type="button"
                        onClick={() => {
                          setDisabled(false);
                        }}
                      >
                        Editar
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="btn btn-primary-danger mx-2"
                          type="button"
                          onClick={cancelBeforeUpdate}
                        >
                          Cancelar
                        </Button>

                        <Button
                          className="btn btn-primary-lexmark"
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EntityDetails;