import { AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { BsCursor, BsFillArrowDownCircleFill, BsMinecartLoaded, BsPencilSquare, BsPhone, BsPlusCircle, BsRecord, BsRecycle } from 'react-icons/bs';
import { Row } from 'reactstrap';

import { saveAs } from 'file-saver'
import useHttp from "../../../hook/use-http";

// this variable will represent different supplies
const supplies = [
    {
        id: 1,
        name: 'DISTRIBUIDOR AUTORIZADO',
        title: 'DISTRIBUIDOR_AUTORIZADO',
        icon: <BsMinecartLoaded />,
        code: "CER_SUM_001"
    },
    {
        id: 2,
        name: 'GARANTÍA TÉCNICA',
        title: 'GARANTÍA_TÉCNICA',
        icon: <BsPhone />,
        code: "CER_SUM_002"
    },
    {
        id: 3,
        name: 'COMUNICADO DE ORIGINALIDAD',
        title: 'COMUNICADO_DE_ORIGINALIDAD',
        icon: <BsPencilSquare />,
        code: "CER_SUM_003"

    },
    {
        id: 4,
        name: 'CERTIFICADO AMBIENTAL',
        title: 'CERTIFICADO_AMBIENTAL',
        icon: <BsRecycle />,
        code: "CER_SUM_004"
    },
    {
        id: 5,
        name: 'CERTIFICADO ISO',
        title: 'CERTIFICADO_ISO',
        icon: <BsRecord />,
        code: "CER_SUM_005"
    },
    {
        id: 6,
        name: 'CERTIFICADO SUMINISTROS NUEVOS',
        title: 'CERTIFICADO_SUMINISTROS_NUEVOS',
        icon: <BsPlusCircle />,
        code: "CER_SUM_006"
    }
]

const SupplyAdmin = ({ handleChangeListCertificatedSelected, certificationSelected, estado, match, codeRequest }) => {

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    const [statusRequest, setStatusRequest] = useState(estado);

    useEffect(() => {

        try {

            certificationSelected.forEach(element => {

                const certification = supplies.find(item => {
                    return item.code === element
                })

                if (certification) {
                    // now we need to change the color of the div component
                    let container = document.getElementById(certification.name);
                    container.style.backgroundColor = '#008945'
                    container.style.color = 'white'

                    let title = document.getElementById(certification.title)
                    title.style.color = 'white'
                }
            });

        } catch (error) {
            console.log(error)
        }

    }, [])

    const handleRequestDownloadDataResponse = async (response) => {
        const result = await response.blob();
        const blob = new Blob([result], { type: 'application/pdf' })
        saveAs(blob, "certificados.pdf")
    }

    const handleRequestDownload = () => {

        try {
            try {
                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                myHeaders.append("Accept", "application/pdf");
                myHeaders.append("responseType", "blob");

                request({
                    url: '/auth/certificado/download/' + codeRequest,
                    method: 'GET',
                    headers: myHeaders,
                }, handleRequestDownloadDataResponse)

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const renderedSupplies = Object.values(supplies).map(item => {
        return (
            <div
                key={item.id}
                id={item.name}
                className="form-check" style={{
                    border: '1px solid #CDCDCD',
                    marginTop: 20
                }}
            >
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                        <i style={{ fontSize: 25, marginRight: 10 }}>
                            {item.icon}
                        </i>
                        <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                            {item.name}
                        </h6>
                    </div>
                    {/*
                    <div style={{ marginRight: 30, display: 'flex' }}>
                        <input style={{ margin: 'auto' }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                     */}
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    CERTIFICADO
                </h4>
                {
                    estado === 'APROBADO' && (
                        <button
                            className="btn btn-info"
                            onClick={handleRequestDownload}
                            style={{ backgroundColor: '#3af23a', color: 'white' }}
                        >
                            <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillArrowDownCircleFill />
                            </div>
                        </button>
                    )
                }
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </>
    )

}

export default SupplyAdmin;