import React, { useState, useEffect } from "react"
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { addCounterLineInCertificateById } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";


const Item = ({ id, addItemIntoListCertification, nombresIntput, apellidosInput, cedulaInput, removeItemIntoListCertification, code }) => {

    const [idItem, setIdItem] = useState(id)

    const [isAdmin, setIsAdmin] = useState(false);
    const statusCertification = useSelector(state => state.TeamCertifications.statusCertification)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCounterLineInCertificateById(code))
    }, [])

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role === 'ROLE_ADMIN') {
            setIsAdmin(true);
        }
        console.log(statusCertification)
    }, [])

    // this variable will be uses to create a technical certification
    const [nombres, setNombres] = useState(cedulaInput)
    const [apellidos, setApellidos] = useState(apellidosInput)
    const [cedula, setCedula] = useState(nombresIntput + ' ' + apellidosInput)

    useEffect(() => {
        setNombres(cedulaInput)
        setApellidos(apellidosInput)
        setCedula(nombresIntput + ' ' + apellidosInput)
    }, [cedulaInput, apellidosInput, nombresIntput])
    //const [cargo, setCargo] = useState('')

    // this variable represent if the component is or not deleted
    const [isDeleted, setIsDeleted] = useState(false)
    // this variable control if the component was clicked 
    const [isClicked, setIsClicked] = useState(false)

    // this will be used to send the product to the parent component
    // but this will be send only if the all inputs are completed
    useEffect(() => {
        if (nombres.trim() && cedula.trim()) {
            addItemIntoListCertification({ id, nombres, cedula, apellidos })
        }
    }, [nombres, cedula, apellidos])

    // this function will be used to delete the component
    const deleteComponentClicked = () => {
        setIsDeleted(true)
        removeItemIntoListCertification(idItem)
    }

    return (
        <>  {!isDeleted && (
            <div>
                <div className="row mb-1 mt-3">
                    <div className="col-7">
                        <p style={{ fontWeight: 'bold' }}>
                            NOMBRES Y APELLIDOS
                        </p>
                    </div>
                    {/** 
                    <div className="col-4">
                        <p style={{ fontWeight: 'bold' }}>
                            APELLIDOS
                        </p>
                    </div>
                    */}
                    <div className="col-5">
                        <p style={{ fontWeight: 'bold' }}>
                            CEDULA
                        </p>
                    </div>
                </div>

                <div className="row">

                    <AvForm className="needs-validation row" style={{ margin: 0, padding: 0 }}>

                        {isAdmin ? (
                            <div className="col-7">
                                <input
                                    class="form-control"
                                    type="text"
                                    value={cedula}
                                    onChange={(e) => setCedula(e.target.value)}
                                    disabled
                                />
                            </div>
                        ) : (

                            <div className="col-7">
                                <input
                                    class="form-control"
                                    type="text"
                                    value={cedula}
                                    onChange={(e) => setCedula(e.target.value)}
                                    disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}

                                />
                            </div>
                        )}

                        {/** 
                    {isAdmin ? (

                        <div className="col-4">
                            <input
                                class="form-control"
                                type="text"
                                value={apellidos}
                                onChange={(e) => setApellidos(e.target.value)}
                                disabled
                            />
                        </div>
                    ) : (

                        <div className="col-4">
                            <input
                                class="form-control"
                                type="text"
                                value={apellidos}
                                onChange={(e) => setApellidos(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}

                            />
                        </div>
                    )}

                    */}

                        {isAdmin ? (

                            <div className="col-5 d-flex">
                                <input
                                    class="form-control"
                                    type="number"
                                    value={nombres}
                                    onChange={(e) => setNombres(e.target.value)}
                                    style={{
                                        borderColor: `${isClicked ? 'red' : ''}`
                                    }}
                                    disabled
                                />
                            </div>
                        ) : (

                            <div className="col-5 d-flex">

                                {/* 
                                <input
                                    class="form-control"
                                    type="number"
                                    value={nombres}
                                    onChange={(e) => setNombres(e.target.value)}
                                    style={{
                                        borderColor: `${isClicked ? 'red' : ''}`
                                    }}
                                    disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}

                                />
                                */}

                                <AvField
                                    name="cedula"
                                    type="number"
                                    errorMessage="Ingrese la cedula"
                                    className="form-control"
                                    validate={{ required: { value: true }, minLength: { value: 10, errorMessage: 'La cedula debe contener 10 caracteres numéricos ' }, maxLength: { value: 10, errorMessage: 'La cedula debe contener 10 caracteres numéricos ' } }}
                                    id="validationCustom01"
                                    value={nombres}
                                    onChange={(e) => setNombres(e.target.value)}
                                    disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}
                                />

                                {statusCertification === 'RECHAZADO' && (
                                    <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                        deleteComponentClicked
                                    }>

                                        < BsFillXCircleFill />

                                    </button>
                                )}

                                {statusCertification === '' && (
                                    <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                        deleteComponentClicked
                                    }>

                                        < BsFillXCircleFill />

                                    </button>
                                )}
                            </div>
                        )}

                    </AvForm>
                </div>
            </div>
        )}
        </>
    )

}

export default Item;