import * as types from './actionType'

const initialState = {
    certifications: [],
    isLoading: false,
    isError: false,
    certificationResponse: [],
    isAdmin: false,
    statusCertification: ''
}

export const TeamCertifications = (state = initialState, action) => {

    const { type } = action

    switch (type) {
        case types.GET_TEAM_CERTIFICATE_LIST:
            return {
                ...state
            }

        case types.REMOVE_ALL_CERTIFICATE_SELECTED:
            return {
                ...state,
                certifications: []
            }

        case types.REMOVE_ALL_COUNTER_IN_CERTIFICATE_INITIAL:

            const { title, counter } = action.payload

            const cert = Object.values(state.certifications).find(i => {
                return i.nameCertificate === title
            })

            if (cert) {

                let result = cert
                result.counter = counter

                return {
                    ...state,
                    certifications: [
                        ...state.certifications,
                        result
                    ]
                }
            }


            return {
                ...state,
                certifications: [
                    ...state.certifications]
            }



        case types.ADD_ITEMS_CERTIFICATE_INTO_LIST:


            let { idCertification, items, softwares, nameCertificate = '', actionType } = action.payload

            
            // when i have the first item i include in the list
            // this will be used when you wanna delete a element 
            if (state.certifications.length == 0 && items.length > 0) {
                const first = {
                    id: idCertification,
                    items: items,
                    softwares: softwares,
                    nameCertificate: nameCertificate
                }

                return {
                    ...state,
                    certifications: [
                        ...state.certifications,
                        first
                    ]
                }
            }

            if (actionType === 'REMOVE') {


                let certs = state.certifications.filter(cer => {
                    return cer.id !== idCertification
                })

                let actual = state.certifications.filter(cer => {
                    return cer.id === idCertification
                })

                let cou = actual[0].counterLines

                return {
                    ...state,
                    certifications: [
                        ...certs,
                        {
                            id: idCertification,
                            items: items,
                            softwares: softwares,
                            nameCertificate: nameCertificate,
                            counterLines: cou - 1,
                            counterItems: actual[0].counterItems
                        }
                    ]
                }
            }

            // this when you wanna to add a new item
            if (items.length >= 0) {

                let certs = state.certifications.filter(cer => {
                    return cer.id !== idCertification
                })

                let actual = state.certifications.filter(cer => {
                    return cer.id === idCertification
                })

                return {
                    ...state,
                    certifications: [
                        ...certs,
                        {
                            id: idCertification,
                            items: items,
                            softwares: softwares,
                            nameCertificate: nameCertificate,
                            counterItems: items.length === 0 ? null : softwares.length > 0 ? items.length + softwares.length : items.length,
                            counterLines: actual.length > 0 ? actual[0].counterLines : null
                        }
                    ]
                }

            } else {
                return {
                    ...state
                }
            }

        case types.ADD_COUNTER_LINE_CERTIFICATION_BY_ID:

            let { id_cert } = action.payload

            let myCertificate = state.certifications.find(cer => {
                return cer.id === id_cert
            })

            myCertificate.counterLines = myCertificate.counterLines === undefined ? 1 : myCertificate.counterLines + 1

            let certs = state.certifications.filter(cer => {
                return cer.id !== id_cert
            })

            let result = []

            // the problem in this case i want to save a array but that is a 

            if (certs.length > 0) {


                result = certs.map(i => {
                    return i
                })

                result.push(
                    {
                        counterItems: myCertificate?.counterItems,
                        counterLines: myCertificate?.counterLines,
                        id: myCertificate?.id,
                        items: myCertificate?.items,
                        nameCertificate: myCertificate?.nameCertificate,
                        softwares: myCertificate?.softwares
                    }
                )

            } else {
                result = [myCertificate]
            }

            return {
                ...state,
                certifications: result
            }

        case types.DELETE_COUNTER_IN_CERTIFICATE: {

            let { id_cert } = action.payload

            let myCertificate = state.certifications.find(cer => {
                return cer.id === id_cert
            })

            myCertificate.counterLines = myCertificate.counterLines - 1

            let certs = state.certifications.filter(cer => {
                return cer.id !== id_cert
            })

            let result = []

            if (certs.length > 0) {

                result = certs.map(i => {
                    return i
                })

                result.push(
                    {
                        counterItems: myCertificate?.counterItems,
                        counterLines: myCertificate?.counterLines,
                        id: myCertificate?.id,
                        items: myCertificate?.items,
                        nameCertificate: myCertificate?.nameCertificate,
                        softwares: myCertificate?.softwares
                    }
                )

            } else {
                result = [myCertificate]
            }

            return {
                ...state,
                certifications: result
            }

        }

        case types.ADD_ALL_CERTIFICATIONS_FROM_REQUEST:
            let { certifications } = action.payload
            return {
                ...state,
                certificationResponse: certifications
            }



        case types.ADD_STATUS_ADMIN:
            let { status } = action.payload
            return {
                ...state,
                isAdmin: status
            }

        case types.ADD_STATUS_CERTIFICATION: {
            let { statusCertification } = action.payload
            return {
                ...state,
                statusCertification: statusCertification
            }
        }

        default:
            return state;
    }
}