import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

const GroupCreated = ({ history }) => {

    const [nameGroup, setNameGroup] = useState('')

    useEffect(() => {

        if (localStorage.getItem("nameGroupCreated")) {
            setNameGroup(localStorage.getItem("nameGroupCreated"))
            localStorage.removeItem("nameGroupCreated")
        }

    }, [])

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={4}>
                            <Card outline color="success" className="border">
                                <CardHeader className="bg-transparent">
                                    <h5 className="my-0 text-success"><i className="mdi mdi-check-all me-3"></i>GRUPO CREADO CON EXITO</h5>
                                </CardHeader>
                                <CardBody>
                                    {/*
                                    <CardTitle className="h4">card title</CardTitle>
                                     */}
                                    <CardText>En este momento su grupo se ha creado con exito, por favor diríjase a la lista de grupos para visualizarlo.</CardText>
                                    <CardText>En nombre del grupo es <span style={{ fontWeight: 'bold' }}></span>{nameGroup}</CardText>
                                    <Button
                                        className='btn btn-primary-lexmark'
                                        onClick={() => history.push('/opportunity-groups')}
                                    >Lista Grupo</Button>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default GroupCreated;