import React from 'react';

import './pagination.css'

const Pagination = ({ commentsPerPage, totalComments, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalComments / commentsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className='pagination' >
                {pageNumbers.map(number => (
                    <li key={number}
                        className="page-item"
                    >
                        <a onClick={() => paginate(number)}
                            className={
                                `${currentPage === number ? ' page-selected' : 'page-link'}`
                            }
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
