const ListSuppliesJson = [
    {"np":"08A0477","Model":"E320/22","Product":"E320, E322 High Yield Print Cartridge","Yield":"6000","AA":"08A0477E320/22","RR":"NO"},
    {"np":"08A0478","Model":"E320/22","Product":"E320, E322 High Yield Return Program Print Cartridge","Yield":"6000","AA":"08A0478E320/22","RR":"NO"},
    {"np":"1025041","Model":"C925de","Product":"Lexmark 256MB DDR2-DRAM","Yield":"","AA":"1025041C925de","RR":"NO"},
    {"np":"1025041","Model":"C950de","Product":"Lexmark 256MB DDR2-DRAM","Yield":"","AA":"1025041C950de","RR":"NO"},
    {"np":"1025041","Model":"X925de","Product":"Lexmark 256MB DDR2-DRAM","Yield":"","AA":"1025041X925de","RR":"NO"},
    {"np":"1025041","Model":"X950de","Product":"Lexmark 256MB DDR2-DRAM","Yield":"","AA":"1025041X950de","RR":"NO"},
    {"np":"1025041","Model":"X952dte","Product":"Lexmark 256MB DDR2-DRAM","Yield":"","AA":"1025041X952dte","RR":"NO"},
    {"np":"1025042","Model":"C925de","Product":"Lexmark 512MB DDR2-DRAM","Yield":"","AA":"1025042C925de","RR":"NO"},
    {"np":"1025042","Model":"C950de","Product":"Lexmark 512MB DDR2-DRAM","Yield":"","AA":"1025042C950de","RR":"NO"},
    {"np":"1025042","Model":"X925de","Product":"Lexmark 512MB DDR2-DRAM","Yield":"","AA":"1025042X925de","RR":"NO"},
    {"np":"1025042","Model":"X950de","Product":"Lexmark 512MB DDR2-DRAM","Yield":"","AA":"1025042X950de","RR":"NO"},
    {"np":"1025042","Model":"X952dte","Product":"Lexmark 512MB DDR2-DRAM","Yield":"","AA":"1025042X952dte","RR":"NO"},
    {"np":"1025043","Model":"C925de","Product":"Lexmark 1024MB DDR2-DRAM","Yield":"","AA":"1025043C925de","RR":"NO"},
    {"np":"1025043","Model":"C950de","Product":"Lexmark 1024MB DDR2-DRAM","Yield":"","AA":"1025043C950de","RR":"NO"},
    {"np":"1025043","Model":"X925de","Product":"Lexmark 1024MB DDR2-DRAM","Yield":"","AA":"1025043X925de","RR":"NO"},
    {"np":"1025043","Model":"X950de","Product":"Lexmark 1024MB DDR2-DRAM","Yield":"","AA":"1025043X950de","RR":"NO"},
    {"np":"1025043","Model":"X952dte","Product":"Lexmark 1024MB DDR2-DRAM","Yield":"","AA":"1025043X952dte","RR":"NO"},
    {"np":"10B3100","Model":"C750","Product":"C75x, C76x, C77x, C78x Waste Toner Container","Yield":"50000","AA":"10B3100C750","RR":"NO"},
    {"np":"10S0150","Model":"E210","Product":"E210 Print Cartridge","Yield":"2000","AA":"10S0150E210","RR":"NO"},
    {"np":"11C0099","Model":"2580+","Product":"9-Pin Dot Matrix","Yield":"","AA":"11C00992580+","RR":"NO"},
    {"np":"11C0109","Model":"2580n+","Product":"9-Pin Dot Matrix, network","Yield":"","AA":"11C01092580n+","RR":"NO"},
    {"np":"11C0111","Model":"2581+","Product":"9-Pin Dot Matrix","Yield":"","AA":"11C01112581+","RR":"NO"},
    {"np":"11C2956","Model":"2581n+","Product":"9-Pin Dot Matrix, network","Yield":"","AA":"11C29562581n+","RR":"NO"},
    {"np":"11K3188","Model":"Finisher Staples","Product":"Staple Cartridges","Yield":"9000","AA":"11K3188Finisher Staples","RR":"NO"},
    {"np":"12018SL","Model":"E120","Product":"E120 Return Program Toner Cartridge","Yield":"2000","AA":"12018SLE120","RR":"NO"},
    {"np":"12026XW","Model":"E120","Product":"E120 Photoconductor Kit","Yield":"25000","AA":"12026XWE120","RR":"NO"},
    {"np":"12038SL","Model":"E120","Product":"E120 Toner Cartridge","Yield":"2000","AA":"12038SLE120","RR":"NO"},
    {"np":"12A4710","Model":"X422","Product":"X422 Return Program Print Cartridge (6K)","Yield":"6000","AA":"12A4710X422","RR":"NO"},
    {"np":"12A4715","Model":"X422","Product":"X422 High Yield Return Program Print Cartridge (12K)","Yield":"12000","AA":"12A4715X422","RR":"NO"},
    {"np":"12A5840","Model":"Optra T","Product":"Optra T Return Program Print Cartridge (10k)","Yield":"10000","AA":"12A5840Optra T","RR":"NO"},
    {"np":"12A5845","Model":"Optra T","Product":"Optra T High Yield Return Program Print Cartridge (25k)","Yield":"25000","AA":"12A5845Optra T","RR":"NO"},
    {"np":"12A5849","Model":"Optra T","Product":"Optra T High Yield Return Program Print Cartridge for Labels","Yield":"25000","AA":"12A5849Optra T","RR":"NO"},
    {"np":"12A6830","Model":"Optra T","Product":"T520, T522 Return Program Print Cartridge","Yield":"7500","AA":"12A6830Optra T","RR":"NO"},
    {"np":"12A6835","Model":"Optra T","Product":"T520, T522 High Yield Return Program Print Cartridge","Yield":"20000","AA":"12A6835Optra T","RR":"NO"},
    {"np":"12A6839","Model":"Optra T","Product":"T520, T522 High Yield Return Program Print Cartridge Labels","Yield":"20000","AA":"12A6839Optra T","RR":"NO"},
    {"np":"12A6860","Model":"Optra T","Product":"T620, T622 Return Program Print Cartridge","Yield":"10000","AA":"12A6860Optra T","RR":"NO"},
    {"np":"12A6865","Model":"Optra T","Product":"T620, T622 High Yield Return Program Print Cartridge","Yield":"30000","AA":"12A6865Optra T","RR":"NO"},
    {"np":"12A6869","Model":"Optra T","Product":"T620, T622 High Yield Return Program Print Cartridge Labels","Yield":"30000","AA":"12A6869Optra T","RR":"NO"},
    {"np":"12A7315","Model":"T420","Product":"T420 High Yield Print Cartridge","Yield":"10000","AA":"12A7315T420","RR":"NO"},
    {"np":"12A7400","Model":"E321/23","Product":"E321, E323 Return Program Print Cartridge","Yield":"3000","AA":"12A7400E321/23","RR":"NO"},
    {"np":"12A7405","Model":"E321/23","Product":"E321, E323 High Yield Return Program Print Cartridge","Yield":"6000","AA":"12A7405E321/23","RR":"NO"},
    {"np":"12A7410","Model":"T420","Product":"T420 Return Program Print Cartridge","Yield":"5000","AA":"12A7410T420","RR":"NO"},
    {"np":"12A7415","Model":"T420","Product":"T420 High Yield Return Program Print Cartridge","Yield":"10000","AA":"12A7415T420","RR":"NO"},
    {"np":"12A7460","Model":"T63x","Product":"T630, T632, T634 Return Program Print Cartridge","Yield":"5000","AA":"12A7460T63x","RR":"NO"},
    {"np":"12A7462","Model":"T63x","Product":"T630, T632, T634 High Yield Return Program Print Cartridge","Yield":"21000","AA":"12A7462T63x","RR":"NO"},
    {"np":"12A7465","Model":"T63x","Product":"T632, T634 Extra High Yield Return Program Print Cartridge","Yield":"32000","AA":"12A7465T63x","RR":"NO"},
    {"np":"12A7468","Model":"T63x","Product":"T630, T632, T634 High Yield Return Program Print Cartridge for Labels","Yield":"21000","AA":"12A7468T63x","RR":"NO"},
    {"np":"12A7469","Model":"T63x","Product":"T632, T634 Extra High Yield Return Program Print Cartridge for Labels","Yield":"32000","AA":"12A7469T63x","RR":"NO"},
    {"np":"12A8302","Model":"E23/33/24/34x","Product":"E230, E232, E238, E240, E33x, E34x Photoconductor Kit","Yield":"30000","AA":"12A8302E23/33/24/34x","RR":"NO"},
    {"np":"12A8325","Model":"T430","Product":"T430 High Yield Print Cartridge","Yield":"12000","AA":"12A8325T430","RR":"NO"},
    {"np":"12A8420","Model":"T430","Product":"T430 Return Program Print Cartridge","Yield":"6000","AA":"12A8420T430","RR":"NO"},
    {"np":"12A8425","Model":"T430","Product":"T430 High Yield Return Program Print Cartridge","Yield":"12000","AA":"12A8425T430","RR":"NO"},
    {"np":"12B0090","Model":"W820","Product":"W820, X820e Print Cartridge","Yield":"30000","AA":"12B0090W820","RR":"NO"},
    {"np":"12L0252","Model":"Finisher Staples","Product":"W820/W810/X830e/X832e Staple Cartridges (3 pack)","Yield":"15000","AA":"12L0252Finisher Staples","RR":"NO"},
    {"np":"12N0768","Model":"C91x","Product":"C910, C912 Cyan Toner Cartridge","Yield":"14000","AA":"12N0768C91x","RR":"NO"},
    {"np":"12N0769","Model":"C91x","Product":"C910, C912 Magenta Toner Cartridge","Yield":"14000","AA":"12N0769C91x","RR":"NO"},
    {"np":"12N0770","Model":"C91x","Product":"C910, C912 Yellow Toner Cartridge","Yield":"14000","AA":"12N0770C91x","RR":"NO"},
    {"np":"12N0771","Model":"C91x","Product":"C910, C912 Black Toner Cartridge","Yield":"14000","AA":"12N0771C91x","RR":"NO"},
    {"np":"12N0772","Model":"C91x","Product":"C920, C91x Color Photodeveloper Kit (Set of 3, CYM)","Yield":"28000","AA":"12N0772C91x","RR":"NO"},
    {"np":"12N0773","Model":"C91x","Product":"C920, C91x Black Photodeveloper","Yield":"28000","AA":"12N0773C91x","RR":"NO"},
    {"np":"12S0400","Model":"E220","Product":"E220 Return Program Print Cartridge","Yield":"2500","AA":"12S0400E220","RR":"NO"},
    {"np":"12T0693","Model":"2580+","Product":"Automatic Sheet Feed (narrow)","Yield":"","AA":"12T06932580+","RR":"NO"},
    {"np":"12T0693","Model":"2580n+","Product":"Automatic Sheet Feed (narrow)","Yield":"","AA":"12T06932580n+","RR":"NO"},
    {"np":"12T0694","Model":"2581+","Product":"Automatic Sheet Feed (wide)","Yield":"","AA":"12T06942581+","RR":"NO"},
    {"np":"12T0694","Model":"2581n+","Product":"Automatic Sheet Feed (wide)","Yield":"","AA":"12T06942581n+","RR":"NO"},
    {"np":"12T0695","Model":"2580+","Product":"Tractor 2 (narrow)","Yield":"","AA":"12T06952580+","RR":"NO"},
    {"np":"12T0695","Model":"2580n+","Product":"Tractor 2 (narrow)","Yield":"","AA":"12T06952580n+","RR":"NO"},
    {"np":"12T0696","Model":"2581+","Product":"Tractor 2 (wide)","Yield":"","AA":"12T06962581+","RR":"NO"},
    {"np":"12T0696","Model":"2581n+","Product":"Tractor 2 (wide)","Yield":"","AA":"12T06962581n+","RR":"NO"},
    {"np":"12T0697","Model":"2580+","Product":"Cut Sheet Output Support Stand","Yield":"","AA":"12T06972580+","RR":"NO"},
    {"np":"12T0697","Model":"2580n+","Product":"Cut Sheet Output Support Stand","Yield":"","AA":"12T06972580n+","RR":"NO"},
    {"np":"12T0697","Model":"2581+","Product":"Cut Sheet Output Support Stand","Yield":"","AA":"12T06972581+","RR":"NO"},
    {"np":"12T0697","Model":"2581n+","Product":"Cut Sheet Output Support Stand","Yield":"","AA":"12T06972581n+","RR":"NO"},
    {"np":"12T0698","Model":"2580+","Product":"Serial Interface Option","Yield":"","AA":"12T06982580+","RR":"NO"},
    {"np":"12T0698","Model":"2581+","Product":"Serial Interface Option","Yield":"","AA":"12T06982581+","RR":"NO"},
    {"np":"13L0034","Model":"4227 Dot Matrix","Product":"4227 Series Printer Ribbon","Yield":"","AA":"13L00344227 Dot Matrix","RR":"NO"},
    {"np":"14F0000","Model":"C950de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000C950de","RR":"NO"},
    {"np":"14F0000","Model":"CS921de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000CS921de","RR":"NO"},
    {"np":"14F0000","Model":"CS923de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000CS923de","RR":"NO"},
    {"np":"14F0000","Model":"CX921de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000CX921de","RR":"NO"},
    {"np":"14F0000","Model":"CX922de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000CX922de","RR":"NO"},
    {"np":"14F0000","Model":"CX923dte","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000CX923dte","RR":"NO"},
    {"np":"14F0000","Model":"MS810de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS810de","RR":"NO"},
    {"np":"14F0000","Model":"MS810dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS810dn","RR":"NO"},
    {"np":"14F0000","Model":"MS811dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS811dn","RR":"NO"},
    {"np":"14F0000","Model":"MS812de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS812de","RR":"NO"},
    {"np":"14F0000","Model":"MS812dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS812dn","RR":"NO"},
    {"np":"14F0000","Model":"MS821dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS821dn","RR":"NO"},
    {"np":"14F0000","Model":"MS823dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS823dn","RR":"NO"},
    {"np":"14F0000","Model":"MS826de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS826de","RR":"NO"},
    {"np":"14F0000","Model":"MS911de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MS911de","RR":"NO"},
    {"np":"14F0000","Model":"MX710dhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX710dhe","RR":"NO"},
    {"np":"14F0000","Model":"MX711dhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX711dhe","RR":"NO"},
    {"np":"14F0000","Model":"MX722adhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX722adhe","RR":"NO"},
    {"np":"14F0000","Model":"MX810dfe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX810dfe","RR":"NO"},
    {"np":"14F0000","Model":"MX810dme","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX810dme","RR":"NO"},
    {"np":"14F0000","Model":"MX810dpe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX810dpe","RR":"NO"},
    {"np":"14F0000","Model":"MX811dfe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX811dfe","RR":"NO"},
    {"np":"14F0000","Model":"MX811dme","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX811dme","RR":"NO"},
    {"np":"14F0000","Model":"MX811dpe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX811dpe","RR":"NO"},
    {"np":"14F0000","Model":"MX812dfe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX812dfe","RR":"NO"},
    {"np":"14F0000","Model":"MX812dme","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX812dme","RR":"NO"},
    {"np":"14F0000","Model":"MX812dpe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX812dpe","RR":"NO"},
    {"np":"14F0000","Model":"MX822ade","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX822ade","RR":"NO"},
    {"np":"14F0000","Model":"MX826ade","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX826ade","RR":"NO"},
    {"np":"14F0000","Model":"MX910de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX910de","RR":"NO"},
    {"np":"14F0000","Model":"MX911dte","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX911dte","RR":"NO"},
    {"np":"14F0000","Model":"MX912dxe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000MX912dxe","RR":"NO"},
    {"np":"14F0000","Model":"X925de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000X925de","RR":"NO"},
    {"np":"14F0000","Model":"X950de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000X950de","RR":"NO"},
    {"np":"14F0000","Model":"X952dte","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000X952dte","RR":"NO"},
    {"np":"14F0000","Model":"X954dhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"14F0000X954dhe","RR":"NO"},
    {"np":"14F0037","Model":"X925de","Product":"Lexmark MarkNet N8120 Gigabit Ethernet Print Server","Yield":"","AA":"14F0037X925de","RR":"NO"},
    {"np":"14F0042","Model":"C950de","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"14F0042C950de","RR":"NO"},
    {"np":"14F0042","Model":"X925de","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"14F0042X925de","RR":"NO"},
    {"np":"14F0042","Model":"X950de","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"14F0042X950de","RR":"NO"},
    {"np":"14F0042","Model":"X952dte","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"14F0042X952dte","RR":"NO"},
    {"np":"14F0042","Model":"X954dhe","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"14F0042X954dhe","RR":"NO"},
    {"np":"14F0100","Model":"C950de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100C950de","RR":"NO"},
    {"np":"14F0100","Model":"CS921de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100CS921de","RR":"NO"},
    {"np":"14F0100","Model":"CS923de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100CS923de","RR":"NO"},
    {"np":"14F0100","Model":"CX921de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100CX921de","RR":"NO"},
    {"np":"14F0100","Model":"CX922de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100CX922de","RR":"NO"},
    {"np":"14F0100","Model":"CX923dte","Product":"Lexmark RS-232C Serial Interface Card","Yield":"","AA":"14F0100CX923dte","RR":"NO"},
    {"np":"14F0100","Model":"MS810de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS810de","RR":"NO"},
    {"np":"14F0100","Model":"MS810dn","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS810dn","RR":"NO"},
    {"np":"14F0100","Model":"MS811dn","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS811dn","RR":"NO"},
    {"np":"14F0100","Model":"MS812de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS812de","RR":"NO"},
    {"np":"14F0100","Model":"MS812dn","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS812dn","RR":"NO"},
    {"np":"14F0100","Model":"MS821dn","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS821dn","RR":"NO"},
    {"np":"14F0100","Model":"MS823dn","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS823dn","RR":"NO"},
    {"np":"14F0100","Model":"MS826de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS826de","RR":"NO"},
    {"np":"14F0100","Model":"MS911de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MS911de","RR":"NO"},
    {"np":"14F0100","Model":"MX710dhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX710dhe","RR":"NO"},
    {"np":"14F0100","Model":"MX711dhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX711dhe","RR":"NO"},
    {"np":"14F0100","Model":"MX722adhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX722adhe","RR":"NO"},
    {"np":"14F0100","Model":"MX810dfe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX810dfe","RR":"NO"},
    {"np":"14F0100","Model":"MX810dme","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX810dme","RR":"NO"},
    {"np":"14F0100","Model":"MX810dpe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX810dpe","RR":"NO"},
    {"np":"14F0100","Model":"MX811dfe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX811dfe","RR":"NO"},
    {"np":"14F0100","Model":"MX811dme","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX811dme","RR":"NO"},
    {"np":"14F0100","Model":"MX811dpe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX811dpe","RR":"NO"},
    {"np":"14F0100","Model":"MX812dfe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX812dfe","RR":"NO"},
    {"np":"14F0100","Model":"MX812dme","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX812dme","RR":"NO"},
    {"np":"14F0100","Model":"MX812dpe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX812dpe","RR":"NO"},
    {"np":"14F0100","Model":"MX822ade","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX822ade","RR":"NO"},
    {"np":"14F0100","Model":"MX826ade","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX826ade","RR":"NO"},
    {"np":"14F0100","Model":"MX910de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX910de","RR":"NO"},
    {"np":"14F0100","Model":"MX911dte","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX911dte","RR":"NO"},
    {"np":"14F0100","Model":"MX912dxe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100MX912dxe","RR":"NO"},
    {"np":"14F0100","Model":"X925de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100X925de","RR":"NO"},
    {"np":"14F0100","Model":"X950de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100X950de","RR":"NO"},
    {"np":"14F0100","Model":"X952dte","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100X952dte","RR":"NO"},
    {"np":"14F0100","Model":"X954dhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"14F0100X954dhe","RR":"NO"},
    {"np":"14F0245","Model":"C925de","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245C925de","RR":"NO"},
    {"np":"14F0245","Model":"C950de","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245C950de","RR":"NO"},
    {"np":"14F0245","Model":"X925de","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245X925de","RR":"NO"},
    {"np":"14F0245","Model":"X950de","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245X950de","RR":"NO"},
    {"np":"14F0245","Model":"X952dte","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245X952dte","RR":"NO"},
    {"np":"14F0245","Model":"X954dhe","Product":"Lexmark 256MB Flash Card","Yield":"","AA":"14F0245X954dhe","RR":"NO"},
    {"np":"14K0050","Model":"W812","Product":"W812 Print Cartridge","Yield":"12000","AA":"14K0050W812","RR":"NO"},
    {"np":"15G041C","Model":"C752/C76x","Product":"C752L, C752, C760, C762 Cyan Return Program Print Cartridge","Yield":"6000","AA":"15G041CC752/C76x","RR":"NO"},
    {"np":"15G041K","Model":"C752/C76x","Product":"C752L, C752, C760, C762 Black Return Program Print Cartridge","Yield":"6000","AA":"15G041KC752/C76x","RR":"NO"},
    {"np":"15G041M","Model":"C752/C76x","Product":"C752L, C752, C760, C762 Magenta Return Program Print Cartridge","Yield":"6000","AA":"15G041MC752/C76x","RR":"NO"},
    {"np":"15G041Y","Model":"C752/C76x","Product":"C752L, C752, C760, C762 Yellow Return Program Print Cartridge","Yield":"6000","AA":"15G041YC752/C76x","RR":"NO"},
    {"np":"15G042C","Model":"C752/C76x","Product":"C752, C762 Cyan High Yield Return Program Print Cartridge","Yield":"15000","AA":"15G042CC752/C76x","RR":"NO"},
    {"np":"15G042K","Model":"C752/C76x","Product":"C752, C762 Black High Yield Return Program Print Cartridge","Yield":"15000","AA":"15G042KC752/C76x","RR":"NO"},
    {"np":"15G042M","Model":"C752/C76x","Product":"C752, C762 Magenta High Yield Return Program Print Cartridge","Yield":"15000","AA":"15G042MC752/C76x","RR":"NO"},
    {"np":"15G042Y","Model":"C752/C76x","Product":"C752, C762 Yellow High Yield Return Program Print Cartridge","Yield":"15000","AA":"15G042YC752/C76x","RR":"NO"},
    {"np":"16J0300","Model":"MS810dn","Product":"MX6500e (Brazil/ LAD- 110V)","Yield":"","AA":"16J0300MS810dn","RR":"NO"},
    {"np":"16J0300","Model":"MS811dn","Product":"MX6500e (Brazil/ LAD- 110V)","Yield":"","AA":"16J0300MS811dn","RR":"NO"},
    {"np":"16J0300","Model":"MS812dn","Product":"MX6500e (Brazil/ LAD- 110V)","Yield":"","AA":"16J0300MS812dn","RR":"NO"},
    {"np":"16J0303","Model":"MS810dn","Product":"MX6500e (Argentina)","Yield":"","AA":"16J0303MS810dn","RR":"NO"},
    {"np":"16J0303","Model":"MS811dn","Product":"MX6500e (Argentina)","Yield":"","AA":"16J0303MS811dn","RR":"NO"},
    {"np":"16J0303","Model":"MS812dn","Product":"MX6500e (Argentina)","Yield":"","AA":"16J0303MS812dn","RR":"NO"},
    {"np":"16J0305","Model":"MS810dn","Product":"MX6500e (Chile/ Uruguay)","Yield":"","AA":"16J0305MS810dn","RR":"NO"},
    {"np":"16J0305","Model":"MS811dn","Product":"MX6500e (Chile/ Uruguay)","Yield":"","AA":"16J0305MS811dn","RR":"NO"},
    {"np":"16J0305","Model":"MS812dn","Product":"MX6500e (Chile/ Uruguay)","Yield":"","AA":"16J0305MS812dn","RR":"NO"},
    {"np":"16J0334","Model":"MS810dn","Product":"MX6500e (Peru/ Bolivia/ Paraguay)","Yield":"","AA":"16J0334MS810dn","RR":"NO"},
    {"np":"16J0334","Model":"MS811dn","Product":"MX6500e (Peru/ Bolivia/ Paraguay)","Yield":"","AA":"16J0334MS811dn","RR":"NO"},
    {"np":"16J0334","Model":"MS812dn","Product":"MX6500e (Peru/ Bolivia/ Paraguay)","Yield":"","AA":"16J0334MS812dn","RR":"NO"},
    {"np":"17G0152","Model":"Optra M41x","Product":"Optra M410, M412 Print Cartridge","Yield":"5000","AA":"17G0152Optra M41x","RR":"NO"},
    {"np":"17G0154","Model":"Optra M41x","Product":"Optra M410, M412 High Yield Print Cartridge (15K)","Yield":"15000","AA":"17G0154Optra M41x","RR":"NO"},
    {"np":"18M0100","Model":"B2236dw","Product":"33/36 ppm, 256MB, 1 GHz, 2-line LCD display, WiFi,","Yield":"","AA":"18M0100B2236dw","RR":"NO"},
    {"np":"18M0400","Model":"MB2236adw","Product":"33/36 ppm, 512MB, 1 GHz, 2-line LCD & Num Pad display, WiFi,","Yield":"","AA":"18M0400MB2236adw","RR":"NO"},
    {"np":"18M0700","Model":"MB2236adwe","Product":"33/36 ppm, 256MB, 1 GHz,  2.8� touch screen display, WiFi,","Yield":"","AA":"18M0700MB2236adwe","RR":"NO"}
    ,
    {"np":"18S0090","Model":"X215","Product":"X215 Print Cartridge","Yield":"3200","AA":"18S0090X215","RR":"NO"},
    {"np":"20K0500","Model":"C510","Product":"C510 Cyan Toner Cartridge","Yield":"3000","AA":"20K0500C510","RR":"NO"},
    {"np":"20K0501","Model":"C510","Product":"C510 Magenta Toner Cartridge","Yield":"3000","AA":"20K0501C510","RR":"NO"},
    {"np":"20K0502","Model":"C510","Product":"C510 Yellow Toner Cartridge","Yield":"3000","AA":"20K0502C510","RR":"NO"},
    {"np":"20K0503","Model":"C510","Product":"C510 Black Toner Cartridge","Yield":"5000","AA":"20K0503C510","RR":"NO"},
    {"np":"20K0504","Model":"C510","Product":"C510 Photodeveloper Cartridge","Yield":"10000","AA":"20K0504C510","RR":"NO"},
    {"np":"20K0505","Model":"C510","Product":"C510 Waste Toner Bottle","Yield":"3000","AA":"20K0505C510","RR":"NO"},
    {"np":"20K1400","Model":"C510","Product":"C510 Cyan High Yield Toner Cartridge","Yield":"6600","AA":"20K1400C510","RR":"NO"},
    {"np":"20K1401","Model":"C510","Product":"C510 Magenta High Yield Toner Cartridge","Yield":"6600","AA":"20K1401C510","RR":"NO"},
    {"np":"20K1402","Model":"C510","Product":"C510 Yellow High Yield Toner Cartridge","Yield":"6600","AA":"20K1402C510","RR":"NO"},
    {"np":"20K1403","Model":"C510","Product":"C510 Black High Yield Toner Cartridge","Yield":"10000","AA":"20K1403C510","RR":"NO"},
    {"np":"20N0W00","Model":"C3224dw","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W00C3224dw","RR":"NO"},
    {"np":"20N0W00","Model":"C3326dw","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W00C3326dw","RR":"NO"},
    {"np":"20N0W00","Model":"MC3224adwe","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W00MC3224adwe","RR":"NO"},
    {"np":"20N0W00","Model":"MC3224dwe","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W00MC3224dwe","RR":"NO"},
    {"np":"20N0W0","Model":"CS431dw","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W0CS431dw","RR":"NO"},
    {"np":"20N0W0","Model":"CX431adw","Product":"15K -Waste Toner Bottle","Yield":"15000","AA":"20N0W0CX431adw","RR":"NO"},
    {"np":"20N40C0","Model":"CS431dw","Product":"1.5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"1500","AA":"20N40C0CS431dw","RR":"NO"},
    {"np":"20N40C0","Model":"CX431adw","Product":"1.5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"1500","AA":"20N40C0CX431adw","RR":"NO"},
    {"np":"20N40K0","Model":"CS431dw","Product":"1.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40K0CS431dw","RR":"NO"},
    {"np":"20N40K0","Model":"CX431adw","Product":"1.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40K0CX431adw","RR":"NO"},
    {"np":"20N40M0","Model":"CS431dw","Product":"1.5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40M0CS431dw","RR":"NO"},
    {"np":"20N40M0","Model":"CX431adw","Product":"1.5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40M0CX431adw","RR":"NO"},
    {"np":"20N40Y0","Model":"CS431dw","Product":"1.5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40Y0CS431dw","RR":"NO"},
    {"np":"20N40Y0","Model":"CX431adw","Product":"1.5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"1500","AA":"20N40Y0CX431adw","RR":"NO"},
    {"np":"20N4XC0","Model":"CS431dw","Product":"6.7K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"6000","AA":"20N4XC0CS431dw","RR":"NO"},
    {"np":"20N4XC0","Model":"CX431adw","Product":"6.7K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"6000","AA":"20N4XC0CX431adw","RR":"NO"},
    {"np":"20N4XK0","Model":"CS431dw","Product":"6K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"6700","AA":"20N4XK0CS431dw","RR":"NO"},
    {"np":"20N4XK0","Model":"CX431adw","Product":"6K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"6700","AA":"20N4XK0CX431adw","RR":"NO"},
    {"np":"20N4XM0","Model":"CS431dw","Product":"6.7K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"6000","AA":"20N4XM0CS431dw","RR":"NO"},
    {"np":"20N4XM0","Model":"CX431adw","Product":"6.7K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"6000","AA":"20N4XM0CX431adw","RR":"NO"},
    {"np":"20N4XY0","Model":"CS431dw","Product":"6.7K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"6000","AA":"20N4XY0CS431dw","RR":"NO"},
    {"np":"20N4XY0","Model":"CX431adw","Product":"6.7K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"6000","AA":"20N4XY0CX431adw","RR":"NO"},
    {"np":"21K0127","Model":"CS820de","Product":"Forms and Bar Code Card","Yield":"","AA":"21K0127CS820de","RR":"NO"},
    {"np":"21K0127","Model":"CX820de","Product":"Forms and Bar Code Card","Yield":"","AA":"21K0127CX820de","RR":"NO"},
    {"np":"21K0127","Model":"CX825de","Product":"Forms and Bar Code Card","Yield":"","AA":"21K0127CX825de","RR":"NO"},
    {"np":"21K0127","Model":"CX860de","Product":"Forms and Bar Code Card","Yield":"","AA":"21K0127CX860de","RR":"NO"},
    {"np":"21K0128","Model":"CS820de","Product":"Card for IPDS","Yield":"","AA":"21K0128CS820de","RR":"NO"},
    {"np":"21K0128","Model":"CX820de","Product":"Card for IPDS","Yield":"","AA":"21K0128CX820de","RR":"NO"},
    {"np":"21K0128","Model":"CX825de","Product":"Card for IPDS","Yield":"","AA":"21K0128CX825de","RR":"NO"},
    {"np":"21K0128","Model":"CX860de","Product":"Card for IPDS","Yield":"","AA":"21K0128CX860de","RR":"NO"},
    {"np":"21K0129","Model":"CS820de","Product":"Card for PRESCRIBE","Yield":"","AA":"21K0129CS820de","RR":"NO"},
    {"np":"21K0129","Model":"CX820de","Product":"Card for PRESCRIBE","Yield":"","AA":"21K0129CX820de","RR":"NO"},
    {"np":"21K0129","Model":"CX825de","Product":"Card for PRESCRIBE","Yield":"","AA":"21K0129CX825de","RR":"NO"},
    {"np":"21K0129","Model":"CX860de","Product":"Card for PRESCRIBE","Yield":"","AA":"21K0129CX860de","RR":"NO"},
    {"np":"21K0200","Model":"CS820de","Product":"L�ser, 60ppm, Pantalla t�ctil a color 4.3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 200.000p�gs, Recomendado de 25.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, inalambrico (opcional), Procesador dual core de 1.33Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 500 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"21K0200CS820de","RR":"NO"}
    ,
    {"np":"21K0237","Model":"CS820de","Product":"2200-Sheet Tray","Yield":"","AA":"21K0237CS820de","RR":"NO"},
    {"np":"21K0237","Model":"CX820de","Product":"2200-Sheet Tray","Yield":"","AA":"21K0237CX820de","RR":"NO"},
    {"np":"21K0237","Model":"CX825de","Product":"2200-Sheet Tray","Yield":"","AA":"21K0237CX825de","RR":"NO"},
    {"np":"21K0237","Model":"CX860de","Product":"2200-Sheet Tray","Yield":"","AA":"21K0237CX860de","RR":"NO"},
    {"np":"21K0567","Model":"CS820de","Product":"550-Sheet Tray","Yield":"","AA":"21K0567CS820de","RR":"NO"},
    {"np":"21K0567","Model":"CX820de","Product":"550-Sheet Tray","Yield":"","AA":"21K0567CX820de","RR":"NO"},
    {"np":"21K0567","Model":"CX825de","Product":"550-Sheet Tray","Yield":"","AA":"21K0567CX825de","RR":"NO"},
    {"np":"21K0567","Model":"CX860de","Product":"550-Sheet Tray","Yield":"","AA":"21K0567CX860de","RR":"NO"},
    {"np":"21K0787","Model":"CS820de","Product":"Envelope Tray","Yield":"","AA":"21K0787CS820de","RR":"NO"},
    {"np":"21K0787","Model":"CX820de","Product":"Envelope Tray","Yield":"","AA":"21K0787CX820de","RR":"NO"},
    {"np":"21K0787","Model":"CX825de","Product":"Envelope Tray","Yield":"","AA":"21K0787CX825de","RR":"NO"},
    {"np":"21K0787","Model":"CX860de","Product":"Envelope Tray","Yield":"","AA":"21K0787CX860de","RR":"NO"},
    {"np":"21K2501","Model":"CS820de","Product":"Caster Base","Yield":"","AA":"21K2501CS820de","RR":"NO"},
    {"np":"21K2501","Model":"CX820de","Product":"Caster Base","Yield":"","AA":"21K2501CX820de","RR":"NO"},
    {"np":"21K2501","Model":"CX825de","Product":"Caster Base","Yield":"","AA":"21K2501CX825de","RR":"NO"},
    {"np":"21K2501","Model":"CX860de","Product":"Caster Base","Yield":"","AA":"21K2501CX860de","RR":"NO"},
    {"np":"21Z0357","Model":"C950de","Product":"5K -Booklet Staple Cartridges (4 pack)","Yield":"20000","AA":"21Z0357C950de","RR":"NO"},
    {"np":"21Z0357","Model":"X950de","Product":"5K -Booklet Saddle Staple Cartridges (4 pack)","Yield":"20000","AA":"21Z0357X950de","RR":"NO"},
    {"np":"21Z0357","Model":"X952dte","Product":"5K -Booklet Saddle Staple Cartridges (4 pack)","Yield":"20000","AA":"21Z0357X952dte","RR":"NO"},
    {"np":"21Z0357","Model":"X954dhe","Product":"5K -Booklet Saddle Staple Cartridges (4 pack)","Yield":"20000","AA":"21Z0357X954dhe","RR":"NO"},
    {"np":"22Z0000","Model":"C950de","Product":"50/50 ppm, 1024MB, 1.2GHz, MDC 225K, A3","Yield":"","AA":"22Z0000C950de","RR":"NO"},
    {"np":"22Z0012","Model":"C950de","Product":"520-Sheet Drawer Stand","Yield":"","AA":"22Z0012C950de","RR":"NO"},
    {"np":"22Z0012","Model":"X950de","Product":"520-Sheet Drawer Stand","Yield":"","AA":"22Z0012X950de","RR":"NO"},
    {"np":"22Z0013","Model":"C950de","Product":"3x520-Sheet Drawer Stand","Yield":"","AA":"22Z0013C950de","RR":"NO"},
    {"np":"22Z0013","Model":"X950de","Product":"3x520-Sheet Drawer Stand","Yield":"","AA":"22Z0013X950de","RR":"NO"},
    {"np":"22Z0014","Model":"C950de","Product":"2520-Sheet Tandem Tray Module","Yield":"","AA":"22Z0014C950de","RR":"NO"},
    {"np":"22Z0014","Model":"X950de","Product":"2520-Sheet Tandem Tray Module","Yield":"","AA":"22Z0014X950de","RR":"NO"},
    {"np":"22Z0015","Model":"C950de","Product":"2000-Sheet High Capacity Feeder","Yield":"","AA":"22Z0015C950de","RR":"NO"},
    {"np":"22Z0015","Model":"X950de","Product":"2000-Sheet High Capacity Feeder","Yield":"","AA":"22Z0015X950de","RR":"NO"},
    {"np":"22Z0015","Model":"X952dte","Product":"2000-Sheet High Capacity Feeder","Yield":"","AA":"22Z0015X952dte","RR":"NO"},
    {"np":"22Z0015","Model":"X954dhe","Product":"2000-Sheet High Capacity Feeder","Yield":"","AA":"22Z0015X954dhe","RR":"NO"},
    {"np":"22Z0016","Model":"C950de","Product":"3500-Sheet Finisher (3-Hole)","Yield":"","AA":"22Z0016C950de","RR":"NO"},
    {"np":"22Z0016","Model":"X950de","Product":"3500-Sheet Finisher (3-Hole)","Yield":"","AA":"22Z0016X950de","RR":"NO"},
    {"np":"22Z0016","Model":"X952dte","Product":"3500-Sheet Finisher (3-Hole)","Yield":"","AA":"22Z0016X952dte","RR":"NO"},
    {"np":"22Z0016","Model":"X954dhe","Product":"3500-Sheet Finisher (3-Hole)","Yield":"","AA":"22Z0016X954dhe","RR":"NO"},
    {"np":"22Z0017","Model":"C950de","Product":"Booklet Finisher (3-Hole)","Yield":"","AA":"22Z0017C950de","RR":"NO"},
    {"np":"22Z0017","Model":"X950de","Product":"Booklet Finisher (3-Hole)","Yield":"","AA":"22Z0017X950de","RR":"NO"},
    {"np":"22Z0017","Model":"X952dte","Product":"Booklet Finisher (3-Hole)","Yield":"","AA":"22Z0017X952dte","RR":"NO"},
    {"np":"22Z0017","Model":"X954dhe","Product":"Booklet Finisher (3-Hole)","Yield":"","AA":"22Z0017X954dhe","RR":"NO"},
    {"np":"22Z0019","Model":"X950de","Product":"45/40 ppm, 1024MB, 1.2GHz, MDC 200K, A3","Yield":"","AA":"22Z0019X950de","RR":"NO"},
    {"np":"22Z0020","Model":"X952dte","Product":"50/45 ppm, 1024MB, 1.2GHz, MDC 225K, A3","Yield":"","AA":"22Z0020X952dte","RR":"NO"},
    {"np":"22Z0021","Model":"X954dhe","Product":"55/50 ppm, 2048MB, 1.2GHz, MDC 250K, A3","Yield":"","AA":"22Z0021X954dhe","RR":"NO"},
    {"np":"22Z0175","Model":"C950de","Product":"3500-Sheet Finisher (4-Hole)","Yield":"","AA":"22Z0175C950de","RR":"NO"},
    {"np":"22Z0175","Model":"X950de","Product":"3500-Sheet Finisher (4-Hole)","Yield":"","AA":"22Z0175X950de","RR":"NO"},
    {"np":"22Z0175","Model":"X952dte","Product":"3500-Sheet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0175X952dte","RR":"NO"},
    {"np":"22Z0175","Model":"X954dhe","Product":"3500-Sheet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0175X954dhe","RR":"NO"},
    {"np":"22Z0176","Model":"C950de","Product":"Booklet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0176C950de","RR":"NO"},
    {"np":"22Z0176","Model":"X950de","Product":"Booklet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0176X950de","RR":"NO"},
    {"np":"22Z0176","Model":"X952dte","Product":"Booklet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0176X952dte","RR":"NO"},
    {"np":"22Z0176","Model":"X954dhe","Product":"Booklet Finisher (4-Hole - A4)","Yield":"","AA":"22Z0176X954dhe","RR":"NO"},
    {"np":"22Z0182","Model":"C950de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"22Z0182C950de","RR":"NO"},
    {"np":"22Z0183","Model":"C950de","Product":"Lexmark Card for IPDS","Yield":"","AA":"22Z0183C950de","RR":"NO"},
    {"np":"22Z0184","Model":"C950de","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"22Z0184C950de","RR":"NO"},
    {"np":"22Z0185","Model":"X950de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"22Z0185X950de","RR":"NO"},
    {"np":"22Z0185","Model":"X952dte","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"22Z0185X952dte","RR":"NO"},
    {"np":"22Z0185","Model":"X954dhe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"22Z0185X954dhe","RR":"NO"},
    {"np":"22Z0186","Model":"X950de","Product":"Lexmark Card for IPDS","Yield":"","AA":"22Z0186X950de","RR":"NO"},
    {"np":"22Z0186","Model":"X952dte","Product":"Lexmark Card for IPDS","Yield":"","AA":"22Z0186X952dte","RR":"NO"},
    {"np":"22Z0186","Model":"X954dhe","Product":"Lexmark Card for IPDS","Yield":"","AA":"22Z0186X954dhe","RR":"NO"},
    {"np":"22Z0187","Model":"X950de","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"22Z0187X950de","RR":"NO"},
    {"np":"22Z0187","Model":"X952dte","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"22Z0187X952dte","RR":"NO"},
    {"np":"22Z0187","Model":"X954dhe","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"22Z0187X954dhe","RR":"NO"},
    {"np":"2353798","Model":"C925de","Product":"C925 1yr OSR  NBD","Yield":"","AA":"2353798C925de","RR":"NO"},
    {"np":"2353799","Model":"C925de","Product":"C925 2yr OSR  NBD","Yield":"","AA":"2353799C925de","RR":"NO"},
    {"np":"2353800","Model":"C925de","Product":"C925 3yr OSR  NBD","Yield":"","AA":"2353800C925de","RR":"NO"},
    {"np":"2353801","Model":"C925de","Product":"C925 4yr OSR  NBD","Yield":"","AA":"2353801C925de","RR":"NO"},
    {"np":"2353802","Model":"C925de","Product":"C925 1yr Renew OSR  NBD","Yield":"","AA":"2353802C925de","RR":"NO"},
    {"np":"2353803","Model":"C925de","Product":"C925 1yr Post Wty OSR  NBD","Yield":"","AA":"2353803C925de","RR":"NO"},
    {"np":"2353807","Model":"C925de","Product":"C925 1yr Parts Only","Yield":"","AA":"2353807C925de","RR":"NO"},
    {"np":"2353808","Model":"C925de","Product":"C925 2yr Parts Only","Yield":"","AA":"2353808C925de","RR":"NO"},
    {"np":"2353809","Model":"C925de","Product":"C925 3yr Parts Only","Yield":"","AA":"2353809C925de","RR":"NO"},
    {"np":"2353810","Model":"C925de","Product":"C925 4yr Parts Only","Yield":"","AA":"2353810C925de","RR":"NO"},
    {"np":"2353820","Model":"X925de","Product":"X925 1yr OSR  NBD","Yield":"","AA":"2353820X925de","RR":"NO"},
    {"np":"2353821","Model":"X925de","Product":"X925 2yr OSR  NBD","Yield":"","AA":"2353821X925de","RR":"NO"},
    {"np":"2353822","Model":"X925de","Product":"X925 3yr OSR  NBD","Yield":"","AA":"2353822X925de","RR":"NO"},
    {"np":"2353823","Model":"X925de","Product":"X925 4yr OSR  NBD","Yield":"","AA":"2353823X925de","RR":"NO"},
    {"np":"2353824","Model":"X925de","Product":"X925 1yr Renew OSR  NBD","Yield":"","AA":"2353824X925de","RR":"NO"},
    {"np":"2353825","Model":"X925de","Product":"X925 1yr Post Wty OSR  NBD","Yield":"","AA":"2353825X925de","RR":"NO"},
    {"np":"2353829","Model":"X925de","Product":"X925 1yr Parts Only","Yield":"","AA":"2353829X925de","RR":"NO"},
    {"np":"2353830","Model":"X925de","Product":"X925 2yr Parts Only","Yield":"","AA":"2353830X925de","RR":"NO"},
    {"np":"2353831","Model":"X925de","Product":"X925 3yr Parts Only","Yield":"","AA":"2353831X925de","RR":"NO"},
    {"np":"2353832","Model":"X925de","Product":"X925 4yr Parts Only","Yield":"","AA":"2353832X925de","RR":"NO"},
    {"np":"2354207","Model":"C950de","Product":"C950 1yr OSR  NBD","Yield":"","AA":"2354207C950de","RR":"NO"},
    {"np":"2354208","Model":"C950de","Product":"C950 2yr OSR  NBD","Yield":"","AA":"2354208C950de","RR":"NO"},
    {"np":"2354209","Model":"C950de","Product":"C950 3yr OSR  NBD","Yield":"","AA":"2354209C950de","RR":"NO"},
    {"np":"2354210","Model":"C950de","Product":"C950 4yr OSR  NBD","Yield":"","AA":"2354210C950de","RR":"NO"},
    {"np":"2354211","Model":"C950de","Product":"C950 1yr Renew OSR  NBD","Yield":"","AA":"2354211C950de","RR":"NO"},
    {"np":"2354212","Model":"C950de","Product":"C950 1yr Post Wty OSR  NBD","Yield":"","AA":"2354212C950de","RR":"NO"},
    {"np":"2354216","Model":"C950de","Product":"C950 1yr Parts Only","Yield":"","AA":"2354216C950de","RR":"NO"},
    {"np":"2354217","Model":"C950de","Product":"C950 2yr Parts Only","Yield":"","AA":"2354217C950de","RR":"NO"},
    {"np":"2354218","Model":"C950de","Product":"C950 3yr Parts Only","Yield":"","AA":"2354218C950de","RR":"NO"},
    {"np":"2354219","Model":"C950de","Product":"C950 4yr Parts Only","Yield":"","AA":"2354219C950de","RR":"NO"},
    {"np":"2354235","Model":"X950de","Product":"X950 1yr OSR  NBD","Yield":"","AA":"2354235X950de","RR":"NO"},
    {"np":"2354236","Model":"X950de","Product":"X950 2yr OSR  NBD","Yield":"","AA":"2354236X950de","RR":"NO"},
    {"np":"2354237","Model":"X950de","Product":"X950 3yr OSR  NBD","Yield":"","AA":"2354237X950de","RR":"NO"},
    {"np":"2354238","Model":"X950de","Product":"X950 4yr OSR  NBD","Yield":"","AA":"2354238X950de","RR":"NO"},
    {"np":"2354239","Model":"X950de","Product":"X950 1yr Renew OSR  NBD","Yield":"","AA":"2354239X950de","RR":"NO"},
    {"np":"2354240","Model":"X950de","Product":"X950 1yr Post Wty OSR  NBD","Yield":"","AA":"2354240X950de","RR":"NO"},
    {"np":"2354244","Model":"X950de","Product":"X950 1yr Parts Only","Yield":"","AA":"2354244X950de","RR":"NO"},
    {"np":"2354245","Model":"X950de","Product":"X950 2yr Parts Only","Yield":"","AA":"2354245X950de","RR":"NO"},
    {"np":"2354246","Model":"X950de","Product":"X950 3yr Parts Only","Yield":"","AA":"2354246X950de","RR":"NO"},
    {"np":"2354247","Model":"X950de","Product":"X950 4yr Parts Only","Yield":"","AA":"2354247X950de","RR":"NO"},
    {"np":"2354263","Model":"X952dte","Product":"X952 1yr OSR  NBD","Yield":"","AA":"2354263X952dte","RR":"NO"},
    {"np":"2354264","Model":"X952dte","Product":"X952 2yr OSR  NBD","Yield":"","AA":"2354264X952dte","RR":"NO"},
    {"np":"2354265","Model":"X952dte","Product":"X952 3yr OSR  NBD","Yield":"","AA":"2354265X952dte","RR":"NO"},
    {"np":"2354266","Model":"X952dte","Product":"X952 4yr OSR  NBD","Yield":"","AA":"2354266X952dte","RR":"NO"},
    {"np":"2354267","Model":"X952dte","Product":"X952 1yr Renew OSR  NBD","Yield":"","AA":"2354267X952dte","RR":"NO"},
    {"np":"2354268","Model":"X952dte","Product":"X952 1yr Post Wty OSR  NBD","Yield":"","AA":"2354268X952dte","RR":"NO"},
    {"np":"2354272","Model":"X952dte","Product":"X952 1yr Parts Only","Yield":"","AA":"2354272X952dte","RR":"NO"},
    {"np":"2354273","Model":"X952dte","Product":"X952 2yr Parts Only","Yield":"","AA":"2354273X952dte","RR":"NO"},
    {"np":"2354274","Model":"X952dte","Product":"X952 3yr Parts Only","Yield":"","AA":"2354274X952dte","RR":"NO"},
    {"np":"2354275","Model":"X952dte","Product":"X952 4yr Parts Only","Yield":"","AA":"2354275X952dte","RR":"NO"},
    {"np":"2354291","Model":"X954dhe","Product":"X954 1yr OSR  NBD","Yield":"","AA":"2354291X954dhe","RR":"NO"},
    {"np":"2354292","Model":"X954dhe","Product":"X954 2yr OSR  NBD","Yield":"","AA":"2354292X954dhe","RR":"NO"},
    {"np":"2354293","Model":"X954dhe","Product":"X954 3yr OSR  NBD","Yield":"","AA":"2354293X954dhe","RR":"NO"},
    {"np":"2354294","Model":"X954dhe","Product":"X954 4yr OSR  NBD","Yield":"","AA":"2354294X954dhe","RR":"NO"},
    {"np":"2354295","Model":"X954dhe","Product":"X954 1yr Renew OSR  NBD","Yield":"","AA":"2354295X954dhe","RR":"NO"},
    {"np":"2354296","Model":"X954dhe","Product":"X954 1yr Post Wty OSR  NBD","Yield":"","AA":"2354296X954dhe","RR":"NO"},
    {"np":"2354300","Model":"X954dhe","Product":"X954 1yr Parts Only","Yield":"","AA":"2354300X954dhe","RR":"NO"},
    {"np":"2354301","Model":"X954dhe","Product":"X954 2yr Parts Only","Yield":"","AA":"2354301X954dhe","RR":"NO"},
    {"np":"2354302","Model":"X954dhe","Product":"X954 3yr Parts Only","Yield":"","AA":"2354302X954dhe","RR":"NO"},
    {"np":"2354303","Model":"X954dhe","Product":"X954 4yr Parts Only","Yield":"","AA":"2354303X954dhe","RR":"NO"},
    {"np":"2354928","Model":"2580+","Product":"2500+ Series 1yr OSR  NBD","Yield":"","AA":"23549282580+","RR":"NO"},
    {"np":"2354928","Model":"2580n+","Product":"2500+ Series 1yr OSR  NBD","Yield":"","AA":"23549282580n+","RR":"NO"},
    {"np":"2354928","Model":"2581+","Product":"2500+ Series 1yr OSR  NBD","Yield":"","AA":"23549282581+","RR":"NO"},
    {"np":"2354928","Model":"2581n+","Product":"2500+ Series 1yr OSR  NBD","Yield":"","AA":"23549282581n+","RR":"NO"},
    {"np":"2354929","Model":"2580+","Product":"2500+ Series 2yr OSR  NBD","Yield":"","AA":"23549292580+","RR":"NO"},
    {"np":"2354929","Model":"2580n+","Product":"2500+ Series 2yr OSR  NBD","Yield":"","AA":"23549292580n+","RR":"NO"},
    {"np":"2354929","Model":"2581+","Product":"2500+ Series 2yr OSR  NBD","Yield":"","AA":"23549292581+","RR":"NO"},
    {"np":"2354929","Model":"2581n+","Product":"2500+ Series 2yr OSR  NBD","Yield":"","AA":"23549292581n+","RR":"NO"},
    {"np":"2354930","Model":"2580+","Product":"2500+ Series 3yr OSR  NBD","Yield":"","AA":"23549302580+","RR":"NO"},
    {"np":"2354930","Model":"2580n+","Product":"2500+ Series 3yr OSR  NBD","Yield":"","AA":"23549302580n+","RR":"NO"},
    {"np":"2354930","Model":"2581+","Product":"2500+ Series 3yr OSR  NBD","Yield":"","AA":"23549302581+","RR":"NO"},
    {"np":"2354930","Model":"2581n+","Product":"2500+ Series 3yr OSR  NBD","Yield":"","AA":"23549302581n+","RR":"NO"},
    {"np":"2354931","Model":"2580+","Product":"2500+ Series 4yr OSR  NBD","Yield":"","AA":"23549312580+","RR":"NO"},
    {"np":"2354931","Model":"2580n+","Product":"2500+ Series 4yr OSR  NBD","Yield":"","AA":"23549312580n+","RR":"NO"},
    {"np":"2354931","Model":"2581+","Product":"2500+ Series 4yr OSR  NBD","Yield":"","AA":"23549312581+","RR":"NO"},
    {"np":"2354931","Model":"2581n+","Product":"2500+ Series 4yr OSR  NBD","Yield":"","AA":"23549312581n+","RR":"NO"},
    {"np":"2354932","Model":"2580+","Product":"2500+ Series 1yr Renew OSR  NBD","Yield":"","AA":"23549322580+","RR":"NO"},
    {"np":"2354932","Model":"2580n+","Product":"2500+ Series 1yr Renew OSR  NBD","Yield":"","AA":"23549322580n+","RR":"NO"},
    {"np":"2354932","Model":"2581+","Product":"2500+ Series 1yr Renew OSR  NBD","Yield":"","AA":"23549322581+","RR":"NO"},
    {"np":"2354932","Model":"2581n+","Product":"2500+ Series 1yr Renew OSR  NBD","Yield":"","AA":"23549322581n+","RR":"NO"},
    {"np":"2354976","Model":"2580+","Product":"2500+ Series 1yr Post Wty OSR  NBD","Yield":"","AA":"23549762580+","RR":"NO"},
    {"np":"2354976","Model":"2580n+","Product":"2500+ Series 1yr Post Wty OSR  NBD","Yield":"","AA":"23549762580n+","RR":"NO"},
    {"np":"2354976","Model":"2581+","Product":"2500+ Series 1yr Post Wty OSR  NBD","Yield":"","AA":"23549762581+","RR":"NO"},
    {"np":"2354976","Model":"2581n+","Product":"2500+ Series 1yr Post Wty OSR  NBD","Yield":"","AA":"23549762581n+","RR":"NO"},
    {"np":"2354980","Model":"2580+","Product":"2500+ Series 1yr Parts Only","Yield":"","AA":"23549802580+","RR":"NO"},
    {"np":"2354980","Model":"2580n+","Product":"2500+ Series 1yr Parts Only","Yield":"","AA":"23549802580n+","RR":"NO"},
    {"np":"2354980","Model":"2581+","Product":"2500+ Series 1yr Parts Only","Yield":"","AA":"23549802581+","RR":"NO"},
    {"np":"2354980","Model":"2581n+","Product":"2500+ Series 1yr Parts Only","Yield":"","AA":"23549802581n+","RR":"NO"},
    {"np":"2354981","Model":"2580+","Product":"2500+ Series 2yr Parts Only","Yield":"","AA":"23549812580+","RR":"NO"},
    {"np":"2354981","Model":"2580n+","Product":"2500+ Series 2yr Parts Only","Yield":"","AA":"23549812580n+","RR":"NO"},
    {"np":"2354981","Model":"2581+","Product":"2500+ Series 2yr Parts Only","Yield":"","AA":"23549812581+","RR":"NO"},
    {"np":"2354981","Model":"2581n+","Product":"2500+ Series 2yr Parts Only","Yield":"","AA":"23549812581n+","RR":"NO"},
    {"np":"2354982","Model":"2580+","Product":"2500+ Series 3yr Parts Only","Yield":"","AA":"23549822580+","RR":"NO"},
    {"np":"2354982","Model":"2580n+","Product":"2500+ Series 3yr Parts Only","Yield":"","AA":"23549822580n+","RR":"NO"},
    {"np":"2354982","Model":"2581+","Product":"2500+ Series 3yr Parts Only","Yield":"","AA":"23549822581+","RR":"NO"},
    {"np":"2354982","Model":"2581n+","Product":"2500+ Series 3yr Parts Only","Yield":"","AA":"23549822581n+","RR":"NO"},
    {"np":"2354983","Model":"2580+","Product":"2500+ Series 4yr Parts Only","Yield":"","AA":"23549832580+","RR":"NO"},
    {"np":"2354983","Model":"2580n+","Product":"2500+ Series 4yr Parts Only","Yield":"","AA":"23549832580n+","RR":"NO"},
    {"np":"2354983","Model":"2581+","Product":"2500+ Series 4yr Parts Only","Yield":"","AA":"23549832581+","RR":"NO"},
    {"np":"2354983","Model":"2581n+","Product":"2500+ Series 4yr Parts Only","Yield":"","AA":"23549832581n+","RR":"NO"},
    {"np":"2355108","Model":"MX710dhe","Product":"MX710 1yr OSR  NBD","Yield":"","AA":"2355108MX710dhe","RR":"NO"},
    {"np":"2355109","Model":"MX710dhe","Product":"MX710 2yr OSR  NBD","Yield":"","AA":"2355109MX710dhe","RR":"NO"},
    {"np":"2355110","Model":"MX710dhe","Product":"MX710 3yr OSR  NBD","Yield":"","AA":"2355110MX710dhe","RR":"NO"},
    {"np":"2355111","Model":"MX710dhe","Product":"MX710 4yr OSR  NBD","Yield":"","AA":"2355111MX710dhe","RR":"NO"},
    {"np":"2355112","Model":"MX710dhe","Product":"MX710 1yr Renew OSR  NBD","Yield":"","AA":"2355112MX710dhe","RR":"NO"},
    {"np":"2355113","Model":"MX710dhe","Product":"MX710 1yr Post Wty OSR  NBD","Yield":"","AA":"2355113MX710dhe","RR":"NO"},
    {"np":"2355117","Model":"MX710dhe","Product":"MX710 1yr Parts Only","Yield":"","AA":"2355117MX710dhe","RR":"NO"},
    {"np":"2355118","Model":"MX710dhe","Product":"MX710 2yr Parts Only","Yield":"","AA":"2355118MX710dhe","RR":"NO"},
    {"np":"2355119","Model":"MX710dhe","Product":"MX710 3yr Parts Only","Yield":"","AA":"2355119MX710dhe","RR":"NO"},
    {"np":"2355120","Model":"MX710dhe","Product":"MX710 4yr Parts Only","Yield":"","AA":"2355120MX710dhe","RR":"NO"},
    {"np":"2355136","Model":"MX711dhe","Product":"MX711 1yr OSR  NBD","Yield":"","AA":"2355136MX711dhe","RR":"NO"},
    {"np":"2355137","Model":"MX711dhe","Product":"MX711 2yr OSR  NBD","Yield":"","AA":"2355137MX711dhe","RR":"NO"},
    {"np":"2355138","Model":"MX711dhe","Product":"MX711 3yr OSR  NBD","Yield":"","AA":"2355138MX711dhe","RR":"NO"},
    {"np":"2355139","Model":"MX711dhe","Product":"MX711 4yr OSR  NBD","Yield":"","AA":"2355139MX711dhe","RR":"NO"},
    {"np":"2355140","Model":"MX711dhe","Product":"MX711 1yr Renew OSR  NBD","Yield":"","AA":"2355140MX711dhe","RR":"NO"},
    {"np":"2355141","Model":"MX711dhe","Product":"MX711 1yr Post Wty OSR  NBD","Yield":"","AA":"2355141MX711dhe","RR":"NO"},
    {"np":"2355145","Model":"MX711dhe","Product":"MX711 1yr Parts Only","Yield":"","AA":"2355145MX711dhe","RR":"NO"},
    {"np":"2355146","Model":"MX711dhe","Product":"MX711 2yr Parts Only","Yield":"","AA":"2355146MX711dhe","RR":"NO"},
    {"np":"2355147","Model":"MX711dhe","Product":"MX711 3yr Parts Only","Yield":"","AA":"2355147MX711dhe","RR":"NO"},
    {"np":"2355148","Model":"MX711dhe","Product":"MX711 4yr Parts Only","Yield":"","AA":"2355148MX711dhe","RR":"NO"},
    {"np":"2355164","Model":"MX810dfe","Product":"MX810 1yr OSR  NBD","Yield":"","AA":"2355164MX810dfe","RR":"NO"},
    {"np":"2355164","Model":"MX810dme","Product":"MX810 1yr OSR  NBD","Yield":"","AA":"2355164MX810dme","RR":"NO"},
    {"np":"2355164","Model":"MX810dpe","Product":"MX810 1yr OSR  NBD","Yield":"","AA":"2355164MX810dpe","RR":"NO"},
    {"np":"2355165","Model":"MX810dfe","Product":"MX810 2yr OSR  NBD","Yield":"","AA":"2355165MX810dfe","RR":"NO"},
    {"np":"2355165","Model":"MX810dme","Product":"MX810 2yr OSR  NBD","Yield":"","AA":"2355165MX810dme","RR":"NO"},
    {"np":"2355165","Model":"MX810dpe","Product":"MX810 2yr OSR  NBD","Yield":"","AA":"2355165MX810dpe","RR":"NO"},
    {"np":"2355166","Model":"MX810dfe","Product":"MX810 3yr OSR  NBD","Yield":"","AA":"2355166MX810dfe","RR":"NO"},
    {"np":"2355166","Model":"MX810dme","Product":"MX810 3yr OSR  NBD","Yield":"","AA":"2355166MX810dme","RR":"NO"},
    {"np":"2355166","Model":"MX810dpe","Product":"MX810 3yr OSR  NBD","Yield":"","AA":"2355166MX810dpe","RR":"NO"},
    {"np":"2355167","Model":"MX810dfe","Product":"MX810 4yr OSR  NBD","Yield":"","AA":"2355167MX810dfe","RR":"NO"},
    {"np":"2355167","Model":"MX810dme","Product":"MX810 4yr OSR  NBD","Yield":"","AA":"2355167MX810dme","RR":"NO"},
    {"np":"2355167","Model":"MX810dpe","Product":"MX810 4yr OSR  NBD","Yield":"","AA":"2355167MX810dpe","RR":"NO"},
    {"np":"2355168","Model":"MX810dfe","Product":"MX810 1yr Renew OSR  NBD","Yield":"","AA":"2355168MX810dfe","RR":"NO"},
    {"np":"2355168","Model":"MX810dme","Product":"MX810 1yr Renew OSR  NBD","Yield":"","AA":"2355168MX810dme","RR":"NO"},
    {"np":"2355168","Model":"MX810dpe","Product":"MX810 1yr Renew OSR  NBD","Yield":"","AA":"2355168MX810dpe","RR":"NO"},
    {"np":"2355169","Model":"MX810dfe","Product":"MX810 1yr Post Wty OSR  NBD","Yield":"","AA":"2355169MX810dfe","RR":"NO"},
    {"np":"2355169","Model":"MX810dme","Product":"MX810 1yr Post Wty OSR  NBD","Yield":"","AA":"2355169MX810dme","RR":"NO"},
    {"np":"2355169","Model":"MX810dpe","Product":"MX810 1yr Post Wty OSR  NBD","Yield":"","AA":"2355169MX810dpe","RR":"NO"},
    {"np":"2355173","Model":"MX810dfe","Product":"MX810 1yr Parts Only","Yield":"","AA":"2355173MX810dfe","RR":"NO"},
    {"np":"2355173","Model":"MX810dme","Product":"MX810 1yr Parts Only","Yield":"","AA":"2355173MX810dme","RR":"NO"},
    {"np":"2355173","Model":"MX810dpe","Product":"MX810 1yr Parts Only","Yield":"","AA":"2355173MX810dpe","RR":"NO"},
    {"np":"2355174","Model":"MX810dfe","Product":"MX810 2yr Parts Only","Yield":"","AA":"2355174MX810dfe","RR":"NO"},
    {"np":"2355174","Model":"MX810dme","Product":"MX810 2yr Parts Only","Yield":"","AA":"2355174MX810dme","RR":"NO"},
    {"np":"2355174","Model":"MX810dpe","Product":"MX810 2yr Parts Only","Yield":"","AA":"2355174MX810dpe","RR":"NO"},
    {"np":"2355175","Model":"MX810dfe","Product":"MX810 3yr Parts Only","Yield":"","AA":"2355175MX810dfe","RR":"NO"},
    {"np":"2355175","Model":"MX810dme","Product":"MX810 3yr Parts Only","Yield":"","AA":"2355175MX810dme","RR":"NO"},
    {"np":"2355175","Model":"MX810dpe","Product":"MX810 3yr Parts Only","Yield":"","AA":"2355175MX810dpe","RR":"NO"},
    {"np":"2355176","Model":"MX810dfe","Product":"MX810 4yr Parts Only","Yield":"","AA":"2355176MX810dfe","RR":"NO"},
    {"np":"2355176","Model":"MX810dme","Product":"MX810 4yr Parts Only","Yield":"","AA":"2355176MX810dme","RR":"NO"},
    {"np":"2355176","Model":"MX810dpe","Product":"MX810 4yr Parts Only","Yield":"","AA":"2355176MX810dpe","RR":"NO"},
    {"np":"2355192","Model":"MX811dfe","Product":"MX811 1yr OSR  NBD","Yield":"","AA":"2355192MX811dfe","RR":"NO"},
    {"np":"2355192","Model":"MX811dme","Product":"MX811 1yr OSR  NBD","Yield":"","AA":"2355192MX811dme","RR":"NO"},
    {"np":"2355192","Model":"MX811dpe","Product":"MX811 1yr OSR  NBD","Yield":"","AA":"2355192MX811dpe","RR":"NO"},
    {"np":"2355193","Model":"MX811dfe","Product":"MX811 2yr OSR  NBD","Yield":"","AA":"2355193MX811dfe","RR":"NO"},
    {"np":"2355193","Model":"MX811dme","Product":"MX811 2yr OSR  NBD","Yield":"","AA":"2355193MX811dme","RR":"NO"},
    {"np":"2355193","Model":"MX811dpe","Product":"MX811 2yr OSR  NBD","Yield":"","AA":"2355193MX811dpe","RR":"NO"},
    {"np":"2355194","Model":"MX811dfe","Product":"MX811 3yr OSR  NBD","Yield":"","AA":"2355194MX811dfe","RR":"NO"},
    {"np":"2355194","Model":"MX811dme","Product":"MX811 3yr OSR  NBD","Yield":"","AA":"2355194MX811dme","RR":"NO"},
    {"np":"2355194","Model":"MX811dpe","Product":"MX811 3yr OSR  NBD","Yield":"","AA":"2355194MX811dpe","RR":"NO"},
    {"np":"2355195","Model":"MX811dfe","Product":"MX811 4yr OSR  NBD","Yield":"","AA":"2355195MX811dfe","RR":"NO"},
    {"np":"2355195","Model":"MX811dme","Product":"MX811 4yr OSR  NBD","Yield":"","AA":"2355195MX811dme","RR":"NO"},
    {"np":"2355195","Model":"MX811dpe","Product":"MX811 4yr OSR  NBD","Yield":"","AA":"2355195MX811dpe","RR":"NO"},
    {"np":"2355196","Model":"MX811dfe","Product":"MX811 1yr Renew OSR  NBD","Yield":"","AA":"2355196MX811dfe","RR":"NO"},
    {"np":"2355196","Model":"MX811dme","Product":"MX811 1yr Renew OSR  NBD","Yield":"","AA":"2355196MX811dme","RR":"NO"},
    {"np":"2355196","Model":"MX811dpe","Product":"MX811 1yr Renew OSR  NBD","Yield":"","AA":"2355196MX811dpe","RR":"NO"},
    {"np":"2355197","Model":"MX811dfe","Product":"MX811 1yr Post Wty OSR  NBD","Yield":"","AA":"2355197MX811dfe","RR":"NO"},
    {"np":"2355197","Model":"MX811dme","Product":"MX811 1yr Post Wty OSR  NBD","Yield":"","AA":"2355197MX811dme","RR":"NO"},
    {"np":"2355197","Model":"MX811dpe","Product":"MX811 1yr Post Wty OSR  NBD","Yield":"","AA":"2355197MX811dpe","RR":"NO"},
    {"np":"2355201","Model":"MX811dfe","Product":"MX811 1yr Parts Only","Yield":"","AA":"2355201MX811dfe","RR":"NO"},
    {"np":"2355201","Model":"MX811dme","Product":"MX811 1yr Parts Only","Yield":"","AA":"2355201MX811dme","RR":"NO"},
    {"np":"2355201","Model":"MX811dpe","Product":"MX811 1yr Parts Only","Yield":"","AA":"2355201MX811dpe","RR":"NO"},
    {"np":"2355202","Model":"MX811dfe","Product":"MX811 2yr Parts Only","Yield":"","AA":"2355202MX811dfe","RR":"NO"},
    {"np":"2355202","Model":"MX811dme","Product":"MX811 2yr Parts Only","Yield":"","AA":"2355202MX811dme","RR":"NO"},
    {"np":"2355202","Model":"MX811dpe","Product":"MX811 2yr Parts Only","Yield":"","AA":"2355202MX811dpe","RR":"NO"},
    {"np":"2355203","Model":"MX811dfe","Product":"MX811 3yr Parts Only","Yield":"","AA":"2355203MX811dfe","RR":"NO"},
    {"np":"2355203","Model":"MX811dme","Product":"MX811 3yr Parts Only","Yield":"","AA":"2355203MX811dme","RR":"NO"},
    {"np":"2355203","Model":"MX811dpe","Product":"MX811 3yr Parts Only","Yield":"","AA":"2355203MX811dpe","RR":"NO"},
    {"np":"2355204","Model":"MX811dfe","Product":"MX811 4yr Parts Only","Yield":"","AA":"2355204MX811dfe","RR":"NO"},
    {"np":"2355204","Model":"MX811dme","Product":"MX811 4yr Parts Only","Yield":"","AA":"2355204MX811dme","RR":"NO"},
    {"np":"2355204","Model":"MX811dpe","Product":"MX811 4yr Parts Only","Yield":"","AA":"2355204MX811dpe","RR":"NO"},
    {"np":"2355220","Model":"MX812dfe","Product":"MX812 1yr OSR  NBD","Yield":"","AA":"2355220MX812dfe","RR":"NO"},
    {"np":"2355220","Model":"MX812dme","Product":"MX812 1yr OSR  NBD","Yield":"","AA":"2355220MX812dme","RR":"NO"},
    {"np":"2355220","Model":"MX812dpe","Product":"MX812 1yr OSR  NBD","Yield":"","AA":"2355220MX812dpe","RR":"NO"},
    {"np":"2355221","Model":"MX812dfe","Product":"MX812 2yr OSR  NBD","Yield":"","AA":"2355221MX812dfe","RR":"NO"},
    {"np":"2355221","Model":"MX812dme","Product":"MX812 2yr OSR  NBD","Yield":"","AA":"2355221MX812dme","RR":"NO"},
    {"np":"2355221","Model":"MX812dpe","Product":"MX812 2yr OSR  NBD","Yield":"","AA":"2355221MX812dpe","RR":"NO"},
    {"np":"2355222","Model":"MX812dfe","Product":"MX812 3yr OSR  NBD","Yield":"","AA":"2355222MX812dfe","RR":"NO"},
    {"np":"2355222","Model":"MX812dme","Product":"MX812 3yr OSR  NBD","Yield":"","AA":"2355222MX812dme","RR":"NO"},
    {"np":"2355222","Model":"MX812dpe","Product":"MX812 3yr OSR  NBD","Yield":"","AA":"2355222MX812dpe","RR":"NO"},
    {"np":"2355223","Model":"MX812dfe","Product":"MX812 4yr OSR  NBD","Yield":"","AA":"2355223MX812dfe","RR":"NO"},
    {"np":"2355223","Model":"MX812dme","Product":"MX812 4yr OSR  NBD","Yield":"","AA":"2355223MX812dme","RR":"NO"},
    {"np":"2355223","Model":"MX812dpe","Product":"MX812 4yr OSR  NBD","Yield":"","AA":"2355223MX812dpe","RR":"NO"},
    {"np":"2355224","Model":"MX812dfe","Product":"MX812 1yr Renew OSR  NBD","Yield":"","AA":"2355224MX812dfe","RR":"NO"},
    {"np":"2355224","Model":"MX812dme","Product":"MX812 1yr Renew OSR  NBD","Yield":"","AA":"2355224MX812dme","RR":"NO"},
    {"np":"2355224","Model":"MX812dpe","Product":"MX812 1yr Renew OSR  NBD","Yield":"","AA":"2355224MX812dpe","RR":"NO"},
    {"np":"2355225","Model":"MX812dfe","Product":"MX812 1yr Post Wty OSR  NBD","Yield":"","AA":"2355225MX812dfe","RR":"NO"},
    {"np":"2355225","Model":"MX812dme","Product":"MX812 1yr Post Wty OSR  NBD","Yield":"","AA":"2355225MX812dme","RR":"NO"},
    {"np":"2355225","Model":"MX812dpe","Product":"MX812 1yr Post Wty OSR  NBD","Yield":"","AA":"2355225MX812dpe","RR":"NO"},
    {"np":"2355229","Model":"MX812dfe","Product":"MX812 1yr Parts Only","Yield":"","AA":"2355229MX812dfe","RR":"NO"},
    {"np":"2355229","Model":"MX812dme","Product":"MX812 1yr Parts Only","Yield":"","AA":"2355229MX812dme","RR":"NO"},
    {"np":"2355229","Model":"MX812dpe","Product":"MX812 1yr Parts Only","Yield":"","AA":"2355229MX812dpe","RR":"NO"},
    {"np":"2355230","Model":"MX812dfe","Product":"MX812 2yr Parts Only","Yield":"","AA":"2355230MX812dfe","RR":"NO"},
    {"np":"2355230","Model":"MX812dme","Product":"MX812 2yr Parts Only","Yield":"","AA":"2355230MX812dme","RR":"NO"},
    {"np":"2355230","Model":"MX812dpe","Product":"MX812 2yr Parts Only","Yield":"","AA":"2355230MX812dpe","RR":"NO"},
    {"np":"2355231","Model":"MX812dfe","Product":"MX812 3yr Parts Only","Yield":"","AA":"2355231MX812dfe","RR":"NO"},
    {"np":"2355231","Model":"MX812dme","Product":"MX812 3yr Parts Only","Yield":"","AA":"2355231MX812dme","RR":"NO"},
    {"np":"2355231","Model":"MX812dpe","Product":"MX812 3yr Parts Only","Yield":"","AA":"2355231MX812dpe","RR":"NO"},
    {"np":"2355232","Model":"MX812dfe","Product":"MX812 4yr Parts Only","Yield":"","AA":"2355232MX812dfe","RR":"NO"},
    {"np":"2355232","Model":"MX812dme","Product":"MX812 4yr Parts Only","Yield":"","AA":"2355232MX812dme","RR":"NO"},
    {"np":"2355232","Model":"MX812dpe","Product":"MX812 4yr Parts Only","Yield":"","AA":"2355232MX812dpe","RR":"NO"},
    {"np":"2355247","Model":"2580+","Product":"Standard Installation","Yield":"","AA":"23552472580+","RR":"NO"},
    {"np":"2355247","Model":"2580n+","Product":"Standard Installation","Yield":"","AA":"23552472580n+","RR":"NO"},
    {"np":"2355247","Model":"2581+","Product":"Standard Installation","Yield":"","AA":"23552472581+","RR":"NO"},
    {"np":"2355247","Model":"2581n+","Product":"Standard Installation","Yield":"","AA":"23552472581n+","RR":"NO"},
    {"np":"2355247","Model":"CS310dn","Product":"Standard Installation","Yield":"","AA":"2355247CS310dn","RR":"NO"},
    {"np":"2355247","Model":"CS421dn","Product":"Standard Installation","Yield":"","AA":"2355247CS421dn","RR":"NO"},
    {"np":"2355247","Model":"CS510de","Product":"Standard Installation","Yield":"","AA":"2355247CS510de","RR":"NO"},
    {"np":"2355247","Model":"CS521dn","Product":"Standard Installation","Yield":"","AA":"2355247CS521dn","RR":"NO"},
    {"np":"2355247","Model":"CS622de","Product":"Standard Installation","Yield":"","AA":"2355247CS622de","RR":"NO"},
    {"np":"2355247","Model":"CS720de","Product":"Standard Installation","Yield":"","AA":"2355247CS720de","RR":"NO"},
    {"np":"2355247","Model":"CS725de","Product":"Standard Installation","Yield":"","AA":"2355247CS725de","RR":"NO"},
    {"np":"2355247","Model":"MS312dn","Product":"Standard Installation","Yield":"","AA":"2355247MS312dn","RR":"NO"},
    {"np":"2355247","Model":"MS315dn","Product":"Standard Installation","Yield":"","AA":"2355247MS315dn","RR":"NO"},
    {"np":"2355247","Model":"MS321dn","Product":"Standard Installation","Yield":"","AA":"2355247MS321dn","RR":"NO"},
    {"np":"2355247","Model":"MS415dn","Product":"Standard Installation","Yield":"","AA":"2355247MS415dn","RR":"NO"},
    {"np":"2355247","Model":"MS421dn","Product":"Standard Installation","Yield":"","AA":"2355247MS421dn","RR":"NO"},
    {"np":"2355247","Model":"MS521dn","Product":"Standard Installation","Yield":"","AA":"2355247MS521dn","RR":"NO"},
    {"np":"2355247","Model":"MS610de","Product":"Standard Installation","Yield":"","AA":"2355247MS610de","RR":"NO"},
    {"np":"2355247","Model":"MS610dn","Product":"Standard Installation","Yield":"","AA":"2355247MS610dn","RR":"NO"},
    {"np":"2355247","Model":"MS621dn","Product":"Standard Installation","Yield":"","AA":"2355247MS621dn","RR":"NO"},
    {"np":"2355247","Model":"MS622de","Product":"Standard Installation","Yield":"","AA":"2355247MS622de","RR":"NO"},
    {"np":"2355247","Model":"MS810de","Product":"Standard Installation","Yield":"","AA":"2355247MS810de","RR":"NO"},
    {"np":"2355247","Model":"MS810dn","Product":"Standard Installation","Yield":"","AA":"2355247MS810dn","RR":"NO"},
    {"np":"2355247","Model":"MS811dn","Product":"Standard Installation","Yield":"","AA":"2355247MS811dn","RR":"NO"},
    {"np":"2355247","Model":"MS812de","Product":"Standard Installation","Yield":"","AA":"2355247MS812de","RR":"NO"},
    {"np":"2355247","Model":"MS812dn","Product":"Standard Installation","Yield":"","AA":"2355247MS812dn","RR":"NO"},
    {"np":"2355247","Model":"MS821dn","Product":"Standard Installation","Yield":"","AA":"2355247MS821dn","RR":"NO"},
    {"np":"2355247","Model":"MS823dn","Product":"Standard Installation","Yield":"","AA":"2355247MS823dn","RR":"NO"},
    {"np":"2355247","Model":"MS826de","Product":"Standard Installation","Yield":"","AA":"2355247MS826de","RR":"NO"},
    {"np":"2355249","Model":"CS820de","Product":"Standard Installation","Yield":"","AA":"2355249CS820de","RR":"NO"},
    {"np":"2355249","Model":"MX310dn","Product":"Standard Installation","Yield":"","AA":"2355249MX310dn","RR":"NO"},
    {"np":"2355249","Model":"MX321adn","Product":"Standard Installation","Yield":"","AA":"2355249MX321adn","RR":"NO"},
    {"np":"2355249","Model":"MX410de","Product":"Standard Installation","Yield":"","AA":"2355249MX410de","RR":"NO"},
    {"np":"2355249","Model":"MX421ade","Product":"Standard Installation","Yield":"","AA":"2355249MX421ade","RR":"NO"},
    {"np":"2355249","Model":"MX511de","Product":"Standard Installation","Yield":"","AA":"2355249MX511de","RR":"NO"},
    {"np":"2355249","Model":"MX521ade","Product":"Standard Installation","Yield":"","AA":"2355249MX521ade","RR":"NO"},
    {"np":"2355249","Model":"MX522adhe","Product":"Standard Installation","Yield":"","AA":"2355249MX522adhe","RR":"NO"},
    {"np":"2355249","Model":"MX611dfe","Product":"Standard Installation","Yield":"","AA":"2355249MX611dfe","RR":"NO"},
    {"np":"2355249","Model":"MX611dhe","Product":"Standard Installation","Yield":"","AA":"2355249MX611dhe","RR":"NO"},
    {"np":"2355249","Model":"MX622adhe","Product":"Standard Installation","Yield":"","AA":"2355249MX622adhe","RR":"NO"},
    {"np":"2355249","Model":"MX710dhe","Product":"Standard Installation","Yield":"","AA":"2355249MX710dhe","RR":"NO"},
    {"np":"2355249","Model":"MX711dhe","Product":"Standard Installation","Yield":"","AA":"2355249MX711dhe","RR":"NO"},
    {"np":"2355249","Model":"MX810dfe","Product":"Standard Installation","Yield":"","AA":"2355249MX810dfe","RR":"NO"},
    {"np":"2355249","Model":"MX810dme","Product":"Standard Installation","Yield":"","AA":"2355249MX810dme","RR":"NO"},
    {"np":"2355249","Model":"MX810dpe","Product":"Standard Installation","Yield":"","AA":"2355249MX810dpe","RR":"NO"},
    {"np":"2355252","Model":"C925de","Product":"Standard Installation","Yield":"","AA":"2355252C925de","RR":"NO"},
    {"np":"2355252","Model":"C950de","Product":"Standard Installation","Yield":"","AA":"2355252C950de","RR":"NO"},
    {"np":"2355252","Model":"CS921de","Product":"Standard Installation","Yield":"","AA":"2355252CS921de","RR":"NO"},
    {"np":"2355252","Model":"CS923de","Product":"Standard Installation","Yield":"","AA":"2355252CS923de","RR":"NO"},
    {"np":"2355252","Model":"CX310dn","Product":"Standard Installation","Yield":"","AA":"2355252CX310dn","RR":"NO"},
    {"np":"2355252","Model":"CX410de","Product":"Standard Installation","Yield":"","AA":"2355252CX410de","RR":"NO"},
    {"np":"2355252","Model":"CX421adn","Product":"Standard Installation","Yield":"","AA":"2355252CX421adn","RR":"NO"},
    {"np":"2355252","Model":"CX510de","Product":"Standard Installation","Yield":"","AA":"2355252CX510de","RR":"NO"},
    {"np":"2355252","Model":"CX510dhe","Product":"Standard Installation","Yield":"","AA":"2355252CX510dhe","RR":"NO"},
    {"np":"2355252","Model":"CX522ade","Product":"Standard Installation","Yield":"","AA":"2355252CX522ade","RR":"NO"},
    {"np":"2355252","Model":"CX622ade","Product":"Standard Installation","Yield":"","AA":"2355252CX622ade","RR":"NO"},
    {"np":"2355252","Model":"CX625adhe","Product":"Standard Installation","Yield":"","AA":"2355252CX625adhe","RR":"NO"},
    {"np":"2355252","Model":"CX725dhe","Product":"Standard Installation","Yield":"","AA":"2355252CX725dhe","RR":"NO"},
    {"np":"2355252","Model":"CX820de","Product":"Standard Installation","Yield":"","AA":"2355252CX820de","RR":"NO"},
    {"np":"2355252","Model":"CX825de","Product":"Standard Installation","Yield":"","AA":"2355252CX825de","RR":"NO"},
    {"np":"2355252","Model":"CX860de","Product":"Standard Installation","Yield":"","AA":"2355252CX860de","RR":"NO"},
    {"np":"2355252","Model":"CX921de","Product":"Standard Installation","Yield":"","AA":"2355252CX921de","RR":"NO"},
    {"np":"2355252","Model":"CX922de","Product":"Standard Installation","Yield":"","AA":"2355252CX922de","RR":"NO"},
    {"np":"2355252","Model":"CX923dte","Product":"Standard Installation","Yield":"","AA":"2355252CX923dte","RR":"NO"},
    {"np":"2355252","Model":"MS911de","Product":"Standard Installation","Yield":"","AA":"2355252MS911de","RR":"NO"},
    {"np":"2355252","Model":"MX722adhe","Product":"Standard Installation","Yield":"","AA":"2355252MX722adhe","RR":"NO"},
    {"np":"2355252","Model":"MX811dfe","Product":"Standard Installation","Yield":"","AA":"2355252MX811dfe","RR":"NO"},
    {"np":"2355252","Model":"MX811dme","Product":"Standard Installation","Yield":"","AA":"2355252MX811dme","RR":"NO"},
    {"np":"2355252","Model":"MX811dpe","Product":"Standard Installation","Yield":"","AA":"2355252MX811dpe","RR":"NO"},
    {"np":"2355252","Model":"MX812dfe","Product":"Standard Installation","Yield":"","AA":"2355252MX812dfe","RR":"NO"},
    {"np":"2355252","Model":"MX812dpe","Product":"Standard Installation","Yield":"","AA":"2355252MX812dpe","RR":"NO"},
    {"np":"2355252","Model":"MX822ade","Product":"Standard Installation","Yield":"","AA":"2355252MX822ade","RR":"NO"},
    {"np":"2355252","Model":"MX826ade","Product":"Standard Installation","Yield":"","AA":"2355252MX826ade","RR":"NO"},
    {"np":"2355252","Model":"MX910de","Product":"Standard Installation","Yield":"","AA":"2355252MX910de","RR":"NO"},
    {"np":"2355252","Model":"MX911dte","Product":"Standard Installation","Yield":"","AA":"2355252MX911dte","RR":"NO"},
    {"np":"2355252","Model":"MX912dxe","Product":"Standard Installation","Yield":"","AA":"2355252MX912dxe","RR":"NO"},
    {"np":"2355252","Model":"X925de","Product":"Standard Installation","Yield":"","AA":"2355252X925de","RR":"NO"},
    {"np":"2355252","Model":"X950de","Product":"Standard Installation","Yield":"","AA":"2355252X950de","RR":"NO"},
    {"np":"2355252","Model":"X952dte","Product":"Standard Installation","Yield":"","AA":"2355252X952dte","RR":"NO"},
    {"np":"2355252","Model":"X954dhe","Product":"Standard Installation","Yield":"","AA":"2355252X954dhe","RR":"NO"},
    {"np":"2355488","Model":"MX310dn","Product":"MX310 1yr OSR  NBD","Yield":"","AA":"2355488MX310dn","RR":"NO"},
    {"np":"2355489","Model":"MX310dn","Product":"MX310 2yr OSR  NBD","Yield":"","AA":"2355489MX310dn","RR":"NO"},
    {"np":"2355490","Model":"MX310dn","Product":"MX310 3yr OSR  NBD","Yield":"","AA":"2355490MX310dn","RR":"NO"},
    {"np":"2355491","Model":"MX310dn","Product":"MX310 4yr OSR  NBD","Yield":"","AA":"2355491MX310dn","RR":"NO"},
    {"np":"2355492","Model":"MX310dn","Product":"MX310 1yr Renew OSR  NBD","Yield":"","AA":"2355492MX310dn","RR":"NO"},
    {"np":"2355493","Model":"MX310dn","Product":"MX310 1yr Post Wty OSR  NBD","Yield":"","AA":"2355493MX310dn","RR":"NO"},
    {"np":"2355497","Model":"MX310dn","Product":"MX310 1yr Parts Only","Yield":"","AA":"2355497MX310dn","RR":"NO"},
    {"np":"2355498","Model":"MX310dn","Product":"MX310 2yr Parts Only","Yield":"","AA":"2355498MX310dn","RR":"NO"},
    {"np":"2355499","Model":"MX310dn","Product":"MX310 3yr Parts Only","Yield":"","AA":"2355499MX310dn","RR":"NO"},
    {"np":"2355500","Model":"MX310dn","Product":"MX310 4yr Parts Only","Yield":"","AA":"2355500MX310dn","RR":"NO"},
    {"np":"2355526","Model":"MX410de","Product":"MX410 1yr OSR  NBD","Yield":"","AA":"2355526MX410de","RR":"NO"},
    {"np":"2355527","Model":"MX410de","Product":"MX410 2yr OSR  NBD","Yield":"","AA":"2355527MX410de","RR":"NO"},
    {"np":"2355528","Model":"MX410de","Product":"MX410 3yr OSR  NBD","Yield":"","AA":"2355528MX410de","RR":"NO"},
    {"np":"2355529","Model":"MX410de","Product":"MX410 4yr OSR  NBD","Yield":"","AA":"2355529MX410de","RR":"NO"},
    {"np":"2355530","Model":"MX410de","Product":"MX410 1yr Renew OSR  NBD","Yield":"","AA":"2355530MX410de","RR":"NO"},
    {"np":"2355531","Model":"MX410de","Product":"MX410 1yr Post Wty OSR  NBD","Yield":"","AA":"2355531MX410de","RR":"NO"},
    {"np":"2355535","Model":"MX410de","Product":"MX410 1yr Parts Only","Yield":"","AA":"2355535MX410de","RR":"NO"},
    {"np":"2355536","Model":"MX410de","Product":"MX410 2yr Parts Only","Yield":"","AA":"2355536MX410de","RR":"NO"},
    {"np":"2355537","Model":"MX410de","Product":"MX410 3yr Parts Only","Yield":"","AA":"2355537MX410de","RR":"NO"},
    {"np":"2355538","Model":"MX410de","Product":"MX410 4yr Parts Only","Yield":"","AA":"2355538MX410de","RR":"NO"},
    {"np":"2355564","Model":"MX511de","Product":"MX51x 1yr OSR  NBD","Yield":"","AA":"2355564MX511de","RR":"NO"},
    {"np":"2355565","Model":"MX511de","Product":"MX51x 2yr OSR  NBD","Yield":"","AA":"2355565MX511de","RR":"NO"},
    {"np":"2355566","Model":"MX511de","Product":"MX51x 3yr OSR  NBD","Yield":"","AA":"2355566MX511de","RR":"NO"},
    {"np":"2355567","Model":"MX511de","Product":"MX51x 4yr OSR  NBD","Yield":"","AA":"2355567MX511de","RR":"NO"},
    {"np":"2355568","Model":"MX511de","Product":"MX51x 1yr Renew OSR  NBD","Yield":"","AA":"2355568MX511de","RR":"NO"},
    {"np":"2355569","Model":"MX511de","Product":"MX51x 1yr Post Wty OSR  NBD","Yield":"","AA":"2355569MX511de","RR":"NO"},
    {"np":"2355573","Model":"MX511de","Product":"MX51x 1yr Parts Only","Yield":"","AA":"2355573MX511de","RR":"NO"},
    {"np":"2355574","Model":"MX511de","Product":"MX51x 2yr Parts Only","Yield":"","AA":"2355574MX511de","RR":"NO"},
    {"np":"2355575","Model":"MX511de","Product":"MX51x 3yr Parts Only","Yield":"","AA":"2355575MX511de","RR":"NO"},
    {"np":"2355576","Model":"MX511de","Product":"MX51x 4yr Parts Only","Yield":"","AA":"2355576MX511de","RR":"NO"},
    {"np":"2355602","Model":"MX611dfe","Product":"MX61x 1yr OSR  NBD","Yield":"","AA":"2355602MX611dfe","RR":"NO"},
    {"np":"2355602","Model":"MX611dhe","Product":"MX61x 1yr OSR  NBD","Yield":"","AA":"2355602MX611dhe","RR":"NO"},
    {"np":"2355603","Model":"MX611dfe","Product":"MX61x 2yr OSR  NBD","Yield":"","AA":"2355603MX611dfe","RR":"NO"},
    {"np":"2355603","Model":"MX611dhe","Product":"MX61x 2yr OSR  NBD","Yield":"","AA":"2355603MX611dhe","RR":"NO"},
    {"np":"2355604","Model":"MX611dfe","Product":"MX61x 3yr OSR  NBD","Yield":"","AA":"2355604MX611dfe","RR":"NO"},
    {"np":"2355604","Model":"MX611dhe","Product":"MX61x 3yr OSR  NBD","Yield":"","AA":"2355604MX611dhe","RR":"NO"},
    {"np":"2355605","Model":"MX611dfe","Product":"MX61x 4yr OSR  NBD","Yield":"","AA":"2355605MX611dfe","RR":"NO"},
    {"np":"2355605","Model":"MX611dhe","Product":"MX61x 4yr OSR  NBD","Yield":"","AA":"2355605MX611dhe","RR":"NO"},
    {"np":"2355606","Model":"MX611dfe","Product":"MX61x 1yr Renew OSR  NBD","Yield":"","AA":"2355606MX611dfe","RR":"NO"},
    {"np":"2355606","Model":"MX611dhe","Product":"MX61x 1yr Renew OSR  NBD","Yield":"","AA":"2355606MX611dhe","RR":"NO"},
    {"np":"2355607","Model":"MX611dfe","Product":"MX61x 1yr Post Wty OSR  NBD","Yield":"","AA":"2355607MX611dfe","RR":"NO"},
    {"np":"2355607","Model":"MX611dhe","Product":"MX61x 1yr Post Wty OSR  NBD","Yield":"","AA":"2355607MX611dhe","RR":"NO"},
    {"np":"2355611","Model":"MX611dfe","Product":"MX61x 1yr Parts Only","Yield":"","AA":"2355611MX611dfe","RR":"NO"},
    {"np":"2355611","Model":"MX611dhe","Product":"MX61x 1yr Parts Only","Yield":"","AA":"2355611MX611dhe","RR":"NO"},
    {"np":"2355612","Model":"MX611dfe","Product":"MX61x 2yr Parts Only","Yield":"","AA":"2355612MX611dfe","RR":"NO"},
    {"np":"2355612","Model":"MX611dhe","Product":"MX61x 2yr Parts Only","Yield":"","AA":"2355612MX611dhe","RR":"NO"},
    {"np":"2355613","Model":"MX611dfe","Product":"MX61x 3yr Parts Only","Yield":"","AA":"2355613MX611dfe","RR":"NO"},
    {"np":"2355613","Model":"MX611dhe","Product":"MX61x 3yr Parts Only","Yield":"","AA":"2355613MX611dhe","RR":"NO"},
    {"np":"2355614","Model":"MX611dfe","Product":"MX61x 4yr Parts Only","Yield":"","AA":"2355614MX611dfe","RR":"NO"},
    {"np":"2355614","Model":"MX611dhe","Product":"MX61x 4yr Parts Only","Yield":"","AA":"2355614MX611dhe","RR":"NO"},
    {"np":"2355786","Model":"MS610de","Product":"MS610 1yr OSR  NBD","Yield":"","AA":"2355786MS610de","RR":"NO"},
    {"np":"2355786","Model":"MS610dn","Product":"MS610 1yr OSR  NBD","Yield":"","AA":"2355786MS610dn","RR":"NO"},
    {"np":"2355787","Model":"MS610de","Product":"MS610 2yr OSR  NBD","Yield":"","AA":"2355787MS610de","RR":"NO"},
    {"np":"2355787","Model":"MS610dn","Product":"MS610 2yr OSR  NBD","Yield":"","AA":"2355787MS610dn","RR":"NO"},
    {"np":"2355788","Model":"MS610de","Product":"MS610 3yr OSR  NBD","Yield":"","AA":"2355788MS610de","RR":"NO"},
    {"np":"2355788","Model":"MS610dn","Product":"MS610 3yr OSR  NBD","Yield":"","AA":"2355788MS610dn","RR":"NO"},
    {"np":"2355789","Model":"MS610de","Product":"MS610 4yr OSR  NBD","Yield":"","AA":"2355789MS610de","RR":"NO"},
    {"np":"2355789","Model":"MS610dn","Product":"MS610 4yr OSR  NBD","Yield":"","AA":"2355789MS610dn","RR":"NO"},
    {"np":"2355790","Model":"MS610de","Product":"MS610 1yr Renew OSR  NBD","Yield":"","AA":"2355790MS610de","RR":"NO"},
    {"np":"2355790","Model":"MS610dn","Product":"MS610 1yr Renew OSR  NBD","Yield":"","AA":"2355790MS610dn","RR":"NO"},
    {"np":"2355791","Model":"MS610de","Product":"MS610 1yr Post Wty OSR  NBD","Yield":"","AA":"2355791MS610de","RR":"NO"},
    {"np":"2355791","Model":"MS610dn","Product":"MS610 1yr Post Wty OSR  NBD","Yield":"","AA":"2355791MS610dn","RR":"NO"},
    {"np":"2355795","Model":"MS610de","Product":"MS610 1yr Parts Only","Yield":"","AA":"2355795MS610de","RR":"NO"},
    {"np":"2355795","Model":"MS610dn","Product":"MS610 1yr Parts Only","Yield":"","AA":"2355795MS610dn","RR":"NO"},
    {"np":"2355796","Model":"MS610de","Product":"MS610 2yr Parts Only","Yield":"","AA":"2355796MS610de","RR":"NO"},
    {"np":"2355796","Model":"MS610dn","Product":"MS610 2yr Parts Only","Yield":"","AA":"2355796MS610dn","RR":"NO"},
    {"np":"2355797","Model":"MS610de","Product":"MS610 3yr Parts Only","Yield":"","AA":"2355797MS610de","RR":"NO"},
    {"np":"2355797","Model":"MS610dn","Product":"MS610 3yr Parts Only","Yield":"","AA":"2355797MS610dn","RR":"NO"},
    {"np":"2355798","Model":"MS610de","Product":"MS610 4yr Parts Only","Yield":"","AA":"2355798MS610de","RR":"NO"},
    {"np":"2355798","Model":"MS610dn","Product":"MS610 4yr Parts Only","Yield":"","AA":"2355798MS610dn","RR":"NO"},
    {"np":"2355832","Model":"MS810de","Product":"MS810 1yr OSR  NBD","Yield":"","AA":"2355832MS810de","RR":"NO"},
    {"np":"2355832","Model":"MS810dn","Product":"MS810 1yr OSR  NBD","Yield":"","AA":"2355832MS810dn","RR":"NO"},
    {"np":"2355833","Model":"MS810de","Product":"MS810 2yr OSR  NBD","Yield":"","AA":"2355833MS810de","RR":"NO"},
    {"np":"2355833","Model":"MS810dn","Product":"MS810 2yr OSR  NBD","Yield":"","AA":"2355833MS810dn","RR":"NO"},
    {"np":"2355834","Model":"MS810de","Product":"MS810 3yr OSR  NBD","Yield":"","AA":"2355834MS810de","RR":"NO"},
    {"np":"2355834","Model":"MS810dn","Product":"MS810 3yr OSR  NBD","Yield":"","AA":"2355834MS810dn","RR":"NO"},
    {"np":"2355835","Model":"MS810de","Product":"MS810 4yr OSR  NBD","Yield":"","AA":"2355835MS810de","RR":"NO"},
    {"np":"2355835","Model":"MS810dn","Product":"MS810 4yr OSR  NBD","Yield":"","AA":"2355835MS810dn","RR":"NO"},
    {"np":"2355836","Model":"MS810de","Product":"MS810 1yr Renew OSR  NBD","Yield":"","AA":"2355836MS810de","RR":"NO"},
    {"np":"2355836","Model":"MS810dn","Product":"MS810 1yr Renew OSR  NBD","Yield":"","AA":"2355836MS810dn","RR":"NO"},
    {"np":"2355837","Model":"MS810de","Product":"MS810 1yr Post Wty OSR  NBD","Yield":"","AA":"2355837MS810de","RR":"NO"},
    {"np":"2355837","Model":"MS810dn","Product":"MS810 1yr Post Wty OSR  NBD","Yield":"","AA":"2355837MS810dn","RR":"NO"},
    {"np":"2355841","Model":"MS810de","Product":"MS810 1yr Parts Only","Yield":"","AA":"2355841MS810de","RR":"NO"},
    {"np":"2355841","Model":"MS810dn","Product":"MS810 1yr Parts Only","Yield":"","AA":"2355841MS810dn","RR":"NO"},
    {"np":"2355842","Model":"MS810de","Product":"MS810 2yr Parts Only","Yield":"","AA":"2355842MS810de","RR":"NO"},
    {"np":"2355842","Model":"MS810dn","Product":"MS810 2yr Parts Only","Yield":"","AA":"2355842MS810dn","RR":"NO"},
    {"np":"2355843","Model":"MS810de","Product":"MS810 3yr Parts Only","Yield":"","AA":"2355843MS810de","RR":"NO"},
    {"np":"2355843","Model":"MS810dn","Product":"MS810 3yr Parts Only","Yield":"","AA":"2355843MS810dn","RR":"NO"},
    {"np":"2355844","Model":"MS810de","Product":"MS810 4yr Parts Only","Yield":"","AA":"2355844MS810de","RR":"NO"},
    {"np":"2355844","Model":"MS810dn","Product":"MS810 4yr Parts Only","Yield":"","AA":"2355844MS810dn","RR":"NO"},
    {"np":"2355878","Model":"MS811dn","Product":"MS811 1yr OSR  NBD","Yield":"","AA":"2355878MS811dn","RR":"NO"},
    {"np":"2355879","Model":"MS811dn","Product":"MS811 2yr OSR  NBD","Yield":"","AA":"2355879MS811dn","RR":"NO"},
    {"np":"2355880","Model":"MS811dn","Product":"MS811 3yr OSR  NBD","Yield":"","AA":"2355880MS811dn","RR":"NO"},
    {"np":"2355881","Model":"MS811dn","Product":"MS811 4yr OSR  NBD","Yield":"","AA":"2355881MS811dn","RR":"NO"},
    {"np":"2355882","Model":"MS811dn","Product":"MS811 1yr Renew OSR  NBD","Yield":"","AA":"2355882MS811dn","RR":"NO"},
    {"np":"2355883","Model":"MS811dn","Product":"MS811 1yr Post Wty OSR  NBD","Yield":"","AA":"2355883MS811dn","RR":"NO"},
    {"np":"2355887","Model":"MS811dn","Product":"MS811 1yr Parts Only","Yield":"","AA":"2355887MS811dn","RR":"NO"},
    {"np":"2355888","Model":"MS811dn","Product":"MS811 2yr Parts Only","Yield":"","AA":"2355888MS811dn","RR":"NO"},
    {"np":"2355889","Model":"MS811dn","Product":"MS811 3yr Parts Only","Yield":"","AA":"2355889MS811dn","RR":"NO"},
    {"np":"2355890","Model":"MS811dn","Product":"MS811 4yr Parts Only","Yield":"","AA":"2355890MS811dn","RR":"NO"},
    {"np":"2355924","Model":"MS812de","Product":"MS812 1yr OSR  NBD","Yield":"","AA":"2355924MS812de","RR":"NO"},
    {"np":"2355924","Model":"MS812dn","Product":"MS812 1yr OSR  NBD","Yield":"","AA":"2355924MS812dn","RR":"NO"},
    {"np":"2355925","Model":"MS812de","Product":"MS812 2yr OSR  NBD","Yield":"","AA":"2355925MS812de","RR":"NO"},
    {"np":"2355925","Model":"MS812dn","Product":"MS812 2yr OSR  NBD","Yield":"","AA":"2355925MS812dn","RR":"NO"},
    {"np":"2355926","Model":"MS812de","Product":"MS812 3yr OSR  NBD","Yield":"","AA":"2355926MS812de","RR":"NO"},
    {"np":"2355926","Model":"MS812dn","Product":"MS812 3yr OSR  NBD","Yield":"","AA":"2355926MS812dn","RR":"NO"},
    {"np":"2355927","Model":"MS812de","Product":"MS812 4yr OSR  NBD","Yield":"","AA":"2355927MS812de","RR":"NO"},
    {"np":"2355927","Model":"MS812dn","Product":"MS812 4yr OSR  NBD","Yield":"","AA":"2355927MS812dn","RR":"NO"},
    {"np":"2355928","Model":"MS812de","Product":"MS812 1yr Renew OSR  NBD","Yield":"","AA":"2355928MS812de","RR":"NO"},
    {"np":"2355928","Model":"MS812dn","Product":"MS812 1yr Renew OSR  NBD","Yield":"","AA":"2355928MS812dn","RR":"NO"},
    {"np":"2355929","Model":"MS812de","Product":"MS812 1yr Post Wty OSR  NBD","Yield":"","AA":"2355929MS812de","RR":"NO"},
    {"np":"2355929","Model":"MS812dn","Product":"MS812 1yr Post Wty OSR  NBD","Yield":"","AA":"2355929MS812dn","RR":"NO"},
    {"np":"2355933","Model":"MS812de","Product":"MS812 1yr Parts Only","Yield":"","AA":"2355933MS812de","RR":"NO"},
    {"np":"2355933","Model":"MS812dn","Product":"MS812 1yr Parts Only","Yield":"","AA":"2355933MS812dn","RR":"NO"},
    {"np":"2355934","Model":"MS812de","Product":"MS812 2yr Parts Only","Yield":"","AA":"2355934MS812de","RR":"NO"},
    {"np":"2355934","Model":"MS812dn","Product":"MS812 2yr Parts Only","Yield":"","AA":"2355934MS812dn","RR":"NO"},
    {"np":"2355935","Model":"MS812de","Product":"MS812 3yr Parts Only","Yield":"","AA":"2355935MS812de","RR":"NO"},
    {"np":"2355935","Model":"MS812dn","Product":"MS812 3yr Parts Only","Yield":"","AA":"2355935MS812dn","RR":"NO"},
    {"np":"2355936","Model":"MS812de","Product":"MS812 4yr Parts Only","Yield":"","AA":"2355936MS812de","RR":"NO"},
    {"np":"2355936","Model":"MS812dn","Product":"MS812 4yr Parts Only","Yield":"","AA":"2355936MS812dn","RR":"NO"},
    {"np":"2356080","Model":"CS310dn","Product":"CS310 1yr OSR  NBD","Yield":"","AA":"2356080CS310dn","RR":"NO"},
    {"np":"2356081","Model":"CS310dn","Product":"CS310 2yr OSR  NBD","Yield":"","AA":"2356081CS310dn","RR":"NO"},
    {"np":"2356082","Model":"CS310dn","Product":"CS310 3yr OSR  NBD","Yield":"","AA":"2356082CS310dn","RR":"NO"},
    {"np":"2356083","Model":"CS310dn","Product":"CS310 4yr OSR  NBD","Yield":"","AA":"2356083CS310dn","RR":"NO"},
    {"np":"2356084","Model":"CS310dn","Product":"CS310 1yr Renew OSR  NBD","Yield":"","AA":"2356084CS310dn","RR":"NO"},
    {"np":"2356085","Model":"CS310dn","Product":"CS310 1yr Post Wty OSR  NBD","Yield":"","AA":"2356085CS310dn","RR":"NO"},
    {"np":"2356089","Model":"CS310dn","Product":"CS310 1yr Parts Only","Yield":"","AA":"2356089CS310dn","RR":"NO"},
    {"np":"2356090","Model":"CS310dn","Product":"CS310 2yr Parts Only","Yield":"","AA":"2356090CS310dn","RR":"NO"},
    {"np":"2356091","Model":"CS310dn","Product":"CS310 3yr Parts Only","Yield":"","AA":"2356091CS310dn","RR":"NO"},
    {"np":"2356092","Model":"CS310dn","Product":"CS310 4yr Parts Only","Yield":"","AA":"2356092CS310dn","RR":"NO"},
    {"np":"2356172","Model":"CS510de","Product":"CS510 1yr OSR  NBD","Yield":"","AA":"2356172CS510de","RR":"NO"},
    {"np":"2356173","Model":"CS510de","Product":"CS510 2yr OSR  NBD","Yield":"","AA":"2356173CS510de","RR":"NO"},
    {"np":"2356174","Model":"CS510de","Product":"CS510 3yr OSR  NBD","Yield":"","AA":"2356174CS510de","RR":"NO"},
    {"np":"2356175","Model":"CS510de","Product":"CS510 4yr OSR  NBD","Yield":"","AA":"2356175CS510de","RR":"NO"},
    {"np":"2356176","Model":"CS510de","Product":"CS510 1yr Renew OSR  NBD","Yield":"","AA":"2356176CS510de","RR":"NO"},
    {"np":"2356177","Model":"CS510de","Product":"CS510 1yr Post Wty OSR  NBD","Yield":"","AA":"2356177CS510de","RR":"NO"},
    {"np":"2356181","Model":"CS510de","Product":"CS510 1yr Parts Only","Yield":"","AA":"2356181CS510de","RR":"NO"},
    {"np":"2356182","Model":"CS510de","Product":"CS510 2yr Parts Only","Yield":"","AA":"2356182CS510de","RR":"NO"},
    {"np":"2356183","Model":"CS510de","Product":"CS510 3yr Parts Only","Yield":"","AA":"2356183CS510de","RR":"NO"},
    {"np":"2356184","Model":"CS510de","Product":"CS510 4yr Parts Only","Yield":"","AA":"2356184CS510de","RR":"NO"},
    {"np":"2356210","Model":"CX310dn","Product":"CX310 1yr OSR  NBD","Yield":"","AA":"2356210CX310dn","RR":"NO"},
    {"np":"2356211","Model":"CX310dn","Product":"CX310 2yr OSR  NBD","Yield":"","AA":"2356211CX310dn","RR":"NO"},
    {"np":"2356212","Model":"CX310dn","Product":"CX310 3yr OSR  NBD","Yield":"","AA":"2356212CX310dn","RR":"NO"},
    {"np":"2356213","Model":"CX310dn","Product":"CX310 4yr OSR  NBD","Yield":"","AA":"2356213CX310dn","RR":"NO"},
    {"np":"2356214","Model":"CX310dn","Product":"CX310 1yr Renew OSR  NBD","Yield":"","AA":"2356214CX310dn","RR":"NO"},
    {"np":"2356215","Model":"CX310dn","Product":"CX310 1yr Post Wty OSR  NBD","Yield":"","AA":"2356215CX310dn","RR":"NO"},
    {"np":"2356219","Model":"CX310dn","Product":"CX310 1yr Parts Only","Yield":"","AA":"2356219CX310dn","RR":"NO"},
    {"np":"2356220","Model":"CX310dn","Product":"CX310 2yr Parts Only","Yield":"","AA":"2356220CX310dn","RR":"NO"},
    {"np":"2356221","Model":"CX310dn","Product":"CX310 3yr Parts Only","Yield":"","AA":"2356221CX310dn","RR":"NO"},
    {"np":"2356222","Model":"CX310dn","Product":"CX310 4yr Parts Only","Yield":"","AA":"2356222CX310dn","RR":"NO"},
    {"np":"2356248","Model":"CX410de","Product":"CX410 1yr OSR  NBD","Yield":"","AA":"2356248CX410de","RR":"NO"},
    {"np":"2356249","Model":"CX410de","Product":"CX410 2yr OSR  NBD","Yield":"","AA":"2356249CX410de","RR":"NO"},
    {"np":"2356250","Model":"CX410de","Product":"CX410 3yr OSR  NBD","Yield":"","AA":"2356250CX410de","RR":"NO"},
    {"np":"2356251","Model":"CX410de","Product":"CX410 4yr OSR  NBD","Yield":"","AA":"2356251CX410de","RR":"NO"},
    {"np":"2356252","Model":"CX410de","Product":"CX410 1yr Renew OSR  NBD","Yield":"","AA":"2356252CX410de","RR":"NO"},
    {"np":"2356253","Model":"CX410de","Product":"CX410 1yr Post Wty OSR  NBD","Yield":"","AA":"2356253CX410de","RR":"NO"},
    {"np":"2356257","Model":"CX410de","Product":"CX410 1yr Parts Only","Yield":"","AA":"2356257CX410de","RR":"NO"},
    {"np":"2356258","Model":"CX410de","Product":"CX410 2yr Parts Only","Yield":"","AA":"2356258CX410de","RR":"NO"},
    {"np":"2356259","Model":"CX410de","Product":"CX410 3yr Parts Only","Yield":"","AA":"2356259CX410de","RR":"NO"},
    {"np":"2356260","Model":"CX410de","Product":"CX410 4yr Parts Only","Yield":"","AA":"2356260CX410de","RR":"NO"},
    {"np":"2356286","Model":"CX510de","Product":"CX510 1yr OSR  NBD","Yield":"","AA":"2356286CX510de","RR":"NO"},
    {"np":"2356286","Model":"CX510dhe","Product":"CX510 1yr OSR  NBD","Yield":"","AA":"2356286CX510dhe","RR":"NO"},
    {"np":"2356287","Model":"CX510de","Product":"CX510 2yr OSR  NBD","Yield":"","AA":"2356287CX510de","RR":"NO"},
    {"np":"2356287","Model":"CX510dhe","Product":"CX510 2yr OSR  NBD","Yield":"","AA":"2356287CX510dhe","RR":"NO"},
    {"np":"2356288","Model":"CX510de","Product":"CX510 3yr OSR  NBD","Yield":"","AA":"2356288CX510de","RR":"NO"},
    {"np":"2356288","Model":"CX510dhe","Product":"CX510 3yr OSR  NBD","Yield":"","AA":"2356288CX510dhe","RR":"NO"},
    {"np":"2356289","Model":"CX510de","Product":"CX510 4yr OSR  NBD","Yield":"","AA":"2356289CX510de","RR":"NO"},
    {"np":"2356289","Model":"CX510dhe","Product":"CX510 4yr OSR  NBD","Yield":"","AA":"2356289CX510dhe","RR":"NO"},
    {"np":"2356290","Model":"CX510de","Product":"CX510 1yr Renew OSR  NBD","Yield":"","AA":"2356290CX510de","RR":"NO"},
    {"np":"2356290","Model":"CX510dhe","Product":"CX510 1yr Renew OSR  NBD","Yield":"","AA":"2356290CX510dhe","RR":"NO"},
    {"np":"2356291","Model":"CX510de","Product":"CX510 1yr Post Wty OSR  NBD","Yield":"","AA":"2356291CX510de","RR":"NO"},
    {"np":"2356291","Model":"CX510dhe","Product":"CX510 1yr Post Wty OSR  NBD","Yield":"","AA":"2356291CX510dhe","RR":"NO"},
    {"np":"2356295","Model":"CX510de","Product":"CX510 1yr Parts Only","Yield":"","AA":"2356295CX510de","RR":"NO"},
    {"np":"2356295","Model":"CX510dhe","Product":"CX510 1yr Parts Only","Yield":"","AA":"2356295CX510dhe","RR":"NO"},
    {"np":"2356296","Model":"CX510de","Product":"CX510 2yr Parts Only","Yield":"","AA":"2356296CX510de","RR":"NO"},
    {"np":"2356296","Model":"CX510dhe","Product":"CX510 2yr Parts Only","Yield":"","AA":"2356296CX510dhe","RR":"NO"},
    {"np":"2356297","Model":"CX510de","Product":"CX510 3yr Parts Only","Yield":"","AA":"2356297CX510de","RR":"NO"},
    {"np":"2356297","Model":"CX510dhe","Product":"CX510 3yr Parts Only","Yield":"","AA":"2356297CX510dhe","RR":"NO"},
    {"np":"2356298","Model":"CX510de","Product":"CX510 4yr Parts Only","Yield":"","AA":"2356298CX510de","RR":"NO"},
    {"np":"2356298","Model":"CX510dhe","Product":"CX510 4yr Parts Only","Yield":"","AA":"2356298CX510dhe","RR":"NO"},
    {"np":"2356647","Model":"MS312dn","Product":"MS312 1yr OSR  NBD","Yield":"","AA":"2356647MS312dn","RR":"NO"},
    {"np":"2356648","Model":"MS312dn","Product":"MS312 2yr OSR  NBD","Yield":"","AA":"2356648MS312dn","RR":"NO"},
    {"np":"2356649","Model":"MS312dn","Product":"MS312 3yr OSR  NBD","Yield":"","AA":"2356649MS312dn","RR":"NO"},
    {"np":"2356650","Model":"MS312dn","Product":"MS312 4yr OSR  NBD","Yield":"","AA":"2356650MS312dn","RR":"NO"},
    {"np":"2356651","Model":"MS312dn","Product":"MS312 1yr Renew OSR  NBD","Yield":"","AA":"2356651MS312dn","RR":"NO"},
    {"np":"2356652","Model":"MS312dn","Product":"MS312 1yr Post Wty OSR  NBD","Yield":"","AA":"2356652MS312dn","RR":"NO"},
    {"np":"2356655","Model":"MS312dn","Product":"MS312 1yr Parts Only","Yield":"","AA":"2356655MS312dn","RR":"NO"},
    {"np":"2356656","Model":"MS312dn","Product":"MS312 2yr Parts Only","Yield":"","AA":"2356656MS312dn","RR":"NO"},
    {"np":"2356657","Model":"MS312dn","Product":"MS312 3yr Parts Only","Yield":"","AA":"2356657MS312dn","RR":"NO"},
    {"np":"2356658","Model":"MS312dn","Product":"MS312 4yr Parts Only","Yield":"","AA":"2356658MS312dn","RR":"NO"},
    {"np":"2356700","Model":"MS315dn","Product":"MS315 1yr OSR  NBD","Yield":"","AA":"2356700MS315dn","RR":"NO"},
    {"np":"2356701","Model":"MS315dn","Product":"MS315 2yr OSR  NBD","Yield":"","AA":"2356701MS315dn","RR":"NO"},
    {"np":"2356702","Model":"MS315dn","Product":"MS315 3yr OSR  NBD","Yield":"","AA":"2356702MS315dn","RR":"NO"},
    {"np":"2356703","Model":"MS315dn","Product":"MS315 4yr OSR  NBD","Yield":"","AA":"2356703MS315dn","RR":"NO"},
    {"np":"2356704","Model":"MS315dn","Product":"MS315 1yr Renew OSR  NBD","Yield":"","AA":"2356704MS315dn","RR":"NO"},
    {"np":"2356705","Model":"MS315dn","Product":"MS315 1yr Post Wty OSR  NBD","Yield":"","AA":"2356705MS315dn","RR":"NO"},
    {"np":"2356708","Model":"MS315dn","Product":"MS315 1yr Parts Only","Yield":"","AA":"2356708MS315dn","RR":"NO"},
    {"np":"2356709","Model":"MS315dn","Product":"MS315 2yr Parts Only","Yield":"","AA":"2356709MS315dn","RR":"NO"},
    {"np":"2356710","Model":"MS315dn","Product":"MS315 3yr Parts Only","Yield":"","AA":"2356710MS315dn","RR":"NO"},
    {"np":"2356711","Model":"MS315dn","Product":"MS315 4yr Parts Only","Yield":"","AA":"2356711MS315dn","RR":"NO"},
    {"np":"2356753","Model":"MS415dn","Product":"MS415 1yr OSR  NBD","Yield":"","AA":"2356753MS415dn","RR":"NO"},
    {"np":"2356754","Model":"MS415dn","Product":"MS415 2yr OSR  NBD","Yield":"","AA":"2356754MS415dn","RR":"NO"},
    {"np":"2356755","Model":"MS415dn","Product":"MS415 3yr OSR  NBD","Yield":"","AA":"2356755MS415dn","RR":"NO"},
    {"np":"2356756","Model":"MS415dn","Product":"MS415 4yr OSR  NBD","Yield":"","AA":"2356756MS415dn","RR":"NO"},
    {"np":"2356757","Model":"MS415dn","Product":"MS415 1yr Renew OSR  NBD","Yield":"","AA":"2356757MS415dn","RR":"NO"},
    {"np":"2356758","Model":"MS415dn","Product":"MS415 1yr Post Wty OSR  NBD","Yield":"","AA":"2356758MS415dn","RR":"NO"},
    {"np":"2356761","Model":"MS415dn","Product":"MS415 1yr Parts Only","Yield":"","AA":"2356761MS415dn","RR":"NO"},
    {"np":"2356762","Model":"MS415dn","Product":"MS415 2yr Parts Only","Yield":"","AA":"2356762MS415dn","RR":"NO"},
    {"np":"2356763","Model":"MS415dn","Product":"MS415 3yr Parts Only","Yield":"","AA":"2356763MS415dn","RR":"NO"},
    {"np":"2356764","Model":"MS415dn","Product":"MS415 4yr Parts Only","Yield":"","AA":"2356764MS415dn","RR":"NO"},
    {"np":"2356829","Model":"MX910de","Product":"MX910 1yr OSR  NBD","Yield":"","AA":"2356829MX910de","RR":"NO"},
    {"np":"2356830","Model":"MX910de","Product":"MX910 2yr OSR  NBD","Yield":"","AA":"2356830MX910de","RR":"NO"},
    {"np":"2356831","Model":"MX910de","Product":"MX910 3yr OSR  NBD","Yield":"","AA":"2356831MX910de","RR":"NO"},
    {"np":"2356832","Model":"MX910de","Product":"MX910 4yr OSR  NBD","Yield":"","AA":"2356832MX910de","RR":"NO"},
    {"np":"2356833","Model":"MX910de","Product":"MX910 1yr Renew OSR  NBD","Yield":"","AA":"2356833MX910de","RR":"NO"},
    {"np":"2356834","Model":"MX910de","Product":"MX910 1yr Post Wty OSR  NBD","Yield":"","AA":"2356834MX910de","RR":"NO"},
    {"np":"2356837","Model":"MX910de","Product":"MX910 1yr Parts Only","Yield":"","AA":"2356837MX910de","RR":"NO"},
    {"np":"2356838","Model":"MX910de","Product":"MX910 2yr Parts Only","Yield":"","AA":"2356838MX910de","RR":"NO"},
    {"np":"2356839","Model":"MX910de","Product":"MX910 3yr Parts Only","Yield":"","AA":"2356839MX910de","RR":"NO"},
    {"np":"2356840","Model":"MX910de","Product":"MX910 4yr Parts Only","Yield":"","AA":"2356840MX910de","RR":"NO"},
    {"np":"2356866","Model":"MX911dte","Product":"MX911 1yr OSR  NBD","Yield":"","AA":"2356866MX911dte","RR":"NO"},
    {"np":"2356867","Model":"MX911dte","Product":"MX911 2yr OSR  NBD","Yield":"","AA":"2356867MX911dte","RR":"NO"},
    {"np":"2356868","Model":"MX911dte","Product":"MX911 3yr OSR  NBD","Yield":"","AA":"2356868MX911dte","RR":"NO"},
    {"np":"2356869","Model":"MX911dte","Product":"MX911 4yr OSR  NBD","Yield":"","AA":"2356869MX911dte","RR":"NO"},
    {"np":"2356870","Model":"MX911dte","Product":"MX911 1yr Renew OSR  NBD","Yield":"","AA":"2356870MX911dte","RR":"NO"},
    {"np":"2356871","Model":"MX911dte","Product":"MX911 1yr Post Wty OSR  NBD","Yield":"","AA":"2356871MX911dte","RR":"NO"},
    {"np":"2356874","Model":"MX911dte","Product":"MX911 1yr Parts Only","Yield":"","AA":"2356874MX911dte","RR":"NO"},
    {"np":"2356875","Model":"MX911dte","Product":"MX911 2yr Parts Only","Yield":"","AA":"2356875MX911dte","RR":"NO"},
    {"np":"2356876","Model":"MX911dte","Product":"MX911 3yr Parts Only","Yield":"","AA":"2356876MX911dte","RR":"NO"},
    {"np":"2356877","Model":"MX911dte","Product":"MX911 4yr Parts Only","Yield":"","AA":"2356877MX911dte","RR":"NO"},
    {"np":"2356903","Model":"MX912dxe","Product":"MX912 1yr OSR  NBD","Yield":"","AA":"2356903MX912dxe","RR":"NO"},
    {"np":"2356904","Model":"MX912dxe","Product":"MX912 2yr OSR  NBD","Yield":"","AA":"2356904MX912dxe","RR":"NO"},
    {"np":"2356905","Model":"MX912dxe","Product":"MX912 3yr OSR  NBD","Yield":"","AA":"2356905MX912dxe","RR":"NO"},
    {"np":"2356906","Model":"MX912dxe","Product":"MX912 4yr OSR  NBD","Yield":"","AA":"2356906MX912dxe","RR":"NO"},
    {"np":"2356907","Model":"MX912dxe","Product":"MX912 1yr Renew OSR  NBD","Yield":"","AA":"2356907MX912dxe","RR":"NO"},
    {"np":"2356908","Model":"MX912dxe","Product":"MX912 1yr Post Wty OSR  NBD","Yield":"","AA":"2356908MX912dxe","RR":"NO"},
    {"np":"2356911","Model":"MX912dxe","Product":"MX912 1yr Parts Only","Yield":"","AA":"2356911MX912dxe","RR":"NO"},
    {"np":"2356912","Model":"MX912dxe","Product":"MX912 2yr Parts Only","Yield":"","AA":"2356912MX912dxe","RR":"NO"},
    {"np":"2356913","Model":"MX912dxe","Product":"MX912 3yr Parts Only","Yield":"","AA":"2356913MX912dxe","RR":"NO"},
    {"np":"2356914","Model":"MX912dxe","Product":"MX912 4yr Parts Only","Yield":"","AA":"2356914MX912dxe","RR":"NO"},
    {"np":"2356940","Model":"MS911de","Product":"MS911 1yr OSR  NBD","Yield":"","AA":"2356940MS911de","RR":"NO"},
    {"np":"2356941","Model":"MS911de","Product":"MS911 2yr OSR  NBD","Yield":"","AA":"2356941MS911de","RR":"NO"},
    {"np":"2356942","Model":"MS911de","Product":"MS911 3yr OSR  NBD","Yield":"","AA":"2356942MS911de","RR":"NO"},
    {"np":"2356943","Model":"MS911de","Product":"MS911 4yr OSR  NBD","Yield":"","AA":"2356943MS911de","RR":"NO"},
    {"np":"2356944","Model":"MS911de","Product":"MS911 1yr Renew OSR  NBD","Yield":"","AA":"2356944MS911de","RR":"NO"},
    {"np":"2356945","Model":"MS911de","Product":"MS911 1yr Post Wty OSR  NBD","Yield":"","AA":"2356945MS911de","RR":"NO"},
    {"np":"2356948","Model":"MS911de","Product":"MS911 1yr Parts Only","Yield":"","AA":"2356948MS911de","RR":"NO"},
    {"np":"2356949","Model":"MS911de","Product":"MS911 2yr Parts Only","Yield":"","AA":"2356949MS911de","RR":"NO"},
    {"np":"2356950","Model":"MS911de","Product":"MS911 3yr Parts Only","Yield":"","AA":"2356950MS911de","RR":"NO"},
    {"np":"2356951","Model":"MS911de","Product":"MS911 4yr Parts Only","Yield":"","AA":"2356951MS911de","RR":"NO"},
    {"np":"2357014","Model":"2580+","Product":"2500+ Series 1yr Renew Parts Only","Yield":"","AA":"23570142580+","RR":"NO"},
    {"np":"2357014","Model":"2580n+","Product":"2500+ Series 1yr Renew Parts Only","Yield":"","AA":"23570142580n+","RR":"NO"},
    {"np":"2357014","Model":"2581+","Product":"2500+ Series 1yr Renew Parts Only","Yield":"","AA":"23570142581+","RR":"NO"},
    {"np":"2357014","Model":"2581n+","Product":"2500+ Series 1yr Renew Parts Only","Yield":"","AA":"23570142581n+","RR":"NO"},
    {"np":"2357032","Model":"C925de","Product":"C925 1yr Renew Parts Only","Yield":"","AA":"2357032C925de","RR":"NO"},
    {"np":"2357035","Model":"C950de","Product":"C950 1yr Renew Parts Only","Yield":"","AA":"2357035C950de","RR":"NO"},
    {"np":"2357038","Model":"CS310dn","Product":"CS310 1yr Renew Parts Only","Yield":"","AA":"2357038CS310dn","RR":"NO"},
    {"np":"2357042","Model":"CS510de","Product":"CS510 1yr Renew Parts Only","Yield":"","AA":"2357042CS510de","RR":"NO"},
    {"np":"2357044","Model":"CX310dn","Product":"CX310 1yr Renew Parts Only","Yield":"","AA":"2357044CX310dn","RR":"NO"},
    {"np":"2357046","Model":"CX410de","Product":"CX410 1yr Renew Parts Only","Yield":"","AA":"2357046CX410de","RR":"NO"},
    {"np":"2357048","Model":"CX510de","Product":"CX510 1yr Renew Parts Only","Yield":"","AA":"2357048CX510de","RR":"NO"},
    {"np":"2357048","Model":"CX510dhe","Product":"CX510 1yr Renew Parts Only","Yield":"","AA":"2357048CX510dhe","RR":"NO"},
    {"np":"2357054","Model":"MS312dn","Product":"MS312 1yr Renew Parts Only","Yield":"","AA":"2357054MS312dn","RR":"NO"},
    {"np":"2357058","Model":"MS415dn","Product":"MS415 1yr Renew Parts Only","Yield":"","AA":"2357058MS415dn","RR":"NO"},
    {"np":"2357062","Model":"MS610de","Product":"MS610 1yr Renew Parts Only","Yield":"","AA":"2357062MS610de","RR":"NO"},
    {"np":"2357062","Model":"MS610dn","Product":"MS610 1yr Renew Parts Only","Yield":"","AA":"2357062MS610dn","RR":"NO"},
    {"np":"2357068","Model":"MS810de","Product":"MS810 1yr Renew Parts Only","Yield":"","AA":"2357068MS810de","RR":"NO"},
    {"np":"2357068","Model":"MS810dn","Product":"MS810 1yr Renew Parts Only","Yield":"","AA":"2357068MS810dn","RR":"NO"},
    {"np":"2357070","Model":"MS811dn","Product":"MS811 1yr Renew Parts Only","Yield":"","AA":"2357070MS811dn","RR":"NO"},
    {"np":"2357072","Model":"MS812de","Product":"MS812 1yr Renew Parts Only","Yield":"","AA":"2357072MS812de","RR":"NO"},
    {"np":"2357072","Model":"MS812dn","Product":"MS812 1yr Renew Parts Only","Yield":"","AA":"2357072MS812dn","RR":"NO"},
    {"np":"2357074","Model":"MS911de","Product":"MS911 1yr Renew Parts Only","Yield":"","AA":"2357074MS911de","RR":"NO"},
    {"np":"2357076","Model":"MX310dn","Product":"MX310 1yr Renew Parts Only","Yield":"","AA":"2357076MX310dn","RR":"NO"},
    {"np":"2357078","Model":"MX410de","Product":"MX410 1yr Renew Parts Only","Yield":"","AA":"2357078MX410de","RR":"NO"},
    {"np":"2357080","Model":"MX511de","Product":"MX51x 1yr Renew Parts Only","Yield":"","AA":"2357080MX511de","RR":"NO"},
    {"np":"2357082","Model":"MX611dfe","Product":"MX61x 1yr Renew Parts Only","Yield":"","AA":"2357082MX611dfe","RR":"NO"},
    {"np":"2357082","Model":"MX611dhe","Product":"MX61x 1yr Renew Parts Only","Yield":"","AA":"2357082MX611dhe","RR":"NO"},
    {"np":"2357087","Model":"MX710dhe","Product":"MX710 1yr Renew Parts Only","Yield":"","AA":"2357087MX710dhe","RR":"NO"},
    {"np":"2357089","Model":"MX711dhe","Product":"MX711 1yr Renew Parts Only","Yield":"","AA":"2357089MX711dhe","RR":"NO"},
    {"np":"2357091","Model":"MX810dfe","Product":"MX810 1yr Renew Parts Only","Yield":"","AA":"2357091MX810dfe","RR":"NO"},
    {"np":"2357091","Model":"MX810dme","Product":"MX810 1yr Renew Parts Only","Yield":"","AA":"2357091MX810dme","RR":"NO"},
    {"np":"2357091","Model":"MX810dpe","Product":"MX810 1yr Renew Parts Only","Yield":"","AA":"2357091MX810dpe","RR":"NO"},
    {"np":"2357093","Model":"MX811dfe","Product":"MX811 1yr Renew Parts Only","Yield":"","AA":"2357093MX811dfe","RR":"NO"},
    {"np":"2357093","Model":"MX811dme","Product":"MX811 1yr Renew Parts Only","Yield":"","AA":"2357093MX811dme","RR":"NO"},
    {"np":"2357093","Model":"MX811dpe","Product":"MX811 1yr Renew Parts Only","Yield":"","AA":"2357093MX811dpe","RR":"NO"},
    {"np":"2357095","Model":"MX812dfe","Product":"MX812 1yr Renew Parts Only","Yield":"","AA":"2357095MX812dfe","RR":"NO"},
    {"np":"2357095","Model":"MX812dme","Product":"MX812 1yr Renew Parts Only","Yield":"","AA":"2357095MX812dme","RR":"NO"},
    {"np":"2357095","Model":"MX812dpe","Product":"MX812 1yr Renew Parts Only","Yield":"","AA":"2357095MX812dpe","RR":"NO"},
    {"np":"2357097","Model":"MX910de","Product":"MX910 1yr Renew Parts Only","Yield":"","AA":"2357097MX910de","RR":"NO"},
    {"np":"2357099","Model":"MX911dte","Product":"MX911 1yr Renew Parts Only","Yield":"","AA":"2357099MX911dte","RR":"NO"},
    {"np":"2357101","Model":"MX912dxe","Product":"MX912 1yr Renew Parts Only","Yield":"","AA":"2357101MX912dxe","RR":"NO"},
    {"np":"2357119","Model":"X925de","Product":"X925 1yr Renew Parts Only","Yield":"","AA":"2357119X925de","RR":"NO"},
    {"np":"2357121","Model":"X950de","Product":"X950 1yr Renew Parts Only","Yield":"","AA":"2357121X950de","RR":"NO"},
    {"np":"2357124","Model":"X952dte","Product":"X952 1yr Renew Parts Only","Yield":"","AA":"2357124X952dte","RR":"NO"},
    {"np":"2357127","Model":"X954dhe","Product":"X954 1yr Renew Parts Only","Yield":"","AA":"2357127X954dhe","RR":"NO"},
    {"np":"2359916","Model":"CS820de","Product":"CS820 1yr OSR  NBD","Yield":"","AA":"2359916CS820de","RR":"NO"},
    {"np":"2359917","Model":"CS820de","Product":"CS820 2yr OSR  NBD","Yield":"","AA":"2359917CS820de","RR":"NO"},
    {"np":"2359918","Model":"CS820de","Product":"CS820 3yr OSR  NBD","Yield":"","AA":"2359918CS820de","RR":"NO"},
    {"np":"2359919","Model":"CS820de","Product":"CS820 4yr OSR  NBD","Yield":"","AA":"2359919CS820de","RR":"NO"},
    {"np":"2359920","Model":"CS820de","Product":"CS820 1yr Renew OSR  NBD","Yield":"","AA":"2359920CS820de","RR":"NO"},
    {"np":"2359921","Model":"CS820de","Product":"CS820 1yr Post Wty OSR  NBD","Yield":"","AA":"2359921CS820de","RR":"NO"},
    {"np":"2359946","Model":"CS820de","Product":"CS820 1yr Parts Only","Yield":"","AA":"2359946CS820de","RR":"NO"},
    {"np":"2359947","Model":"CS820de","Product":"CS820 2yr Parts Only","Yield":"","AA":"2359947CS820de","RR":"NO"},
    {"np":"2359948","Model":"CS820de","Product":"CS820 3yr Parts Only","Yield":"","AA":"2359948CS820de","RR":"NO"},
    {"np":"2359949","Model":"CS820de","Product":"CS820 4yr Parts Only","Yield":"","AA":"2359949CS820de","RR":"NO"},
    {"np":"2359950","Model":"CS820de","Product":"CS820 1yr Renew Parts Only","Yield":"","AA":"2359950CS820de","RR":"NO"},
    {"np":"2359957","Model":"CX820de","Product":"CX820 1yr OSR  NBD","Yield":"","AA":"2359957CX820de","RR":"NO"},
    {"np":"2359958","Model":"CX820de","Product":"CX820 2yr OSR  NBD","Yield":"","AA":"2359958CX820de","RR":"NO"},
    {"np":"2359959","Model":"CX820de","Product":"CX820 3yr OSR  NBD","Yield":"","AA":"2359959CX820de","RR":"NO"},
    {"np":"2359960","Model":"CX820de","Product":"CX820 4yr OSR  NBD","Yield":"","AA":"2359960CX820de","RR":"NO"},
    {"np":"2359961","Model":"CX820de","Product":"CX820 1yr Renew OSR  NBD","Yield":"","AA":"2359961CX820de","RR":"NO"},
    {"np":"2359962","Model":"CX820de","Product":"CX820 1yr Post Wty OSR  NBD","Yield":"","AA":"2359962CX820de","RR":"NO"},
    {"np":"2359987","Model":"CX820de","Product":"CX820 1yr Parts Only","Yield":"","AA":"2359987CX820de","RR":"NO"},
    {"np":"2359988","Model":"CX820de","Product":"CX820 2yr Parts Only","Yield":"","AA":"2359988CX820de","RR":"NO"},
    {"np":"2359989","Model":"CX820de","Product":"CX820 3yr Parts Only","Yield":"","AA":"2359989CX820de","RR":"NO"},
    {"np":"2359990","Model":"CX820de","Product":"CX820 4yr Parts Only","Yield":"","AA":"2359990CX820de","RR":"NO"},
    {"np":"2359991","Model":"CX820de","Product":"CX820 1yr Renew Parts Only","Yield":"","AA":"2359991CX820de","RR":"NO"},
    {"np":"2359998","Model":"CX825de","Product":"CX820 1yr OSR  NBD","Yield":"","AA":"2359998CX825de","RR":"NO"},
    {"np":"2359999","Model":"CX825de","Product":"CX820 2yr OSR  NBD","Yield":"","AA":"2359999CX825de","RR":"NO"},
    {"np":"2360000","Model":"CX825de","Product":"CX820 3yr OSR  NBD","Yield":"","AA":"2360000CX825de","RR":"NO"},
    {"np":"2360001","Model":"CX825de","Product":"CX825 4yr OSR  NBD","Yield":"","AA":"2360001CX825de","RR":"NO"},
    {"np":"2360002","Model":"CX825de","Product":"CX825 1yr Renew OSR  NBD","Yield":"","AA":"2360002CX825de","RR":"NO"},
    {"np":"2360003","Model":"CX825de","Product":"CX825 1yr Post Wty OSR  NBD","Yield":"","AA":"2360003CX825de","RR":"NO"},
    {"np":"2360028","Model":"CX825de","Product":"CX825 1yr Parts Only","Yield":"","AA":"2360028CX825de","RR":"NO"},
    {"np":"2360029","Model":"CX825de","Product":"CX825 2yr Parts Only","Yield":"","AA":"2360029CX825de","RR":"NO"},
    {"np":"2360030","Model":"CX825de","Product":"CX825 3yr Parts Only","Yield":"","AA":"2360030CX825de","RR":"NO"},
    {"np":"2360031","Model":"CX825de","Product":"CX825 4yr Parts Only","Yield":"","AA":"2360031CX825de","RR":"NO"},
    {"np":"2360032","Model":"CX825de","Product":"CX825 1yr Renew Parts Only","Yield":"","AA":"2360032CX825de","RR":"NO"},
    {"np":"2360039","Model":"CX860de","Product":"CX860 1yr OSR  NBD","Yield":"","AA":"2360039CX860de","RR":"NO"},
    {"np":"2360040","Model":"CX860de","Product":"CX860 2yr OSR  NBD","Yield":"","AA":"2360040CX860de","RR":"NO"},
    {"np":"2360041","Model":"CX860de","Product":"CX860 3yr OSR  NBD","Yield":"","AA":"2360041CX860de","RR":"NO"},
    {"np":"2360042","Model":"CX860de","Product":"CX860 4yr OSR  NBD","Yield":"","AA":"2360042CX860de","RR":"NO"},
    {"np":"2360043","Model":"CX860de","Product":"CX860 1yr Renew OSR  NBD","Yield":"","AA":"2360043CX860de","RR":"NO"},
    {"np":"2360044","Model":"CX860de","Product":"CX860 1yr Post Wty OSR  NBD","Yield":"","AA":"2360044CX860de","RR":"NO"},
    {"np":"2360069","Model":"CX860de","Product":"CX860 1yr Parts Only","Yield":"","AA":"2360069CX860de","RR":"NO"},
    {"np":"2360070","Model":"CX860de","Product":"CX860 2yr Parts Only","Yield":"","AA":"2360070CX860de","RR":"NO"},
    {"np":"2360071","Model":"CX860de","Product":"CX860 3yr Parts Only","Yield":"","AA":"2360071CX860de","RR":"NO"},
    {"np":"2360072","Model":"CX860de","Product":"CX860 4yr Parts Only","Yield":"","AA":"2360072CX860de","RR":"NO"},
    {"np":"2360073","Model":"CX860de","Product":"CX860 1yr Renew Parts Only","Yield":"","AA":"2360073CX860de","RR":"NO"},
    {"np":"2360080","Model":"CS720de","Product":"CS720 1yr OSR  NBD","Yield":"","AA":"2360080CS720de","RR":"NO"},
    {"np":"2360081","Model":"CS720de","Product":"CS720 2yr OSR  NBD","Yield":"","AA":"2360081CS720de","RR":"NO"},
    {"np":"2360082","Model":"CS720de","Product":"CS720 3yr OSR  NBD","Yield":"","AA":"2360082CS720de","RR":"NO"},
    {"np":"2360083","Model":"CS720de","Product":"CS720 4yr OSR  NBD","Yield":"","AA":"2360083CS720de","RR":"NO"},
    {"np":"2360084","Model":"CS720de","Product":"CS720 1yr Renew OSR  NBD","Yield":"","AA":"2360084CS720de","RR":"NO"},
    {"np":"2360085","Model":"CS720de","Product":"CS720 1yr Post Wty OSR  NBD","Yield":"","AA":"2360085CS720de","RR":"NO"},
    {"np":"2360110","Model":"CS720de","Product":"CS720 1yr Parts Only","Yield":"","AA":"2360110CS720de","RR":"NO"},
    {"np":"2360111","Model":"CS720de","Product":"CS720 2yr Parts Only","Yield":"","AA":"2360111CS720de","RR":"NO"},
    {"np":"2360112","Model":"CS720de","Product":"CS720 3yr Parts Only","Yield":"","AA":"2360112CS720de","RR":"NO"},
    {"np":"2360113","Model":"CS720de","Product":"CS720 4yr Parts Only","Yield":"","AA":"2360113CS720de","RR":"NO"},
    {"np":"2360114","Model":"CS720de","Product":"2018-374428","Yield":"","AA":"2360114CS720de","RR":"NO"},
    {"np":"2360121","Model":"CS725de","Product":"CS725 1yr OSR  NBD","Yield":"","AA":"2360121CS725de","RR":"NO"},
    {"np":"2360122","Model":"CS725de","Product":"CS725 2yr OSR  NBD","Yield":"","AA":"2360122CS725de","RR":"NO"},
    {"np":"2360123","Model":"CS725de","Product":"CS725 3yr OSR  NBD","Yield":"","AA":"2360123CS725de","RR":"NO"},
    {"np":"2360124","Model":"CS725de","Product":"CS725 4yr OSR  NBD","Yield":"","AA":"2360124CS725de","RR":"NO"},
    {"np":"2360125","Model":"CS725de","Product":"CS725 1yr Renew OSR  NBD","Yield":"","AA":"2360125CS725de","RR":"NO"},
    {"np":"2360126","Model":"CS725de","Product":"CS725 1yr Post Wty OSR  NBD","Yield":"","AA":"2360126CS725de","RR":"NO"},
    {"np":"2360151","Model":"CS725de","Product":"CS725 1yr Parts Only","Yield":"","AA":"2360151CS725de","RR":"NO"},
    {"np":"2360152","Model":"CS725de","Product":"CS725 2yr Parts Only","Yield":"","AA":"2360152CS725de","RR":"NO"},
    {"np":"2360153","Model":"CS725de","Product":"CS725 3yr Parts Only","Yield":"","AA":"2360153CS725de","RR":"NO"},
    {"np":"2360154","Model":"CS725de","Product":"CS725 4yr Parts Only","Yield":"","AA":"2360154CS725de","RR":"NO"},
    {"np":"2360155","Model":"CS725de","Product":"CS725 1yr Renew Parts Only","Yield":"","AA":"2360155CS725de","RR":"NO"},
    {"np":"2360162","Model":"CX725dhe","Product":"CX725 1yr OSR  NBD","Yield":"","AA":"2360162CX725dhe","RR":"NO"},
    {"np":"2360163","Model":"CX725dhe","Product":"CX725 2yr OSR  NBD","Yield":"","AA":"2360163CX725dhe","RR":"NO"},
    {"np":"2360164","Model":"CX725dhe","Product":"CX725 3yr OSR  NBD","Yield":"","AA":"2360164CX725dhe","RR":"NO"},
    {"np":"2360165","Model":"CX725dhe","Product":"CX725 4yr OSR  NBD","Yield":"","AA":"2360165CX725dhe","RR":"NO"},
    {"np":"2360166","Model":"CX725dhe","Product":"CX725 1yr Renew OSR  NBD","Yield":"","AA":"2360166CX725dhe","RR":"NO"},
    {"np":"2360167","Model":"CX725dhe","Product":"CX725 1yr Post Wty OSR  NBD","Yield":"","AA":"2360167CX725dhe","RR":"NO"},
    {"np":"2360192","Model":"CX725dhe","Product":"CX725 1yr Parts Only","Yield":"","AA":"2360192CX725dhe","RR":"NO"},
    {"np":"2360193","Model":"CX725dhe","Product":"CX725 2yr Parts Only","Yield":"","AA":"2360193CX725dhe","RR":"NO"},
    {"np":"2360194","Model":"CX725dhe","Product":"CX725 3yr Parts Only","Yield":"","AA":"2360194CX725dhe","RR":"NO"},
    {"np":"2360195","Model":"CX725dhe","Product":"CX725 4yr Parts Only","Yield":"","AA":"2360195CX725dhe","RR":"NO"},
    {"np":"2360196","Model":"CX725dhe","Product":"CX725 1yr Renew Parts Only","Yield":"","AA":"2360196CX725dhe","RR":"NO"},
    {"np":"2361545","Model":"CS921de","Product":"CS921 1yr OSR  NBD","Yield":"","AA":"2361545CS921de","RR":"NO"},
    {"np":"2361546","Model":"CS921de","Product":"CS921 2yr OSR  NBD","Yield":"","AA":"2361546CS921de","RR":"NO"},
    {"np":"2361547","Model":"CS921de","Product":"CS921 3yr OSR  NBD","Yield":"","AA":"2361547CS921de","RR":"NO"},
    {"np":"2361548","Model":"CS921de","Product":"CS921 4yr OSR  NBD","Yield":"","AA":"2361548CS921de","RR":"NO"},
    {"np":"2361549","Model":"CS921de","Product":"CS921 1yr Renew OSR  NBD","Yield":"","AA":"2361549CS921de","RR":"NO"},
    {"np":"2361550","Model":"CS921de","Product":"CS921 1yr Post Wty OSR  NBD","Yield":"","AA":"2361550CS921de","RR":"NO"},
    {"np":"2361560","Model":"CS921de","Product":"CS921 1yr Parts Only","Yield":"","AA":"2361560CS921de","RR":"NO"},
    {"np":"2361561","Model":"CS921de","Product":"CS921 2yr Parts Only","Yield":"","AA":"2361561CS921de","RR":"NO"},
    {"np":"2361562","Model":"CS921de","Product":"CS921 3yr Parts Only","Yield":"","AA":"2361562CS921de","RR":"NO"},
    {"np":"2361563","Model":"CS921de","Product":"CS921 4yr Parts Only","Yield":"","AA":"2361563CS921de","RR":"NO"},
    {"np":"2361564","Model":"CS921de","Product":"CS921 1yr Renew Parts Only","Yield":"","AA":"2361564CS921de","RR":"NO"},
    {"np":"2361577","Model":"CS923de","Product":"CS923 1yr OSR  NBD","Yield":"","AA":"2361577CS923de","RR":"NO"},
    {"np":"2361578","Model":"CS923de","Product":"CS923 2yr OSR  NBD","Yield":"","AA":"2361578CS923de","RR":"NO"},
    {"np":"2361579","Model":"CS923de","Product":"CS923 3yr OSR  NBD","Yield":"","AA":"2361579CS923de","RR":"NO"},
    {"np":"2361580","Model":"CS923de","Product":"CS923 4yr OSR  NBD","Yield":"","AA":"2361580CS923de","RR":"NO"},
    {"np":"2361581","Model":"CS923de","Product":"CS923 1yr Renew OSR  NBD","Yield":"","AA":"2361581CS923de","RR":"NO"},
    {"np":"2361582","Model":"CS923de","Product":"CS923 1yr Post Wty OSR  NBD","Yield":"","AA":"2361582CS923de","RR":"NO"},
    {"np":"2361592","Model":"CS923de","Product":"CS923 1yr Parts Only","Yield":"","AA":"2361592CS923de","RR":"NO"},
    {"np":"2361593","Model":"CS923de","Product":"CS923 2yr Parts Only","Yield":"","AA":"2361593CS923de","RR":"NO"},
    {"np":"2361594","Model":"CS923de","Product":"CS923 3yr Parts Only","Yield":"","AA":"2361594CS923de","RR":"NO"},
    {"np":"2361595","Model":"CS923de","Product":"CS923 4yr Parts Only","Yield":"","AA":"2361595CS923de","RR":"NO"},
    {"np":"2361596","Model":"CS923de","Product":"CS923 1yr Renew Parts Only","Yield":"","AA":"2361596CS923de","RR":"NO"},
    {"np":"2361609","Model":"CX920de","Product":"CX920 1yr OSR  NBD","Yield":"","AA":"2361609CX920de","RR":"NO"},
    {"np":"2361610","Model":"CX920de","Product":"CX920 2yr OSR  NBD","Yield":"","AA":"2361610CX920de","RR":"NO"},
    {"np":"2361611","Model":"CX920de","Product":"CX920 3yr OSR  NBD","Yield":"","AA":"2361611CX920de","RR":"NO"},
    {"np":"2361641","Model":"CX921de","Product":"CX921 1yr OSR  NBD","Yield":"","AA":"2361641CX921de","RR":"NO"},
    {"np":"2361642","Model":"CX921de","Product":"CX921 2yr OSR  NBD","Yield":"","AA":"2361642CX921de","RR":"NO"},
    {"np":"2361643","Model":"CX921de","Product":"CX921 3yr OSR  NBD","Yield":"","AA":"2361643CX921de","RR":"NO"},
    {"np":"2361644","Model":"CX921de","Product":"CX921 4yr OSR  NBD","Yield":"","AA":"2361644CX921de","RR":"NO"},
    {"np":"2361645","Model":"CX921de","Product":"CX921 1yr Renew OSR  NBD","Yield":"","AA":"2361645CX921de","RR":"NO"},
    {"np":"2361646","Model":"CX921de","Product":"CX921 1yr Post Wty OSR  NBD","Yield":"","AA":"2361646CX921de","RR":"NO"},
    {"np":"2361656","Model":"CX921de","Product":"CX921 1yr Parts Only","Yield":"","AA":"2361656CX921de","RR":"NO"},
    {"np":"2361657","Model":"CX921de","Product":"CX921 2yr Parts Only","Yield":"","AA":"2361657CX921de","RR":"NO"},
    {"np":"2361658","Model":"CX921de","Product":"CX921 3yr Parts Only","Yield":"","AA":"2361658CX921de","RR":"NO"},
    {"np":"2361659","Model":"CX921de","Product":"CX921 4yr Parts Only","Yield":"","AA":"2361659CX921de","RR":"NO"},
    {"np":"2361660","Model":"CX921de","Product":"CX921 1yr Renew Parts Only","Yield":"","AA":"2361660CX921de","RR":"NO"},
    {"np":"2361673","Model":"CX922de","Product":"CX922 1yr OSR  NBD","Yield":"","AA":"2361673CX922de","RR":"NO"},
    {"np":"2361674","Model":"CX922de","Product":"CX922 2yr OSR  NBD","Yield":"","AA":"2361674CX922de","RR":"NO"},
    {"np":"2361675","Model":"CX922de","Product":"CX922 3yr OSR  NBD","Yield":"","AA":"2361675CX922de","RR":"NO"},
    {"np":"2361676","Model":"CX922de","Product":"CX922 4yr OSR  NBD","Yield":"","AA":"2361676CX922de","RR":"NO"},
    {"np":"2361677","Model":"CX922de","Product":"CX922 1yr Renew OSR  NBD","Yield":"","AA":"2361677CX922de","RR":"NO"},
    {"np":"2361678","Model":"CX922de","Product":"CX922 1yr Post Wty OSR  NBD","Yield":"","AA":"2361678CX922de","RR":"NO"},
    {"np":"2361688","Model":"CX922de","Product":"CX922 1yr Parts Only","Yield":"","AA":"2361688CX922de","RR":"NO"},
    {"np":"2361689","Model":"CX922de","Product":"CX922 2yr Parts Only","Yield":"","AA":"2361689CX922de","RR":"NO"},
    {"np":"2361690","Model":"CX922de","Product":"CX922 3yr Parts Only","Yield":"","AA":"2361690CX922de","RR":"NO"},
    {"np":"2361691","Model":"CX922de","Product":"CX922 4yr Parts Only","Yield":"","AA":"2361691CX922de","RR":"NO"},
    {"np":"2361692","Model":"CX922de","Product":"CX922 1yr Renew Parts Only","Yield":"","AA":"2361692CX922de","RR":"NO"},
    {"np":"2361705","Model":"CX923dte","Product":"CX923 1yr OSR  NBD","Yield":"","AA":"2361705CX923dte","RR":"NO"},
    {"np":"2361706","Model":"CX923dte","Product":"CX923 2yr OSR  NBD","Yield":"","AA":"2361706CX923dte","RR":"NO"},
    {"np":"2361707","Model":"CX923dte","Product":"CX923 3yr OSR  NBD","Yield":"","AA":"2361707CX923dte","RR":"NO"},
    {"np":"2361708","Model":"CX923dte","Product":"CX923 4yr OSR  NBD","Yield":"","AA":"2361708CX923dte","RR":"NO"},
    {"np":"2361709","Model":"CX923dte","Product":"CX923 1yr Renew OSR  NBD","Yield":"","AA":"2361709CX923dte","RR":"NO"},
    {"np":"2361710","Model":"CX923dte","Product":"CX923 1yr Post Wty OSR  NBD","Yield":"","AA":"2361710CX923dte","RR":"NO"},
    {"np":"2361720","Model":"CX923dte","Product":"CX923 1yr Parts Only","Yield":"","AA":"2361720CX923dte","RR":"NO"},
    {"np":"2361721","Model":"CX923dte","Product":"CX923 2yr Parts Only","Yield":"","AA":"2361721CX923dte","RR":"NO"},
    {"np":"2361722","Model":"CX923dte","Product":"CX923 3yr Parts Only","Yield":"","AA":"2361722CX923dte","RR":"NO"},
    {"np":"2361723","Model":"CX923dte","Product":"CX923 4yr Parts Only","Yield":"","AA":"2361723CX923dte","RR":"NO"},
    {"np":"2361724","Model":"CX923dte","Product":"CX923 1yr Renew Parts Only","Yield":"","AA":"2361724CX923dte","RR":"NO"},
    {"np":"2361852","Model":"MS321dn","Product":"MS321 1yr OSR NBD Extended 1yr NBD OSR","Yield":"","AA":"2361852MS321dn","RR":"NO"},
    {"np":"2361853","Model":"MS321dn","Product":"MS321 2yr OSR NBD Extended 2yr NBD OSR","Yield":"","AA":"2361853MS321dn","RR":"NO"},
    {"np":"2361854","Model":"MS321dn","Product":"MS321 3yr OSR NBD Extended 3yr NBD OSR","Yield":"","AA":"2361854MS321dn","RR":"NO"},
    {"np":"2361855","Model":"MS321dn","Product":"MS321 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2361855MS321dn","RR":"NO"},
    {"np":"2361856","Model":"MS321dn","Product":"MS321 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2361856MS321dn","RR":"NO"},
    {"np":"2361857","Model":"MS321dn","Product":"MS321 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2361857MS321dn","RR":"NO"},
    {"np":"2361866","Model":"MS321dn","Product":"MS321 1yr Parts Only","Yield":"","AA":"2361866MS321dn","RR":"NO"},
    {"np":"2361867","Model":"MS321dn","Product":"MS321 2yr Parts Only","Yield":"","AA":"2361867MS321dn","RR":"NO"},
    {"np":"2361868","Model":"MS321dn","Product":"MS321 3yr Parts Only","Yield":"","AA":"2361868MS321dn","RR":"NO"},
    {"np":"2361869","Model":"MS321dn","Product":"MS321 4yr Parts Only","Yield":"","AA":"2361869MS321dn","RR":"NO"},
    {"np":"2361870","Model":"MS321dn","Product":"MS321 1yr Renew Parts Only","Yield":"","AA":"2361870MS321dn","RR":"NO"},
    {"np":"2361899","Model":"MS421dn","Product":"MS421 1yr OSR NBD Extended 1yr NBD OSR","Yield":"","AA":"2361899MS421dn","RR":"NO"},
    {"np":"2361900","Model":"MS421dn","Product":"MS421 2yr OSR NBD Extended 2yr NBD OSR","Yield":"","AA":"2361900MS421dn","RR":"NO"},
    {"np":"2361901","Model":"MS421dn","Product":"MS421 3yr OSR NBD Extended 3yr NBD OSR","Yield":"","AA":"2361901MS421dn","RR":"NO"},
    {"np":"2361902","Model":"MS421dn","Product":"MS421 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2361902MS421dn","RR":"NO"},
    {"np":"2361903","Model":"MS421dn","Product":"MS421 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2361903MS421dn","RR":"NO"},
    {"np":"2361904","Model":"MS421dn","Product":"MS421 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2361904MS421dn","RR":"NO"},
    {"np":"2361913","Model":"MS421dn","Product":"MS421 1yr Parts Only","Yield":"","AA":"2361913MS421dn","RR":"NO"},
    {"np":"2361914","Model":"MS421dn","Product":"MS421 2yr Parts Only","Yield":"","AA":"2361914MS421dn","RR":"NO"},
    {"np":"2361915","Model":"MS421dn","Product":"MS421 3yr Parts Only","Yield":"","AA":"2361915MS421dn","RR":"NO"},
    {"np":"2361916","Model":"MS421dn","Product":"MS421 4yr Parts Only","Yield":"","AA":"2361916MS421dn","RR":"NO"},
    {"np":"2361917","Model":"MS421dn","Product":"MS421 1yr Renew Parts Only","Yield":"","AA":"2361917MS421dn","RR":"NO"},
    {"np":"2361946","Model":"MS521dn","Product":"MS521 1yr OSR NBD Extended 1yr NBD OSR","Yield":"","AA":"2361946MS521dn","RR":"NO"},
    {"np":"2361947","Model":"MS521dn","Product":"MS521 2yr OSR  NBD","Yield":"","AA":"2361947MS521dn","RR":"NO"},
    {"np":"2361948","Model":"MS521dn","Product":"MS521 3yr OSR  NBD","Yield":"","AA":"2361948MS521dn","RR":"NO"},
    {"np":"2361949","Model":"MS521dn","Product":"MS521 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2361949MS521dn","RR":"NO"},
    {"np":"2361950","Model":"MS521dn","Product":"MS521 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2361950MS521dn","RR":"NO"},
    {"np":"2361951","Model":"MS521dn","Product":"MS521 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2361951MS521dn","RR":"NO"},
    {"np":"2361960","Model":"MS521dn","Product":"MS521 1yr Parts Only","Yield":"","AA":"2361960MS521dn","RR":"NO"},
    {"np":"2361961","Model":"MS521dn","Product":"MS521 2yr Parts Only","Yield":"","AA":"2361961MS521dn","RR":"NO"},
    {"np":"2361962","Model":"MS521dn","Product":"MS521 3yr Parts Only","Yield":"","AA":"2361962MS521dn","RR":"NO"},
    {"np":"2361963","Model":"MS521dn","Product":"MS521 4yr Parts Only","Yield":"","AA":"2361963MS521dn","RR":"NO"},
    {"np":"2361964","Model":"MS521dn","Product":"MS521 1yr Renew Parts Only","Yield":"","AA":"2361964MS521dn","RR":"NO"},
    {"np":"2361993","Model":"MS621dn","Product":"MS621 1yr OSR  NBD","Yield":"","AA":"2361993MS621dn","RR":"NO"},
    {"np":"2361994","Model":"MS621dn","Product":"MS621 2yr OSR  NBD","Yield":"","AA":"2361994MS621dn","RR":"NO"},
    {"np":"2361995","Model":"MS621dn","Product":"MS621 3yr OSR  NBD","Yield":"","AA":"2361995MS621dn","RR":"NO"},
    {"np":"2361996","Model":"MS621dn","Product":"MS621 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2361996MS621dn","RR":"NO"},
    {"np":"2361997","Model":"MS621dn","Product":"MS621 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2361997MS621dn","RR":"NO"},
    {"np":"2361998","Model":"MS621dn","Product":"MS621 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2361998MS621dn","RR":"NO"},
    {"np":"2362007","Model":"MS621dn","Product":"MS621 1yr Parts Only","Yield":"","AA":"2362007MS621dn","RR":"NO"},
    {"np":"2362008","Model":"MS621dn","Product":"MS621 2yr Parts Only","Yield":"","AA":"2362008MS621dn","RR":"NO"},
    {"np":"2362009","Model":"MS621dn","Product":"MS621 3yr Parts Only","Yield":"","AA":"2362009MS621dn","RR":"NO"},
    {"np":"2362010","Model":"MS621dn","Product":"MS621 4yr Parts Only","Yield":"","AA":"2362010MS621dn","RR":"NO"},
    {"np":"2362011","Model":"MS621dn","Product":"MS621 1yr Renew Parts Only","Yield":"","AA":"2362011MS621dn","RR":"NO"},
    {"np":"2362040","Model":"MS622de","Product":"MS622 1yr OSR  NBD","Yield":"","AA":"2362040MS622de","RR":"NO"},
    {"np":"2362041","Model":"MS622de","Product":"MS622 2yr OSR  NBD","Yield":"","AA":"2362041MS622de","RR":"NO"},
    {"np":"2362042","Model":"MS622de","Product":"MS622 3yr OSR  NBD","Yield":"","AA":"2362042MS622de","RR":"NO"},
    {"np":"2362043","Model":"MS622de","Product":"MS622 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362043MS622de","RR":"NO"},
    {"np":"2362044","Model":"MS622de","Product":"MS622 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362044MS622de","RR":"NO"},
    {"np":"2362045","Model":"MS622de","Product":"MS622 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362045MS622de","RR":"NO"},
    {"np":"2362054","Model":"MS622de","Product":"MS622 1yr Parts Only","Yield":"","AA":"2362054MS622de","RR":"NO"},
    {"np":"2362055","Model":"MS622de","Product":"MS622 2yr Parts Only","Yield":"","AA":"2362055MS622de","RR":"NO"},
    {"np":"2362056","Model":"MS622de","Product":"MS622 3yr Parts Only","Yield":"","AA":"2362056MS622de","RR":"NO"},
    {"np":"2362057","Model":"MS622de","Product":"MS622 4yr Parts Only","Yield":"","AA":"2362057MS622de","RR":"NO"},
    {"np":"2362058","Model":"MS622de","Product":"MS622 1yr Renew Parts Only","Yield":"","AA":"2362058MS622de","RR":"NO"},
    {"np":"2362071","Model":"MX321adn","Product":"MX321 1yr OSR NBD Extended 1yr NBD OSR","Yield":"","AA":"2362071MX321adn","RR":"NO"},
    {"np":"2362072","Model":"MX321adn","Product":"MX321 2yr OSR NBD Extended 2yr NBD OSR","Yield":"","AA":"2362072MX321adn","RR":"NO"},
    {"np":"2362073","Model":"MX321adn","Product":"MX321 3yr OSR NBD Extended 3yr NBD OSR","Yield":"","AA":"2362073MX321adn","RR":"NO"},
    {"np":"2362074","Model":"MX321adn","Product":"MX321 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362074MX321adn","RR":"NO"},
    {"np":"2362075","Model":"MX321adn","Product":"MX321 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362075MX321adn","RR":"NO"},
    {"np":"2362076","Model":"MX321adn","Product":"MX321 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362076MX321adn","RR":"NO"},
    {"np":"2362086","Model":"MX321adn","Product":"MX321 1yr Parts Only","Yield":"","AA":"2362086MX321adn","RR":"NO"},
    {"np":"2362087","Model":"MX321adn","Product":"MX321 2yr Parts Only","Yield":"","AA":"2362087MX321adn","RR":"NO"},
    {"np":"2362088","Model":"MX321adn","Product":"MX321 3yr Parts Only","Yield":"","AA":"2362088MX321adn","RR":"NO"},
    {"np":"2362089","Model":"MX321adn","Product":"MX321 4yr Parts Only","Yield":"","AA":"2362089MX321adn","RR":"NO"},
    {"np":"2362090","Model":"MX321adn","Product":"MX321 1yr Renew Parts Only","Yield":"","AA":"2362090MX321adn","RR":"NO"},
    {"np":"2362103","Model":"MX421ade","Product":"MX421 1yr OSR NBD Extended 1yr NBD OSR","Yield":"","AA":"2362103MX421ade","RR":"NO"},
    {"np":"2362104","Model":"MX421ade","Product":"MX421 2yr OSR NBD Extended 2yr NBD OSR","Yield":"","AA":"2362104MX421ade","RR":"NO"},
    {"np":"2362105","Model":"MX421ade","Product":"MX421 3yr OSR NBD Extended 3yr NBD OSR","Yield":"","AA":"2362105MX421ade","RR":"NO"},
    {"np":"2362106","Model":"MX421ade","Product":"MX421 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362106MX421ade","RR":"NO"},
    {"np":"2362107","Model":"MX421ade","Product":"MX421 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362107MX421ade","RR":"NO"},
    {"np":"2362108","Model":"MX421ade","Product":"MX421 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362108MX421ade","RR":"NO"},
    {"np":"2362118","Model":"MX421ade","Product":"MX421 1yr Parts Only","Yield":"","AA":"2362118MX421ade","RR":"NO"},
    {"np":"2362119","Model":"MX421ade","Product":"MX421 2yr Parts Only","Yield":"","AA":"2362119MX421ade","RR":"NO"},
    {"np":"2362120","Model":"MX421ade","Product":"MX421 3yr Parts Only","Yield":"","AA":"2362120MX421ade","RR":"NO"},
    {"np":"2362121","Model":"MX421ade","Product":"MX421 4yr Parts Only","Yield":"","AA":"2362121MX421ade","RR":"NO"},
    {"np":"2362122","Model":"MX421ade","Product":"MX421 1yr Renew Parts Only","Yield":"","AA":"2362122MX421ade","RR":"NO"},
    {"np":"2362135","Model":"MX521ade","Product":"MX521 1yr OSR NBD","Yield":"","AA":"2362135MX521ade","RR":"NO"},
    {"np":"2362136","Model":"MX521ade","Product":"MX521 2yr OSR NBD","Yield":"","AA":"2362136MX521ade","RR":"NO"},
    {"np":"2362137","Model":"MX521ade","Product":"MX521 3yr OSR NBD","Yield":"","AA":"2362137MX521ade","RR":"NO"},
    {"np":"2362138","Model":"MX521ade","Product":"MX521 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362138MX521ade","RR":"NO"},
    {"np":"2362139","Model":"MX521ade","Product":"MX521 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362139MX521ade","RR":"NO"},
    {"np":"2362140","Model":"MX521ade","Product":"MX521 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362140MX521ade","RR":"NO"},
    {"np":"2362150","Model":"MX521ade","Product":"MX521 1yr Parts Only","Yield":"","AA":"2362150MX521ade","RR":"NO"},
    {"np":"2362151","Model":"MX521ade","Product":"MX521 2yr Parts Only","Yield":"","AA":"2362151MX521ade","RR":"NO"},
    {"np":"2362152","Model":"MX521ade","Product":"MX521 3yr Parts Only","Yield":"","AA":"2362152MX521ade","RR":"NO"},
    {"np":"2362153","Model":"MX521ade","Product":"MX521 4yr Parts Only","Yield":"","AA":"2362153MX521ade","RR":"NO"},
    {"np":"2362154","Model":"MX521ade","Product":"MX521 1yr Renew Parts Only","Yield":"","AA":"2362154MX521ade","RR":"NO"},
    {"np":"2362167","Model":"MX522adhe","Product":"MX522 1yr OSR NBD","Yield":"","AA":"2362167MX522adhe","RR":"NO"},
    {"np":"2362168","Model":"MX522adhe","Product":"MX522 2yr OSR NBD","Yield":"","AA":"2362168MX522adhe","RR":"NO"},
    {"np":"2362169","Model":"MX522adhe","Product":"MX522 3yr OSR NBD","Yield":"","AA":"2362169MX522adhe","RR":"NO"},
    {"np":"2362170","Model":"MX522adhe","Product":"MX522 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362170MX522adhe","RR":"NO"},
    {"np":"2362171","Model":"MX522adhe","Product":"MX522 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362171MX522adhe","RR":"NO"},
    {"np":"2362172","Model":"MX522adhe","Product":"MX522 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362172MX522adhe","RR":"NO"},
    {"np":"2362182","Model":"MX522adhe","Product":"MX522 1yr Parts Only","Yield":"","AA":"2362182MX522adhe","RR":"NO"},
    {"np":"2362183","Model":"MX522adhe","Product":"MX522 2yr Parts Only","Yield":"","AA":"2362183MX522adhe","RR":"NO"},
    {"np":"2362184","Model":"MX522adhe","Product":"MX522 3yr Parts Only","Yield":"","AA":"2362184MX522adhe","RR":"NO"},
    {"np":"2362185","Model":"MX522adhe","Product":"MX522 4yr Parts Only","Yield":"","AA":"2362185MX522adhe","RR":"NO"},
    {"np":"2362186","Model":"MX522adhe","Product":"MX522 1yr Renew Parts Only","Yield":"","AA":"2362186MX522adhe","RR":"NO"},
    {"np":"2362199","Model":"MX622adhe","Product":"MX622 1yr OSR NBD","Yield":"","AA":"2362199MX622adhe","RR":"NO"},
    {"np":"2362200","Model":"MX622adhe","Product":"MX622 2yr OSR NBD","Yield":"","AA":"2362200MX622adhe","RR":"NO"},
    {"np":"2362201","Model":"MX622adhe","Product":"MX622 3yr OSR NBD","Yield":"","AA":"2362201MX622adhe","RR":"NO"},
    {"np":"2362202","Model":"MX622adhe","Product":"MX622 4yr OSR NBD Extended 4yr NBD OSR","Yield":"","AA":"2362202MX622adhe","RR":"NO"},
    {"np":"2362203","Model":"MX622adhe","Product":"MX622 1yr Renew OSR NBD Renewal 1yr NBD","Yield":"","AA":"2362203MX622adhe","RR":"NO"},
    {"np":"2362204","Model":"MX622adhe","Product":"MX622 1yr Post Wty OSR NBD Post 1yr NBD","Yield":"","AA":"2362204MX622adhe","RR":"NO"},
    {"np":"2362214","Model":"MX622adhe","Product":"MX622 1yr Parts Only","Yield":"","AA":"2362214MX622adhe","RR":"NO"},
    {"np":"2362215","Model":"MX622adhe","Product":"MX622 2yr Parts Only","Yield":"","AA":"2362215MX622adhe","RR":"NO"},
    {"np":"2362216","Model":"MX622adhe","Product":"MX622 3yr Parts Only","Yield":"","AA":"2362216MX622adhe","RR":"NO"},
    {"np":"2362217","Model":"MX622adhe","Product":"MX622 4yr Parts Only","Yield":"","AA":"2362217MX622adhe","RR":"NO"},
    {"np":"2362218","Model":"MX622adhe","Product":"MX622 1yr Renew Parts Only","Yield":"","AA":"2362218MX622adhe","RR":"NO"},
    {"np":"2363265","Model":"MS821dn","Product":"MS821 1yr OSR  NBD","Yield":"","AA":"2363265MS821dn","RR":"NO"},
    {"np":"2363266","Model":"MS821dn","Product":"MS821 2yr OSR  NBD","Yield":"","AA":"2363266MS821dn","RR":"NO"},
    {"np":"2363267","Model":"MS821dn","Product":"MS821 3yr OSR  NBD","Yield":"","AA":"2363267MS821dn","RR":"NO"},
    {"np":"2363268","Model":"MS821dn","Product":"MS821 4yr OSR  NBD","Yield":"","AA":"2363268MS821dn","RR":"NO"},
    {"np":"2363269","Model":"MS821dn","Product":"MS821 1yr Renew OSR  NBD","Yield":"","AA":"2363269MS821dn","RR":"NO"},
    {"np":"2363270","Model":"MS821dn","Product":"MS821 1yr Post Wty OSR  NBD","Yield":"","AA":"2363270MS821dn","RR":"NO"},
    {"np":"2363279","Model":"MS821dn","Product":"MS821 1yr Parts Only","Yield":"","AA":"2363279MS821dn","RR":"NO"},
    {"np":"2363280","Model":"MS821dn","Product":"MS821 2yr Parts Only","Yield":"","AA":"2363280MS821dn","RR":"NO"},
    {"np":"2363281","Model":"MS821dn","Product":"MS821 3yr Parts Only","Yield":"","AA":"2363281MS821dn","RR":"NO"},
    {"np":"2363282","Model":"MS821dn","Product":"MS821 4yr Parts Only","Yield":"","AA":"2363282MS821dn","RR":"NO"},
    {"np":"2363283","Model":"MS821dn","Product":"MS821 1yr Renew Parts Only","Yield":"","AA":"2363283MS821dn","RR":"NO"},
    {"np":"2363373","Model":"MS823dn","Product":"MS823 1yr OSR  NBD","Yield":"","AA":"2363373MS823dn","RR":"NO"},
    {"np":"2363374","Model":"MS823dn","Product":"MS823 2yr OSR  NBD","Yield":"","AA":"2363374MS823dn","RR":"NO"},
    {"np":"2363375","Model":"MS823dn","Product":"MS823 3yr OSR  NBD","Yield":"","AA":"2363375MS823dn","RR":"NO"},
    {"np":"2363376","Model":"MS823dn","Product":"MS823 4yr OSR  NBD","Yield":"","AA":"2363376MS823dn","RR":"NO"},
    {"np":"2363377","Model":"MS823dn","Product":"MS823 1yr Renew OSR  NBD","Yield":"","AA":"2363377MS823dn","RR":"NO"},
    {"np":"2363378","Model":"MS823dn","Product":"MS823 1yr Post Wty OSR  NBD","Yield":"","AA":"2363378MS823dn","RR":"NO"},
    {"np":"2363387","Model":"MS823dn","Product":"MS823 1yr Parts Only","Yield":"","AA":"2363387MS823dn","RR":"NO"},
    {"np":"2363388","Model":"MS823dn","Product":"MS823 2yr Parts Only","Yield":"","AA":"2363388MS823dn","RR":"NO"},
    {"np":"2363389","Model":"MS823dn","Product":"MS823 3yr Parts Only","Yield":"","AA":"2363389MS823dn","RR":"NO"},
    {"np":"2363390","Model":"MS823dn","Product":"MS823 4yr Parts Only","Yield":"","AA":"2363390MS823dn","RR":"NO"},
    {"np":"2363391","Model":"MS823dn","Product":"MS823 1yr Renew Parts Only","Yield":"","AA":"2363391MS823dn","RR":"NO"},
    {"np":"2363535","Model":"MS826de","Product":"MS823 1yr OSR  NBD","Yield":"","AA":"2363535MS826de","RR":"NO"},
    {"np":"2363536","Model":"MS826de","Product":"MS823 2yr OSR  NBD","Yield":"","AA":"2363536MS826de","RR":"NO"},
    {"np":"2363537","Model":"MS826de","Product":"MS823 3yr OSR  NBD","Yield":"","AA":"2363537MS826de","RR":"NO"},
    {"np":"2363538","Model":"MS826de","Product":"MS826 4yr OSR  NBD","Yield":"","AA":"2363538MS826de","RR":"NO"},
    {"np":"2363539","Model":"MS826de","Product":"MS826 1yr Renew OSR  NBD","Yield":"","AA":"2363539MS826de","RR":"NO"},
    {"np":"2363540","Model":"MS826de","Product":"MS826 1yr Post Wty OSR  NBD","Yield":"","AA":"2363540MS826de","RR":"NO"},
    {"np":"2363549","Model":"MS826de","Product":"MS826 1yr Parts Only","Yield":"","AA":"2363549MS826de","RR":"NO"},
    {"np":"2363550","Model":"MS826de","Product":"MS826 2yr Parts Only","Yield":"","AA":"2363550MS826de","RR":"NO"},
    {"np":"2363551","Model":"MS826de","Product":"MS826 3yr Parts Only","Yield":"","AA":"2363551MS826de","RR":"NO"},
    {"np":"2363552","Model":"MS826de","Product":"MS826 4yr Parts Only","Yield":"","AA":"2363552MS826de","RR":"NO"},
    {"np":"2363553","Model":"MS826de","Product":"MS826 1yr Renew Parts Only","Yield":"","AA":"2363553MS826de","RR":"NO"},
    {"np":"2363656","Model":"MX721ade","Product":"MX722 1yr OSR  NBD","Yield":"","AA":"2363656MX721ade","RR":"NO"},
    {"np":"2363657","Model":"MX721ade","Product":"MX722 2yr OSR  NBD","Yield":"","AA":"2363657MX721ade","RR":"NO"},
    {"np":"2363658","Model":"MX721ade","Product":"MX722 3yr OSR  NBD","Yield":"","AA":"2363658MX721ade","RR":"NO"},
    {"np":"2363694","Model":"MX722adhe","Product":"MX722 1yr OSR  NBD","Yield":"","AA":"2363694MX722adhe","RR":"NO"},
    {"np":"2363695","Model":"MX722adhe","Product":"MX722 2yr OSR  NBD","Yield":"","AA":"2363695MX722adhe","RR":"NO"},
    {"np":"2363696","Model":"MX722adhe","Product":"MX722 3yr OSR  NBD","Yield":"","AA":"2363696MX722adhe","RR":"NO"},
    {"np":"2363697","Model":"MX722adhe","Product":"MX722 4yr OSR  NBD","Yield":"","AA":"2363697MX722adhe","RR":"NO"},
    {"np":"2363698","Model":"MX722adhe","Product":"MX722 1yr Renew OSR  NBD","Yield":"","AA":"2363698MX722adhe","RR":"NO"},
    {"np":"2363699","Model":"MX722adhe","Product":"MX722 1yr Post Wty OSR  NBD","Yield":"","AA":"2363699MX722adhe","RR":"NO"},
    {"np":"2363709","Model":"MX722adhe","Product":"MX722 1yr Parts Only","Yield":"","AA":"2363709MX722adhe","RR":"NO"},
    {"np":"2363710","Model":"MX722adhe","Product":"MX722 2yr Parts Only","Yield":"","AA":"2363710MX722adhe","RR":"NO"},
    {"np":"2363711","Model":"MX722adhe","Product":"MX722 3yr Parts Only","Yield":"","AA":"2363711MX722adhe","RR":"NO"},
    {"np":"2363712","Model":"MX722adhe","Product":"MX722 4yr Parts Only","Yield":"","AA":"2363712MX722adhe","RR":"NO"},
    {"np":"2363713","Model":"MX722adhe","Product":"MX722 1yr Renew Parts Only","Yield":"","AA":"2363713MX722adhe","RR":"NO"},
    {"np":"2363770","Model":"MX822ade","Product":"MX822 1yr OSR  NBD","Yield":"","AA":"2363770MX822ade","RR":"NO"},
    {"np":"2363771","Model":"MX822ade","Product":"MX822 2yr OSR  NBD","Yield":"","AA":"2363771MX822ade","RR":"NO"},
    {"np":"2363772","Model":"MX822ade","Product":"MX822 3yr OSR  NBD","Yield":"","AA":"2363772MX822ade","RR":"NO"},
    {"np":"2363773","Model":"MX822ade","Product":"MX822 4yr OSR  NBD","Yield":"","AA":"2363773MX822ade","RR":"NO"},
    {"np":"2363774","Model":"MX822ade","Product":"MX822 1yr Renew OSR  NBD","Yield":"","AA":"2363774MX822ade","RR":"NO"},
    {"np":"2363775","Model":"MX822ade","Product":"MX822 1yr Post Wty OSR  NBD","Yield":"","AA":"2363775MX822ade","RR":"NO"},
    {"np":"2363785","Model":"MX822ade","Product":"MX822 1yr Parts Only","Yield":"","AA":"2363785MX822ade","RR":"NO"},
    {"np":"2363786","Model":"MX822ade","Product":"MX822 2yr Parts Only","Yield":"","AA":"2363786MX822ade","RR":"NO"},
    {"np":"2363787","Model":"MX822ade","Product":"MX822 3yr Parts Only","Yield":"","AA":"2363787MX822ade","RR":"NO"},
    {"np":"2363788","Model":"MX822ade","Product":"MX822 4yr Parts Only","Yield":"","AA":"2363788MX822ade","RR":"NO"},
    {"np":"2363789","Model":"MX822ade","Product":"MX822 1yr Renew Parts Only","Yield":"","AA":"2363789MX822ade","RR":"NO"},
    {"np":"2363846","Model":"MX826ade","Product":"MX826 1yr OSR  NBD","Yield":"","AA":"2363846MX826ade","RR":"NO"},
    {"np":"2363847","Model":"MX826ade","Product":"MX826 2yr OSR  NBD","Yield":"","AA":"2363847MX826ade","RR":"NO"},
    {"np":"2363848","Model":"MX826ade","Product":"MX826 3yr OSR  NBD","Yield":"","AA":"2363848MX826ade","RR":"NO"},
    {"np":"2363849","Model":"MX826ade","Product":"MX826 4yr OSR  NBD","Yield":"","AA":"2363849MX826ade","RR":"NO"},
    {"np":"2363850","Model":"MX826ade","Product":"MX826 1yr Renew OSR  NBD","Yield":"","AA":"2363850MX826ade","RR":"NO"},
    {"np":"2363851","Model":"MX826ade","Product":"MX826 1yr Post Wty OSR  NBD","Yield":"","AA":"2363851MX826ade","RR":"NO"},
    {"np":"2363861","Model":"MX826ade","Product":"MX826 1yr Parts Only","Yield":"","AA":"2363861MX826ade","RR":"NO"},
    {"np":"2363862","Model":"MX826ade","Product":"MX826 2yr Parts Only","Yield":"","AA":"2363862MX826ade","RR":"NO"},
    {"np":"2363863","Model":"MX826ade","Product":"MX826 3yr Parts Only","Yield":"","AA":"2363863MX826ade","RR":"NO"},
    {"np":"2363864","Model":"MX826ade","Product":"MX826 4yr Parts Only","Yield":"","AA":"2363864MX826ade","RR":"NO"},
    {"np":"2363865","Model":"MX826ade","Product":"MX826 1yr Renew Parts Only","Yield":"","AA":"2363865MX826ade","RR":"NO"},
    {"np":"2363919","Model":"CS421dn","Product":"CS421 1yr OSR  NBD","Yield":"","AA":"2363919CS421dn","RR":"NO"},
    {"np":"2363920","Model":"CS421dn","Product":"CS421 2yr OSR  NBD","Yield":"","AA":"2363920CS421dn","RR":"NO"},
    {"np":"2363921","Model":"CS421dn","Product":"CS421 3yr OSR  NBD","Yield":"","AA":"2363921CS421dn","RR":"NO"},
    {"np":"2363922","Model":"CS421dn","Product":"CS421 4yr OSR  NBD","Yield":"","AA":"2363922CS421dn","RR":"NO"},
    {"np":"2363923","Model":"CS421dn","Product":"CS421 1yr Renew OSR  NBD","Yield":"","AA":"2363923CS421dn","RR":"NO"},
    {"np":"2363924","Model":"CS421dn","Product":"CS421 1yr Post Wty OSR  NBD","Yield":"","AA":"2363924CS421dn","RR":"NO"},
    {"np":"2363933","Model":"CS421dn","Product":"CS421 1yr Parts Only","Yield":"","AA":"2363933CS421dn","RR":"NO"},
    {"np":"2363934","Model":"CS421dn","Product":"CS421 2yr Parts Only","Yield":"","AA":"2363934CS421dn","RR":"NO"},
    {"np":"2363935","Model":"CS421dn","Product":"CS421 3yr Parts Only","Yield":"","AA":"2363935CS421dn","RR":"NO"},
    {"np":"2363936","Model":"CS421dn","Product":"CS421 4yr Parts Only","Yield":"","AA":"2363936CS421dn","RR":"NO"},
    {"np":"2363937","Model":"CS421dn","Product":"CS421 1yr Renew Parts Only","Yield":"","AA":"2363937CS421dn","RR":"NO"},
    {"np":"2363973","Model":"CS521dn","Product":"CS521 1yr OSR  NBD","Yield":"","AA":"2363973CS521dn","RR":"NO"},
    {"np":"2363974","Model":"CS521dn","Product":"CS521 2yr OSR  NBD","Yield":"","AA":"2363974CS521dn","RR":"NO"},
    {"np":"2363975","Model":"CS521dn","Product":"CS521 3yr OSR  NBD","Yield":"","AA":"2363975CS521dn","RR":"NO"},
    {"np":"2363976","Model":"CS521dn","Product":"CS521 4yr OSR  NBD","Yield":"","AA":"2363976CS521dn","RR":"NO"},
    {"np":"2363977","Model":"CS521dn","Product":"CS521 1yr Renew OSR  NBD","Yield":"","AA":"2363977CS521dn","RR":"NO"},
    {"np":"2363978","Model":"CS521dn","Product":"CS521 1yr Post Wty OSR  NBD","Yield":"","AA":"2363978CS521dn","RR":"NO"},
    {"np":"2363987","Model":"CS521dn","Product":"CS521 1yr Parts Only","Yield":"","AA":"2363987CS521dn","RR":"NO"},
    {"np":"2363988","Model":"CS521dn","Product":"CS521 2yr Parts Only","Yield":"","AA":"2363988CS521dn","RR":"NO"},
    {"np":"2363989","Model":"CS521dn","Product":"CS521 3yr Parts Only","Yield":"","AA":"2363989CS521dn","RR":"NO"},
    {"np":"2363990","Model":"CS521dn","Product":"CS521 4yr Parts Only","Yield":"","AA":"2363990CS521dn","RR":"NO"},
    {"np":"2363991","Model":"CS521dn","Product":"CS521 1yr Renew Parts Only","Yield":"","AA":"2363991CS521dn","RR":"NO"},
    {"np":"2364027","Model":"CS622de","Product":"CS622 1yr OSR  NBD","Yield":"","AA":"2364027CS622de","RR":"NO"},
    {"np":"2364028","Model":"CS622de","Product":"CS622 2yr OSR  NBD","Yield":"","AA":"2364028CS622de","RR":"NO"},
    {"np":"2364029","Model":"CS622de","Product":"CS622 3yr OSR  NBD","Yield":"","AA":"2364029CS622de","RR":"NO"},
    {"np":"2364030","Model":"CS622de","Product":"CS622 4yr OSR  NBD","Yield":"","AA":"2364030CS622de","RR":"NO"},
    {"np":"2364031","Model":"CS622de","Product":"CS622 1yr Renew OSR  NBD","Yield":"","AA":"2364031CS622de","RR":"NO"},
    {"np":"2364032","Model":"CS622de","Product":"CS622 1yr Post Wty OSR  NBD","Yield":"","AA":"2364032CS622de","RR":"NO"},
    {"np":"2364041","Model":"CS622de","Product":"CS622 1yr Parts Only","Yield":"","AA":"2364041CS622de","RR":"NO"},
    {"np":"2364042","Model":"CS622de","Product":"CS622 2yr Parts Only","Yield":"","AA":"2364042CS622de","RR":"NO"},
    {"np":"2364043","Model":"CS622de","Product":"CS622 3yr Parts Only","Yield":"","AA":"2364043CS622de","RR":"NO"},
    {"np":"2364044","Model":"CS622de","Product":"CS622 4yr Parts Only","Yield":"","AA":"2364044CS622de","RR":"NO"},
    {"np":"2364045","Model":"CS622de","Product":"CS622 1yr Renew Parts Only","Yield":"","AA":"2364045CS622de","RR":"NO"},
    {"np":"2364152","Model":"CX421adn","Product":"CX421 1yr OSR  NBD","Yield":"","AA":"2364152CX421adn","RR":"NO"},
    {"np":"2364153","Model":"CX421adn","Product":"CX421 2yr OSR  NBD","Yield":"","AA":"2364153CX421adn","RR":"NO"},
    {"np":"2364154","Model":"CX421adn","Product":"CX421 3yr OSR  NBD","Yield":"","AA":"2364154CX421adn","RR":"NO"},
    {"np":"2364155","Model":"CX421adn","Product":"CX421 4yr OSR  NBD","Yield":"","AA":"2364155CX421adn","RR":"NO"},
    {"np":"2364156","Model":"CX421adn","Product":"CX421 1yr Renew OSR  NBD","Yield":"","AA":"2364156CX421adn","RR":"NO"},
    {"np":"2364157","Model":"CX421adn","Product":"CX421 1yr Post Wty OSR  NBD","Yield":"","AA":"2364157CX421adn","RR":"NO"},
    {"np":"2364167","Model":"CX421adn","Product":"CX421 1yr Parts Only","Yield":"","AA":"2364167CX421adn","RR":"NO"},
    {"np":"2364168","Model":"CX421adn","Product":"CX421 2yr Parts Only","Yield":"","AA":"2364168CX421adn","RR":"NO"},
    {"np":"2364169","Model":"CX421adn","Product":"CX421 3yr Parts Only","Yield":"","AA":"2364169CX421adn","RR":"NO"},
    {"np":"2364170","Model":"CX421adn","Product":"CX421 4yr Parts Only","Yield":"","AA":"2364170CX421adn","RR":"NO"},
    {"np":"2364171","Model":"CX421adn","Product":"CX421 1yr Renew Parts Only","Yield":"","AA":"2364171CX421adn","RR":"NO"},
    {"np":"2364190","Model":"CX522ade","Product":"CX522 1yr OSR  NBD","Yield":"","AA":"2364190CX522ade","RR":"NO"},
    {"np":"2364191","Model":"CX522ade","Product":"CX522 2yr OSR  NBD","Yield":"","AA":"2364191CX522ade","RR":"NO"},
    {"np":"2364192","Model":"CX522ade","Product":"CX522 3yr OSR  NBD","Yield":"","AA":"2364192CX522ade","RR":"NO"},
    {"np":"2364193","Model":"CX522ade","Product":"CX522 4yr OSR  NBD","Yield":"","AA":"2364193CX522ade","RR":"NO"},
    {"np":"2364194","Model":"CX522ade","Product":"CX522 1yr Renew OSR  NBD","Yield":"","AA":"2364194CX522ade","RR":"NO"},
    {"np":"2364195","Model":"CX522ade","Product":"CX522 1yr Post Wty OSR  NBD","Yield":"","AA":"2364195CX522ade","RR":"NO"},
    {"np":"2364205","Model":"CX522ade","Product":"CX522 1yr Parts Only","Yield":"","AA":"2364205CX522ade","RR":"NO"},
    {"np":"2364206","Model":"CX522ade","Product":"CX522 2yr Parts Only","Yield":"","AA":"2364206CX522ade","RR":"NO"},
    {"np":"2364207","Model":"CX522ade","Product":"CX522 3yr Parts Only","Yield":"","AA":"2364207CX522ade","RR":"NO"},
    {"np":"2364208","Model":"CX522ade","Product":"CX522 4yr Parts Only","Yield":"","AA":"2364208CX522ade","RR":"NO"},
    {"np":"2364209","Model":"CX522ade","Product":"CX522 1yr Renew Parts Only","Yield":"","AA":"2364209CX522ade","RR":"NO"},
    {"np":"2364228","Model":"CX622ade","Product":"CX622 1yr OSR  NBD","Yield":"","AA":"2364228CX622ade","RR":"NO"},
    {"np":"2364229","Model":"CX622ade","Product":"CX622 2yr OSR  NBD","Yield":"","AA":"2364229CX622ade","RR":"NO"},
    {"np":"2364230","Model":"CX622ade","Product":"CX622 3yr OSR  NBD","Yield":"","AA":"2364230CX622ade","RR":"NO"},
    {"np":"2364231","Model":"CX622ade","Product":"CX622 4yr OSR  NBD","Yield":"","AA":"2364231CX622ade","RR":"NO"},
    {"np":"2364232","Model":"CX622ade","Product":"CX622 1yr Renew OSR  NBD","Yield":"","AA":"2364232CX622ade","RR":"NO"},
    {"np":"2364233","Model":"CX622ade","Product":"CX622 1yr Post Wty OSR  NBD","Yield":"","AA":"2364233CX622ade","RR":"NO"},
    {"np":"2364243","Model":"CX622ade","Product":"CX622 1yr Parts Only","Yield":"","AA":"2364243CX622ade","RR":"NO"},
    {"np":"2364244","Model":"CX622ade","Product":"CX622 2yr Parts Only","Yield":"","AA":"2364244CX622ade","RR":"NO"},
    {"np":"2364245","Model":"CX622ade","Product":"CX622 3yr Parts Only","Yield":"","AA":"2364245CX622ade","RR":"NO"},
    {"np":"2364246","Model":"CX622ade","Product":"CX622 4yr Parts Only","Yield":"","AA":"2364246CX622ade","RR":"NO"},
    {"np":"2364247","Model":"CX622ade","Product":"CX622 1yr Renew Parts Only","Yield":"","AA":"2364247CX622ade","RR":"NO"},
    {"np":"2364266","Model":"CX625adhe","Product":"CX625 1yr OSR  NBD","Yield":"","AA":"2364266CX625adhe","RR":"NO"},
    {"np":"2364267","Model":"CX625adhe","Product":"CX625 2yr OSR  NBD","Yield":"","AA":"2364267CX625adhe","RR":"NO"},
    {"np":"2364268","Model":"CX625adhe","Product":"CX625 3yr OSR  NBD","Yield":"","AA":"2364268CX625adhe","RR":"NO"},
    {"np":"2364269","Model":"CX625adhe","Product":"CX625 4yr OSR  NBD","Yield":"","AA":"2364269CX625adhe","RR":"NO"},
    {"np":"2364270","Model":"CX625adhe","Product":"CX625 1yr Renew OSR  NBD","Yield":"","AA":"2364270CX625adhe","RR":"NO"},
    {"np":"2364271","Model":"CX625adhe","Product":"CX625 1yr Post Wty OSR  NBD","Yield":"","AA":"2364271CX625adhe","RR":"NO"},
    {"np":"2364281","Model":"CX625adhe","Product":"CX625 1yr Parts Only","Yield":"","AA":"2364281CX625adhe","RR":"NO"},
    {"np":"2364282","Model":"CX625adhe","Product":"CX625 2yr Parts Only","Yield":"","AA":"2364282CX625adhe","RR":"NO"},
    {"np":"2364283","Model":"CX625adhe","Product":"CX625 3yr Parts Only","Yield":"","AA":"2364283CX625adhe","RR":"NO"},
    {"np":"2364284","Model":"CX625adhe","Product":"CX625 4yr Parts Only","Yield":"","AA":"2364284CX625adhe","RR":"NO"},
    {"np":"2364285","Model":"CX625adhe","Product":"CX625 1yr Renew Parts Only","Yield":"","AA":"2364285CX625adhe","RR":"NO"},
    {"np":"2367475","Model":"B2236dw","Product":"B2236 1yr OSR  NBD","Yield":"","AA":"2367475B2236dw","RR":"NO"},
    {"np":"2367476","Model":"B2236dw","Product":"B2236 2yr OSR  NBD","Yield":"","AA":"2367476B2236dw","RR":"NO"},
    {"np":"2367477","Model":"B2236dw","Product":"B2236 3yr OSR  NBD","Yield":"","AA":"2367477B2236dw","RR":"NO"},
    {"np":"2367514","Model":"MB2236adw","Product":"MB2236 1yr OSR  NBD","Yield":"","AA":"2367514MB2236adw","RR":"NO"},
    {"np":"2367514","Model":"MB2236adwe","Product":"MB2236 1yr OSR  NBD","Yield":"","AA":"2367514MB2236adwe","RR":"NO"},
    {"np":"2367515","Model":"MB2236adw","Product":"MB2236 2yr OSR  NBD","Yield":"","AA":"2367515MB2236adw","RR":"NO"},
    {"np":"2367515","Model":"MB2236adwe","Product":"MB2236 2yr OSR  NBD","Yield":"","AA":"2367515MB2236adwe","RR":"NO"},
    {"np":"2367516","Model":"MB2236adw","Product":"MB2236 3yr OSR  NBD","Yield":"","AA":"2367516MB2236adw","RR":"NO"},
    {"np":"2367516","Model":"MB2236adwe","Product":"MB2236 3yr OSR  NBD","Yield":"","AA":"2367516MB2236adwe","RR":"NO"},
    {"np":"2370773","Model":"C3224dw","Product":"C3224 1yr OSR  NBD","Yield":"","AA":"2370773C3224dw","RR":"NO"},
    {"np":"2370774","Model":"C3224dw","Product":"C3224 2yr OSR  NBD","Yield":"","AA":"2370774C3224dw","RR":"NO"},
    {"np":"2370775","Model":"C3224dw","Product":"C3224 3yr OSR  NBD","Yield":"","AA":"2370775C3224dw","RR":"NO"},
    {"np":"2370847","Model":"MC3224adwe","Product":"MC3224 1yr OSR  NBD","Yield":"","AA":"2370847MC3224adwe","RR":"NO"},
    {"np":"2370847","Model":"MC3224dwe","Product":"MC3224 1yr OSR  NBD","Yield":"","AA":"2370847MC3224dwe","RR":"NO"},
    {"np":"2370848","Model":"MC3224adwe","Product":"MC3224 2yr OSR  NBD","Yield":"","AA":"2370848MC3224adwe","RR":"NO"},
    {"np":"2370848","Model":"MC3224dwe","Product":"MC3224 2yr OSR  NBD","Yield":"","AA":"2370848MC3224dwe","RR":"NO"},
    {"np":"2370849","Model":"MC3224adwe","Product":"MC3224 3yr OSR  NBD","Yield":"","AA":"2370849MC3224adwe","RR":"NO"},
    {"np":"2370849","Model":"MC3224dwe","Product":"MC3224 3yr OSR  NBD","Yield":"","AA":"2370849MC3224dwe","RR":"NO"},
    {"np":"2371433","Model":"CS431dw","Product":"CS431 1yr OSR  NBD","Yield":"","AA":"2371433CS431dw","RR":"NO"},
    {"np":"2371434","Model":"CS431dw","Product":"CS431 2yr OSR  NBD","Yield":"","AA":"2371434CS431dw","RR":"NO"},
    {"np":"2371435","Model":"CS431dw","Product":"CS431 3yr OSR  NBD","Yield":"","AA":"2371435CS431dw","RR":"NO"},
    {"np":"2371561","Model":"CX431adw","Product":"CX431 1yr OSR  NBD","Yield":"","AA":"2371561CX431adw","RR":"NO"},
    {"np":"2371562","Model":"CX431adw","Product":"CX431 2yr OSR  NBD","Yield":"","AA":"2371562CX431adw","RR":"NO"},
    {"np":"2371563","Model":"CX431adw","Product":"CX431 3yr OSR  NBD","Yield":"","AA":"2371563CX431adw","RR":"NO"},
    {"np":"2371703","Model":"MS331dn","Product":"MS331 1yr OSR  NBD","Yield":"","AA":"2371703MS331dn","RR":"NO"},
    {"np":"2371704","Model":"MS331dn","Product":"MS331 2yr OSR  NBD","Yield":"","AA":"2371704MS331dn","RR":"NO"},
    {"np":"2371705","Model":"MS331dn","Product":"MS331 3yr OSR  NBD","Yield":"","AA":"2371705MS331dn","RR":"NO"},
    {"np":"2371851","Model":"MS431dn","Product":"MS431 1yr OSR  NBD","Yield":"","AA":"2371851MS431dn","RR":"NO"},
    {"np":"2371851","Model":"MS431dw","Product":"MS431 1yr OSR  NBD","Yield":"","AA":"2371851MS431dw","RR":"NO"},
    {"np":"2371852","Model":"MS431dn","Product":"MS431 2yr OSR  NBD","Yield":"","AA":"2371852MS431dn","RR":"NO"},
    {"np":"2371852","Model":"MS431dw","Product":"MS431 2yr OSR  NBD","Yield":"","AA":"2371852MS431dw","RR":"NO"},
    {"np":"2371853","Model":"MS431dn","Product":"MS431 3yr OSR  NBD","Yield":"","AA":"2371853MS431dn","RR":"NO"},
    {"np":"2371853","Model":"MS431dw","Product":"MS431 3yr OSR  NBD","Yield":"","AA":"2371853MS431dw","RR":"NO"},
    {"np":"2371985","Model":"MX331adn","Product":"MX331 1yr OSR  NBD","Yield":"","AA":"2371985MX331adn","RR":"NO"},
    {"np":"2371986","Model":"MX331adn","Product":"MX331 2yr OSR  NBD","Yield":"","AA":"2371986MX331adn","RR":"NO"},
    {"np":"2371987","Model":"MX331adn","Product":"MX331 3yr OSR  NBD","Yield":"","AA":"2371987MX331adn","RR":"NO"},
    {"np":"2372077","Model":"MX431adn","Product":"MX431 1yr OSR  NBD","Yield":"","AA":"2372077MX431adn","RR":"NO"},
    {"np":"2372077","Model":"MX431adw","Product":"MX431 1yr OSR  NBD","Yield":"","AA":"2372077MX431adw","RR":"NO"},
    {"np":"2372078","Model":"MX431adn","Product":"MX431 2yr OSR  NBD","Yield":"","AA":"2372078MX431adn","RR":"NO"},
    {"np":"2372078","Model":"MX431adw","Product":"MX431 2yr OSR  NBD","Yield":"","AA":"2372078MX431adw","RR":"NO"},
    {"np":"2372079","Model":"MX431adn","Product":"MX431 3yr OSR  NBD","Yield":"","AA":"2372079MX431adn","RR":"NO"},
    {"np":"2372079","Model":"MX431adw","Product":"MX431 3yr OSR  NBD","Yield":"","AA":"2372079MX431adw","RR":"NO"},
    {"np":"24018SL","Model":"E23/33/24/34x","Product":"E230, E232, E234, E33x, E240, E340, E342 Return Program Toner Cartridge","Yield":"2500","AA":"24018SLE23/33/24/34x","RR":"NO"},
    {"np":"24038SL","Model":"E23/33/24/34x","Product":"E230, E232, E234, E33x, E240, E340, E342 Toner Cartridge","Yield":"2500","AA":"24038SLE23/33/24/34x","RR":"NO"},
    {"np":"24B6233�","Model":"MS610dn","Product":"5K -MICR Toner Cartridge, �Return, LAD labeled�","Yield":"5000","AA":"24B6233� MS610dn","RR":"NO"}
    ,
    {"np":"24B6234�","Model":"MS610dn","Product":"12K -MICR Toner Cartridge, Return, LAD labeled�","Yield":"12000","AA":"24B6234� MS610dn","RR":"NO"}
    ,
    {"np":"24B6235�","Model":"MS810dn","Product":"8K -MICR Toner Cartridge, Return, LAD labeled�","Yield":"8000","AA":"24B6235� MS810dn","RR":"NO"}
    ,
    {"np":"24B6236�","Model":"MS810dn","Product":"17K -MICR Toner Cartridge, Return, LAD labeled�","Yield":"17000","AA":"24B6236� MS810dn","RR":"NO"}
    ,
    {"np":"24B6241�","Model":"MS610dn","Product":"40K -MICR Imaging Unit, Return, LAD labeled�","Yield":"40000","AA":"24B6241� MS610dn","RR":"NO"}
    ,
    {"np":"24B6242�","Model":"MS810dn","Product":"70K -MICR Imaging Unit, Return, LAD labeled�","Yield":"70000","AA":"24B6242� MS810dn","RR":"NO"}
    ,
    {"np":"24T0128","Model":"MX711dhe","Product":"70 ppm, 1.2GB, 800MHz, DC 300K, e-Task (Fuser-A4)","Yield":"","AA":"24T0128MX711dhe","RR":"NO"},
    {"np":"24T7300","Model":"MX810dfe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX810dfe","RR":"NO"},
    {"np":"24T7300","Model":"MX810dme","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX810dme","RR":"NO"},
    {"np":"24T7300","Model":"MX810dpe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX810dpe","RR":"NO"},
    {"np":"24T7300","Model":"MX811dfe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX811dfe","RR":"NO"},
    {"np":"24T7300","Model":"MX811dme","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX811dme","RR":"NO"},
    {"np":"24T7300","Model":"MX811dpe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX811dpe","RR":"NO"},
    {"np":"24T7300","Model":"MX812dfe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX812dfe","RR":"NO"},
    {"np":"24T7300","Model":"MX812dme","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX812dme","RR":"NO"},
    {"np":"24T7300","Model":"MX812dpe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"24T7300MX812dpe","RR":"NO"},
    {"np":"24T7310","Model":"MX710dhe","Product":"63 ppm, 512MB, 800MHz, DC 275K, e-Task","Yield":"","AA":"24T7310MX710dhe","RR":"NO"},
    {"np":"24T7320","Model":"MX711dhe","Product":"70 ppm, 1.2GB, 800MHz, DC 300K, e-Task","Yield":"","AA":"24T7320MX711dhe","RR":"NO"},
    {"np":"24T7350","Model":"MX810dfe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX810dfe","RR":"NO"},
    {"np":"24T7350","Model":"MX810dme","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX810dme","RR":"NO"},
    {"np":"24T7350","Model":"MX810dpe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX810dpe","RR":"NO"},
    {"np":"24T7350","Model":"MX811dfe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX811dfe","RR":"NO"},
    {"np":"24T7350","Model":"MX811dme","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX811dme","RR":"NO"},
    {"np":"24T7350","Model":"MX811dpe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX811dpe","RR":"NO"},
    {"np":"24T7350","Model":"MX812dfe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX812dfe","RR":"NO"},
    {"np":"24T7350","Model":"MX812dme","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX812dme","RR":"NO"},
    {"np":"24T7350","Model":"MX812dpe","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"24T7350MX812dpe","RR":"NO"},
    {"np":"24T7351","Model":"MX710dhe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX710dhe","RR":"NO"},
    {"np":"24T7351","Model":"MX711dhe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX711dhe","RR":"NO"},
    {"np":"24T7351","Model":"MX810dfe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX810dfe","RR":"NO"},
    {"np":"24T7351","Model":"MX810dme","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX810dme","RR":"NO"},
    {"np":"24T7351","Model":"MX810dpe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX810dpe","RR":"NO"},
    {"np":"24T7351","Model":"MX811dfe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX811dfe","RR":"NO"},
    {"np":"24T7351","Model":"MX811dme","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX811dme","RR":"NO"},
    {"np":"24T7351","Model":"MX811dpe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX811dpe","RR":"NO"},
    {"np":"24T7351","Model":"MX812dfe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX812dfe","RR":"NO"},
    {"np":"24T7351","Model":"MX812dme","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX812dme","RR":"NO"},
    {"np":"24T7351","Model":"MX812dpe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24T7351MX812dpe","RR":"NO"},
    {"np":"24T7352","Model":"MX710dhe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX710dhe","RR":"NO"},
    {"np":"24T7352","Model":"MX711dhe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX711dhe","RR":"NO"},
    {"np":"24T7352","Model":"MX810dfe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX810dfe","RR":"NO"},
    {"np":"24T7352","Model":"MX810dme","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX810dme","RR":"NO"},
    {"np":"24T7352","Model":"MX810dpe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX810dpe","RR":"NO"},
    {"np":"24T7352","Model":"MX811dfe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX811dfe","RR":"NO"},
    {"np":"24T7352","Model":"MX811dme","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX811dme","RR":"NO"},
    {"np":"24T7352","Model":"MX811dpe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX811dpe","RR":"NO"},
    {"np":"24T7352","Model":"MX812dfe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX812dfe","RR":"NO"},
    {"np":"24T7352","Model":"MX812dme","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX812dme","RR":"NO"},
    {"np":"24T7352","Model":"MX812dpe","Product":"Lexmark Card for IPDS","Yield":"","AA":"24T7352MX812dpe","RR":"NO"},
    {"np":"24T7353","Model":"MX710dhe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX710dhe","RR":"NO"},
    {"np":"24T7353","Model":"MX711dhe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX711dhe","RR":"NO"},
    {"np":"24T7353","Model":"MX810dfe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX810dfe","RR":"NO"},
    {"np":"24T7353","Model":"MX810dme","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX810dme","RR":"NO"},
    {"np":"24T7353","Model":"MX810dpe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX810dpe","RR":"NO"},
    {"np":"24T7353","Model":"MX811dfe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX811dfe","RR":"NO"},
    {"np":"24T7353","Model":"MX811dme","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX811dme","RR":"NO"},
    {"np":"24T7353","Model":"MX811dpe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX811dpe","RR":"NO"},
    {"np":"24T7353","Model":"MX812dfe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX812dfe","RR":"NO"},
    {"np":"24T7353","Model":"MX812dme","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX812dme","RR":"NO"},
    {"np":"24T7353","Model":"MX812dpe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"24T7353MX812dpe","RR":"NO"},
    {"np":"24T7408","Model":"MX810dfe","Product":"55 ppm, 1.2GB, 800MHz, DC 300K, Finisher","Yield":"","AA":"24T7408MX810dfe","RR":"NO"},
    {"np":"24T7409","Model":"MX810dpe","Product":"55 ppm, 1.2GB, 800MHz, DC 300K, Staple & Hole Punch Finisher","Yield":"","AA":"24T7409MX810dpe","RR":"NO"},
    {"np":"24T7410","Model":"MX810dme","Product":"55 ppm, 1.2GB, 800MHz, DC 300K, Mailbox","Yield":"","AA":"24T7410MX810dme","RR":"NO"},
    {"np":"24T7420","Model":"MX811dfe","Product":"63 ppm, 1.2GB, 800MHz, DC 300K, Finisher","Yield":"","AA":"24T7420MX811dfe","RR":"NO"},
    {"np":"24T7421","Model":"MX811dpe","Product":"63 ppm, 1.2GB, 800MHz, DC 300K, Staple & Hole Punch Finisher","Yield":"","AA":"24T7421MX811dpe","RR":"NO"},
    {"np":"24T7422","Model":"MX811dme","Product":"63 ppm, 1.2GB, 800MHz, DC 300K, Mailbox","Yield":"","AA":"24T7422MX811dme","RR":"NO"},
    {"np":"24T7432","Model":"MX812dfe","Product":"70 ppm, 1.2GB, 800MHz, DC 300K, Finisher","Yield":"","AA":"24T7432MX812dfe","RR":"NO"},
    {"np":"24T7433","Model":"MX812dpe","Product":"70 ppm, 1.2GB, 800MHz, DC 300K, Staple & Hole Punch Finisher","Yield":"","AA":"24T7433MX812dpe","RR":"NO"},
    {"np":"24T7434","Model":"MX812dme","Product":"70 ppm, 1.2GB, 800MHz, DC 300K, Mailbox","Yield":"","AA":"24T7434MX812dme","RR":"NO"},
    {"np":"24Z0000","Model":"C925de","Product":"30/30 ppm, 256MB, 800MHz, MDC 200K, A3","Yield":"","AA":"24Z0000C925de","RR":"NO"},
    {"np":"24Z0030","Model":"C925de","Product":"550-Sheet Drawer","Yield":"","AA":"24Z0030C925de","RR":"NO"},
    {"np":"24Z0030","Model":"X925de","Product":"550-Sheet Drawer","Yield":"","AA":"24Z0030X925de","RR":"NO"},
    {"np":"24Z0031","Model":"C925de","Product":"Cabinet with casters","Yield":"","AA":"24Z0031C925de","RR":"NO"},
    {"np":"24Z0031","Model":"X925de","Product":"Cabinet with casters","Yield":"","AA":"24Z0031X925de","RR":"NO"},
    {"np":"24Z0038","Model":"C925de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24Z0038C925de","RR":"NO"},
    {"np":"24Z0039","Model":"C925de","Product":"Lexmark Card for IPDS","Yield":"","AA":"24Z0039C925de","RR":"NO"},
    {"np":"24Z0040","Model":"C925de","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"24Z0040C925de","RR":"NO"},
    {"np":"24Z0041","Model":"X925de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"24Z0041X925de","RR":"NO"},
    {"np":"24Z0042","Model":"X925de","Product":"Lexmark Card for IPDS","Yield":"","AA":"24Z0042X925de","RR":"NO"},
    {"np":"24Z0043","Model":"X925de","Product":"Lexmark Card for PRESCRIBE Emulation","Yield":"","AA":"24Z0043X925de","RR":"NO"},
    {"np":"24Z0061","Model":"C925de","Product":"Lexmark MarkNet N8130 Fiber Ethernet 100BaseFX, 10BaseFL Print Server","Yield":"","AA":"24Z0061C925de","RR":"NO"},
    {"np":"24Z0062","Model":"C925de","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"24Z0062C925de","RR":"NO"},
    {"np":"24Z0064","Model":"C925de","Product":"Lexamark RS-232C Serial Interface Card","Yield":"","AA":"24Z0064C925de","RR":"NO"},
    {"np":"24Z0065","Model":"C925de","Product":"Lexmark Parallel 1284-B Interface Card","Yield":"","AA":"24Z0065C925de","RR":"NO"},
    {"np":"24Z0653","Model":"X925de","Product":"30/30 ppm, 512MB, 900MHz, MDC 200K, A3","Yield":"","AA":"24Z0653X925de","RR":"NO"},
    {"np":"25A0013","Model":"C950de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013C950de","RR":"NO"},
    {"np":"25A0013","Model":"CS820de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CS820de","RR":"NO"},
    {"np":"25A0013","Model":"CS921de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CS921de","RR":"NO"},
    {"np":"25A0013","Model":"CS923de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CS923de","RR":"NO"},
    {"np":"25A0013","Model":"CX820de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX820de","RR":"NO"},
    {"np":"25A0013","Model":"CX825de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX825de","RR":"NO"},
    {"np":"25A0013","Model":"CX860de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX860de","RR":"NO"},
    {"np":"25A0013","Model":"CX921de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX921de","RR":"NO"},
    {"np":"25A0013","Model":"CX922de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX922de","RR":"NO"},
    {"np":"25A0013","Model":"CX923dte","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013CX923dte","RR":"NO"},
    {"np":"25A0013","Model":"MS810de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS810de","RR":"NO"},
    {"np":"25A0013","Model":"MS810dn","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS810dn","RR":"NO"},
    {"np":"25A0013","Model":"MS811dn","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS811dn","RR":"NO"},
    {"np":"25A0013","Model":"MS812de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS812de","RR":"NO"},
    {"np":"25A0013","Model":"MS812dn","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS812dn","RR":"NO"},
    {"np":"25A0013","Model":"MS821dn","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS821dn","RR":"NO"},
    {"np":"25A0013","Model":"MS823dn","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS823dn","RR":"NO"},
    {"np":"25A0013","Model":"MS826de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS826de","RR":"NO"},
    {"np":"25A0013","Model":"MS911de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MS911de","RR":"NO"},
    {"np":"25A0013","Model":"MX810dfe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX810dfe","RR":"NO"},
    {"np":"25A0013","Model":"MX810dme","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX810dme","RR":"NO"},
    {"np":"25A0013","Model":"MX810dpe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX810dpe","RR":"NO"},
    {"np":"25A0013","Model":"MX811dfe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX811dfe","RR":"NO"},
    {"np":"25A0013","Model":"MX811dme","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX811dme","RR":"NO"},
    {"np":"25A0013","Model":"MX811dpe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX811dpe","RR":"NO"},
    {"np":"25A0013","Model":"MX812dfe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX812dfe","RR":"NO"},
    {"np":"25A0013","Model":"MX812dme","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX812dme","RR":"NO"},
    {"np":"25A0013","Model":"MX812dpe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX812dpe","RR":"NO"},
    {"np":"25A0013","Model":"MX822ade","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX822ade","RR":"NO"},
    {"np":"25A0013","Model":"MX826ade","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX826ade","RR":"NO"},
    {"np":"25A0013","Model":"MX910de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX910de","RR":"NO"},
    {"np":"25A0013","Model":"MX911dte","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX911dte","RR":"NO"},
    {"np":"25A0013","Model":"MX912dxe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013MX912dxe","RR":"NO"},
    {"np":"25A0013","Model":"X950de","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013X950de","RR":"NO"},
    {"np":"25A0013","Model":"X952dte","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013X952dte","RR":"NO"},
    {"np":"25A0013","Model":"X954dhe","Product":"5K -Staple Cartridges (3 pack)","Yield":"15000","AA":"25A0013X954dhe","RR":"NO"},
    {"np":"25B0001","Model":"MX722adhe","Product":"L�ser, 70ppm, Pantalla t�ctil color 7 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 350.000p�gs, Recomendado de 10.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador quad core  de 1.2Ghz, Memoria de 2048 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 550 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"25B0001MX722adhe","RR":"NO"}
    ,
    {"np":"25B0610","Model":"MX826ade","Product":"70/66 ppm, 1024 MB, 1 GHz, 10\" color touch screen & numpad, DC 350K","Yield":"","AA":"25B0610MX826ade","RR":"NO"},
    {"np":"25B2000","Model":"MX822ade","Product":"55/61 ppm, 1024 MB, 1 GHz, 10\" color touch screen & numpad, DC 300K","Yield":"","AA":"25B2000MX822ade","RR":"NO"},
    {"np":"25B2900","Model":"MX822ade","Product":"550- Sheet Tray","Yield":"","AA":"25B2900MX822ade","RR":"NO"},
    {"np":"25B2900","Model":"MX826ade","Product":"550- Sheet Tray","Yield":"","AA":"25B2900MX826ade","RR":"NO"},
    {"np":"25B2950","Model":"MX822ade","Product":"2100-Sheet Tray","Yield":"","AA":"25B2950MX822ade","RR":"NO"},
    {"np":"25B2950","Model":"MX826ade","Product":"2100-Sheet Tray","Yield":"","AA":"25B2950MX826ade","RR":"NO"},
    {"np":"25B2999","Model":"MX822ade","Product":"Offset Stacker","Yield":"","AA":"25B2999MX822ade","RR":"NO"},
    {"np":"25B2999","Model":"MX826ade","Product":"Offset Stacker","Yield":"","AA":"25B2999MX826ade","RR":"NO"},
    {"np":"26Z0000","Model":"MS911de","Product":"L�ser, 55ppm, Pantalla t�ctil color de 4,3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 300.000p�gs, Recomendado de 59.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, USB 2.0, Inalambrica (opcional), Procesador dual core  de 800Mhz, Memoria de 512 MB. Gramaje admitido de 60 a 256 g/m�. Bandeja multiprop�sito de 150 hojas, bandeja x2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"26Z0000MS911de","RR":"NO"}
    ,
    {"np":"26Z0023","Model":"MS911de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"26Z0023MS911de","RR":"NO"},
    {"np":"26Z0024","Model":"MS911de","Product":"Lexmark Card for IPDS","Yield":"","AA":"26Z0024MS911de","RR":"NO"},
    {"np":"26Z0025","Model":"MS911de","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"26Z0025MS911de","RR":"NO"},
    {"np":"26Z0080","Model":"CS921de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080CS921de","RR":"NO"},
    {"np":"26Z0080","Model":"CS923de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080CS923de","RR":"NO"},
    {"np":"26Z0080","Model":"CX921de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080CX921de","RR":"NO"},
    {"np":"26Z0080","Model":"CX922de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080CX922de","RR":"NO"},
    {"np":"26Z0080","Model":"CX923dte","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080CX923dte","RR":"NO"},
    {"np":"26Z0080","Model":"MS911de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080MS911de","RR":"NO"},
    {"np":"26Z0080","Model":"MX910de","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080MX910de","RR":"NO"},
    {"np":"26Z0080","Model":"MX911dte","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080MX911dte","RR":"NO"},
    {"np":"26Z0080","Model":"MX912dxe","Product":"Staple, 2/4 Hole Punch Finisher","Yield":"","AA":"26Z0080MX912dxe","RR":"NO"},
    {"np":"26Z0081","Model":"CS921de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081CS921de","RR":"NO"},
    {"np":"26Z0081","Model":"CS923de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081CS923de","RR":"NO"},
    {"np":"26Z0081","Model":"CX921de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081CX921de","RR":"NO"},
    {"np":"26Z0081","Model":"CX922de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081CX922de","RR":"NO"},
    {"np":"26Z0081","Model":"CX923dte","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081CX923dte","RR":"NO"},
    {"np":"26Z0081","Model":"MS911de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081MS911de","RR":"NO"},
    {"np":"26Z0081","Model":"MX910de","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081MX910de","RR":"NO"},
    {"np":"26Z0081","Model":"MX911dte","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081MX911dte","RR":"NO"},
    {"np":"26Z0081","Model":"MX912dxe","Product":"Staple, 2/3 Hole Punch Finisher","Yield":"","AA":"26Z0081MX912dxe","RR":"NO"},
    {"np":"26Z0082","Model":"CS921de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082CS921de","RR":"NO"},
    {"np":"26Z0082","Model":"CS923de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082CS923de","RR":"NO"},
    {"np":"26Z0082","Model":"CX921de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082CX921de","RR":"NO"},
    {"np":"26Z0082","Model":"CX922de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082CX922de","RR":"NO"},
    {"np":"26Z0082","Model":"CX923dte","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082CX923dte","RR":"NO"},
    {"np":"26Z0082","Model":"MS911de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082MS911de","RR":"NO"},
    {"np":"26Z0082","Model":"MX910de","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082MX910de","RR":"NO"},
    {"np":"26Z0082","Model":"MX911dte","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082MX911dte","RR":"NO"},
    {"np":"26Z0082","Model":"MX912dxe","Product":"2/4 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0082MX912dxe","RR":"NO"},
    {"np":"26Z0083","Model":"CS921de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083CS921de","RR":"NO"},
    {"np":"26Z0083","Model":"CS923de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083CS923de","RR":"NO"},
    {"np":"26Z0083","Model":"CX921de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083CX921de","RR":"NO"},
    {"np":"26Z0083","Model":"CX922de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083CX922de","RR":"NO"},
    {"np":"26Z0083","Model":"CX923dte","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083CX923dte","RR":"NO"},
    {"np":"26Z0083","Model":"MS911de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083MS911de","RR":"NO"},
    {"np":"26Z0083","Model":"MX910de","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083MX910de","RR":"NO"},
    {"np":"26Z0083","Model":"MX911dte","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083MX911dte","RR":"NO"},
    {"np":"26Z0083","Model":"MX912dxe","Product":"2/3 Hole Punch Booklet Finisher","Yield":"","AA":"26Z0083MX912dxe","RR":"NO"},
    {"np":"26Z0084","Model":"CS921de","Product":"Staple Finisher","Yield":"","AA":"26Z0084CS921de","RR":"NO"},
    {"np":"26Z0084","Model":"CS923de","Product":"Staple Finisher","Yield":"","AA":"26Z0084CS923de","RR":"NO"},
    {"np":"26Z0084","Model":"CX921de","Product":"Staple Finisher","Yield":"","AA":"26Z0084CX921de","RR":"NO"},
    {"np":"26Z0084","Model":"CX922de","Product":"Staple Finisher","Yield":"","AA":"26Z0084CX922de","RR":"NO"},
    {"np":"26Z0084","Model":"CX923dte","Product":"Staple Finisher","Yield":"","AA":"26Z0084CX923dte","RR":"NO"},
    {"np":"26Z0084","Model":"MS911de","Product":"Staple Finisher","Yield":"","AA":"26Z0084MS911de","RR":"NO"},
    {"np":"26Z0084","Model":"MX910de","Product":"Staple Finisher","Yield":"","AA":"26Z0084MX910de","RR":"NO"},
    {"np":"26Z0084","Model":"MX911dte","Product":"Staple Finisher","Yield":"","AA":"26Z0084MX911dte","RR":"NO"},
    {"np":"26Z0084","Model":"MX912dxe","Product":"Staple Finisher","Yield":"","AA":"26Z0084MX912dxe","RR":"NO"},
    {"np":"26Z0085","Model":"MS911de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"26Z0085MS911de","RR":"NO"},
    {"np":"26Z0085","Model":"MX910de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"26Z0085MX910de","RR":"NO"},
    {"np":"26Z0085","Model":"MX911dte","Product":"2 x 500-Sheet Tray","Yield":"","AA":"26Z0085MX911dte","RR":"NO"},
    {"np":"26Z0085","Model":"MX912dxe","Product":"2 x 500-Sheet Tray","Yield":"","AA":"26Z0085MX912dxe","RR":"NO"},
    {"np":"26Z0086","Model":"MS911de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"26Z0086MS911de","RR":"NO"},
    {"np":"26Z0086","Model":"MX910de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"26Z0086MX910de","RR":"NO"},
    {"np":"26Z0086","Model":"MX911dte","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"26Z0086MX911dte","RR":"NO"},
    {"np":"26Z0086","Model":"MX912dxe","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"26Z0086MX912dxe","RR":"NO"},
    {"np":"26Z0087","Model":"MS911de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"26Z0087MS911de","RR":"NO"},
    {"np":"26Z0087","Model":"MX910de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"26Z0087MX910de","RR":"NO"},
    {"np":"26Z0087","Model":"MX911dte","Product":"2500-Sheet Tray - A4","Yield":"","AA":"26Z0087MX911dte","RR":"NO"},
    {"np":"26Z0087","Model":"MX912dxe","Product":"2500-Sheet Tray - A4","Yield":"","AA":"26Z0087MX912dxe","RR":"NO"},
    {"np":"26Z0088","Model":"CS921de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088CS921de","RR":"NO"},
    {"np":"26Z0088","Model":"CS923de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088CS923de","RR":"NO"},
    {"np":"26Z0088","Model":"CX921de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088CX921de","RR":"NO"},
    {"np":"26Z0088","Model":"CX922de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088CX922de","RR":"NO"},
    {"np":"26Z0088","Model":"CX923dte","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088CX923dte","RR":"NO"},
    {"np":"26Z0088","Model":"MS911de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088MS911de","RR":"NO"},
    {"np":"26Z0088","Model":"MX910de","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088MX910de","RR":"NO"},
    {"np":"26Z0088","Model":"MX911dte","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088MX911dte","RR":"NO"},
    {"np":"26Z0088","Model":"MX912dxe","Product":"3000-Sheet Tray - Letter","Yield":"","AA":"26Z0088MX912dxe","RR":"NO"},
    {"np":"26Z0089","Model":"CS921de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089CS921de","RR":"NO"},
    {"np":"26Z0089","Model":"CS923de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089CS923de","RR":"NO"},
    {"np":"26Z0089","Model":"CX921de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089CX921de","RR":"NO"},
    {"np":"26Z0089","Model":"CX922de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089CX922de","RR":"NO"},
    {"np":"26Z0089","Model":"CX923dte","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089CX923dte","RR":"NO"},
    {"np":"26Z0089","Model":"MS911de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089MS911de","RR":"NO"},
    {"np":"26Z0089","Model":"MX910de","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089MX910de","RR":"NO"},
    {"np":"26Z0089","Model":"MX911dte","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089MX911dte","RR":"NO"},
    {"np":"26Z0089","Model":"MX912dxe","Product":"3000-Sheet Tray - A4","Yield":"","AA":"26Z0089MX912dxe","RR":"NO"},
    {"np":"26Z0090","Model":"CX921de","Product":"Working Shelf","Yield":"","AA":"26Z0090CX921de","RR":"NO"},
    {"np":"26Z0090","Model":"CX922de","Product":"Working Shelf","Yield":"","AA":"26Z0090CX922de","RR":"NO"},
    {"np":"26Z0090","Model":"CX923dte","Product":"Working Shelf","Yield":"","AA":"26Z0090CX923dte","RR":"NO"},
    {"np":"26Z0090","Model":"MX910de","Product":"Working Shelf","Yield":"","AA":"26Z0090MX910de","RR":"NO"},
    {"np":"26Z0090","Model":"MX911dte","Product":"Working Shelf","Yield":"","AA":"26Z0090MX911dte","RR":"NO"},
    {"np":"26Z0090","Model":"MX912dxe","Product":"Working Shelf","Yield":"","AA":"26Z0090MX912dxe","RR":"NO"},
    {"np":"26Z0091","Model":"CS921de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091CS921de","RR":"NO"},
    {"np":"26Z0091","Model":"CS923de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091CS923de","RR":"NO"},
    {"np":"26Z0091","Model":"CX921de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091CX921de","RR":"NO"},
    {"np":"26Z0091","Model":"CX922de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091CX922de","RR":"NO"},
    {"np":"26Z0091","Model":"CX923dte","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091CX923dte","RR":"NO"},
    {"np":"26Z0091","Model":"MS911de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091MS911de","RR":"NO"},
    {"np":"26Z0091","Model":"MX910de","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091MX910de","RR":"NO"},
    {"np":"26Z0091","Model":"MX911dte","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091MX911dte","RR":"NO"},
    {"np":"26Z0091","Model":"MX912dxe","Product":"10-Sheet Banner Tray","Yield":"","AA":"26Z0091MX912dxe","RR":"NO"},
    {"np":"26Z0094","Model":"MS911de","Product":"Cabinet with Casters","Yield":"","AA":"26Z0094MS911de","RR":"NO"},
    {"np":"26Z0094","Model":"MX910de","Product":"Cabinet with Casters","Yield":"","AA":"26Z0094MX910de","RR":"NO"},
    {"np":"26Z0094","Model":"MX911dte","Product":"Cabinet with Casters","Yield":"","AA":"26Z0094MX911dte","RR":"NO"},
    {"np":"26Z0094","Model":"MX912dxe","Product":"Cabinet with Casters","Yield":"","AA":"26Z0094MX912dxe","RR":"NO"},
    {"np":"26Z0100","Model":"MX910de","Product":"L�ser, 45ppm, Pantalla t�ctil color 10 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 200.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador dual core  de 800 Mhz, Memoria de 1024 MB. Gramaje admitido de 60 a 256 g/m�. Bandeja multiprop�sito de 150 hojas, bandeja x2 de 500 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"26Z0100MX910de","RR":"NO"}
    ,
    {"np":"26Z0101","Model":"MX911dte","Product":"L�ser, 55ppm, Pantalla t�ctil color 10 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 300.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador dual core  de 800 Mhz, Memoria de 1024 MB. Gramaje admitido de 60 a 256 g/m�. Bandeja multiprop�sito de 150 hojas, bandeja x4 de 500 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"26Z0101MX911dte","RR":"NO"}
    ,
    {"np":"26Z0102","Model":"MX912dxe","Product":"L�ser, 65ppm, Pantalla t�ctil color 10 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 300.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador dual core  de 800 Mhz, Memoria de 1024 MB. Gramaje admitido de 60 a 256 g/m�. Bandeja multiprop�sito de 150 hojas, bandeja x2 de 500 hojas, Bandeja Tadem 2.500 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"26Z0102MX912dxe","RR":"NO"}
    ,
    {"np":"26Z0195","Model":"MX910de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"26Z0195MX910de","RR":"NO"},
    {"np":"26Z0195","Model":"MX911dte","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"26Z0195MX911dte","RR":"NO"},
    {"np":"26Z0195","Model":"MX912dxe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"26Z0195MX912dxe","RR":"NO"},
    {"np":"26Z0196","Model":"MX910de","Product":"Lexmark Card for IPDS","Yield":"","AA":"26Z0196MX910de","RR":"NO"},
    {"np":"26Z0196","Model":"MX911dte","Product":"Lexmark Card for IPDS","Yield":"","AA":"26Z0196MX911dte","RR":"NO"},
    {"np":"26Z0196","Model":"MX912dxe","Product":"Lexmark Card for IPDS","Yield":"","AA":"26Z0196MX912dxe","RR":"NO"},
    {"np":"26Z0197","Model":"MX910de","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"26Z0197MX910de","RR":"NO"},
    {"np":"26Z0197","Model":"MX911dte","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"26Z0197MX911dte","RR":"NO"},
    {"np":"26Z0197","Model":"MX912dxe","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"26Z0197MX912dxe","RR":"NO"},
    {"np":"27X0014","Model":"C925de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0014C925de","RR":"NO"},
    {"np":"27X0014","Model":"C950de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0014C950de","RR":"NO"},
    {"np":"27X0125","Model":"CX310dn","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0125CX310dn","RR":"NO"},
    {"np":"27X0125","Model":"CX410de","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0125CX410de","RR":"NO"},
    {"np":"27X0125","Model":"CX510de","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0125CX510de","RR":"NO"},
    {"np":"27X0125","Model":"CX510dhe","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0125CX510dhe","RR":"NO"},
    {"np":"27X0128","Model":"MS312dn","Product":"Lexmark MarkNet N8352 802.11b/g/n Wireless Print Server Kit-V","Yield":"","AA":"27X0128MS312dn","RR":"NO"},
    {"np":"27X0128","Model":"MS315dn","Product":"Lexmark MarkNet N8352 802.11b/g/n Wireless Print Server Kit-V","Yield":"","AA":"27X0128MS315dn","RR":"NO"},
    {"np":"27X0128","Model":"MS415dn","Product":"Lexmark MarkNet N8352 802.11b/g/n Wireless Print Server Kit-V","Yield":"","AA":"27X0128MS415dn","RR":"NO"},
    {"np":"27X0128","Model":"MS610de","Product":"Lexmark MarkNet N8352 802.11b/g/n Wireless Print Server Kit-V","Yield":"","AA":"27X0128MS610de","RR":"NO"},
    {"np":"27X0128","Model":"MS610dn","Product":"Lexmark MarkNet N8352 802.11b/g/n Wireless Print Server Kit-V","Yield":"","AA":"27X0128MS610dn","RR":"NO"},
    {"np":"27X0129","Model":"MX310dn","Product":"Lexmark MarkNet 8352 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0129MX310dn","RR":"NO"},
    {"np":"27X0129","Model":"MX410de","Product":"Lexmark MarkNet 8352 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0129MX410de","RR":"NO"},
    {"np":"27X0130","Model":"CS310dn","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0130CS310dn","RR":"NO"},
    {"np":"27X0130","Model":"CS510de","Product":"Lexmark MarkNet 8352 802.11b/g/n Wireless Print Server Kit","Yield":"","AA":"27X0130CS510de","RR":"NO"},
    {"np":"27X0135","Model":"CS310dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CS310dn","RR":"NO"},
    {"np":"27X0135","Model":"CS510de","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CS510de","RR":"NO"},
    {"np":"27X0135","Model":"CX310dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CX310dn","RR":"NO"},
    {"np":"27X0135","Model":"CX410de","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CX410de","RR":"NO"},
    {"np":"27X0135","Model":"CX510de","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CX510de","RR":"NO"},
    {"np":"27X0135","Model":"CX510dhe","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135CX510dhe","RR":"NO"},
    {"np":"27X0135","Model":"MS312dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MS312dn","RR":"NO"},
    {"np":"27X0135","Model":"MS315dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MS315dn","RR":"NO"},
    {"np":"27X0135","Model":"MS415dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MS415dn","RR":"NO"},
    {"np":"27X0135","Model":"MS610de","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MS610de","RR":"NO"},
    {"np":"27X0135","Model":"MS610dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MS610dn","RR":"NO"},
    {"np":"27X0135","Model":"MX310dn","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MX310dn","RR":"NO"},
    {"np":"27X0135","Model":"MX410de","Product":"Lexmark  MarkNet N8352 Wireless plus NFC","Yield":"","AA":"27X0135MX410de","RR":"NO"},
    {"np":"27X0142","Model":"CS921de","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142CS921de","RR":"NO"}
    ,
    {"np":"27X0142","Model":"CS923de","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142CS923de","RR":"NO"}
    ,
    {"np":"27X0142","Model":"CX921de","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142CX921de","RR":"NO"}
    ,
    {"np":"27X0142","Model":"CX922de","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142CX922de","RR":"NO"}
    ,
    {"np":"27X0142","Model":"CX923dte","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142CX923dte","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MS821dn","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MS821dn","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MS823dn","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MS823dn","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MS826de","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MS826de","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MX722adhe","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MX722adhe","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MX822ade","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MX822ade","RR":"NO"}
    ,
    {"np":"27X0142","Model":"MX826ade","Product":"MarkNet N8230 Fiber Ethernet 100BASE-FX(LC), 1000BASE-SX(LC)�","Yield":"","AA":"27X0142MX826ade","RR":"NO"}
    ,
    {"np":"27X0200","Model":"CS510de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200CS510de","RR":"NO"},
    {"np":"27X0200","Model":"CX510de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200CX510de","RR":"NO"},
    {"np":"27X0200","Model":"MS810de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200MS810de","RR":"NO"},
    {"np":"27X0200","Model":"MS810dn","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200MS810dn","RR":"NO"},
    {"np":"27X0200","Model":"MS811dn","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200MS811dn","RR":"NO"},
    {"np":"27X0200","Model":"MS812de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200MS812de","RR":"NO"},
    {"np":"27X0200","Model":"MS812dn","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0200MS812dn","RR":"NO"},
    {"np":"27X0200","Model":"MS911de","Product":"Lexmark 320+ GB Hard Drive","Yield":"","AA":"27X0200MS911de","RR":"NO"},
    {"np":"27X0210","Model":"MS610de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0210MS610de","RR":"NO"},
    {"np":"27X0210","Model":"MX511de","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0210MX511de","RR":"NO"},
    {"np":"27X0210","Model":"MX611dfe","Product":"Lexmark 320+GB Hard Disk","Yield":"","AA":"27X0210MX611dfe","RR":"NO"},
    {"np":"27X0225","Model":"C950de","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225C950de","RR":"NO"},
    {"np":"27X0225","Model":"MS810de","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS810de","RR":"NO"},
    {"np":"27X0225","Model":"MS810dn","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS810dn","RR":"NO"},
    {"np":"27X0225","Model":"MS811dn","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS811dn","RR":"NO"},
    {"np":"27X0225","Model":"MS812de","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS812de","RR":"NO"},
    {"np":"27X0225","Model":"MS812dn","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS812dn","RR":"NO"},
    {"np":"27X0225","Model":"MS911de","Product":"Lexmark MarkNet N8350 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225MS911de","RR":"NO"},
    {"np":"27X0225","Model":"MX710dhe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX710dhe","RR":"NO"},
    {"np":"27X0225","Model":"MX711dhe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX711dhe","RR":"NO"},
    {"np":"27X0225","Model":"MX810dfe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX810dfe","RR":"NO"},
    {"np":"27X0225","Model":"MX810dme","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX810dme","RR":"NO"},
    {"np":"27X0225","Model":"MX810dpe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX810dpe","RR":"NO"},
    {"np":"27X0225","Model":"MX811dfe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX811dfe","RR":"NO"},
    {"np":"27X0225","Model":"MX811dme","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX811dme","RR":"NO"},
    {"np":"27X0225","Model":"MX811dpe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX811dpe","RR":"NO"},
    {"np":"27X0225","Model":"MX812dfe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX812dfe","RR":"NO"},
    {"np":"27X0225","Model":"MX812dme","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX812dme","RR":"NO"},
    {"np":"27X0225","Model":"MX812dpe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX812dpe","RR":"NO"},
    {"np":"27X0225","Model":"MX910de","Product":"Lexmark MarkNet N8350 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX910de","RR":"NO"},
    {"np":"27X0225","Model":"MX911dte","Product":"Lexmark MarkNet N8350 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX911dte","RR":"NO"},
    {"np":"27X0225","Model":"MX912dxe","Product":"Lexmark MarkNet N8350 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225MX912dxe","RR":"NO"},
    {"np":"27X0225","Model":"X925de","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225X925de","RR":"NO"},
    {"np":"27X0225","Model":"X950de","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225X950de","RR":"NO"},
    {"np":"27X0225","Model":"X952dte","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225X952dte","RR":"NO"},
    {"np":"27X0225","Model":"X954dhe","Product":"Lexmark MarkNet N8250 802.11b/g/n Wireless Print Server","Yield":"","AA":"27X0225X954dhe","RR":"NO"},
    {"np":"27X0309","Model":"CS820de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CS820de","RR":"NO"},
    {"np":"27X0309","Model":"CS921de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CS921de","RR":"NO"},
    {"np":"27X0309","Model":"CS923de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CS923de","RR":"NO"},
    {"np":"27X0309","Model":"CX820de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX820de","RR":"NO"},
    {"np":"27X0309","Model":"CX825de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX825de","RR":"NO"},
    {"np":"27X0309","Model":"CX860de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX860de","RR":"NO"},
    {"np":"27X0309","Model":"CX921de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX921de","RR":"NO"},
    {"np":"27X0309","Model":"CX922de","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX922de","RR":"NO"},
    {"np":"27X0309","Model":"CX923dte","Product":"Lexmark MarkNet 8360 802.11b/g/n/a Wireless Print Server plus Bluetooth, NFC, Dual Band","Yield":"","AA":"27X0309CX923dte","RR":"NO"},
    {"np":"27X0310","Model":"MS810de","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS810de","RR":"NO"},
    {"np":"27X0310","Model":"MS810dn","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS810dn","RR":"NO"},
    {"np":"27X0310","Model":"MS811dn","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS811dn","RR":"NO"},
    {"np":"27X0310","Model":"MS812de","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS812de","RR":"NO"},
    {"np":"27X0310","Model":"MS812dn","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS812dn","RR":"NO"},
    {"np":"27X0310","Model":"MS911de","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MS911de","RR":"NO"},
    {"np":"27X0310","Model":"MX710dhe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX710dhe","RR":"NO"},
    {"np":"27X0310","Model":"MX711dhe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX711dhe","RR":"NO"},
    {"np":"27X0310","Model":"MX810dfe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX810dfe","RR":"NO"},
    {"np":"27X0310","Model":"MX810dme","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX810dme","RR":"NO"},
    {"np":"27X0310","Model":"MX810dpe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX810dpe","RR":"NO"},
    {"np":"27X0310","Model":"MX811dfe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX811dfe","RR":"NO"},
    {"np":"27X0310","Model":"MX811dme","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX811dme","RR":"NO"},
    {"np":"27X0310","Model":"MX811dpe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX811dpe","RR":"NO"},
    {"np":"27X0310","Model":"MX812dfe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX812dfe","RR":"NO"},
    {"np":"27X0310","Model":"MX812dme","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX812dme","RR":"NO"},
    {"np":"27X0310","Model":"MX812dpe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX812dpe","RR":"NO"},
    {"np":"27X0310","Model":"MX910de","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX910de","RR":"NO"},
    {"np":"27X0310","Model":"MX911dte","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX911dte","RR":"NO"},
    {"np":"27X0310","Model":"MX912dxe","Product":"Lexmark MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0310MX912dxe","RR":"NO"},
    {"np":"27X0327","Model":"MX511de","Product":"MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0327MX511de","RR":"NO"},
    {"np":"27X0327","Model":"MX611dfe","Product":"MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0327MX611dfe","RR":"NO"},
    {"np":"27X0327","Model":"MX611dhe","Product":"MarkNet N8350 Wireless plus NFC","Yield":"","AA":"27X0327MX611dhe","RR":"NO"},
    {"np":"27X0400","Model":"CS720de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CS720de","RR":"NO"},
    {"np":"27X0400","Model":"CS725de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CS725de","RR":"NO"},
    {"np":"27X0400","Model":"CS820de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CS820de","RR":"NO"},
    {"np":"27X0400","Model":"CS921de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CS921de","RR":"NO"},
    {"np":"27X0400","Model":"CS923de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CS923de","RR":"NO"},
    {"np":"27X0400","Model":"CX522ade","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CX522ade","RR":"NO"},
    {"np":"27X0400","Model":"CX622ade","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CX622ade","RR":"NO"},
    {"np":"27X0400","Model":"CX725dhe","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400CX725dhe","RR":"NO"},
    {"np":"27X0400","Model":"MS821dn","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400MS821dn","RR":"NO"},
    {"np":"27X0400","Model":"MS823dn","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400MS823dn","RR":"NO"},
    {"np":"27X0400","Model":"MS826de","Product":"500+GB Hard Disk","Yield":"","AA":"27X0400MS826de","RR":"NO"},
    {"np":"27X0500","Model":"CS622de","Product":"500GB Hard Disk Drive (USB)","Yield":"","AA":"27X0500CS622de","RR":"NO"},
    {"np":"27X0500","Model":"MS622de","Product":"500GB Hard Disk Drive (USB)","Yield":"","AA":"27X0500MS622de","RR":"NO"},
    {"np":"27X0803","Model":"CS720de","Product":"MarkNet N8360 802.11 Wireless w/NFC","Yield":"","AA":"27X0803CS720de","RR":"NO"},
    {"np":"27X0803","Model":"CS725de","Product":"MarkNet N8360 802.11 Wireless w/NFC","Yield":"","AA":"27X0803CS725de","RR":"NO"},
    {"np":"27X0803","Model":"CX725dhe","Product":"MarkNet N8360 802.11 Wireless w/NFC","Yield":"","AA":"27X0803CX725dhe","RR":"NO"},
    {"np":"27X0900","Model":"MS610de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MS610de","RR":"NO"},
    {"np":"27X0900","Model":"MS622de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MS622de","RR":"NO"},
    {"np":"27X0900","Model":"MX511de","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX511de","RR":"NO"},
    {"np":"27X0900","Model":"MX521ade","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX521ade","RR":"NO"},
    {"np":"27X0900","Model":"MX522adhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX522adhe","RR":"NO"},
    {"np":"27X0900","Model":"MX611dfe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX611dfe","RR":"NO"},
    {"np":"27X0900","Model":"MX611dhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX611dhe","RR":"NO"},
    {"np":"27X0900","Model":"MX622adhe","Product":"RS-232C Serial Interface Card","Yield":"","AA":"27X0900MX622adhe","RR":"NO"},
    {"np":"27X0901","Model":"MS610de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MS610de","RR":"NO"},
    {"np":"27X0901","Model":"MS621dn","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MS621dn","RR":"NO"},
    {"np":"27X0901","Model":"MS622de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MS622de","RR":"NO"},
    {"np":"27X0901","Model":"MX511de","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX511de","RR":"NO"},
    {"np":"27X0901","Model":"MX521ade","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX521ade","RR":"NO"},
    {"np":"27X0901","Model":"MX522adhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX522adhe","RR":"NO"},
    {"np":"27X0901","Model":"MX611dfe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX611dfe","RR":"NO"},
    {"np":"27X0901","Model":"MX611dhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX611dhe","RR":"NO"},
    {"np":"27X0901","Model":"MX622adhe","Product":"Parallel 1284-B Interface Card","Yield":"","AA":"27X0901MX622adhe","RR":"NO"},
    {"np":"27X0903","Model":"MX511de","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server*","Yield":"","AA":"27X0903MX511de","RR":"NO"},
    {"np":"27X0903","Model":"MX611dfe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server*","Yield":"","AA":"27X0903MX611dfe","RR":"NO"},
    {"np":"27X0903","Model":"MX611dhe","Product":"Lexmark MarkNet 8350 802.11 b/g/n Wireless Print Server*","Yield":"","AA":"27X0903MX611dhe","RR":"NO"},
    {"np":"27X2000","Model":"MS521dn","Product":"Thin Parallel","Yield":"","AA":"27X2000MS521dn","RR":"NO"},
    {"np":"27X2000","Model":"MS621dn","Product":"Thin Parallel","Yield":"","AA":"27X2000MS621dn","RR":"NO"},
    {"np":"27X2000","Model":"MS622de","Product":"Thin Parallel","Yield":"","AA":"27X2000MS622de","RR":"NO"},
    {"np":"27X6410","Model":"CS421dn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CS421dn","RR":"NO"},
    {"np":"27X6410","Model":"CS521dn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CS521dn","RR":"NO"},
    {"np":"27X6410","Model":"CS622de","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CS622de","RR":"NO"},
    {"np":"27X6410","Model":"CX421adn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CX421adn","RR":"NO"},
    {"np":"27X6410","Model":"CX522ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CX522ade","RR":"NO"},
    {"np":"27X6410","Model":"CX622ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CX622ade","RR":"NO"},
    {"np":"27X6410","Model":"CX625adhe","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410CX625adhe","RR":"NO"},
    {"np":"27X6410","Model":"MS621dn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MS621dn","RR":"NO"},
    {"np":"27X6410","Model":"MS622de","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MS622de","RR":"NO"},
    {"np":"27X6410","Model":"MS821dn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MS821dn","RR":"NO"},
    {"np":"27X6410","Model":"MS823dn","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MS823dn","RR":"NO"},
    {"np":"27X6410","Model":"MS826de","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MS826de","RR":"NO"},
    {"np":"27X6410","Model":"MX421ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX421ade","RR":"NO"},
    {"np":"27X6410","Model":"MX521ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX521ade","RR":"NO"},
    {"np":"27X6410","Model":"MX522adhe","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX522adhe","RR":"NO"},
    {"np":"27X6410","Model":"MX622adhe","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX622adhe","RR":"NO"},
    {"np":"27X6410","Model":"MX722adhe","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX722adhe","RR":"NO"},
    {"np":"27X6410","Model":"MX822ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX822ade","RR":"NO"},
    {"np":"27X6410","Model":"MX826ade","Product":"Marknet N8372 802.11b/g/n (Front Wifi- FSM)","Yield":"","AA":"27X6410MX826ade","RR":"NO"},
    {"np":"27X6510","Model":"MS521dn","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS521dn","RR":"NO"},
    {"np":"27X6510","Model":"MS621dn","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS621dn","RR":"NO"},
    {"np":"27X6510","Model":"MS622de","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS622de","RR":"NO"},
    {"np":"27X6510","Model":"MS821dn","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS821dn","RR":"NO"},
    {"np":"27X6510","Model":"MS823dn","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS823dn","RR":"NO"},
    {"np":"27X6510","Model":"MS826de","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MS826de","RR":"NO"},
    {"np":"27X6510","Model":"MX722adhe","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MX722adhe","RR":"NO"},
    {"np":"27X6510","Model":"MX822ade","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MX822ade","RR":"NO"},
    {"np":"27X6510","Model":"MX826ade","Product":"Marknet N8370 802.11a/b/g/n/ac 2.4/5GHz Wireless (Rear Wifi)","Yield":"","AA":"27X6510MX826ade","RR":"NO"},
    {"np":"28C0050","Model":"CS310dn","Product":"25/25 ppm, 256MB, 800MHz, DC 60K","Yield":"","AA":"28C0050CS310dn","RR":"NO"},
    {"np":"28C0550","Model":"CX310dn","Product":"25/25 ppm, 512MB, 800MHz, DC 60K","Yield":"","AA":"28C0550CX310dn","RR":"NO"},
    {"np":"28D0550","Model":"CX410de","Product":"32/32 ppm, 512MB, 800MHz, DC 75K","Yield":"","AA":"28D0550CX410de","RR":"NO"},
    {"np":"28E0050","Model":"CS510de","Product":"32/32 ppm, 512MB, 800MHz, DC 85K","Yield":"","AA":"28E0050CS510de","RR":"NO"},
    {"np":"28E0500","Model":"CX510de","Product":"32/32 ppm, 1.2GB, 800MHz, DC 85K","Yield":"","AA":"28E0500CX510de","RR":"NO"},
    {"np":"28E0615","Model":"CX510dhe","Product":"32/32 ppm, 1.2GB, 800MHz, DC 85K,","Yield":"","AA":"28E0615CX510dhe","RR":"NO"},
    {"np":"29S0000","Model":"MS331dn Remplaza a: MS321dn","Product":"L�ser,  40ppm, Pantalla LCD de 2 l�neas, Ciclo de trabajo mensual m�ximo de hasta 50.000p�gs, Recomendado de 5.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Procesador dual core  de 1.0Ghz, Memoria de 256 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0000MS331dn Remplaza a: MS321dn","RR":"NO"}
    ,
    {"np":"29S0050","Model":"MS431dn Remplaza a: MS421dn","Product":"L�ser,  42ppm, Pantalla LCD de 2 l�neas, Ciclo de trabajo mensual m�ximo de hasta 80.000p�gs, Recomendado de 8.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Procesador dual core  de 1.0Ghz, Memoria de 256 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0050MS431dn Remplaza a: MS421dn","RR":"NO"}
    ,
    {"np":"29S0100","Model":"MS431dw !!NUEVO��","Product":"L�ser,  42ppm, Pantalla LCD de 2 l�neas, Ciclo de trabajo mensual m�ximo de hasta 80.000p�gs, Recomendado de 8.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI incluido, Procesador dual core  de 1.0Ghz, Memoria de 256 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0100MS431dw !!NUEVO��","RR":"NO"}
    ,
    {"np":"29S0150","Model":"MX331adn Remplaza a: MX321adn","Product":"L�ser,  40ppm, Pantalla LCD touch de 2.8 pulgadas, ADF (sigle faz), Ciclo de trabajo mensual m�ximo de hasta 50.000p�gs, Recomendado de 5.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrica (opcional), USB, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0150MX331adn Remplaza a: MX321adn","RR":"NO"}
    ,
    {"np":"29S0200","Model":"MX431adn Remplaza a: MX421adn","Product":"L�ser, 42ppm, Pantalla LCD touch de 2.8 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 80.000p�gs, Recomendado de 8.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrica (opcional), USB, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0200MX431adn Remplaza a: MX421adn","RR":"NO"}
    ,
    {"np":"29S0500","Model":"MX431adw !!NUEVO��","Product":"L�ser, 42ppm, Pantalla LCD touch de 2.8 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 80.000p�gs, Recomendado de 8.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI Incorporado, USB, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"29S0500MX431adw !!NUEVO��","RR":"NO"}
    ,
    {"np":"3070166","Model":"2580+","Product":"4M -Standard Yield Black Re-inking Ribbon","Yield":"4000000","AA":"30701662580+","RR":"NO"},
    {"np":"3070166","Model":"2580n+","Product":"4M -Standard Yield Black Re-inking Ribbon","Yield":"4000000","AA":"30701662580n+","RR":"NO"},
    {"np":"3070166","Model":"2581+","Product":"4M -Standard Yield Black Re-inking Ribbon","Yield":"4000000","AA":"30701662581+","RR":"NO"},
    {"np":"3070166","Model":"2581n+","Product":"4M -Standard Yield Black Re-inking Ribbon","Yield":"4000000","AA":"30701662581n+","RR":"NO"},
    {"np":"3070169","Model":"2580+","Product":"8M -High Yield Black Re-Inking Ribbon","Yield":"8000000","AA":"30701692580+","RR":"NO"},
    {"np":"3070169","Model":"2580n+","Product":"8M -High Yield Black Re-Inking Ribbon","Yield":"8000000","AA":"30701692580n+","RR":"NO"},
    {"np":"3070169","Model":"2581+","Product":"8M -High Yield Black Re-Inking Ribbon","Yield":"8000000","AA":"30701692581+","RR":"NO"},
    {"np":"3070169","Model":"2581n+","Product":"8M -High Yield Black Re-Inking Ribbon","Yield":"8000000","AA":"30701692581n+","RR":"NO"},
    {"np":"3073173","Model":"CS310dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173CS310dn","RR":"NO"},
    {"np":"3073173","Model":"CS421dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173CS421dn","RR":"NO"},
    {"np":"3073173","Model":"CS510de","Product":"Swivel Cabinet","Yield":"","AA":"3073173CS510de","RR":"NO"},
    {"np":"3073173","Model":"CS521dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173CS521dn","RR":"NO"},
    {"np":"3073173","Model":"CS622de","Product":"Swivel Cabinet","Yield":"","AA":"3073173CS622de","RR":"NO"},
    {"np":"3073173","Model":"CX310dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX310dn","RR":"NO"},
    {"np":"3073173","Model":"CX410de","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX410de","RR":"NO"},
    {"np":"3073173","Model":"CX421adn","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX421adn","RR":"NO"},
    {"np":"3073173","Model":"CX510de","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX510de","RR":"NO"},
    {"np":"3073173","Model":"CX510dhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX510dhe","RR":"NO"},
    {"np":"3073173","Model":"CX522ade","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX522ade","RR":"NO"},
    {"np":"3073173","Model":"CX622ade","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX622ade","RR":"NO"},
    {"np":"3073173","Model":"CX625adhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173CX625adhe","RR":"NO"},
    {"np":"3073173","Model":"MS321dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS321dn","RR":"NO"},
    {"np":"3073173","Model":"MS421dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS421dn","RR":"NO"},
    {"np":"3073173","Model":"MS521dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS521dn","RR":"NO"},
    {"np":"3073173","Model":"MS610de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS610de","RR":"NO"},
    {"np":"3073173","Model":"MS610dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS610dn","RR":"NO"},
    {"np":"3073173","Model":"MS621dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS621dn","RR":"NO"},
    {"np":"3073173","Model":"MS622de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS622de","RR":"NO"},
    {"np":"3073173","Model":"MS810de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS810de","RR":"NO"},
    {"np":"3073173","Model":"MS810dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS810dn","RR":"NO"},
    {"np":"3073173","Model":"MS811dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS811dn","RR":"NO"},
    {"np":"3073173","Model":"MS812de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS812de","RR":"NO"},
    {"np":"3073173","Model":"MS812dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS812dn","RR":"NO"},
    {"np":"3073173","Model":"MS821dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS821dn","RR":"NO"},
    {"np":"3073173","Model":"MS823dn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS823dn","RR":"NO"},
    {"np":"3073173","Model":"MS826de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MS826de","RR":"NO"},
    {"np":"3073173","Model":"MX321adn","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX321adn","RR":"NO"},
    {"np":"3073173","Model":"MX421ade","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX421ade","RR":"NO"},
    {"np":"3073173","Model":"MX511de","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX511de","RR":"NO"},
    {"np":"3073173","Model":"MX521ade","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX521ade","RR":"NO"},
    {"np":"3073173","Model":"MX522adhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX522adhe","RR":"NO"},
    {"np":"3073173","Model":"MX611dfe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX611dfe","RR":"NO"},
    {"np":"3073173","Model":"MX611dhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX611dhe","RR":"NO"},
    {"np":"3073173","Model":"MX622adhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX622adhe","RR":"NO"},
    {"np":"3073173","Model":"MX710dhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX710dhe","RR":"NO"},
    {"np":"3073173","Model":"MX711dhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX711dhe","RR":"NO"},
    {"np":"3073173","Model":"MX722adhe","Product":"Swivel Cabinet","Yield":"","AA":"3073173MX722adhe","RR":"NO"},
    {"np":"32C0000","Model":"CS921de","Product":"L�ser 35ppm, Pantalla t�ctil a color 4.3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 150.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, inalambrico (opcional), Procesador dual core de 1.2Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja x2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0000CS921de","RR":"NO"}
    ,
    {"np":"32C0001","Model":"CS923de","Product":"L�ser 55ppm, Pantalla t�ctil a color 4.3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 250.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, inalambrico (opcional), Procesador dual core de 1.2Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja x2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0001CS923de","RR":"NO"}
    ,
    {"np":"32C0050","Model":"CS921de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"32C0050CS921de","RR":"NO"},
    {"np":"32C0050","Model":"CS923de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"32C0050CS923de","RR":"NO"},
    {"np":"32C0050","Model":"CX921de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"32C0050CX921de","RR":"NO"},
    {"np":"32C0050","Model":"CX922de","Product":"2 x 500-Sheet Tray","Yield":"","AA":"32C0050CX922de","RR":"NO"},
    {"np":"32C0051","Model":"CS921de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"32C0051CS921de","RR":"NO"},
    {"np":"32C0051","Model":"CS923de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"32C0051CS923de","RR":"NO"},
    {"np":"32C0051","Model":"CX921de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"32C0051CX921de","RR":"NO"},
    {"np":"32C0051","Model":"CX922de","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"32C0051CX922de","RR":"NO"},
    {"np":"32C0051","Model":"CX923dte","Product":"2500-Sheet Tray - Letter","Yield":"","AA":"32C0051CX923dte","RR":"NO"},
    {"np":"32C0052","Model":"CS921de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"32C0052CS921de","RR":"NO"},
    {"np":"32C0052","Model":"CS923de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"32C0052CS923de","RR":"NO"},
    {"np":"32C0052","Model":"CX921de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"32C0052CX921de","RR":"NO"},
    {"np":"32C0052","Model":"CX922de","Product":"2500-Sheet Tray - A4","Yield":"","AA":"32C0052CX922de","RR":"NO"},
    {"np":"32C0052","Model":"CX923dte","Product":"2500-Sheet Tray - A4","Yield":"","AA":"32C0052CX923dte","RR":"NO"},
    {"np":"32C0053","Model":"CS921de","Product":"Cabinet","Yield":"","AA":"32C0053CS921de","RR":"NO"},
    {"np":"32C0053","Model":"CS923de","Product":"Cabinet","Yield":"","AA":"32C0053CS923de","RR":"NO"},
    {"np":"32C0053","Model":"CX921de","Product":"Cabinet","Yield":"","AA":"32C0053CX921de","RR":"NO"},
    {"np":"32C0053","Model":"CX922de","Product":"Cabinet","Yield":"","AA":"32C0053CX922de","RR":"NO"},
    {"np":"32C0053","Model":"CX923dte","Product":"Cabinet","Yield":"","AA":"32C0053CX923dte","RR":"NO"},
    {"np":"32C0200","Model":"CX921de","Product":"L�ser 35ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 200.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja X2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0200CX921de","RR":"NO"}
    ,
    {"np":"32C0201","Model":"CX922de","Product":"L�ser 45ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 225.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja X2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0201CX922de","RR":"NO"}
    ,
    {"np":"32C0202","Model":"CX923dte","Product":"L�ser 55ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 250.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja X4 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0202CX923dte","RR":"NO"}
    ,
    {"np":"32C0350","Model":"CX920de","Product":"L�ser 25ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 150.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 300 g/m�. Bandeja multiprop�sito de 150 hoja, bandeja X2 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"32C0350CX920de","RR":"NO"}
    ,
    {"np":"34018HL","Model":"E23/33/24/34x","Product":"E330, E332, E340, E342 High Yield Return Program Toner Cartridge","Yield":"6000","AA":"34018HLE23/33/24/34x","RR":"NO"},
    {"np":"34038HL","Model":"E23/33/24/34x","Product":"E330, E332, E340, E342 High Yield Toner Cartridge","Yield":"6000","AA":"34038HLE23/33/24/34x","RR":"NO"},
    {"np":"34S4500","Model":"MS610de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"34S4500MS610de","RR":"NO"},
    {"np":"34S4501","Model":"MS610de","Product":"Lexmark Card for IPDS","Yield":"","AA":"34S4501MS610de","RR":"NO"},
    {"np":"34S4502","Model":"MS610de","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"34S4502MS610de","RR":"NO"},
    {"np":"35S0060","Model":"MS312dn","Product":"35 ppm, 128MB, 800 MHz, DC 50K","Yield":"","AA":"35S0060MS312dn","RR":"NO"},
    {"np":"35S0160","Model":"MS315dn","Product":"37 ppm, 256MB, 800 MHz, DC 60K","Yield":"","AA":"35S0160MS315dn","RR":"NO"},
    {"np":"35S0260","Model":"MS415dn","Product":"40 ppm, 256MB, 800 MHz, DC 80K","Yield":"","AA":"35S0260MS415dn","RR":"NO"},
    {"np":"35S0267","Model":"MS312dn","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MS312dn","RR":"NO"},
    {"np":"35S0267","Model":"MS315dn","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MS315dn","RR":"NO"},
    {"np":"35S0267","Model":"MS415dn","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MS415dn","RR":"NO"},
    {"np":"35S0267","Model":"MS610de","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MS610de","RR":"NO"},
    {"np":"35S0267","Model":"MS610dn","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MS610dn","RR":"NO"},
    {"np":"35S0267","Model":"MX310dn","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MX310dn","RR":"NO"},
    {"np":"35S0267","Model":"MX410de","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MX410de","RR":"NO"},
    {"np":"35S0267","Model":"MX511de","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MX511de","RR":"NO"},
    {"np":"35S0267","Model":"MX611dfe","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MX611dfe","RR":"NO"},
    {"np":"35S0267","Model":"MX611dhe","Product":"250- Sheet Tray","Yield":"","AA":"35S0267MX611dhe","RR":"NO"},
    {"np":"35S0367","Model":"MS312dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MS312dn","RR":"NO"},
    {"np":"35S0367","Model":"MS315dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MS315dn","RR":"NO"},
    {"np":"35S0367","Model":"MS415dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MS415dn","RR":"NO"},
    {"np":"35S0367","Model":"MS610de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MS610de","RR":"NO"},
    {"np":"35S0367","Model":"MS610dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MS610dn","RR":"NO"},
    {"np":"35S0367","Model":"MX310dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MX310dn","RR":"NO"},
    {"np":"35S0367","Model":"MX410de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MX410de","RR":"NO"},
    {"np":"35S0367","Model":"MX511de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MX511de","RR":"NO"},
    {"np":"35S0367","Model":"MX611dfe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MX611dfe","RR":"NO"},
    {"np":"35S0367","Model":"MX611dhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"35S0367MX611dhe","RR":"NO"},
    {"np":"35S0400","Model":"MS610dn","Product":"50 ppm, 256MB, 800MHz, DC 100K","Yield":"","AA":"35S0400MS610dn","RR":"NO"},
    {"np":"35S0500","Model":"MS610de","Product":"50 ppm, 512MB, 800MHz, DC 150K, e-Task","Yield":"","AA":"35S0500MS610de","RR":"NO"},
    {"np":"35S0567","Model":"MS312dn","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MS312dn","RR":"NO"},
    {"np":"35S0567","Model":"MS315dn","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MS315dn","RR":"NO"},
    {"np":"35S0567","Model":"MS415dn","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MS415dn","RR":"NO"},
    {"np":"35S0567","Model":"MS610de","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MS610de","RR":"NO"},
    {"np":"35S0567","Model":"MS610dn","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MS610dn","RR":"NO"},
    {"np":"35S0567","Model":"MX310dn","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MX310dn","RR":"NO"},
    {"np":"35S0567","Model":"MX410de","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MX410de","RR":"NO"},
    {"np":"35S0567","Model":"MX511de","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MX511de","RR":"NO"},
    {"np":"35S0567","Model":"MX611dfe","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MX611dfe","RR":"NO"},
    {"np":"35S0567","Model":"MX611dhe","Product":"550- Sheet Tray","Yield":"","AA":"35S0567MX611dhe","RR":"NO"},
    {"np":"35S2992","Model":"MS610dn","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"35S2992MS610dn","RR":"NO"},
    {"np":"35S2993","Model":"MS610dn","Product":"Lexmark Card for IPDS","Yield":"","AA":"35S2993MS610dn","RR":"NO"},
    {"np":"35S2994","Model":"MS610dn","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"35S2994MS610dn","RR":"NO"},
    {"np":"35S5700","Model":"MX310dn","Product":"35 ppm, 256MB, 800MHz, DC 50K","Yield":"","AA":"35S5700MX310dn","RR":"NO"},
    {"np":"35S5701","Model":"MX410de","Product":"40 ppm, 512MB, 800MHz, DC 80K, e-Task","Yield":"","AA":"35S5701MX410de","RR":"NO"},
    {"np":"35S5703","Model":"MX511de","Product":"45 ppm, 512MB, 800MHz, DC 100K, e-Task","Yield":"","AA":"35S5703MX511de","RR":"NO"},
    {"np":"35S5888","Model":"MX410de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"35S5888MX410de","RR":"NO"},
    {"np":"35S5888","Model":"MX511de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"35S5888MX511de","RR":"NO"},
    {"np":"35S5889","Model":"MX410de","Product":"Lexmark Card for IPDS","Yield":"","AA":"35S5889MX410de","RR":"NO"},
    {"np":"35S5889","Model":"MX511de","Product":"Lexmark Card for IPDS","Yield":"","AA":"35S5889MX511de","RR":"NO"},
    {"np":"35S5890","Model":"MX410de","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"35S5890MX410de","RR":"NO"},
    {"np":"35S5890","Model":"MX511de","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"35S5890MX511de","RR":"NO"},
    {"np":"35S6702","Model":"MX611dhe","Product":"50 ppm, 1.2GB, 800MHz, DC 150K, e-Task","Yield":"","AA":"35S6702MX611dhe","RR":"NO"},
    {"np":"35S6744","Model":"MX611dfe","Product":"50 ppm, 1.2GB, 800MHz, DC 150K, e-Task","Yield":"","AA":"35S6744MX611dfe","RR":"NO"},
    {"np":"35S6850","Model":"MX611dfe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"35S6850MX611dfe","RR":"NO"},
    {"np":"35S6850","Model":"MX611dhe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"35S6850MX611dhe","RR":"NO"},
    {"np":"35S6851","Model":"MX611dfe","Product":"Lexmark Card for IPDS","Yield":"","AA":"35S6851MX611dfe","RR":"NO"},
    {"np":"35S6851","Model":"MX611dhe","Product":"Lexmark Card for IPDS","Yield":"","AA":"35S6851MX611dhe","RR":"NO"},
    {"np":"35S6852","Model":"MX611dfe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"35S6852MX611dfe","RR":"NO"},
    {"np":"35S6852","Model":"MX611dhe","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"35S6852MX611dhe","RR":"NO"},
    {"np":"35S8000","Model":"MX611dhe","Product":"Lexmark Series Stapler Option","Yield":"","AA":"35S8000MX611dhe","RR":"NO"},
    {"np":"35S8500","Model":"MX611dfe","Product":"5K -Staple Cartridges","Yield":"5000","AA":"35S8500MX611dfe","RR":"NO"},
    {"np":"35S8500","Model":"MX611dhe","Product":"5K -Staple Cartridges","Yield":"5000","AA":"35S8500MX611dhe","RR":"NO"},
    {"np":"35S8502","Model":"CS310dn","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CS310dn","RR":"NO"},
    {"np":"35S8502","Model":"CS510de","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CS510de","RR":"NO"},
    {"np":"35S8502","Model":"CX310dn","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CX310dn","RR":"NO"},
    {"np":"35S8502","Model":"CX410de","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CX410de","RR":"NO"},
    {"np":"35S8502","Model":"CX510de","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CX510de","RR":"NO"},
    {"np":"35S8502","Model":"CX510dhe","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502CX510dhe","RR":"NO"},
    {"np":"35S8502","Model":"MS321dn","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MS321dn","RR":"NO"},
    {"np":"35S8502","Model":"MS421dn","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MS421dn","RR":"NO"},
    {"np":"35S8502","Model":"MS521dn","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MS521dn","RR":"NO"},
    {"np":"35S8502","Model":"MS610de","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502MS610de","RR":"NO"},
    {"np":"35S8502","Model":"MS610dn","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502MS610dn","RR":"NO"},
    {"np":"35S8502","Model":"MS621dn","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MS621dn","RR":"NO"},
    {"np":"35S8502","Model":"MS622de","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MS622de","RR":"NO"},
    {"np":"35S8502","Model":"MX321adn","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MX321adn","RR":"NO"},
    {"np":"35S8502","Model":"MX421ade","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MX421ade","RR":"NO"},
    {"np":"35S8502","Model":"MX511de","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502MX511de","RR":"NO"},
    {"np":"35S8502","Model":"MX521ade","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MX521ade","RR":"NO"},
    {"np":"35S8502","Model":"MX522adhe","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MX522adhe","RR":"NO"},
    {"np":"35S8502","Model":"MX611dfe","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502MX611dfe","RR":"NO"},
    {"np":"35S8502","Model":"MX611dhe","Product":"Lexmark Adjustable Printer Stand","Yield":"","AA":"35S8502MX611dhe","RR":"NO"},
    {"np":"35S8502","Model":"MX622adhe","Product":"Adjustable Printer Stand","Yield":"","AA":"35S8502MX622adhe","RR":"NO"},
    {"np":"36S0100","Model":"MS321dn","Product":"36/38 ppm, 512MB, 1 GHz, 2-line APA display, DC 50K","Yield":"","AA":"36S0100MS321dn","RR":"NO"},
    {"np":"36S0200","Model":"MS421dn","Product":"40/42 ppm, 512MB, 1 GHz, 2-line APA display, DC 100K","Yield":"","AA":"36S0200MS421dn","RR":"NO"},
    {"np":"36S0300","Model":"MS521dn","Product":"L�ser,  46ppm, Pantalla LCD color de 2.4 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 120.000p�gs, Recomendado de 15.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0300MS521dn","RR":"NO"}
    ,
    {"np":"36S0400","Model":"MS621dn","Product":"L�ser,  50ppm, Pantalla LCD color de 2.4 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 175.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB 2.0, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0400MS621dn","RR":"NO"}
    ,
    {"np":"36S0500","Model":"MS622de","Product":"L�ser,  50ppm, Pantalla tackil color de 4,3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 175.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB 2.0, Procesador dual core  de 1.0Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0500MS622de","RR":"NO"}
    ,
    {"np":"36S0620","Model":"MX321adn","Product":"36/38 ppm, 1024 MB, 1 GHz, 2.4� color display & numpad, DC 50K","Yield":"","AA":"36S0620MX321adn","RR":"NO"}
    ,
    {"np":"36S0700","Model":"MX421ade","Product":"40/42 ppm, 1024 MB, 1 GHz, 4.3\" color touch screen& numpad, DC 100K","Yield":"","AA":"36S0700MX421ade","RR":"NO"},
    {"np":"36S0820","Model":"MX521ade","Product":"44/46 ppm, 1024 MB, 1 GHz,  4.3\" color touch screen & numpad, DC 120K","Yield":"","AA":"36S0820MX521ade","RR":"NO"},
    {"np":"36S0820","Model":"MX521ade !!Cat�logo Electr�nico��","Product":"L�ser, 46ppm, Pantalla t�ctil color 4.3 pulgadas, RADF (d�plex con recirculaci�n), Ciclo de trabajo mensual m�ximo de hasta 120.000p�gs, Recomendado de 15.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador dual core  de 1.0Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0820MX521ade !!Cat�logo Electr�nico��","RR":"NO"}
    ,
    {"np":"36S0840","Model":"MX522adhe","Product":"L�ser, 46ppm, Pantalla t�ctil color 4.3 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 120.000p�gs, Recomendado de 15.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador quad core  de 1.2Ghz, Memoria de 2048 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0840MX522adhe","RR":"NO"}
    ,
    {"np":"36S0920","Model":"MX622adhe","Product":"L�ser, 50ppm, Pantalla t�ctil color 7 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 175.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB, Procesador quad core  de 1.2Ghz, Memoria de 2048 MB. Gramaje admitido de 60 a 120 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 250 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"36S0920MX622adhe","RR":"NO"}
    ,
    {"np":"36S2910","Model":"MS321dn","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MS321dn","RR":"NO"},
    {"np":"36S2910","Model":"MS421dn","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MS421dn","RR":"NO"},
    {"np":"36S2910","Model":"MS521dn","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MS521dn","RR":"NO"},
    {"np":"36S2910","Model":"MS621dn","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MS621dn","RR":"NO"},
    {"np":"36S2910","Model":"MS622de","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MS622de","RR":"NO"},
    {"np":"36S2910","Model":"MX321adn","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MX321adn","RR":"NO"},
    {"np":"36S2910","Model":"MX421ade","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MX421ade","RR":"NO"},
    {"np":"36S2910","Model":"MX521ade","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MX521ade","RR":"NO"},
    {"np":"36S2910","Model":"MX522adhe","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MX522adhe","RR":"NO"},
    {"np":"36S2910","Model":"MX622adhe","Product":"250- Sheet Tray","Yield":"","AA":"36S2910MX622adhe","RR":"NO"},
    {"np":"36S3110","Model":"MS321dn","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MS321dn","RR":"NO"},
    {"np":"36S3110","Model":"MS421dn","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MS421dn","RR":"NO"},
    {"np":"36S3110","Model":"MS521dn","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MS521dn","RR":"NO"},
    {"np":"36S3110","Model":"MS621dn","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MS621dn","RR":"NO"},
    {"np":"36S3110","Model":"MS622de","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MS622de","RR":"NO"},
    {"np":"36S3110","Model":"MX321adn","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MX321adn","RR":"NO"},
    {"np":"36S3110","Model":"MX421ade","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MX421ade","RR":"NO"},
    {"np":"36S3110","Model":"MX521ade","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MX521ade","RR":"NO"},
    {"np":"36S3110","Model":"MX522adhe","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MX522adhe","RR":"NO"},
    {"np":"36S3110","Model":"MX622adhe","Product":"550- Sheet Tray","Yield":"","AA":"36S3110MX622adhe","RR":"NO"},
    {"np":"36S3120","Model":"MS321dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MS321dn","RR":"NO"},
    {"np":"36S3120","Model":"MS421dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MS421dn","RR":"NO"},
    {"np":"36S3120","Model":"MS521dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MS521dn","RR":"NO"},
    {"np":"36S3120","Model":"MS621dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MS621dn","RR":"NO"},
    {"np":"36S3120","Model":"MS622de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MS622de","RR":"NO"},
    {"np":"36S3120","Model":"MX321adn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MX321adn","RR":"NO"},
    {"np":"36S3120","Model":"MX421ade","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MX421ade","RR":"NO"},
    {"np":"36S3120","Model":"MX521ade","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MX521ade","RR":"NO"},
    {"np":"36S3120","Model":"MX522adhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MX522adhe","RR":"NO"},
    {"np":"36S3120","Model":"MX622adhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"36S3120MX622adhe","RR":"NO"},
    {"np":"36S8010","Model":"MX622adhe","Product":"Stapler Option","Yield":"","AA":"36S8010MX622adhe","RR":"NO"},
    {"np":"38C0512","Model":"CS510de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"38C0512CS510de","RR":"NO"},
    {"np":"38C0517","Model":"CS510de","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"38C0517CS510de","RR":"NO"},
    {"np":"38C0626","Model":"CS310dn","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CS310dn","RR":"NO"},
    {"np":"38C0626","Model":"CS510de","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CS510de","RR":"NO"},
    {"np":"38C0626","Model":"CX310dn","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CX310dn","RR":"NO"},
    {"np":"38C0626","Model":"CX410de","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CX410de","RR":"NO"},
    {"np":"38C0626","Model":"CX510de","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CX510de","RR":"NO"},
    {"np":"38C0626","Model":"CX510dhe","Product":"650-Sheet Duo Tray","Yield":"","AA":"38C0626CX510dhe","RR":"NO"},
    {"np":"38C0636","Model":"CS510de","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"38C0636CS510de","RR":"NO"},
    {"np":"38C0636","Model":"CX410de","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"38C0636CX410de","RR":"NO"},
    {"np":"38C0636","Model":"CX510de","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"38C0636CX510de","RR":"NO"},
    {"np":"38C0636","Model":"CX510dhe","Product":"Lexmark 550-Sheet Tray","Yield":"","AA":"38C0636CX510dhe","RR":"NO"},
    {"np":"38C5050","Model":"CX310dn","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"38C5050CX310dn","RR":"NO"},
    {"np":"38C5051","Model":"CX310dn","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"38C5051CX310dn","RR":"NO"},
    {"np":"38C5052","Model":"CX410de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"38C5052CX410de","RR":"NO"},
    {"np":"38C5053","Model":"CX410de","Product":"Lexmark PRESCRIBE Card","Yield":"","AA":"38C5053CX410de","RR":"NO"},
    {"np":"38C5054","Model":"CX510de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"38C5054CX510de","RR":"NO"},
    {"np":"38C5054","Model":"CX510dhe","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"38C5054CX510dhe","RR":"NO"},
    {"np":"38C5055","Model":"CX510de","Product":"Lexmark  PRESCRIBE Card","Yield":"","AA":"38C5055CX510de","RR":"NO"},
    {"np":"38C5055","Model":"CX510dhe","Product":"Lexmark  PRESCRIBE Card","Yield":"","AA":"38C5055CX510dhe","RR":"NO"},
    {"np":"40C2100","Model":"CS720de","Product":"550-Sheet Tray","Yield":"","AA":"40C2100CS720de","RR":"NO"},
    {"np":"40C2100","Model":"CS725de","Product":"550-Sheet Tray","Yield":"","AA":"40C2100CS725de","RR":"NO"},
    {"np":"40C2100","Model":"CX725dhe","Product":"550-Sheet Tray","Yield":"","AA":"40C2100CX725dhe","RR":"NO"},
    {"np":"40C2300","Model":"CS421dn","Product":"Adjustable Stand","Yield":"","AA":"40C2300CS421dn","RR":"NO"},
    {"np":"40C2300","Model":"CS521dn","Product":"Adjustable Stand","Yield":"","AA":"40C2300CS521dn","RR":"NO"},
    {"np":"40C2300","Model":"CS622de","Product":"Adjustable Stand","Yield":"","AA":"40C2300CS622de","RR":"NO"},
    {"np":"40C2300","Model":"CS720de","Product":"Adjustable Stand","Yield":"","AA":"40C2300CS720de","RR":"NO"},
    {"np":"40C2300","Model":"CS725de","Product":"Adjustable Stand","Yield":"","AA":"40C2300CS725de","RR":"NO"},
    {"np":"40C2300","Model":"CX421adn","Product":"Adjustable Stand","Yield":"","AA":"40C2300CX421adn","RR":"NO"},
    {"np":"40C2300","Model":"CX522ade","Product":"Adjustable Stand","Yield":"","AA":"40C2300CX522ade","RR":"NO"},
    {"np":"40C2300","Model":"CX622ade","Product":"Adjustable Stand","Yield":"","AA":"40C2300CX622ade","RR":"NO"},
    {"np":"40C2300","Model":"CX625adhe","Product":"Adjustable Stand","Yield":"","AA":"40C2300CX625adhe","RR":"NO"},
    {"np":"40C2300","Model":"CX725dhe","Product":"Adjustable Stand","Yield":"","AA":"40C2300CX725dhe","RR":"NO"},
    {"np":"40C2300","Model":"MS821dn","Product":"Adjustable Stand","Yield":"","AA":"40C2300MS821dn","RR":"NO"},
    {"np":"40C2300","Model":"MS823dn","Product":"Adjustable Stand","Yield":"","AA":"40C2300MS823dn","RR":"NO"},
    {"np":"40C2300","Model":"MS826de","Product":"Adjustable Stand","Yield":"","AA":"40C2300MS826de","RR":"NO"},
    {"np":"40C2300","Model":"MX722adhe","Product":"Adjustable Stand","Yield":"","AA":"40C2300MX722adhe","RR":"NO"},
    {"np":"40C9000","Model":"CS725de","Product":"L�ser, 50ppm, Pantalla t�ctil a color 4.3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 150.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, inalambrico (opcional), Procesador dual core de 1.2Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 218 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 300 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"40C9000CS725de","RR":"NO"}
    ,
    {"np":"40C9100","Model":"CS720de","Product":"40/40 ppm, 1024MB, 1.2GHz, MDC 120K, e-Task","Yield":"","AA":"40C9100CS720de","RR":"NO"},
    {"np":"40C9200","Model":"CS421dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS421dn","RR":"NO"},
    {"np":"40C9200","Model":"CS521dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS521dn","RR":"NO"},
    {"np":"40C9200","Model":"CS622de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS622de","RR":"NO"},
    {"np":"40C9200","Model":"CS720de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS720de","RR":"NO"},
    {"np":"40C9200","Model":"CS725de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS725de","RR":"NO"},
    {"np":"40C9200","Model":"CS921de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS921de","RR":"NO"},
    {"np":"40C9200","Model":"CS923de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CS923de","RR":"NO"},
    {"np":"40C9200","Model":"CX421adn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX421adn","RR":"NO"},
    {"np":"40C9200","Model":"CX522ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX522ade","RR":"NO"},
    {"np":"40C9200","Model":"CX622ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX622ade","RR":"NO"},
    {"np":"40C9200","Model":"CX625adhe","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX625adhe","RR":"NO"},
    {"np":"40C9200","Model":"CX725dhe","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX725dhe","RR":"NO"},
    {"np":"40C9200","Model":"CX921de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX921de","RR":"NO"},
    {"np":"40C9200","Model":"CX922de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX922de","RR":"NO"},
    {"np":"40C9200","Model":"CX923dte","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200CX923dte","RR":"NO"},
    {"np":"40C9200","Model":"MS521dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS521dn","RR":"NO"},
    {"np":"40C9200","Model":"MS621dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS621dn","RR":"NO"},
    {"np":"40C9200","Model":"MS622de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS622de","RR":"NO"},
    {"np":"40C9200","Model":"MS821dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS821dn","RR":"NO"},
    {"np":"40C9200","Model":"MS823dn","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS823dn","RR":"NO"},
    {"np":"40C9200","Model":"MS826de","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MS826de","RR":"NO"},
    {"np":"40C9200","Model":"MX421ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX421ade","RR":"NO"},
    {"np":"40C9200","Model":"MX521ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX521ade","RR":"NO"},
    {"np":"40C9200","Model":"MX522adhe","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX522adhe","RR":"NO"},
    {"np":"40C9200","Model":"MX622adhe","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX622adhe","RR":"NO"},
    {"np":"40C9200","Model":"MX722adhe","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX722adhe","RR":"NO"},
    {"np":"40C9200","Model":"MX822ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX822ade","RR":"NO"},
    {"np":"40C9200","Model":"MX826ade","Product":"Forms and Bar Code Card","Yield":"","AA":"40C9200MX826ade","RR":"NO"},
    {"np":"40C9201","Model":"CS720de","Product":"Card for IPDS","Yield":"","AA":"40C9201CS720de","RR":"NO"},
    {"np":"40C9201","Model":"CS725de","Product":"Card for IPDS","Yield":"","AA":"40C9201CS725de","RR":"NO"},
    {"np":"40C9201","Model":"CS921de","Product":"Card for IPDS","Yield":"","AA":"40C9201CS921de","RR":"NO"},
    {"np":"40C9201","Model":"CS923de","Product":"Card for IPDS","Yield":"","AA":"40C9201CS923de","RR":"NO"},
    {"np":"40C9201","Model":"CX725dhe","Product":"Card for IPDS","Yield":"","AA":"40C9201CX725dhe","RR":"NO"},
    {"np":"40C9201","Model":"CX921de","Product":"Card for IPDS","Yield":"","AA":"40C9201CX921de","RR":"NO"},
    {"np":"40C9201","Model":"CX922de","Product":"Card for IPDS","Yield":"","AA":"40C9201CX922de","RR":"NO"},
    {"np":"40C9201","Model":"CX923dte","Product":"Card for IPDS","Yield":"","AA":"40C9201CX923dte","RR":"NO"},
    {"np":"40C9201","Model":"MS521dn","Product":"Card for IPDS","Yield":"","AA":"40C9201MS521dn","RR":"NO"},
    {"np":"40C9201","Model":"MS621dn","Product":"Card for IPDS","Yield":"","AA":"40C9201MS621dn","RR":"NO"},
    {"np":"40C9201","Model":"MS622de","Product":"Card for IPDS","Yield":"","AA":"40C9201MS622de","RR":"NO"},
    {"np":"40C9201","Model":"MS821dn","Product":"Card for IPDS","Yield":"","AA":"40C9201MS821dn","RR":"NO"},
    {"np":"40C9201","Model":"MS823dn","Product":"Card for IPDS","Yield":"","AA":"40C9201MS823dn","RR":"NO"},
    {"np":"40C9201","Model":"MS826de","Product":"Card for IPDS","Yield":"","AA":"40C9201MS826de","RR":"NO"},
    {"np":"40C9201","Model":"MX421ade","Product":"Card for IPDS","Yield":"","AA":"40C9201MX421ade","RR":"NO"},
    {"np":"40C9201","Model":"MX521ade","Product":"Card for IPDS","Yield":"","AA":"40C9201MX521ade","RR":"NO"},
    {"np":"40C9201","Model":"MX522adhe","Product":"Card for IPDS","Yield":"","AA":"40C9201MX522adhe","RR":"NO"},
    {"np":"40C9201","Model":"MX622adhe","Product":"Card for IPDS","Yield":"","AA":"40C9201MX622adhe","RR":"NO"},
    {"np":"40C9201","Model":"MX722adhe","Product":"Card for IPDS","Yield":"","AA":"40C9201MX722adhe","RR":"NO"},
    {"np":"40C9201","Model":"MX822ade","Product":"Card for IPDS","Yield":"","AA":"40C9201MX822ade","RR":"NO"},
    {"np":"40C9201","Model":"MX826ade","Product":"Card for IPDS","Yield":"","AA":"40C9201MX826ade","RR":"NO"},
    {"np":"40C9202","Model":"CS421dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS421dn","RR":"NO"},
    {"np":"40C9202","Model":"CS521dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS521dn","RR":"NO"},
    {"np":"40C9202","Model":"CS622de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS622de","RR":"NO"},
    {"np":"40C9202","Model":"CS720de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS720de","RR":"NO"},
    {"np":"40C9202","Model":"CS725de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS725de","RR":"NO"},
    {"np":"40C9202","Model":"CS921de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS921de","RR":"NO"},
    {"np":"40C9202","Model":"CS923de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CS923de","RR":"NO"},
    {"np":"40C9202","Model":"CX421adn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX421adn","RR":"NO"},
    {"np":"40C9202","Model":"CX522ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX522ade","RR":"NO"},
    {"np":"40C9202","Model":"CX622ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX622ade","RR":"NO"},
    {"np":"40C9202","Model":"CX625adhe","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX625adhe","RR":"NO"},
    {"np":"40C9202","Model":"CX725dhe","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX725dhe","RR":"NO"},
    {"np":"40C9202","Model":"CX921de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX921de","RR":"NO"},
    {"np":"40C9202","Model":"CX922de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX922de","RR":"NO"},
    {"np":"40C9202","Model":"CX923dte","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202CX923dte","RR":"NO"},
    {"np":"40C9202","Model":"MS521dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS521dn","RR":"NO"},
    {"np":"40C9202","Model":"MS621dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS621dn","RR":"NO"},
    {"np":"40C9202","Model":"MS622de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS622de","RR":"NO"},
    {"np":"40C9202","Model":"MS821dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS821dn","RR":"NO"},
    {"np":"40C9202","Model":"MS823dn","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS823dn","RR":"NO"},
    {"np":"40C9202","Model":"MS826de","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MS826de","RR":"NO"},
    {"np":"40C9202","Model":"MX421ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX421ade","RR":"NO"},
    {"np":"40C9202","Model":"MX521ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX521ade","RR":"NO"},
    {"np":"40C9202","Model":"MX522adhe","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX522adhe","RR":"NO"},
    {"np":"40C9202","Model":"MX622adhe","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX622adhe","RR":"NO"},
    {"np":"40C9202","Model":"MX722adhe","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX722adhe","RR":"NO"},
    {"np":"40C9202","Model":"MX822ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX822ade","RR":"NO"},
    {"np":"40C9202","Model":"MX826ade","Product":"Card for PERSCRIBE Emulation","Yield":"","AA":"40C9202MX826ade","RR":"NO"},
    {"np":"40C9501","Model":"CX725dhe","Product":"L�ser 50ppm, Pantalla LCD touch de 7 pulgadas, RADF (D�plex con recirculaci�n), Ciclo de trabajo mensual m�ximo de hasta 150.000p�gs, Recomendado de 20.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 218 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 300 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"40C9501CX725dhe","RR":"NO"}
    ,
    {"np":"40G0110","Model":"MS810dn","Product":"55 ppm, 512MB, 800MHz, DC 250K","Yield":"","AA":"40G0110MS810dn","RR":"NO"},
    {"np":"40G0150","Model":"MS810de","Product":"55 ppm, 512MB, 800MHz, DC 250K, e-Task","Yield":"","AA":"40G0150MS810de","RR":"NO"},
    {"np":"40G0210","Model":"MS811dn","Product":"63 ppm, 512MB, 800MHz, DC 275K","Yield":"","AA":"40G0210MS811dn","RR":"NO"},
    {"np":"40G0310","Model":"MS812dn","Product":"70 ppm, 512MB, 800MHz, DC 300K","Yield":"","AA":"40G0310MS812dn","RR":"NO"},
    {"np":"40G0350","Model":"MS812de","Product":"70 ppm, 512MB, 800MHz, DC 300K, e-Task","Yield":"","AA":"40G0350MS812de","RR":"NO"},
    {"np":"40G0800","Model":"MS810de","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MS810de","RR":"NO"},
    {"np":"40G0800","Model":"MS810dn","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MS810dn","RR":"NO"},
    {"np":"40G0800","Model":"MS811dn","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MS811dn","RR":"NO"},
    {"np":"40G0800","Model":"MS812de","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MS812de","RR":"NO"},
    {"np":"40G0800","Model":"MS812dn","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MS812dn","RR":"NO"},
    {"np":"40G0800","Model":"MX710dhe","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MX710dhe","RR":"NO"},
    {"np":"40G0800","Model":"MX711dhe","Product":"250- Sheet Tray","Yield":"","AA":"40G0800MX711dhe","RR":"NO"},
    {"np":"40G0802","Model":"MS810de","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MS810de","RR":"NO"},
    {"np":"40G0802","Model":"MS810dn","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MS810dn","RR":"NO"},
    {"np":"40G0802","Model":"MS811dn","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MS811dn","RR":"NO"},
    {"np":"40G0802","Model":"MS812de","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MS812de","RR":"NO"},
    {"np":"40G0802","Model":"MS812dn","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MS812dn","RR":"NO"},
    {"np":"40G0802","Model":"MX710dhe","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MX710dhe","RR":"NO"},
    {"np":"40G0802","Model":"MX711dhe","Product":"550- Sheet Tray","Yield":"","AA":"40G0802MX711dhe","RR":"NO"},
    {"np":"40G0804","Model":"MS810de","Product":"2100-Sheet Tray","Yield":"","AA":"40G0804MS810de","RR":"NO"},
    {"np":"40G0804","Model":"MS810dn","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"40G0804MS810dn","RR":"NO"},
    {"np":"40G0804","Model":"MS811dn","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"40G0804MS811dn","RR":"NO"},
    {"np":"40G0804","Model":"MS812de","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"40G0804MS812de","RR":"NO"},
    {"np":"40G0804","Model":"MS812dn","Product":"Lexmark 2100-Sheet Tray","Yield":"","AA":"40G0804MS812dn","RR":"NO"},
    {"np":"40G0804","Model":"MX710dhe","Product":"2100-Sheet Tray","Yield":"","AA":"40G0804MX710dhe","RR":"NO"},
    {"np":"40G0804","Model":"MX711dhe","Product":"2100-Sheet Tray","Yield":"","AA":"40G0804MX711dhe","RR":"NO"},
    {"np":"40G0810","Model":"MS810dn","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"40G0810MS810dn","RR":"NO"},
    {"np":"40G0810","Model":"MS811dn","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"40G0810MS811dn","RR":"NO"},
    {"np":"40G0810","Model":"MS812dn","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"40G0810MS812dn","RR":"NO"},
    {"np":"40G0811","Model":"MS810dn","Product":"Lexmark Card for IPDS","Yield":"","AA":"40G0811MS810dn","RR":"NO"},
    {"np":"40G0811","Model":"MS811dn","Product":"Lexmark Card for IPDS","Yield":"","AA":"40G0811MS811dn","RR":"NO"},
    {"np":"40G0811","Model":"MS812dn","Product":"Lexmark Card for IPDS","Yield":"","AA":"40G0811MS812dn","RR":"NO"},
    {"np":"40G0817","Model":"MS810dn","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"40G0817MS810dn","RR":"NO"},
    {"np":"40G0817","Model":"MS811dn","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"40G0817MS811dn","RR":"NO"},
    {"np":"40G0817","Model":"MS812dn","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"40G0817MS812dn","RR":"NO"},
    {"np":"40G0820","Model":"MS810de","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MS810de","RR":"NO"},
    {"np":"40G0820","Model":"MS810dn","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MS810dn","RR":"NO"},
    {"np":"40G0820","Model":"MS811dn","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MS811dn","RR":"NO"},
    {"np":"40G0820","Model":"MS812de","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MS812de","RR":"NO"},
    {"np":"40G0820","Model":"MS812dn","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MS812dn","RR":"NO"},
    {"np":"40G0820","Model":"MX710dhe","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MX710dhe","RR":"NO"},
    {"np":"40G0820","Model":"MX711dhe","Product":"250-Sheet Lockable Tray","Yield":"","AA":"40G0820MX711dhe","RR":"NO"},
    {"np":"40G0822","Model":"MS810de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MS810de","RR":"NO"},
    {"np":"40G0822","Model":"MS810dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MS810dn","RR":"NO"},
    {"np":"40G0822","Model":"MS811dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MS811dn","RR":"NO"},
    {"np":"40G0822","Model":"MS812de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MS812de","RR":"NO"},
    {"np":"40G0822","Model":"MS812dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MS812dn","RR":"NO"},
    {"np":"40G0822","Model":"MX710dhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MX710dhe","RR":"NO"},
    {"np":"40G0822","Model":"MX711dhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"40G0822MX711dhe","RR":"NO"},
    {"np":"40G0830","Model":"MS810de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"40G0830MS810de","RR":"NO"},
    {"np":"40G0831","Model":"MS810de","Product":"Lexmark Card for IPDS","Yield":"","AA":"40G0831MS810de","RR":"NO"},
    {"np":"40G0837","Model":"MS810de","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"40G0837MS810de","RR":"NO"},
    {"np":"40G0840","Model":"MS812de","Product":"Lexmark Forms and Bar Code Card","Yield":"","AA":"40G0840MS812de","RR":"NO"},
    {"np":"40G0841","Model":"MS812de","Product":"Lexmark Card for IPDS","Yield":"","AA":"40G0841MS812de","RR":"NO"},
    {"np":"40G0847","Model":"MS812de","Product":"Lexmark Card for PERSCRIBE Emulation","Yield":"","AA":"40G0847MS812de","RR":"NO"},
    {"np":"40G0849","Model":"MS810de","Product":"Staple & Hole Punch Finisher","Yield":"","AA":"40G0849MS810de","RR":"NO"},
    {"np":"40G0849","Model":"MS810dn","Product":"Staple & Hole Punch Finisher","Yield":"","AA":"40G0849MS810dn","RR":"NO"},
    {"np":"40G0849","Model":"MS811dn","Product":"Staple & Hole Punch Finisher","Yield":"","AA":"40G0849MS811dn","RR":"NO"},
    {"np":"40G0849","Model":"MS812de","Product":"Staple & Hole Punch Finisher","Yield":"","AA":"40G0849MS812de","RR":"NO"},
    {"np":"40G0849","Model":"MS812dn","Product":"Staple & Hole Punch Finisher","Yield":"","AA":"40G0849MS812dn","RR":"NO"},
    {"np":"40G0850","Model":"MS810de","Product":"Staple Finisher","Yield":"","AA":"40G0850MS810de","RR":"NO"},
    {"np":"40G0850","Model":"MS810dn","Product":"Staple Finisher","Yield":"","AA":"40G0850MS810dn","RR":"NO"},
    {"np":"40G0850","Model":"MS811dn","Product":"Staple Finisher","Yield":"","AA":"40G0850MS811dn","RR":"NO"},
    {"np":"40G0850","Model":"MS812de","Product":"Staple Finisher","Yield":"","AA":"40G0850MS812de","RR":"NO"},
    {"np":"40G0850","Model":"MS812dn","Product":"Staple Finisher","Yield":"","AA":"40G0850MS812dn","RR":"NO"},
    {"np":"40G0851","Model":"MS810de","Product":"Lexmark Output Expander","Yield":"","AA":"40G0851MS810de","RR":"NO"},
    {"np":"40G0851","Model":"MS810dn","Product":"Lexmark Output Expander","Yield":"","AA":"40G0851MS810dn","RR":"NO"},
    {"np":"40G0851","Model":"MS811dn","Product":"Lexmark Output Expander","Yield":"","AA":"40G0851MS811dn","RR":"NO"},
    {"np":"40G0851","Model":"MS812de","Product":"Lexmark Output Expander","Yield":"","AA":"40G0851MS812de","RR":"NO"},
    {"np":"40G0851","Model":"MS812dn","Product":"Lexmark Output Expander","Yield":"","AA":"40G0851MS812dn","RR":"NO"},
    {"np":"40G0852","Model":"MS810de","Product":"4-Bin Mailbox","Yield":"","AA":"40G0852MS810de","RR":"NO"},
    {"np":"40G0852","Model":"MS810dn","Product":"4-Bin Mailbox","Yield":"","AA":"40G0852MS810dn","RR":"NO"},
    {"np":"40G0852","Model":"MS811dn","Product":"4-Bin Mailbox","Yield":"","AA":"40G0852MS811dn","RR":"NO"},
    {"np":"40G0852","Model":"MS812de","Product":"4-Bin Mailbox","Yield":"","AA":"40G0852MS812de","RR":"NO"},
    {"np":"40G0852","Model":"MS812dn","Product":"4-Bin Mailbox","Yield":"","AA":"40G0852MS812dn","RR":"NO"},
    {"np":"40G0853","Model":"MS810de","Product":"High Capacity Output Expander","Yield":"","AA":"40G0853MS810de","RR":"NO"},
    {"np":"40G0853","Model":"MS810dn","Product":"High Capacity Output Expander","Yield":"","AA":"40G0853MS810dn","RR":"NO"},
    {"np":"40G0853","Model":"MS811dn","Product":"High Capacity Output Expander","Yield":"","AA":"40G0853MS811dn","RR":"NO"},
    {"np":"40G0853","Model":"MS812de","Product":"High Capacity Output Expander","Yield":"","AA":"40G0853MS812de","RR":"NO"},
    {"np":"40G0853","Model":"MS812dn","Product":"High Capacity Output Expander","Yield":"","AA":"40G0853MS812dn","RR":"NO"},
    {"np":"40G0854","Model":"MS810de","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MS810de","RR":"NO"},
    {"np":"40G0854","Model":"MS810dn","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MS810dn","RR":"NO"},
    {"np":"40G0854","Model":"MS811dn","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MS811dn","RR":"NO"},
    {"np":"40G0854","Model":"MS812de","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MS812de","RR":"NO"},
    {"np":"40G0854","Model":"MS812dn","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MS812dn","RR":"NO"},
    {"np":"40G0854","Model":"MX710dhe","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MX710dhe","RR":"NO"},
    {"np":"40G0854","Model":"MX711dhe","Product":"Lexmark Spacer","Yield":"","AA":"40G0854MX711dhe","RR":"NO"},
    {"np":"40G0855","Model":"MS810de","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MS810de","RR":"NO"},
    {"np":"40G0855","Model":"MS810dn","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MS810dn","RR":"NO"},
    {"np":"40G0855","Model":"MS811dn","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MS811dn","RR":"NO"},
    {"np":"40G0855","Model":"MS812de","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MS812de","RR":"NO"},
    {"np":"40G0855","Model":"MS812dn","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MS812dn","RR":"NO"},
    {"np":"40G0855","Model":"MX710dhe","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MX710dhe","RR":"NO"},
    {"np":"40G0855","Model":"MX711dhe","Product":"Lexmark Caster Base","Yield":"","AA":"40G0855MX711dhe","RR":"NO"},
    {"np":"40N9000","Model":"C3224dw","Product":"24/22.8 ppm, 256 MB, 1 GHz, 2 line LCD display, WiFi, DC 30K","Yield":"","AA":"40N9000C3224dw","RR":"NO"},
    {"np":"40N9010","Model":"C3326dw","Product":"26/24.7 ppm, 512 MB, 1 GHz, 2 line LCD display, WiFi, DC 50K","Yield":"","AA":"40N9010C3326dw","RR":"NO"},
    {"np":"40N9040","Model":"MC3224dwe","Product":"24/22.8 ppm, 512 MB, 1 GHz, 2.8� touch screen display, WiFi, DC 30K","Yield":"","AA":"40N9040MC3224dwe","RR":"NO"}
    ,
    {"np":"40N9050","Model":"MC3224adwe Incluye fax","Product":"24/22.8 ppm, 512 MB, 1 GHz, 2.8� touch screen display, WiFi, DC 40K","Yield":"","AA":"40N9050MC3224adwe Incluye fax","RR":"NO"}
    ,
    {"np":"40N9320","Model":"CS431dw Remplaza a: CS421dn","Product":"L�ser, 26ppm, Pantalla LCD touch 2.8 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 75.000p�gs, Recomendado de 5.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 100 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"40N9320CS431dw Remplaza a: CS421dn","RR":"NO"}
    ,
    {"np":"40N9370","Model":"CX431adw Remplaza a: CX421adn","Product":"L�ser 26ppm, Pantalla LCD touch de 2.8 pulgadas, DADF (d�plex a una sola pasada), Ciclo de trabajo mensual m�ximo de hasta 75.000p�gs, Recomendado de 5.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI, USB, Procesador dual core  de 1.0Ghz, Memoria de 1 GB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 100 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"40N9370CX431adw Remplaza a: CX421adn","RR":"NO"}
    ,
    {"np":"40X6011","Model":"C925de","Product":"100K -Transfer Belt with 2nd roll","Yield":"100000","AA":"40X6011C925de","RR":"NO"},
    {"np":"40X6011","Model":"X925de","Product":"100K -Transfer Belt and Roll","Yield":"100000","AA":"40X6011X925de","RR":"NO"},
    {"np":"40X6013","Model":"C925de","Product":"120K -Fuser Maintenance Kit / 110-120V","Yield":"120000","AA":"40X6013C925de","RR":"NO"},
    {"np":"40X6013","Model":"X925de","Product":"120K- Fuser Maintenance Kit / 110-120V","Yield":"120000","AA":"40X6013X925de","RR":"NO"},
    {"np":"40X6093","Model":"C925de","Product":"120K -Fuser Maintenance Kit / 220V","Yield":"120000","AA":"40X6093C925de","RR":"NO"},
    {"np":"40X6093","Model":"X925de","Product":"120K -Fuser Maintenance Kit / 220V","Yield":"120000","AA":"40X6093X925de","RR":"NO"},
    {"np":"40X6247","Model":"CX310dn","Product":"90K -ADF Separator Pad","Yield":"90000","AA":"40X6247CX310dn","RR":"NO"},
    {"np":"40X6247","Model":"MX321adn","Product":"ADF RADF Separator Roll","Yield":"220000","AA":"40X6247MX321adn","RR":"NO"},
    {"np":"40X6328","Model":"X925de","Product":"ADF Separator Roll","Yield":"","AA":"40X6328X925de","RR":"NO"},
    {"np":"40X6357","Model":"X925de","Product":"Multipurpose feeder (MPF) Maintenance kit","Yield":"","AA":"40X6357X925de","RR":"NO"},
    {"np":"40X6372","Model":"C925de","Product":"Paperfeed Maintenance kit","Yield":"","AA":"40X6372C925de","RR":"NO"},
    {"np":"40X6372","Model":"X925de","Product":"Paperfeed Maintenance kit","Yield":"","AA":"40X6372X925de","RR":"NO"},
    {"np":"40X6457","Model":"C925de","Product":"Multipurpose feeder (MPF) Maintenance kit","Yield":"","AA":"40X6457C925de","RR":"NO"},
    {"np":"40X7530","Model":"X950de","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X7530X950de","RR":"NO"},
    {"np":"40X7530","Model":"X952dte","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X7530X952dte","RR":"NO"},
    {"np":"40X7530","Model":"X954dhe","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X7530X954dhe","RR":"NO"},
    {"np":"40X7540","Model":"C950de","Product":"160K -Maintenance Kit","Yield":"160000","AA":"40X7540C950de","RR":"NO"},
    {"np":"40X7540","Model":"X950de","Product":"160K -Maintenance Kit","Yield":"160000","AA":"40X7540X950de","RR":"NO"},
    {"np":"40X7540","Model":"X952dte","Product":"160K -Maintenance Kit","Yield":"160000","AA":"40X7540X952dte","RR":"NO"},
    {"np":"40X7540","Model":"X954dhe","Product":"160K -Maintenance Kit","Yield":"160000","AA":"40X7540X954dhe","RR":"NO"},
    {"np":"40X7550","Model":"C950de","Product":"320K -Fuser Maintenance Kit / 110-120V","Yield":"320000","AA":"40X7550C950de","RR":"NO"},
    {"np":"40X7550","Model":"X950de","Product":"320K -Fuser Maintenance Kit /110-120V","Yield":"320000","AA":"40X7550X950de","RR":"NO"},
    {"np":"40X7550","Model":"X952dte","Product":"320K -Fuser Maintenance Kit /110-120V","Yield":"320000","AA":"40X7550X952dte","RR":"NO"},
    {"np":"40X7550","Model":"X954dhe","Product":"320K -Fuser Maintenance Kit /110-120V","Yield":"320000","AA":"40X7550X954dhe","RR":"NO"},
    {"np":"40X7560","Model":"C950de","Product":"480K -Maintenance Kit","Yield":"480000","AA":"40X7560C950de","RR":"NO"},
    {"np":"40X7560","Model":"X950de","Product":"480K -Maintenance Kit","Yield":"480000","AA":"40X7560X950de","RR":"NO"},
    {"np":"40X7560","Model":"X952dte","Product":"480K -Maintenance Kit","Yield":"480000","AA":"40X7560X952dte","RR":"NO"},
    {"np":"40X7560","Model":"X954dhe","Product":"480K -Maintenance Kit","Yield":"480000","AA":"40X7560X954dhe","RR":"NO"},
    {"np":"40X7569","Model":"C950de","Product":"320K -Fuser Maintenance Kit / 220V","Yield":"320000","AA":"40X7569C950de","RR":"NO"},
    {"np":"40X7569","Model":"X950de","Product":"320K -Fuser Maintenance Kit /220V","Yield":"320000","AA":"40X7569X950de","RR":"NO"},
    {"np":"40X7569","Model":"X952dte","Product":"320K -Fuser Maintenance Kit /220V","Yield":"320000","AA":"40X7569X952dte","RR":"NO"},
    {"np":"40X7610","Model":"CX310dn","Product":"85K -Image Transfer Unit","Yield":"85000","AA":"40X7610CX310dn","RR":"NO"},
    {"np":"40X7610","Model":"CX410de","Product":"85K -Image Transfer Unit","Yield":"85000","AA":"40X7610CX410de","RR":"NO"},
    {"np":"40X7610","Model":"CX510de","Product":"85K -Image Transfer Unit","Yield":"85000","AA":"40X7610CX510de","RR":"NO"},
    {"np":"40X7610","Model":"CX510dhe","Product":"85K -Image Transfer Unit","Yield":"85000","AA":"40X7610CX510dhe","RR":"NO"},
    {"np":"40X7615","Model":"CS310dn","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CS310dn","RR":"NO"},
    {"np":"40X7615","Model":"CS510de","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CS510de","RR":"NO"},
    {"np":"40X7615","Model":"CX310dn","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CX310dn","RR":"NO"},
    {"np":"40X7615","Model":"CX410de","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CX410de","RR":"NO"},
    {"np":"40X7615","Model":"CX510de","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CX510de","RR":"NO"},
    {"np":"40X7615","Model":"CX510dhe","Product":"85K -Fuser Maintenance Kit / 110-120V","Yield":"85000","AA":"40X7615CX510dhe","RR":"NO"},
    {"np":"40X7616","Model":"CS310dn","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CS310dn","RR":"NO"},
    {"np":"40X7616","Model":"CS510de","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CS510de","RR":"NO"},
    {"np":"40X7616","Model":"CX310dn","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CX310dn","RR":"NO"},
    {"np":"40X7616","Model":"CX410de","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CX410de","RR":"NO"},
    {"np":"40X7616","Model":"CX510de","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CX510de","RR":"NO"},
    {"np":"40X7616","Model":"CX510dhe","Product":"85K -Fuser Maintenance Kit /  220V","Yield":"85000","AA":"40X7616CX510dhe","RR":"NO"},
    {"np":"40X7706","Model":"MS810de","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MS810de","RR":"NO"},
    {"np":"40X7706","Model":"MS810dn","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MS810dn","RR":"NO"},
    {"np":"40X7706","Model":"MS811dn","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MS811dn","RR":"NO"},
    {"np":"40X7706","Model":"MS812de","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MS812de","RR":"NO"},
    {"np":"40X7706","Model":"MS812dn","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MS812dn","RR":"NO"},
    {"np":"40X7706","Model":"MX710dhe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX710dhe","RR":"NO"},
    {"np":"40X7706","Model":"MX711dhe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX711dhe","RR":"NO"},
    {"np":"40X7706","Model":"MX810dfe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX810dfe","RR":"NO"},
    {"np":"40X7706","Model":"MX810dme","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX810dme","RR":"NO"},
    {"np":"40X7706","Model":"MX810dpe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX810dpe","RR":"NO"},
    {"np":"40X7706","Model":"MX811dfe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX811dfe","RR":"NO"},
    {"np":"40X7706","Model":"MX811dme","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX811dme","RR":"NO"},
    {"np":"40X7706","Model":"MX811dpe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX811dpe","RR":"NO"},
    {"np":"40X7706","Model":"MX812dfe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX812dfe","RR":"NO"},
    {"np":"40X7706","Model":"MX812dme","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX812dme","RR":"NO"},
    {"np":"40X7706","Model":"MX812dpe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"40X7706MX812dpe","RR":"NO"},
    {"np":"40X8023","Model":"MS312dn","Product":"150K -Fuser / 110-120V","Yield":"150000","AA":"40X8023MS312dn","RR":"NO"},
    {"np":"40X8023","Model":"MS315dn","Product":"150K -Fuser / 110-120V","Yield":"150000","AA":"40X8023MS315dn","RR":"NO"},
    {"np":"40X8023","Model":"MS415dn","Product":"150K -Fuser / 110-120V","Yield":"150000","AA":"40X8023MS415dn","RR":"NO"},
    {"np":"40X8024","Model":"MS312dn","Product":"150K -Fuser / 220V","Yield":"150000","AA":"40X8024MS312dn","RR":"NO"},
    {"np":"40X8024","Model":"MS315dn","Product":"150K -Fuser / 220V","Yield":"150000","AA":"40X8024MS315dn","RR":"NO"},
    {"np":"40X8024","Model":"MS415dn","Product":"150K -Fuser / 220V","Yield":"150000","AA":"40X8024MS415dn","RR":"NO"},
    {"np":"40X8296","Model":"MS312dn","Product":"150K -Pick Roller","Yield":"150000","AA":"40X8296MS312dn","RR":"NO"},
    {"np":"40X8296","Model":"MS315dn","Product":"150K -Pick Roller","Yield":"150000","AA":"40X8296MS315dn","RR":"NO"},
    {"np":"40X8296","Model":"MS415dn","Product":"150K -Pick Roller","Yield":"150000","AA":"40X8296MS415dn","RR":"NO"},
    {"np":"40X8393","Model":"MS321dn","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MS321dn","RR":"NO"},
    {"np":"40X8393","Model":"MS421dn","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MS421dn","RR":"NO"},
    {"np":"40X8393","Model":"MS521dn","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MS521dn","RR":"NO"},
    {"np":"40X8393","Model":"MX321adn","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MX321adn","RR":"NO"},
    {"np":"40X8393","Model":"MX421ade","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MX421ade","RR":"NO"},
    {"np":"40X8393","Model":"MX521ade","Product":"Transfer Roller","Yield":"200000","AA":"40X8393MX521ade","RR":"NO"},
    {"np":"40X8420","Model":"MS810de","Product":"200K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MS810de","RR":"NO"},
    {"np":"40X8420","Model":"MS810dn","Product":"200K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MS810dn","RR":"NO"},
    {"np":"40X8420","Model":"MS811dn","Product":"200K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MS811dn","RR":"NO"},
    {"np":"40X8420","Model":"MS812de","Product":"200K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MS812de","RR":"NO"},
    {"np":"40X8420","Model":"MS812dn","Product":"200K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MS812dn","RR":"NO"},
    {"np":"40X8420","Model":"MX710dhe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX710dhe","RR":"NO"},
    {"np":"40X8420","Model":"MX711dhe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX711dhe","RR":"NO"},
    {"np":"40X8420","Model":"MX810dfe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX810dfe","RR":"NO"},
    {"np":"40X8420","Model":"MX810dme","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX810dme","RR":"NO"},
    {"np":"40X8420","Model":"MX810dpe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX810dpe","RR":"NO"},
    {"np":"40X8420","Model":"MX811dfe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX811dfe","RR":"NO"},
    {"np":"40X8420","Model":"MX811dme","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX811dme","RR":"NO"},
    {"np":"40X8420","Model":"MX811dpe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX811dpe","RR":"NO"},
    {"np":"40X8420","Model":"MX812dfe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX812dfe","RR":"NO"},
    {"np":"40X8420","Model":"MX812dme","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX812dme","RR":"NO"},
    {"np":"40X8420","Model":"MX812dpe","Product":"200K -Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"200000","AA":"40X8420MX812dpe","RR":"NO"},
    {"np":"40X8421","Model":"MS810de","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MS810de","RR":"NO"},
    {"np":"40X8421","Model":"MS810dn","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MS810dn","RR":"NO"},
    {"np":"40X8421","Model":"MS811dn","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MS811dn","RR":"NO"},
    {"np":"40X8421","Model":"MS812de","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MS812de","RR":"NO"},
    {"np":"40X8421","Model":"MS812dn","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MS812dn","RR":"NO"},
    {"np":"40X8421","Model":"MX710dhe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX710dhe","RR":"NO"},
    {"np":"40X8421","Model":"MX711dhe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX711dhe","RR":"NO"},
    {"np":"40X8421","Model":"MX810dfe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX810dfe","RR":"NO"},
    {"np":"40X8421","Model":"MX810dme","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX810dme","RR":"NO"},
    {"np":"40X8421","Model":"MX810dpe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX810dpe","RR":"NO"},
    {"np":"40X8421","Model":"MX811dfe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX811dfe","RR":"NO"},
    {"np":"40X8421","Model":"MX811dme","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX811dme","RR":"NO"},
    {"np":"40X8421","Model":"MX811dpe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX811dpe","RR":"NO"},
    {"np":"40X8421","Model":"MX812dfe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX812dfe","RR":"NO"},
    {"np":"40X8421","Model":"MX812dme","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX812dme","RR":"NO"},
    {"np":"40X8421","Model":"MX812dpe","Product":"200K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"200000","AA":"40X8421MX812dpe","RR":"NO"},
    {"np":"40X8423","Model":"MS810de","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MS810de","RR":"NO"},
    {"np":"40X8423","Model":"MS810dn","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MS810dn","RR":"NO"},
    {"np":"40X8423","Model":"MS811dn","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MS811dn","RR":"NO"},
    {"np":"40X8423","Model":"MS812de","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MS812de","RR":"NO"},
    {"np":"40X8423","Model":"MS812dn","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MS812dn","RR":"NO"},
    {"np":"40X8423","Model":"MX710dhe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX710dhe","RR":"NO"},
    {"np":"40X8423","Model":"MX711dhe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX711dhe","RR":"NO"},
    {"np":"40X8423","Model":"MX810dfe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX810dfe","RR":"NO"},
    {"np":"40X8423","Model":"MX810dme","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX810dme","RR":"NO"},
    {"np":"40X8423","Model":"MX810dpe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX810dpe","RR":"NO"},
    {"np":"40X8423","Model":"MX811dfe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX811dfe","RR":"NO"},
    {"np":"40X8423","Model":"MX811dme","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX811dme","RR":"NO"},
    {"np":"40X8423","Model":"MX811dpe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX811dpe","RR":"NO"},
    {"np":"40X8423","Model":"MX812dfe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX812dfe","RR":"NO"},
    {"np":"40X8423","Model":"MX812dme","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX812dme","RR":"NO"},
    {"np":"40X8423","Model":"MX812dpe","Product":"200K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"200000","AA":"40X8423MX812dpe","RR":"NO"},
    {"np":"40X8424","Model":"MS810de","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MS810de","RR":"NO"},
    {"np":"40X8424","Model":"MS810dn","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MS810dn","RR":"NO"},
    {"np":"40X8424","Model":"MS811dn","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MS811dn","RR":"NO"},
    {"np":"40X8424","Model":"MS812de","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MS812de","RR":"NO"},
    {"np":"40X8424","Model":"MS812dn","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MS812dn","RR":"NO"},
    {"np":"40X8424","Model":"MX710dhe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX710dhe","RR":"NO"},
    {"np":"40X8424","Model":"MX711dhe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX711dhe","RR":"NO"},
    {"np":"40X8424","Model":"MX810dfe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX810dfe","RR":"NO"},
    {"np":"40X8424","Model":"MX810dme","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX810dme","RR":"NO"},
    {"np":"40X8424","Model":"MX810dpe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX810dpe","RR":"NO"},
    {"np":"40X8424","Model":"MX811dfe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX811dfe","RR":"NO"},
    {"np":"40X8424","Model":"MX811dme","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX811dme","RR":"NO"},
    {"np":"40X8424","Model":"MX811dpe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX811dpe","RR":"NO"},
    {"np":"40X8424","Model":"MX812dfe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX812dfe","RR":"NO"},
    {"np":"40X8424","Model":"MX812dme","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX812dme","RR":"NO"},
    {"np":"40X8424","Model":"MX812dpe","Product":"200K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"200000","AA":"40X8424MX812dpe","RR":"NO"},
    {"np":"40X8431","Model":"MX710dhe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX710dhe","RR":"NO"},
    {"np":"40X8431","Model":"MX711dhe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX711dhe","RR":"NO"},
    {"np":"40X8431","Model":"MX810dfe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX810dfe","RR":"NO"},
    {"np":"40X8431","Model":"MX810dme","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX810dme","RR":"NO"},
    {"np":"40X8431","Model":"MX810dpe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX810dpe","RR":"NO"},
    {"np":"40X8431","Model":"MX811dfe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX811dfe","RR":"NO"},
    {"np":"40X8431","Model":"MX811dme","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX811dme","RR":"NO"},
    {"np":"40X8431","Model":"MX811dpe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX811dpe","RR":"NO"},
    {"np":"40X8431","Model":"MX812dfe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX812dfe","RR":"NO"},
    {"np":"40X8431","Model":"MX812dme","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX812dme","RR":"NO"},
    {"np":"40X8431","Model":"MX812dpe","Product":"120K -ADF Maintenance Kit","Yield":"120000","AA":"40X8431MX812dpe","RR":"NO"},
    {"np":"40X8433","Model":"MS610dn","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X8433MS610dn","RR":"NO"},
    {"np":"40X8434","Model":"MS610de","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X8434MS610de","RR":"NO"},
    {"np":"40X8435","Model":"MS610dn","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X8435MS610dn","RR":"NO"},
    {"np":"40X8436","Model":"MS610de","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X8436MS610de","RR":"NO"},
    {"np":"40X8443","Model":"MS312dn","Product":"200K -Pick Roller for Optional Trays","Yield":"200000","AA":"40X8443MS312dn","RR":"NO"},
    {"np":"40X8443","Model":"MS315dn","Product":"200K -Pick Roller for Optional Trays","Yield":"200000","AA":"40X8443MS315dn","RR":"NO"},
    {"np":"40X8443","Model":"MS415dn","Product":"200K -Pick Roller for Optional Trays","Yield":"200000","AA":"40X8443MS415dn","RR":"NO"},
    {"np":"40X8443","Model":"MS610de","Product":"200K -Pick Roller for Optional Trays","Yield":"200000","AA":"40X8443MS610de","RR":"NO"},
    {"np":"40X8443","Model":"MS610dn","Product":"200K -Pick Roller for Optional Trays","Yield":"200000","AA":"40X8443MS610dn","RR":"NO"},
    {"np":"40X8443","Model":"MX310dn","Product":"200K -Optional Tray Pick Tire","Yield":"200000","AA":"40X8443MX310dn","RR":"NO"},
    {"np":"40X8443","Model":"MX410de","Product":"200K -Optional Tray Pick Tire","Yield":"200000","AA":"40X8443MX410de","RR":"NO"},
    {"np":"40X8443","Model":"MX511de","Product":"200K -Optional Tray Pick Tire","Yield":"200000","AA":"40X8443MX511de","RR":"NO"},
    {"np":"40X8443","Model":"MX611dfe","Product":"200K -Optional Tray Pick Tire","Yield":"200000","AA":"40X8443MX611dfe","RR":"NO"},
    {"np":"40X8443","Model":"MX611dhe","Product":"200K -Optional Tray Pick Tire","Yield":"200000","AA":"40X8443MX611dhe","RR":"NO"},
    {"np":"40X8736","Model":"CX725dhe","Product":"CX725, MX3/4/51x/61x ADF pick roll","Yield":"220000","AA":"40X8736CX725dhe","RR":"NO"},
    {"np":"40X9046","Model":"MS911de","Product":"Fuser (Universal: 110V,220V)","Yield":"720000","AA":"40X9046MS911de","RR":"NO"},
    {"np":"40X9108","Model":"CX410de","Product":"200K -ADF Separator Roller","Yield":"200000","AA":"40X9108CX410de","RR":"NO"},
    {"np":"40X9108","Model":"CX510de","Product":"200K -ADF Separator Roller","Yield":"200000","AA":"40X9108CX510de","RR":"NO"},
    {"np":"40X9108","Model":"CX510dhe","Product":"200K -ADF Separator Roller","Yield":"200000","AA":"40X9108CX510dhe","RR":"NO"},
    {"np":"40X9110","Model":"MX321adn","Product":"ADF RADF Restraint Pad","Yield":"220000","AA":"40X9110MX321adn","RR":"NO"},
    {"np":"40X9110","Model":"MX421ade","Product":"ADF RADF Restraint Pad","Yield":"220000","AA":"40X9110MX421ade","RR":"NO"},
    {"np":"40X9110","Model":"MX521ade","Product":"ADF RADF Restraint Pad","Yield":"220000","AA":"40X9110MX521ade","RR":"NO"},
    {"np":"40X9135","Model":"MX310dn","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X9135MX310dn","RR":"NO"},
    {"np":"40X9135","Model":"MX410de","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X9135MX410de","RR":"NO"},
    {"np":"40X9135","Model":"MX511de","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X9135MX511de","RR":"NO"},
    {"np":"40X9136","Model":"MX310dn","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X9136MX310dn","RR":"NO"},
    {"np":"40X9136","Model":"MX410de","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X9136MX410de","RR":"NO"},
    {"np":"40X9136","Model":"MX511de","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X9136MX511de","RR":"NO"},
    {"np":"40X9137","Model":"MX611dfe","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X9137MX611dfe","RR":"NO"},
    {"np":"40X9137","Model":"MX611dhe","Product":"200K -Fuser Maintenance Kit / 110-120V","Yield":"200000","AA":"40X9137MX611dhe","RR":"NO"},
    {"np":"40X9138","Model":"MX611dfe","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X9138MX611dfe","RR":"NO"},
    {"np":"40X9138","Model":"MX611dhe","Product":"200K -Fuser Maintenance Kit / 220V","Yield":"200000","AA":"40X9138MX611dhe","RR":"NO"},
    {"np":"40X9669","Model":"MS911de","Product":"MS/MX 91x ITB 300K Maintenance kit","Yield":"300000","AA":"40X9669MS911de","RR":"NO"},
    {"np":"40X9669","Model":"MX910de","Product":"MS/MX 91x ITB 300K Maintenance kit","Yield":"300000","AA":"40X9669MX910de","RR":"NO"},
    {"np":"40X9669","Model":"MX911dte","Product":"MS/MX 91x ITB 300K Maintenance kit","Yield":"300000","AA":"40X9669MX911dte","RR":"NO"},
    {"np":"40X9669","Model":"MX912dxe","Product":"MS/MX 91x ITB 300K Maintenance kit","Yield":"300000","AA":"40X9669MX912dxe","RR":"NO"},
    {"np":"40X9671","Model":"MS911de","Product":"720K -Fuser Maintenance Kit","Yield":"720000","AA":"40X9671MS911de","RR":"NO"},
    {"np":"40X9671","Model":"MX910de","Product":"720K -Fuser Maintenance Kit","Yield":"720000","AA":"40X9671MX910de","RR":"NO"},
    {"np":"40X9671","Model":"MX911dte","Product":"720K -Fuser Maintenance Kit","Yield":"720000","AA":"40X9671MX911dte","RR":"NO"},
    {"np":"40X9671","Model":"MX912dxe","Product":"720K -Fuser Maintenance Kit","Yield":"720000","AA":"40X9671MX912dxe","RR":"NO"},
    {"np":"40X9672","Model":"MX910de","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X9672MX910de","RR":"NO"},
    {"np":"40X9672","Model":"MX911dte","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X9672MX911dte","RR":"NO"},
    {"np":"40X9672","Model":"MX912dxe","Product":"200K -ADF Maintenance Kit","Yield":"200000","AA":"40X9672MX912dxe","RR":"NO"},
    {"np":"40X9673","Model":"MS911de","Product":"200K -Multipurpose Feeder Maintenance Kit","Yield":"200000","AA":"40X9673MS911de","RR":"NO"},
    {"np":"40X9673","Model":"MX910de","Product":"200K -Multipurpose Feeder Maintenance Kit","Yield":"200000","AA":"40X9673MX910de","RR":"NO"},
    {"np":"40X9673","Model":"MX911dte","Product":"200K -Multipurpose Feeder Maintenance Kit","Yield":"200000","AA":"40X9673MX911dte","RR":"NO"},
    {"np":"40X9673","Model":"MX912dxe","Product":"200K -Multipurpose Feeder Maintenance Kit","Yield":"200000","AA":"40X9673MX912dxe","RR":"NO"},
    {"np":"40X9704","Model":"MS911de","Product":"300K -Image Transfer Kit","Yield":"300000","AA":"40X9704MS911de","RR":"NO"},
    {"np":"40X9929","Model":"CS720de","Product":"CS720, CS725, CX725 Image Transfer Unit","Yield":"150000","AA":"40X9929CS720de","RR":"NO"},
    {"np":"40X9929","Model":"CS725de","Product":"CS720, CS725, CX725 Image Transfer Unit","Yield":"150000","AA":"40X9929CS725de","RR":"NO"},
    {"np":"40X9929","Model":"CX725dhe","Product":"CS720, CS725, CX725 Image Transfer Unit","Yield":"150000","AA":"40X9929CX725dhe","RR":"NO"},
    {"np":"40X9936","Model":"MS911de","Product":"Developer unit (600K)","Yield":"600000","AA":"40X9936MS911de","RR":"NO"},
    {"np":"41X0252","Model":"CS725de","Product":"CS72x, CX72x Fuser, Type 00, 110V-120V","Yield":"","AA":"41X0252CS725de","RR":"NO"},
    {"np":"41X0554","Model":"CS720de","Product":"CS720, CS725, CX725 Fuser Maintenance Kit 110-120V","Yield":"150000","AA":"41X0554CS720de","RR":"NO"},
    {"np":"41X0554","Model":"CS725de","Product":"CS72x CX72x Fuser Maintenance Kit, Type 00, 110V-120V","Yield":"150000","AA":"41X0554CS725de","RR":"NO"},
    {"np":"41X0554","Model":"CX725dhe","Product":"CS72x CX72x Fuser Maintenance Kit, Type 00, 110V-120V","Yield":"150000","AA":"41X0554CX725dhe","RR":"NO"},
    {"np":"41X0556","Model":"CS720de","Product":"CS720, CS725, CX725 Fuser Maintenance Kit 220-240V","Yield":"150000","AA":"41X0556CS720de","RR":"NO"},
    {"np":"41X0556","Model":"CS725de","Product":"CS720, CS725, CX725 Fuser Maintenance Kit 220-240V","Yield":"150000","AA":"41X0556CS725de","RR":"NO"},
    {"np":"41X0556","Model":"CX725dhe","Product":"CS720, CS725, CX725 Fuser Maintenance Kit 220-240V","Yield":"150000","AA":"41X0556CX725dhe","RR":"NO"},
    {"np":"41X0917","Model":"CX421adn","Product":"220K -RADF Separator Roller","Yield":"220000","AA":"41X0917CX421adn","RR":"NO"},
    {"np":"41X0917","Model":"CX522ade","Product":"220K -RADF Separator Roller","Yield":"220000","AA":"41X0917CX522ade","RR":"NO"},
    {"np":"41X0917","Model":"CX622ade","Product":"220K -RADF Separator Roller","Yield":"220000","AA":"41X0917CX622ade","RR":"NO"},
    {"np":"41X0917","Model":"CX625adhe","Product":"220K -RADF Separator Roller","Yield":"220000","AA":"41X0917CX625adhe","RR":"NO"},
    {"np":"41X0917","Model":"CX725dhe","Product":"CX725 ADF Separator Roll","Yield":"220000","AA":"41X0917CX725dhe","RR":"NO"},
    {"np":"41X0918","Model":"MS321dn","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MS321dn","RR":"NO"},
    {"np":"41X0918","Model":"MS421dn","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MS421dn","RR":"NO"},
    {"np":"41X0918","Model":"MS521dn","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MS521dn","RR":"NO"},
    {"np":"41X0918","Model":"MX321adn","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MX321adn","RR":"NO"},
    {"np":"41X0918","Model":"MX421ade","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MX421ade","RR":"NO"},
    {"np":"41X0918","Model":"MX521ade","Product":"Pick Tires - Integrated Tray Rollers","Yield":"200000","AA":"41X0918MX521ade","RR":"NO"},
    {"np":"41X0928","Model":"CS820de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit, 110V-120V","Yield":"300000","AA":"41X0928CS820de","RR":"NO"},
    {"np":"41X0928","Model":"CX820de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit, 110V-120V","Yield":"300000","AA":"41X0928CX820de","RR":"NO"},
    {"np":"41X0928","Model":"CX825de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit, 110V-120V","Yield":"300000","AA":"41X0928CX825de","RR":"NO"},
    {"np":"41X0928","Model":"CX860de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit 110V-120V","Yield":"300000","AA":"41X0928CX860de","RR":"NO"},
    {"np":"41X0929","Model":"CS820de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit 220V-240V","Yield":"300000","AA":"41X0929CS820de","RR":"NO"},
    {"np":"41X0929","Model":"CX820de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit 220V-240V","Yield":"300000","AA":"41X0929CX820de","RR":"NO"},
    {"np":"41X0929","Model":"CX825de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit 220V-240V","Yield":"300000","AA":"41X0929CX825de","RR":"NO"},
    {"np":"41X0929","Model":"CX860de","Product":"CS82x, CX82x, CX86x Combo Fuser & ITM Maintenance Kit 220V-240V","Yield":"300000","AA":"41X0929CX860de","RR":"NO"},
    {"np":"41X0931","Model":"CX820de","Product":"ADF Maintenance Kit","Yield":"200000","AA":"41X0931CX820de","RR":"NO"},
    {"np":"41X0931","Model":"CX825de","Product":"ADF Maintenance Kit","Yield":"200000","AA":"41X0931CX825de","RR":"NO"},
    {"np":"41X0931","Model":"CX860de","Product":"ADF Maintenance Kit","Yield":"200000","AA":"41X0931CX860de","RR":"NO"},
    {"np":"41X1041","Model":"CS622de","Product":"CS/CX 42x, 52x, 62x Fuser, 110V-220V","Yield":"","AA":"41X1041CS622de","RR":"NO"},
    {"np":"41X1041","Model":"CX522ade","Product":"CS/CX 42x, 52x, 62x Fuser, 110V-220V","Yield":"","AA":"41X1041CX522ade","RR":"NO"},
    {"np":"41X1041","Model":"CX622ade","Product":"CS/CX 42x, 52x, 62x Fuser, 110V-220V","Yield":"","AA":"41X1041CX622ade","RR":"NO"},
    {"np":"41X1041","Model":"CX625adhe","Product":"CS/CX 42x, 52x, 62x Fuser, 110V-220V","Yield":"","AA":"41X1041CX625adhe","RR":"NO"},
    {"np":"41X1178","Model":"MS321dn","Product":"Fuser � 110V","Yield":"200000","AA":"41X1178MS321dn","RR":"NO"}
    ,
    {"np":"41X1178","Model":"MS421dn","Product":"Fuser � 110V","Yield":"200000","AA":"41X1178MS421dn","RR":"NO"}
    ,
    {"np":"41X1178","Model":"MS521dn","Product":"Fuser, 110V","Yield":"200000","AA":"41X1178MS521dn","RR":"NO"},
    {"np":"41X1178","Model":"MS621dn","Product":"Fuser, 110V","Yield":"200000","AA":"41X1178MS621dn","RR":"NO"},
    {"np":"41X1178","Model":"MX321adn","Product":"Fuser � 110V","Yield":"200000","AA":"41X1178MX321adn","RR":"NO"}
    ,
    {"np":"41X1178","Model":"MX421ade","Product":"Fuser � 110V","Yield":"200000","AA":"41X1178MX421ade","RR":"NO"}
    ,
    {"np":"41X1178","Model":"MX521ade","Product":"Fuser � 110V","Yield":"200000","AA":"41X1178MX521ade","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MS321dn","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MS321dn","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MS421dn","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MS421dn","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MS521dn","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MS521dn","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MX321adn","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MX321adn","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MX421ade","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MX421ade","RR":"NO"}
    ,
    {"np":"41X1179","Model":"MX521ade","Product":"Fuser � 220V","Yield":"200000","AA":"41X1179MX521ade","RR":"NO"}
    ,
    {"np":"41X1197","Model":"MX321adn","Product":"MPF Pick Roller and Separator Pad","Yield":"200000","AA":"41X1197MX321adn","RR":"NO"},
    {"np":"41X1197","Model":"MX421ade","Product":"MPF Pick Roller and Separator Pad","Yield":"200000","AA":"41X1197MX421ade","RR":"NO"},
    {"np":"41X1197","Model":"MX521ade","Product":"MPF Pick Roller and Separator Pad","Yield":"200000","AA":"41X1197MX521ade","RR":"NO"},
    {"np":"41X1212","Model":"MS321dn","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MS321dn","RR":"NO"},
    {"np":"41X1212","Model":"MS421dn","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MS421dn","RR":"NO"},
    {"np":"41X1212","Model":"MS521dn","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MS521dn","RR":"NO"},
    {"np":"41X1212","Model":"MS621dn","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MS621dn","RR":"NO"},
    {"np":"41X1212","Model":"MS622de","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MS622de","RR":"NO"},
    {"np":"41X1212","Model":"MX321adn","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MX321adn","RR":"NO"},
    {"np":"41X1212","Model":"MX421ade","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MX421ade","RR":"NO"},
    {"np":"41X1212","Model":"MX521ade","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MX521ade","RR":"NO"},
    {"np":"41X1212","Model":"MX522adhe","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MX522adhe","RR":"NO"},
    {"np":"41X1212","Model":"MX622adhe","Product":"Separator Roll Assembly - Optional Tray","Yield":"200000","AA":"41X1212MX622adhe","RR":"NO"},
    {"np":"41X1225","Model":"MS621dn","Product":"MS621, MS622 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1225MS621dn","RR":"NO"},
    {"np":"41X1225","Model":"MS622de","Product":"MS621, MS622 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1225MS622de","RR":"NO"},
    {"np":"41X1225","Model":"MX522adhe","Product":"MS/MX 62x 200K Maintenance Kit, 110V","Yield":"200000","AA":"41X1225MX522adhe","RR":"NO"},
    {"np":"41X1225","Model":"MX622adhe","Product":"MX621, MX622 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1225MX622adhe","RR":"NO"},
    {"np":"41X1226","Model":"MS621dn","Product":"MS/MX 62x 200K Maintenance Kit, 220V","Yield":"200000","AA":"41X1226MS621dn","RR":"NO"},
    {"np":"41X1226","Model":"MS622de","Product":"MS/MX 62x 200K Maintenance Kit, 220V","Yield":"200000","AA":"41X1226MS622de","RR":"NO"},
    {"np":"41X1226","Model":"MX522adhe","Product":"MS/MX 62x 200K Maintenance Kit, 220V","Yield":"200000","AA":"41X1226MX522adhe","RR":"NO"},
    {"np":"41X1226","Model":"MX622adhe","Product":"MS/MX 62x 200K Maintenance Kit, 220V","Yield":"200000","AA":"41X1226MX622adhe","RR":"NO"},
    {"np":"41X1228","Model":"MS521dn","Product":"MS521 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1228MS521dn","RR":"NO"},
    {"np":"41X1228","Model":"MX521ade","Product":"MX521 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1228MX521ade","RR":"NO"},
    {"np":"41X1228","Model":"MX522adhe","Product":"MX522 Fuser Maintenance Kit, 110V","Yield":"200000","AA":"41X1228MX522adhe","RR":"NO"},
    {"np":"41X1239","Model":"MS321dn","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MS321dn","RR":"NO"},
    {"np":"41X1239","Model":"MS421dn","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MS421dn","RR":"NO"},
    {"np":"41X1239","Model":"MS521dn","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MS521dn","RR":"NO"},
    {"np":"41X1239","Model":"MS621dn","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MS621dn","RR":"NO"},
    {"np":"41X1239","Model":"MS622de","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MS622de","RR":"NO"},
    {"np":"41X1239","Model":"MX321adn","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MX321adn","RR":"NO"},
    {"np":"41X1239","Model":"MX421ade","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MX421ade","RR":"NO"},
    {"np":"41X1239","Model":"MX521ade","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MX521ade","RR":"NO"},
    {"np":"41X1239","Model":"MX522adhe","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MX522adhe","RR":"NO"},
    {"np":"41X1239","Model":"MX622adhe","Product":"Pick Roll - Optional Tray","Yield":"200000","AA":"41X1239MX622adhe","RR":"NO"},
    {"np":"41X1322","Model":"MX522adhe","Product":"ADF DADF Restraint Pad","Yield":"220000","AA":"41X1322MX522adhe","RR":"NO"},
    {"np":"41X1322","Model":"MX622adhe","Product":"ADF DADF Restraint Pad","Yield":"220000","AA":"41X1322MX622adhe","RR":"NO"},
    {"np":"41X1325","Model":"MX522adhe","Product":"ADF DADF Separator Roll","Yield":"220000","AA":"41X1325MX522adhe","RR":"NO"},
    {"np":"41X1325","Model":"MX622adhe","Product":"ADF DADF Separator Roll","Yield":"220000","AA":"41X1325MX622adhe","RR":"NO"},
    {"np":"41X1326","Model":"MX522adhe","Product":"ADF DADF Pick Roller","Yield":"220000","AA":"41X1326MX522adhe","RR":"NO"},
    {"np":"41X1326","Model":"MX622adhe","Product":"ADF DADF Pick Roller","Yield":"220000","AA":"41X1326MX622adhe","RR":"NO"},
    {"np":"41X1505","Model":"CS923de","Product":"CS923, CX922, CX923, CX924 Universal Fuser Unit (110V, 120V, 230V)","Yield":"720000","AA":"41X1505CS923de","RR":"NO"},
    {"np":"41X1505","Model":"CX922de","Product":"CS923, CX922, CX923, CX924 Universal Fuser Unit (110V, 120V, 230V)","Yield":"600000","AA":"41X1505CX922de","RR":"NO"},
    {"np":"41X1505","Model":"CX923dte","Product":"CS923, CX922, CX923, CX924 Universal Fuser Unit (110V, 120V, 230V)","Yield":"600000","AA":"41X1505CX923dte","RR":"NO"},
    {"np":"41X1592","Model":"CX920de","Product":"CX 92x ADF Kit (200K)","Yield":"200000","AA":"41X1592CX920de","RR":"NO"},
    {"np":"41X1592","Model":"CX921de","Product":"CX 92x ADF Kit (200K)","Yield":"200000","AA":"41X1592CX921de","RR":"NO"},
    {"np":"41X1592","Model":"CX922de","Product":"CX 92x ADF Kit (200K)","Yield":"200000","AA":"41X1592CX922de","RR":"NO"},
    {"np":"41X1592","Model":"CX923dte","Product":"CX 92x ADF Kit (200K)","Yield":"200000","AA":"41X1592CX923dte","RR":"NO"},
    {"np":"41X1593","Model":"CS921de","Product":"CS921, CS927, CX920, CX921, CX927 ITU Maintenance Kit","Yield":"300000","AA":"41X1593CS921de","RR":"NO"},
    {"np":"41X1593","Model":"CX920de","Product":"CS921, CS927, CX920, CX921, CX927 ITU Maintenance Kit","Yield":"300000","AA":"41X1593CX920de","RR":"NO"},
    {"np":"41X1593","Model":"CX921de","Product":"CS921, CS927, CX920, CX921, CX927 ITU Maintenance Kit","Yield":"300000","AA":"41X1593CX921de","RR":"NO"},
    {"np":"41X1594","Model":"CS921de","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CS921de","RR":"NO"},
    {"np":"41X1594","Model":"CS923de","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CS923de","RR":"NO"},
    {"np":"41X1594","Model":"CX920de","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CX920de","RR":"NO"},
    {"np":"41X1594","Model":"CX921de","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CX921de","RR":"NO"},
    {"np":"41X1594","Model":"CX922de","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CX922de","RR":"NO"},
    {"np":"41X1594","Model":"CX923dte","Product":"CS/CX 92x CMY Developer unit Maintenance Kit","Yield":"600000","AA":"41X1594CX923dte","RR":"NO"},
    {"np":"41X1598","Model":"CS921de","Product":"CS/CX 92x K Developer Unit","Yield":"600000","AA":"41X1598CS921de","RR":"NO"},
    {"np":"41X1598","Model":"CS923de","Product":"CS/CX 92x K Developer Unit","Yield":"600000","AA":"41X1598CS923de","RR":"NO"},
    {"np":"41X1598","Model":"CX920de","Product":"CS/CX 92x K Developer unit","Yield":"600000","AA":"41X1598CX920de","RR":"NO"},
    {"np":"41X1598","Model":"CX921de","Product":"CS/CX 92x K Developer unit","Yield":"600000","AA":"41X1598CX921de","RR":"NO"},
    {"np":"41X1598","Model":"CX922de","Product":"CS/CX 92x K Developer unit","Yield":"600000","AA":"41X1598CX922de","RR":"NO"},
    {"np":"41X1598","Model":"CX923dte","Product":"CS/CX 92x K Developer unit","Yield":"600000","AA":"41X1598CX923dte","RR":"NO"},
    {"np":"41X1860","Model":"CX920de","Product":"CS921, CS927, CX920, CX921, CX927 Fuser 120V","Yield":"600000","AA":"41X1860CX920de","RR":"NO"},
    {"np":"41X1860","Model":"CX921de","Product":"CS921, CS927, CX920, CX921, CX927 Fuser 120V","Yield":"600000","AA":"41X1860CX921de","RR":"NO"},
    {"np":"41X1977","Model":"CS921de","Product":"CS/CX 92x MPF kit","Yield":"200000","AA":"41X1977CS921de","RR":"NO"},
    {"np":"41X1977","Model":"CS923de","Product":"CS/CX 92x MPF kit","Yield":"200000","AA":"41X1977CS923de","RR":"NO"},
    {"np":"41X1977","Model":"CX921de","Product":"CS/CX 92x MPF kit","Yield":"200000","AA":"41X1977CX921de","RR":"NO"},
    {"np":"41X1977","Model":"CX922de","Product":"CS/CX 92x MPF kit","Yield":"200000","AA":"41X1977CX922de","RR":"NO"},
    {"np":"41X1977","Model":"CX923dte","Product":"CS/CX 92x MPF kit","Yield":"200000","AA":"41X1977CX923dte","RR":"NO"},
    {"np":"41X2090","Model":"CS923de","Product":"CS923, CX922, CX923, CX924 ITU Maintenance Kit","Yield":"300000","AA":"41X2090CS923de","RR":"NO"},
    {"np":"41X2090","Model":"CX922de","Product":"CS923, CX922, CX923, CX924 ITU Maintenance Kit","Yield":"300000","AA":"41X2090CX922de","RR":"NO"},
    {"np":"41X2090","Model":"CX923dte","Product":"CS923, CX922, CX923, CX924 ITU Maintenance Kit","Yield":"300000","AA":"41X2090CX923dte","RR":"NO"},
    {"np":"41X2096","Model":"CS421dn","Product":"125K -Maintenance Kit, 110V","Yield":"125000","AA":"41X2096CS421dn","RR":"NO"},
    {"np":"41X2096","Model":"CS521dn","Product":"CS/CX 42x, 52x, 62x Fuser Maintenance Kit, 110V-120V","Yield":"125000","AA":"41X2096CS521dn","RR":"NO"},
    {"np":"41X2096","Model":"CS622de","Product":"CS/CX 42x, 52x, 62x Fuser Maintenance Kit, 110V-120V","Yield":"125000","AA":"41X2096CS622de","RR":"NO"},
    {"np":"41X2096","Model":"CX421adn","Product":"125K -Maintenance Kit, 110V","Yield":"125000","AA":"41X2096CX421adn","RR":"NO"},
    {"np":"41X2096","Model":"CX522ade","Product":"CS/CX 42x, 52x, 62x Fuser Maintenance Kit, 110V-120V","Yield":"125000","AA":"41X2096CX522ade","RR":"NO"},
    {"np":"41X2096","Model":"CX622ade","Product":"CS/CX 42x, 52x, 62x Fuser Maintenance Kit, 110V-120V","Yield":"125000","AA":"41X2096CX622ade","RR":"NO"},
    {"np":"41X2096","Model":"CX625adhe","Product":"CS/CX 42x, 52x, 62x Fuser Maintenance Kit, 110V-120V","Yield":"125000","AA":"41X2096CX625adhe","RR":"NO"},
    {"np":"41X2097","Model":"CS421dn","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CS421dn","RR":"NO"},
    {"np":"41X2097","Model":"CS521dn","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CS521dn","RR":"NO"},
    {"np":"41X2097","Model":"CS622de","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CS622de","RR":"NO"},
    {"np":"41X2097","Model":"CX421adn","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CX421adn","RR":"NO"},
    {"np":"41X2097","Model":"CX522ade","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CX522ade","RR":"NO"},
    {"np":"41X2097","Model":"CX622ade","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CX622ade","RR":"NO"},
    {"np":"41X2097","Model":"CX625adhe","Product":"125K -Maintenance Kit, 220V","Yield":"125000","AA":"41X2097CX625adhe","RR":"NO"},
    {"np":"41X2223","Model":"MX321adn","Product":"ADF RADF Pick Roller","Yield":"220000","AA":"41X2223MX321adn","RR":"NO"},
    {"np":"41X2223","Model":"MX421ade","Product":"ADF RADF Pick Roller","Yield":"220000","AA":"41X2223MX421ade","RR":"NO"},
    {"np":"41X2223","Model":"MX521ade","Product":"ADF RADF Pick Roller","Yield":"220000","AA":"41X2223MX521ade","RR":"NO"},
    {"np":"41X2224","Model":"MX421ade","Product":"ADF RADF Separator Roll","Yield":"220000","AA":"41X2224MX421ade","RR":"NO"},
    {"np":"41X2224","Model":"MX521ade","Product":"ADF RADF Separator Roll","Yield":"220000","AA":"41X2224MX521ade","RR":"NO"},
    {"np":"41X2233","Model":"MS821dn","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MS821dn","RR":"NO"},
    {"np":"41X2233","Model":"MS823dn","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MS823dn","RR":"NO"},
    {"np":"41X2233","Model":"MS826de","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MS826de","RR":"NO"},
    {"np":"41X2233","Model":"MX722adhe","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MX722adhe","RR":"NO"},
    {"np":"41X2233","Model":"MX822ade","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MX822ade","RR":"NO"},
    {"np":"41X2233","Model":"MX826ade","Product":"225K -Return Program Fuser Maint. Kit Type 00, 110-120V Letter","Yield":"225000","AA":"41X2233MX826ade","RR":"NO"},
    {"np":"41X2234","Model":"MS821dn","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MS821dn","RR":"NO"},
    {"np":"41X2234","Model":"MS823dn","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MS823dn","RR":"NO"},
    {"np":"41X2234","Model":"MS826de","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MS826de","RR":"NO"},
    {"np":"41X2234","Model":"MX722adhe","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MX722adhe","RR":"NO"},
    {"np":"41X2234","Model":"MX822ade","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MX822ade","RR":"NO"},
    {"np":"41X2234","Model":"MX826ade","Product":"225K -Return Program Fuser Maint. Kit Type 01, 220-240V A4","Yield":"225000","AA":"41X2234MX826ade","RR":"NO"},
    {"np":"41X2236","Model":"MS821dn","Product":"MS82x, MX721, MX722, MX82x Return Program Fuser Maintenance Kit, 115V, Type 03, A4","Yield":"225000","AA":"41X2236MS821dn","RR":"NO"},
    {"np":"41X2236","Model":"MS823dn","Product":"MS82x, MX721, MX722, MX82x Return Program Fuser Maintenance Kit, 115V, Type 03, A4","Yield":"225000","AA":"41X2236MS823dn","RR":"NO"},
    {"np":"41X2236","Model":"MS826de","Product":"MS82x, MX721, MX722, MX82x Return Program Fuser Maintenance Kit, 115V, Type 03, A4","Yield":"225000","AA":"41X2236MS826de","RR":"NO"},
    {"np":"41X2236","Model":"MX721ade","Product":"MS82x, MX721, MX722, MX82x Return Program Fuser Maintenance Kit, 115V, Type 03, A4","Yield":"225000","AA":"41X2236MX721ade","RR":"NO"},
    {"np":"41X2236","Model":"MX722adhe","Product":"MS82x, MX721, MX722, MX82x Return Program Fuser Maintenance Kit, 115V, Type 03, A4","Yield":"225000","AA":"41X2236MX722adhe","RR":"NO"},
    {"np":"41X2236","Model":"MX822ade","Product":"225K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"225000","AA":"41X2236MX822ade","RR":"NO"},
    {"np":"41X2236","Model":"MX826ade","Product":"225K -Return Program Fuser Maint. Kit Type 03, 110-120V A4","Yield":"225000","AA":"41X2236MX826ade","RR":"NO"},
    {"np":"41X2237","Model":"MS821dn","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MS821dn","RR":"NO"},
    {"np":"41X2237","Model":"MS823dn","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MS823dn","RR":"NO"},
    {"np":"41X2237","Model":"MS826de","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MS826de","RR":"NO"},
    {"np":"41X2237","Model":"MX722adhe","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MX722adhe","RR":"NO"},
    {"np":"41X2237","Model":"MX822ade","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MX822ade","RR":"NO"},
    {"np":"41X2237","Model":"MX826ade","Product":"225K -Return Program Fuser Maint. Kit Type 04, 220-240V Letter","Yield":"225000","AA":"41X2237MX826ade","RR":"NO"},
    {"np":"41X2250","Model":"MS821dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MS821dn","RR":"NO"},
    {"np":"41X2250","Model":"MS823dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MS823dn","RR":"NO"},
    {"np":"41X2250","Model":"MS826de","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MS826de","RR":"NO"},
    {"np":"41X2250","Model":"MX722adhe","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MX722adhe","RR":"NO"},
    {"np":"41X2250","Model":"MX822ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MX822ade","RR":"NO"},
    {"np":"41X2250","Model":"MX826ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 32, 110-120V Letter","Yield":"400000","AA":"41X2250MX826ade","RR":"NO"},
    {"np":"41X2251","Model":"MS821dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MS821dn","RR":"NO"},
    {"np":"41X2251","Model":"MS823dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MS823dn","RR":"NO"},
    {"np":"41X2251","Model":"MS826de","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MS826de","RR":"NO"},
    {"np":"41X2251","Model":"MX722adhe","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MX722adhe","RR":"NO"},
    {"np":"41X2251","Model":"MX822ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MX822ade","RR":"NO"},
    {"np":"41X2251","Model":"MX826ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 33, 220-240V A4","Yield":"400000","AA":"41X2251MX826ade","RR":"NO"},
    {"np":"41X2252","Model":"MS821dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MS821dn","RR":"NO"},
    {"np":"41X2252","Model":"MS823dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MS823dn","RR":"NO"},
    {"np":"41X2252","Model":"MS826de","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MS826de","RR":"NO"},
    {"np":"41X2252","Model":"MX722adhe","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MX722adhe","RR":"NO"},
    {"np":"41X2252","Model":"MX822ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MX822ade","RR":"NO"},
    {"np":"41X2252","Model":"MX826ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 35, 110-120V A4","Yield":"400000","AA":"41X2252MX826ade","RR":"NO"},
    {"np":"41X2253","Model":"MS821dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MS821dn","RR":"NO"},
    {"np":"41X2253","Model":"MS823dn","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MS823dn","RR":"NO"},
    {"np":"41X2253","Model":"MS826de","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MS826de","RR":"NO"},
    {"np":"41X2253","Model":"MX722adhe","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MX722adhe","RR":"NO"},
    {"np":"41X2253","Model":"MX822ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MX822ade","RR":"NO"},
    {"np":"41X2253","Model":"MX826ade","Product":"400K -Return Program High Yield Fuser Maint. Kit Type 36, 220-240V Letter","Yield":"400000","AA":"41X2253MX826ade","RR":"NO"},
    {"np":"41X2351","Model":"MX722adhe","Product":"300K -ADF Maintenance Kit","Yield":"300000","AA":"41X2351MX722adhe","RR":"NO"},
    {"np":"41X2351","Model":"MX822ade","Product":"300K -ADF Maintenance Kit","Yield":"300000","AA":"41X2351MX822ade","RR":"NO"},
    {"np":"41X2351","Model":"MX826ade","Product":"300K -ADF Maintenance Kit","Yield":"300000","AA":"41X2351MX826ade","RR":"NO"},
    {"np":"41X2352","Model":"MS821dn","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MS821dn","RR":"NO"},
    {"np":"41X2352","Model":"MS823dn","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MS823dn","RR":"NO"},
    {"np":"41X2352","Model":"MS826de","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MS826de","RR":"NO"},
    {"np":"41X2352","Model":"MX722adhe","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MX722adhe","RR":"NO"},
    {"np":"41X2352","Model":"MX822ade","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MX822ade","RR":"NO"},
    {"np":"41X2352","Model":"MX826ade","Product":"300K -Roller Maintenance Kit","Yield":"300000","AA":"41X2352MX826ade","RR":"NO"},
    {"np":"41X2392","Model":"CS431dw","Product":"Fuser, 110V","Yield":"","AA":"41X2392CS431dw","RR":"NO"},
    {"np":"41X2392","Model":"CX431adw","Product":"Fuser, 110V","Yield":"","AA":"41X2392CX431adw","RR":"NO"},
    {"np":"41X2586","Model":"MS431dn","Product":"Fuser, 115V","Yield":"","AA":"41X2586MS431dn","RR":"NO"},
    {"np":"41X2586","Model":"MS431dw","Product":"Fuser, 115V","Yield":"","AA":"41X2586MS431dw","RR":"NO"},
    {"np":"41X2586","Model":"MX331adn","Product":"Fuser, 115V","Yield":"","AA":"41X2586MX331adn","RR":"NO"},
    {"np":"41X2586","Model":"MX431adn","Product":"Fuser, 115V","Yield":"","AA":"41X2586MX431adn","RR":"NO"},
    {"np":"41X2586","Model":"MX431adw","Product":"Fuser, 115V","Yield":"","AA":"41X2586MX431adw","RR":"NO"},
    {"np":"42C0030","Model":"CS421dn","Product":"25/23 ppm, 512 MB, 1 GHz, 2.4� color display & numpad, DC 75K","Yield":"","AA":"42C0030CS421dn","RR":"NO"}
    ,
    {"np":"42C0060","Model":"CS521dn","Product":"35/33 ppm, 1024 MB, 1 GHz, 2.4� color display & numpad, DC 85K","Yield":"","AA":"42C0060CS521dn","RR":"NO"}
    ,
    {"np":"42C0080","Model":"CS622de","Product":"L�ser, 40ppm, Pantalla t�ctil a color 4.3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 100.000p�gs, Recomendado de 10.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet,  Procesador dual core  de 1.0Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 200 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 125 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42C0080CS622de","RR":"NO"}
    ,
    {"np":"42C7330","Model":"CX421adn","Product":"25/23 ppm, 1024 MB, 1.2GHz, 2.4� color display & numpad, DC 75K","Yield":"","AA":"42C7330CX421adn","RR":"NO"}
    ,
    {"np":"42C7360","Model":"CX522ade","Product":"L�ser 35ppm, Pantalla LCD touch de 4.3 pulgadas, RADF (D�plex con recirculaci�n), Ciclo de trabajo mensual m�ximo de hasta 85.000p�gs, Recomendado de 8.500 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 200 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42C7360CX522ade","RR":"NO"}
    ,
    {"np":"42C7380","Model":"CX622ade","Product":"L�ser 40ppm, Pantalla LCD touch de 4.3 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 100.000p�gs, Recomendado de 10.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 200 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42C7380CX622ade","RR":"NO"}
    ,
    {"np":"42C7550","Model":"CS521dn","Product":"550-Sheet Tray","Yield":"","AA":"42C7550CS521dn","RR":"NO"},
    {"np":"42C7550","Model":"CS622de","Product":"550-Sheet Tray","Yield":"","AA":"42C7550CS622de","RR":"NO"},
    {"np":"42C7550","Model":"CX522ade","Product":"550-Sheet Tray","Yield":"","AA":"42C7550CX522ade","RR":"NO"},
    {"np":"42C7550","Model":"CX622ade","Product":"550-Sheet Tray","Yield":"","AA":"42C7550CX622ade","RR":"NO"},
    {"np":"42C7550","Model":"CX625adhe","Product":"550-Sheet Tray","Yield":"","AA":"42C7550CX625adhe","RR":"NO"},
    {"np":"42C7650","Model":"CS421dn","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CS421dn","RR":"NO"},
    {"np":"42C7650","Model":"CS521dn","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CS521dn","RR":"NO"},
    {"np":"42C7650","Model":"CS622de","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CS622de","RR":"NO"},
    {"np":"42C7650","Model":"CX421adn","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CX421adn","RR":"NO"},
    {"np":"42C7650","Model":"CX522ade","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CX522ade","RR":"NO"},
    {"np":"42C7650","Model":"CX622ade","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CX622ade","RR":"NO"},
    {"np":"42C7650","Model":"CX625adhe","Product":"650-Sheet Duo Tray","Yield":"","AA":"42C7650CX625adhe","RR":"NO"},
    {"np":"42C7780","Model":"CX625adhe","Product":"L�ser 40ppm, Pantalla LCD touch de 7 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 100.000p�gs, Recomendado de 10.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI (opcional), USB, Procesador dual core  de 1.2Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 200 g/m�. Bandeja multiprop�sito de 1 hoja, bandeja 1 de 250 hojas, Bandeja de salida de 150 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42C7780CX625adhe","RR":"NO"}
    ,
    {"np":"42C7880","Model":"CX625adhe","Product":"40/37 ppm, 2GB, 1.2 GHz, 7\" color touch screen & numpad, DC 100K","Yield":"","AA":"42C7880CX625adhe","RR":"NO"},
    {"np":"42K0010","Model":"CX820de","Product":"L�ser 52ppm, Pantalla LCD touch de 7 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 200.000p�gs, Recomendado de 25.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.33Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 300 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42K0010CX820de","RR":"NO"}
    ,
    {"np":"42K0040","Model":"CX825de","Product":"L�ser 55ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 250.000p�gs, Recomendado de 30.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.6Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 500 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42K0040CX825de","RR":"NO"}
    ,
    {"np":"42K0070","Model":"CX860de","Product":"L�ser 60ppm, Pantalla LCD touch de 10 pulgadas, DADF (d�plex de un solo paso), Ciclo de trabajo mensual m�ximo de hasta 350.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inal�mbrico (opcional), USB, Procesador dual core  de 1.6Ghz, Memoria de 2048MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hoja, bandeja 1 de 550 hojas, Bandeja de salida de 500 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"42K0070CX860de","RR":"NO"}
    ,
    {"np":"42K1253","Model":"CX825de","Product":"Chi / Uru - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1253CX825de","RR":"NO"},
    {"np":"42K1253","Model":"CX860de","Product":"Chi / Uru - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1253CX860de","RR":"NO"},
    {"np":"42K1254","Model":"CX825de","Product":"Arg - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1254CX825de","RR":"NO"},
    {"np":"42K1254","Model":"CX860de","Product":"Arg - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1254CX860de","RR":"NO"},
    {"np":"42K1255","Model":"CX825de","Product":"Bra - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1255CX825de","RR":"NO"},
    {"np":"42K1255","Model":"CX860de","Product":"Bra - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1255CX860de","RR":"NO"},
    {"np":"42K1267","Model":"CX825de","Product":"Per / Bol / Par - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1267CX825de","RR":"NO"},
    {"np":"42K1267","Model":"CX860de","Product":"Per / Bol / Par - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox)","Yield":"","AA":"42K1267CX860de","RR":"NO"},
    {"np":"42K1581","Model":"CX825de","Product":"Per / Bol / Par - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1581CX825de","RR":"NO"},
    {"np":"42K1581","Model":"CX860de","Product":"Per / Bol / Par - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1581CX860de","RR":"NO"},
    {"np":"42K1583","Model":"CX825de","Product":"Chi / Uru - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1583CX825de","RR":"NO"},
    {"np":"42K1583","Model":"CX860de","Product":"Chi / Uru - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1583CX860de","RR":"NO"},
    {"np":"42K1584","Model":"CX825de","Product":"Arg - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1584CX825de","RR":"NO"},
    {"np":"42K1584","Model":"CX860de","Product":"Arg - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1584CX860de","RR":"NO"},
    {"np":"42K1585","Model":"CX825de","Product":"Bra - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1585CX825de","RR":"NO"},
    {"np":"42K1585","Model":"CX860de","Product":"Bra - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1585CX860de","RR":"NO"},
    {"np":"42K1597","Model":"CX825de","Product":"LAD - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1597CX825de","RR":"NO"},
    {"np":"42K1597","Model":"CX860de","Product":"LAD - Super Finisher (Multi-position stapler+ punch with 2-bin mailbox) Tall","Yield":"","AA":"42K1597CX860de","RR":"NO"},
    {"np":"42K2000","Model":"CS820de","Product":"Inline Stapler","Yield":"","AA":"42K2000CS820de","RR":"NO"},
    {"np":"42K2000","Model":"CX820de","Product":"Inline Stapler","Yield":"","AA":"42K2000CX820de","RR":"NO"},
    {"np":"42K2300","Model":"CX825de","Product":"Inline Stapler","Yield":"","AA":"42K2300CX825de","RR":"NO"},
    {"np":"42K2300","Model":"CX860de","Product":"Inline Stapler","Yield":"","AA":"42K2300CX860de","RR":"NO"},
    {"np":"50F0Z00","Model":"MS312dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MS312dn","RR":"NO"},
    {"np":"50F0Z00","Model":"MS315dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MS315dn","RR":"NO"},
    {"np":"50F0Z00","Model":"MS415dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MS415dn","RR":"NO"},
    {"np":"50F0Z00","Model":"MS610de","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MS610de","RR":"NO"},
    {"np":"50F0Z00","Model":"MS610dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MS610dn","RR":"NO"},
    {"np":"50F0Z00","Model":"MX310dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MX310dn","RR":"NO"},
    {"np":"50F0Z00","Model":"MX410de","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MX410de","RR":"NO"},
    {"np":"50F0Z00","Model":"MX511de","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MX511de","RR":"NO"},
    {"np":"50F0Z00","Model":"MX611dfe","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MX611dfe","RR":"NO"},
    {"np":"50F0Z00","Model":"MX611dhe","Product":"60K -Imaging Unit","Yield":"60000","AA":"50F0Z00MX611dhe","RR":"NO"},
    {"np":"50F4000","Model":"MS312dn","Product":"1.5K -Standard Yield Toner","Yield":"1500","AA":"50F4000MS312dn","RR":"NO"},
    {"np":"50F4000","Model":"MS315dn","Product":"1.5K -Standard Yield Toner","Yield":"1500","AA":"50F4000MS315dn","RR":"NO"},
    {"np":"50F4000","Model":"MS415dn","Product":"1.5K -Standard Yield Toner","Yield":"1500","AA":"50F4000MS415dn","RR":"NO"},
    {"np":"50F4000","Model":"MS610de","Product":"1.5K -Standard Yield Toner","Yield":"1500","AA":"50F4000MS610de","RR":"NO"},
    {"np":"50F4000","Model":"MS610dn","Product":"1.5K -Standard Yield Toner","Yield":"1500","AA":"50F4000MS610dn","RR":"NO"},
    {"np":"50F4H00","Model":"MS312dn","Product":"5K -High Yield Toner","Yield":"5000","AA":"50F4H00MS312dn","RR":"NO"},
    {"np":"50F4H00","Model":"MS315dn","Product":"5K -High Yield Toner","Yield":"5000","AA":"50F4H00MS315dn","RR":"NO"},
    {"np":"50F4H00","Model":"MS415dn","Product":"5K -High Yield Toner","Yield":"5000","AA":"50F4H00MS415dn","RR":"NO"},
    {"np":"50F4H00","Model":"MS610de","Product":"5K -High Yield Toner","Yield":"5000","AA":"50F4H00MS610de","RR":"NO"},
    {"np":"50F4H00","Model":"MS610dn","Product":"5K -High Yield Toner","Yield":"5000","AA":"50F4H00MS610dn","RR":"NO"},
    {"np":"50F4U00","Model":"MS610de","Product":"20K -Ultra High Yield Toner","Yield":"20000","AA":"50F4U00MS610de","RR":"NO"},
    {"np":"50F4U00","Model":"MS610dn","Product":"20K -Ultra High Yield Toner","Yield":"20000","AA":"50F4U00MS610dn","RR":"NO"},
    {"np":"50F4X00","Model":"MS415dn","Product":"10K -Extra High Yield Toner","Yield":"10000","AA":"50F4X00MS415dn","RR":"NO"},
    {"np":"50F4X00","Model":"MS610de","Product":"10K -Extra High Yield Toner","Yield":"10000","AA":"50F4X00MS610de","RR":"NO"},
    {"np":"50F4X00","Model":"MS610dn","Product":"10K -Extra High Yield Toner","Yield":"10000","AA":"50F4X00MS610dn","RR":"NO"},
    {"np":"50G0100","Model":"MS821dn","Product":"L�ser, 55ppm, Pantalla LCD color de 2.4 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 250.000p�gs, Recomendado de 50.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, Inalambrica (opcional), USB 2.0, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 550 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"50G0100MS821dn","RR":"NO"}
    ,
    {"np":"50G0200","Model":"MS823dn","Product":"L�ser, 65ppm, Pantalla LCD color de 2.4 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 300.000p�gs, Recomendado de 75.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, WIFI (opcional), USB 2.0, Procesador dual core  de 1.0Ghz, Memoria de 512 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 550 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"50G0200MS823dn","RR":"NO"}
    ,
    {"np":"50G0310","Model":"MS826de","Product":"70/66 ppm, 1024 MB, 1 GHz, 4.3\" color touch screen & numpad, DC 350K","Yield":"","AA":"50G0310MS826de","RR":"NO"},
    {"np":"50G0330","Model":"MS826de","Product":"L�ser, 70ppm, Pantalla t�ctil color de 4,3 pulgadas, Ciclo de trabajo mensual m�ximo de hasta 350.000p�gs, Recomendado de 100.000 p�ginas. Conectividad 10/100/1000Base-TX Ethernet, USB 2.0, Procesador dual core  de 1.0Ghz, Memoria de 1024 MB. Gramaje admitido de 60 a 176 g/m�. Bandeja multiprop�sito de 100 hojas, bandeja 1 de 550 hojas, Bandeja de salida de 550 hojas, Garant�a 1 a�o de f�brica.","Yield":"","AA":"50G0330MS826de","RR":"NO"}
    ,
    {"np":"50G0800","Model":"MS821dn","Product":"250-Sheet Tray","Yield":"","AA":"50G0800MS821dn","RR":"NO"},
    {"np":"50G0800","Model":"MS823dn","Product":"250-Sheet Tray","Yield":"","AA":"50G0800MS823dn","RR":"NO"},
    {"np":"50G0800","Model":"MS826de","Product":"250-Sheet Tray","Yield":"","AA":"50G0800MS826de","RR":"NO"},
    {"np":"50G0800","Model":"MX722adhe","Product":"250-Sheet Tray","Yield":"","AA":"50G0800MX722adhe","RR":"NO"},
    {"np":"50G0802","Model":"MS821dn","Product":"550- Sheet Tray","Yield":"","AA":"50G0802MS821dn","RR":"NO"},
    {"np":"50G0802","Model":"MS823dn","Product":"550- Sheet Tray","Yield":"","AA":"50G0802MS823dn","RR":"NO"},
    {"np":"50G0802","Model":"MS826de","Product":"550- Sheet Tray","Yield":"","AA":"50G0802MS826de","RR":"NO"},
    {"np":"50G0802","Model":"MX722adhe","Product":"550- Sheet Tray","Yield":"","AA":"50G0802MX722adhe","RR":"NO"},
    {"np":"50G0804","Model":"MS821dn","Product":"2100-Sheet Tray","Yield":"","AA":"50G0804MS821dn","RR":"NO"},
    {"np":"50G0804","Model":"MS823dn","Product":"2100-Sheet Tray","Yield":"","AA":"50G0804MS823dn","RR":"NO"},
    {"np":"50G0804","Model":"MS826de","Product":"2100-Sheet Tray","Yield":"","AA":"50G0804MS826de","RR":"NO"},
    {"np":"50G0804","Model":"MX722adhe","Product":"2100-Sheet Tray","Yield":"","AA":"50G0804MX722adhe","RR":"NO"},
    {"np":"50G0820","Model":"MS821dn","Product":"250-Sheet Lockable Tray","Yield":"","AA":"50G0820MS821dn","RR":"NO"},
    {"np":"50G0820","Model":"MS823dn","Product":"250-Sheet Lockable Tray","Yield":"","AA":"50G0820MS823dn","RR":"NO"},
    {"np":"50G0820","Model":"MS826de","Product":"250-Sheet Lockable Tray","Yield":"","AA":"50G0820MS826de","RR":"NO"},
    {"np":"50G0820","Model":"MX722adhe","Product":"250-Sheet Lockable Tray","Yield":"","AA":"50G0820MX722adhe","RR":"NO"},
    {"np":"50G0822","Model":"MS821dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"50G0822MS821dn","RR":"NO"},
    {"np":"50G0822","Model":"MS823dn","Product":"550-Sheet Lockable Tray","Yield":"","AA":"50G0822MS823dn","RR":"NO"},
    {"np":"50G0822","Model":"MS826de","Product":"550-Sheet Lockable Tray","Yield":"","AA":"50G0822MS826de","RR":"NO"},
    {"np":"50G0822","Model":"MX722adhe","Product":"550-Sheet Lockable Tray","Yield":"","AA":"50G0822MX722adhe","RR":"NO"},
    {"np":"50G0849","Model":"MS821dn","Product":"Staple, Hole Punch Finisher","Yield":"","AA":"50G0849MS821dn","RR":"NO"},
    {"np":"50G0849","Model":"MS823dn","Product":"Staple, Hole Punch Finisher","Yield":"","AA":"50G0849MS823dn","RR":"NO"},
    {"np":"50G0849","Model":"MS826de","Product":"Staple, Hole Punch Finisher","Yield":"","AA":"50G0849MS826de","RR":"NO"},
    {"np":"50G0849","Model":"MX822ade","Product":"Staple, Hole Punch Finisher","Yield":"","AA":"50G0849MX822ade","RR":"NO"},
    {"np":"50G0849","Model":"MX826ade","Product":"Staple, Hole Punch Finisher","Yield":"","AA":"50G0849MX826ade","RR":"NO"},
    {"np":"50G0850","Model":"MS821dn","Product":"Staple Finisher","Yield":"","AA":"50G0850MS821dn","RR":"NO"},
    {"np":"50G0850","Model":"MS823dn","Product":"Staple Finisher","Yield":"","AA":"50G0850MS823dn","RR":"NO"},
    {"np":"50G0850","Model":"MS826de","Product":"Staple Finisher","Yield":"","AA":"50G0850MS826de","RR":"NO"},
    {"np":"50G0850","Model":"MX822ade","Product":"Staple Finisher","Yield":"","AA":"50G0850MX822ade","RR":"NO"},
    {"np":"50G0850","Model":"MX826ade","Product":"Staple Finisher","Yield":"","AA":"50G0850MX826ade","RR":"NO"},
    {"np":"50G0851","Model":"MS821dn","Product":"Output Expander","Yield":"","AA":"50G0851MS821dn","RR":"NO"},
    {"np":"50G0851","Model":"MS823dn","Product":"Output Expander","Yield":"","AA":"50G0851MS823dn","RR":"NO"},
    {"np":"50G0851","Model":"MS826de","Product":"Output Expander","Yield":"","AA":"50G0851MS826de","RR":"NO"},
    {"np":"50G0851","Model":"MX822ade","Product":"Output Expander","Yield":"","AA":"50G0851MX822ade","RR":"NO"},
    {"np":"50G0851","Model":"MX826ade","Product":"Output Expander","Yield":"","AA":"50G0851MX826ade","RR":"NO"},
    {"np":"50G0852","Model":"MS821dn","Product":"4-Bin Mailbox","Yield":"","AA":"50G0852MS821dn","RR":"NO"},
    {"np":"50G0852","Model":"MS823dn","Product":"4-Bin Mailbox","Yield":"","AA":"50G0852MS823dn","RR":"NO"},
    {"np":"50G0852","Model":"MS826de","Product":"4-Bin Mailbox","Yield":"","AA":"50G0852MS826de","RR":"NO"},
    {"np":"50G0852","Model":"MX822ade","Product":"4-Bin Mailbox","Yield":"","AA":"50G0852MX822ade","RR":"NO"},
    {"np":"50G0852","Model":"MX826ade","Product":"4-Bin Mailbox","Yield":"","AA":"50G0852MX826ade","RR":"NO"},
    {"np":"50G0853","Model":"MS821dn","Product":"High Capacity Output Expander","Yield":"","AA":"50G0853MS821dn","RR":"NO"},
    {"np":"50G0853","Model":"MS823dn","Product":"High Capacity Output Expander","Yield":"","AA":"50G0853MS823dn","RR":"NO"},
    {"np":"50G0853","Model":"MS826de","Product":"High Capacity Output Expander","Yield":"","AA":"50G0853MS826de","RR":"NO"},
    {"np":"50G0854","Model":"MS821dn","Product":"Spacer","Yield":"","AA":"50G0854MS821dn","RR":"NO"},
    {"np":"50G0854","Model":"MS823dn","Product":"Spacer","Yield":"","AA":"50G0854MS823dn","RR":"NO"},
    {"np":"50G0854","Model":"MS826de","Product":"Spacer","Yield":"","AA":"50G0854MS826de","RR":"NO"},
    {"np":"50G0854","Model":"MX722adhe","Product":"Spacer","Yield":"","AA":"50G0854MX722adhe","RR":"NO"},
    {"np":"50G0855","Model":"MS821dn","Product":"Caster Base","Yield":"","AA":"50G0855MS821dn","RR":"NO"},
    {"np":"50G0855","Model":"MS823dn","Product":"Caster Base","Yield":"","AA":"50G0855MS823dn","RR":"NO"},
    {"np":"50G0855","Model":"MS826de","Product":"Caster Base","Yield":"","AA":"50G0855MS826de","RR":"NO"},
    {"np":"50G0855","Model":"MX722adhe","Product":"Caster Base","Yield":"","AA":"50G0855MX722adhe","RR":"NO"},
    {"np":"52D0Z00","Model":"MS810de","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MS810de","RR":"NO"},
    {"np":"52D0Z00","Model":"MS810dn","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MS810dn","RR":"NO"},
    {"np":"52D0Z00","Model":"MS811dn","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MS811dn","RR":"NO"},
    {"np":"52D0Z00","Model":"MS812de","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MS812de","RR":"NO"},
    {"np":"52D0Z00","Model":"MS812dn","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MS812dn","RR":"NO"},
    {"np":"52D0Z00","Model":"MX710dhe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX710dhe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX711dhe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX711dhe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX810dfe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX810dfe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX810dme","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX810dme","RR":"NO"},
    {"np":"52D0Z00","Model":"MX810dpe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX810dpe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX811dfe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX811dfe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX811dme","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX811dme","RR":"NO"},
    {"np":"52D0Z00","Model":"MX811dpe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX811dpe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX812dfe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX812dfe","RR":"NO"},
    {"np":"52D0Z00","Model":"MX812dme","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX812dme","RR":"NO"},
    {"np":"52D0Z00","Model":"MX812dpe","Product":"100K -Imaging Unit","Yield":"100000","AA":"52D0Z00MX812dpe","RR":"NO"},
    {"np":"52D4H00","Model":"MS810de","Product":"25K -High Yield Toner","Yield":"25000","AA":"52D4H00MS810de","RR":"NO"},
    {"np":"52D4H00","Model":"MS810dn","Product":"25K -High Yield Toner","Yield":"25000","AA":"52D4H00MS810dn","RR":"NO"},
    {"np":"52D4H00","Model":"MS811dn","Product":"25K -High Yield Toner","Yield":"25000","AA":"52D4H00MS811dn","RR":"NO"},
    {"np":"52D4H00","Model":"MS812de","Product":"25K -High Yield Toner","Yield":"25000","AA":"52D4H00MS812de","RR":"NO"},
    {"np":"52D4H00","Model":"MS812dn","Product":"25K -High Yield Toner","Yield":"25000","AA":"52D4H00MS812dn","RR":"NO"},
    {"np":"52D4X00","Model":"MS811dn","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"52D4X00MS811dn","RR":"NO"},
    {"np":"52D4X00","Model":"MS812de","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"52D4X00MS812de","RR":"NO"},
    {"np":"52D4X00","Model":"MS812dn","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"52D4X00MS812dn","RR":"NO"},
    {"np":"54G0H00","Model":"MS911de","Product":"32.5K - Black High Yield Toner Cartridge","Yield":"32500","AA":"54G0H00MS911de","RR":"NO"},
    {"np":"54G0P00","Model":"MS911de","Product":"125K - Photoconductor Unit","Yield":"125000","AA":"54G0P00MS911de","RR":"NO"},
    {"np":"54G0P00","Model":"MX910de","Product":"125K - Photoconductor Unit","Yield":"125000","AA":"54G0P00MX910de","RR":"NO"},
    {"np":"54G0P00","Model":"MX911dte","Product":"125K - Photoconductor Unit","Yield":"125000","AA":"54G0P00MX911dte","RR":"NO"},
    {"np":"54G0P00","Model":"MX912dxe","Product":"125K - Photoconductor Unit","Yield":"125000","AA":"54G0P00MX912dxe","RR":"NO"},
    {"np":"54G0W00","Model":"CS921de","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CS921de","RR":"NO"},
    {"np":"54G0W00","Model":"CS923de","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CS923de","RR":"NO"},
    {"np":"54G0W00","Model":"CX920de","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CX920de","RR":"NO"},
    {"np":"54G0W00","Model":"CX921de","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CX921de","RR":"NO"},
    {"np":"54G0W00","Model":"CX922de","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CX922de","RR":"NO"},
    {"np":"54G0W00","Model":"CX923dte","Product":"50K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00CX923dte","RR":"NO"},
    {"np":"54G0W00","Model":"MS911de","Product":"90K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00MS911de","RR":"NO"},
    {"np":"54G0W00","Model":"MX910de","Product":"90K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00MX910de","RR":"NO"},
    {"np":"54G0W00","Model":"MX911dte","Product":"90K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00MX911dte","RR":"NO"},
    {"np":"54G0W00","Model":"MX912dxe","Product":"90K - Waste Toner Bottle","Yield":"90000","AA":"54G0W00MX912dxe","RR":"NO"},
    {"np":"55B0ZA0","Model":"MS331dn","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MS331dn","RR":"NO"},
    {"np":"55B0ZA0","Model":"MS431dn","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MS431dn","RR":"NO"},
    {"np":"55B0ZA0","Model":"MS431dw","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MS431dw","RR":"NO"},
    {"np":"55B0ZA0","Model":"MX331adn","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MX331adn","RR":"NO"},
    {"np":"55B0ZA0","Model":"MX431adn","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MX431adn","RR":"NO"},
    {"np":"55B0ZA0","Model":"MX431adw","Product":"40K -Black PC","Yield":"40000","AA":"55B0ZA0MX431adw","RR":"NO"},
    {"np":"55B4000","Model":"MS331dn","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MS331dn","RR":"NO"},
    {"np":"55B4000","Model":"MS431dn","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MS431dn","RR":"NO"},
    {"np":"55B4000","Model":"MS431dw","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MS431dw","RR":"NO"},
    {"np":"55B4000","Model":"MX331adn","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MX331adn","RR":"NO"},
    {"np":"55B4000","Model":"MX431adn","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MX431adn","RR":"NO"},
    {"np":"55B4000","Model":"MX431adw","Product":"3K -Standard Yield Toner","Yield":"3000","AA":"55B4000MX431adw","RR":"NO"},
    {"np":"55B4H00","Model":"MS331dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MS331dn","RR":"NO"},
    {"np":"55B4H00","Model":"MS431dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MS431dn","RR":"NO"},
    {"np":"55B4H00","Model":"MS431dw","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MS431dw","RR":"NO"},
    {"np":"55B4H00","Model":"MX331adn","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MX331adn","RR":"NO"},
    {"np":"55B4H00","Model":"MX431adn","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MX431adn","RR":"NO"},
    {"np":"55B4H00","Model":"MX431adw","Product":"15K -High Yield Toner","Yield":"15000","AA":"55B4H00MX431adw","RR":"NO"},
    {"np":"55B4X00","Model":"MS431dn","Product":"20K -Extra High Yield Toner - Regular","Yield":"20000","AA":"55B4X00MS431dn","RR":"NO"},
    {"np":"55B4X00","Model":"MS431dw","Product":"20K -Extra High Yield Toner - Regular","Yield":"20000","AA":"55B4X00MS431dw","RR":"NO"},
    {"np":"55B4X00","Model":"MX431adn","Product":"20K -Extra High Yield Toner - Regular","Yield":"20000","AA":"55B4X00MX431adn","RR":"NO"},
    {"np":"55B4X00","Model":"MX431adw","Product":"20K -Extra High Yield Toner - Regular","Yield":"20000","AA":"55B4X00MX431adw","RR":"NO"},
    {"np":"56F0Z00","Model":"MS321dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MS321dn","RR":"NO"},
    {"np":"56F0Z00","Model":"MS421dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MS421dn","RR":"NO"},
    {"np":"56F0Z00","Model":"MS521dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MS521dn","RR":"NO"},
    {"np":"56F0Z00","Model":"MS621dn","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MS621dn","RR":"NO"},
    {"np":"56F0Z00","Model":"MS622de","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MS622de","RR":"NO"},
    {"np":"56F0Z00","Model":"MX321adn","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MX321adn","RR":"NO"},
    {"np":"56F0Z00","Model":"MX421ade","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MX421ade","RR":"NO"},
    {"np":"56F0Z00","Model":"MX521ade","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MX521ade","RR":"NO"},
    {"np":"56F0Z00","Model":"MX522adhe","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MX522adhe","RR":"NO"},
    {"np":"56F0Z00","Model":"MX622adhe","Product":"60K -Imaging Unit","Yield":"60000","AA":"56F0Z00MX622adhe","RR":"NO"},
    {"np":"56F4000","Model":"MS321dn","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MS321dn","RR":"NO"},
    {"np":"56F4000","Model":"MS421dn","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MS421dn","RR":"NO"},
    {"np":"56F4000","Model":"MS521dn","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MS521dn","RR":"NO"},
    {"np":"56F4000","Model":"MS621dn","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MS621dn","RR":"NO"},
    {"np":"56F4000","Model":"MS622de","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MS622de","RR":"NO"},
    {"np":"56F4000","Model":"MX321adn","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MX321adn","RR":"NO"},
    {"np":"56F4000","Model":"MX421ade","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MX421ade","RR":"NO"},
    {"np":"56F4000","Model":"MX521ade","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MX521ade","RR":"NO"},
    {"np":"56F4000","Model":"MX522adhe","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MX522adhe","RR":"NO"},
    {"np":"56F4000","Model":"MX622adhe","Product":"6K -Standard Yield Toner","Yield":"6000","AA":"56F4000MX622adhe","RR":"NO"},
    {"np":"56F4H00","Model":"MS321dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MS321dn","RR":"NO"},
    {"np":"56F4H00","Model":"MS421dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MS421dn","RR":"NO"},
    {"np":"56F4H00","Model":"MS521dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MS521dn","RR":"NO"},
    {"np":"56F4H00","Model":"MS621dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MS621dn","RR":"NO"},
    {"np":"56F4H00","Model":"MS622de","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MS622de","RR":"NO"},
    {"np":"56F4H00","Model":"MX321adn","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MX321adn","RR":"NO"},
    {"np":"56F4H00","Model":"MX421ade","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MX421ade","RR":"NO"},
    {"np":"56F4H00","Model":"MX521ade","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MX521ade","RR":"NO"},
    {"np":"56F4H00","Model":"MX522adhe","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MX522adhe","RR":"NO"},
    {"np":"56F4H00","Model":"MX622adhe","Product":"15K -High Yield Toner","Yield":"15000","AA":"56F4H00MX622adhe","RR":"NO"},
    {"np":"56F4U00","Model":"MS521dn","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MS521dn","RR":"NO"},
    {"np":"56F4U00","Model":"MS621dn","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MS621dn","RR":"NO"},
    {"np":"56F4U00","Model":"MS622de","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MS622de","RR":"NO"},
    {"np":"56F4U00","Model":"MX521ade","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MX521ade","RR":"NO"},
    {"np":"56F4U00","Model":"MX522adhe","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MX522adhe","RR":"NO"},
    {"np":"56F4U00","Model":"MX622adhe","Product":"25K - Ultra High Yield Toner","Yield":"25000","AA":"56F4U00MX622adhe","RR":"NO"},
    {"np":"56F4X00","Model":"MS421dn","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MS421dn","RR":"NO"},
    {"np":"56F4X00","Model":"MS521dn","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MS521dn","RR":"NO"},
    {"np":"56F4X00","Model":"MS621dn","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MS621dn","RR":"NO"},
    {"np":"56F4X00","Model":"MS622de","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MS622de","RR":"NO"},
    {"np":"56F4X00","Model":"MX421ade","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MX421ade","RR":"NO"},
    {"np":"56F4X00","Model":"MX521ade","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MX521ade","RR":"NO"},
    {"np":"56F4X00","Model":"MX522adhe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MX522adhe","RR":"NO"},
    {"np":"56F4X00","Model":"MX622adhe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"56F4X00MX622adhe","RR":"NO"},
    {"np":"57X0070","Model":"MX810dfe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX810dfe","RR":"NO"},
    {"np":"57X0070","Model":"MX810dme","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX810dme","RR":"NO"},
    {"np":"57X0070","Model":"MX810dpe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX810dpe","RR":"NO"},
    {"np":"57X0070","Model":"MX811dfe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX811dfe","RR":"NO"},
    {"np":"57X0070","Model":"MX811dme","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX811dme","RR":"NO"},
    {"np":"57X0070","Model":"MX811dpe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX811dpe","RR":"NO"},
    {"np":"57X0070","Model":"MX812dfe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX812dfe","RR":"NO"},
    {"np":"57X0070","Model":"MX812dme","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX812dme","RR":"NO"},
    {"np":"57X0070","Model":"MX812dpe","Product":"Lexmark Removable HDD Kit","Yield":"","AA":"57X0070MX812dpe","RR":"NO"},
    {"np":"57X0085","Model":"CX860de","Product":"Lexmark Secure Element","Yield":"","AA":"57X0085CX860de","RR":"NO"},
    {"np":"57X0204","Model":"CX522ade","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204CX522ade","RR":"NO"},
    {"np":"57X0204","Model":"CX622ade","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204CX622ade","RR":"NO"},
    {"np":"57X0204","Model":"CX625adhe","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204CX625adhe","RR":"NO"},
    {"np":"57X0204","Model":"MS821dn","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MS821dn","RR":"NO"},
    {"np":"57X0204","Model":"MS823dn","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MS823dn","RR":"NO"},
    {"np":"57X0204","Model":"MS826de","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MS826de","RR":"NO"},
    {"np":"57X0204","Model":"MX722adhe","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MX722adhe","RR":"NO"},
    {"np":"57X0204","Model":"MX822ade","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MX822ade","RR":"NO"},
    {"np":"57X0204","Model":"MX826ade","Product":"Memory Expansion (4GB) DDR3","Yield":"","AA":"57X0204MX826ade","RR":"NO"},
    {"np":"57X0300","Model":"CS622de","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300CS622de","RR":"NO"},
    {"np":"57X0300","Model":"CS820de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300CS820de","RR":"NO"},
    {"np":"57X0300","Model":"CX622ade","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300CX622ade","RR":"NO"},
    {"np":"57X0300","Model":"CX625adhe","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300CX625adhe","RR":"NO"},
    {"np":"57X0300","Model":"CX820de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300CX820de","RR":"NO"},
    {"np":"57X0300","Model":"CX825de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300CX825de","RR":"NO"},
    {"np":"57X0300","Model":"CX860de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300CX860de","RR":"NO"},
    {"np":"57X0300","Model":"MS610de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS610de","RR":"NO"},
    {"np":"57X0300","Model":"MS622de","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS622de","RR":"NO"},
    {"np":"57X0300","Model":"MS810de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS810de","RR":"NO"},
    {"np":"57X0300","Model":"MS812de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS812de","RR":"NO"},
    {"np":"57X0300","Model":"MS826de","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS826de","RR":"NO"},
    {"np":"57X0300","Model":"MS911de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MS911de","RR":"NO"},
    {"np":"57X0300","Model":"MX410de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX410de","RR":"NO"},
    {"np":"57X0300","Model":"MX511de","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX511de","RR":"NO"},
    {"np":"57X0300","Model":"MX611dfe","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX611dfe","RR":"NO"},
    {"np":"57X0300","Model":"MX611dhe","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX611dhe","RR":"NO"},
    {"np":"57X0300","Model":"MX710dhe","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX710dhe","RR":"NO"},
    {"np":"57X0300","Model":"MX711dhe","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX711dhe","RR":"NO"},
    {"np":"57X0300","Model":"MX722adhe","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX722adhe","RR":"NO"},
    {"np":"57X0300","Model":"MX810dfe","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX810dfe","RR":"NO"},
    {"np":"57X0300","Model":"MX810dme","Product":"Lexmark Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX810dme","RR":"NO"},
    {"np":"57X0300","Model":"MX822ade","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX822ade","RR":"NO"},
    {"np":"57X0300","Model":"MX826ade","Product":"Contact Reader/Authentication Device","Yield":"","AA":"57X0300MX826ade","RR":"NO"},
    {"np":"57X0301","Model":"CS510de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS510de","RR":"NO"},
    {"np":"57X0301","Model":"CS622de","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS622de","RR":"NO"},
    {"np":"57X0301","Model":"CS720de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS720de","RR":"NO"},
    {"np":"57X0301","Model":"CS725de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS725de","RR":"NO"},
    {"np":"57X0301","Model":"CS820de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS820de","RR":"NO"},
    {"np":"57X0301","Model":"CS921de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS921de","RR":"NO"},
    {"np":"57X0301","Model":"CS923de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CS923de","RR":"NO"},
    {"np":"57X0301","Model":"CX510de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX510de","RR":"NO"},
    {"np":"57X0301","Model":"CX510dhe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX510dhe","RR":"NO"},
    {"np":"57X0301","Model":"CX622ade","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX622ade","RR":"NO"},
    {"np":"57X0301","Model":"CX625adhe","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX625adhe","RR":"NO"},
    {"np":"57X0301","Model":"CX725dhe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX725dhe","RR":"NO"},
    {"np":"57X0301","Model":"CX820de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX820de","RR":"NO"},
    {"np":"57X0301","Model":"CX825de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX825de","RR":"NO"},
    {"np":"57X0301","Model":"CX860de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX860de","RR":"NO"},
    {"np":"57X0301","Model":"CX921de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX921de","RR":"NO"},
    {"np":"57X0301","Model":"CX922de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX922de","RR":"NO"},
    {"np":"57X0301","Model":"CX923dte","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301CX923dte","RR":"NO"},
    {"np":"57X0301","Model":"MS610de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS610de","RR":"NO"},
    {"np":"57X0301","Model":"MS622de","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS622de","RR":"NO"},
    {"np":"57X0301","Model":"MS810de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS810de","RR":"NO"},
    {"np":"57X0301","Model":"MS812de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS812de","RR":"NO"},
    {"np":"57X0301","Model":"MS826de","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS826de","RR":"NO"},
    {"np":"57X0301","Model":"MS911de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MS911de","RR":"NO"},
    {"np":"57X0301","Model":"MX410de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX410de","RR":"NO"},
    {"np":"57X0301","Model":"MX511de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX511de","RR":"NO"},
    {"np":"57X0301","Model":"MX611dfe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX611dfe","RR":"NO"},
    {"np":"57X0301","Model":"MX611dhe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX611dhe","RR":"NO"},
    {"np":"57X0301","Model":"MX710dhe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX710dhe","RR":"NO"},
    {"np":"57X0301","Model":"MX711dhe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX711dhe","RR":"NO"},
    {"np":"57X0301","Model":"MX722adhe","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX722adhe","RR":"NO"},
    {"np":"57X0301","Model":"MX810dfe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX810dfe","RR":"NO"},
    {"np":"57X0301","Model":"MX810dme","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX810dme","RR":"NO"},
    {"np":"57X0301","Model":"MX810dpe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX810dpe","RR":"NO"},
    {"np":"57X0301","Model":"MX811dfe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX811dfe","RR":"NO"},
    {"np":"57X0301","Model":"MX811dme","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX811dme","RR":"NO"},
    {"np":"57X0301","Model":"MX811dpe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX811dpe","RR":"NO"},
    {"np":"57X0301","Model":"MX812dfe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX812dfe","RR":"NO"},
    {"np":"57X0301","Model":"MX812dme","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX812dme","RR":"NO"},
    {"np":"57X0301","Model":"MX812dpe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX812dpe","RR":"NO"},
    {"np":"57X0301","Model":"MX822ade","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX822ade","RR":"NO"},
    {"np":"57X0301","Model":"MX826ade","Product":"Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX826ade","RR":"NO"},
    {"np":"57X0301","Model":"MX910de","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX910de","RR":"NO"},
    {"np":"57X0301","Model":"MX911dte","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX911dte","RR":"NO"},
    {"np":"57X0301","Model":"MX912dxe","Product":"Lexmark Contactless Reader/Authentication Device","Yield":"","AA":"57X0301MX912dxe","RR":"NO"},
    {"np":"57X7000","Model":"MS911de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7000MS911de","RR":"NO"},
    {"np":"57X7000","Model":"MX910de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7000MX910de","RR":"NO"},
    {"np":"57X7000","Model":"MX911dte","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7000MX911dte","RR":"NO"},
    {"np":"57X7000","Model":"MX912dxe","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7000MX912dxe","RR":"NO"},
    {"np":"57X7004","Model":"MS911de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7004MS911de","RR":"NO"},
    {"np":"57X7004","Model":"MX910de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7004MX910de","RR":"NO"},
    {"np":"57X7004","Model":"MX911dte","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7004MX911dte","RR":"NO"},
    {"np":"57X7004","Model":"MX912dxe","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7004MX912dxe","RR":"NO"},
    {"np":"57X7025","Model":"CX510de","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025CX510de","RR":"NO"},
    {"np":"57X7025","Model":"CX510dhe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025CX510dhe","RR":"NO"},
    {"np":"57X7025","Model":"MS812de","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MS812de","RR":"NO"},
    {"np":"57X7025","Model":"MX611dfe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX611dfe","RR":"NO"},
    {"np":"57X7025","Model":"MX611dhe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX611dhe","RR":"NO"},
    {"np":"57X7025","Model":"MX710dhe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX710dhe","RR":"NO"},
    {"np":"57X7025","Model":"MX711dhe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX711dhe","RR":"NO"},
    {"np":"57X7025","Model":"MX810dfe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX810dfe","RR":"NO"},
    {"np":"57X7025","Model":"MX810dme","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX810dme","RR":"NO"},
    {"np":"57X7025","Model":"MX810dpe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX810dpe","RR":"NO"},
    {"np":"57X7025","Model":"MX811dfe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX811dfe","RR":"NO"},
    {"np":"57X7025","Model":"MX811dme","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX811dme","RR":"NO"},
    {"np":"57X7025","Model":"MX811dpe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX811dpe","RR":"NO"},
    {"np":"57X7025","Model":"MX812dfe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX812dfe","RR":"NO"},
    {"np":"57X7025","Model":"MX812dme","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX812dme","RR":"NO"},
    {"np":"57X7025","Model":"MX812dpe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX812dpe","RR":"NO"},
    {"np":"57X7025","Model":"MX910de","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX910de","RR":"NO"},
    {"np":"57X7025","Model":"MX911dte","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX911dte","RR":"NO"},
    {"np":"57X7025","Model":"MX912dxe","Product":"Lexmark Voice Guidance Kit","Yield":"","AA":"57X7025MX912dxe","RR":"NO"},
    {"np":"57X7030","Model":"MX822ade","Product":"Keyboard Kit (English)","Yield":"","AA":"57X7030MX822ade","RR":"NO"},
    {"np":"57X7030","Model":"MX826ade","Product":"Keyboard Kit (English)","Yield":"","AA":"57X7030MX826ade","RR":"NO"},
    {"np":"57X7040","Model":"CS622de","Product":"Keyboard","Yield":"","AA":"57X7040CS622de","RR":"NO"},
    {"np":"57X7040","Model":"MX822ade","Product":"Keyboard","Yield":"","AA":"57X7040MX822ade","RR":"NO"},
    {"np":"57X7040","Model":"MX826ade","Product":"Keyboard","Yield":"","AA":"57X7040MX826ade","RR":"NO"},
    {"np":"57X7050","Model":"CS921de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7050CS921de","RR":"NO"},
    {"np":"57X7050","Model":"CS923de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7050CS923de","RR":"NO"},
    {"np":"57X7050","Model":"CX921de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7050CX921de","RR":"NO"},
    {"np":"57X7050","Model":"CX922de","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7050CX922de","RR":"NO"},
    {"np":"57X7050","Model":"CX923dte","Product":"Keyboard Holder with English Keyboard","Yield":"","AA":"57X7050CX923dte","RR":"NO"},
    {"np":"57X7054","Model":"CS921de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7054CS921de","RR":"NO"},
    {"np":"57X7054","Model":"CS923de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7054CS923de","RR":"NO"},
    {"np":"57X7054","Model":"CX921de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7054CX921de","RR":"NO"},
    {"np":"57X7054","Model":"CX922de","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7054CX922de","RR":"NO"},
    {"np":"57X7054","Model":"CX923dte","Product":"Keyboard Holder with Spanish Keyboard","Yield":"","AA":"57X7054CX923dte","RR":"NO"},
    {"np":"57X9000","Model":"C925de","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000C925de","RR":"NO"},
    {"np":"57X9000","Model":"C950de","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000C950de","RR":"NO"},
    {"np":"57X9000","Model":"X925de","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000X925de","RR":"NO"},
    {"np":"57X9000","Model":"X950de","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000X950de","RR":"NO"},
    {"np":"57X9000","Model":"X952dte","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000X952dte","RR":"NO"},
    {"np":"57X9000","Model":"X954dhe","Product":"Lexmark PrintCryption Card","Yield":"","AA":"57X9000X954dhe","RR":"NO"},
    {"np":"57X9011","Model":"MS610dn","Product":"Lexmark 1GB DDR3 SO-DIMM","Yield":"","AA":"57X9011MS610dn","RR":"NO"},
    {"np":"57X9012","Model":"CS310dn","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CS310dn","RR":"NO"},
    {"np":"57X9012","Model":"CS510de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CS510de","RR":"NO"},
    {"np":"57X9012","Model":"CX310dn","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CX310dn","RR":"NO"},
    {"np":"57X9012","Model":"CX410de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CX410de","RR":"NO"},
    {"np":"57X9012","Model":"CX510de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CX510de","RR":"NO"},
    {"np":"57X9012","Model":"CX510dhe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012CX510dhe","RR":"NO"},
    {"np":"57X9012","Model":"MS610de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS610de","RR":"NO"},
    {"np":"57X9012","Model":"MS810de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS810de","RR":"NO"},
    {"np":"57X9012","Model":"MS810dn","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS810dn","RR":"NO"},
    {"np":"57X9012","Model":"MS811dn","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS811dn","RR":"NO"},
    {"np":"57X9012","Model":"MS812de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS812de","RR":"NO"},
    {"np":"57X9012","Model":"MS812dn","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS812dn","RR":"NO"},
    {"np":"57X9012","Model":"MS911de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MS911de","RR":"NO"},
    {"np":"57X9012","Model":"MX410de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX410de","RR":"NO"},
    {"np":"57X9012","Model":"MX511de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX511de","RR":"NO"},
    {"np":"57X9012","Model":"MX611dfe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX611dfe","RR":"NO"},
    {"np":"57X9012","Model":"MX611dhe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX611dhe","RR":"NO"},
    {"np":"57X9012","Model":"MX710dhe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX710dhe","RR":"NO"},
    {"np":"57X9012","Model":"MX711dhe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX711dhe","RR":"NO"},
    {"np":"57X9012","Model":"MX810dfe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX810dfe","RR":"NO"},
    {"np":"57X9012","Model":"MX810dme","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX810dme","RR":"NO"},
    {"np":"57X9012","Model":"MX810dpe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX810dpe","RR":"NO"},
    {"np":"57X9012","Model":"MX811dfe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX811dfe","RR":"NO"},
    {"np":"57X9012","Model":"MX811dme","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX811dme","RR":"NO"},
    {"np":"57X9012","Model":"MX811dpe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX811dpe","RR":"NO"},
    {"np":"57X9012","Model":"MX812dfe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX812dfe","RR":"NO"},
    {"np":"57X9012","Model":"MX812dme","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX812dme","RR":"NO"},
    {"np":"57X9012","Model":"MX812dpe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX812dpe","RR":"NO"},
    {"np":"57X9012","Model":"MX910de","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX910de","RR":"NO"},
    {"np":"57X9012","Model":"MX911dte","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX911dte","RR":"NO"},
    {"np":"57X9012","Model":"MX912dxe","Product":"Lexmark 2GBx32 DDR3 RAM","Yield":"","AA":"57X9012MX912dxe","RR":"NO"},
    {"np":"57X9014","Model":"MS610dn","Product":"Lexmark 512MB DDR3 SO-DIMM","Yield":"","AA":"57X9014MS610dn","RR":"NO"},
    {"np":"57X9016","Model":"CS310dn","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CS310dn","RR":"NO"},
    {"np":"57X9016","Model":"CS510de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CS510de","RR":"NO"},
    {"np":"57X9016","Model":"CX310dn","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CX310dn","RR":"NO"},
    {"np":"57X9016","Model":"CX410de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CX410de","RR":"NO"},
    {"np":"57X9016","Model":"CX510de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CX510de","RR":"NO"},
    {"np":"57X9016","Model":"CX510dhe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016CX510dhe","RR":"NO"},
    {"np":"57X9016","Model":"MS610de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS610de","RR":"NO"},
    {"np":"57X9016","Model":"MS810de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS810de","RR":"NO"},
    {"np":"57X9016","Model":"MS810dn","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS810dn","RR":"NO"},
    {"np":"57X9016","Model":"MS811dn","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS811dn","RR":"NO"},
    {"np":"57X9016","Model":"MS812de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS812de","RR":"NO"},
    {"np":"57X9016","Model":"MS812dn","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS812dn","RR":"NO"},
    {"np":"57X9016","Model":"MS911de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MS911de","RR":"NO"},
    {"np":"57X9016","Model":"MX410de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX410de","RR":"NO"},
    {"np":"57X9016","Model":"MX511de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX511de","RR":"NO"},
    {"np":"57X9016","Model":"MX611dfe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX611dfe","RR":"NO"},
    {"np":"57X9016","Model":"MX611dhe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX611dhe","RR":"NO"},
    {"np":"57X9016","Model":"MX710dhe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX710dhe","RR":"NO"},
    {"np":"57X9016","Model":"MX711dhe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX711dhe","RR":"NO"},
    {"np":"57X9016","Model":"MX810dfe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX810dfe","RR":"NO"},
    {"np":"57X9016","Model":"MX810dme","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX810dme","RR":"NO"},
    {"np":"57X9016","Model":"MX810dpe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX810dpe","RR":"NO"},
    {"np":"57X9016","Model":"MX811dfe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX811dfe","RR":"NO"},
    {"np":"57X9016","Model":"MX811dme","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX811dme","RR":"NO"},
    {"np":"57X9016","Model":"MX811dpe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX811dpe","RR":"NO"},
    {"np":"57X9016","Model":"MX812dfe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX812dfe","RR":"NO"},
    {"np":"57X9016","Model":"MX812dme","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX812dme","RR":"NO"},
    {"np":"57X9016","Model":"MX812dpe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX812dpe","RR":"NO"},
    {"np":"57X9016","Model":"MX910de","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX910de","RR":"NO"},
    {"np":"57X9016","Model":"MX911dte","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX911dte","RR":"NO"},
    {"np":"57X9016","Model":"MX912dxe","Product":"Lexmark 1GBx32 DDR3 RAM","Yield":"","AA":"57X9016MX912dxe","RR":"NO"},
    {"np":"57X9020","Model":"CS720de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CS720de","RR":"NO"},
    {"np":"57X9020","Model":"CS725de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CS725de","RR":"NO"},
    {"np":"57X9020","Model":"CS820de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CS820de","RR":"NO"},
    {"np":"57X9020","Model":"CS921de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CS921de","RR":"NO"},
    {"np":"57X9020","Model":"CS923de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CS923de","RR":"NO"},
    {"np":"57X9020","Model":"CX725dhe","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX725dhe","RR":"NO"},
    {"np":"57X9020","Model":"CX820de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX820de","RR":"NO"},
    {"np":"57X9020","Model":"CX825de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX825de","RR":"NO"},
    {"np":"57X9020","Model":"CX860de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX860de","RR":"NO"},
    {"np":"57X9020","Model":"CX921de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX921de","RR":"NO"},
    {"np":"57X9020","Model":"CX922de","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX922de","RR":"NO"},
    {"np":"57X9020","Model":"CX923dte","Product":"Lexmark 2GB DDR3, G2, 512Mx32, 204 SODIMM","Yield":"","AA":"57X9020CX923dte","RR":"NO"},
    {"np":"57X9101","Model":"CS510de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101CS510de","RR":"NO"},
    {"np":"57X9101","Model":"CX310dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101CX310dn","RR":"NO"},
    {"np":"57X9101","Model":"CX410de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101CX410de","RR":"NO"},
    {"np":"57X9101","Model":"CX510de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101CX510de","RR":"NO"},
    {"np":"57X9101","Model":"CX510dhe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101CX510dhe","RR":"NO"},
    {"np":"57X9101","Model":"MS312dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS312dn","RR":"NO"},
    {"np":"57X9101","Model":"MS315dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS315dn","RR":"NO"},
    {"np":"57X9101","Model":"MS415dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS415dn","RR":"NO"},
    {"np":"57X9101","Model":"MS610de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS610de","RR":"NO"},
    {"np":"57X9101","Model":"MS610dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS610dn","RR":"NO"},
    {"np":"57X9101","Model":"MS810de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS810de","RR":"NO"},
    {"np":"57X9101","Model":"MS810dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS810dn","RR":"NO"},
    {"np":"57X9101","Model":"MS811dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS811dn","RR":"NO"},
    {"np":"57X9101","Model":"MS812de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS812de","RR":"NO"},
    {"np":"57X9101","Model":"MS812dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS812dn","RR":"NO"},
    {"np":"57X9101","Model":"MS911de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MS911de","RR":"NO"},
    {"np":"57X9101","Model":"MX310dn","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX310dn","RR":"NO"},
    {"np":"57X9101","Model":"MX410de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX410de","RR":"NO"},
    {"np":"57X9101","Model":"MX511de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX511de","RR":"NO"},
    {"np":"57X9101","Model":"MX611dfe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX611dfe","RR":"NO"},
    {"np":"57X9101","Model":"MX611dhe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX611dhe","RR":"NO"},
    {"np":"57X9101","Model":"MX710dhe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX710dhe","RR":"NO"},
    {"np":"57X9101","Model":"MX711dhe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX711dhe","RR":"NO"},
    {"np":"57X9101","Model":"MX810dfe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX810dfe","RR":"NO"},
    {"np":"57X9101","Model":"MX810dme","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX810dme","RR":"NO"},
    {"np":"57X9101","Model":"MX810dpe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX810dpe","RR":"NO"},
    {"np":"57X9101","Model":"MX811dfe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX811dfe","RR":"NO"},
    {"np":"57X9101","Model":"MX811dme","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX811dme","RR":"NO"},
    {"np":"57X9101","Model":"MX811dpe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX811dpe","RR":"NO"},
    {"np":"57X9101","Model":"MX812dfe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX812dfe","RR":"NO"},
    {"np":"57X9101","Model":"MX812dme","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX812dme","RR":"NO"},
    {"np":"57X9101","Model":"MX812dpe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX812dpe","RR":"NO"},
    {"np":"57X9101","Model":"MX910de","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX910de","RR":"NO"},
    {"np":"57X9101","Model":"MX911dte","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX911dte","RR":"NO"},
    {"np":"57X9101","Model":"MX912dxe","Product":"Lexmark 256MB User Flash Memory","Yield":"","AA":"57X9101MX912dxe","RR":"NO"},
    {"np":"57X9801","Model":"CS421dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS421dn","RR":"NO"},
    {"np":"57X9801","Model":"CS521dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS521dn","RR":"NO"},
    {"np":"57X9801","Model":"CS622de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS622de","RR":"NO"},
    {"np":"57X9801","Model":"CS720de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS720de","RR":"NO"},
    {"np":"57X9801","Model":"CS725de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS725de","RR":"NO"},
    {"np":"57X9801","Model":"CS820de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS820de","RR":"NO"},
    {"np":"57X9801","Model":"CS921de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS921de","RR":"NO"},
    {"np":"57X9801","Model":"CS923de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CS923de","RR":"NO"},
    {"np":"57X9801","Model":"CX421adn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX421adn","RR":"NO"},
    {"np":"57X9801","Model":"CX522ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX522ade","RR":"NO"},
    {"np":"57X9801","Model":"CX622ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX622ade","RR":"NO"},
    {"np":"57X9801","Model":"CX625adhe","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX625adhe","RR":"NO"},
    {"np":"57X9801","Model":"CX725dhe","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX725dhe","RR":"NO"},
    {"np":"57X9801","Model":"CX820de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX820de","RR":"NO"},
    {"np":"57X9801","Model":"CX825de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX825de","RR":"NO"},
    {"np":"57X9801","Model":"CX860de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX860de","RR":"NO"},
    {"np":"57X9801","Model":"CX921de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX921de","RR":"NO"},
    {"np":"57X9801","Model":"CX922de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX922de","RR":"NO"},
    {"np":"57X9801","Model":"CX923dte","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801CX923dte","RR":"NO"},
    {"np":"57X9801","Model":"MS321dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS321dn","RR":"NO"},
    {"np":"57X9801","Model":"MS421dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS421dn","RR":"NO"},
    {"np":"57X9801","Model":"MS521dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS521dn","RR":"NO"},
    {"np":"57X9801","Model":"MS621dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS621dn","RR":"NO"},
    {"np":"57X9801","Model":"MS622de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS622de","RR":"NO"},
    {"np":"57X9801","Model":"MS821dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS821dn","RR":"NO"},
    {"np":"57X9801","Model":"MS823dn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS823dn","RR":"NO"},
    {"np":"57X9801","Model":"MS826de","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MS826de","RR":"NO"},
    {"np":"57X9801","Model":"MX321adn","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX321adn","RR":"NO"},
    {"np":"57X9801","Model":"MX421ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX421ade","RR":"NO"},
    {"np":"57X9801","Model":"MX521ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX521ade","RR":"NO"},
    {"np":"57X9801","Model":"MX522adhe","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX522adhe","RR":"NO"},
    {"np":"57X9801","Model":"MX622adhe","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX622adhe","RR":"NO"},
    {"np":"57X9801","Model":"MX722adhe","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX722adhe","RR":"NO"},
    {"np":"57X9801","Model":"MX822ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX822ade","RR":"NO"},
    {"np":"57X9801","Model":"MX826ade","Product":"256MB User Flash Memory","Yield":"","AA":"57X9801MX826ade","RR":"NO"},
    {"np":"58D0HA0","Model":"MS821dn","Product":"15K -High Yield Toner - Regular","Yield":"15000","AA":"58D0HA0MS821dn","RR":"NO"},
    {"np":"58D0Z00","Model":"MS821dn","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MS821dn","RR":"NO"},
    {"np":"58D0Z00","Model":"MS823dn","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MS823dn","RR":"NO"},
    {"np":"58D0Z00","Model":"MS826de","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MS826de","RR":"NO"},
    {"np":"58D0Z00","Model":"MX721ade","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MX721ade","RR":"NO"},
    {"np":"58D0Z00","Model":"MX722adhe","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MX722adhe","RR":"NO"},
    {"np":"58D0Z00","Model":"MX822ade","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MX822ade","RR":"NO"},
    {"np":"58D0Z00","Model":"MX826ade","Product":"150K -Imaging Unit","Yield":"150000","AA":"58D0Z00MX826ade","RR":"NO"},
    {"np":"58D4000","Model":"MS821dn","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MS821dn","RR":"NO"},
    {"np":"58D4000","Model":"MS823dn","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MS823dn","RR":"NO"},
    {"np":"58D4000","Model":"MS826de","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MS826de","RR":"NO"},
    {"np":"58D4000","Model":"MX721ade","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MX721ade","RR":"NO"},
    {"np":"58D4000","Model":"MX722adhe","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MX722adhe","RR":"NO"},
    {"np":"58D4000","Model":"MX822ade","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MX822ade","RR":"NO"},
    {"np":"58D4000","Model":"MX826ade","Product":"7.5K - Toner","Yield":"7500","AA":"58D4000MX826ade","RR":"NO"},
    {"np":"58D4H00","Model":"MS821dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MS821dn","RR":"NO"},
    {"np":"58D4H00","Model":"MS823dn","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MS823dn","RR":"NO"},
    {"np":"58D4H00","Model":"MS826de","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MS826de","RR":"NO"},
    {"np":"58D4H00","Model":"MX721ade","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MX721ade","RR":"NO"},
    {"np":"58D4H00","Model":"MX722adhe","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MX722adhe","RR":"NO"},
    {"np":"58D4H00","Model":"MX822ade","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MX822ade","RR":"NO"},
    {"np":"58D4H00","Model":"MX826ade","Product":"15K -High Yield Toner","Yield":"15000","AA":"58D4H00MX826ade","RR":"NO"},
    {"np":"58D4U00","Model":"MS823dn","Product":"55K -Ultra High Yield Toner","Yield":"55000","AA":"58D4U00MS823dn","RR":"NO"},
    {"np":"58D4U00","Model":"MS826de","Product":"55K -Ultra High Yield Toner","Yield":"55000","AA":"58D4U00MS826de","RR":"NO"},
    {"np":"58D4U00","Model":"MX722adhe","Product":"55K -Ultra High Yield Toner","Yield":"55000","AA":"58D4U00MX722adhe","RR":"NO"},
    {"np":"58D4U00","Model":"MX822ade","Product":"55K -Ultra High Yield Toner","Yield":"55000","AA":"58D4U00MX822ade","RR":"NO"},
    {"np":"58D4U00","Model":"MX826ade","Product":"55K -Ultra High Yield Toner","Yield":"55000","AA":"58D4U00MX826ade","RR":"NO"},
    {"np":"58D4X00","Model":"MS823dn","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MS823dn","RR":"NO"},
    {"np":"58D4X00","Model":"MS826de","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MS826de","RR":"NO"},
    {"np":"58D4X00","Model":"MX721ade","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MX721ade","RR":"NO"},
    {"np":"58D4X00","Model":"MX722adhe","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MX722adhe","RR":"NO"},
    {"np":"58D4X00","Model":"MX822ade","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MX822ade","RR":"NO"},
    {"np":"58D4X00","Model":"MX826ade","Product":"35K -Extra High Yield Toner","Yield":"35000","AA":"58D4X00MX826ade","RR":"NO"},
    {"np":"60F4000","Model":"MX310dn","Product":"2.5K -Standard Yield Toner","Yield":"2500","AA":"60F4000MX310dn","RR":"NO"},
    {"np":"60F4000","Model":"MX410de","Product":"2.5K -Standard Yield Toner","Yield":"2500","AA":"60F4000MX410de","RR":"NO"},
    {"np":"60F4000","Model":"MX511de","Product":"2.5K -Standard Yield Toner","Yield":"2500","AA":"60F4000MX511de","RR":"NO"},
    {"np":"60F4000","Model":"MX611dfe","Product":"2.5K -Standard Yield Toner","Yield":"2500","AA":"60F4000MX611dfe","RR":"NO"},
    {"np":"60F4000","Model":"MX611dhe","Product":"2.5K -Standard Yield Toner","Yield":"2500","AA":"60F4000MX611dhe","RR":"NO"},
    {"np":"60F4H00","Model":"MX310dn","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX310dn","RR":"NO"},
    {"np":"60F4H00","Model":"MX410de","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX410de","RR":"NO"},
    {"np":"60F4H00","Model":"MX511de","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX511de","RR":"NO"},
    {"np":"60F4H00","Model":"MX611dfe","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX611dfe","RR":"NO"},
    {"np":"60F4H00","Model":"MX611dhe","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX611dhe","RR":"NO"},
    {"np":"60F4X00","Model":"MX511de","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX511de","RR":"NO"},
    {"np":"60F4X00","Model":"MX611dfe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX611dfe","RR":"NO"},
    {"np":"60F4X00","Model":"MX611dhe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX611dhe","RR":"NO"},
    {"np":"62D4H00","Model":"MX710dhe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX710dhe","RR":"NO"},
    {"np":"62D4H00","Model":"MX711dhe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX711dhe","RR":"NO"},
    {"np":"62D4H00","Model":"MX810dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dfe","RR":"NO"},
    {"np":"62D4H00","Model":"MX810dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dme","RR":"NO"},
    {"np":"62D4H00","Model":"MX810dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dpe","RR":"NO"},
    {"np":"62D4H00","Model":"MX811dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dfe","RR":"NO"},
    {"np":"62D4H00","Model":"MX811dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dme","RR":"NO"},
    {"np":"62D4H00","Model":"MX811dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dpe","RR":"NO"},
    {"np":"62D4H00","Model":"MX812dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dfe","RR":"NO"},
    {"np":"62D4H00","Model":"MX812dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dme","RR":"NO"},
    {"np":"62D4H00","Model":"MX812dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dpe","RR":"NO"},
    {"np":"62D4X00","Model":"MX711dhe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX711dhe","RR":"NO"},
    {"np":"62D4X00","Model":"MX810dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dfe","RR":"NO"},
    {"np":"62D4X00","Model":"MX810dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dme","RR":"NO"},
    {"np":"62D4X00","Model":"MX810dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dpe","RR":"NO"},
    {"np":"62D4X00","Model":"MX811dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dfe","RR":"NO"},
    {"np":"62D4X00","Model":"MX811dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dme","RR":"NO"},
    {"np":"62D4X00","Model":"MX811dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dpe","RR":"NO"},
    {"np":"62D4X00","Model":"MX812dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dfe","RR":"NO"},
    {"np":"62D4X00","Model":"MX812dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dme","RR":"NO"},
    {"np":"62D4X00","Model":"MX812dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dpe","RR":"NO"},
    {"np":"64004HL","Model":"T64x","Product":"T640, T642, T644 High Yield Return Program Print Cartridge for Labels","Yield":"21000","AA":"64004HLT64x","RR":"NO"},
    {"np":"64018HL","Model":"T64x","Product":"T640, T642, T644 High Yield Return Program Print Cartridge","Yield":"21000","AA":"64018HLT64x","RR":"NO"},
    {"np":"64018SL","Model":"T64x","Product":"T640, T642, T644 Return Program Print Cartridge","Yield":"6000","AA":"64018SLT64x","RR":"NO"},
    {"np":"64038HL","Model":"T64x","Product":"T640, T642, T644 High Yield Print Cartridge","Yield":"21000","AA":"64038HLT64x","RR":"NO"},
    {"np":"64404XL","Model":"T64x","Product":"T644 Extra High Yield Return Program Print Cartridge for Labels","Yield":"32000","AA":"64404XLT64x","RR":"NO"},
    {"np":"64418XL","Model":"T64x","Product":"T644 Extra High Yield Return Program Print Cartridge","Yield":"32000","AA":"64418XLT64x","RR":"NO"},
    {"np":"64438XL","Model":"T64x","Product":"T644 Extra High Yield Print Cartridge","Yield":"32000","AA":"64438XLT64x","RR":"NO"},
    {"np":"64G0H00","Model":"MX910de","Product":"32.5K - Black High Yield Toner Cartridge","Yield":"32500","AA":"64G0H00MX910de","RR":"NO"},
    {"np":"64G0H00","Model":"MX911dte","Product":"32.5K - Black High Yield Toner Cartridge","Yield":"32500","AA":"64G0H00MX911dte","RR":"NO"},
    {"np":"64G0H00","Model":"MX912dxe","Product":"32.5K - Black High Yield Toner Cartridge","Yield":"32500","AA":"64G0H00MX912dxe","RR":"NO"},
    {"np":"70C0Z10","Model":"CS310dn","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CS310dn","RR":"NO"},
    {"np":"70C0Z10","Model":"CS510de","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CS510de","RR":"NO"},
    {"np":"70C0Z10","Model":"CX310dn","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CX310dn","RR":"NO"},
    {"np":"70C0Z10","Model":"CX410de","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CX410de","RR":"NO"},
    {"np":"70C0Z10","Model":"CX510de","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CX510de","RR":"NO"},
    {"np":"70C0Z10","Model":"CX510dhe","Product":"40K -Black Imaging Kit","Yield":"40000","AA":"70C0Z10CX510dhe","RR":"NO"},
    {"np":"70C0Z50","Model":"CS310dn","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CS310dn","RR":"NO"},
    {"np":"70C0Z50","Model":"CS510de","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CS510de","RR":"NO"},
    {"np":"70C0Z50","Model":"CX310dn","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CX310dn","RR":"NO"},
    {"np":"70C0Z50","Model":"CX410de","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CX410de","RR":"NO"},
    {"np":"70C0Z50","Model":"CX510de","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CX510de","RR":"NO"},
    {"np":"70C0Z50","Model":"CX510dhe","Product":"40K -Black and Color Imagining Kit","Yield":"40000","AA":"70C0Z50CX510dhe","RR":"NO"},
    {"np":"70C80C0","Model":"CS310dn","Product":"1K -Cyan Return Program Toner Cartridge","Yield":"1000","AA":"70C80C0CS310dn","RR":"NO"},
    {"np":"70C80C0","Model":"CS510de","Product":"1K -Cyan Return Program Toner Cartridge","Yield":"1000","AA":"70C80C0CS510de","RR":"NO"},
    {"np":"70C80K0","Model":"CS310dn","Product":"1K -Black Return Program Toner Cartridge","Yield":"1000","AA":"70C80K0CS310dn","RR":"NO"},
    {"np":"70C80K0","Model":"CS510de","Product":"1K -Black Return Program Toner Cartridge","Yield":"1000","AA":"70C80K0CS510de","RR":"NO"},
    {"np":"70C80M0","Model":"CS310dn","Product":"1K -Magenta Return Program Toner Cartridge","Yield":"1000","AA":"70C80M0CS310dn","RR":"NO"},
    {"np":"70C80M0","Model":"CS510de","Product":"1K -Magenta Return Program Toner Cartridge","Yield":"1000","AA":"70C80M0CS510de","RR":"NO"},
    {"np":"70C80Y0","Model":"CS310dn","Product":"1K -Yellow Return Program Toner Cartridge","Yield":"1000","AA":"70C80Y0CS310dn","RR":"NO"},
    {"np":"70C80Y0","Model":"CS510de","Product":"1K -Yellow Return Program Toner Cartridge","Yield":"1000","AA":"70C80Y0CS510de","RR":"NO"},
    {"np":"70C8HC0","Model":"CS310dn","Product":"3K -Cyan High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HC0CS310dn","RR":"NO"},
    {"np":"70C8HC0","Model":"CS510de","Product":"3K -Cyan High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HC0CS510de","RR":"NO"},
    {"np":"70C8HK0","Model":"CS310dn","Product":"4K -Black High Yield Return Program Toner Cartridge","Yield":"4000","AA":"70C8HK0CS310dn","RR":"NO"},
    {"np":"70C8HK0","Model":"CS510de","Product":"4K -Black High Yield Return Program Toner Cartridge","Yield":"4000","AA":"70C8HK0CS510de","RR":"NO"},
    {"np":"70C8HM0","Model":"CS310dn","Product":"3K -Magenta High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HM0CS310dn","RR":"NO"},
    {"np":"70C8HM0","Model":"CS510de","Product":"3K -Magenta High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HM0CS510de","RR":"NO"},
    {"np":"70C8HY0","Model":"CS310dn","Product":"3K -Yellow High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HY0CS310dn","RR":"NO"},
    {"np":"70C8HY0","Model":"CS510de","Product":"3K -Yellow High Yield Return Program Toner Cartridge","Yield":"3000","AA":"70C8HY0CS510de","RR":"NO"},
    {"np":"70C8XC0","Model":"CS510de","Product":"4K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"70C8XC0CS510de","RR":"NO"},
    {"np":"70C8XK0","Model":"CS510de","Product":"8K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8000","AA":"70C8XK0CS510de","RR":"NO"},
    {"np":"70C8XM0","Model":"CS510de","Product":"4K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"4000","AA":"70C8XM0CS510de","RR":"NO"},
    {"np":"70C8XY0","Model":"CS510de","Product":"4K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"4000","AA":"70C8XY0CS510de","RR":"NO"},
    {"np":"72K0DK0","Model":"CS820de","Product":"300K -Black Return Program Developer Unit","Yield":"300000","AA":"72K0DK0CS820de","RR":"NO"},
    {"np":"72K0DK0","Model":"CX820de","Product":"300K -Black Return Program Developer Unit","Yield":"300000","AA":"72K0DK0CX820de","RR":"NO"},
    {"np":"72K0DK0","Model":"CX825de","Product":"300K -Black Return Program Developer Unit","Yield":"300000","AA":"72K0DK0CX825de","RR":"NO"},
    {"np":"72K0DK0","Model":"CX860de","Product":"300K -Black Return Program Developer Unit","Yield":"300000","AA":"72K0DK0CX860de","RR":"NO"},
    {"np":"72K0DV0","Model":"CS820de","Product":"300K -Color (CMY) Return Program Developer Kit","Yield":"300000","AA":"72K0DV0CS820de","RR":"NO"},
    {"np":"72K0DV0","Model":"CX820de","Product":"300K -Color (CMY) Return Program Developer Kit","Yield":"300000","AA":"72K0DV0CX820de","RR":"NO"},
    {"np":"72K0DV0","Model":"CX825de","Product":"300K -Color (CMY) Return Program Developer Kit","Yield":"300000","AA":"72K0DV0CX825de","RR":"NO"},
    {"np":"72K0DV0","Model":"CX860de","Product":"300K -Color (CMY) Return Program Developer Kit","Yield":"300000","AA":"72K0DV0CX860de","RR":"NO"},
    {"np":"72K0P00","Model":"CS820de","Product":"175K -Photoconductor - 1 Pack","Yield":"175000","AA":"72K0P00CS820de","RR":"NO"},
    {"np":"72K0P00","Model":"CX820de","Product":"175K -Photoconductor - 1 Pack","Yield":"175000","AA":"72K0P00CX820de","RR":"NO"},
    {"np":"72K0P00","Model":"CX825de","Product":"175K -Photoconductor - 1 Pack","Yield":"175000","AA":"72K0P00CX825de","RR":"NO"},
    {"np":"72K0P00","Model":"CX860de","Product":"175K -Photoconductor - 1 Pack","Yield":"175000","AA":"72K0P00CX860de","RR":"NO"},
    {"np":"72K0Q00","Model":"CS820de","Product":"175K -Photoconductor - 3 Pack","Yield":"175000","AA":"72K0Q00CS820de","RR":"NO"},
    {"np":"72K0Q00","Model":"CX820de","Product":"175K -Photoconductor - 3 Pack","Yield":"175000","AA":"72K0Q00CX820de","RR":"NO"},
    {"np":"72K0Q00","Model":"CX825de","Product":"175K -Photoconductor - 3 Pack","Yield":"175000","AA":"72K0Q00CX825de","RR":"NO"},
    {"np":"72K0Q00","Model":"CX860de","Product":"175K -Photoconductor - 3 Pack","Yield":"175000","AA":"72K0Q00CX860de","RR":"NO"},
    {"np":"72K0W00","Model":"CS820de","Product":"115K -Waste Toner Bottle","Yield":"115000","AA":"72K0W00CS820de","RR":"NO"},
    {"np":"72K0W00","Model":"CX820de","Product":"115K -Waste Toner Bottle","Yield":"115000","AA":"72K0W00CX820de","RR":"NO"},
    {"np":"72K0W00","Model":"CX825de","Product":"115K -Waste Toner Bottle","Yield":"115000","AA":"72K0W00CX825de","RR":"NO"},
    {"np":"72K0W00","Model":"CX860de","Product":"115K -Waste Toner Bottle","Yield":"115000","AA":"72K0W00CX860de","RR":"NO"},
    {"np":"72K4XC0","Model":"CS820de","Product":"22K -Cyan Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"72K4XC0CS820de","RR":"NO"},
    {"np":"72K4XK0","Model":"CS820de","Product":"33K -Black Extra High Yield Return Program Toner Cartrige","Yield":"33000","AA":"72K4XK0CS820de","RR":"NO"},
    {"np":"72K4XK0","Model":"CX820de","Product":"33K -Black Extra High Yield Return Program Toner Cartrige","Yield":"33000","AA":"72K4XK0CX820de","RR":"NO"},
    {"np":"72K4XK0","Model":"CX825de","Product":"33K -Black Extra High Yield Return Program Toner Cartrige","Yield":"33000","AA":"72K4XK0CX825de","RR":"NO"},
    {"np":"72K4XK0","Model":"CX860de","Product":"33K -Black Extra High Yield Return Program Toner Cartrige","Yield":"33000","AA":"72K4XK0CX860de","RR":"NO"},
    {"np":"72K4XM0","Model":"CS820de","Product":"22K -Magenta Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"72K4XM0CS820de","RR":"NO"},
    {"np":"72K4XY0","Model":"CS820de","Product":"22K -Yellow Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"72K4XY0CS820de","RR":"NO"},
    {"np":"74C0W00","Model":"CS720de","Product":"90K -Waste Toner Bottle","Yield":"90000","AA":"74C0W00CS720de","RR":"NO"},
    {"np":"74C0W00","Model":"CS725de","Product":"90K -Waste Toner Bottle","Yield":"90000","AA":"74C0W00CS725de","RR":"NO"},
    {"np":"74C0W00","Model":"CX725dhe","Product":"90K -Waste Toner Bottle","Yield":"90000","AA":"74C0W00CX725dhe","RR":"NO"},
    {"np":"74C0ZK0","Model":"CS720de","Product":"150K -Black Return Program Imaging Unit","Yield":"150000","AA":"74C0ZK0CS720de","RR":"NO"},
    {"np":"74C0ZK0","Model":"CS725de","Product":"150K -Black Return Program Imaging Unit","Yield":"150000","AA":"74C0ZK0CS725de","RR":"NO"},
    {"np":"74C0ZK0","Model":"CX725dhe","Product":"150K -Black Return Program Imaging Unit","Yield":"150000","AA":"74C0ZK0CX725dhe","RR":"NO"},
    {"np":"74C0ZV0","Model":"CS720de","Product":"150K -Color (CMY) Return Program Imaging Kit","Yield":"150000","AA":"74C0ZV0CS720de","RR":"NO"},
    {"np":"74C0ZV0","Model":"CS725de","Product":"150K -Color (CMY) Return Program Imaging Kit","Yield":"150000","AA":"74C0ZV0CS725de","RR":"NO"},
    {"np":"74C0ZV0","Model":"CX725dhe","Product":"150K -Color (CMY) Return Program Imaging Kit","Yield":"150000","AA":"74C0ZV0CX725dhe","RR":"NO"},
    {"np":"74C4HC0","Model":"CS725de","Product":"12K -Cyan High Yield Return Program Toner Cartridge","Yield":"12000","AA":"74C4HC0CS725de","RR":"NO"},
    {"np":"74C4HK0","Model":"CS720de","Product":"20K -Black High Yield Return Program Toner Cartridge","Yield":"20000","AA":"74C4HK0CS720de","RR":"NO"},
    {"np":"74C4HK0","Model":"CS725de","Product":"20K -Black High Yield Return Program Toner Cartridge","Yield":"20000","AA":"74C4HK0CS725de","RR":"NO"},
    {"np":"74C4HM0","Model":"CS725de","Product":"12K -Magenta High Yield Return Program Toner Cartridge","Yield":"12000","AA":"74C4HM0CS725de","RR":"NO"},
    {"np":"74C4HY0","Model":"CS725de","Product":"12K -Yellow High Yield Return Program Toner Cartridge","Yield":"12000","AA":"74C4HY0CS725de","RR":"NO"},
    {"np":"74C4SC0","Model":"CS720de","Product":"7K -Cyan Low Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SC0CS720de","RR":"NO"},
    {"np":"74C4SC0","Model":"CS725de","Product":"7K -Cyan Low Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SC0CS725de","RR":"NO"},
    {"np":"74C4SC0","Model":"CX725dhe","Product":"7K -Cyan Low Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SC0CX725dhe","RR":"NO"},
    {"np":"74C4SK0","Model":"CS720de","Product":"7K -Black Standrad Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SK0CS720de","RR":"NO"},
    {"np":"74C4SK0","Model":"CS725de","Product":"7K -Black Standrad Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SK0CS725de","RR":"NO"},
    {"np":"74C4SK0","Model":"CX725dhe","Product":"7K -Black Standrad Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SK0CX725dhe","RR":"NO"},
    {"np":"74C4SM0","Model":"CS720de","Product":"7K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SM0CS720de","RR":"NO"},
    {"np":"74C4SM0","Model":"CS725de","Product":"7K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SM0CS725de","RR":"NO"},
    {"np":"74C4SM0","Model":"CX725dhe","Product":"7K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SM0CX725dhe","RR":"NO"},
    {"np":"74C4SY0","Model":"CS720de","Product":"7K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SY0CS720de","RR":"NO"},
    {"np":"74C4SY0","Model":"CS725de","Product":"7K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SY0CS725de","RR":"NO"},
    {"np":"74C4SY0","Model":"CX725dhe","Product":"7K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"7000","AA":"74C4SY0CX725dhe","RR":"NO"},
    {"np":"76C00C0","Model":"CS921de","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CS921de","RR":"NO"},
    {"np":"76C00C0","Model":"CS923de","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CS923de","RR":"NO"},
    {"np":"76C00C0","Model":"CX920de","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CX920de","RR":"NO"},
    {"np":"76C00C0","Model":"CX921de","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CX921de","RR":"NO"},
    {"np":"76C00C0","Model":"CX922de","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CX922de","RR":"NO"},
    {"np":"76C00C0","Model":"CX923dte","Product":"11.5K -Cyan Toner Cartridge","Yield":"11500","AA":"76C00C0CX923dte","RR":"NO"},
    {"np":"76C00K0","Model":"CS921de","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CS921de","RR":"NO"},
    {"np":"76C00K0","Model":"CS923de","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CS923de","RR":"NO"},
    {"np":"76C00K0","Model":"CX920de","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CX920de","RR":"NO"},
    {"np":"76C00K0","Model":"CX921de","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CX921de","RR":"NO"},
    {"np":"76C00K0","Model":"CX922de","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CX922de","RR":"NO"},
    {"np":"76C00K0","Model":"CX923dte","Product":"18.5K - Black Toner Cartridge","Yield":"18500","AA":"76C00K0CX923dte","RR":"NO"},
    {"np":"76C00M0","Model":"CS921de","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CS921de","RR":"NO"},
    {"np":"76C00M0","Model":"CS923de","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CS923de","RR":"NO"},
    {"np":"76C00M0","Model":"CX920de","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CX920de","RR":"NO"},
    {"np":"76C00M0","Model":"CX921de","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CX921de","RR":"NO"},
    {"np":"76C00M0","Model":"CX922de","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CX922de","RR":"NO"},
    {"np":"76C00M0","Model":"CX923dte","Product":"11.5K -Magenta Toner Cartridge","Yield":"11500","AA":"76C00M0CX923dte","RR":"NO"},
    {"np":"76C00Y0","Model":"CS921de","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CS921de","RR":"NO"},
    {"np":"76C00Y0","Model":"CS923de","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CS923de","RR":"NO"},
    {"np":"76C00Y0","Model":"CX920de","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CX920de","RR":"NO"},
    {"np":"76C00Y0","Model":"CX921de","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CX921de","RR":"NO"},
    {"np":"76C00Y0","Model":"CX922de","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CX922de","RR":"NO"},
    {"np":"76C00Y0","Model":"CX923dte","Product":"11.5K -Yellow Toner Cartridge","Yield":"11500","AA":"76C00Y0CX923dte","RR":"NO"},
    {"np":"76C0HC0","Model":"CS923de","Product":"34K -Cyan High Yield Toner Cartridge","Yield":"34000","AA":"76C0HC0CS923de","RR":"NO"},
    {"np":"76C0HC0","Model":"CX921de","Product":"34K -Cyan High Yield Toner Cartridge","Yield":"34000","AA":"76C0HC0CX921de","RR":"NO"},
    {"np":"76C0HC0","Model":"CX922de","Product":"34K -Cyan High Yield Toner Cartridge","Yield":"34000","AA":"76C0HC0CX922de","RR":"NO"},
    {"np":"76C0HC0","Model":"CX923dte","Product":"34K -Cyan High Yield Toner Cartridge","Yield":"34000","AA":"76C0HC0CX923dte","RR":"NO"},
    {"np":"76C0HK0","Model":"CS923de","Product":"34K - Black High Yield Toner Cartridge","Yield":"34000","AA":"76C0HK0CS923de","RR":"NO"},
    {"np":"76C0HM0","Model":"CS923de","Product":"34K -Magenta High Yield Toner Cartridge","Yield":"34000","AA":"76C0HM0CS923de","RR":"NO"},
    {"np":"76C0HM0","Model":"CX921de","Product":"34K -Magenta High Yield Toner Cartridge","Yield":"34000","AA":"76C0HM0CX921de","RR":"NO"},
    {"np":"76C0HM0","Model":"CX922de","Product":"34K -Magenta High Yield Toner Cartridge","Yield":"34000","AA":"76C0HM0CX922de","RR":"NO"},
    {"np":"76C0HM0","Model":"CX923dte","Product":"34K -Magenta High Yield Toner Cartridge","Yield":"34000","AA":"76C0HM0CX923dte","RR":"NO"},
    {"np":"76C0HY0","Model":"CS923de","Product":"34K -Yellow High Yield Toner Cartridge","Yield":"34000","AA":"76C0HY0CS923de","RR":"NO"},
    {"np":"76C0HY0","Model":"CX921de","Product":"34K -Yellow High Yield Toner Cartridge","Yield":"34000","AA":"76C0HY0CX921de","RR":"NO"},
    {"np":"76C0HY0","Model":"CX922de","Product":"34K -Yellow High Yield Toner Cartridge","Yield":"34000","AA":"76C0HY0CX922de","RR":"NO"},
    {"np":"76C0HY0","Model":"CX923dte","Product":"34K -Yellow High Yield Toner Cartridge","Yield":"34000","AA":"76C0HY0CX923dte","RR":"NO"},
    {"np":"76C0PK0","Model":"CS921de","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CS921de","RR":"NO"},
    {"np":"76C0PK0","Model":"CS923de","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CS923de","RR":"NO"},
    {"np":"76C0PK0","Model":"CX920de","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CX920de","RR":"NO"},
    {"np":"76C0PK0","Model":"CX921de","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CX921de","RR":"NO"},
    {"np":"76C0PK0","Model":"CX922de","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CX922de","RR":"NO"},
    {"np":"76C0PK0","Model":"CX923dte","Product":"100K - Black Photoconductor Unit","Yield":"100000","AA":"76C0PK0CX923dte","RR":"NO"},
    {"np":"76C0PV0","Model":"CS921de","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CS921de","RR":"NO"},
    {"np":"76C0PV0","Model":"CS923de","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CS923de","RR":"NO"},
    {"np":"76C0PV0","Model":"CX920de","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CX920de","RR":"NO"},
    {"np":"76C0PV0","Model":"CX921de","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CX921de","RR":"NO"},
    {"np":"76C0PV0","Model":"CX922de","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CX922de","RR":"NO"},
    {"np":"76C0PV0","Model":"CX923dte","Product":"90K - CMY Photocunductor Unit","Yield":"90000","AA":"76C0PV0CX923dte","RR":"NO"},
    {"np":"78C0W00","Model":"CS421dn","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CS421dn","RR":"NO"},
    {"np":"78C0W00","Model":"CS521dn","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CS521dn","RR":"NO"},
    {"np":"78C0W00","Model":"CS622de","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CS622de","RR":"NO"},
    {"np":"78C0W00","Model":"CX421adn","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CX421adn","RR":"NO"},
    {"np":"78C0W00","Model":"CX522ade","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CX522ade","RR":"NO"},
    {"np":"78C0W00","Model":"CX622ade","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CX622ade","RR":"NO"},
    {"np":"78C0W00","Model":"CX625adhe","Product":"25K -Waste Toner Bottle","Yield":"25000","AA":"78C0W00CX625adhe","RR":"NO"},
    {"np":"78C0ZK0","Model":"CS421dn","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CS421dn","RR":"NO"},
    {"np":"78C0ZK0","Model":"CS521dn","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CS521dn","RR":"NO"},
    {"np":"78C0ZK0","Model":"CS622de","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CS622de","RR":"NO"},
    {"np":"78C0ZK0","Model":"CX421adn","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CX421adn","RR":"NO"},
    {"np":"78C0ZK0","Model":"CX522ade","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CX522ade","RR":"NO"},
    {"np":"78C0ZK0","Model":"CX622ade","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CX622ade","RR":"NO"},
    {"np":"78C0ZK0","Model":"CX625adhe","Product":"125K -Black Imaging Kit","Yield":"125000","AA":"78C0ZK0CX625adhe","RR":"NO"},
    {"np":"78C0ZV0","Model":"CS421dn","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CS421dn","RR":"NO"},
    {"np":"78C0ZV0","Model":"CS521dn","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CS521dn","RR":"NO"},
    {"np":"78C0ZV0","Model":"CS622de","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CS622de","RR":"NO"},
    {"np":"78C0ZV0","Model":"CX421adn","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CX421adn","RR":"NO"},
    {"np":"78C0ZV0","Model":"CX522ade","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CX522ade","RR":"NO"},
    {"np":"78C0ZV0","Model":"CX622ade","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CX622ade","RR":"NO"},
    {"np":"78C0ZV0","Model":"CX625adhe","Product":"125K -Black and Color Imagining Kit","Yield":"125000","AA":"78C0ZV0CX625adhe","RR":"NO"},
    {"np":"78C40C0","Model":"CS421dn","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CS421dn","RR":"NO"},
    {"np":"78C40C0","Model":"CS521dn","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CS521dn","RR":"NO"},
    {"np":"78C40C0","Model":"CS622de","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CS622de","RR":"NO"},
    {"np":"78C40C0","Model":"CX421adn","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CX421adn","RR":"NO"},
    {"np":"78C40C0","Model":"CX522ade","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CX522ade","RR":"NO"},
    {"np":"78C40C0","Model":"CX622ade","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CX622ade","RR":"NO"},
    {"np":"78C40C0","Model":"CX625adhe","Product":"1.4K -Cyan Return Program Toner Cartridge","Yield":"1400","AA":"78C40C0CX625adhe","RR":"NO"},
    {"np":"78C40K0","Model":"CS421dn","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CS421dn","RR":"NO"},
    {"np":"78C40K0","Model":"CS521dn","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CS521dn","RR":"NO"},
    {"np":"78C40K0","Model":"CS622de","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CS622de","RR":"NO"},
    {"np":"78C40K0","Model":"CX421adn","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CX421adn","RR":"NO"},
    {"np":"78C40K0","Model":"CX522ade","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CX522ade","RR":"NO"},
    {"np":"78C40K0","Model":"CX622ade","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CX622ade","RR":"NO"},
    {"np":"78C40K0","Model":"CX625adhe","Product":"2K -Black Return Program Toner Cartridge","Yield":"2000","AA":"78C40K0CX625adhe","RR":"NO"},
    {"np":"78C40M0","Model":"CS421dn","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CS421dn","RR":"NO"},
    {"np":"78C40M0","Model":"CS521dn","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CS521dn","RR":"NO"},
    {"np":"78C40M0","Model":"CS622de","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CS622de","RR":"NO"},
    {"np":"78C40M0","Model":"CX421adn","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CX421adn","RR":"NO"},
    {"np":"78C40M0","Model":"CX522ade","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CX522ade","RR":"NO"},
    {"np":"78C40M0","Model":"CX622ade","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CX622ade","RR":"NO"},
    {"np":"78C40M0","Model":"CX625adhe","Product":"1.4K -Magenta Return Program Toner Cartridge","Yield":"1400","AA":"78C40M0CX625adhe","RR":"NO"},
    {"np":"78C40Y0","Model":"CS421dn","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CS421dn","RR":"NO"},
    {"np":"78C40Y0","Model":"CS521dn","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CS521dn","RR":"NO"},
    {"np":"78C40Y0","Model":"CS622de","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CS622de","RR":"NO"},
    {"np":"78C40Y0","Model":"CX421adn","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CX421adn","RR":"NO"},
    {"np":"78C40Y0","Model":"CX522ade","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CX522ade","RR":"NO"},
    {"np":"78C40Y0","Model":"CX622ade","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CX622ade","RR":"NO"},
    {"np":"78C40Y0","Model":"CX625adhe","Product":"1.4K -Yellow Return Program Toner Cartridge","Yield":"1400","AA":"78C40Y0CX625adhe","RR":"NO"},
    {"np":"78C4UC0","Model":"CS521dn","Product":"7K -Cyan Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UC0CS521dn","RR":"NO"},
    {"np":"78C4UC0","Model":"CS622de","Product":"7K -Cyan Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UC0CS622de","RR":"NO"},
    {"np":"78C4UC0","Model":"CX522ade","Product":"7K -Cyan Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UC0CX522ade","RR":"NO"},
    {"np":"78C4UC0","Model":"CX622ade","Product":"7K -Cyan Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UC0CX622ade","RR":"NO"},
    {"np":"78C4UC0","Model":"CX625adhe","Product":"7K -Cyan Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UC0CX625adhe","RR":"NO"},
    {"np":"78C4UK0","Model":"CS521dn","Product":"10.5K -Black Ultra High Yield Return Program Toner Cartridge","Yield":"10500","AA":"78C4UK0CS521dn","RR":"NO"},
    {"np":"78C4UK0","Model":"CS622de","Product":"10.5K -Black Ultra High Yield Return Program Toner Cartridge","Yield":"10500","AA":"78C4UK0CS622de","RR":"NO"},
    {"np":"78C4UK0","Model":"CX522ade","Product":"10.5K -Black Ultra High Yield Return Program Toner Cartridge","Yield":"10500","AA":"78C4UK0CX522ade","RR":"NO"},
    {"np":"78C4UK0","Model":"CX622ade","Product":"10.5K -Black Ultra High Yield Return Program Toner Cartridge","Yield":"10500","AA":"78C4UK0CX622ade","RR":"NO"},
    {"np":"78C4UK0","Model":"CX625adhe","Product":"10.5K -Black Ultra High Yield Return Program Toner Cartridge","Yield":"10500","AA":"78C4UK0CX625adhe","RR":"NO"},
    {"np":"78C4UM0","Model":"CS521dn","Product":"7K -Magenta Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UM0CS521dn","RR":"NO"},
    {"np":"78C4UM0","Model":"CS622de","Product":"7K -Magenta Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UM0CS622de","RR":"NO"},
    {"np":"78C4UM0","Model":"CX522ade","Product":"7K -Magenta Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UM0CX522ade","RR":"NO"},
    {"np":"78C4UM0","Model":"CX622ade","Product":"7K -Magenta Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UM0CX622ade","RR":"NO"},
    {"np":"78C4UM0","Model":"CX625adhe","Product":"7K -Magenta Ultra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"78C4UM0CX625adhe","RR":"NO"},
    {"np":"78C4UY0","Model":"CS521dn","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"78C4UY0CS521dn","RR":"NO"},
    {"np":"78C4UY0","Model":"CS622de","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"78C4UY0CS622de","RR":"NO"},
    {"np":"78C4UY0","Model":"CX522ade","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"78C4UY0CX522ade","RR":"NO"},
    {"np":"78C4UY0","Model":"CX622ade","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"78C4UY0CX622ade","RR":"NO"},
    {"np":"78C4UY0","Model":"CX625adhe","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"78C4UY0CX625adhe","RR":"NO"},
    {"np":"78C4XC0","Model":"CS421dn","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CS421dn","RR":"NO"},
    {"np":"78C4XC0","Model":"CS521dn","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CS521dn","RR":"NO"},
    {"np":"78C4XC0","Model":"CS622de","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CS622de","RR":"NO"},
    {"np":"78C4XC0","Model":"CX421adn","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CX421adn","RR":"NO"},
    {"np":"78C4XC0","Model":"CX522ade","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CX522ade","RR":"NO"},
    {"np":"78C4XC0","Model":"CX622ade","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CX622ade","RR":"NO"},
    {"np":"78C4XC0","Model":"CX625adhe","Product":"5K -Cyan Extra High Yield Return Program Toner Cartridge","Yield":"5000","AA":"78C4XC0CX625adhe","RR":"NO"},
    {"np":"78C4XK0","Model":"CS421dn","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CS421dn","RR":"NO"},
    {"np":"78C4XK0","Model":"CS521dn","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CS521dn","RR":"NO"},
    {"np":"78C4XK0","Model":"CS622de","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CS622de","RR":"NO"},
    {"np":"78C4XK0","Model":"CX421adn","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CX421adn","RR":"NO"},
    {"np":"78C4XK0","Model":"CX522ade","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CX522ade","RR":"NO"},
    {"np":"78C4XK0","Model":"CX622ade","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CX622ade","RR":"NO"},
    {"np":"78C4XK0","Model":"CX625adhe","Product":"8.5K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8500","AA":"78C4XK0CX625adhe","RR":"NO"},
    {"np":"78C4XM0","Model":"CS421dn","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CS421dn","RR":"NO"},
    {"np":"78C4XM0","Model":"CS521dn","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CS521dn","RR":"NO"},
    {"np":"78C4XM0","Model":"CS622de","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CS622de","RR":"NO"},
    {"np":"78C4XM0","Model":"CX421adn","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CX421adn","RR":"NO"},
    {"np":"78C4XM0","Model":"CX522ade","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CX522ade","RR":"NO"},
    {"np":"78C4XM0","Model":"CX622ade","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CX622ade","RR":"NO"},
    {"np":"78C4XM0","Model":"CX625adhe","Product":"5K -Magenta Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XM0CX625adhe","RR":"NO"},
    {"np":"78C4XY0","Model":"CS421dn","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CS421dn","RR":"NO"},
    {"np":"78C4XY0","Model":"CS521dn","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CS521dn","RR":"NO"},
    {"np":"78C4XY0","Model":"CS622de","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CS622de","RR":"NO"},
    {"np":"78C4XY0","Model":"CX421adn","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CX421adn","RR":"NO"},
    {"np":"78C4XY0","Model":"CX522ade","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CX522ade","RR":"NO"},
    {"np":"78C4XY0","Model":"CX622ade","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CX622ade","RR":"NO"},
    {"np":"78C4XY0","Model":"CX625adhe","Product":"5K -Yellow Extra High Yield  Return Program Toner Cartridge","Yield":"5000","AA":"78C4XY0CX625adhe","RR":"NO"},
    {"np":"80C8HC0","Model":"CX410de","Product":"3K -Cyan High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HC0CX410de","RR":"NO"},
    {"np":"80C8HC0","Model":"CX510de","Product":"3K -Cyan High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HC0CX510de","RR":"NO"},
    {"np":"80C8HC0","Model":"CX510dhe","Product":"3K -Cyan High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HC0CX510dhe","RR":"NO"},
    {"np":"80C8HK0","Model":"CX410de","Product":"4K -Black High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8HK0CX410de","RR":"NO"},
    {"np":"80C8HK0","Model":"CX510de","Product":"4K -Black High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8HK0CX510de","RR":"NO"},
    {"np":"80C8HK0","Model":"CX510dhe","Product":"4K -Black High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8HK0CX510dhe","RR":"NO"},
    {"np":"80C8HM0","Model":"CX410de","Product":"3K -Magenta High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HM0CX410de","RR":"NO"},
    {"np":"80C8HM0","Model":"CX510de","Product":"3K -Magenta High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HM0CX510de","RR":"NO"},
    {"np":"80C8HM0","Model":"CX510dhe","Product":"3K -Magenta High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HM0CX510dhe","RR":"NO"},
    {"np":"80C8HY0","Model":"CX410de","Product":"3K -Yellow High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HY0CX410de","RR":"NO"},
    {"np":"80C8HY0","Model":"CX510de","Product":"3K -Yellow High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HY0CX510de","RR":"NO"},
    {"np":"80C8HY0","Model":"CX510dhe","Product":"3K -Yellow High Yield Return Program Toner Cartridge","Yield":"3000","AA":"80C8HY0CX510dhe","RR":"NO"},
    {"np":"80C8SC0","Model":"CX310dn","Product":"2K -Cyan Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SC0CX310dn","RR":"NO"},
    {"np":"80C8SC0","Model":"CX410de","Product":"2K -Cyan Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SC0CX410de","RR":"NO"},
    {"np":"80C8SC0","Model":"CX510de","Product":"2K -Cyan Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SC0CX510de","RR":"NO"},
    {"np":"80C8SC0","Model":"CX510dhe","Product":"2K -Cyan Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SC0CX510dhe","RR":"NO"},
    {"np":"80C8SK0","Model":"CX310dn","Product":"2.5K -Black Standard Yield Return Program Toner Cartridge","Yield":"2500","AA":"80C8SK0CX310dn","RR":"NO"},
    {"np":"80C8SK0","Model":"CX410de","Product":"2.5K -Black Standard Yield Return Program Toner Cartridge","Yield":"2500","AA":"80C8SK0CX410de","RR":"NO"},
    {"np":"80C8SK0","Model":"CX510de","Product":"2.5K -Black Standard Yield Return Program Toner Cartridge","Yield":"2500","AA":"80C8SK0CX510de","RR":"NO"},
    {"np":"80C8SK0","Model":"CX510dhe","Product":"2.5K -Black Standard Yield Return Program Toner Cartridge","Yield":"2500","AA":"80C8SK0CX510dhe","RR":"NO"},
    {"np":"80C8SM0","Model":"CX310dn","Product":"2K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SM0CX310dn","RR":"NO"},
    {"np":"80C8SM0","Model":"CX410de","Product":"2K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SM0CX410de","RR":"NO"},
    {"np":"80C8SM0","Model":"CX510de","Product":"2K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SM0CX510de","RR":"NO"},
    {"np":"80C8SM0","Model":"CX510dhe","Product":"2K -Magenta Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SM0CX510dhe","RR":"NO"},
    {"np":"80C8SY0","Model":"CX310dn","Product":"2K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SY0CX310dn","RR":"NO"},
    {"np":"80C8SY0","Model":"CX410de","Product":"2K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SY0CX410de","RR":"NO"},
    {"np":"80C8SY0","Model":"CX510de","Product":"2K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SY0CX510de","RR":"NO"},
    {"np":"80C8SY0","Model":"CX510dhe","Product":"2K -Yellow Standard Yield Return Program Toner Cartridge","Yield":"2000","AA":"80C8SY0CX510dhe","RR":"NO"},
    {"np":"80C8XC0","Model":"CX510de","Product":"4K - Cyan Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XC0CX510de","RR":"NO"},
    {"np":"80C8XC0","Model":"CX510dhe","Product":"4K - Cyan Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XC0CX510dhe","RR":"NO"},
    {"np":"80C8XK0","Model":"CX510de","Product":"8K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8000","AA":"80C8XK0CX510de","RR":"NO"},
    {"np":"80C8XK0","Model":"CX510dhe","Product":"8K -Black Extra High Yield  Return Program Toner Cartridge","Yield":"8000","AA":"80C8XK0CX510dhe","RR":"NO"},
    {"np":"80C8XM0","Model":"CX510de","Product":"4K -  Magenta Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XM0CX510de","RR":"NO"},
    {"np":"80C8XM0","Model":"CX510dhe","Product":"4K -  Magenta Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XM0CX510dhe","RR":"NO"},
    {"np":"80C8XY0","Model":"CX510de","Product":"4K - Yellow Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XY0CX510de","RR":"NO"},
    {"np":"80C8XY0","Model":"CX510dhe","Product":"4K - Yellow Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"80C8XY0CX510dhe","RR":"NO"},
    {"np":"82K4HC0","Model":"CX820de","Product":"17K -Cyan High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HC0CX820de","RR":"NO"},
    {"np":"82K4HC0","Model":"CX825de","Product":"17K -Cyan High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HC0CX825de","RR":"NO"},
    {"np":"82K4HC0","Model":"CX860de","Product":"17K -Cyan High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HC0CX860de","RR":"NO"},
    {"np":"82K4HM0","Model":"CX820de","Product":"17K -Magenta High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HM0CX820de","RR":"NO"},
    {"np":"82K4HM0","Model":"CX825de","Product":"17K -Magenta High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HM0CX825de","RR":"NO"},
    {"np":"82K4HM0","Model":"CX860de","Product":"17K -Magenta High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HM0CX860de","RR":"NO"},
    {"np":"82K4HY0","Model":"CX820de","Product":"17K -Yellow High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HY0CX820de","RR":"NO"},
    {"np":"82K4HY0","Model":"CX825de","Product":"17K -Yellow High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HY0CX825de","RR":"NO"},
    {"np":"82K4HY0","Model":"CX860de","Product":"17K -Yellow High Yield Return Program Toner Cartridge","Yield":"17000","AA":"82K4HY0CX860de","RR":"NO"},
    {"np":"82K4UC0","Model":"CX860de","Product":"55K -Cyan Ultra High Yield Return Program Toner Cartrige","Yield":"55000","AA":"82K4UC0CX860de","RR":"NO"},
    {"np":"82K4UK0","Model":"CX860de","Product":"55K -Black Ultra High Yield Return Program Toner Cartrige","Yield":"55000","AA":"82K4UK0CX860de","RR":"NO"},
    {"np":"82K4UM0","Model":"CX860de","Product":"55K -Magenta Ultra High Yield Return Program Toner Cartrige","Yield":"55000","AA":"82K4UM0CX860de","RR":"NO"},
    {"np":"82K4UY0","Model":"CX860de","Product":"55K -Yellow Ultra High Yield Return Program Toner Cartrige","Yield":"55000","AA":"82K4UY0CX860de","RR":"NO"},
    {"np":"82K4XC0","Model":"CX825de","Product":"22K -Cyan Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XC0CX825de","RR":"NO"},
    {"np":"82K4XC0","Model":"CX860de","Product":"22K -Cyan Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XC0CX860de","RR":"NO"},
    {"np":"82K4XM0","Model":"CX825de","Product":"22K -Magenta Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XM0CX825de","RR":"NO"},
    {"np":"82K4XM0","Model":"CX860de","Product":"22K -Magenta Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XM0CX860de","RR":"NO"},
    {"np":"82K4XY0","Model":"CX825de","Product":"22K -Yellow Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XY0CX825de","RR":"NO"},
    {"np":"82K4XY0","Model":"CX860de","Product":"22K -Yellow Extra High Yield Return Program Toner Cartrige","Yield":"22000","AA":"82K4XY0CX860de","RR":"NO"},
    {"np":"84C4HC0","Model":"CX725dhe","Product":"16K -Cyan High Yield Return Program Toner Cartridge","Yield":"16000","AA":"84C4HC0CX725dhe","RR":"NO"},
    {"np":"84C4HK0","Model":"CX725dhe","Product":"25K -Black High Yield Return Program Toner Cartridge","Yield":"25000","AA":"84C4HK0CX725dhe","RR":"NO"},
    {"np":"84C4HM0","Model":"CX725dhe","Product":"16K -Magenta High Yield Return Program Toner Cartridge","Yield":"16000","AA":"84C4HM0CX725dhe","RR":"NO"},
    {"np":"84C4HY0","Model":"CX725dhe","Product":"16K -Yellow High Yield Return Program Toner Cartridge","Yield":"16000","AA":"84C4HY0CX725dhe","RR":"NO"},
    {"np":"86C0HK0","Model":"CX921de","Product":"34K - Black High Yield Toner Cartridge","Yield":"34000","AA":"86C0HK0CX921de","RR":"NO"},
    {"np":"86C0HK0","Model":"CX922de","Product":"34K - Black High Yield Toner Cartridge","Yield":"34000","AA":"86C0HK0CX922de","RR":"NO"},
    {"np":"86C0HK0","Model":"CX923dte","Product":"34K - Black High Yield Toner Cartridge","Yield":"34000","AA":"86C0HK0CX923dte","RR":"NO"},
    {"np":"B220Z00","Model":"B2236dw","Product":"12K -Imaging Unit","Yield":"12000","AA":"B220Z00B2236dw","RR":"NO"},
    {"np":"B220Z00","Model":"MB2236adw","Product":"12K -Imaging Unit","Yield":"12000","AA":"B220Z00MB2236adw","RR":"NO"},
    {"np":"B220Z00","Model":"MB2236adwe","Product":"12K -Imaging Unit","Yield":"12000","AA":"B220Z00MB2236adwe","RR":"NO"},
    {"np":"B224000","Model":"B2236dw","Product":"1.2K -Standard Yield Toner","Yield":"1200","AA":"B224000B2236dw","RR":"NO"},
    {"np":"B224000","Model":"MB2236adw","Product":"1.2K -Standard Yield Toner","Yield":"1200","AA":"B224000MB2236adw","RR":"NO"},
    {"np":"B224000","Model":"MB2236adwe","Product":"1.2K -Standard Yield Toner","Yield":"1200","AA":"B224000MB2236adwe","RR":"NO"},
    {"np":"B224H00","Model":"B2236dw","Product":"3K -High Yield Toner","Yield":"3000","AA":"B224H00B2236dw","RR":"NO"},
    {"np":"B224H00","Model":"MB2236adw","Product":"3K -High Yield Toner","Yield":"3000","AA":"B224H00MB2236adw","RR":"NO"},
    {"np":"B224H00","Model":"MB2236adwe","Product":"3K -High Yield Toner","Yield":"3000","AA":"B224H00MB2236adwe","RR":"NO"},
    {"np":"B224X00","Model":"B2236dw","Product":"6K -Extra High Yield Toner","Yield":"6000","AA":"B224X00B2236dw","RR":"NO"},
    {"np":"B224X00","Model":"MB2236adw","Product":"6K -Extra High Yield Toner","Yield":"6000","AA":"B224X00MB2236adw","RR":"NO"},
    {"np":"B224X00","Model":"MB2236adwe","Product":"6K -Extra High Yield Toner","Yield":"6000","AA":"B224X00MB2236adwe","RR":"NO"},
    {"np":"C3240C0","Model":"C3224dw","Product":"1.5K - Cyan Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240C0C3224dw","RR":"NO"}
    ,
    {"np":"C3240C0","Model":"C3326dw","Product":"1.5K - Cyan Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240C0C3326dw","RR":"NO"}
    ,
    {"np":"C3240C0","Model":"MC3224adwe","Product":"1.5K - Cyan Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240C0MC3224adwe","RR":"NO"}
    ,
    {"np":"C3240C0","Model":"MC3224dwe","Product":"1.5K - Cyan Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240C0MC3224dwe","RR":"NO"}
    ,
    {"np":"C3240K0","Model":"C3224dw","Product":"1.5K - BlackReturn Program AM Print Cartridge�","Yield":"1500","AA":"C3240K0C3224dw","RR":"NO"}
    ,
    {"np":"C3240K0","Model":"C3326dw","Product":"1.5K - BlackReturn Program AM Print Cartridge�","Yield":"1500","AA":"C3240K0C3326dw","RR":"NO"}
    ,
    {"np":"C3240K0","Model":"MC3224adwe","Product":"1.5K - BlackReturn Program AM Print Cartridge�","Yield":"1500","AA":"C3240K0MC3224adwe","RR":"NO"}
    ,
    {"np":"C3240K0","Model":"MC3224dwe","Product":"1.5K - BlackReturn Program AM Print Cartridge�","Yield":"1500","AA":"C3240K0MC3224dwe","RR":"NO"}
    ,
    {"np":"C3240M0","Model":"C3224dw","Product":"1.5K - Magenta Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240M0C3224dw","RR":"NO"}
    ,
    {"np":"C3240M0","Model":"C3326dw","Product":"1.5K - Magenta Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240M0C3326dw","RR":"NO"}
    ,
    {"np":"C3240M0","Model":"MC3224adwe","Product":"1.5K - Magenta Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240M0MC3224adwe","RR":"NO"}
    ,
    {"np":"C3240M0","Model":"MC3224dwe","Product":"1.5K - Magenta Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240M0MC3224dwe","RR":"NO"}
    ,
    {"np":"C3240Y0","Model":"C3224dw","Product":"1.5K - Yellow 1.5K Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240Y0C3224dw","RR":"NO"}
    ,
    {"np":"C3240Y0","Model":"C3326dw","Product":"1.5K - Yellow 1.5K Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240Y0C3326dw","RR":"NO"}
    ,
    {"np":"C3240Y0","Model":"MC3224adwe","Product":"1.5K - Yellow 1.5K Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240Y0MC3224adwe","RR":"NO"}
    ,
    {"np":"C3240Y0","Model":"MC3224dwe","Product":"1.5K - Yellow 1.5K Return Program AM Print Cartridge�","Yield":"1500","AA":"C3240Y0MC3224dwe","RR":"NO"}
    ,
    {"np":"C334HC0","Model":"C3326dw","Product":"2.5K - Cyan Return Program AM Print Cartridge�","Yield":"2500","AA":"C334HC0C3326dw","RR":"NO"}
    ,
    {"np":"C334HK0","Model":"C3326dw","Product":"3K - BlackReturn Program AM Print Cartridge�","Yield":"3000","AA":"C334HK0C3326dw","RR":"NO"}
    ,
    {"np":"C334HM0","Model":"C3326dw","Product":"2.5K - Magenta Return Program AM Print Cartridge�","Yield":"2500","AA":"C334HM0C3326dw","RR":"NO"}
    ,
    {"np":"C334HY0","Model":"C3326dw","Product":"2.5K - Yellow 1.5K Return Program AM Print Cartridge�","Yield":"2500","AA":"C334HY0C3326dw","RR":"NO"}
    ,
    {"np":"C500H2CG","Model":"C500/X50x","Product":"C500, X500, X502 Cyan High Yield Toner Cartridge","Yield":"3000","AA":"C500H2CGC500/X50x","RR":"NO"},
    {"np":"C500H2KG","Model":"C500/X50x","Product":"C500, X500, X502 Black High Yield Toner Cartridge","Yield":"5000","AA":"C500H2KGC500/X50x","RR":"NO"},
    {"np":"C500H2MG","Model":"C500/X50x","Product":"C500, X500, X502 Magenta High Yield Toner Cartridge","Yield":"3000","AA":"C500H2MGC500/X50x","RR":"NO"},
    {"np":"C500H2YG","Model":"C500/X50x","Product":"C500, X500, X502 Yellow High Yield Toner Cartridge","Yield":"3000","AA":"C500H2YGC500/X50x","RR":"NO"},
    {"np":"C500S2CG","Model":"C500/X50x","Product":"C500, X500, X502 Cyan Toner Cartridge","Yield":"1500","AA":"C500S2CGC500/X50x","RR":"NO"},
    {"np":"C500S2KG","Model":"C500/X50x","Product":"C500, X500, X502 Black Toner Cartridge","Yield":"2500","AA":"C500S2KGC500/X50x","RR":"NO"},
    {"np":"C500S2MG","Model":"C500/X50x","Product":"C500, X500, X502 Magenta Toner Cartridge","Yield":"1500","AA":"C500S2MGC500/X50x","RR":"NO"},
    {"np":"C500S2YG","Model":"C500/X50x","Product":"C500, X500, X502 Yellow Toner Cartridge","Yield":"1500","AA":"C500S2YGC500/X50x","RR":"NO"},
    {"np":"C500X26G","Model":"C500/X50x","Product":"C500, X500, X502 Photodeveloper Cartridge","Yield":"120000","AA":"C500X26GC500/X50x","RR":"NO"},
    {"np":"C500X27G","Model":"C500/X50x","Product":"C500, X500, X502 Waste Toner Bottle","Yield":"30000","AA":"C500X27GC500/X50x","RR":"NO"},
    {"np":"C500X28G","Model":"C500/X50x","Product":"C500, C510, X500, X502 Fuser Maintenance Kit 110-120V","Yield":"60000","AA":"C500X28GC500/X50x","RR":"NO"},
    {"np":"C5200CS","Model":"C52x/C53x","Product":"C520, C530 Cyan Return Program Toner Cartridge","Yield":"1500","AA":"C5200CSC52x/C53x","RR":"NO"},
    {"np":"C5200KS","Model":"C52x/C53x","Product":"C520, C530 Black Return Program Toner Cartridge","Yield":"1500","AA":"C5200KSC52x/C53x","RR":"NO"},
    {"np":"C5200MS","Model":"C52x/C53x","Product":"C520, C530 Magenta Return Program Toner Cartridge","Yield":"1500","AA":"C5200MSC52x/C53x","RR":"NO"},
    {"np":"C5200YS","Model":"C52x/C53x","Product":"C520, C530 Yellow Return Program Toner Cartridge","Yield":"1500","AA":"C5200YSC52x/C53x","RR":"NO"},
    {"np":"C52025X","Model":"C52x/C53x","Product":"C52x, C53x Waste Toner Box","Yield":"30000","AA":"C52025XC52x/C53x","RR":"NO"},
    {"np":"C5220CS","Model":"C52x/C53x","Product":"C522, C524, C53x Cyan Return Program Toner Cartridge","Yield":"3000","AA":"C5220CSC52x/C53x","RR":"NO"},
    {"np":"C5220KS","Model":"C52x/C53x","Product":"C522, C524, C53x Black Return Program Toner Cartridge","Yield":"4000","AA":"C5220KSC52x/C53x","RR":"NO"},
    {"np":"C5220MS","Model":"C52x/C53x","Product":"C522, C524, C53x Magenta Return Program Toner Cartridge","Yield":"3000","AA":"C5220MSC52x/C53x","RR":"NO"},
    {"np":"C5220YS","Model":"C52x/C53x","Product":"C522, C524, C53x Yellow Return Program Toner Cartridge","Yield":"3000","AA":"C5220YSC52x/C53x","RR":"NO"},
    {"np":"C5222CS","Model":"C52x/C53x","Product":"C522, C524, C53x Cyan Toner Cartridge","Yield":"3000","AA":"C5222CSC52x/C53x","RR":"NO"},
    {"np":"C5222KS","Model":"C52x/C53x","Product":"C522, C524, C53x Black Toner Cartridge","Yield":"4000","AA":"C5222KSC52x/C53x","RR":"NO"},
    {"np":"C5222MS","Model":"C52x/C53x","Product":"C522, C524, C53x Magenta Toner Cartridge","Yield":"3000","AA":"C5222MSC52x/C53x","RR":"NO"},
    {"np":"C5222YS","Model":"C52x/C53x","Product":"C522, C524, C53x Yellow Toner Cartridge","Yield":"3000","AA":"C5222YSC52x/C53x","RR":"NO"},
    {"np":"C5240CH","Model":"C52x/C53x","Product":"C524, C532, C534 Cyan High Yield Return Program Toner Cartridge","Yield":"5000","AA":"C5240CHC52x/C53x","RR":"NO"},
    {"np":"C5240KH","Model":"C52x/C53x","Product":"C524, C534 Black High Yield Return Program Toner Cartridge","Yield":"8000","AA":"C5240KHC52x/C53x","RR":"NO"},
    {"np":"C5240MH","Model":"C52x/C53x","Product":"C524, C532, C534 Magenta High Yield Return Program Toner Cartridge","Yield":"5000","AA":"C5240MHC52x/C53x","RR":"NO"},
    {"np":"C5240YH","Model":"C52x/C53x","Product":"C524, C532, C534 Yellow High Yield Return Program Toner Cartridge","Yield":"5000","AA":"C5240YHC52x/C53x","RR":"NO"},
    {"np":"C5242CH","Model":"C52x/C53x","Product":"C524, C532, C534 Cyan High Yield Toner Cartridge","Yield":"5000","AA":"C5242CHC52x/C53x","RR":"NO"},
    {"np":"C5242MH","Model":"C52x/C53x","Product":"C524, C532, C534 Magenta High Yield Toner Cartridge","Yield":"5000","AA":"C5242MHC52x/C53x","RR":"NO"},
    {"np":"C5242YH","Model":"C52x/C53x","Product":"C524, C532, C534 Yellow High Yield Toner Cartridge","Yield":"5000","AA":"C5242YHC52x/C53x","RR":"NO"},
    {"np":"C53030X","Model":"C52x/C53x","Product":"C52x, C53x Photoconductor Unit 1-Pack","Yield":"20000","AA":"C53030XC52x/C53x","RR":"NO"},
    {"np":"C53034X","Model":"C52x/C53x","Product":"C52x, C53x Photoconductor Unit 4-Pack","Yield":"80000","AA":"C53034XC52x/C53x","RR":"NO"},
    {"np":"C5340CX","Model":"C52x/C53x","Product":"C534 Cyan Extra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"C5340CXC52x/C53x","RR":"NO"},
    {"np":"C5340MX","Model":"C52x/C53x","Product":"C534 Magenta Extra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"C5340MXC52x/C53x","RR":"NO"},
    {"np":"C5340YX","Model":"C52x/C53x","Product":"C534 Yellow Extra High Yield Return Program Toner Cartridge","Yield":"7000","AA":"C5340YXC52x/C53x","RR":"NO"},
    {"np":"C5342CX","Model":"C52x/C53x","Product":"C534 Cyan Extra High Yield Toner Cartridge","Yield":"7000","AA":"C5342CXC52x/C53x","RR":"NO"},
    {"np":"C540A1CG","Model":"C54x/X54x","Product":"C54x, X54x Cyan Return Program Toner Cartridge","Yield":"1000","AA":"C540A1CGC54x/X54x","RR":"NO"},
    {"np":"C540A1KG","Model":"C54x/X54x","Product":"C54x, X54x Black Return Program Toner Cartridge","Yield":"1000","AA":"C540A1KGC54x/X54x","RR":"NO"},
    {"np":"C540A1MG","Model":"C54x/X54x","Product":"C54x, X54x Magenta Return Program Toner Cartridge","Yield":"1000","AA":"C540A1MGC54x/X54x","RR":"NO"},
    {"np":"C540A1YG","Model":"C54x/X54x","Product":"C54x, X54x Yellow Return Program Toner Cartridge","Yield":"1000","AA":"C540A1YGC54x/X54x","RR":"NO"},
    {"np":"C540H1CG","Model":"C54x/X54x","Product":"C54x, X54x Cyan High Yield Return Program Toner Cartridge","Yield":"2000","AA":"C540H1CGC54x/X54x","RR":"NO"},
    {"np":"C540H1KG","Model":"C54x/X54x","Product":"C54x, X54x Black High Yield Return Program Toner Cartridge","Yield":"2500","AA":"C540H1KGC54x/X54x","RR":"NO"},
    {"np":"C540H1MG","Model":"C54x/X54x","Product":"C54x, X54x Magenta High Yield Return Program Toner Cartridge","Yield":"2000","AA":"C540H1MGC54x/X54x","RR":"NO"},
    {"np":"C540H1YG","Model":"C54x/X54x","Product":"C54x, X54x Yellow High Yield Return Program Toner Cartridge","Yield":"2000","AA":"C540H1YGC54x/X54x","RR":"NO"},
    {"np":"C540X35G","Model":"C54x/X54x","Product":"C54x, X54x Photoconductor Unit","Yield":"30000","AA":"C540X35GC54x/X54x","RR":"NO"},
    {"np":"C540X71G","Model":"C54x/X54x","Product":"C54x, X54x Black Imaging Kit","Yield":"30000","AA":"C540X71GC54x/X54x","RR":"NO"},
    {"np":"C540X74G","Model":"C54x/X54x","Product":"C54x, X54x Black and Color Imaging Kit","Yield":"30000","AA":"C540X74GC54x/X54x","RR":"NO"},
    {"np":"C540X75G","Model":"CS310dn","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCS310dn","RR":"NO"},
    {"np":"C540X75G","Model":"CS510de","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCS510de","RR":"NO"},
    {"np":"C540X75G","Model":"CX310dn","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCX310dn","RR":"NO"},
    {"np":"C540X75G","Model":"CX410de","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCX410de","RR":"NO"},
    {"np":"C540X75G","Model":"CX510de","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCX510de","RR":"NO"},
    {"np":"C540X75G","Model":"CX510dhe","Product":"36K -Waste Toner Bottle","Yield":"36000","AA":"C540X75GCX510dhe","RR":"NO"},
    {"np":"C544X1CG","Model":"C54x/X54x","Product":"C544/6, X544/546, X548 Cyan Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"C544X1CGC54x/X54x","RR":"NO"},
    {"np":"C544X1KG","Model":"C54x/X54x","Product":"C544, X544 Black Extra High Yield Return Program Toner Cartridge","Yield":"6000","AA":"C544X1KGC54x/X54x","RR":"NO"},
    {"np":"C544X1MG","Model":"C54x/X54x","Product":"C544/6, X544/546, X548 Magenta Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"C544X1MGC54x/X54x","RR":"NO"},
    {"np":"C544X1YG","Model":"C54x/X54x","Product":"C544/6, X544/546, X548 Yellow Extra High Yield Return Program Toner Cartridge","Yield":"4000","AA":"C544X1YGC54x/X54x","RR":"NO"},
    {"np":"C734A1CG","Model":"C73x/X73x","Product":"C734, C736, X734, X736, X738 Cyan Return Program Toner Cartridge","Yield":"6000","AA":"C734A1CGC73x/X73x","RR":"NO"},
    {"np":"C734A1KG","Model":"C73x/X73x","Product":"C734, C736, X734, X736, X738 Black Return Program Toner Cartridge","Yield":"8000","AA":"C734A1KGC73x/X73x","RR":"NO"},
    {"np":"C734A1MG","Model":"C73x/X73x","Product":"C734, C736, X734, X736, X738 Magenta Return Program Toner Cartridge","Yield":"6000","AA":"C734A1MGC73x/X73x","RR":"NO"},
    {"np":"C734A1YG","Model":"C73x/X73x","Product":"C734, C736, X734, X736, X738 Yellow Return Program Toner Cartridge","Yield":"6000","AA":"C734A1YGC73x/X73x","RR":"NO"},
    {"np":"C734X20G","Model":"C73x/X73x","Product":"C73x, C74x, X73x, X74x Photoconductor Unit, 1-Pack","Yield":"20000","AA":"C734X20GC73x/X73x","RR":"NO"},
    {"np":"C734X24G","Model":"C73x/X73x","Product":"C73x, C74x, X73x, X74x Photoconductor Unit, 4-Pack","Yield":"80000","AA":"C734X24GC73x/X73x","RR":"NO"},
    {"np":"C734X77G","Model":"C73x/X73x","Product":"C73x, C74x, X73x, X74x Waste Toner Box","Yield":"25000","AA":"C734X77GC73x/X73x","RR":"NO"},
    {"np":"C736H1CG","Model":"C73x/X73x","Product":"C736, X736, X738 Cyan High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C736H1CGC73x/X73x","RR":"NO"},
    {"np":"C736H1KG","Model":"C73x/X73x","Product":"C736, X736, X738 Black High Yield Return Program Toner Cartridge","Yield":"12000","AA":"C736H1KGC73x/X73x","RR":"NO"},
    {"np":"C736H1MG","Model":"C73x/X73x","Product":"C736, X736, X738 Magenta High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C736H1MGC73x/X73x","RR":"NO"},
    {"np":"C736H1YG","Model":"C73x/X73x","Product":"C736, X736, X738 Yellow High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C736H1YGC73x/X73x","RR":"NO"},
    {"np":"C746A1CG","Model":"C74x","Product":"7K -Cyan Return Program Toner Cartridge","Yield":"7000","AA":"C746A1CGC74x","RR":"NO"},
    {"np":"C746A1MG","Model":"C74x","Product":"7K -Magenta Return Program Toner Cartridge","Yield":"7000","AA":"C746A1MGC74x","RR":"NO"},
    {"np":"C746A1YG","Model":"C74x","Product":"7K -Yellow Return Program Toner Cartridge","Yield":"7000","AA":"C746A1YGC74x","RR":"NO"},
    {"np":"C746H1KG","Model":"C74x","Product":"12K -Black High Yield Return Program Toner Cartridge","Yield":"12000","AA":"C746H1KGC74x","RR":"NO"},
    {"np":"C748H1CG","Model":"C74x","Product":"10K -Cyan High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C748H1CGC74x","RR":"NO"},
    {"np":"C748H1MG","Model":"C74x","Product":"10K -Magenta High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C748H1MGC74x","RR":"NO"},
    {"np":"C748H1YG","Model":"C74x","Product":"10K -Yellow High Yield Return Program Toner Cartridge","Yield":"10000","AA":"C748H1YGC74x","RR":"NO"},
    {"np":"C7700CH","Model":"C77x","Product":"C770, C772 Cyan High Yield Return Program Print Cartridge","Yield":"10000","AA":"C7700CHC77x","RR":"NO"},
    {"np":"C7700CS","Model":"C77x","Product":"C770, C772 Cyan Return Program Print Cartridge","Yield":"6000","AA":"C7700CSC77x","RR":"NO"},
    {"np":"C7700KH","Model":"C77x","Product":"C770, C772 Black High Yield Return Program Print Cartridge","Yield":"10000","AA":"C7700KHC77x","RR":"NO"},
    {"np":"C7700KS","Model":"C77x","Product":"C770, C772 Black Return Program Print Cartridge","Yield":"6000","AA":"C7700KSC77x","RR":"NO"},
    {"np":"C7700MH","Model":"C77x","Product":"C770, C772 Magenta High Yield Return Program Print Cartridge","Yield":"10000","AA":"C7700MHC77x","RR":"NO"},
    {"np":"C7700MS","Model":"C77x","Product":"C770, C772 Magenta Return Program Print Cartridge","Yield":"6000","AA":"C7700MSC77x","RR":"NO"},
    {"np":"C7700YH","Model":"C77x","Product":"C770, C772 Yellow High Yield Return Program Print Cartridge","Yield":"10000","AA":"C7700YHC77x","RR":"NO"},
    {"np":"C7700YS","Model":"C77x","Product":"C770, C772 Yellow Return Program Print Cartridge","Yield":"6000","AA":"C7700YSC77x","RR":"NO"},
    {"np":"C7720CX","Model":"C77x","Product":"C772 Cyan Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C7720CXC77x","RR":"NO"},
    {"np":"C7720KX","Model":"C77x","Product":"C772 Black Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C7720KXC77x","RR":"NO"},
    {"np":"C7720MX","Model":"C77x","Product":"C772 Magenta Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C7720MXC77x","RR":"NO"},
    {"np":"C7720YX","Model":"C77x","Product":"C772 Yellow Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C7720YXC77x","RR":"NO"},
    {"np":"C7722CX","Model":"C77x","Product":"C772 Cyan Extra High Yield Print Cartridge","Yield":"15000","AA":"C7722CXC77x","RR":"NO"},
    {"np":"C7722KX","Model":"C77x","Product":"C772 Black Extra High Yield Print Cartridge","Yield":"15000","AA":"C7722KXC77x","RR":"NO"},
    {"np":"C780A1CG","Model":"C78x","Product":"C780, C782 Cyan Return Program Print Cartridge","Yield":"6000","AA":"C780A1CGC78x","RR":"NO"},
    {"np":"C780A1KG","Model":"C78x","Product":"C780, C782 Black Return Program Print Cartridge","Yield":"6000","AA":"C780A1KGC78x","RR":"NO"},
    {"np":"C780A1MG","Model":"C78x","Product":"C780, C782 Magenta Return Program Print Cartridge","Yield":"6000","AA":"C780A1MGC78x","RR":"NO"},
    {"np":"C780A1YG","Model":"C78x","Product":"C780, C782 Yellow Return Program Print Cartridge","Yield":"6000","AA":"C780A1YGC78x","RR":"NO"},
    {"np":"C780A2YG","Model":"C78x","Product":"C780, C782 Yellow Print Cartridge","Yield":"6000","AA":"C780A2YGC78x","RR":"NO"},
    {"np":"C780H1CG","Model":"C78x","Product":"C780, C782 Cyan High Yield Return Program Print Cartridge","Yield":"10000","AA":"C780H1CGC78x","RR":"NO"},
    {"np":"C780H1KG","Model":"C78x","Product":"C780, C782 Black High Yield Return Program Print Cartridge","Yield":"10000","AA":"C780H1KGC78x","RR":"NO"},
    {"np":"C780H1MG","Model":"C78x","Product":"C780, C782 Magenta High Yield Return Program Print Cartridge","Yield":"10000","AA":"C780H1MGC78x","RR":"NO"},
    {"np":"C780H1YG","Model":"C78x","Product":"C780, C782 Yellow High Yield Return Program Print Cartridge","Yield":"10000","AA":"C780H1YGC78x","RR":"NO"},
    {"np":"C780H2CG","Model":"C78x","Product":"C780, C782 Cyan High Yield Print Cartridge","Yield":"10000","AA":"C780H2CGC78x","RR":"NO"},
    {"np":"C780H2MG","Model":"C78x","Product":"C780, C782 Magenta High Yield Print Cartridge","Yield":"10000","AA":"C780H2MGC78x","RR":"NO"},
    {"np":"C780H2YG","Model":"C78x","Product":"C780, C782 Yellow High Yield Print Cartridge","Yield":"10000","AA":"C780H2YGC78x","RR":"NO"},
    {"np":"C782X1CG","Model":"C78x","Product":"C782 Cyan Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C782X1CGC78x","RR":"NO"},
    {"np":"C782X1KG","Model":"C78x","Product":"C782 Black Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C782X1KGC78x","RR":"NO"},
    {"np":"C782X1MG","Model":"C78x","Product":"C782 Magenta Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C782X1MGC78x","RR":"NO"},
    {"np":"C782X1YG","Model":"C78x","Product":"C782 Yellow Extra High Yield Return Program Print Cartridge","Yield":"15000","AA":"C782X1YGC78x","RR":"NO"},
    {"np":"C782X2CG","Model":"C78x","Product":"C782 Cyan Extra High Yield Print Cartridge","Yield":"15000","AA":"C782X2CGC78x","RR":"NO"},
    {"np":"C792X1CG","Model":"C792","Product":"20K -Cyan Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"C792X1CGC792","RR":"NO"},
    {"np":"C792X1KG","Model":"C792","Product":"20K -Black Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"C792X1KGC792","RR":"NO"},
    {"np":"C792X1MG","Model":"C792","Product":"20K -Magenta Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"C792X1MGC792","RR":"NO"},
    {"np":"C792X1YG","Model":"C792","Product":"20K -Yellow Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"C792X1YGC792","RR":"NO"},
    {"np":"C792X77G","Model":"C792","Product":"50K -Waste Toner Bottle","Yield":"50000","AA":"C792X77GC792","RR":"NO"},
    {"np":"C9202CH","Model":"C920","Product":"C920 Cyan Toner Cartridge","Yield":"14000","AA":"C9202CHC920","RR":"NO"},
    {"np":"C9202KH","Model":"C920","Product":"C920 Black Toner Cartridge","Yield":"15000","AA":"C9202KHC920","RR":"NO"},
    {"np":"C9202MH","Model":"C920","Product":"C920 Magenta Toner Cartridge","Yield":"14000","AA":"C9202MHC920","RR":"NO"},
    {"np":"C9202YH","Model":"C920","Product":"C920 Yellow Toner Cartridge","Yield":"14000","AA":"C9202YHC920","RR":"NO"},
    {"np":"C92035X","Model":"C920","Product":"C920, C912, C910 Oil Coating Roller","Yield":"15000","AA":"C92035XC920","RR":"NO"},
    {"np":"C925H2CG","Model":"C925","Product":"7.5K -Cyan High Yield Toner Cartridge","Yield":"7500","AA":"C925H2CGC925","RR":"NO"},
    {"np":"C925H2CG","Model":"C925de","Product":"7.5K -Cyan High Yield Toner Cartridge","Yield":"7500","AA":"C925H2CGC925de","RR":"NO"},
    {"np":"C925H2KG","Model":"C925","Product":"8.5K -Black High Yield Toner Cartridge","Yield":"8500","AA":"C925H2KGC925","RR":"NO"},
    {"np":"C925H2KG","Model":"C925de","Product":"8.5K -Black High Yield Toner Cartridge","Yield":"8500","AA":"C925H2KGC925de","RR":"NO"},
    {"np":"C925H2MG","Model":"C925","Product":"7.5K -Magenta High Yield Toner Cartridge","Yield":"7500","AA":"C925H2MGC925","RR":"NO"},
    {"np":"C925H2MG","Model":"C925de","Product":"7.5K -Magenta High Yield Toner Cartridge","Yield":"7500","AA":"C925H2MGC925de","RR":"NO"},
    {"np":"C925H2YG","Model":"C925","Product":"7.5K -Yellow High Yield Toner Cartridge","Yield":"7500","AA":"C925H2YGC925","RR":"NO"},
    {"np":"C925H2YG","Model":"C925de","Product":"7.5K -Yellow High Yield Toner Cartridge","Yield":"7500","AA":"C925H2YGC925de","RR":"NO"},
    {"np":"C925X72G","Model":"C925/X925","Product":"30K- Black Imaging Unit Photoconductor","Yield":"30000","AA":"C925X72GC925/X925","RR":"NO"},
    {"np":"C925X72G","Model":"C925de","Product":"30K- Black Imaging Unit Photoconductor","Yield":"30000","AA":"C925X72GC925de","RR":"NO"},
    {"np":"C925X72G","Model":"X925de","Product":"30K -Black Imaging Unit","Yield":"30000","AA":"C925X72GX925de","RR":"NO"},
    {"np":"C925X73G","Model":"C925/X925","Product":"30K -Cyan Imaging Unit Photoconductor","Yield":"30000","AA":"C925X73GC925/X925","RR":"NO"},
    {"np":"C925X73G","Model":"C925de","Product":"30K -Cyan Imaging Unit Photoconductor","Yield":"30000","AA":"C925X73GC925de","RR":"NO"},
    {"np":"C925X73G","Model":"X925de","Product":"30K -Cyan Imaging Unit","Yield":"30000","AA":"C925X73GX925de","RR":"NO"},
    {"np":"C925X74G","Model":"C925/X925","Product":"30K -Magenta Imaging Unit Photoconductor","Yield":"30000","AA":"C925X74GC925/X925","RR":"NO"},
    {"np":"C925X74G","Model":"C925de","Product":"30K -Magenta Imaging Unit Photoconductor","Yield":"30000","AA":"C925X74GC925de","RR":"NO"},
    {"np":"C925X74G","Model":"X925de","Product":"30K- Magenta Imaging Unit","Yield":"30000","AA":"C925X74GX925de","RR":"NO"},
    {"np":"C925X75G","Model":"C925/X925","Product":"30K -Yellow Imaging Unit Photoconductor","Yield":"30000","AA":"C925X75GC925/X925","RR":"NO"},
    {"np":"C925X75G","Model":"C925de","Product":"30K -Yellow Imaging Unit Photoconductor","Yield":"30000","AA":"C925X75GC925de","RR":"NO"},
    {"np":"C925X75G","Model":"X925de","Product":"30K -Yellow Imaging Unit","Yield":"30000","AA":"C925X75GX925de","RR":"NO"},
    {"np":"C925X76G","Model":"C925/X925","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C925X76GC925/X925","RR":"NO"},
    {"np":"C925X76G","Model":"C925de","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C925X76GC925de","RR":"NO"},
    {"np":"C925X76G","Model":"X925de","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C925X76GX925de","RR":"NO"},
    {"np":"C930H2CG","Model":"C935","Product":"C935 Cyan High Yield Toner Cartridge","Yield":"24000","AA":"C930H2CGC935","RR":"NO"},
    {"np":"C930H2KG","Model":"C935","Product":"C935 Black High Yield Toner Cartridge","Yield":"38000","AA":"C930H2KGC935","RR":"NO"},
    {"np":"C930H2MG","Model":"C935","Product":"C935 Magenta High Yield Toner Cartridge","Yield":"24000","AA":"C930H2MGC935","RR":"NO"},
    {"np":"C930H2YG","Model":"C935","Product":"C935 Yellow High Yield Toner Cartridge","Yield":"24000","AA":"C930H2YGC935","RR":"NO"},
    {"np":"C930X72G","Model":"C935","Product":"C935, X940e, X945e Black Photoconductor Unit 1-Pack","Yield":"53000","AA":"C930X72GC935","RR":"NO"},
    {"np":"C930X73G","Model":"C935","Product":"C935, X940e, X945e CMY Photoconductor Unit 3-Pack","Yield":"141000","AA":"C930X73GC935","RR":"NO"},
    {"np":"C930X76G","Model":"C935","Product":"C935, X940e, X945e Waste Toner Bottle","Yield":"30000","AA":"C930X76GC935","RR":"NO"},
    {"np":"C950X2CG","Model":"C950","Product":"22K -Cyan High Yield Toner Cartridge","Yield":"22000","AA":"C950X2CGC950","RR":"NO"},
    {"np":"C950X2CG","Model":"C950de","Product":"22K -Cyan High Yield Toner Cartridge","Yield":"22000","AA":"C950X2CGC950de","RR":"NO"},
    {"np":"C950X2KG","Model":"C950","Product":"32K -Black High Yield Toner Cartridge","Yield":"32000","AA":"C950X2KGC950","RR":"NO"},
    {"np":"C950X2KG","Model":"C950de","Product":"32K -Black High Yield Toner Cartridge","Yield":"32000","AA":"C950X2KGC950de","RR":"NO"},
    {"np":"C950X2MG","Model":"C950","Product":"22K -Magenta High Yield Toner Cartridge","Yield":"22000","AA":"C950X2MGC950","RR":"NO"},
    {"np":"C950X2MG","Model":"C950de","Product":"22K -Magenta High Yield Toner Cartridge","Yield":"22000","AA":"C950X2MGC950de","RR":"NO"},
    {"np":"C950X2YG","Model":"C950","Product":"22K -Yellow High Yield Toner Cartridge","Yield":"22000","AA":"C950X2YGC950","RR":"NO"},
    {"np":"C950X2YG","Model":"C950de","Product":"22K -Yellow High Yield Toner Cartridge","Yield":"22000","AA":"C950X2YGC950de","RR":"NO"},
    {"np":"C950X71G","Model":"C950/X95x","Product":"115K -Photoconductor Unit 1-Pack","Yield":"115000","AA":"C950X71GC950/X95x","RR":"NO"},
    {"np":"C950X71G","Model":"C950de","Product":"115K -Black Photoconductor Kit","Yield":"115000","AA":"C950X71GC950de","RR":"NO"},
    {"np":"C950X71G","Model":"X950de","Product":"115K -Photoconductor Unit 1-Pack","Yield":"115000","AA":"C950X71GX950de","RR":"NO"},
    {"np":"C950X71G","Model":"X952dte","Product":"115K- Photoconductor Unit 1-Pack","Yield":"115000","AA":"C950X71GX952dte","RR":"NO"},
    {"np":"C950X71G","Model":"X954dhe","Product":"115K -Photoconductor Unit 1-Pack","Yield":"115000","AA":"C950X71GX954dhe","RR":"NO"},
    {"np":"C950X73G","Model":"C950/X95x","Product":"115K -Photoconductor Unit 3-Pack","Yield":"115000","AA":"C950X73GC950/X95x","RR":"NO"},
    {"np":"C950X73G","Model":"C950de","Product":"115K -Color Photoconductor Unit 3-Pack","Yield":"115000","AA":"C950X73GC950de","RR":"NO"},
    {"np":"C950X73G","Model":"X950de","Product":"115K -Photoconductor Unit 3-Pack","Yield":"115000","AA":"C950X73GX950de","RR":"NO"},
    {"np":"C950X73G","Model":"X952dte","Product":"115K -Photoconductor Unit 3-Pack","Yield":"115000","AA":"C950X73GX952dte","RR":"NO"},
    {"np":"C950X73G","Model":"X954dhe","Product":"115K -Photoconductor Unit 3-Pack","Yield":"115000","AA":"C950X73GX954dhe","RR":"NO"},
    {"np":"C950X76G","Model":"C950/X95x","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C950X76GC950/X95x","RR":"NO"},
    {"np":"C950X76G","Model":"C950de","Product":"30K- Waste Toner Bottle","Yield":"30000","AA":"C950X76GC950de","RR":"NO"},
    {"np":"C950X76G","Model":"X950de","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C950X76GX950de","RR":"NO"},
    {"np":"C950X76G","Model":"X952dte","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C950X76GX952dte","RR":"NO"},
    {"np":"C950X76G","Model":"X954dhe","Product":"30K -Waste Toner Bottle","Yield":"30000","AA":"C950X76GX954dhe","RR":"NO"},
    {"np":"E250A11L","Model":"E250/E35x","Product":"E250, E350, E352 Return Program Toner Cartridge","Yield":"3500","AA":"E250A11LE250/E35x","RR":"NO"},
    {"np":"E250A21L","Model":"E250/E35x","Product":"E250, E350, E352 Toner Cartridge","Yield":"3500","AA":"E250A21LE250/E35x","RR":"NO"},
    {"np":"E250X22G","Model":"E250/E35x","Product":"E250, E350, E352, E450 Photoconductor Kit","Yield":"30000","AA":"E250X22GE250/E35x","RR":"NO"},
    {"np":"E260A11L","Model":"E260/E360/E46x","Product":"E260, E360, E460, E462 Return Program Toner Cartridge","Yield":"3500","AA":"E260A11LE260/E360/E46x","RR":"NO"},
    {"np":"E260A21L","Model":"E260/E360/E46x","Product":"E260, E360, E460, E462 Toner Cartridge","Yield":"3500","AA":"E260A21LE260/E360/E46x","RR":"NO"},
    {"np":"E260X22G","Model":"E260/E360/E46x","Product":"E260, E360, E46x, X264, X36x, X46x Photoconductor Kit","Yield":"30000","AA":"E260X22GE260/E360/E46x","RR":"NO"},
    {"np":"E352H11L","Model":"E250/E35x","Product":"E350, E352 High Yield Return Program Toner Cartridge","Yield":"9000","AA":"E352H11LE250/E35x","RR":"NO"},
    {"np":"E352H21L","Model":"E250/E35x","Product":"E350, E352 High Yield Toner Cartridge","Yield":"9000","AA":"E352H21LE250/E35x","RR":"NO"},
    {"np":"E360H11L","Model":"E260/E360/E46x","Product":"E360, E460, E462 High Yield Return Program Toner Cartridge","Yield":"9000","AA":"E360H11LE260/E360/E46x","RR":"NO"},
    {"np":"E360H21L","Model":"E260/E360/E46x","Product":"E360, E460, E462 High Yield Toner Cartridge","Yield":"9000","AA":"E360H21LE260/E360/E46x","RR":"NO"},
    {"np":"E450A11L","Model":"E450","Product":"E450 Return Program Toner Cartridge","Yield":"6000","AA":"E450A11LE450","RR":"NO"},
    {"np":"E450A21L","Model":"E450","Product":"E450 Toner Cartridge","Yield":"6000","AA":"E450A21LE450","RR":"NO"},
    {"np":"E450H11L","Model":"E450","Product":"E450 High Yield Return Program Toner Cartridge","Yield":"11000","AA":"E450H11LE450","RR":"NO"},
    {"np":"E450H21L","Model":"E450","Product":"E450 High Yield Toner Cartridge","Yield":"11000","AA":"E450H21LE450","RR":"NO"},
    {"np":"E460X11L","Model":"E260/E360/E46x","Product":"E460 Extra High Yield Return Program Toner Cartridge","Yield":"15000","AA":"E460X11LE260/E360/E46x","RR":"NO"},
    {"np":"E460X21L","Model":"E260/E360/E46x","Product":"E460 Extra High Yield Toner Cartridge","Yield":"15000","AA":"E460X21LE260/E360/E46x","RR":"NO"},
    {"np":"STI-09-93077551-SEC9720","Model":"MS610dn","Product":"Secure MICR SIMM","Yield":"","AA":"STI-09-93077551-SEC9720MS610dn","RR":"NO"},
    {"np":"STI-09-93077551-SEC9730","Model":"MS810dn","Product":"Secure MICR SIMM","Yield":"","AA":"STI-09-93077551-SEC9730MS810dn","RR":"NO"},
    {"np":"STI-09-93077551-TSO9720","Model":"MS610dn","Product":"Standard MICR SIMM","Yield":"","AA":"STI-09-93077551-TSO9720MS610dn","RR":"NO"},
    {"np":"STI-09-93077551-TSO9730","Model":"MS810dn","Product":"Standard MICR SIMM","Yield":"","AA":"STI-09-93077551-TSO9730MS810dn","RR":"NO"},
    {"np":"STI-204065H-LAP-24B6236","Model":"MS810dn","Product":"17K -MICR toner","Yield":"17000","AA":"STI-204065H-LAP-24B6236MS810dn","RR":"NO"},
    {"np":"STI-204065-LAP-24B6235","Model":"MS810dn","Product":"8K -MICR toner","Yield":"8000","AA":"STI-204065-LAP-24B6235MS810dn","RR":"NO"},
    {"np":"STI-204514H-LAP-24B6234","Model":"MS610dn","Product":"12K -MICR toner","Yield":"12000","AA":"STI-204514H-LAP-24B6234MS610dn","RR":"NO"},
    {"np":"STI-204514-LAP-24B6233","Model":"MS610dn","Product":"5K -ST9712 MICR toner","Yield":"5000","AA":"STI-204514-LAP-24B6233MS610dn","RR":"NO"},
    {"np":"STI-24B6241","Model":"MS610dn","Product":"40K -ST9712 MICR Imaging Unit","Yield":"40000","AA":"STI-24B6241MS610dn","RR":"NO"},
    {"np":"STI-24B6242","Model":"MS810dn","Product":"70K -MICR Imaging Unit","Yield":"70000","AA":"STI-24B6242MS810dn","RR":"NO"},
    {"np":"T650A11L","Model":"T65x","Product":"T650, T652, T654, T656 Return Program Print Cartridge","Yield":"7000","AA":"T650A11LT65x","RR":"NO"},
    {"np":"T650H04L","Model":"T65x","Product":"T650, T652, T654, T656 High Yield Return Program Print Cartridge for Labels","Yield":"25000","AA":"T650H04LT65x","RR":"NO"},
    {"np":"T650H11L","Model":"T65x","Product":"T650, T652, T654, T656 High Yield Return Program Print Cartridge","Yield":"25000","AA":"T650H11LT65x","RR":"NO"},
    {"np":"T654X04L","Model":"T65x","Product":"T654, T656 Extra High Yield Return Program Print Cartridge for Labels","Yield":"36000","AA":"T654X04LT65x","RR":"NO"},
    {"np":"T654X11L","Model":"T65x","Product":"T654, T656 Extra High Yield Return Program Print Cartridge","Yield":"36000","AA":"T654X11LT65x","RR":"NO"},
    {"np":"W84020H","Model":"W840","Product":"W840 Toner Cartridge","Yield":"30000","AA":"W84020HW840","RR":"NO"},
    {"np":"W84030H","Model":"W841","Product":"W840 Photoconductor Kit","Yield":"60000","AA":"W84030HW841","RR":"NO"},
    {"np":"X203A11G","Model":"X20x","Product":"X203, X204 Return Program Toner Cartridge","Yield":"2500","AA":"X203A11GX20x","RR":"NO"},
    {"np":"X203H22G","Model":"X20x","Product":"X203, X204 Photoconductor Kit","Yield":"25000","AA":"X203H22GX20x","RR":"NO"},
    {"np":"X264A11G","Model":"X26x/X36x","Product":"X264, X363, X364 Return Program Toner Cartridge","Yield":"3500","AA":"X264A11GX26x/X36x","RR":"NO"},
    {"np":"X264H11G","Model":"X26x/X36x","Product":"X264, X363, X364 High Yield Return Program Toner Cartridge","Yield":"9000","AA":"X264H11GX26x/X36x","RR":"NO"},
    {"np":"X340A11G","Model":"X340/X342","Product":"X340, X342 Return Program Toner Cartridge","Yield":"2500","AA":"X340A11GX340/X342","RR":"NO"},
    {"np":"X340A21G","Model":"X340/X342","Product":"X340, X342 Toner Cartridge","Yield":"2500","AA":"X340A21GX340/X342","RR":"NO"},
    {"np":"X340H11G","Model":"X340/X342","Product":"X342 High Yield Return Program Toner Cartridge","Yield":"6000","AA":"X340H11GX340/X342","RR":"NO"},
    {"np":"X340H21G","Model":"X340/X342","Product":"X342 High Yield Toner Cartridge","Yield":"6000","AA":"X340H21GX340/X342","RR":"NO"},
    {"np":"X340H22G","Model":"X340/X342","Product":"X340, X342 Photoconductor Kit","Yield":"30000","AA":"X340H22GX340/X342","RR":"NO"},
    {"np":"X463A11G","Model":"X46x","Product":"X463, X464, X466 Return Program Toner Cartridge","Yield":"3500","AA":"X463A11GX46x","RR":"NO"},
    {"np":"X463H11G","Model":"X46x","Product":"X463, X464, X466 High Yield Return Program Toner Cartridge","Yield":"9000","AA":"X463H11GX46x","RR":"NO"},
    {"np":"X463X11G","Model":"X46x","Product":"X463, X464, X466 Extra High Yield Return Program Toner Cartridge","Yield":"15000","AA":"X463X11GX46x","RR":"NO"},
    {"np":"X560A2CG","Model":"X560","Product":"X560 Cyan Print Cartridge","Yield":"4000","AA":"X560A2CGX560","RR":"NO"},
    {"np":"X560A2MG","Model":"X560","Product":"X560 Magenta Print Cartridge","Yield":"4000","AA":"X560A2MGX560","RR":"NO"},
    {"np":"X560A2YG","Model":"X560","Product":"X560 Yellow Print Cartridge","Yield":"4000","AA":"X560A2YGX560","RR":"NO"},
    {"np":"X560H2CG","Model":"X560","Product":"X560 Cyan High Yield Print Cartridge","Yield":"10000","AA":"X560H2CGX560","RR":"NO"},
    {"np":"X560H2KG","Model":"X560","Product":"X560 Black High Yield Print Cartridge","Yield":"10000","AA":"X560H2KGX560","RR":"NO"},
    {"np":"X560H2MG","Model":"X560","Product":"X560 Magenta High Yield Print Cartridge","Yield":"10000","AA":"X560H2MGX560","RR":"NO"},
    {"np":"X560H2YG","Model":"X560","Product":"X560 Yellow High Yield Print Cartridge","Yield":"10000","AA":"X560H2YGX560","RR":"NO"},
    {"np":"X644A11L","Model":"X64x","Product":"X642e, X644e, X646e Return Program Print Cartridge","Yield":"10000","AA":"X644A11LX64x","RR":"NO"},
    {"np":"X644A21L","Model":"X64x","Product":"X642e, X644e, X646e Print Cartridge","Yield":"10000","AA":"X644A21LX64x","RR":"NO"},
    {"np":"X644H01L","Model":"X64x","Product":"X642e, X644e, X646e High Yield Return Program Print Cartridge for Labels","Yield":"21000","AA":"X644H01LX64x","RR":"NO"},
    {"np":"X644H11L","Model":"X64x","Product":"X642e, X644e, X646e High Yield Return Program Print Cartridge","Yield":"21000","AA":"X644H11LX64x","RR":"NO"},
    {"np":"X644H21L","Model":"X64x","Product":"X642e, X644e, X646e High Yield Print Cartridge","Yield":"21000","AA":"X644H21LX64x","RR":"NO"},
    {"np":"X644X01L","Model":"X64x","Product":"X644e, X646e Extra High Yield Return Program Print Cartridge for Labels","Yield":"32000","AA":"X644X01LX64x","RR":"NO"},
    {"np":"X644X11L","Model":"X64x","Product":"X644e, X646e Extra High Yield Return Program Print Cartridge","Yield":"32000","AA":"X644X11LX64x","RR":"NO"},
    {"np":"X644X21L","Model":"X64x","Product":"X644e, X646e Extra High Yield Print Cartridge","Yield":"32000","AA":"X644X21LX64x","RR":"NO"},
    {"np":"X651A11L","Model":"X64x","Product":"X651, X652, X654, X656, X658 Return Program Print Cartridge","Yield":"7000","AA":"X651A11LX64x","RR":"NO"},
    {"np":"X651H11L","Model":"X64x","Product":"X651, X652, X654, X656, X658 High Yield Return Program Print Cartridge","Yield":"25000","AA":"X651H11LX64x","RR":"NO"},
    {"np":"X651H21L","Model":"X64x","Product":"X651, X652, X654, X656, X658 High Yield Print Cartridge","Yield":"25000","AA":"X651H21LX64x","RR":"NO"},
    {"np":"X654X11L","Model":"X64x","Product":"X654, X656, X658 Extra High Yield Return Program Print Cartridge","Yield":"36000","AA":"X654X11LX64x","RR":"NO"},
    {"np":"X654X21L","Model":"X64x","Product":"X654, X656, X658 Extra High Yield Print Cartridge","Yield":"36000","AA":"X654X21LX64x","RR":"NO"},
    {"np":"X746H1KG","Model":"X74x","Product":"12K -Black High Yield Return Program Toner Cartridge","Yield":"12000","AA":"X746H1KGX74x","RR":"NO"},
    {"np":"X748H1CG","Model":"X74x","Product":"10K -Cyan High Yield Return Program Toner Cartridge","Yield":"10000","AA":"X748H1CGX74x","RR":"NO"},
    {"np":"X748H1MG","Model":"X74x","Product":"10K -Magenta High Yield Return Program Toner Cartridge","Yield":"10000","AA":"X748H1MGX74x","RR":"NO"},
    {"np":"X748H1YG","Model":"X74x","Product":"10K -Yellow High Yield Return Program Toner Cartridge","Yield":"10000","AA":"X748H1YGX74x","RR":"NO"},
    {"np":"X792X1CG","Model":"X792","Product":"20K -Cyan Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"X792X1CGX792","RR":"NO"},
    {"np":"X792X1KG","Model":"X792","Product":"20K -Black Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"X792X1KGX792","RR":"NO"},
    {"np":"X792X1MG","Model":"X792","Product":"20K -Magenta Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"X792X1MGX792","RR":"NO"},
    {"np":"X792X1YG","Model":"X792","Product":"20K -Yellow Extra High Yield Return Program Print Cartridge","Yield":"20000","AA":"X792X1YGX792","RR":"NO"},
    {"np":"X850H21G","Model":"X85x","Product":"X850e, X852e, X854e Toner Cartridge","Yield":"30000","AA":"X850H21GX85x","RR":"NO"},
    {"np":"X850H22G","Model":"X85x","Product":"X850e, X852e, X854e Photoconductor Kit","Yield":"60000","AA":"X850H22GX85x","RR":"NO"},
    {"np":"X925H2CG","Model":"X925","Product":"7.5K -Cyan High Yield Toner Cartridge","Yield":"7500","AA":"X925H2CGX925","RR":"NO"},
    {"np":"X925H2CG","Model":"X925de","Product":"7.5K -Cyan High Yield Toner Cartridge","Yield":"7500","AA":"X925H2CGX925de","RR":"NO"},
    {"np":"X925H2KG","Model":"X925","Product":"8.5K -Black High Yield Toner Cartridge","Yield":"8500","AA":"X925H2KGX925","RR":"NO"},
    {"np":"X925H2KG","Model":"X925de","Product":"8.5K -Black High Yield Toner Cartridge","Yield":"8500","AA":"X925H2KGX925de","RR":"NO"},
    {"np":"X925H2MG","Model":"X925","Product":"7.5K  -Magenta High Yield Toner Cartridge","Yield":"7500","AA":"X925H2MGX925","RR":"NO"},
    {"np":"X925H2MG","Model":"X925de","Product":"7.5K  -Magenta High Yield Toner Cartridge","Yield":"7500","AA":"X925H2MGX925de","RR":"NO"},
    {"np":"X925H2YG","Model":"X925","Product":"7.5K -Yellow High Yield Toner Cartridge","Yield":"7500","AA":"X925H2YGX925","RR":"NO"},
    {"np":"X925H2YG","Model":"X925de","Product":"7.5K -Yellow High Yield Toner Cartridge","Yield":"7500","AA":"X925H2YGX925de","RR":"NO"},
    {"np":"X945X2CG","Model":"X94x","Product":"X940e, X945e Cyan High Yield Toner Cartridge","Yield":"22000","AA":"X945X2CGX94x","RR":"NO"},
    {"np":"X945X2KG","Model":"X94x","Product":"X940e, X945e Black High Yield Toner Cartridge","Yield":"36000","AA":"X945X2KGX94x","RR":"NO"},
    {"np":"X945X2MG","Model":"X94x","Product":"X940e, X945e Magenta High Yield Toner Cartridge","Yield":"22000","AA":"X945X2MGX94x","RR":"NO"},
    {"np":"X945X2YG","Model":"X94x","Product":"X940e, X945e Yellow High Yield Toner Cartridge","Yield":"22000","AA":"X945X2YGX94x","RR":"NO"},
    {"np":"X950X2CG","Model":"X950de","Product":"22K -Cyan Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2CGX950de","RR":"NO"},
    {"np":"X950X2CG","Model":"X952dte","Product":"22K -Cyan Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2CGX952dte","RR":"NO"},
    {"np":"X950X2CG","Model":"X954dhe","Product":"22K -Cyan High Yield Toner Cartridge","Yield":"22000","AA":"X950X2CGX954dhe","RR":"NO"},
    {"np":"X950X2CG","Model":"X95x","Product":"22K -Cyan High Yield Toner Cartridge","Yield":"22000","AA":"X950X2CGX95x","RR":"NO"},
    {"np":"X950X2KG","Model":"X950de","Product":"32K -Black Extra High Yield Toner Cartridge","Yield":"32000","AA":"X950X2KGX950de","RR":"NO"},
    {"np":"X950X2KG","Model":"X952dte","Product":"32K -Black Extra High Yield Toner Cartridge","Yield":"32000","AA":"X950X2KGX952dte","RR":"NO"},
    {"np":"X950X2KG","Model":"X954dhe","Product":"32K -Black  High Yield Toner Cartridge","Yield":"32000","AA":"X950X2KGX954dhe","RR":"NO"},
    {"np":"X950X2KG","Model":"X95x","Product":"32K -Black  High Yield Toner Cartridge","Yield":"32000","AA":"X950X2KGX95x","RR":"NO"},
    {"np":"X950X2MG","Model":"X950de","Product":"22K -Magenta Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2MGX950de","RR":"NO"},
    {"np":"X950X2MG","Model":"X952dte","Product":"22K -Magenta Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2MGX952dte","RR":"NO"},
    {"np":"X950X2MG","Model":"X954dhe","Product":"22K -Magenta High Yield Toner Cartridge","Yield":"22000","AA":"X950X2MGX954dhe","RR":"NO"},
    {"np":"X950X2MG","Model":"X95x","Product":"22K -Magenta High Yield Toner Cartridge","Yield":"22000","AA":"X950X2MGX95x","RR":"NO"},
    {"np":"X950X2YG","Model":"X950de","Product":"22K -Yellow Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2YGX950de","RR":"NO"},
    {"np":"X950X2YG","Model":"X952dte","Product":"22K -Yellow Extra High Yield Toner Cartridge","Yield":"22000","AA":"X950X2YGX952dte","RR":"NO"},
    {"np":"X950X2YG","Model":"X954dhe","Product":"22K -Yellow High Yield Toner Cartridge","Yield":"22000","AA":"X950X2YGX954dhe","RR":"NO"},
    {"np":"X950X2YG","Model":"X95x","Product":"22K -Yellow High Yield Toner Cartridge","Yield":"22000","AA":"X950X2YGX95x","RR":"NO"},
    {"np":"52D4X00","Model":"MX711dhe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX711dhe","RR":"#REF!"},
    {"np":"52D4X00","Model":"MX810dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dfe","RR":"NO"},
    {"np":"52D4X00","Model":"MX810dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dme","RR":"NO"},
    {"np":"52D4X00","Model":"MX810dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX810dpe","RR":"NO"},
    {"np":"52D4X00","Model":"MX811dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dfe","RR":"NO"},
    {"np":"52D4X00","Model":"MX811dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dme","RR":"NO"},
    {"np":"52D4X00","Model":"MX811dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX811dpe","RR":"NO"},
    {"np":"52D4X00","Model":"MX812dfe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dfe","RR":"NO"},
    {"np":"52D4X00","Model":"MX812dme","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dme","RR":"NO"},
    {"np":"52D4X00","Model":"MX812dpe","Product":"45K -Extra High Yield Toner","Yield":"45000","AA":"62D4X00MX812dpe","RR":"NO"},
    {"np":"50F4U00","Model":"MX511de","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX511de","RR":"NO"},
    {"np":"50F4U00","Model":"MX611dfe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX611dfe","RR":"NO"},
    {"np":"50F4U00","Model":"MX611dhe","Product":"20K -Extra High Yield Toner","Yield":"20000","AA":"60F4X00MX611dhe","RR":"NO"},
    {"np":"60F4X00","Model":"MX310dn","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX310dn","RR":"NO"},
    {"np":"60F4X00","Model":"MX410de","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX410de","RR":"NO"},
    {"np":"60F4X00","Model":"MX511de","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX511de","RR":"NO"},
    {"np":"60F4X00","Model":"MX611dfe","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX611dfe","RR":"NO"},
    {"np":"60F4X00","Model":"MX611dhe","Product":"10K -High Yield Toner","Yield":"10000","AA":"60F4H00MX611dhe","RR":"NO"},
    {"np":"52D4H00","Model":"MX710dhe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX710dhe","RR":"NO"},
    {"np":"52D4H00","Model":"MX711dhe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX711dhe","RR":"NO"},
    {"np":"52D4H00","Model":"MX810dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dfe","RR":"NO"},
    {"np":"52D4H00","Model":"MX810dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dme","RR":"NO"},
    {"np":"52D4H00","Model":"MX810dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX810dpe","RR":"NO"},
    {"np":"52D4H00","Model":"MX811dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dfe","RR":"NO"},
    {"np":"52D4H00","Model":"MX811dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dme","RR":"NO"},
    {"np":"52D4H00","Model":"MX811dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX811dpe","RR":"NO"},
    {"np":"52D4H00","Model":"MX812dfe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dfe","RR":"NO"},
    {"np":"52D4H00","Model":"MX812dme","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dme","RR":"NO"},
    {"np":"52D4H00","Model":"MX812dpe","Product":"25K -High Yield Toner","Yield":"25000","AA":"62D4H00MX812dpe","RR":"NO"},
    {"np":"T650A11L","Model":"X64x","Product":"X651, X652, X654, X656, X658 Return Program Print Cartridge","Yield":"7000","AA":"X651A11LX64x","RR":"NO"},
    {"np":"T650H11L","Model":"X64x","Product":"X651, X652, X654, X656, X658 High Yield Return Program Print Cartridge","Yield":"25000","AA":"X651H11LX64x","RR":"NO"},
    {"np":"T654X11L","Model":"X64x","Product":"X654, X656, X658 Extra High Yield Return Program Print Cartridge","Yield":"36000","AA":"X654X11LX64x","RR":"NO"}
    ]

    export default ListSuppliesJson;