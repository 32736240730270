import { AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react'
import { BsArrowCounterclockwise, BsBookmarkCheck, BsBookmarks, BsBookmarkStar, BsBoxSeam, BsCardChecklist, BsCaretUp, BsCursor, BsMinecartLoaded, BsPencilSquare, BsPhone, BsRecord, BsRecycle, BsReverseLayoutTextSidebarReverse, BsReverseLayoutTextWindowReverse } from 'react-icons/bs';
import { Row } from 'reactstrap';

// import all certification teams 
import DistribuidorAutorizadoDeEquipos from './DistribuidorAutorizadoDeEquipos';

// import the component DistribuidorAutorizado
import DistribuidorAutorizadoDeSoftware from '../../components/DistribuidorAutorizadoDeSoftware/item';

// import the component CertificadoTecnico
import CertificadoTecnico from '../../components/CertificadoTecnico/index';

// import the component CertificacionASP
import CertificadoASP from '../../components/CertificadoASP/index';

// import the component GarantiaTecnicaDeEquiposNuevos
import GarantiaTecnicaDeEquiposNuevos from '../../components/GarantiaTecnicaDeEquiposNuevos/index';

// import the component CertificadoTecnicoDeSoftware
import CertificadoTecnicoDeSoftware from '../../components/CertificadoTecnicoDeSoftware/index';

// import the component CertificadoEquipoOptimasCondiciones
import CertificadoEquipoOptimasCondiciones from '../../components/CertificadoEquipoOptimasCondiciones/index';

// this variable will represent different supplies
const supplies = [
    {   // this certificate not provide extra information
        id: 1,
        name: 'DISTRIBUIDOR AUTORIZADO DE EQUIPOS',
        title: 'DISTRIBUIDOR_AUTORIZADO_DE_EQUIPOS',
        icon: <BsMinecartLoaded />,
        name_component: ''
    },
    {   // valid if the certification dont need a information
        id: 2,
        name: 'DISTRIBUIDOR AUTORIZADO DE SOFTWARE',
        title: 'DISTRBUIDOR_AUTORIZADO_DE_SOFTWARE',
        icon: <BsPhone />,
        name_component:
            <DistribuidorAutorizadoDeSoftware
                title='DISTRIBUIDOR AUTORIZADO DE SOFTWARE'
            />
    },
    {   // valid if the certification dont need a information
        id: 99,
        name: 'CERTIFICADO COMPATIBILIDAD DE SOFTWARE',
        title: 'CERTIFICADO COMPATIBILIDAD DE SOFTWARE',
        icon: <BsPhone />,
        name_component:
            <DistribuidorAutorizadoDeSoftware
                title='CERTIFICADO COMPATIBILIDAD DE SOFTWARE'
                id="099"
                code="CER_EQU_013"
            />,
        code: "CER_EQU_013"
    },
    {   // valid if the certification dont need a information
        id: 3,
        name: 'GARANTÍA TÉCNICA',
        title: 'GARANTÍA_TÉCNICA_EQUIPOS_NUEVOS',
        icon: <BsPencilSquare />,
        name_component:
            <GarantiaTecnicaDeEquiposNuevos
                title='GARANTÍA TÉCNICA'
            />
    },
    /*
    {   // valid if the certification dont need a information
        id: 4,
        name: 'GARANTÍA TÉCNICA EQUIPOS USADOS',
        title: 'GARANTÍA_TÉCNICA_EQUIPOS_USADOS',
        icon: <BsRecycle />,
        name_component:
            <GarantiaTecnicaDeEquiposNuevos
                title='GARANTÍA TÉCNICA EQUIPOS USADOS'
            />
    },
    */
    /*
    {   // valid if the certification dont need a information
        id: 5,
        name: 'CERTIFICADO DE RESPONSABILIDAD SOCIAL',
        title: 'CERTIFICADO_DE_RESPONSABILIDAD_SOCIAL',
        icon: <BsRecycle />,
        name_component:
            <DistribuidorAutorizadoDeSoftware
                title='CERTIFICADO DE RESPONSABILIDAD SOCIAL'
            />
    },
    {   // valid if the certification dont need a information
        id: 6,
        name: 'COMPATIBILIDAD SOFTWARE PROPIEDAD DE LEXMARK ',
        title: 'COMPATIBILIDAD_SOFTWARE_PROPIEDAD_DE_LEXMARK ',
        icon: <BsRecycle />,
        name_component:
            <DistribuidorAutorizadoDeSoftware
                title='COMPATIBILIDAD SOFTWARE PROPIEDAD DE LEXMARK'
            />
    },
    {   // valid if the certification dont need a information
        id: 7,
        name: 'COMPATIBILIDAD SOFTWARE PROPIEDAD DE LEXMARK',
        title: 'COMPATIBILIDAD_SOFTWARE_PROPIEDAD_DE_LEXMARK',
        icon: <BsRecycle />,
        name_component:
            <DistribuidorAutorizadoDeEquipos
                title='COMPATIBILIDAD SOFTWARE PROPIEDAD DE LEXMARK'
            />
    },
    */
    /*
    {   // i dont know where i find information about this certification
        id: 5,
        name: 'CERTIFICADO DE VIGENCIA TECNOLÓGICA',
        title: 'CERTIFICADO_DE_VIGENCIA_TECNOLÓGICA',
        icon: <BsRecord />,
        name_component:
            <DistribuidorAutorizadoDeEquipos
                title='CERTIFICADO DE VIGENCIA TECNOLÓGICA'
            />
    },
    {
        id: 6,
        name: 'CERTIFICADO DE EQUIPOS NUEVOS',
        title: 'CERTIFICADO_DE_EQUIPOS_NUEVOS',
        icon: <BsCursor />,
        name_component:
            <DistribuidorAutorizadoDeEquipos
                title='CERTIFICADO DE EQUIPOS NUEVOS'
            />
    },
    {
        id: 7,
        name: 'CERTIFICADO CAS',
        title: 'CERTIFICADO_CAS',
        icon: <BsCursor />,
        name_component:
            <DistribuidorAutorizadoDeEquipos
                title='CERTIFICADO CAS'
            />
    },
    */
    {   // valid if the certification dont need a information
        id: 8,
        name: 'STOCK SUMINISTROS',
        title: 'STOCK_SUMINISTROS',
        icon: <BsCursor />,
        name_component: ''
    },
    {   // ready to use
        id: 9,
        name: 'CERTIFICADO TÉCNICOS EQUIPOS',
        title: 'CERTIFICADO_TÉCNICOS_EQUIPOS',
        icon: <BsReverseLayoutTextSidebarReverse />,
        name_component:
            <CertificadoTecnico
                title='CERTIFICADO TÉCNICOS EQUIPOS'
            />
    },
    {   // ready to use
        id: 10,
        name: 'CERTIFICADO TÉCNICOS SOFTWARE',
        title: 'CERTIFICADO_TÉCNICOS_SOFTWARE',
        icon: <BsCursor />,
        name_component:
            <CertificadoTecnicoDeSoftware
                title='CERTIFICADO TÉCNICOS SOFTWARE'
            />
    },
    {   // validate where i can find information about this certification
        id: 11,
        name: 'CERTIFICADO AMBIENTAL',
        title: 'CERTIFICADO_AMBIENTAL',
        icon: <BsArrowCounterclockwise />,
        name_component: ''
    },
    { // ready to use
        id: 13,
        name: 'COMUNICADO DE ORIGINALIDAD',
        title: 'COMUNICADO_DE_ORIGINALIDAD',
        icon: <BsBookmarks />,
        name_component: ''
    },
    {
        id: 14,
        name: 'CERTIFICADO DE ASP',
        title: 'CERTIFICADO_DE_ASP',
        icon: <BsBookmarkStar />,
        name_component:
            <CertificadoASP
                title='CERTIFICADO DE ASP'
            />
    },
    {
        id: 15,
        name: 'CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES',
        title: 'CERTIFICADO_EQUIPOS_EN_OPTIMAS_CONDICIONES',
        icon: <BsCardChecklist />,
        name_component:
            <CertificadoEquipoOptimasCondiciones
                title='CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES'
            />
    },
    {
        id: 16,
        name: 'CERTIFICADO DE EQUIPOS NUEVOS',
        title: 'CERTIFICADO_DE_EQUIPOS_NUEVOS',
        icon: <BsBoxSeam />,
        name_component:
            <CertificadoEquipoOptimasCondiciones
                title='CERTIFICADO DE EQUIPOS NUEVOS'
            />
    },
    {
        id: 17,
        name: 'CERTIFICADO CAS',
        title: 'CERTIFICADO_CAS',
        icon: <BsCaretUp />,
        name_component: ''
    }
]


const Teams = ({ addToListTeams, removeToListTeams }) => {

    const [suppliesSelected, setSuppliesSelected] = useState([]);

    // this function will be used to add or not supplies into list
    const addSuppliesToList = (selected) => {

        const supply = suppliesSelected.find(item => {
            return item.name === selected.name
        })

        // add to supply into list
        if (!supply) {
            // add supply into list
            setSuppliesSelected((prov) => {
                return [
                    ...prov,
                    selected
                ]
            })
            // now we need to change the color of the div component
            let container = document.getElementById(selected.name);
            container.style.backgroundColor = '#008945'
            container.style.color = 'white'

            let title = document.getElementById(selected.title)
            title.style.color = 'white'

            addToListTeams(selected)


        } else { // delete supply into list

            setSuppliesSelected((prov) => {
                const filter = prov.filter(item => {
                    return item.name !== supply.name
                })
                return filter
            })

            let container = document.getElementById(selected.name);
            container.style.backgroundColor = 'white'
            container.style.color = '#433A40'

            let title = document.getElementById(selected.title)
            title.style.color = '#433A40'

            removeToListTeams(selected)
        }

    }

    const renderedSupplies = Object.values(supplies).map(item => {
        return (
            <div
                key={item.id}
                id={item.name}
                className="form-check" style={{
                    border: '1px solid #CDCDCD',
                    marginTop: 20
                }}
                onClick={() => addSuppliesToList(item)}
            >
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                        <i style={{ fontSize: 25, marginRight: 10 }}>
                            {item.icon}
                        </i>
                        <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0  , color : 'white' }}>
                            {item.name}
                        </h6>
                    </div>
                    {/*
                    <div style={{ marginRight: 30, display: 'flex' }}>
                        <input style={{ margin: 'auto' }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                     */}
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    CERTIFICADO
                </h4>
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </>
    )

}

export default Teams;