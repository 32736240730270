import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col } from 'reactstrap';

const ListSuppliesAdmin = ({ title, handleChangeListSuppliesSelected, suministros = [] }) => {

    // this variable will be control items of the list
    const [listsComponentItem, setListsComponentItem] = useState(suministros);
    const [amountItemIntoList, setAmountItemIntoList] = useState(0)


    useEffect(() => {
        setListsComponentItem(suministros)
        setAmountItemIntoList(suministros.length)
    })


    // rendered the list of the item list part number
    const renderedItemList = Object.values(listsComponentItem).map((item, index) => {
        return (
            <div key={index}>
                <div className="row mb-1 mt-3">
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            NUMERO DE PARTE
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            MODELO
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            DESCRIPCIÓN
                        </p>
                    </div>
                    <div className="col-2">
                        <p style={{ fontWeight: 'bold' }}>
                            CANTIDAD
                        </p>
                    </div>
                </div>

                <div className="row">

                    <div className="col-3">
                        <input
                            class="form-control"
                            value={item.partno}
                            disabled
                        />
                    </div>

                    <div className="col-3">
                        <input
                            class="form-control"
                            value={item.model}
                            disabled
                        />
                    </div>

                    <div className="col-3">
                        <input
                            class="form-control"
                            value={item.descripcion}
                            disabled
                        />
                    </div>

                    <div className="col-3 d-flex">
                        <input
                            class="form-control"
                            type="text"
                            value={item.cantidad}
                            disabled
                        />
                    </div>

                </div>
            </div>
        )
    })


    return (
        <>
            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                <Card style={{ height: '100%' }}>
                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="d-flex justify-content-between" style={{ marginTop: 20 }}>
                            <h4 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                {title}
                            </h4>
                        </div>
                        <div id="list-supplies">
                            {amountItemIntoList === 0 ?
                                (
                                    <h6 style={{
                                        textAlign: 'center'

                                    }}>LISTA DE {title} </h6>
                                ) :
                                renderedItemList
                            }

                        </div>
                    </CardBody>
                </Card>
            </Col>

        </>
    )
}

export default ListSuppliesAdmin;