import React, { useState, useEffect } from "react"
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs"

import ListSuppliesJson from './ListSuppliesJson'

const Item = ({
    id,
    addItemIntoListCertification,
    removeItemIntoListCertification,
    partno,
    descripcion,
    modelo,
    cantidad
}) => {

    const [idItem, setIdItem] = useState(id)

    // this variable will be uses to create a technical certification
    const [ciudad, setCiudad] = useState('')
    const [direccion, setDireccion] = useState('')

    // this variable represent if the component is or not deleted
    const [isDeleted, setIsDeleted] = useState(false)
    // this variable control if the component was clicked 
    const [isClicked, setIsClicked] = useState(false)

    // this variable will be used to create a supply
    const [np, setNp] = useState(partno ? partno : '')
    const [model, setModel] = useState(modelo ? modelo : '')
    const [description, setDescription] = useState(descripcion ? descripcion : '')
    const [amount, setAmount] = useState(cantidad ? cantidad : '')

    const [isParams, setIsParams] = useState(false)

    useEffect(() => {
        if (partno && !isParams) {
            setNp(partno)
            setModel(modelo)
            setDescription(descripcion)
            //setAmount(cantidad)
            setIsParams(true)
        }
    })

    // this variable will be used to save the supplies
    const [suppliesList, setSuppliesList] = useState([
        { np: "55B4000", Model: "MS331dn", Product: "3K -Standard Yield Toner", yield: "3,000" },
        { np: "55B4H00", Model: "MS331dn", Product: "15K -High Yield Toner", yield: "15,000" },
        { np: "55B0ZA0", Model: "MS331dn", Product: "40K -Black PC ", yield: "40,000" },
        { np: "2371703", Model: "MS331dn", Product: "MS331 1yr OSR  NBD", yield: "" },
        { np: "2371704", Model: "MS331dn", Product: "MS331 2yr OSR  NBD", yield: "" },
        { np: "2371705", Model: "MS331dn", Product: "MS331 3yr OSR  NBD", yield: "" },
    ])

    useEffect(() => {
        setSuppliesList(() => {
            return ListSuppliesJson.map(item => {
                return {
                    np: item.np.toString(),
                    Model: item.Model,
                    Product: item.Product,
                }
            })
        })
    }, [])

    // this will be used to send the product to the parent component
    // but this will be send only if the all inputs are completed
    useEffect(() => {

        try {

            if (np.trim() !== '' && model.trim() !== '' && description.trim() !== '' && amount.trim() !== '') {
                addItemIntoListCertification(
                    {
                        "id": id,
                        "partno": np,
                        "model": model,
                        "descripcion": description,
                        "cantidad": amount
                    })
            }

        } catch (error) {
            console.log(error)
        }

    }, [np, model, description, amount])

    // this function will be used to rendered by np
    const renderedByNP = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.np}
                label={item.np + " - " + item.Model.toUpperCase() + " - " + item.Product.toUpperCase()}
            />
        )
    })

    // this function will be used to rendered by model
    const renderedByProduct = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.Product}
                label={item.np + " - " + item.Model.toUpperCase() + " - " + item.Product.toUpperCase()}
            />
        )
    })

    // this function will be used to rendered by model
    const renderedByModel = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.Model}
                label={item.np + " - " + item.Model.toUpperCase() + " - " + item.Product.toUpperCase()}
            />
        )
    })

    // this function will be used to get the product by description product,
    // once the product is searched we need to complete the other inputs with 
    // the value has the element selected
    const selectByProductDescription = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.Product;
        })

        if (element) {
            setNp(element.np);
            setModel(element.Model)
            setDescription(element.Product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp('');
            setModel('')
            setDescription(e.target.value)
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    // this function will be used to get the product by np,
    // once the product is searched we need to complete the other inputs with 
    // the value has the element selected
    const selectByNp = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.np;
        })

        if (element) {
            setNp(element.np);
            setModel(element.Model)
            setDescription(element.Product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp(e.target.value);
            setModel('')
            setDescription('')
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    const selectByModel = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.Model;
        })

        if (element) {
            setNp(element.np);
            setModel(element.Model)
            setDescription(element.Product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp('');
            setModel(e.target.value)
            setDescription('')
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    // change the amount value
    const changeTheAmountValue = (e) => {
        const value = e.target.value
        if (value.trim().length < 0 || value === '') {
            setIsClicked(true)
        } else {
            setIsClicked(false)
        }
        setAmount(value)
    }

    // this function will be used to delete the component
    const deleteComponentClicked = () => {
        // pass the id of the item to delete from the parent component
        removeItemIntoListCertification(idItem)
        setIsDeleted(true)
    }

    return (
        <>  {!isDeleted && (
            <div>
                <div className="row mb-1 mt-3">
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            NUMERO DE PARTE
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            MODELO
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            DESCRIPCIÓN
                        </p>
                    </div>
                    <div className="col-2">
                        <p style={{ fontWeight: 'bold' }}>
                            CANTIDAD
                        </p>
                    </div>
                </div>

                <div className="row">

                    <div className="col-3">
                        <input
                            class="form-control"
                            list="datalistOptions"
                            value={np}
                            onChange={(e) => selectByNp(e)}
                        />
                        <datalist id="datalistOptions">
                            {renderedByNP}
                        </datalist>
                    </div>

                    <div className="col-3">
                        <input
                            class="form-control"
                            list="datalistOptions3"
                            value={model}
                            onChange={(e) => selectByModel(e)}
                        />
                        <datalist id="datalistOptions3">
                            {renderedByModel}
                        </datalist>
                    </div>

                    <div className="col-3">
                        <input
                            class="form-control"
                            list="datalistOptions2"
                            value={description}
                            onChange={(e) => selectByProductDescription(e)}
                        />
                        <datalist id="datalistOptions2">
                            {renderedByProduct}
                        </datalist>
                    </div>

                    <div className="col-3 d-flex">
                        <input
                            class="form-control"
                            type="number"
                            value={amount}
                            onChange={(e) => changeTheAmountValue(e)}
                            style={{
                                borderColor: `${isClicked ? 'red' : ''}`
                            }}
                        />
                        <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                            deleteComponentClicked
                        }>
                            < BsFillXCircleFill />
                        </button>
                    </div>

                </div>
            </div>
        )}
        </>
    )

}

export default Item;