export const GET_TEAM_CERTIFICATE_LIST = 'GET_TEAM_CERTIFICATE_LIST';
export const ADD_ITEMS_CERTIFICATE_INTO_LIST = 'ADD_CERTIFICATE_ITEM_INTO_LIST';
export const ADD_SOFTWARE_INTO_LIST = 'ADD_SOFTWARE_INTO_LIST'
export const ADD_ALL_CERTIFICATIONS_FROM_REQUEST = 'ADD_ALL_CERTIFICATIONS_FROM_REQUEST'
export const ADD_STATUS_ADMIN = 'ADD_STATUS_ADMIN'
export const ADD_STATUS_CERTIFICATION = 'ADD_STATUS_CERTIFICATION'
export const ADD_COUNTER_LINE_CERTIFICATION_BY_ID = 'ADD_COUNTER_LINE_CERTIFICATION_BY_ID'
export const DELETE_COUNTER_IN_CERTIFICATE = 'DELETE_COUNTER_IN_CERTIFICATE'
export const REMOVE_ALL_CERTIFICATE_SELECTED = 'REMOVE_ALL_CERTIFICATE_SELECTED'
export const REMOVE_ALL_COUNTER_IN_CERTIFICATE_INITIAL = 'REMOVE_ALL_COUNTER_IN_CERTIFICATE_INITIAL'
export const REMOVE_ITEM_INTO_CERTIFICATE = 'REMOVE_ITEM_INTO_CERTIFICATE'