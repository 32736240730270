import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import {
  BsApp,
  BsArrowRight,
  BsCalendar2Date,
  BsCalendar3Event,
  BsCalendar4Event,
  BsCalendarMinus,
  BsCalendarRange,
  BsCursor,
  BsEnvelope,
  BsPerson,
} from "react-icons/bs";
import { Col, Row } from "reactstrap";

// this variable will be used to list all types contraction

const tipos_contratacion = [
  { id: 1, name: "SUBASTA INVERSA ELECTRÓNICA" },
  { id: 2, name: "LICITACIÓN" },
  { id: 2, name: "ÍNFIMA CUANTÍA" },
  { id: 3, name: "CATÁLOGO ELECTRÓNICO" },
];

// this function will be uses to show form about public sector
const PublicSector = ({ sector }) => {
  const [tipoContratacion, setTipoContratacion] = useState("");

  // to control the principal information
  const [entiedadContratante, setEntidadContratante] = useState("WAYNER MOYA");
  const [emailEncargado, setEmailEncargado] = useState(
    "WAYNERMOYA@HOTMAIL.COM"
  );
  const [objectoProceso, setObjectoProceso] = useState("");

  // to control of the code of the process
  const [codigoProceso, setCodigoProceso] = useState(sector.cod_proceso);

  // this variable will be used to control de date of the process
  const [fechaLimitePregunta, setFechaLimitePregunta] = useState(
    sector.fecha_lim_preguntas
  );
  const [fechaLimiteRespuesta, setFechaLimiteRespuesta] = useState(
    sector.fecha_lim_respuestas
  );
  const [fechaLimiteOferta, setFechaLimiteOferta] = useState(
    sector.fecha_lim_entrega_oferta
  );
  const [fechaLimiteCalificacion, setFechaLimiteCalificacion] = useState(
    sector.fecha_lim_califica
  );
  const [fechaInicioPuja, setFechaInicioPuja] = useState(sector.fecha_ini_puja);

  const [plazoEntrega, setPlazoEntrega] = useState(sector.plazo_entrega);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="form-label mb-3" style={{ fontWeight: "bold" }}>
          DATOS DEL PROCESO - SECTOR PUBLICO
        </h4>
      </div>
      <AvForm lassName="needs-validation">
        <Row style={{ marginTop: 20 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>ENTIDAD CONTRATANTE</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsPerson />
                </i>
                <AvField
                  disabled
                  value={entiedadContratante}
                  name="entidad_contratante"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  EMAIL ENCARGADO DEL PROCESO
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsEnvelope />
                </i>
                <AvField
                  disabled
                  value={emailEncargado}
                  name="email_encargado"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  style={{ width: "100%" }}
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>OBJETO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsApp />
                </i>
                <AvField
                  disabled
                  value={objectoProceso}
                  name="objecto_proceso"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>CODIGO DEL PROCESO</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCursor />
                </i>
                <AvField
                  disabled
                  value={codigoProceso}
                  name="codigo_proceso"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>TIPO DE CONTRATACION</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsArrowRight />
                </i>
                <select
                  disabled
                  className="form-control"
                  style={{ width: "100%" }}
                  value={tipoContratacion}
                  onChange={(e) => {
                    setTipoContratacion(e.target.value);
                  }}
                >
                  {Object.values(tipos_contratacion).map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE PREGUNTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <AvField
                  disabled
                  value={fechaLimitePregunta}
                  name="fecha_limite_preguntas"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA LÍMITE RESPUESTAS</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarRange />
                </i>
                <AvField
                  disabled
                  value={fechaLimiteRespuesta}
                  name="fecha_limite_respuesta"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE ENTREGA OFERTA
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar2Date />
                </i>
                <AvField
                  disabled
                  value={fechaLimiteOferta}
                  name="fecha_limite_entrega_oferta"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  FECHA LÍMITE CALIFICACIÓN
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendarMinus />
                </i>
                <AvField
                  disabled
                  value={fechaLimiteCalificacion}
                  name="fecha_limite_calificacion"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>

          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>FECHA INICIO PUJA</h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar3Event />
                </i>
                <AvField
                  disabled
                  value={fechaInicioPuja}
                  name="fecha_inicio_puja"
                  type="text"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl="6">
            <div>
              <div>
                <h6 style={{ fontWeight: "bold" }}>
                  PLAZO DE ENTREGA ( dias )
                </h6>
              </div>

              <div className="d-flex align-items-center">
                <i style={{ fontSize: 25, marginRight: 10 }}>
                  <BsCalendar4Event />
                </i>
                <AvField
                  disabled
                  value={plazoEntrega}
                  style={{ width: "100%" }}
                  name="plazo_entrega"
                  type="dias"
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                />
              </div>
            </div>
          </Col>
          <Col xl="6">
            <div></div>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};

export default PublicSector;
