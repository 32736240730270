import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

//Calendar 
import Calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer"

//ecommerce
import Ecommerce from "./e-commerce/reducer";

// DataJsonConsume 
import DataJsonConsume from "./data/reducer";

// ReportDetailsJsonConsume
import ReportDetailsJsonConsume from "./report_detail/reducer";

// Import General Report
import GeneralReportsJsonConsume from "./general_report/reducer";

// Import Certification 
import CertificationJsonConsume from "./certification/reducer";

// Import profile
import { profileReducer } from "./profile/reducer";

import { TeamCertifications } from "./team_certificate/reducer";

import { Supplies } from "./supplies/reducer";

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    Account,
    Login,
    Forget,
    Calendar,
    Ecommerce,
    chat,
    DataJsonConsume,
    ReportDetailsJsonConsume,
    CertificationJsonConsume,
    GeneralReportsJsonConsume,
    profileReducer,
    TeamCertifications,
    Supplies
});

export default rootReducer;