import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

import { Modal } from 'react-bootstrap';

import { saveAs } from 'file-saver'

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import {
    BsFillCursorFill,
    BsBarChartSteps,
    BsFillCalendarCheckFill,
    BsFillPersonCheckFill,
    BsReverseLayoutTextSidebarReverse,
    BsFillStopCircleFill,
    BsFillArrowDownCircleFill,
    BsFillPencilFill,
    BsFillVinylFill,
    BsFillPatchCheckFill,
    BsFillXCircleFill,
} from "react-icons/bs";

// import the special component 
import Specials from "./Specials";

import useHttp from "../../hook/use-http";

// import the style of the component
import './style.css'
import { toast } from "react-toastify";


// this variable will be used to control the type of the request
// this value will get in the param
let typeSolicitation = 'especiales'

/* This array represent different provider */
const providers = [
    { id: 1, name: "ELECTRONICA SIGLO XXI ELECTROSIGLO S.A." },
    { id: 2, name: "TECNOMEGA C.A." },
    { id: 3, name: "MEGAMICRO S.A." },
];

const SpecialCertificate = ({ match, history }) => {

    // this represent the provider
    const [proveedor, setProveedor] = useState('ELECTRONICA SIGLO XXI ELECTROSIGLO S.A.');

    // this variable will be used to control the type of the request
    // this function will be used to request the server to login the user
    const { loading, error, request: requestUrl } = useHttp();

    // this variable represent the date of the request
    const [dateRequest, setDateRequest] = useState('')

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)

    // this variable will be used to set the channel selected
    const [request, setRequest] = useState([])

    // this variable will be used to get information of the channel
    const profile = useSelector(state => state.profileReducer.profile)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /*********************************************************************** */
    /*********************************************************************** */
    /**  DECLARE THE VARIABLE USED TO CONTROL THE VIEW OF THE USER ***********/

    const [isAdmin, setIsAdmin] = useState(false)

    /*********************************************************************** */
    /*********************************************************************** */

    /*********************************************************************** */
    /*********************************************************************** */
    /**  DECLARE THE VARIABLE USED TO CREATE A SPECIAL REQUEST  **************/

    const [specificRequirement, setSpecificRequirement] = useState('')
    const [reasonRejection, setReasonRejection] = useState('')

    /*********************************************************************** */
    /*********************************************************************** */

    // this function will be used to get the specific requirement of the component special
    // and set the value of the specific requirement
    const onHandleEdithRequest = (e) => {
        setSpecificRequirement(e.target.value)
    }

    // this function will be used to process the request of getChannelProfile
    const getChannelProfileDataRequest = (e) => {

        if (!e.status) {
            // set the header of the request
            // add a extra field to the header
            setRequest(prov => {
                return {
                    ...prov,
                    razon_social: e.razonSocial,
                    ruc: e.ruc,
                    mayorista: e.mayorista
                }
            })
        }
    }

    // this function will be get the header of the channel 
    const getChannelProfile = () => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/channel/register`,
                method: 'GET',
                headers: myHeaders,
            }, getChannelProfileDataRequest)

        } catch (err) {
            console.log(err)
        }

    }

    // this function will be used to get the profile of the channel
    const getChannelProfileAdmin = (ruc) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/channel/list/ruc/` + ruc,
                method: 'GET',
                headers: myHeaders,
            }, getChannelProfileDataRequest)

        } catch (err) {
            console.log(err)
        }

    }
    // this function will be used to process the request of getDataOfCodeRequest
    const getDataOfCodeRequestDataResponse = (response) => {
        if (!response.status) {
            // set information of the request 
            setRequest(prov => {
                return {
                    ...prov,
                    estado: response.estado,
                    mayorista: response.mayorista,
                    RUC: response.ruc,
                    estado: response.estado,
                    requerimiento: response.requerimentoEspecifico,
                    motivoRechazo: response.motivoRechazo
                }
            })

            setProveedor(response.mayorista)

            let d = new Date(response.createdDate)
            let dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');

            setDateRequest(dformat)

            setSpecificRequirement(response.requerimentoEspecifico)

            // and call the function to get the profile of the channel admin 
            // to create the header of the component special
            getChannelProfileAdmin(response.ruc)
        }
    }

    // this function will be get the data of the code of the request
    const getDataOfCodeRequest = (code) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/solicitud/list/` + code,
                method: 'GET',
                headers: myHeaders,
            }, getDataOfCodeRequestDataResponse)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {

        const { code } = match.params
        console.log(match.params)
        if (code) {
            getDataOfCodeRequest(code);
        } /*else {

            // this variable will save the channel selected
            const resultSelected = dataJson[0]

            // set the channel selected
            setRequest(resultSelected)
        }
        */


        // here i need to calculate the time of the request
        let d = new Date
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        setDateRequest(dformat)

        const role = localStorage.getItem('role')

        if (role == 'ROLE_CHANNEL') {
            setIsAdmin(false)
        } else {
            setIsAdmin(true)
        }

    }, [])

    const sendRequestSpecialDataResponse = (response) => {
        if (response.status) {
            // show a error message here
            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            history.push('/request-sended')

        }
    }

    const sendRequestSpecial = () => {
        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/register/especial',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "ruc": profile.ruc,
                    "descripcion": profile.razonSocial,
                    "tipo": "ESPECIAL",
                    "mayorista": profile.mayorista,
                    "sector": "PRIVADO",
                    "requerimentoEspecifico": specificRequirement
                }
            }, sendRequestSpecialDataResponse)

        } catch (er) {
            console.log(er)
        }
    }

    const sendEdithRequestSupplyDataResponse = (response) => {
        if (response.status) {
            // show a error message here
            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            history.push('/request-sended')
        }
    }

    const edithRequestSpecial = () => {
        try {

            if (specificRequirement.length === 0) {
                toast('Debe ingresar un requerimiento especial', { type: 'error', autoClose: 10000 });
                return;
            }

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/edit/especial',
                method: 'PUT',
                headers: myHeaders,
                body: {
                    "codigo": code,
                    "ruc": profile.ruc,
                    "descripcion": profile.razonSocial,
                    "tipo": "ESPECIAL",
                    "mayorista": profile.mayorista,
                    "sector": "PRIVADO",
                    "requerimentoEspecifico": specificRequirement
                }
            }, sendEdithRequestSupplyDataResponse)

        } catch (er) {
            console.log(er)
        }
    }

    const approveRequestAdminFunctionDataResponse = (response) => {
        history.push('/request-approved')
    }

    const approveRequestAdminFunction = () => {
        try {

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/aprobar',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoSolicitud": code
                }
            }, approveRequestAdminFunctionDataResponse)

        } catch (er) {
            console.log(er)
        }

    }

    const denyRequestAdminFunctionDataResponse = () => {
        history.push('/request-rejected')
    }

    const denyRequestAdminFunction = () => {
        try {

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/rechazar',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoSolicitud": code,
                    "motivoRechazo": reasonRejection
                }
            }, denyRequestAdminFunctionDataResponse)

        } catch (er) {
            console.log(er)
        }
    }

    const handleRequestDownloadDataResponse = async (response) => {
        const result = await response.blob();
        const blob = new Blob([result], { type: 'application/pdf' })
        saveAs(blob, "certificados.pdf")
    }

    const handleRequestDownload = () => {

        const { code } = match.params

        alert(code)

        try {
            try {
                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                myHeaders.append("Accept", "application/pdf");
                myHeaders.append("responseType", "blob");

                requestUrl({
                    url: '/auth/certificado/download/' + code,
                    method: 'GET',
                    headers: myHeaders,
                }, handleRequestDownloadDataResponse)

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            {request && (

                <div className="page-content">
                    <Container fluid={true}>
                        <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>


                            <h1 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                SOLICITAR CERTIFICADO {typeSolicitation.toUpperCase()}
                            </h1>

                            {request.motivoRechazo && (
                                <div>
                                    <div class=" alert alert-danger" role="alert">
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>SOLICITUD RECHAZADO:</span>  {request.motivoRechazo}
                                    </div>
                                </div>
                            )}

                            <Col xl="12" style={{ width: '100%' }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>

                                        <AvForm className="needs-validation" >
                                            <Row className="d-flex justify-content-center ">
                                                <Col xl="3" className="d-flex flex-column justify-content-around" >
                                                    <div style={{ marginBottom: 10 }} >

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                CANAL
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCursorFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.razon_social ? request.razon_social : profile.razonSocial}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <Col xl="4" className="d-flex flex-column justify-content-end">
                                                        <div style={{ marginBottom: 10 }}>
                                                            <div>
                                                                <h6 style={{ fontWeight: 'bold' }}>
                                                                    RUC
                                                                </h6>
                                                            </div>

                                                            <div className="d-flex align-items-center">
                                                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                    <BsReverseLayoutTextSidebarReverse />
                                                                </i>
                                                                <h6 style={{ margin: 0 }}>
                                                                    {request.ruc ? request.ruc : profile.ruc}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Col>

                                                <Col xl="3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                TIPO DE CERTIFICADO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsBarChartSteps />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {typeSolicitation.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                    </div>


                                                    <div className="d-flex flex-column justify-content-center ">
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA SOLICITUD
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCalendarCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {dateRequest}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xl="3" style={{ display: 'flex', flexDirection: 'column' }} >

                                                    <div className="d-flex flex-column justify-content-center ">

                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                ESTADO
                                                            </h6>
                                                        </div>
                                                        {
                                                            request.estado === 'PENDIENTE' ?
                                                                (
                                                                    <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                        <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                            <BsFillStopCircleFill style={{ color: '#FFDB58', fontWeight: 'bold' }} />
                                                                        </i>
                                                                        <h6 style={{
                                                                            margin: 0, fontWeight: 'bold', color: '#FFDB58', //WebkitTextStroke: '.5px black'
                                                                        }}>
                                                                            PENDIENTE
                                                                        </h6>
                                                                    </div>
                                                                ) : request.estado === 'RECHAZADO' ?
                                                                    (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                        <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                            <BsFillXCircleFill style={{ color: 'red', fontWeight: 'bold' }} />
                                                                        </i>
                                                                        <h6 style={{
                                                                            margin: 0, fontWeight: 'bold', color: 'red', //WebkitTextStroke: '.5px black'
                                                                        }}>
                                                                            RECHAZADO
                                                                        </h6>
                                                                    </div>)
                                                                    : request.estado === 'APROBADO' ?
                                                                        (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                <BsFillPatchCheckFill style={{ color: 'green', fontWeight: 'bold' }} />
                                                                            </i>
                                                                            <h6 style={{
                                                                                margin: 0, fontWeight: 'bold', color: 'green', //WebkitTextStroke: '.5px black'
                                                                            }}>
                                                                                APROBADO
                                                                            </h6>
                                                                        </div>)
                                                                        : request.estado === 'REINGRESO' ?
                                                                            (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                    <BsFillVinylFill style={{ color: '#ff8000', fontWeight: 'bold' }} />
                                                                                </i>
                                                                                <h6 style={{
                                                                                    margin: 0, fontWeight: 'bold', color: '#ff8000', //WebkitTextStroke: '.5px black'
                                                                                }}>
                                                                                    REINGRESO
                                                                                </h6>
                                                                            </div>)
                                                                            : request.estado ?
                                                                                (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                                    <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                        <BsFillPencilFill style={{ color: '#FFDB58', fontWeight: 'bold' }} />
                                                                                    </i>
                                                                                    <h6 style={{
                                                                                        margin: 0, fontWeight: 'bold', color: '#FFDB58', //WebkitTextStroke: '.5px black'
                                                                                    }}>
                                                                                        EDITANDO
                                                                                    </h6>
                                                                                </div>) : ''

                                                        }
                                                    </div>
                                                </Col>
                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/*

                            < Specials
                                onHandleEdithRequestFatherComponent={onHandleEdithRequest}
                                requestSpecial={request.requerimiento ? request.requerimiento : null}
                            />
                            
                            */}

                            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                                <Card style={{ height: '100%' }}>
                                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>

                                        <div>
                                            <div className="d-flex justify-content-between mb-2" style={{ marginTop: 20 }}>
                                                <h4 className="form-label mb-3"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    SELECCIONE MAYORISTA
                                                </h4>
                                            </div>
                                            <Row>
                                                <Col md="6" sm="12">
                                                    <div className="mb-3 d-flex">
                                                        <Col md={12} style={{ width: '100%' }}>

                                                            {isAdmin ? (

                                                                <select className="form-control" value={proveedor} disabled
                                                                >
                                                                    {Object.values(providers).map(provider => (
                                                                        <option key={provider.id} value={provider.name}>{provider.name}</option>
                                                                    ))}

                                                                </select>

                                                            ) : (

                                                                <select className="form-control" value={proveedor}
                                                                    onChange={
                                                                        (e) => {
                                                                            setProveedor(e.target.value)
                                                                        }
                                                                    }
                                                                    disabled={
                                                                        request.estado !== 'RECHAZADO'}
                                                                >
                                                                    {Object.values(providers).map(provider => (
                                                                        <option key={provider.id} value={provider.name}>{provider.name}</option>
                                                                    ))}

                                                                </select>
                                                            )}

                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="d-flex justify-content-between mb-2" style={{ marginTop: 20 }}>
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                ESPECIFICAR EL REQUERIMIENTO
                                            </h4>

                                        </div>
                                        {isAdmin ? (

                                            <div id="list-supplies">
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"
                                                    value={specificRequirement}
                                                    onChange={(event) => setSpecificRequirement(event.target.value)}
                                                    disabled
                                                ></textarea>
                                            </div>
                                        ) : (
                                            <div id="list-supplies">
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"
                                                    value={specificRequirement}
                                                    onChange={(event) => setSpecificRequirement(event.target.value)}
                                                    disabled={
                                                        request.estado === 'RECHAZADO' || !request.estado ? false :
                                                            true
                                                    }
                                                ></textarea>
                                            </div>
                                        )}

                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                        {
                            isAdmin ? (
                                <>
                                    <div className="d-flex justify-content-end mt-3">
                                        <div className="mx-3">
                                            <button
                                                className="btn btn-danger-lexmark mx-2"
                                                type="button"
                                                onClick={handleShow}
                                            >RECHAZAR</button>
                                            <button
                                                className="btn btn-primary-lexmark"
                                                type="button"
                                                onClick={approveRequestAdminFunction}
                                            >APROBAR</button>
                                        </div>
                                    </div>
                                </>
                            ) : !isAdmin && request.estado === 'RECHAZADO' ? (
                                <div className="d-flex justify-content-end mt-3">
                                    <div className="mx-3">
                                        <button
                                            className="btn btn-primary-lexmark"
                                            type="button"
                                            onClick={edithRequestSpecial}
                                        >RENVIAR SOLICITUD</button>
                                    </div>
                                </div>
                            ) : !isAdmin && !request.estado ? (
                                <div className="d-flex justify-content-end mt-3">
                                    <div className="mx-3">
                                        <button
                                            className="btn btn-primary-lexmark"
                                            type="button"
                                            onClick={sendRequestSpecial}
                                        >SOLICITAR</button>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </Container >
                </div >
            )}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>RECHAZAR SOLICITUD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Por favor ingrese el motivo por la cual rechazará la solicitud.
                    </p>
                    <input
                        type="text"
                        class="form-control"
                        value={reasonRejection}
                        onChange={(e) => setReasonRejection(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>
                        CERRAR
                    </button>
                    <Button className="btn btn-danger" onClick={denyRequestAdminFunction}>RECHAZAR</Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment >
    );
}

export default SpecialCertificate;
