import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

/* This array represent different provider */
const providers = [
  { id: 1, name: "SIGLO 21" },
  { id: 2, name: "TECNOMEGA" },
  { id: 3, name: "MEGAMICRO" },
];
const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

/* This array represent the list of type of channel */

const typesChannel = [
  { id: 1, name: "TRADICIONAL" },
  { id: 2, name: "CONNECT" },
];

const typesOpportunity = [
  { id: 1, name: "SUMINISTROS" },
  { id: 2, name: "EQUIPOS" },
  { id: 3, name: "OUTSOURCING" },
];

/**
 * It returns a string with the current date and time in the format YYYY-MM-DDTHH:MM
 * @returns A string in the format of YYYY-MM-DDTHH:MM
 */
const getTodayDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return year + "-" + month + "-" + day + "T" + hour + ":" + minutes;
};

// create a function to return the current date in format unix timestamp
const getCurrentDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  let milliseconds =
    date.getMilliseconds() < 10
      ? "0" + date.getMilliseconds()
      : date.getMilliseconds();
  return year + month + day + hour + minutes + seconds + milliseconds;
};

const today = new Date().toISOString().split("T")[0];

const EditOpportunity = ({ history, match }) => {
  const [entities, setEntities] = useState([]);
  const [historicData, setHistoricData] = useState([]);

  const [mayorista, setMayorista] = useState("");
  const [fechaCierre, setFechaCierre] = useState(null);
  const [fileAddedTwo, setFileAddedTwo] = useState(null);
  const [fileAddedThree, setFileAddedThree] = useState(null);
  // this function will be process the result of the method getListEntity
  const compararPorEntidad = (a, b) => {
    // Utiliza toUpperCase() para hacer la comparación insensible a mayúsculas y minúsculas
    const entidadA = a.entidad.toUpperCase();
    const entidadB = b.entidad.toUpperCase();
  
    if (entidadA < entidadB) {
      return -1;
    }
    if (entidadA > entidadB) {
      return 1;
    }
    return 0; // Son iguales
  }
  const handleGetListEntityDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      products.sort(compararPorEntidad)
      setEntities([{ entidad: "" }, ...products]);
    }
  };

  // this function will be used to request the data from the server
  const getEntities = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/entity/active",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEntities();
    // const sortEntities = entities.sort((a, b) => {
    //     return a.entidad < b.entidad ? -1 : a.entidad > b.entidad ? 1 : 0;
    // })
    //setEntities([{ "entidad": "" }, ...sortEntities])
  }, []);

  const profile = useSelector((state) => state.profileReducer.profile);

  const [fileAdded, setFileAdded] = useState(null);

  const date = new Date();

  const expandRow = {
    renderer: (row) => (
      <>
        <Row style={{ width: "100%" }}>
          <Col xl="12">
            <div>
              <h6 style={{ fontWeight: "bold" }}>Entidad</h6>
              <p>
                {row?.entidad.length > 260
                  ? row?.entidad.slice(0, 260) + "..."
                  : row?.entidad}
              </p>
            </div>
          </Col>
          <Col xl="12">
            <h6 style={{ fontWeight: "bold" }}>Descripción</h6>
            <p>
              {row?.allDesctiption.length > 260
                ? row?.allDesctiption.slice(0, 260) + "..."
                : row.allDesctiption}
            </p>
          </Col>
        </Row>
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  let valueExtra = {
    hoverIdx: null,
  };
  const rowEvents = {
    onMouseEnter: (e, row, rowIndex) => {
      valueExtra = { hoverIdx: rowIndex };
    },
    onMouseLeave: () => {
      valueExtra = { hoverIdx: null };
    },
  };

  const rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    if (rowIndex % 2 === 0) {
      //style.backgroundColor = 'transparent';
    } else {
      //style.backgroundColor = 'rgba(54, 163, 173, .10)';
    }
    //style.color = '#505D69'
    //style.borderTop = 'none';

    return style;
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: historicData.length, // replace later with size(customers),
    custom: true,
  };

  /* The above code is declaring the state variables for the form. */
  const [codeOpportunity, setCodeOpportunity] = useState(
    "OP_" + getCurrentDate()
  );
  const [typeOpportunity, setTypeOpportunity] = useState("SUMINISTROS");
  const [descriptionOpportunity, setDescriptionOpportunity] = useState("");
  const [nameChannel, setNameChannel] = useState(
    profile.razonSocial ? profile.razonSocial : ""
  );
  const [dateSetOpportunity, setDateSetOpportunity] = useState(getTodayDate());
  const [dateModifyOpportunity, setDateModifyOpportunity] = useState(
    getTodayDate()
  );
  const [nameEntity, setNameEntity] = useState();
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactLandLine, setContactLandLine] = useState("");
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [opportunity, setOpportunity] = useState("");

  const [sellerName, setSellerName] = useState(null);
  const [sellerRole, setSellerRole] = useState(null);

  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  const [presupuestoImpresion, setPresupuestoImpresion] = useState(0);
  const [presupuestoTotal, setPresupuestoTotal] = useState(0);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRedirectToListWhenTheChannelIsCreated = () => {
    history.push(`/channel-list`);
  };

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/

  // this will save the code of the opportunity
  const [codeOpportunityEdit, setCodeOpportunityEdit] = useState(null);

  // this represent the data company
  const [razonSocial, setRazonSocial] = useState("");
  const [ruc, setRuc] = useState("");
  const [direccion, setDireccion] = useState("");

  // this represent the main contact
  const [nombreContacto, setNombreContacto] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [cargo, setCargo] = useState("");

  // this represent the legal represent
  const [nombreRepresentante, setNombreRepresentante] = useState("");
  const [celularRepresentante, setCelularRepresentante] =
    useState("0987766556");
  const [emailRepresentante, setEmailRepresentante] = useState(
    "waynermoya@hotmail.com"
  );

  // this represetnt the second contact
  const [nombreContacto2, setNombreContacto2] = useState("");
  const [celular2, setCelular2] = useState("");
  const [email2, setEmail2] = useState("");

  // this represent the type of channel
  const [tipoCanal, setTipoCanal] = useState("TRADICIONAL");

  // this represent the documents
  const [acuerdoComercialDocumento, setAcuerdoComercialDocumento] =
    useState(null);
  const [acuerdoComercialBase64, setAcuerdoComercialBase64] = useState("");
  const [rucDocumento, setRucDocumento] = useState("");
  const [rucBase64, setRucBase64] = useState("");

  // this represent the provider
  const [proveedor, setProveedor] = useState("SIGLO 21");

  // this represent the observaciones
  const [observaciones, setObservaciones] = useState("");

  /* Create a function to validate the number phone  */
  const validatePhone = (phone) => {
    const regex = /^[0-9]{0}$/;
    return regex.test(phone);
  };

  const columns = [
    // {
    //     dataField: 'checkbox',
    //     text: `${''}`,
    //     sort: true,
    //     sortCaret: (order, column) => {
    //         if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
    //         else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
    //         else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
    //         return null;
    //     }

    // },
    {
      dataField: "codigo",
      text: "CODIGO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "accion",
      text: "ACCION",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "responsable",
      text: "RESPONSABLE",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "mensaje",
      text: "MENSAJE",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "fecha",
      text: "FECHA",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
  ];

  const handleCreateChannelDataResponse = (response) => {
    if (response.status) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      if (fileAdded || fileAddedTwo || fileAddedThree) {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        let formData = new FormData();
        if (fileAdded) {
          formData.append("file", fileAdded, fileAdded.name);
        }
        if (fileAddedTwo) {
          formData.append("file", fileAddedTwo, fileAddedTwo.name);
        }
        if (fileAddedThree) {
          formData.append("file", fileAddedThree, fileAddedThree.name);
        }

        request(
          {
            url: `/auth/oportunidad/file/${opportunity.codigo}`,
            method: "POST",
            headers: myHeaders,
            body: formData,
          },
          () => history.push(`/opportunity-created`)
        );
      } else {
        history.push(`/opportunity-edited`);
      }
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // if (
      //   !fileAdded &&
      //   (!opportunity.pathDeclaracion &&
      //   !opportunity.pathDeclaracion[0])
      // ) {
      //   setMessageStatusIsError(true);
      //   setMessageRequest("Debe adjuntar al menos 1 archivo");
      //   return;
      // }
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/edit`,
          method: "PUT",
          headers: myHeaders,
          body: {
            codigo: codeOpportunityEdit,
            descripcion: descriptionOpportunity,
            tipo: typeOpportunity,
            canal: {
              ruc: profile?.ruc,
              razonSocial: profile?.razonSocial ? profile?.razonSocial : "",
            },
            informacionEntidad: {
              entidad: nameEntity,
              nombreContacto: contactName,
              email: contactEmail,
              telefono: contactPhone,
              telefonoFijo: contactLandLine,
            },
            fechaCierre: `${fechaCierre}T00:00:00Z`,
            mayorista,
            vendedor: {
              nombre: sellerName,
              cargo: sellerRole,
            },
            presupuestoImpresion: parseFloat(
              presupuestoImpresion
                ? ("" + presupuestoImpresion).replace(".", "")
                : 0
            ),
            presupuestoTotalEstimadoEntidad: parseFloat(
              estimatedBudget ? ("" + estimatedBudget).replace(".", "") : 0
            ),
          },
        },
        handleCreateChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  // this function will be process the result of the method getListChannel
  const getOpportunityByCodeDataResponse = (response) => {
    if (response?.status === 401) {
      // show a error message here

      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      setCodeOpportunityEdit(response?.codigo);
      setTypeOpportunity(response?.tipo);
      setNameEntity(response?.informacionEntidad?.entidad);
      setContactName(response?.informacionEntidad?.nombreContacto);
      setContactPhone(response?.informacionEntidad?.telefono);
      setContactEmail(response?.informacionEntidad?.email);
      setDescriptionOpportunity(response?.descripcion);
      setEstimatedBudget(response?.presupuestoTotalEstimadoEntidad);
      setSellerName(response?.vendedor.nombre);
      setSellerRole(response?.vendedor.cargo);
      setPresupuestoImpresion(response?.presupuestoImpresion);
      setContactLandLine(response?.informacionEntidad?.telefonoFijo);
      if (response.fechaCierre) {
        const fechaDate = new Date(response.fechaCierre);
        setFechaCierre(fechaDate.toISOString().substring(0, 10));
      }
      setMayorista(response?.mayorista);
      setOpportunity(response);
    }
  };

  // this function will be used to request the data from the server
  const getOpportunityByCode = () => {
    const { id } = match.params;

    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/list/codigo/${id}`,
          method: "GET",
          headers: myHeaders,
        },
        getOpportunityByCodeDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };
  const compararFechas = (a, b) => {
    const fechaA = new Date(a.fecha);
    const fechaB = new Date(b.fecha);
    return fechaB - fechaA;
  };
  const handleGetHistoricData = (data) => {
    if (data?.status === 401) {
      // show a error message here

      if (data.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(data.message, { type: "error", autoClose: 10000 });
    } else {
      const arrHistoric = data.content.map((item) => {
        return {
          codigo: item.codigo,
          fecha: formatDateTime(item.createdDate),
          accion: item.accion,
          mensaje: item.mensaje,
          estado: item.estado,
          responsable: item.createdBy,
        };
      });
      arrHistoric.sort(compararFechas);
      setHistoricData(arrHistoric);
    }
  };

  const getHistoricData = async () => {
    const { id } = match.params;

    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/historico/list/codigo/${id}?size=100`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetHistoricData
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOpportunityByCode();
    getHistoricData();
  }, []);

  const handleDeleteFile = async (path) => {
    const { id } = match.params;

    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      let data = {
        codigo: id,
        path,
      };
      request(
        {
          url: `/auth/oportunidad/file`,
          method: "DELETE",
          headers: myHeaders,
          body: data,
        },
        getOpportunityByCode
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title mb-3">REGISTAR OPORTUNIDAD</h4>
                    <Row>
                      {/**
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom01">Codigo Oportunidad</Label>

                                                    <Input
                                                        name="codigo_oportunidad"
                                                        id="codigo_oportunidad"
                                                        type="text"
                                                        value={codeOpportunity}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                             */}
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom02"
                          >
                            Tipo Oportunidad
                          </Label>

                          <select
                            className="form-control"
                            value={typeOpportunity}
                            onChange={(e) => {
                              setTypeOpportunity(e.target.value);
                            }}
                          >
                            {Object.values(typesOpportunity).map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom04"
                          >
                            Canal
                          </Label>
                          <AvField
                            name="canal"
                            type="text"
                            errorMessage="Ingrese el nombre del canal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={profile?.razonSocial}
                            disabled
                          />
                        </div>
                      </Col>
                      {/** 
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom04">Fecha Declaración Oportunidad</Label>
                                                    
                                                    <Input
                                                        name="fecha_declaracion_oportunidad"
                                                        id="fecha_declaracion_oportunidad"
                                                        type="datetime-local"
                                                        value={dateSetOpportunity + ':00'}
                                                        defaultValue={dateSetOpportunity + ':00'}
                                                        min={dateSetOpportunity + ':00'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                               
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom04">Ultima Fecha De Modificación</Label>
                                                    <Input
                                                        name="fecha_ultima_modificacion"
                                                        id="fecha_ultima_modificacion"
                                                        type="datetime-local"
                                                        value={dateModifyOpportunity + ':00'}
                                                        defaultValue={dateModifyOpportunity + ':00'}
                                                        min={dateModifyOpportunity + ':00'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                             */}
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom05"
                          >
                            Entidad
                          </Label>
                          {/* 
                                                    <AvField
                                                        name="entidad"
                                                        type="text"
                                                        errorMessage="Ingrese la entidad"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom05"
                                                        value={nameEntity}
                                                        onChange={(e) => setNameEntity(e.target.value)}
                                                    />
                                                    */}
                          <select
                            className="form-control"
                            value={nameEntity}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setNameEntity(e.target.value);
                            }}
                          >
                            {Object.values(entities).map((item, index) => (
                              <option
                                key={index}
                                value={item.entidad?.toUpperCase()}
                              >
                                {item.entidad?.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom06"
                          >
                            Nombre de Contacto Entidad
                          </Label>
                          <AvField
                            name="nombreContacto"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom06"
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom07"
                          >
                            Email de Contacto Entidad
                          </Label>
                          <AvField
                            name="email"
                            type="email"
                            errorMessage="Ingrese el email del contacto"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom07"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Cargo del contacto de la entidad
                          </Label>
                          <AvField
                            name="roleSeller"
                            type="text"
                            errorMessage="Ingrese el cargo del contacto de la entidad"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom21"
                            value={sellerRole}
                            onChange={(e) => setSellerRole(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom08"
                          >
                            Teléfono de Contacto Entidad
                          </Label>
                          <AvField
                            name="telefono"
                            type="number"
                            errorMessage="Ingrese el telefono del contacto"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "El teléfono de contacto debe tener 10 dígitos",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "El teléfono de contacto debe tener 10 dígitos",
                              },
                            }}
                            id="validationCustom08"
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom99"
                          >
                            Teléfono Fijo Entidad
                          </Label>
                          <AvField
                            name="telefono2"
                            type="number"
                            errorMessage="Ingrese el teléfono fijo"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 9,
                                errorMessage:
                                  "El teléfono fijo de contacto debe tener 9 dígitos incluyendo el prefijo de ciudad",
                              },
                              maxLength: {
                                value: 9,
                                errorMessage:
                                  "El teléfono fijo de contacto debe tener 9 dígitos incluyendo el prefijo de ciudad",
                              },
                            }}
                            id="validationCustom99"
                            value={contactLandLine}
                            onChange={(e) => setContactLandLine(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom09"
                          >
                            Presupuesto Total Estimado Entidad
                          </Label>
                          <AvField
                            name="presupuesto_estimado"
                            type="number"
                            errorMessage="Ingrese el presupuesto estimado"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9.]+$",
                                errorMessage:
                                  "Por favor solo ingresar valores numericos",
                              },
                            }}
                            id="validationCustom09"
                            value={estimatedBudget}
                            onChange={(e) => {
                              const allString = e.target.value;
                              const element =
                                e.target.value.length === 1
                                  ? e.target.value
                                  : e.target.value.slice(-1);

                              if (
                                !isNaN(parseFloat(element)) &&
                                isFinite(element)
                              ) {
                                let actualEstimatedBudget =
                                  allString.replaceAll(".", "");
                                let result = "";
                                let counter = 0;
                                for (
                                  let i = actualEstimatedBudget.length - 1;
                                  i > -1;
                                  i--
                                ) {
                                  if (counter === 3) {
                                    result = "." + result;
                                    counter = 0;
                                  }

                                  result = actualEstimatedBudget[i] + result;

                                  counter++;
                                }
                                setEstimatedBudget(result);
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom09"
                          >
                            Presupuesto Proyecto Impresion
                          </Label>
                          <AvField
                            name="presupuestoImpresion"
                            type="text"
                            errorMessage="Ingrese el presupuesto proyecto impresion"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9.]+$",
                                errorMessage:
                                  "Por favor solo ingresar valores numericos",
                              },
                            }}
                            id="validationCustom10"
                            value={presupuestoImpresion}
                            onChange={(e) => {
                              const allString = e.target.value;
                              const element =
                                e.target.value.length === 1
                                  ? e.target.value
                                  : e.target.value.slice(-1);

                              if (
                                !isNaN(parseFloat(element)) &&
                                isFinite(element)
                              ) {
                                let actualEstimatedBudget =
                                  allString.replaceAll(".", "");
                                console.log(actualEstimatedBudget);
                                let result = "";
                                let counter = 0;
                                for (
                                  let i = actualEstimatedBudget.length - 1;
                                  i > -1;
                                  i--
                                ) {
                                  if (counter === 3) {
                                    result = "." + result;
                                    counter = 0;
                                  }

                                  result = actualEstimatedBudget[i] + result;

                                  counter++;
                                }
                                console.log(result);

                                setPresupuestoImpresion(result);
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom20"
                          >
                            Nombre del Vendedor
                          </Label>
                          <AvField
                            name="nombreVendedor"
                            type="text"
                            errorMessage="Ingrese el nombre del vendedor"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom20"
                            value={sellerName}
                            onChange={(e) => setSellerName(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Seleccione Mayorista
                          </Label>
                          <select
                            className="form-control "
                            value={mayorista}
                            onChange={(e) => {
                              setMayorista(e.target.value);
                            }}
                          >
                            {Object.values(providers).map((provider) => (
                              <option key={provider.id} value={provider.name}>
                                {provider.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom23"
                          >
                            Fecha de cierre
                          </Label>
                          <AvField
                            name="dateEnd"
                            type="date"
                            errorMessage="Ingrese una fecha valida"
                            className="form-control"
                            min={today}
                            validate={{ required: { value: true } }}
                            id="validationCustom23"
                            value={fechaCierre}
                            onChange={(e) => setFechaCierre(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="opportunity-create-file"
                          >
                            Adjuntar archivo #1
                          </Label>
                          <br />
                          <AvField
                            disabled={
                              opportunity.pathDeclaracion &&
                              opportunity.pathDeclaracion[0]
                            }
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                            name="fileAdded"
                            type="file"
                            errorMessage="Ingrese el documento del acuerdo comercial"
                            className="form-control"
                            id="opportunity-create-file"
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];
                              const maxFileSize = 5000000; // 5MB in bytes

                              if (f && f.size > maxFileSize) {
                                // Limpiar el campo de archivo seleccionado
                                setMessageStatusIsError(true);
                                setMessageRequest("El archivo pesa mas de 5MB");
                                setFileAdded(null);

                                return;
                              }
                              setMessageStatusIsError(null);
                              setMessageRequest(null);
                              setFileAdded(f);
                              /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>
                      <Col xl="6">
                        {opportunity.pathDeclaracion &&
                          opportunity.pathDeclaracion[0] && (
                            <div>
                              <Label
                                className="form-label"
                                htmlFor="opportunity-preview-file"
                              >
                                Archivo de declaración #1
                              </Label>
                              <br />
                              <div className="d-flex align-center">
                                <a
                                  href={`${process.env.REACT_APP_S3_URL}${opportunity.pathDeclaracion[0]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {process.env.REACT_APP_S3_URL}
                                  {opportunity.pathDeclaracion[0]}
                                </a>
                                <Button
                                  onClick={() =>
                                    handleDeleteFile(
                                      opportunity.pathDeclaracion[0]
                                    )
                                  }
                                  className="ml-2"
                                  type="button"
                                  color="danger"
                                  size="sm"
                                >
                                  Eliminar
                                </Button>
                              </div>
                            </div>
                          )}
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="opportunity-create-file"
                          >
                            Adjuntar archivo #2
                          </Label>
                          <br />
                          <AvField
                            disabled={
                              (opportunity.pathDeclaracion &&
                                opportunity.pathDeclaracion[1]) ||
                              !(
                                (opportunity.pathDeclaracion &&
                                  opportunity.pathDeclaracion[0]) ||
                                fileAdded
                              )
                            }
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                            name="fileAddedTwo"
                            type="file"
                            errorMessage="Ingrese el documento del acuerdo comercial"
                            className="form-control"
                            id="opportunity-create-file"
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];

                              const maxFileSize = 5000000; // 5MB in bytes

                              if (f && f.size > maxFileSize) {
                                // Limpiar el campo de archivo seleccionado
                                setMessageStatusIsError(true);
                                setMessageRequest("El archivo pesa mas de 5MB");
                                setFileAddedTwo(null);

                                return;
                              }
                              setMessageStatusIsError(null);
                              setMessageRequest(null);
                              setFileAddedTwo(f);
                              /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>

                      <Col xl="6">
                        {opportunity.pathDeclaracion &&
                          opportunity.pathDeclaracion[1] && (
                            <div>
                              <Label
                                className="form-label"
                                htmlFor="opportunity-preview-file"
                              >
                                Archivo de declaración #2
                              </Label>
                              <div className="d-flex align-center">
                                <a
                                  href={`${process.env.REACT_APP_S3_URL}${opportunity.pathDeclaracion[1]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {process.env.REACT_APP_S3_URL}
                                  {opportunity.pathDeclaracion[1]}
                                </a>
                                <Button
                                  onClick={() =>
                                    handleDeleteFile(
                                      opportunity.pathDeclaracion[1]
                                    )
                                  }
                                  className="ml-2"
                                  type="button"
                                  color="danger"
                                  size="sm"
                                >
                                  Eliminar
                                </Button>
                              </div>
                            </div>
                          )}
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="opportunity-create-file"
                          >
                            Adjuntar archivo #3
                          </Label>
                          <br />
                          <AvField
                            disabled={
                              (opportunity.pathDeclaracion &&
                                opportunity.pathDeclaracion[2]) ||
                              !(
                                (opportunity.pathDeclaracion &&
                                  opportunity.pathDeclaracion[1]) ||
                                fileAddedTwo
                              )
                            }
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                            name="fileAddedThree"
                            type="file"
                            errorMessage="Ingrese el documento del acuerdo comercial"
                            className="form-control"
                            id="opportunity-create-file"
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];

                              const maxFileSize = 5000000; // 5MB in bytes

                              if (f && f.size > maxFileSize) {
                                // Limpiar el campo de archivo seleccionado
                                setMessageStatusIsError(true);
                                setMessageRequest("El archivo pesa mas de 5MB");
                                setFileAddedThree(null);

                                return;
                              }
                              setMessageStatusIsError(null);
                              setMessageRequest(null);
                              setFileAddedThree(f);
                              /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>

                      <Col xl="6">
                        {opportunity.pathDeclaracion &&
                          opportunity.pathDeclaracion[2] && (
                            <div>
                              <Label
                                className="form-label"
                                htmlFor="opportunity-preview-file"
                              >
                                Archivo de declaración #3
                              </Label>
                              <br />
                              <div className="d-flex align-center">
                                <a
                                  href={`${process.env.REACT_APP_S3_URL}${opportunity.pathDeclaracion[2]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {process.env.REACT_APP_S3_URL}
                                  {opportunity.pathDeclaracion[2]}
                                </a>
                                <Button
                                  onClick={() =>
                                    handleDeleteFile(
                                      opportunity.pathDeclaracion[2]
                                    )
                                  }
                                  className="ml-2"
                                  type="button"
                                  color="danger"
                                  size="sm"
                                >
                                  Eliminar
                                </Button>
                              </div>
                            </div>
                          )}
                      </Col>
                      <Col md="12">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom03"
                          >
                            Descripción del trabajo realizado en la cuenta (
                            Detalle toda la gestión, evite el rechazo de su
                            oportunidad )
                          </Label>
                          <AvField
                            name="description"
                            type="textarea"
                            errorMessage="Ingrese una descripción"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={descriptionOpportunity}
                            onChange={(e) =>
                              setDescriptionOpportunity(e.target.value)
                            }
                            rows="5"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={loading}
                    >
                      Actualizar Oportunidad
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={historicData}
                      // selectRow={selectRow}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={historicData}
                          // selectRow={{ mode: 'checkbox', clickToSelect: true }}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      id="table-responsive"
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      rowStyle={rowStyle}
                                      rowEvents={rowEvents}
                                      expandRow={expandRow}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente el canal{" "}
                <span style={{ fontWeight: "bold" }}> {razonSocial} </span> con
                el contacto{" "}
                <span style={{ fontWeight: "bold" }}> {nombreContacto} </span>,
                se ha enviado un correo al email de contacto con la información
                de inicio de sesión.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheChannelIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditOpportunity;
