import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
  { id: 1, name: "SIGLO 21" },
  { id: 2, name: "TECNOMEGA" },
  { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesBanner = [
  { id: 1, name: "TRADICIONAL" },
  { id: 2, name: "CONNECT" },
];

const BannerRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRedirectToListWhenTheBannerIsCreated = () => {
    history.push(`/banner-list`);
  };

  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    title: null,
    description: null,
    type: "Principal",
    preview: null,
    file: null,
  });

  /* Create a function to validate each input is not empty */
  const validateInput = (input) => {
    return input !== "";
  };

  /* Create a function to validate each input the form */
  const validateForm = () => {
    return (
      validateInput(form.title) &&
      validateInput(form.description) &&
      validateInput(form.type)
    );
  };

  const convertToBase64 = (file, f) => {
    try {
      console.log("file", file);
      //console.log('f', f);

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      console.log(base64);
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          console.log("ddddddd");
          //console.timeLog(reader)
          base64 = reader?.result;
          console.log(base64);
          setForm({ ...form, preview: base64, file: file[0] });
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleCreateBannerDataResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      history.push(`/banner-created`);
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();

      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      let formData = new FormData();
      for (var key in form) {
        if (form.hasOwnProperty(key)) {
          // Si es un objeto de archivo, lo agregamos como un archivo al FormData
          if (key === "file" && form[key] instanceof File) {
            formData.append(key, form[key], form[key].name);
          } else if(key !== 'preview') {
            // Agregar otras propiedades normales al FormData
            formData.append(key, form[key]);
          }
        }
      }

      for (const [key, value] of formData.entries()) {
        if (value instanceof File) {
            console.log(`${key}: ${value.name}, ${value.type}, ${value.size} bytes`);
        } else {
            console.log(`${key}: ${value}`);
        }
    }
      request(
        {
          url: `/auth/banner`,
          method: "POST",
          headers: myHeaders,
          body: formData,
        },
        handleCreateBannerDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title">DATOS BANNER</h4>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-title"
                          >
                            Titulo
                          </Label>
                          <br />
                          <AvField
                            name="title"
                            type="text"
                            errorMessage="Ingrese el titulo"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-title"
                            value={form.title}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-description"
                          >
                            Descripción
                          </Label>
                          <br />

                          <AvField
                            name="description"
                            type="text"
                            errorMessage="Ingrese la descripción"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-description"
                            value={form.description}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <Label
                          className="form-label"
                          htmlFor="banner-create-type"
                        >
                          Tipo
                        </Label>
                        <div className="mb-3 d-flex">
                          <Col md={12} style={{ width: "100%" }}>
                            <select
                              className="form-control"
                              name="type"
                              id="banner-create-type"
                              value={form.type}
                              onChange={handleInputChange}
                            >
                              <option value="Principal">Principal</option>
                              <option value="Login">Login</option>
                            </select>
                          </Col>
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="banner-create-image"
                          >
                            Subir imagen
                          </Label>
                          <br />
                          <AvField
                            accept="image/*"
                            name="file-banner"
                            type="file"
                            errorMessage="Ingrese la imagen"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="banner-create-image"
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];
                              convertToBase64(e.target.files, f);

                              //setAcuerdoComercialDocumento(e.target)
                              /*
                                                            setAcuerdoComercialDocumento(
                                                                convertToBase64(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>
                      {form.preview && (
                        <Col md="6">
                          <div className="mb-3 w-100">
                            <Label
                              className="form-label"
                              htmlFor="banner-create-preview"
                            >
                              Previsualización
                            </Label>
                            <br />
                            <div className="w-100 text-center">
                              <img
                                src={form.preview}
                                className="img-fluid"
                                alt="banner-preview"
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>

                    <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={loading}
                    >
                      Crear
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente el banner{" "}
                <span style={{ fontWeight: "bold" }}> {form.title} </span>.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheBannerIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BannerRegister;
