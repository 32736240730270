import React, { useEffect, useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs';
import { Card, CardBody, Col } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { addItemIntoCertificateList, deleteCounterLineInCertificateById } from '../../store/actions';

import Item from './Item';

const CertificadoASP = ({ title, id, code }) => {

    let dispatch = useDispatch();


    // this variable will be control the amount of component supplies list
    const [listsComponentItem, setListsComponentItem] = useState([]);

    // this variable will be control the id of the component supplies list
    const [amountListItemId, setAmountListItemId] = useState(0)

    // this variable represent the array of the supplies list part number
    const [listCertification, setListCertification] = useState([]);

    const certificationSelectedWithInputs = useSelector(state => state.TeamCertifications.certificationResponse)
    const [isAdmin, setIsAdmin] = useState(false)
    const statusCertification = useSelector(state => state.TeamCertifications.statusCertification)

    const [actionType, setActionType] = useState('ADD')

    useEffect(() => {
        const role = localStorage.getItem('role')
        if (role === 'ROLE_ADMIN') {
            setIsAdmin(true)
        }
    })

    useEffect(() => {

        let result = Object.values(certificationSelectedWithInputs).find(item => {
            return item.codigo === code
        })

        if (result) {

            let counter = -1
            // we need to set all items to save after in a list
            let setItems = Object.values(result.info_cert).map(item => {

                const i = {
                    ciudad: item.ciudad,
                    direccion: item.direccion,
                }

                counter++
                return { ...i, id: counter }
            })

            counter++;
            setAmountListItemId(counter);

            // one the items is setting we need to add into the list
            setListCertification(setItems);
            setListsComponentItem(setItems);
        }

    }, [certificationSelectedWithInputs])

    useEffect(() => {
        // In this dispatch, i send the id of the certificate and the list of the part number
        dispatch(addItemIntoCertificateList(code, listCertification, [], title, actionType));
    }, [listCertification])

    const addNewComponentItem = () => {

        const id = amountListItemId + "_item_id_" + title;

        setListsComponentItem((provItem) => {
            return [...provItem, { id }]
        })

        setAmountListItemId(prov => {
            return prov + 1;
        })

    }

    // this function will be used to add a new item into list of the certification 
    const addItemIntoListCertification = (element) => {

        const newItem = listCertification.find(item => {
            return item.id === element.id
        })

        setActionType('ADD')

        if (!newItem) {

            setListCertification((prov) => {
                return [
                    ...prov,
                    element
                ]
            })

        } else {

            setListCertification((prov) => {
                return prov.map(item => {
                    if (item.id === element.id) {
                        return element
                    } else {
                        return item
                    }
                })
            })
        }
    }

    // this function will be used to remove a item into list of the certification
    const removeItemIntoListCertification = (element) => {

        setActionType('REMOVE')

        const deleteItem = listCertification.find(item => {
            return item.id === element
        })

        if (deleteItem) {
            setListCertification((prov) => {
                return prov.filter(item => {
                    return item.id !== element
                })
            })
        } else {
            dispatch(deleteCounterLineInCertificateById(code))
        }
    }

    // rendered the list of the item list part number
    const renderedItemList = Object.values(listsComponentItem).map((item, index) => {
        return (
            <div key={index}>
                < Item
                    addItemIntoListCertification={addItemIntoListCertification}
                    removeItemIntoListCertification={removeItemIntoListCertification}
                    id={index}
                    ciudadInput={item.ciudad ? item.ciudad : ''}
                    direccionInput={item.direccion ? item.direccion : ''}
                    code={code}
                />
            </div>
        )
    })

    return (
        <>
            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                <Card style={{ height: '100%' }}>
                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="d-flex justify-content-between" style={{ marginTop: 20 }}>
                            <h4 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                {title}
                            </h4>
                            {statusCertification === '' ? (
                                <div>
                                    <button
                                        className="btn btn-primary-lexmark"
                                        onClick={addNewComponentItem}
                                    >
                                        <BsPlusCircleFill style={{ fontSize: 20 }} />
                                    </button>
                                </div>
                            ) : statusCertification === 'RECHAZADO' && !isAdmin ? (
                                <div>
                                    <button
                                        className="btn btn-primary-lexmark"
                                        onClick={addNewComponentItem}
                                    >
                                        <BsPlusCircleFill style={{ fontSize: 20 }} />
                                    </button>
                                </div>
                            ) : ''}
                        </div>
                        <div id="list-supplies">
                            {amountListItemId === 0 ?
                                (
                                    <h6 style={{
                                        textAlign: 'center'

                                    }}>INGRESAR LISTA DE {title} </h6>
                                ) :
                                renderedItemList}
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </>
    )
}

export default CertificadoASP;