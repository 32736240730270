import React, { useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { BsBarChartSteps, BsCalendarEvent, BsCursor, BsDashSquare, BsFillCursorFill, BsNewspaper, BsPatchCheck, BsPerson, BsPersonCircle } from 'react-icons/bs';
import { Col, Row } from 'reactstrap';


const PrivateSectorAdmin = ({ handleChangeDataProcess, onHandleDataProcess, dataProcess }) => {

    // this variable will be used to create a data of process
    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [dirijido, setDirijido] = useState('');
    const [cargo, setCargo] = useState('');

    useEffect(() => {

        try {
            setNombreEmpresa(dataProcess.nombreEmpresa)
            setDirijido(dataProcess.dirigido)
            setCargo(dataProcess.cargo)

        } catch (error) {
            console.log(error)
        }

    }, [])

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    DATOS DEL PROCESO - SECTOR PRIVADO
                </h4>
            </div>
            <AvForm className="needs-validation" onValidSubmit={onHandleDataProcess} >
                <Row style={{ marginTop: 20 }}>
                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    NOMBRE EMPRESA
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsPerson />
                                </i>
                                <AvField
                                    name="nombre_empresa"
                                    type="text"
                                    errorMessage="Ingrese el nombre de la empresa"
                                    value={nombreEmpresa}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                    disabled
                                />
                            </div>
                        </div>

                    </Col>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    DIRIGIDA A
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsCursor />
                                </i>
                                <AvField
                                    name="dirigido"
                                    type="text"
                                    errorMessage="Ingrese el nombre del destinatario"
                                    value={dirijido}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                    disabled
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    CARGO
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsDashSquare />
                                </i>
                                <AvField
                                    name="cargo"
                                    type="text"
                                    errorMessage="Ingrese el cargo"
                                    value={cargo}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                    disabled
                                />
                            </div>
                        </div>

                    </Col>

                </Row>

            </AvForm>

        </>
    )
}

export default PrivateSectorAdmin;