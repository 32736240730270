import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import {
    BsFillCursorFill,
    BsBarChartSteps,
    BsPlusCircleFill,
    BsFillCalendarCheckFill,
    BsFillPersonCheckFill,
    BsFillRecord2Fill,
    BsReverseLayoutTextSidebarReverse,
    BsFillStopCircleFill,
} from "react-icons/bs";
import { changeStatus, updateCertificationDataJson, updateDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from 'react-bootstrap';

// import the component public sector
import PublicSector from "./PublicSector";

// import the component private sector
import PrivateSector from "./PrivateSector";

// import the supply component
import Supply from "./Supply";

// import the supply part component
import ListSupplies from './ListSupplies';

// import the select sector component 
import SelectSector from "./SelectSector";

// import the style of the component
import './style.css'

/* This array represent different provider */
const providers = [
    { id: 1, name: "SIGLO 21" },
    { id: 2, name: "TECNOMEGA" },
    { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */
const typesChannel = [
    { id: 1, name: "TRADICIONAL" },
    { id: 2, name: "CONNECT" },
]


// this variable will be used to control the type of the request
// this value will get in the param
let typeSolicitation = ''

const DetailsRequestCertification = ({ match, history }) => {

    // this variable will be control the amount of component supplies list
    const [listsComponent, setListsComponent] = useState();
    // this variable will be control the id of the component supplies list
    const [amountListSuppliesId, setAmountListSuppliesId] = useState(0)

    // this variable will be used to control which view private or public
    const [isPublic, setIsPublic] = useState(true)
    const handleChangeView = (e) => setIsPublic(e)

    // this variable represent the date of the request
    const [dateRequest, setDateRequest] = useState('')

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)

    // this variable will be used to set the channel selected
    const [request, setRequest] = useState(null)

    // this variable will control the if the admin can change the channel or no
    const [disabled, setDisabled] = useState(true)

    // this will be used to control de component modal  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /***************************************************************/
    /***************************************************************/
    /**  declare state variables to approve or reject a request  ***/
    /***************************************************************/
    /***************************************************************/

    // this variable represent the comment if the request is reject
    const [observaciones, setObservaciones] = useState('')

    // this variable represent the status of the request
    const [statusRequest, setStatusRequest] = useState(null)

    // this variable represent the array of the supplies list part number
    const [partListSupplies, setPartListSupplies] = useState([]);

    useEffect(() => {
        // this variable will be used to get the id of the channel
        const { id = 0, type_solicitation } = match.params;

        typeSolicitation = type_solicitation

        console.log(id)

        // this variable will save the channel selected
        const resultSelected = dataJson[id]

        // set the channel selected
        setRequest(resultSelected)

        // here i need to calculate the time of the request
        let d = new Date
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        setDateRequest(dformat)

    }, [])

    // this function will be used when the request is reject
    const onHandleRejectOrApproveRequest = (status) => {

        const { id = 0 } = match.params;
        const observation = observaciones

        dispatch(updateCertificationDataJson(
            id,
            status,
            observation
        ), {
            type: 'UPDATE_DATA_JSON'
        })

        // close the modal
        handleClose()

        // redirect to list requests
        history.push('/approval-request-list')
    }

    // this function will be used to add a new part supply into list supplies
    const addSupplyIntoListSupplies = (supply) => {
        setPartListSupplies((prov) => {
            return [
                ...prov,
                supply
            ]
        })
    }


    const addNewComponentSupply = () => {
        const id = amountListSuppliesId + "_supply_id";
        setListsComponent((prov) => (
            <div id={id}>
                < ListSupplies
                    addSupplyIntoListSupplies={addSupplyIntoListSupplies}
                    id={id}
                />
                {prov}
            </div>
        ));
        setAmountListSuppliesId(prov => {
            return prov + 1;
        })
    }

    let renderedListSupplies;

    if (request) {
        const s = request.suministros;
        console.log(s)
        
        renderedListSupplies = Object.values(s).map((value, index) => {
            return (
                < ListSupplies
                    addSupplyIntoListSupplies={addSupplyIntoListSupplies}
                    id={index}
                    nps={value.partno}
                    models={value.model}
                    descriptions={value.description}
                    amounts={value.cant}
                />
            )
        })
    }



    return (
        <React.Fragment>
            {request && (

                <div className="page-content">
                    <Container fluid={true}>

                        <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>

                            <h1 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                SOLICITUD CERTIFICADO {typeSolicitation.toUpperCase()} - {request.cod_solicictud}
                            </h1>

                            <Col xl="12" style={{ width: '100%' }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>

                                        <AvForm className="needs-validation" >
                                            <Row style={{ marginTop: 20 }} className="d-flex justify-content-around">

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div >

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                CANAL
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCursorFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.razon_social}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                MAYORISTA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillPersonCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.mayorista}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                TIPO DE CERTIFICADO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsBarChartSteps />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {typeSolicitation.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: 20 }} className="d-flex justify-content-around">

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                RUC
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsReverseLayoutTextSidebarReverse />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.RUC}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA SOLICITUD
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCalendarCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {dateRequest}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                ESTADO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillStopCircleFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.estado_solicitud}
                                                            </h6>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl="12" style={{ width: '100%', marginTop: 20 }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>
                                        <AvForm className="needs-validation" >
                                            <Row >
                                                <Col xl="12" >
                                                    <SelectSector handleChangeView={handleChangeView} />
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Row style={{ paddingRight: 0 }}>

                                {/* SHOW THE PUBLIC SESCTOR */}
                                <Col xl="12" style={{ width: '70%', marginTop: 20 }} id="certificados" >
                                    <Card style={{ height: '100%' }}>
                                        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                            {isPublic ? (
                                                < PublicSector sector={request} />

                                            ) : (
                                                <PrivateSector />
                                            )}

                                        </CardBody>
                                    </Card>
                                </Col>

                                {/* DATOS DEL PROCESO - SECTOR PUBLICO O PRIVADO */}
                                <Col xl="12" style={{ width: '30%', marginTop: 20, padding: 0 }} id="datos_proceso" >
                                    <Card style={{ height: '100%' }}>
                                        <CardBody style={{ height: '100%' }}>
                                            < Supply />
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>

                            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                                <Card style={{ height: '100%' }}>
                                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="d-flex justify-content-between" style={{ marginTop: 20 }}>
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                LISTA DE SUMINISTROS
                                            </h4>

                                        </div>
                                        <div id="list-supplies">
                                            {amountListSuppliesId === 0 &&
                                                (
                                                    renderedListSupplies
                                                )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="mx-3">
                                <button
                                    className="btn btn-primary-lexmark"
                                    type="button"
                                    onClick={() => onHandleRejectOrApproveRequest('APROBADO')}
                                >APROBAR</button>
                            </div>
                            <div>
                                <button className="btn btn-danger-lexmark" type="button"
                                    onClick={() => handleShow()}
                                >RECHAZAR</button>
                            </div>
                        </div>
                        <div>
                            <M show={show} onHide={handleClose}>
                                <M.Header closeButton>
                                    <M.Title>RECHAZO CANAL</M.Title>
                                </M.Header>
                                <M.Body>
                                    INGRESAR LA RAZÓN DEL RECHAZO
                                    <div className="mt-3">
                                        <textarea className="form-control"
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            placeholder="Deja un comentario aquí" id="floatingTextarea">
                                        </textarea>
                                    </div>
                                </M.Body>
                                <M.Footer>
                                    < button className="btn btn-primary-lexmark" onClick={() => onHandleRejectOrApproveRequest('RECHAZADO')}>
                                        RECHAZAR
                                    </button>
                                </M.Footer>
                            </M>
                        </div>

                    </Container>
                </div>
            )}
        </React.Fragment >
    );

}

export default DetailsRequestCertification;
