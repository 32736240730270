import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Channel List 
import ChannelList from "../pages/ChannelList/index";

// Channel Details
import ChannelDetails from "../pages/ChannelDetails/index";

// Channel Register
import ChannelRegister from "../pages/ChannelRegister/index";

// Approval Request List
import ApprovalRequestList from "../pages/ApprovalRequests/index";

// Approval Request Details
import ApprovalRequestsDetails from "../pages/ApprovalRequestsDetails/index";

// General Reports
import GeneralReports from "../pages/GeneralReport/index";

// Detail Reports
import DetailReports from "../pages/DetailReport/index";

// RenewalOfTradeAgreements
import RenewalOfTradeAgreements from "../pages/RenewalOfTradeAgreements/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

// this route is using for the channel 
import ConsultRequestsChannel from "../pages/ConsultRequestsChannel/index";

import SupplyCertificate from '../pages/SupplyCertificate/index'

// DetailsRequestCertification
import DetailsRequestCertification from "../pages/DetailsRequestCertification/index";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

// import the ResetPassword component
import ResetPassword from "../pages/Authentication/ResetPassword";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";
import ChangePasswordToAvailable from "../pages/Authentication/ChangePasswordToAvailable";
import ChangePassword from "../pages/Authentication/ChangePassword";
import ChannelCreated from "../pages/ChannelCreated";
import SpecialCertificate from "../pages/SpecialCertificate";
import RequestCreatedSuccessfully from "../pages/RequestCreatedSuccessfully";
import RequestApprove from "../pages/RequestApprove";
import TeamCertificate from "../pages/TeamCertificate";
import DetailsSpecialCertificate from "../pages/DetailsSpecialCertificate";
import RequestReject from "../pages/RequestReject";
import ChannelEdited from "../pages/ChannelEdited";
import CreateSupplyCertificate from "../pages/CreateSupplyCertificate";
import CreateSpecialCertificate from "../pages/CreateSpecialCertificate";
import CreateTeamCertificate from "../pages/CreateTeamCertificate";
import ValidateRequest from "../pages/ValidateRequest";
import DetailReportTeams from "../pages/DetailReportTeams";
import OpportunityList from "../pages/OportunidadLista/index.";
import CreateOpportunity from "../pages/Oportunidad/index.";
import OpportunityListAdmin from "../pages/OportunidadListaAdmin/index.";
import OpportunityCreated from "../pages/OpportunityCreated";
import OpportunityGroup from "../pages/OpportunityGroup/index.";
import OpportunityGroupDetails from "../pages/OpportunityGroupDetails/index.";
import OpportunityDetails from "../pages/OpportunityDetails/index.";
import GroupCreated from "../pages/GroupCreated";
import OpportunityApproved from "../pages/OpportunityApprove";
import OpportunitiesWon from "../pages/OportunidadesGanadas/index.";
import OpportunitiesApproved from "../pages/OportunidadesAprobadas/index.";
import OpportunitiesReport from "../pages/OpportunitiesReport";
import GroupUpdated from "../pages/GroupUpdated";
import OpportunitiesExpired from "../pages/OportunidadesCaducada/index.";
import EditOpportunity from "../pages/OpportunidadEditar/index.";
import OpportunityEdited from "../pages/OpportunityEdited";
import EntityList from "../pages/EntityList";
import EntityRegister from "../pages/EntityRegister";
import EntityCreated from "../pages/EntityCreated";
import EntityDetails from "../pages/EntityDetails";
import EntityEdited from "../pages/EntityEdited";
import BannerList from "../pages/BannerList";
import BannerRegister from "../pages/BannerRegister";
import BannerDetails from "../pages/BannerDetails";
import BannerCreated from "../pages/BannerCreated";
import BannerEdited from "../pages/BannerEdited";
import OpportunitiesRejected from "../pages/OportunidadesRejected/index.";

// we need to validate the role of the user , because we need to add the route necessary.
const authProtectedRoutes = [

	//Kanban Board
	{ path: "/kanban-board", component: KanbanBoard },

	// Tables
	{ path: "/basic-tables", component: BasicTables },
	{ path: "/datatable-table", component: DatatableTables },
	{ path: "/responsive-table", component: ResponsiveTables },
	{ path: "/editable-table", component: EditableTables },

	// Ui
	{ path: "/ui-alerts", component: UiAlert },
	{ path: "/ui-buttons", component: UiButtons },
	{ path: "/ui-cards", component: UiCards },
	{ path: "/ui-carousel", component: UiCarousel },
	{ path: "/ui-dropdowns", component: UiDropdown },
	{ path: "/ui-general", component: UiGeneral },
	{ path: "/ui-grid", component: UiGrid },
	{ path: "/ui-images", component: UiImages },
	{ path: "/ui-lightbox", component: UiLightbox },
	{ path: "/ui-modals", component: UiModal },
	{ path: "/ui-progressbars", component: UiProgressbar },
	{ path: "/ui-sweet-alert", component: UiSweetAlert },
	{ path: "/ui-tabs-accordions", component: UiTabsAccordions },
	{ path: "/ui-typography", component: UiTypography },
	{ path: "/ui-video", component: UiVideo },
	{ path: "/ui-session-timeout", component: UiSessionTimeout },
	{ path: "/ui-rating", component: UiRating },
	{ path: "/ui-rangeslider", component: UiRangeSlider },
	{ path: "/ui-notifications", component: UiNotifications },
	{ path: "/ui-roundslider", component: UIRoundSlider },

	// Forms
	{ path: "/form-elements", component: FormElements },
	{ path: "/form-advanced", component: FormAdvanced },
	{ path: "/form-editors", component: FormEditors },
	{ path: "/form-mask", component: FormMask },
	{ path: "/form-file-upload", component: FormUpload },
	{ path: "/form-wizard", component: FormWizard },
	{ path: "/form-validation", component: FormValidations },
	{ path: "/form-xeditable", component: FormXeditable },

	//Utility
	{ path: "/starter", component: StarterPage },
	{ path: "/timeline", component: Timeline },
	{ path: "/faqs", component: FAQs },
	{ path: "/pricing", component: Pricing },

	//Icons
	{ path: "/icons-remix", component: RemixIcons },
	{ path: "/material-design", component: MaterialDesign },
	{ path: "/dripicons", component: DripiIcons },
	{ path: "/font-awesome-5", component: FontAwesome },

	// Maps
	{ path: "/google-maps", component: MapsGoogle },
	{ path: "/vector-maps", component: MapsVector },

	//Charts
	{ path: "/apex-charts", component: ChartApex },
	{ path: "/chartjs", component: ChartjsChart },
	{ path: "/charts-sparkline", component: SparklineChart },
	{ path: "/charts-knob", component: ChartsKnob },

	//Email
	{ path: "/email-inbox", component: EmailInbox },
	{ path: "/email-read", component: EmailRead },

	//Ecommerce

	{ path: "/ecommerce-products", component: EcommerceProducts },
	{ path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
	{ path: "/ecommerce-orders", component: EcommerceOrders },
	{ path: "/ecommerce-customers", component: EcommerceCustomers },
	{ path: "/ecommerce-cart", component: EcommerceCart },
	{ path: "/ecommerce-checkout", component: EcommerceCheckout },
	{ path: "/ecommerce-shops", component: EcommerceShops },
	{ path: "/ecommerce-add-product", component: EcommerceAddProduct },

	//chat
	{ path: "/chat", component: Chat },

	//calendar
	{ path: "/calendar", component: Calendar },

	{ path: "/dashboard", component: Dashboard },

	{ path: "/channel-list", component: ChannelList },

	{ path: "/channel-detail/:id", component: ChannelDetails },

	{ path: "/create-channel", component: ChannelRegister },

	{ path: "/approval-request-list", component: ApprovalRequestList },

	{ path: "/approval-request-list-details/:id", component: ApprovalRequestsDetails },

	{ path: "/general-reports-list", component: GeneralReports },

	{ path: "/detail-report-list-supplies", component: DetailReports },

	{ path: "/detail-report-list-teams", component: DetailReportTeams },

	{ path: "/validate-request", component: ValidateRequest },

	{ path: "/renewal-of-trade-agreements", component: RenewalOfTradeAgreements },

	// THESE ROUTES IS USING FOR CHANNEL
	{ path: "/consult-request-channel", component: ConsultRequestsChannel },


	// these routes is using for reports
	{ path: "/supply-certificate/:code?", component: SupplyCertificate },
	{ path: "/special-certificate/:code?", component: SpecialCertificate },
	{ path: "/team-certification/:code?", component: TeamCertificate },

	// these routes is using for channel to create a request
	{ path: "/create-supply-request", component: CreateSupplyCertificate },
	{ path: "/create-special-request", component: CreateSpecialCertificate },
	{ path: "/create-team-request", component: CreateTeamCertificate },

	// these routes is using for view status of the request
	{ path: "/details-special-certification/:code?", component: DetailsSpecialCertificate },

	// finished these routes is using for reports

	{ path: "/detail-certificate/:type_solicitation", component: DetailsRequestCertification },

	{ path: "/change-password", component: ChangePassword },

	{ path: "/channel-created", component: ChannelCreated },

	{ path: "/opportunity-created", component: OpportunityCreated },

	{ path: "/opportunity-edited", component: OpportunityEdited },

	{ path: "/opportunity-edit/:id", component: EditOpportunity },

	{ path: '/channel-edited', component: ChannelEdited },

	{ path: "/request-sended", component: RequestCreatedSuccessfully },

	{ path: "/request-approved", component: RequestApprove },

	{ path: "/request-rejected", component: RequestReject },

	// control the route for opportunity

	{ path: "/opportunity-list", component: OpportunityList },
	{ path: "/opportunity-list-admin", component: OpportunityListAdmin },
	{ path: "/opportunity-groups", component: OpportunityGroup },
	{ path: "/group-created", component: GroupCreated },

	{ path: "/group-updated", component: GroupUpdated },
	{ path: "/opportunity-group-approve", component: OpportunityApproved },
	{ path: "/opportunities-report", component: OpportunitiesReport },

	{ path: "/opportunities-won", component: OpportunitiesWon },
	{ path: "/opportunities-approved", component: OpportunitiesApproved },
	{ path: "/opportunities-rejected", component: OpportunitiesRejected },
	{ path: "/opportunities-expired", component: OpportunitiesExpired },
	{ path: "/opportunity-groups-details/:id", component: OpportunityGroupDetails },
	{ path: "/opportunity-details/:id", component: OpportunityDetails },

	{ path: "/create-opportunity", component: CreateOpportunity },


	// Control entities routes
	{ path: "/entity-list", component: EntityList },

	{ path: "/entity-detail/:id", component: EntityDetails },

	{ path: "/create-entity", component: EntityRegister },

	{ path: "/entity-created", component: EntityCreated },

	{ path: '/entity-edited', component: EntityEdited },
	// control the route for banners
	{ path: "/banner-list", component: BannerList },
	{ path: "/create-banner", component: BannerRegister },
	{ path: "/banner-created", component: BannerCreated },
	{ path: "/banner-edited", component: BannerEdited },
	{ path: "/banner-detail/:id", component: BannerDetails },


	// this route should be at the end of all other routes
	//{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
	{ path: "/", component: Dashboard }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/reset-password/:email", component: ResetPassword },
	{ path: "/change-password-to-available/:email", component: ChangePasswordToAvailable },
	{ path: "/register", component: Register },
	{ path: "/lock-screen", component: AuthLockScreen },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	{ path: "/maintenance", component: Maintenance },
	{ path: "/comingsoon", component: CommingSoon },
	{ path: "/404", component: Error404 },
	{ path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
