import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <nav class="legal-menu" style={{ padding: 0 }}>
                <div class="container">
                    <ul class="legal-menu-brand">
                        <li class="legal-menu-brand-logo"><img
                            src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-symbol-2x.svg"
                            alt="Símbolo de Lexmark" /></li>
                        <li>
                            <ul class="legal-menu-brand-copyright">
                                <li>Lexmark International, Inc.</li>
                                <li>&copy;2022 All rights reserved.</li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="legal-menu-notice">
                        <li><a href="/es_xl/privacy-policy.html">Política de privacidad</a></li>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default Footer;
