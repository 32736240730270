import { AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { BsArrowCounterclockwise, BsBookmarkCheck, BsBookmarks, BsBookmarkStar, BsBoxSeam, BsCardChecklist, BsCaretUp, BsCursor, BsFillArrowDownCircleFill, BsMinecartLoaded, BsPencilSquare, BsPhone, BsRecord, BsRecycle, BsReverseLayoutTextSidebarReverse, BsReverseLayoutTextWindowReverse } from 'react-icons/bs';
import { Row } from 'reactstrap';

import { saveAs } from 'file-saver'
import useHttp from "../../hook/use-http";

// import all certification teams 
import DistribuidorAutorizadoDeEquipos from './DistribuidorAutorizadoDeEquipos';

// import the component DistribuidorAutorizado
//import DistribuidorAutorizadoDeSoftware from '../../components/DistribuidorAutorizadoDeSoftware/index';
import DistribuidorAutorizadoDeSoftware from '../../components/DistribuidorAutorizadoDeSoftware/index';

// import the component CertificadoTecnico
import CertificadoTecnico from '../../components/CertificadoTecnico/index';

// import the component CertificacionASP
import CertificadoASP from '../../components/CertificadoASP/index';

// import the component GarantiaTecnicaDeEquiposNuevos
import GarantiaTecnicaDeEquiposNuevos from '../../components/GarantiaTecnicaDeEquiposNuevos/index';

// import the component CertificadoTecnicoDeSoftware
import CertificadoTecnicoDeSoftware from '../../components/CertificadoTecnicoDeSoftware/index';

// import the component CertificadoEquipoOptimasCondiciones
import CertificadoEquipoOptimasCondiciones from '../../components/CertificadoEquipoOptimasCondiciones/index';

import { useSelector } from 'react-redux';

const Teams = ({ addToListTeams, removeToListTeams, certificationSelected, estado, match, codeRequest }) => {

    const [supplies, setSupplies] = useState([
        {   // this certificate not provide extra information
            id: 1,
            name: 'DISTRIBUIDOR AUTORIZADO DE EQUIPOS',
            title: 'DISTRIBUIDOR_AUTORIZADO_DE_EQUIPOS',
            icon: <BsMinecartLoaded code="CER_EQU_001" />,
            name_component: '',
            code: "CER_EQU_001"
        },
        {   // valid if the certification dont need a information
            id: 2,
            name: 'DISTRIBUIDOR AUTORIZADO DE SOFTWARE',
            title: 'DISTRBUIDOR_AUTORIZADO_DE_SOFTWARE',
            icon: <BsPhone />,
            name_component:
                <DistribuidorAutorizadoDeSoftware
                    title='DISTRIBUIDOR AUTORIZADO DE SOFTWARE'
                    id="002"
                    code="CER_EQU_002"
                />,
            code: "CER_EQU_002"
        },
        {   // valid if the certification dont need a information
            id: 99,
            name: 'CERTIFICADO COMPATIBILIDAD DE SOFTWARE',
            title: 'CERTIFICADO COMPATIBILIDAD DE SOFTWARE',
            icon: <BsPhone />,
            name_component:
                <DistribuidorAutorizadoDeSoftware
                    title='CERTIFICADO COMPATIBILIDAD DE SOFTWARE'
                    id="099"
                    code="CER_EQU_013"
                />,
            code: "CER_EQU_013"
        },
        {   // valid if the certification dont need a information
            id: 3,
            name: 'GARANTÍA TÉCNICA',
            title: 'GARANTÍA_TÉCNICA_EQUIPOS_NUEVOS',
            icon: <BsPencilSquare />,
            name_component:
                <GarantiaTecnicaDeEquiposNuevos
                    title='GARANTÍA TÉCNICA'
                    id="003"
                    code="CER_EQU_003"
                />,
            code: "CER_EQU_003"
        },
        {   // valid if the certification dont need a information
            id: 8,
            name: 'STOCK SUMINISTROS',
            title: 'STOCK_SUMINISTROS',
            icon: <BsCursor />,
            name_component: '',
            code: "CER_EQU_007"
        },
        {   // ready to use
            id: 9,
            name: 'CERTIFICADO TÉCNICOS EQUIPOS',
            title: 'CERTIFICADO_TÉCNICOS_EQUIPOS',
            icon: <BsReverseLayoutTextSidebarReverse />,
            name_component:
                <CertificadoTecnico
                    title='CERTIFICADO TÉCNICOS EQUIPOS'
                    id="005"
                    code="CER_EQU_008"
                />,
            code: "CER_EQU_008"
        },
        {   // ready to use
            id: 10,
            name: 'CERTIFICADO TÉCNICOS SOFTWARE',
            title: 'CERTIFICADO_TÉCNICOS_SOFTWARE',
            icon: <BsCursor />,
            name_component:
                <CertificadoTecnicoDeSoftware
                    title='CERTIFICADO TÉCNICOS SOFTWARE'
                    id="006"
                    code="CER_EQU_009"
                />,
            code: "CER_EQU_009"
        },
        {   // validate where i can find information about this certification
            id: 11,
            name: 'CERTIFICADO AMBIENTAL',
            title: 'CERTIFICADO_AMBIENTAL',
            icon: <BsArrowCounterclockwise />,
            name_component: '',
            code: "CER_EQU_010"
        },
        { // ready to use
            id: 13,
            name: 'COMUNICADO DE ORIGINALIDAD',
            title: 'COMUNICADO_DE_ORIGINALIDAD',
            icon: <BsBookmarks />,
            name_component: '',
            code: "CER_EQU_011"
        },
        {
            id: 15,
            name: 'CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES',
            title: 'CERTIFICADO_EQUIPOS_EN_OPTIMAS_CONDICIONES',
            icon: <BsCardChecklist />,
            name_component:
                <CertificadoEquipoOptimasCondiciones
                    title='CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES'
                    id="010"
                    code="CER_EQU_004"
                />,
            code: "CER_EQU_004",
        },
        {
            id: 16,
            name: 'CERTIFICADO DE EQUIPOS NUEVOS',
            title: 'CERTIFICADO_DE_EQUIPOS_NUEVOS',
            icon: <BsBoxSeam />,
            name_component:
                <CertificadoEquipoOptimasCondiciones
                    title='CERTIFICADO DE EQUIPOS NUEVOS'
                    id="011"
                    code="CER_EQU_005"
                />,
            code: "CER_EQU_005"
        },
        {
            id: 17,
            name: 'CERTIFICADO CAS',
            title: 'CERTIFICADO_CAS',
            icon: <BsCaretUp />,
            name_component: '',
            code: "CER_EQU_006"
        }
    ])

    const typeChannelStorage = useSelector(state => state.profileReducer.profile?.tipo);

    const [typeChannel, setTypeChannel] = useState(typeChannelStorage);
    const [suppliesSelected, setSuppliesSelected] = useState([]);

    const [isParams, setIsParams] = useState(false);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    const [statusRequest, setStatusRequest] = useState(estado);

    const addIntoListTeams = (type) => {
        if (type === 'CONNECT') {
            setSupplies(prov => {
                return [...prov,
                {
                    id: 14,
                    name: 'CERTIFICADO DE ASP',
                    title: 'CERTIFICADO_DE_ASP',
                    icon: <BsBookmarkStar />,
                    name_component:
                        <CertificadoASP
                            title='CERTIFICADO DE ASP'
                            id="009"
                            code="CER_EQU_012"
                        />,
                    code: "CER_EQU_012"
                }]
            })
        }
    }

    useEffect(() => {
        addIntoListTeams(typeChannel);
    }, [typeChannel])

    useEffect(() => {
        try {

            if (Object.values(certificationSelected).length > 0 && !isParams) {

                Object.values(certificationSelected).map(certification => {

                    const supply = supplies.find(supply => supply.code === certification);

                    setSuppliesSelected(prevState => [...prevState, supply]);

                    let container = document.getElementById(supply.name);
                    container.style.backgroundColor = '#008945'
                    container.style.color = 'white'

                    let title = document.getElementById(supply.title)
                    title.style.color = 'white'

                    addToListTeams(supply);

                })
                setIsParams(true);
            }

        } catch (error) {
            console.log(error);
        }
    })

    // this function will be used to add or not supplies into list
    const addSuppliesToList = (selected) => {

        const supply = suppliesSelected.find(item => {
            return item.name === selected.name
        })

        // add to supply into list
        if (!supply) {
            // add supply into list
            setSuppliesSelected((prov) => {
                return [
                    ...prov,
                    selected
                ]
            })
            // now we need to change the color of the div component
            let container = document.getElementById(selected.name);
            container.style.backgroundColor = '#008945'
            container.style.color = 'white'

            let title = document.getElementById(selected.title)
            title.style.color = 'white'

            addToListTeams(selected)


        } else { // delete supply into list

            setSuppliesSelected((prov) => {
                const filter = prov.filter(item => {
                    return item.name !== supply.name
                })
                return filter
            })

            let container = document.getElementById(selected.name);
            container.style.backgroundColor = 'white'
            container.style.color = '#433A40'

            let title = document.getElementById(selected.title)
            title.style.color = '#433A40'

            removeToListTeams(selected)
        }

    }

    const renderedSupplies = Object.values(supplies).map(item => {
        return (
            <div
                key={item.id}
                id={item.name}
                className="form-check" style={{
                    border: '1px solid #CDCDCD',
                    marginTop: 20
                }}
                onClick={() => addSuppliesToList(item)}
            >
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                        <i style={{ fontSize: 25, marginRight: 10 }}>
                            {item.icon}
                        </i>
                        <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                            {item.name}
                        </h6>
                    </div>
                </div>
            </div>
        )
    })

    const handleRequestDownloadDataResponse = async (response) => {
        const result = await response.blob();
        const blob = new Blob([result], { type: 'application/pdf' })
        saveAs(blob, "certificados.pdf")
    }

    const handleRequestDownload = () => {

        try {
            try {
                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                myHeaders.append("Accept", "application/pdf");
                myHeaders.append("responseType", "blob");

                request({
                    url: '/auth/certificado/download/' + codeRequest,
                    method: 'GET',
                    headers: myHeaders,
                }, handleRequestDownloadDataResponse)

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    CERTIFICADO
                </h4>
                {
                    estado === 'APROBADO' && (
                        <button
                            className="btn btn-info"
                            onClick={handleRequestDownload}
                            style={{ backgroundColor: '#3af23a', color: 'white' }}
                        >
                            <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillArrowDownCircleFill />
                            </div>
                        </button>
                    )
                }
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </>
    )

}

export default Teams;