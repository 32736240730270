import React, { Component, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logoutUser } from '../../store/actions';

// import the use-http
import useHttp from '../../hook/use-http';

const Logout = ({ history, logoutUser }) => {

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    /*
    constructor(props) {
        super(props);
        this.state = {  };
    }

    componentDidMount() {
     
        // Fire Action for Remove all Item from localstorage and redirect to login page
        this.props.logoutUser(this.props.history);
    }
    */

    const handleLogoutDataResponse = (response) => {

        if (response.status === 401) {
            // console.log(response.data);
            //window.location.href = '/login';
            logoutUser(history)
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            logoutUser(history)
            // redirect to login page

        }
    }

    const handleLogout = () => {
        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/user/logout`,
                method: 'POST',
                headers: myHeaders,
            }, handleLogoutDataResponse)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleLogout();
    }, [])

    return (
        <React.Fragment>
            <h1>&nbsp;</h1>
        </React.Fragment>
    );

}

export default withRouter(connect(null, { logoutUser })(Logout));

