import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';

import useHttp from '../../hook/use-http';

const ChangePassword = ({ history }) => {

    // this variable will be used to control the result of the form
    const [messageRequest, setMessageRequest] = useState('');
    // this variable will control the status of the message response
    const [messageStatusIsError, setMessageStatusIsError] = useState(null);

    // this variable are updated when the user change the password
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // this function will be used to process the data response from server
    const handleChangePasswordDataResponse = (response) => {
        // if the response is ok show the message to the user
        if (response === true) {

            setMessageRequest("Contraseña cambiada con éxito");
            setMessageStatusIsError(false);

            // reset the form
            //setConfirmPassword('');
            //setNewPassword('');
            //setOldPassword('');

        } else {

            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });

            setMessageRequest("Error al cambiar la contraseña");
            setMessageStatusIsError(true);
        }
    }

    // this function will be used to handle the submit of the form
    const handleSubmit = () => {
        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            const email = localStorage.getItem('email');

            request({
                url: `/auth/user/changePassword`,
                method: 'POST',
                headers: myHeaders,
                body: {
                    "email": email,
                    "previousPassword": oldPassword,
                    "newPassword": newPassword,
                }
            }, handleChangePasswordDataResponse)

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row className='d-flex justify-content-center'>
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">CAMBIAR CONTRASEÑA</h4>
                                    <div>
                                        {messageStatusIsError === true && < Alert color="danger">{messageRequest}</Alert>}
                                        {messageStatusIsError === false && < Alert color="success">{messageRequest}</Alert>}

                                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}  >
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="validationCustom01">CONTRASEÑA ANTERIOR</Label>
                                                        <AvField
                                                            name="oldPassword"
                                                            placeholder="Contraseña anterior"
                                                            type="password"
                                                            errorMessage="Ingrese su contraseña anterior"
                                                            className="form-control"
                                                            validate={{
                                                                required: true,
                                                                minLength: { value: 8, errorMessage: 'La contraseña debe tener al menos 8 caracteres' },
                                                                maxLength: { value: 20, errorMessage: 'La contraseña no debe tener mas de 20 caracteres' },
                                                                pattern: { value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{8,}).*$', errorMessage: 'La contraseña debe tener al menos una mayuscula, una minuscula, un numero y un caracter especial' }
                                                            }}
                                                            id="validationCustom01"
                                                            value={oldPassword}
                                                            onChange={(e) => setOldPassword(e.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="userpassword">NUEVA CONTRASEÑA</Label>
                                                        <AvField
                                                            name="password"
                                                            value={newPassword}
                                                            type="password"
                                                            errorMessage="Proporcione una contraseña valida."
                                                            validate={{
                                                                required: true,
                                                                minLength: { value: 8, errorMessage: 'La contraseña debe tener al menos 8 caracteres' },
                                                                maxLength: { value: 20, errorMessage: 'La contraseña no debe tener mas de 20 caracteres' },
                                                                pattern: { value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{8,}).*$', errorMessage: 'La contraseña debe tener al menos una mayuscula, una minuscula, un numero y un caracter especial' }
                                                            }}
                                                            className="form-control"
                                                            id="userpassword"
                                                            placeholder="Nueva contraseña"
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="useragainpass">CONFIRMAR CONTRASEÑA</Label>
                                                        <AvField
                                                            name="useragainpass"
                                                            value={confirmPassword}
                                                            errorMessage="La contraseña no coincide."
                                                            type="password"
                                                            validate={{
                                                                required: true,
                                                                match: { value: 'password', errorMessage: 'La contraseña no coincide' }
                                                            }}
                                                            className="form-control"
                                                            id="useragainpass"
                                                            placeholder="Confirme su contraseña"
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Button className='btn btn-primary-lexmark' type="submit">Cambiar</Button>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ChangePassword;