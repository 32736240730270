import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from 'react-redux'
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from 'react-bootstrap';

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from 'react-toastify';


import useHttp from "../../hook/use-http";
import { BsBarChartSteps, BsFillCursorFill, BsFillStopCircleFill } from "react-icons/bs";


//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
    { id: 1, name: "SIGLO 21" },
    { id: 2, name: "TECNOMEGA" },
    { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
    { id: 1, name: "TRADICIONAL" },
    { id: 2, name: "CONNECT" },
]


const ValidateRequest = ({ history }) => {

    // this variable will be used to control the result of the form
    const [messageRequest, setMessageRequest] = useState('');
    // this variable will control the status of the message response
    const [messageStatusIsError, setMessageStatusIsError] = useState(null);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // this variable will save the code of the request
    const [codeRequest, setCodeRequest] = useState('');
    // this variable will represent the result of the request
    const [result, setResult] = useState(null);

    const [isResponse, setIsResponse] = useState(false);

    // this variable will be used to save all the data of the request
    const [requestAllInformation, setRequestAllInformation] = useState('');

    const handleGetAllInformationRequestDataResponse = (response) => {
        if (response.status) {

            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
            setMessageRequest(response.message);
            setMessageStatusIsError(true);
        } else {

            // here we need to process the data from the response
            setRequestAllInformation(response)

            setIsResponse(true);

        }
    }

    const handleGetAllInformationRequest = (id_request) => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/solicitud/list/` + id_request,
                method: 'GET',
                headers: myHeaders
            }, handleGetAllInformationRequestDataResponse)


        } catch (error) {
            console.log(error);
        }
    }


    const handleRequestDataResponse = (response) => {
        if (response.status) {

            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
            setMessageRequest(response.message);
            setMessageStatusIsError(true);
        } else {

            setMessageRequest('El certificado con el id ' + codeRequest + ' ha sido validado exitosamente');
            setMessageStatusIsError(false);

            // set the date of the request
            let d = new Date(response.createdDate)
            let dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');

            response.createdDate = dformat;

            setResult(response);

            // here will call the function to get all the information of the request
            handleGetAllInformationRequest(response.codigo_solicitud);

            // here we need to save the response into the local storage
            // then show data in a modal o something like that
        }
    }

    const handleSubmit = (error, values) => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: `/auth/certificado/id/` + codeRequest,
                method: 'GET',
                headers: myHeaders
            }, handleRequestDataResponse)


        } catch (error) {
            console.log(error);
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>

                                    {messageStatusIsError === true && < Alert color="danger">{messageRequest}</Alert>}
                                    {/*}
                                    {messageStatusIsError === false && < Alert color="success">{messageRequest}</Alert>}
                                    */}
                                    <AvForm className="needs-validation" onValidSubmit={(error, values) => handleSubmit(error, values)}  >
                                        <h4 className="card-title mb-4">VALIDAR SOLICITUD</h4>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3 d-flex">
                                                    <Label className="form-label" htmlFor="validationCustom01">Codigo Solicitud</Label>
                                                    <AvField
                                                        name="codigo_solicitud"
                                                        type="text"
                                                        errorMessage="Ingrese el codigo del a solicitud"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                                        value={codeRequest}
                                                        onChange={(e) => setCodeRequest(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            {isResponse && (!messageStatusIsError) && (

                                                <Col md="12">
                                                    < Alert color="success">
                                                        <Row className="d-flex justify-content-center ">

                                                            <Col xl="4" className="d-flex flex-column justify-content-around" >
                                                                <div style={{ marginBottom: 10 }} >

                                                                    <div>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            CODIGO SOLICITUD
                                                                        </span>
                                                                        <br />
                                                                        {result && result.codigo_solicitud}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 10 }} >
                                                                    <div>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            RAZON SOCIAL
                                                                        </span>
                                                                        <br />
                                                                        {requestAllInformation && requestAllInformation.canal.razonSocial}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 10 }} >
                                                                    <div>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            TIPO
                                                                        </span>
                                                                        <br />
                                                                        {requestAllInformation && requestAllInformation.tipo}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 10 }} >
                                                                    <div>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            ACEPTADO POR
                                                                        </span>
                                                                        <br />
                                                                        {result && result.createdBy}
                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col xl="4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 0 }} >

                                                                <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>
                                                                    <div className="d-flex flex-column align-items-end" style={{ justifyContent: 'end' }}>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            CODIGO CERTIFICADO
                                                                        </span>
                                                                        {result && result.codigo_certificado}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>

                                                                    <div className="d-flex flex-column align-items-end" style={{ justifyContent: 'end' }}>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            RUC
                                                                        </span>
                                                                        {requestAllInformation && requestAllInformation.ruc}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>

                                                                    <div className="d-flex flex-column align-items-end" style={{ justifyContent: 'end' }}>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            MAYORISTA
                                                                        </span>
                                                                        {requestAllInformation && requestAllInformation.mayorista}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>

                                                                    <div className="d-flex flex-column align-items-end" style={{ justifyContent: 'end' }}>
                                                                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                                            FECHA DE ACEPTACIÓN
                                                                        </span>
                                                                        {result && result.createdDate}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Alert>
                                                </Col>
                                            )}


                                        </Row>

                                        <Button className="btn btn-primary-lexmark" type="submit">Solicitar</Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>


        </React.Fragment >
    );

}

export default ValidateRequest;
