import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { BsBarChartSteps, BsEye, BsFillCalendarCheckFill, BsFillCaretDownFill, BsFillCaretUpFill, BsFillCursorFill, BsFillEjectFill, BsFillEnvelopeFill, BsFillFileEarmarkTextFill, BsFillGrid1X2Fill, BsFillGrid3X3GapFill, BsFillPatchCheckFill, BsFillPencilFill, BsFillPersonCheckFill, BsFillStopCircleFill, BsFillTelephoneForwardFill, BsFillVinylFill, BsFillXCircleFill, BsFillXDiamondFill, BsHandThumbsUp, BsHandThumbsUpFill, BsInfoCircleFill, BsReverseLayoutTextSidebarReverse } from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Button } from 'react-bootstrap';

import useHttp from "../../hook/use-http";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";


const OpportunityGroup = ({ history }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)


    // this variable will be save the opportunity selected
    const [opportunitySelected, setOpportunitySelected] = useState([])
    // this variable contain the name of the group that we wanna create
    const [nameGroup, setNameGroup] = useState("")
    // this variable will save the count of the opportunities 
    const [countOpportunity, setCountOpportunity] = useState(0)

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [productData, setProductData] = useState([]);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();


    // this variable and function will be used to control the modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // this function will control the data save in opportunitySelected
    const handleSelectOpportunity = (row) => {
        // first check if the row is selected or not
        const opportunity = opportunitySelected.filter(item => {
            return item.cod_solicictud === row.cod_solicictud
        });

        try {
            // if the opportunity is not in the array, then add it 
            if (opportunity?.length === 0 ||
                opportunity === undefined ||
                opportunity === null
            ) {
                setOpportunitySelected((prevState) => [...prevState, row])
            } else {
                // if the opportunity is in the array, then remove it
                setOpportunitySelected((prevState) => [...prevState.filter(item => item.cod_solicictud !== row.cod_solicictud)])
            }
        } catch (error) {
            // show a message error if something goes wrong
            console.log(error)
        }
    }

    // this function will process the data response after call the server method handleCreateGroup
    const handleCreateGroupDataResponse = (response) => {

        if (response.status) {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

        } else {
            history.push("/oportunidad-lista-admin")
        }

    }
    // this function will be used to create a new group of opportunities
    const handleCreateGroup = () => {
        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            
            let setOtherFormantOpportunity = opportunitySelected.map(item => {
                return {
                    "codigo": item.cod_solicictud
                }
            });

            request({
                url: `/auth/oportunidad/group/register`,
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoGrupo": nameGroup,
                    "oportunidades": setOtherFormantOpportunity
                }
            }, handleCreateGroupDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    // this function will be used to redirect to the product detail page
    const onClickRegisterTable = (code) => {
        history.push(`/opportunity-groups-details/${code}`)
    }

    // this function will be process the result of the method getListChannel
    const handleGetListChannelDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here

            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            // this variable will save all groups 
            const result = [];

            // add and extra field to products
            products.map((item, index) => {

                if (item?.createdDate?.length > 0) {

                    // this is only id 
                    item.id = index + 1

                    // this variable will save the code of the group in the database
                    item.code = item.codigo

                    // this variable will save the name of the group
                    item.group_name = item.codigoGrupo

                    // this variable contain an array with all opportunities
                    item.opportunities = item.oportunidades

                    // this variable will be used to save the date when the group was created 
                    let d = new Date(item.createdDate)
                    let dformat = [d.getMonth() + 1,
                    d.getDate(),
                    d.getFullYear()].join('/') + ' ' +
                        [d.getHours(),
                        d.getMinutes(),
                        d.getSeconds()].join(':');

                    item.date_created = dformat

                    let descripcionGrupo =
                        item.descripcionGrupo ? item.descripcionGrupo :
                            'dfdfdddddddddddddddddddddddddddddddddd sfdsf sfdsfsdf'

                    item.description_group = descripcionGrupo?.length > 30 ?
                        descripcionGrupo?.slice(0, 30) + '...' :
                        descripcionGrupo

                    // this variable represent the maximum element in a group
                    item.counter =
                        <div style={{ margin: 'auto', display: 'flex' }}>
                            {item.opportunities?.length} OPORTUNIDADES
                        </div>

                    // this variable save an html element that redirect to a detail of the group
                    item.change =
                        <button
                            className="btn btn-info" onClick={() => onClickRegisterTable(item.group_name)}
                            style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}
                        >
                            <div className="icon-eyes" >
                                <BsFillFileEarmarkTextFill />
                            </div>
                        </button>

                    // finally save the element into an array with all groups
                    result.push(item)
                }
            });

            // Here we sort the array by the date created
            const res = result.sort((a, b) => {
                const dateA = new Date(a.createdDate);
                const dateB = new Date(b.createdDate);
                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                return 0;
            })

            setCountOpportunity(res.length)

            // define the variable that will save the data
            setProductData(res);
        }
    }

    // this function will be used to request the data from the server
    const getListChannel = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                // /auth/oportunidad/list/group/all
                url: `/auth/oportunidad/list/group/all`,
                method: 'GET',
                headers: myHeaders
            }, handleGetListChannelDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    const dateInNewFormat = (date) => {
        let d = new Date(date)
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        return dformat;
    }

    // that will focus to delete one opportunity inside a group by code
    const deleteOpportunityIntoGroup = (opportunity, group_name) => {

        const getGroupByName = Object.values(productData).find(element => {
            return element.codigoGrupo === group_name
        })

        const setInNewFormatOpportunities = Object.values(getGroupByName?.oportunidades).filter(op => {
            return op.codigo !== opportunity && { "codigo": op.codigo }
        })

        
        console.log(setInNewFormatOpportunities)
    }

    useEffect(() => {
        getListChannel();
    }, [])

    let valueExtra = {
        hoverIdx: null
    }

    let expandRow = {
        renderer: (row) => (
            <>
                <Row>
                    {Object.values(row.opportunities).map(element => {
                        return (
                            <div>
                                <Col
                                    xl="12"
                                    style={{ width: '100%' }}
                                    id="datos"
                                    className="d-flex flex-row justify-content-between"
                                >
                                    <h6 style={{ fontWeight: 'bold' }}>
                                        OPORTUNIDAD {element.codigo}
                                    </h6>

                                    {/* 
                                    <div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => deleteOpportunityIntoGroup(element?.codigo, row.group_name)}
                                        >
                                            -
                                        </button>
                                    </div>
                                    */}
                                </Col>
                                <Col xl="12" style={{ width: '100%' }} id="datos" >
                                    <Card style={{ height: '100%' }} >
                                        <CardBody>

                                            <AvForm className="needs-validation" >
                                                <Row
                                                    className="d-flex justify-content-center align-content-center align-items-center flex-wrap mx-5 border-bottom border-light">

                                                    <Col xl="4"
                                                        className="d-flex flex-columns "
                                                        style={{ display: 'flex', flexDirection: 'column' }}
                                                    >

                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                TIPO DE OPORTUNIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {element.tipo}
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                CANAL:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {element.canal?.razonSocial}
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                ENTIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {element.informacionEntidad?.entidad}
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                    <Col xl="4"
                                                        className="d-flex flex-column align-items-center"
                                                    >
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                NOMBRE CONTACTO EN LA ENTIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {(element.informacionEntidad?.nombreContacto)?.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                EMAIL DE LA ENTIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }}>
                                                                {(element.informacionEntidad?.email)?.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                TELEFONO DE LA ENTIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }}>
                                                                {element.informacionEntidad?.telefono}
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                    <Col xl="4"
                                                        className="d-flex flex-column align-items-end"
                                                    >
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                CREACIÓN:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {dateInNewFormat(element?.createdDate)}
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                PRESUPUESTO:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }} >
                                                                {element.presupuesto + ' $'}
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                ESTADO:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }}>
                                                                {element.estado}
                                                            </h6>
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row className="mx-5">
                                                    <Col xl="12" >
                                                        <div className="d-flex my-3">
                                                            <h6 style={{ fontWeight: 'bold', margin: 0, fontSize: 12 }}>
                                                                DESCRIPCIÓN DEL TRABAJO REALIZADO EN LA ENTIDAD:{' '}
                                                            </h6>
                                                            <h6 style={{ margin: 0, fontSize: 12 }}>
                                                                {element.descripcion}
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        )
                    })}
                </Row>
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true
    };

    let rowEvents = {
        onMouseEnter: (e, row, rowIndex) => {
            valueExtra = { hoverIdx: rowIndex }
        },
        onMouseLeave: () => {
            valueExtra = { hoverIdx: null }
        }
    }

    let actionFormater = (cell, row, rowIndex, { hoverIdx }) => {
        if ((hoverIdx !== null || hoverIdx !== undefined) && hoverIdx === rowIndex) {
            return (
                <div
                    style={{ width: '20px', height: '20px', backgroundColor: 'orange' }}
                />
            );
        }
        return (
            <div
                style={{ width: '20px', height: '20px' }}
            />
        );
    }

    let rowStyle = (row, rowIndex) => {
        row.index = rowIndex;
        const style = {};
        if (rowIndex % 2 === 0) {
            //style.backgroundColor = 'transparent';
        } else {
            //style.backgroundColor = 'rgba(54, 163, 173, .10)';
        }
        //style.color = '#505D69'
        //style.borderTop = 'none';

        return style;
    }

    const columns = [
        {
            dataField: 'group_name',
            text: 'NOMBRE GRUPO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'description_group',
            text: 'DESCRIPCION GRUPO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'counter',
            text: 'OPORTUNIDADES AGRUPADAS',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'date_created',
            text: 'FECHA CREACION DEL GRUPO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        /*
        {
    
            dataField: 'canal_name',
            text: 'CANAL',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
    
        },
        {
            dataField: 'tipo',
            text: 'TIPO OPORTUNIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }
        , {
    
            dataField: 'descripcion',
            text: 'DESCRIPTION',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
    
        },
        {
    
            dataField: 'estado',
            text: 'ESTADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        */
        {

            dataField: 'change',
            text: 'DETALLES',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            text: '',
            isDummyField: true,
            formatter: actionFormater,
            formatExtraData: { hoverIdx: valueExtra.hoverIdx },
            headerStyle: { width: '50px' },
            style: { height: '30px' }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: countOpportunity, // replace later with size(customers),
        custom: true,
    }

    const { SearchBar } = Search;

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={productData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={productData}
                                                //selectRow={{ mode: 'checkbox', clickToSelect: true }}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <Row className="mb-2">
                                                            <Col md="12"
                                                                className="d-flex flex-row justify-content-between p-0"
                                                            >
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        rowStyle={rowStyle}
                                                                        rowEvents={rowEvents}
                                                                        expandRow={expandRow}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>AGRUPAR OPORTUNIDADES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AvForm className="needs-validation" onValidSubmit={(error, values) => handleCreateGroup(error, values)}  >
                        <Row>
                            <Col md="12">
                                <div className="mb-3 ">
                                    <Label className="form-label" htmlFor="validationCustom01">Nombre del Grupo</Label>
                                    <AvField
                                        name="grupo"
                                        type="text"
                                        errorMessage="Ingrese el nombre del grupo"
                                        className="form-control"
                                        id="validationCustom01"
                                        validate={{ required: { value: true }, minLength: { value: 3 } }}

                                        value={nameGroup}
                                        onChange={(e) => setNameGroup(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Modal.Footer className="p-0">
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button variant="primary" type="submit">
                                Crear
                            </Button>
                        </Modal.Footer>
                    </AvForm>
                </Modal.Body>

            </Modal>
        </React.Fragment>
    )
}

export default OpportunityGroup;