import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  BsEye,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsFillFileEarmarkTextFill,
} from "react-icons/bs";

// import the redux
import { useSelector, useDispatch } from "react-redux";

import useHttp from "../../hook/use-http";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { toast } from "react-toastify";

const OpportunityList = ({ history }) => {
  const profile = useSelector((state) => state.profileReducer.profile);

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.CertificationJsonConsume.data);

  // this variable will be used to control the settings of the table
  const page = 1;
  const sizePerPage = 10;
  // save the product after add and extra fields
  const [productData, setProductData] = useState([]);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // this function will be used to redirect to the product detail page
  const onClickRegisterTable = (code, estado) => {
    if (estado === "PENDIENTE" || estado === "CONVALIDAR") {
      history.push(`opportunity-edit/${code}`);
    } else {
      history.push(`opportunity-details/${code}`);
    }
  };

  // this function will be process the result of the method getListChannel
  const handleGetListChannelDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      const result = [];

      // add and extra field to products
      products.forEach((item, index) => {
        item.id = index + 1;
        // set the data to the variable request
        item.cod_solicictud = item.codigo;
        item.razon_social = item.descripcion;
        item.tipo_solicitud = item.tipo;
        item.estado_solicitud = item.estado;
        item.tipo_canal = item.tipo;
        item.observaciones = item.observaciones;

        let d = new Date(item.createdDate);
        let dformat =
          [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
          " " +
          [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

        item.createdDate = dformat;

        item.fechaDeclaracion = dformat;

        item.canal_name = item.canal?.razonSocial;

        // entity information
        item.nombreEntidad = item.informacionEntidad?.nombreEntidad;
        item.nombreContactoEntidad =
          item.informacionEntidad?.nombreContactoEntidad;
        item.emailEntidad = item.informacionEntidad?.emailEntidad;
        item.telefonoEntidad = item.informacionEntidad?.telefonoEntidad;
        item.presupuestoEntidad =
          item.informacionEntidad?.presupuestoEntidad + "$";

        item.descripcion =
          item.descripcion.length > 30
            ? item.descripcion.substring(0, 30) + "..."
            : item.descripcion;
        if (item.pathDeclaracion)
          item.file = (
            <a
              href={`${process.env.REACT_APP_S3_URL}${item.pathDeclaracion}`}
              alt="file-declaration"
              target="_blank"
              rel="noreferrer"
            >
              Archivo
            </a>
          );

        item.entidad =
          item?.informacionEntidad?.entidad?.length > 50
            ? item.informacionEntidad.entidad.substring(0, 50) + "..."
            : item.informacionEntidad.entidad;

        if(item.fechaCierre){
          item.fechaCierre = new Date(item.fechaCierre).toISOString().substring(0, 10)
        }

        item.change = (
          <button
            className="btn btn-info"
            onClick={() =>
              onClickRegisterTable(item.cod_solicictud, item.estado_solicitud)
            }
            style={{
              margin: "auto",
              display: "flex",
              backgroundColor: "#3af23a",
            }}
          >
            <div className="icon-eyes">
              {" "}
              <BsFillFileEarmarkTextFill />
            </div>
          </button>
        );

        result.push(item);
      });

      let data = result.sort((a, b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);

        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }
        return 0;
      });

      setProductData(data);
    }
  };

  // this function will be used to request the data from the server
  const getListChannel = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/list/ruc/${profile?.ruc}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetListChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    profile?.ruc && getListChannel();
  }, [profile.ruc]);

  const columns = [
    /*
        {
            dataField: 'checkbox',
            text: `${''}`,
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        */
    {
      dataField: "codigo",
      text: "COD-OP",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "entidad",
      text: "ENTIDAD",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "createdDate",
      text: "FECHA DE CREACIÓN",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "tipo",
      text: "TIPO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "file",
      text: "ARCHIVO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "descripcion",
      text: "DESCRIPCIÓN",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "mayorista",
      text: "MAYORISTA",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "fechaCierre",
      text: "FECHA DE CIERRE",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "change",
      text: "DETALLES",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    /*
        {

            dataField: 'fechaDeclaracion',
            text: 'FECHA DECLARACIÓN OPORTUNIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {

            dataField: 'createdDate',
            text: 'FECHA DE APROBACIÓN',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }

        },
        {
            dataField: 'nombreEntidad',
            text: 'ENTIDAD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'nombreContactoEntidad',
            text: 'NOMBRE CONTACTO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'emailEntidad',
            text: 'EMAIL',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'telefonoEntidad',
            text: 'TELEFONO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        {
            dataField: 'presupuestoEntidad',
            text: 'PRESUPUESTO ESTIMADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        },
        */
    /*
        {
            dataField: 'change',
            text: 'PRESUPUESTO ESTIMADO',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            },
        }
        */
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="table-responsive"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OpportunityList;
