
import { channel } from "redux-saga"
import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_DATA_JSON,
  UPDATE_DATA_JSON,
  CHANGE_STATUS_CHANNEL,
  CREATE_DATA_JSON
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  // In this part we include a data example to use in the app
  data: [
    { "qty": "150", "pn": "50F4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "10", "pn": "58D4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "28", "pn": "56F4X00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "2", "pn": "76C00K0", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "6", "pn": "58D0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "10", "pn": "56F0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "50", "pn": "50F0Z00", "entidad": "EMPRESA ELECTRICA REGIONAL CENTROSUR C.A.", "codigo": "SIE-EECS-DT-128-2021", "date_limit_question": "12/13/2021 19:30:00", "date_limit_answer": "12/15/2021 20:00:00", "date_limit_qualification": "01/12/2022 20:00:00", "date_puja": "01/13/2022 09:00:00", "canal_ajudicado": "AKROS CIA. LTDA.", "valor_final_puja": "27452", "estado_proceso": "Por adjudicar", "mayorista": "Megamicro", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "maria.sarmiento@centrosur.gob.ec" }
    ,
    { "qty": "8", "pn": "50F4H00", "entidad": "UNIVERSIDAD TECNICA DE AMBATO", "codigo": "SIE-UTA-018-2021", "date_limit_question": "12/15/2021 13:00:00", "date_limit_answer": "12/20/2021 13:00:00", "date_limit_qualification": "01/06/2022 20:00:00", "date_puja": "01/07/2022 10:00:00", "canal_ajudicado": "SHUGULI GUACOLLANTE HECTOR RODRIGO", "valor_final_puja": "66490", "estado_proceso": "Por adjudicar", "mayorista": "No es canal", "mes_ajudicado": "Enero", "plazo": "60 dias", "contacto_funcionario": "am.velastegui@uta.edu.ec" }
    ,
    { "qty": "7", "pn": "40X7743", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "5", "pn": "40X8420", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "10", "pn": "52D0Z00", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
    ,
    { "qty": "40", "pn": "52D4X00", "entidad": "DIRECCION PROVINCIAL DEL CONSEJO DE LA JUDICATURA PICHINCHA", "codigo": "SIE-DP17-008-2021", "date_limit_question": "12/20/2021 12:00:00", "date_limit_answer": "12/21/2021 12:00:00", "date_limit_qualification": "01/05/2022 17:00:00", "date_puja": "01/06/2022 14:00:00", "canal_ajudicado": "Desierta", "valor_final_puja": "Desierta", "estado_proceso": "Desierta", "mayorista": "Desierta", "mes_ajudicado": "Enero", "plazo": "20 dias", "contacto_funcionario": "evelyn.urbina@funcionjudicial.gob.ec" }
  ]
}

const ReportDetailsJsonConsume = (state = INIT_STATE, action) => {
  switch (action.type) {

    /* REPORT DETAILS */


    /* END REPORT DETAILS */

    // This will be used to get the data json
    case GET_DATA_JSON:
      return {
        ...state,
        data: action.payload
      }

    case CREATE_DATA_JSON:
      console.log(action.payload)
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case UPDATE_DATA_JSON:

      const id = action.payload.id
      const newChannel = action.payload.data
      const channels = state.data
      channels[id] = newChannel

      return {
        ...state,
        state: {
          data: channels
        }
      }

    case CHANGE_STATUS_CHANNEL:

      const index = action.payload.id
      const status = action.payload.status

      // this variable get all channels
      const chs = state.data
      // get the specific channel
      const ch = chs[index]
      ch.estado = status

      // save the ch in the array chns
      chs[index] = ch

      return {
        ...state,
        state: {
          data: chs
        }
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ReportDetailsJsonConsume
