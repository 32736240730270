import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';


// import the redux
import { useSelector, useDispatch } from 'react-redux'

// import lib to export to excel 
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { toast } from "react-toastify";


import useHttp from "../../hook/use-http"

const GeneralReport = ({ history }) => {

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.GeneralReportsJsonConsume.data)

    // this will be used to set the data in a variable 
    const [reports, setReports] = useState(dataJson)

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [reportsData, setReportsData] = useState([]);

    /*
    useEffect(() => {
        const result = []

        // add and extra field to products
        reports.map((item, index) => {
            item.id = index + 1
            result.push(item)
        });

        setReportsData(result);

    }, [])
    */

    // this function will be process the result of the method getListChannel
    const handleGetGeneralReportDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here

            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            const result = [];

            // add and extra field to products
            products.map((item, index) => {
                console.log('eeeee')


                item.id = index + 1
                // set the data to the variable request
                item.cod_solicictud = item.codigo
                item.razon_social = item.descripcion
                item.tipo_solicitud = item.tipo
                item.estado_solicitud = item.estado
                item.tipo_canal = item.canal ? item.canal.tipo : ''
                item.entidad = item?.datosSectorPublico ? item?.datosSectorPublico?.entidadContratante : ''
                //item.observaciones = item.observaciones

                let d = new Date(item.createdDate)
                let dformat = [d.getMonth() + 1,
                d.getDate(),
                d.getFullYear()].join('/') + ' ' +
                    [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');

                item.createdDate = dformat

                // here control de name of the channel
                item.nombre = item.descripcion

                // name of the person who created the request
                item.nombre_contacto = item.canal ? item.canal.contactoPrincipal.nombreContacto : ''
                item.email_contacto = item.canal ? item.canal.contactoPrincipal.emailContacto : ''
                item.celular_contacto = item.canal ? item.canal.contactoPrincipal.celularContacto : ''
                item.cargo_contacto = item.canal ? item.canal.contactoPrincipal.cargoContacto : ''

                result.push(item)
            });

            console.log(result)
            //setProductData(result);
            setReportsData(result);
        }
    }

    // this function will be used to request the data from the server
    const getGeneralReport = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: '/auth/solicitud/reportes/reporteGeneral',
                method: 'GET',
                headers: myHeaders
            }, handleGetGeneralReportDataResponse)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        getGeneralReport();

    }, [])



    const { ExportCSVButton } = CSVExport;

    const columns = [
        {
            dataField: 'codigo',
            text: 'ID SOLICITD',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'nombre',
            text: 'CANAL SOLICITANTE',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'entidad',
            text: 'ENTIDAD',
            sort: true,

            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'tipo_solicitud',
            text: 'TIPO SOLICITUD',
            sort: true,

            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'estado_solicitud',
            text: 'ESTADO SOLICITUD',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'mayorista',
            text: 'MAYORISTA',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'tipo_canal',
            text: 'TIPO CANAL',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'createdDate',
            text: 'FECHA SOLICITUD',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }, {
            dataField: 'nombre_contacto',
            text: 'NOMBRE SOLICITANTE',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'email_contacto',
            text: 'EMAIL SOLICITANTE',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }, {
            dataField: 'celular_contacto',
            text: 'CELULAR CONTACTO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
        }, {
            dataField: 'cargo_contacto',
            text: 'CARGO CONTACTO',
            sort: true,
            filter: textFilter({
                placeholder: `${' '}`,
                style: {
                    width: 'auto',
                    marginLeft: '10px',
                    paddingRight: '10px',
                }
            }),
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 50,
        totalSize: reports.length, // replace later with size(customers),
        custom: true,
    }


    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={reportsData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={reportsData}
                                                exportCSV={{
                                                    fileName: 'reportsGeneral.csv',
                                                    blobType: 'text/csv;charset=ansi'
                                                }}
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <Row className="mb-2 d-flex justify-content-between">
                                                            {/*
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                             */}
                                                            <Col className="d-flex justify-content-end" >
                                                                {/* 
                                                                < ReactHtmlTableToExcel
                                                                    id="buttonToExportExcel"
                                                                    className="btn btn-success"
                                                                    table="table-responsive"
                                                                    filename="reportsGeneral"
                                                                    sheet="Page 1"
                                                                    buttonText="Export to Excel"
                                                                />
                                                                */}
                                                                <ExportCSVButton
                                                                    {...toolkitProps.csvProps}
                                                                    className="btn btn-success"
                                                                >Export to Excel</ExportCSVButton>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-responsive-custom-lexmark"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        filter={filterFactory()}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}


export default GeneralReport