import React from 'react'
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

const RequestReject = ({ history }) => {

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={4}>
                            <Card outline color="danger" className="border">
                                <CardHeader className="bg-transparent">
                                    <h5 className="my-0 text-danger"><i className="mdi mdi-block-helper me-3"></i>SOLICITUD RECHAZADA CON EXITO</h5>
                                </CardHeader>
                                <CardBody>
                                    <CardText>En este momento se ha rechazado la solicitud, por favor regrese a la lista de solicitudes para visualizar solicitudes pendientes.</CardText>
                                    <Button
                                        className='btn btn-primary-lexmark'
                                        onClick={() => history.push('/approval-request-list')}
                                    >Consultar Solicitudes</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default RequestReject;