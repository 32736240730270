import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import {
    BsFillCursorFill,
    BsBarChartSteps,
    BsFillCalendarCheckFill,
    BsFillPersonCheckFill,
    BsReverseLayoutTextSidebarReverse,
    BsFillStopCircleFill,
} from "react-icons/bs";

// import the special component 
import Specials from "./Specials";

import useHttp from "../../hook/use-http";

// import the style of the component
import './style.css'
import { toast } from "react-toastify";


// this variable will be used to control the type of the request
// this value will get in the param
let typeSolicitation = 'especiales'

const DetailsSpecialCertificate = ({ match, history }) => {

    // this variable will be used to control the type of the request
    // this function will be used to request the server to login the user
    const { loading, error, request: requestUrl } = useHttp();

    // this variable represent the date of the request
    const [dateRequest, setDateRequest] = useState('')

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)

    // this variable will be used to set the channel selected
    const [request, setRequest] = useState([])

    // this variable will be used to get information of the channel
    const profile = useSelector(state => state.profileReducer.profile)

    /*********************************************************************** */
    /*********************************************************************** */
    /**  DECLARE THE VARIABLE USED TO CONTROL THE VIEW OF THE USER ***********/

    const [isAdmin, setIsAdmin] = useState(false)

    /*********************************************************************** */
    /*********************************************************************** */

    /*********************************************************************** */
    /*********************************************************************** */
    /**  DECLARE THE VARIABLE USED TO CREATE A SPECIAL REQUEST  **************/

    const [specificRequirement, setSpecificRequirement] = useState('')

    /*********************************************************************** */
    /*********************************************************************** */

    // this function will be used to get the specific requirement of the component special
    // and set the value of the specific requirement
    const onHandleEdithRequest = (e) => {
        setSpecificRequirement(e.target.value)
    }

    // this function will be used to process the request of getChannelProfile
    const getChannelProfileDataRequest = (e) => {

        if (!e.status) {
            // set the header of the request
            // add a extra field to the header
            setRequest(prov => {
                return {
                    ...prov,
                    razon_social: e.razonSocial,
                    ruc: e.ruc,
                    mayorista: e.mayorista
                }
            })
        }
    }

    // this function will be get the header of the channel 
    const getChannelProfile = () => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/channel/register`,
                method: 'GET',
                headers: myHeaders,
            }, getChannelProfileDataRequest)

        } catch (err) {
            console.log(err)
        }

    }

    // this function will be used to get the profile of the channel
    const getChannelProfileAdmin = (ruc) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/channel/list/ruc/` + ruc,
                method: 'GET',
                headers: myHeaders,
            }, getChannelProfileDataRequest)

        } catch (err) {
            console.log(err)
        }

    }
    // this function will be used to process the request of getDataOfCodeRequest
    const getDataOfCodeRequestDataResponse = (response) => {
        if (!response.status) {
            // set information of the request 
            setRequest(prov => {
                return {
                    ...prov,
                    estado: response.estado,
                    mayorista: response.mayorista,
                    RUC: response.ruc,
                    estado: response.estado,
                    requerimiento: response.requerimentoEspecifico
                }
            })

            // and call the function to get the profile of the channel admin 
            // to create the header of the component special
            getChannelProfileAdmin(response.ruc)
        }
    }

    // this function will be get the data of the code of the request
    const getDataOfCodeRequest = (code) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/solicitud/list/` + code,
                method: 'GET',
                headers: myHeaders,
            }, getDataOfCodeRequestDataResponse)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {

        const { code } = match.params
        console.log(match.params)
        if (code) {
            getDataOfCodeRequest(code);
        } /*else {

            // this variable will save the channel selected
            const resultSelected = dataJson[0]

            // set the channel selected
            setRequest(resultSelected)
        }
        */


        // here i need to calculate the time of the request
        let d = new Date
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        setDateRequest(dformat)

        const role = localStorage.getItem('role')

        if (role == 'ROLE_CHANNEL') {
            setIsAdmin(false)
        } else {
            setIsAdmin(true)
        }

    }, [])

    const sendRequestSpecialDataResponse = (response) => {
        if (response.status === 401) {
            // show a error message here
            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            history.push('/request-sended')

        }
    }

    const sendRequestSpecial = () => {
        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/register/especial',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "ruc": profile.ruc,
                    "descripcion": profile.razonSocial,
                    "tipo": "ESPECIAL",
                    "mayorista": profile.mayorista,
                    "sector": "PRIVADO",
                    "requerimentoEspecifico": specificRequirement,
                    "observaciones": ""
                }
            }, sendRequestSpecialDataResponse)

        } catch (er) {
            console.log(er)
        }
    }

    const approveRequestAdminFunctionDataResponse = (response) => {
        history.push('/request-approved')
    }

    const approveRequestAdminFunction = () => {
        try {

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/aprobar',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoSolicitud": code
                }
            }, approveRequestAdminFunctionDataResponse)

        } catch (er) {
            console.log(er)
        }

    }


    // redirect to component request sended
    const handleRedirect = () => {
        history.push('/request-sended')
    }

    return (
        <React.Fragment>
            {request && (

                <div className="page-content">
                    <Container fluid={true}>

                        <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>

                            <h1 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                SOLICITAR CERTIFICADO {typeSolicitation.toUpperCase()}
                            </h1>

                            <Col xl="12" style={{ width: '100%' }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>
                                        <AvForm className="needs-validation" >

                                            <Row style={{ marginTop: 20 }} className="d-flex justify-content-around">

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div >

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                CANAL
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCursorFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.razon_social ? request.razon_social : profile.razonSocial}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                MAYORISTA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillPersonCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.mayorista ? request.mayorista : profile.mayorista}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                TIPO DE CERTIFICADO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsBarChartSteps />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {typeSolicitation.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: 20 }} className="d-flex justify-content-around">

                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                RUC
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsReverseLayoutTextSidebarReverse />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.ruc ? request.ruc : profile.ruc}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA SOLICITUD
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCalendarCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {dateRequest}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl="4" className="d-flex justify-content-around">
                                                    <div>

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                ESTADO
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillStopCircleFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.estado ? request.estado : 'EDITANDO'}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/*

                            < Specials
                                onHandleEdithRequestFatherComponent={onHandleEdithRequest}
                                requestSpecial={request.requerimiento ? request.requerimiento : null}
                            />
                            
                            */}

                            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                                <Card style={{ height: '100%' }}>
                                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="d-flex justify-content-between" style={{ marginTop: 20 }}>
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                ESPECIFICAR EL REQUERIMIENTO
                                            </h4>
                                        </div>
                                        <div id="list-supplies">
                                            <textarea
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows="3"
                                                value={request.requerimiento ? request.requerimiento : specificRequirement}
                                                onChange={(event) => setSpecificRequirement(event.target.value)}
                                                disabled={request.requerimiento ? true : false}
                                            ></textarea>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        {isAdmin ? (
                            <>
                                <div className="d-flex justify-content-end mt-3">
                                    <div className="mx-3">
                                        <button
                                            className="btn btn-danger-lexmark mx-2"
                                            type="button"
                                        //onClick={handleRedirect}
                                        >RECHAZAR</button>
                                        <button
                                            className="btn btn-primary-lexmark"
                                            type="button"
                                            onClick={approveRequestAdminFunction}
                                        >APROBAR</button>
                                    </div>
                                </div>

                            </>
                        ) : (
                            <div className="d-flex justify-content-end mt-3">
                                <div className="mx-3">
                                    <button
                                        className="btn btn-primary-lexmark"
                                        type="button"
                                        onClick={sendRequestSpecial}
                                    >SOLICITAR</button>
                                </div>
                            </div>
                        )}
                    </Container>
                </div>
            )}
        </React.Fragment >
    );

}

export default DetailsSpecialCertificate;
