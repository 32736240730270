import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { map } from "lodash";
import { useEffect } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Col, Label, Row } from 'reactstrap';

import { Modal, Button } from 'react-bootstrap';

import avatar2 from '../../assets/images/users/calendar_1.png';
import { BsPatchCheckFill } from 'react-icons/bs';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Pagination from './pagination'

const Binnacle = ({ comments, addNewItemIntoBinnacle, isAdmin, stateOpportunity }) => {

    const [lastComment, setLastComment] = useState(null)
    const [newComment, setNewComment] = useState('')
    const [allComments, setAllComments] = useState(comments ? comments : [{ id: 1 }])

    useEffect(() => {
        if (comments.length > allComments.length) {
            setAllComments(comments)
        }
    })

    // this variable and function will be used to control the modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // this will be used to control de pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [commentsPerPage, setCommentsPerPage] = useState(3)

    useEffect(() => {

        if (Object.values(comments).length > 0) {
            setLastComment(comments.length)
        }
    })

    const addNewCommentIntoBinnacle = () => {
        // this variable will be used to get a new date of the new item into binnacle
        const dateNewComment = (new Date())?.toISOString()

        // In this case i send this json to father component to process and send to backend
        addNewItemIntoBinnacle({
            fechaRegistro: dateNewComment,
            observacion: newComment
        })

        setNewComment('')
        handleClose()
    }

    const columns = [
        {
            dataField: 'checkbox',
            text: `${''}`,
            sort: true,


        },
        {
            dataField: 'codigo',
            text: 'CO-OP',
            sort: true,

        },
        {

            dataField: 'canal_name',
            text: 'CANAL',
            sort: true,


        },
        {
            dataField: 'tipo',
            text: 'TIPO',
            sort: true,

        },
        {
            dataField: 'shortEntidad',
            text: 'ENTIDAD',
            sort: true,

        },
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 3,
        totalSize: 15, // replace later with size(customers),
        custom: true,
    }

    // Get current comments
    let indexOfLastComment = currentPage * commentsPerPage;
    let indexOfFirstComment = indexOfLastComment - commentsPerPage
    let currentComment = Object.values(allComments).slice(indexOfFirstComment, indexOfLastComment);


    // change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber)
    };

    return (
        <>
            <div className="mt-4">
                <div className='d-flex flex-row justify-content-between mb-3 align-items-center'>
                    <h4
                        style={{ fontWeight: 'bold' }}
                    >BITÁCORA</h4>

                    {!isAdmin && stateOpportunity === 'APROBADA' &&
                        < Button
                            variant="primary"
                            onClick={handleShow}
                            className="mr-3 d-flex justify-content-center align-items-center"
                        >
                            <BsPatchCheckFill className="mx-2" />
                            Agregar
                        </Button>
                    }
                </div>

                {allComments.length > 0 &&

                    <React.Fragment>

                        <div className="border rounded border-success p-4" >

                            {map(currentComment, (comment, k) => (
                                <React.Fragment key={"review_" + k} className="mt-1">
                                    <div
                                        className={
                                            // this will be when we only have one comment
                                            lastComment === 1 ? "d-flex border-top border-success pb-3 pt-3" :
                                                comment.id === 1 ? "d-flex border-bottom  border-success pb-3 pt-3" :
                                                    // if the last comment is equal to the current comment
                                                    comment.id === lastComment ?
                                                        "d-flex border-bottom  border-top border-success py-3" :
                                                        comment.id === lastComment + 1 ? "d-flex border-top border-success py-3" :
                                                            // if we have a normal comment 
                                                            "d-flex border-bottom border-success py-3"
                                        }
                                    >
                                        <div className="d-flex flex-column">
                                            <p className=" mb-2">{comment.description}</p>
                                            <div className='d-flex flex-row align-items-center'>
                                                <img className="rounded-circle header-profile-user me-1" src={avatar2} alt="Header Avatar" />
                                                <p
                                                    className="float-sm-end font-size-13 d-flex align-content-center m-0"
                                                >
                                                    {comment.date}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}

                            <div className='d-flex flex-row justify-content-between align-items-center mt-3'>

                                <button
                                    id='pageDropDown'
                                    type='button'
                                    className='btn btn-default btn-secondary dropdown-toggle m-0'
                                    style={{ width: 35, height: 40 }}
                                >
                                    {commentsPerPage}
                                </button>

                                <Pagination
                                    commentsPerPage={commentsPerPage}
                                    totalComments={allComments.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            </div>

                        </div>

                    </React.Fragment>
                }


                <Modal show={show} onHide={handleClose} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>OBSERVACIONES</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AvForm className="needs-validation" onValidSubmit={(error, values) => addNewCommentIntoBinnacle(error, values)}  >
                            <Row>
                                <Col md="12">
                                    <div className="mb-3 ">
                                        <Label className="form-label" htmlFor="validationCustom01">OBSERVACIONES</Label>

                                        <AvField
                                            name="text"
                                            type="textarea"
                                            className="form-control"
                                            errorMessage='La observación debe tener al menos 4 caracteres'
                                            value={newComment}
                                            validate={{
                                                required: { value: true },
                                                minLength: { value: 4, errorMessage: 'La observación debe tener al menos 4 caracteres' },
                                            }}
                                            id="validationCustom01"
                                            onChange={(e) => setNewComment(e.target.value)}
                                            rows="5"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Modal.Footer className="p-0">
                                <Button variant="secondary"
                                    onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                //onClick={handleApproveOpportunity}
                                >
                                    Agregar
                                </Button>
                            </Modal.Footer>
                        </AvForm>
                    </Modal.Body>

                </Modal>

            </div>
        </>
    );
}

export default Binnacle