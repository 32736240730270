import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { saveAs } from 'file-saver'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// import the redux
import { useSelector, useDispatch } from 'react-redux'

// import lib to export to excel 
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { BsFillArrowDownCircleFill, BsFillCaretDownFill, BsFillCaretUpFill, BsFillFileEarmarkTextFill } from "react-icons/bs";

import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

const ConsultRequestsChannel = ({ history }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.GeneralReportsJsonConsume.data)

    // this will be used to set the data in a variable 
    const [reports, setReports] = useState(dataJson)

    // this variable will be used to control the settings of the table
    const page = 1;
    const sizePerPage = 10;
    // save the product after add and extra fields
    const [reportsData, setReportsData] = useState([]);
    const [amountItemsIntoList, setAmountItemsIntoList] = useState(0);

    // this function will be used to request the server to login the user
    const { loading, error, request } = useHttp();

    // this function will be used to redirect to details certification 
    const handleDetails = (type, code) => {
        //history.push(`/detail-certificate/suministro`)
        if (type === 'ESPECIAL') {
            history.push('/special-certificate/' + code);
        } else if (type === 'SUMINISTRO') {
            history.push('/supply-certificate/' + code);
        } else {
            history.push('/team-certification/' + code);
        }
    }


    const handleRequestDownloadDataResponse = async (response) => {
        const result = await response.blob();
        const blob = new Blob([result], { type: 'application/pdf' })
        saveAs(blob, "certificados.pdf")
    }

    const handleRequestDownload = (code) => {
        try {
            try {
                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
                myHeaders.append("Accept", "application/pdf");
                myHeaders.append("responseType", "blob");

                request({
                    url: '/auth/certificado/download/' + code,
                    method: 'GET',
                    headers: myHeaders,
                }, handleRequestDownloadDataResponse)

            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleDownloadPDF = (status, code, type) => {
        if (status === 'APROBADO' && type !== 'ESPECIAL') {
            handleRequestDownload(code);
        }
    }
    // this function will be process the result of the method getListChannel
    const handleGetListChannelDataResponse = (products) => {

        if (products.status === 401) {
            // show a error message here
            if (products.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(products.message, { type: 'error', autoClose: 10000 });
        } else {

            const result = [];
            // add and extra field to products
            products.map((item, index) => {

                item.id = index + 1
                item.solicitud = item.codigo
                item.tipo_solicitud = item.tipo
                item.estado_solicitud = item.estado

                let d = new Date(item.createdDate)
                let dformat = [d.getMonth() + 1,
                d.getDate(),
                d.getFullYear()].join('/') + ' ' +
                    [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');

                item.createdDate = dformat

                item.change =
                    <button
                        className="btn btn-info"
                        onClick={() => handleDetails(item.tipo, item.codigo)}
                        style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}

                    >
                        <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillFileEarmarkTextFill />
                        </div>
                    </button>

                item.download =
                    <button
                        className="btn btn-info"
                        onClick={() => handleDownloadPDF(item.estado, item.codigo, item.tipo)}
                        style={{ margin: 'auto', display: 'flex', backgroundColor: "#3af23a" }}
                    >
                        <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillArrowDownCircleFill />
                        </div>
                    </button>

                result.push(item)
            });

            setAmountItemsIntoList(result.length)
            const data = result.sort((a, b) => {
                const dateA = new Date(a.createdDate);
                const dateB = new Date(b.createdDate);

                if (dateA < dateB) {
                    return 1;
                }
                if (dateA > dateB) {
                    return -1;
                }
                return 0;
            });
            setReportsData(data);

        }
    }

    // this function will be used to request the data from the server
    const getListChannel = () => {

        try {

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            request({
                url: '/auth/solicitud/list/user',
                method: 'GET',
                headers: myHeaders,
            }, handleGetListChannelDataResponse)

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        const result = []

        getListChannel();

        /*

        // add and extra field to products
        reports.map((item, index) => {
            item.id = index + 1

            item.change =
                <button
                    className="btn btn-info"
                    onClick={() => handleDetails()}
                >
                    <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillFileEarmarkTextFill />
                    </div>
                </button>

            item.download =
                <button
                    className="btn btn-info"
                >
                    <div className="icon-eyes" style={{ margin: 'auto auto' }} > <BsFillArrowDownCircleFill />
                    </div>
                </button>

            result.push(item)
        });

        setReportsData([result[0]]);
        */

    }, [])


    const columns = [
        {
            dataField: 'solicitud',
            text: 'ID SOLICITD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'tipo_solicitud',
            text: 'TIPO SOLICITUD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'mayorista',
            text: 'MAYORISTA',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'createdDate',
            text: 'FECHA SOLICITUD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'estado_solicitud',
            text: 'ESTADO SOLICITUD',
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        },
        {
            dataField: 'change',
            text: 'DETALLES',
            sort: false,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }, {
            dataField: 'download',
            text: 'DESCARGAR',
            sort: false,
            sortCaret: (order, column) => {
                if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
                else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
                else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
                return null;
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: amountItemsIntoList, // replace later with size(customers),
        custom: true,
    }

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        columns={columns}
                                        data={reportsData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={columns}
                                                data={reportsData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <Row className="mb-2 d-flex justify-content-between">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive" >

                                                                    <BootstrapTable
                                                                        id="table-responsive"
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/*

                                                        <div className="d-flex justify-content-end" >
                                                            < ReactHtmlTableToExcel
                                                                id="buttonToExportExcel"
                                                                className="btn btn-success"
                                                                table="table-responsive"
                                                                filename="reportsDetails"
                                                                sheet="Page 1"
                                                                buttonText="Export to Excel"
                                                            />
                                                        </div>
                                                         */}

                                                    </React.Fragment>
                                                )
                                                }
                                            </ToolkitProvider>
                                        )
                                        }</PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}

export default ConsultRequestsChannel