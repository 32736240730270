import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import {
    BsFillPersonFill,
    BsFillCursorFill,
    BsCalendarEvent,
    BsCalendarMinus,
    BsCalendarRange,
    BsCalendarWeek,
    BsCalendarCheck,
    BsCalendar4Event,
    BsApp,
    BsBarChartSteps,
    BsPersonCircle,
    BsNewspaper,
    BsPatchCheck
} from "react-icons/bs";
import { changeStatus, updateCertificationDataJson, updateDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from 'react-bootstrap';

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';


/* This array represent different provider */
const providers = [
    { id: 1, name: "SIGLO 21" },
    { id: 2, name: "TECNOMEGA" },
    { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
    { id: 1, name: "TRADICIONAL" },
    { id: 2, name: "CONNECT" },
]

const ApprovalRequestsDetails = ({ match, history }) => {

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)

    // this variable will be used to set the channel selected
    const [request, setRequest] = useState(null)

    // this variable will control the if the admin can change the channel or no
    const [disabled, setDisabled] = useState(true)

    // this will be used to control de component modal  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /***************************************************************/
    /***************************************************************/
    /**  declare state variables to approve or reject a request  ***/
    /***************************************************************/
    /***************************************************************/

    // this variable represent the comment if the request is reject
    const [observaciones, setObservaciones] = useState('')

    // this variable represent the status of the request
    const [statusRequest, setStatusRequest] = useState(null)


    useEffect(() => {
        // this variable will be used to get the id of the channel
        const { id } = match.params;

        console.log(id)

        // this variable will save the channel selected
        const resultSelected = dataJson[id]

        // set the channel selected
        setRequest(resultSelected)

        // get div by id

    }, [])

    // this function will be used when the request is reject
    const onHandleRejectOrApproveRequest = (status) => {

        const { id } = match.params;
        const observation = observaciones

        dispatch(updateCertificationDataJson(
            id,
            status,
            observation
        ), {
            type: 'UPDATE_DATA_JSON'
        })

        // close the modal
        handleClose()

        // redirect to list requests
        history.push('/approval-request-list')
    }


    return (
        <React.Fragment>
            {request && (

                <div className="page-content">
                    <Container fluid={true}>

                        <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>

                            <h1 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                {request.razon_social}
                            </h1>

                            <Col xl="12" style={{ width: '50%' }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>

                                        <AvForm className="needs-validation" >
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                SOLICITUD
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCursorFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.cod_solicictud}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA DE SOLICITUD
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendarEvent />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.fecha_solicitud}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                TIPO DE SOLICITUD
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsBarChartSteps />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.tipo_solicitud}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                ESTADO DE SOLICITUD
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsPatchCheck />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.estado_solicitud}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                RUC
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsNewspaper />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.RUC}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                MAYORISTA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsPersonCircle />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.mayorista}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* DATOS DEL PROCESO - SECTOR PUBLICO O PRIVADO */}
                            <Col xl="12" style={{ width: '50%' }} id="datos_proceso" >
                                <Card style={{ height: '100%' }}>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                DATOS DEL PROCESO - SECTOR {request.sector}
                                            </h4>
                                        </div>
                                        <AvForm className="needs-validation" >
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                CODIGO PROCESO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsApp />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.cod_proceso}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA LIMITE DE PREGUNTA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendarMinus />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.fecha_lim_preguntas}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA LIMITE DE RESPUESTA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendarRange />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.fecha_lim_respuestas}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA LIMITE DE CALIFICACION
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendarWeek />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.fecha_lim_califica}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row style={{ marginTop: 20 }}>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA INICIO DE PUJA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendarCheck />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.fecha_ini_puja}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                                <Col xl="6">
                                                    <div>
                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                PLAZO DE ENTREGA
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsCalendar4Event />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.plazo_entrega.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* Show table certification */}
                            <Col xl="12" style={{ width: '50%', marginTop: 20 }} id="certificados" >
                                <Card style={{ height: '100%' }}>
                                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {request.certificados.length > 0 ? 'CERTIFICADOS' : ''}
                                            </h4>
                                        </div>
                                        <AvForm className="needs-validation" style={{ margin: 'auto auto' }} >
                                            <table class="table">
                                                <thead>
                                                    <tr style={{ color: 'black', fontWeight: 'bold' }}>
                                                        <th scope="col">#</th>
                                                        <th scope="col">CODIGO CERTIFICADO</th>
                                                        <th scope="col">COGIGO SEGUIMIENTO</th>
                                                        <th scope="col">NOMBRE CERTIFICADO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        request.certificados.length > 0 ?
                                                            request.certificados.map((certification, index) => {
                                                                return (
                                                                    <tr style={{ color: 'black' }}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{certification.cod_cert}</td>
                                                                        <td>{certification.cod_seq}</td>
                                                                        <td>{certification.nombre}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </tbody>
                                            </table>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* Show table suministros or equipos */}
                            <Col xl="12" style={{ width: '50%', marginTop: 20 }} id="suministrosOEquipos">
                                <Card style={{ height: '100%' }}>
                                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="form-label mb-3"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {request.suministros.length > 0 ? 'SUMINISTROS' : 'EQUIPOS'}
                                            </h4>
                                        </div>
                                        <AvForm className="needs-validation"  >
                                            <table class="table">
                                                <thead>
                                                    <tr style={{ color: 'black', fontWeight: 'bold' }}>
                                                        <th scope="col">#</th>
                                                        <th scope="col">NUMERO DE PARTE</th>
                                                        <th scope="col">MODELO</th>
                                                        <th scope="col">DESCRIPCIÓN</th>
                                                        <th scope="col">CANTIDAD</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        request.suministros.length > 0 ?
                                                            request.suministros.map((suministro, index) => {
                                                                return (
                                                                    <tr style={{ color: 'black' }}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{suministro.partno}</td>
                                                                        <td>{suministro.model}</td>
                                                                        <td>{suministro.description}</td>
                                                                        <td>{suministro.cant}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : request.equipos.map((equipo, index) => {
                                                                return (
                                                                    <tr style={{ color: 'black' }}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{equipo.partno}</td>
                                                                        <td>{equipo.cant}</td>
                                                                        <td>{equipo.model}</td>
                                                                        <td>{equipo.description}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="mx-3">
                                <button
                                    className="btn btn-primary-lexmark"
                                    type="button"
                                    onClick={() => onHandleRejectOrApproveRequest('APROBADO')}
                                >APROBAR</button>
                            </div>
                            <div>
                                <button className="btn btn-danger-lexmark" type="button"
                                    onClick={() => handleShow()}
                                >RECHAZAR</button>
                            </div>
                        </div>
                        <div>
                            <M show={show} onHide={handleClose}>
                                <M.Header closeButton>
                                    <M.Title>RECHAZO CANAL</M.Title>
                                </M.Header>
                                <M.Body>
                                    INGRESAR LA RAZÓN DEL RECHAZO
                                    <div className="mt-3">
                                        <textarea className="form-control"
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            placeholder="Deja un comentario aquí" id="floatingTextarea">
                                        </textarea>
                                    </div>
                                </M.Body>
                                <M.Footer>
                                    < button className="btn btn-primary-lexmark" onClick={() => onHandleRejectOrApproveRequest('RECHAZADO')}>
                                        RECHAZAR
                                    </button>
                                </M.Footer>
                            </M>
                        </div>

                    </Container>
                </div>
            )}
        </React.Fragment >
    );

}

export default ApprovalRequestsDetails;
