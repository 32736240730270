import { AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react'
import { BsCursor, BsMinecartLoaded, BsPencilSquare, BsPhone, BsRecord, BsRecycle } from 'react-icons/bs';
import { Row } from 'reactstrap';

// this variable will represent different supplies
const supplies = [
    { id: 1, name: 'DISTRIBUIDOR AUTORIZADO', title: 'DISTRIBUIDOR_AUTORIZADO', icon: <BsMinecartLoaded /> },
    { id: 2, name: 'GARANTÍA TÉCNICA', title: 'GARANTÍA_TÉCNICA', icon: <BsPhone /> },
    { id: 3, name: 'COMUNICADO DE ORIGINALIDAD', title: 'COMUNICADO_DE_ORIGINALIDAD', icon: <BsPencilSquare /> },
    { id: 4, name: 'CERTIFICADO AMBIENTAL', title: 'CERTIFICADO_AMBIENTAL', icon: <BsRecycle /> },
    { id: 5, name: 'CERTIFICADO ISO', title: 'CERTIFICADO_ISO', icon: <BsRecord /> }
]

const Supply = () => {

    const [suppliesSelected, setSuppliesSelected] = useState([
        { id: 1, name: 'DISTRIBUIDOR AUTORIZADO', title: 'DISTRIBUIDOR_AUTORIZADO', icon: <BsMinecartLoaded /> },
        { id: 2, name: 'GARANTÍA TÉCNICA', title: 'GARANTÍA_TÉCNICA', icon: <BsPhone /> },
    ]);

    // this function will be used to add or not supplies into list
    const addSuppliesToList = (selected) => {

        const supply = suppliesSelected.find(item => {
            return item.name === selected.name
        })

        // add to supply into list
        if (!supply) {
            // add supply into list
            setSuppliesSelected((prov) => {
                return [
                    ...prov,
                    selected
                ]
            })
            // now we need to change the color of the div component
            let container = document.getElementById(selected.name);
            container.style.backgroundColor = '#008945'
            container.style.color = 'white'

            let title = document.getElementById(selected.title)
            title.style.color = 'white'


        } else { // delete supply into list

            setSuppliesSelected((prov) => {
                const filter = prov.filter(item => {
                    return item.name !== supply.name
                })
                return filter
            })

            let container = document.getElementById(selected.name);
            container.style.backgroundColor = 'white'
            container.style.color = '#433A40'

            let title = document.getElementById(selected.title)
            title.style.color = '#433A40'
        }

    }

    useEffect(() => {


        let container = document.getElementById("DISTRIBUIDOR AUTORIZADO");
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        let title = document.getElementById("DISTRIBUIDOR_AUTORIZADO")
        title.style.color = 'white'

        container = document.getElementById("GARANTÍA TÉCNICA");
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        title = document.getElementById("GARANTÍA_TÉCNICA")
        title.style.color = 'white'

        container = document.getElementById("CERTIFICADO AMBIENTAL");
        container.style.backgroundColor = '#008945'
        container.style.color = 'white'

        title = document.getElementById("CERTIFICADO_AMBIENTAL")
        title.style.color = 'white'


    }, [])

    const renderedSupplies = Object.values(supplies).map(item => {
        return (
            <div
                key={item.id}
                id={item.name}
                className="form-check" style={{
                    border: '1px solid #CDCDCD',
                    marginTop: 20
                }}
                onClick={() => addSuppliesToList(item)}
            >
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                        <i style={{ fontSize: 25, marginRight: 10 }}>
                            {item.icon}
                        </i>
                        <h6 id={item.title} style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: 0 }}>
                            {item.name}
                        </h6>
                    </div>
                    {/*
                    <div style={{ marginRight: 30, display: 'flex' }}>
                        <input style={{ margin: 'auto' }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                     */}
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    CERTIFICADO
                </h4>
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ paddingRight: 10, paddingLeft: 10 }}>
                    {renderedSupplies}
                </Row>
            </AvForm>
        </>
    )

}

export default Supply;