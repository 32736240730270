import React, { Component, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";
import { createDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import FormData from "form-data";
//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */
const providers = [
  { id: 1, name: "SIGLO 21" },
  { id: 2, name: "TECNOMEGA" },
  { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
  { id: 1, name: "TRADICIONAL" },
  { id: 2, name: "CONNECT" },
];

const typesOpportunity = [
  { id: 1, name: "SUMINISTROS" },
  { id: 2, name: "EQUIPOS" },
  { id: 3, name: "OUTSOURCING" },
];

/**
 * It returns a string with the current date and time in the format YYYY-MM-DDTHH:MM
 * @returns A string in the format of YYYY-MM-DDTHH:MM
 */
const getTodayDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return year + "-" + month + "-" + day + "T" + hour + ":" + minutes;
};

// create a function to return the current date in format unix timestamp
const getCurrentDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  let milliseconds =
    date.getMilliseconds() < 10
      ? "0" + date.getMilliseconds()
      : date.getMilliseconds();
  return year + month + day + hour + minutes + seconds + milliseconds;
};


const today = new Date().toISOString().split("T")[0];

const CreateOpportunity = ({ history }) => {
  const profile = useSelector((state) => state.profileReducer.profile);

  const [entities, setEntities] = useState([]);

  const date = new Date();

  /* The above code is declaring the state variables for the form. */
  const [codeOpportunity, setCodeOpportunity] = useState(
    "OP_" + getCurrentDate()
  );
  const [typeOpportunity, setTypeOpportunity] = useState("SUMINISTROS");
  const [descriptionOpportunity, setDescriptionOpportunity] = useState("");
  const [nameChannel, setNameChannel] = useState(
    profile.razonSocial ? profile.razonSocial : ""
  );
  const [dateSetOpportunity, setDateSetOpportunity] = useState(getTodayDate());
  const [dateModifyOpportunity, setDateModifyOpportunity] = useState(
    getTodayDate()
  );

  const [nameEntity, setNameEntity] = useState();
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactLandLine, setContactLandLine] = useState("");
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [mayorista, setMayorista] = useState(providers[0].name);
  const [fechaCierre, setFechaCierre] = useState(null);
  const [presupuestoImpresion, setPresupuestoImpresion] = useState(0);

  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.DataJsonConsume.data);

  // this will be used to control de component modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/

  // this represent the data company
  const [razonSocial, setRazonSocial] = useState("");
  const [ruc, setRuc] = useState("");
  const [direccion, setDireccion] = useState("");

  // this represent the main contact
  const [nombreContacto, setNombreContacto] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [cargo, setCargo] = useState("");

  // this represent the legal represent
  const [nombreRepresentante, setNombreRepresentante] = useState("");
  const [celularRepresentante, setCelularRepresentante] = useState("");
  const [emailRepresentante, setEmailRepresentante] = useState("");
  const compararPorEntidad = (a, b) => {
    // Utiliza toUpperCase() para hacer la comparación insensible a mayúsculas y minúsculas
    const entidadA = a.entidad.toUpperCase();
    const entidadB = b.entidad.toUpperCase();
  
    if (entidadA < entidadB) {
      return -1;
    }
    if (entidadA > entidadB) {
      return 1;
    }
    return 0; // Son iguales
  }
  // this function will be process the result of the method getListEntity
  const handleGetListEntityDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      products.sort(compararPorEntidad)
      setEntities([{ entidad: "" }, ...products]);
      setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getEntities = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/entity/active",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEntities();
    // const sortEntities = entities.sort((a, b) => {
    //     return a.entidad < b.entidad ? -1 : a.entidad > b.entidad ? 1 : 0;
    // })
    //setEntities([{ "entidad": "" }, ...sortEntities])
  }, []);

  const handleRedirectToListWhenTheChannelIsCreated = () => {
    history.push(`/channel-list`);
  };
  // this represetnt the second contact
  const [nombreContacto2, setNombreContacto2] = useState("");
  const [celular2, setCelular2] = useState("");
  const [email2, setEmail2] = useState("");

  // this represent the type of channel
  const [tipoCanal, setTipoCanal] = useState("TRADICIONAL");

  // this represent the documents
  const [fileAdded, setFileAdded] = useState(null);
  const [fileAddedTwo, setFileAddedTwo] = useState(null);
  const [fileAddedThree, setFileAddedThree] = useState(null);
  const [sellerName, setSellerName] = useState(null);
  const [sellerRole, setSellerRole] = useState(null);
  const [rucBase64, setRucBase64] = useState("");

  // this represent the provider
  const [proveedor, setProveedor] = useState("SIGLO 21");

  // this represent the observaciones
  const [observaciones, setObservaciones] = useState("");

  /* Create a function to validate the number phone  */
  const validatePhone = (phone) => {
    const regex = /^[0-9]{0}$/;
    return regex.test(phone);
  };

  /* Create a function to validate the email  */
  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  /* Create a function to validate each input is not empty */
  const validateInput = (input) => {
    return input !== "";
  };

  /* Create a function to validate each input the form */
  const validateForm = () => {
    return (
      validateInput(razonSocial) &&
      validateInput(ruc) &&
      validateInput(direccion) &&
      validateInput(nombreContacto) &&
      validateInput(celular) &&
      validateEmail(email) &&
      validateInput(cargo) &&
      validateInput(nombreRepresentante) &&
      validateInput(celularRepresentante) &&
      validateEmail(emailRepresentante) &&
      validateInput(tipoCanal) &&
      //&& validateInput(fileAdded)
      //&& validateInput(rucDocumento)
      validateInput(proveedor)
    );
  };

  useEffect(() => {
    //console.log(fileAdded)
  }, [fileAdded]);

  const handleCreateChannelDataResponse = (response) => {
    if (response.status) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      if (fileAdded) {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );

        let file = fileAdded;
        let formData = new FormData();
        formData.append("file", file, file.name);
        if (fileAddedTwo) {
          formData.append("file", fileAddedTwo, fileAddedTwo.name);
        }
        if (fileAddedThree) {
          formData.append("file", fileAddedThree, fileAddedThree.name);
        }

        request(
          {
            url: `/auth/oportunidad/file/${response.codigo}`,
            method: "POST",
            headers: myHeaders,
            body: formData,
          },
          () => history.push(`/opportunity-created`)
        );
      } else {
        history.push(`/opportunity-created`);
      }
    }
  };

  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      console.log(typeOpportunity);
      request(
        {
          url: `/auth/oportunidad/register`,
          method: "POST",
          headers: myHeaders,
          body: {
            descripcion: descriptionOpportunity,
            tipo: typeOpportunity,
            canal: {
              ruc: profile?.ruc,
              razonSocial: profile?.razonSocial
                ? profile?.razonSocial
                : "GaslyGo",
            },
            informacionEntidad: {
              entidad: nameEntity,
              nombreContacto: contactName,
              email: contactEmail,
              telefono: contactPhone,
              telefonoFijo: contactLandLine,
            },
            vendedor: {
              nombre: sellerName,
              cargo: sellerRole,
            },
            fechaCierre: `${fechaCierre}T00:00:00Z`,
            mayorista,
            presupuestoTotalEstimadoEntidad: parseFloat(
              estimatedBudget ? estimatedBudget.replace(".", "") : 0
            ),
            presupuestoImpresion: parseFloat(
              presupuestoImpresion ? presupuestoImpresion.replace(".", "") : 0
            ),
          },
        },
        handleCreateChannelDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(error, values) =>
                      handleSubmit(error, values)
                    }
                  >
                    <h4 className="card-title mb-3">REGISTAR OPORTUNIDAD</h4>
                    <Row>
                      {/**
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom01">Codigo Oportunidad</Label>

                                                    <Input
                                                        name="codigo_oportunidad"
                                                        id="codigo_oportunidad"
                                                        type="text"
                                                        value={codeOpportunity}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                             */}
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom02"
                          >
                            Tipo Oportunidad
                          </Label>

                          <select
                            className="form-control"
                            value={typeOpportunity}
                            onChange={(e) => {
                              setTypeOpportunity(e.target.value);
                            }}
                          >
                            {Object.values(typesOpportunity).map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom04"
                          >
                            Canal
                          </Label>
                          <AvField
                            name="canal"
                            type="text"
                            errorMessage="Ingrese el nombre del canal"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={profile?.razonSocial}
                            disabled
                          />
                        </div>
                      </Col>
                      {/** 
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom04">Fecha Declaración Oportunidad</Label>
                                                    
                                                    <Input
                                                        name="fecha_declaracion_oportunidad"
                                                        id="fecha_declaracion_oportunidad"
                                                        type="datetime-local"
                                                        value={dateSetOpportunity + ':00'}
                                                        defaultValue={dateSetOpportunity + ':00'}
                                                        min={dateSetOpportunity + ':00'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                               
                                            <Col md="6">
                                                <div className="mb-3 d-flex flex-column">
                                                    <Label className="form-label" htmlFor="validationCustom04">Ultima Fecha De Modificación</Label>
                                                    <Input
                                                        name="fecha_ultima_modificacion"
                                                        id="fecha_ultima_modificacion"
                                                        type="datetime-local"
                                                        value={dateModifyOpportunity + ':00'}
                                                        defaultValue={dateModifyOpportunity + ':00'}
                                                        min={dateModifyOpportunity + ':00'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                             */}
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom05"
                          >
                            Entidad
                          </Label>
                          {/* 
                                                    <AvField
                                                        name="entidad"
                                                        type="text"
                                                        errorMessage="Ingrese la entidad"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom05"
                                                        value={nameEntity}
                                                        onChange={(e) => setNameEntity(e.target.value)}
                                                    />
                                                    */}
                          {/*
                                                    <select className="form-control" value={nameEntity} onChange={
                                                        (e) => {
                                                            console.log(e.target.value)
                                                            setNameEntity(e.target.value)
                                                        }
                                                    } >
                                                        {Object.values(entities).map((item, index) => (
                                                            <option key={index} value={item.entidad?.toUpperCase()}>{item.entidad?.toUpperCase()}</option>
                                                        ))}
                                                    </select>
                                                     */}
                          <AvField
                            name="entidad"
                            type="select"
                            errorMessage="Ingrese la entidad"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={nameEntity}
                            onChange={(e) => setNameEntity(e.target.value)}
                          >
                            {entities.map((item, index) => (
                              <option
                                key={index}
                                value={item.entidad?.toUpperCase()}
                              >
                                {item.entidad?.toUpperCase()}
                              </option>
                            ))}
                          </AvField>
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom06"
                          >
                            Nombre de Contacto Entidad
                          </Label>
                          <AvField
                            name="nombreContacto"
                            type="text"
                            errorMessage="Ingrese el nombre del contacto"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom06"
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom07"
                          >
                            Email de Contacto Entidad
                          </Label>
                          <AvField
                            name="email"
                            type="email"
                            errorMessage="Ingrese el email del contacto"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            id="validationCustom07"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                      
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Cargo del contacto de la entidad
                          </Label>
                          <AvField
                            name="roleSeller"
                            type="text"
                            errorMessage="Ingrese el cargo del contacto de la entidad"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom21"
                            value={sellerRole}
                            onChange={(e) => setSellerRole(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom08"
                          >
                            Teléfono de Contacto Entidad
                          </Label>
                          <AvField
                            name="telefono"
                            type="number"
                            errorMessage="Ingrese el teléfono del contacto"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "El teléfono de contacto debe tener 10 dígitos",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "El teléfono de contacto debe tener 10 dígitos",
                              },
                            }}
                            id="validationCustom08"
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom99"
                          >
                            Teléfono Fijo Entidad
                          </Label>
                          <AvField
                            name="telefono2"
                            type="number"
                            errorMessage="Ingrese el teléfono fijo"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 9,
                                errorMessage:
                                  "El teléfono fijo de contacto debe tener 9 dígitos incluyendo el prefijo de ciudad",
                              },
                              maxLength: {
                                value: 9,
                                errorMessage:
                                  "El teléfono fijo de contacto debe tener 9 dígitos incluyendo el prefijo de ciudad",
                              },
                            }}
                            id="validationCustom99"
                            value={contactLandLine}
                            onChange={(e) => setContactLandLine(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom09"
                          >
                            Presupuesto total estimado entidad
                          </Label>
                          <AvField
                            name="presupuesto_estimado"
                            type="text"
                            errorMessage="Ingrese el presupuesto estimado"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9.]+$",
                                errorMessage:
                                  "Por favor solo ingresar valores numericos",
                              },
                            }}
                            id="validationCustom09"
                            value={estimatedBudget}
                            onChange={(e) => {
                              const allString = e.target.value;
                              const element =
                                e.target.value.length === 1
                                  ? e.target.value
                                  : e.target.value.slice(-1);

                              if (
                                !isNaN(parseFloat(element)) &&
                                isFinite(element)
                              ) {
                                console.log("efsdf");
                                let actualEstimatedBudget =
                                  allString.replaceAll(".", "");
                                console.log(actualEstimatedBudget);
                                let result = "";
                                let counter = 0;
                                for (
                                  let i = actualEstimatedBudget.length - 1;
                                  i > -1;
                                  i--
                                ) {
                                  if (counter === 3) {
                                    result = "." + result;
                                    counter = 0;
                                  }

                                  result = actualEstimatedBudget[i] + result;

                                  counter++;
                                }
                                console.log(result);

                                setEstimatedBudget(result);
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom09"
                          >
                            Presupuesto Proyecto Impresion
                          </Label>
                          <AvField
                            name="presupuestoImpresion"
                            type="text"
                            errorMessage="Ingrese el presupuesto proyecto impresion"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9.]+$",
                                errorMessage:
                                  "Por favor solo ingresar valores numericos",
                              },
                            }}
                            id="validationCustom10"
                            value={presupuestoImpresion}
                            onChange={(e) => {
                              const allString = e.target.value;
                              const element =
                                e.target.value.length === 1
                                  ? e.target.value
                                  : e.target.value.slice(-1);

                              if (
                                !isNaN(parseFloat(element)) &&
                                isFinite(element)
                              ) {
                                let actualEstimatedBudget =
                                  allString.replaceAll(".", "");
                                console.log(actualEstimatedBudget);
                                let result = "";
                                let counter = 0;
                                for (
                                  let i = actualEstimatedBudget.length - 1;
                                  i > -1;
                                  i--
                                ) {
                                  if (counter === 3) {
                                    result = "." + result;
                                    counter = 0;
                                  }

                                  result = actualEstimatedBudget[i] + result;

                                  counter++;
                                }
                                console.log(result);

                                setPresupuestoImpresion(result);
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom20"
                          >
                            Nombre del Vendedor
                          </Label>
                          <AvField
                            name="nombreVendedor"
                            type="text"
                            errorMessage="Ingrese el nombre del vendedor"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom20"
                            value={sellerName}
                            onChange={(e) => setSellerName(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom21"
                          >
                            Seleccione Mayorista
                          </Label>
                          <select
                            className="form-control "
                            value={mayorista}
                            onChange={(e) => {
                              setMayorista(e.target.value);
                            }}
                          >
                            {Object.values(providers).map((provider) => (
                              <option key={provider.id} value={provider.name}>
                                {provider.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom23"
                          >
                            Fecha de cierre
                          </Label>
                          <AvField
                            name="dateEnd"
                            type="date"
                            errorMessage="Ingrese una fecha valida"
                            className="form-control"
                            min={today}
                            validate={{ required: { value: true } }}
                            id="validationCustom23"
                            value={fechaCierre}
                            onChange={(e) => setFechaCierre(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="opportunity-create-file"
                          >
                            Adjuntar archivo #1
                          </Label>
                          <br />
                          <AvField
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                            name="fileAdded"
                            type="file"
                            errorMessage="El documento es invalido"
                            className="form-control"
                            id="opportunity-create-file"
                            onChange={(e) => {
                              //console.log(e.target.files[0])
                              const f = e.target.files[0];
                              const maxFileSize = 5000000; // 5MB in bytes

                              if (f && f.size > maxFileSize) {
                                // Limpiar el campo de archivo seleccionado
                                setMessageStatusIsError(true);
                                setMessageRequest("El archivo pesa mas de 5MB");
                                setFileAdded(null);

                                return;
                              }
                              setMessageStatusIsError(null);
                              setMessageRequest(null);
                              setFileAdded(f);
                              /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                            }}
                          />
                        </div>
                      </Col>

                      {fileAdded && (
                        <Col md="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="opportunity-create-file"
                            >
                              Adjuntar archivo #2
                            </Label>
                            <br />
                            <AvField
                              accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                              name="fileAddedTwo"
                              type="file"
                              errorMessage="El documento es invalido"
                              className="form-control"
                              id="opportunity-create-file-two"
                              onChange={(e) => {
                                //console.log(e.target.files[0])
                                const f = e.target.files[0];
                                const maxFileSize = 5000000; // 5MB in bytes

                                if (f && f.size > maxFileSize) {
                                  // Limpiar el campo de archivo seleccionado
                                  setMessageStatusIsError(true);
                                  setMessageRequest(
                                    "El archivo pesa mas de 5MB"
                                  );
                                  setFileAddedTwo(null);

                                  return;
                                }
                                setMessageStatusIsError(null);
                                setMessageRequest(null);
                                setFileAddedTwo(f);
                                /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      {fileAdded && fileAddedTwo && (
                        <Col md="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="opportunity-create-file"
                            >
                              Adjuntar archivo #3
                            </Label>
                            <br />
                            <AvField
                              accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .ppt, .pptx, .txt"
                              name="fileAddedThree"
                              type="file"
                              errorMessage="El documento es invalido"
                              className="form-control"
                              id="opportunity-create-file-Three"
                              onChange={(e) => {
                                //console.log(e.target.files[0])
                                const f = e.target.files[0];
                                const maxFileSize = 5000000; // 5MB in bytes

                                if (f && f.size > maxFileSize) {
                                  // Limpiar el campo de archivo seleccionado
                                  setMessageStatusIsError(true);
                                  setMessageRequest(
                                    "El archivo pesa mas de 5MB"
                                  );
                                  setFileAddedThree(null);

                                  return;
                                }
                                setMessageStatusIsError(null);
                                setMessageRequest(null);
                                setFileAddedThree(f);
                                /*
                                                            setFileAdded(
                                                                convertToBase64AcuerdoComercial(e.target.files, f)
                                                            )
                                                            */
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="12">
                        <div className="mb-3 d-flex flex-column">
                          <Label
                            className="form-label"
                            htmlFor="validationCustom03"
                          >
                            Descripción del trabajo realizado en la cuenta (
                            Detalle toda la gestión, evite el rechazo de su
                            oportunidad )
                          </Label>
                          <AvField
                            name="description"
                            type="textarea"
                            errorMessage="Ingrese una descripción"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={descriptionOpportunity}
                            onChange={(e) =>
                              setDescriptionOpportunity(e.target.value)
                            }
                            rows="5"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Button
                      className="btn btn-primary-lexmark"
                      type="submit"
                      disabled={loading}
                    >
                      Crear Oportunidad
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente el canal{" "}
                <span style={{ fontWeight: "bold" }}> {razonSocial} </span> con
                el contacto{" "}
                <span style={{ fontWeight: "bold" }}> {nombreContacto} </span>,
                se ha enviado un correo al email de contacto con la información
                de inicio de sesión.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheChannelIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateOpportunity;
