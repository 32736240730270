import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";

import { Carousel } from "react-bootstrap";

import logoSlider from "../../assets/images/carousel-1-lexmark.jpg";

import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

const Dashboard = () => {
  // this function will be used to request the server to login the user
  const { request } = useHttp();

  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    request(
      {
        url: `/noauth/banner/Principal`,
        method: "GET",
        headers: myHeaders,
      },
      handleGetBannersDataResponse
    );
  };

  useEffect(() => {
    getBanners();
  }, []);

  const handleGetBannersDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        console.log(response);
        setBanners(
          response.map((item) => {
            return {
              src: `${process.env.REACT_APP_S3_URL}${item.path}`,
              alt: item.title,
            };
          })
        );

        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div
        id="carouselExampleDark"
        className="page-content carousel carousel-dark slide"
        data-bs-ride="carousel"
        style={{ height: "100%", marginTop: 20 }}
      >
        {/* Carousel content */}
      </div>

      <div style={{ marginBottom: 30 }}>
        <Carousel>
          {banners.map((item, key) => (
            <Carousel.Item interval={5000} key={key}>
              <img className="d-block w-100" src={item.src} alt={item.alt} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
