import React from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { BsBarChartSteps, BsCalendarEvent, BsCursor, BsDashSquare, BsFillCursorFill, BsNewspaper, BsPatchCheck, BsPerson, BsPersonCircle } from 'react-icons/bs';
import { Col, Row } from 'reactstrap';

// this function will be uses to show form about public sector
const PrivateSector = () => {

    return (
        <>
            <div className="d-flex justify-content-between">
                <h4 className="form-label mb-3"
                    style={{ fontWeight: 'bold' }}
                >
                    DATOS DEL PROCESO - SECTOR PRIVADO
                </h4>
            </div>
            <AvForm className="needs-validation" >
                <Row style={{ marginTop: 20 }}>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    NOMBRE EMPRESA
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsPerson />
                                </i>
                                <AvField
                                    name="nombre_empresa"
                                    type="text"
                                    errorMessage="Enter First Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    DIRIGIDA A
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsCursor />
                                </i>
                                <AvField
                                    name="dirigido"
                                    type="text"
                                    errorMessage="Enter First Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>

                    <Col xl="6">
                        <div>
                            <div>
                                <h6 style={{ fontWeight: 'bold' }}>
                                    CARGO
                                </h6>
                            </div>

                            <div className="d-flex align-items-center">
                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                    <BsDashSquare />
                                </i>
                                <AvField
                                    name="cargo"
                                    type="text"
                                    errorMessage="Enter First Name"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="/* Validating the input of the user. */
                                                        /* Validating the input of the user. */
                                                        validationCustom01"
                                />
                            </div>
                        </div>

                    </Col>

                </Row>

            </AvForm>

        </>
    )
}

export default PrivateSector;