import React, { useEffect, useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs';
import { Card, CardBody, Col } from 'reactstrap';

import Item from './Item';
import ListSuppliesJson from './ListSuppliesJson'


const CertificadoASP = ({ title, handleChangeListSuppliesSelected, suministros }) => {

    // counter
    let counter = 0;

    // this variable will be control the amount of component supplies list
    const [listsComponentItem, setListsComponentItem] = useState([]);

    // this variable will be control the id of the component supplies list
    const [amountListItemId, setAmountListItemId] = useState(0)

    // this variable represent the array of the supplies list part number
    const [listCertification, setListCertification] = useState([]);

    const [listSuppliesJson, setSuppliesJson] = useState(ListSuppliesJson);

    useEffect(() => {
        console.log('listCertification', listCertification)
        handleChangeListSuppliesSelected(listCertification)
    }, [listCertification])

    const [isParams, setIsParams] = useState(false);

    useEffect(() => {
        if (suministros.length > 0 && !isParams) {

            setListCertification(suministros)
            console.log('suministros', suministros)

            let counter = 0
            suministros.forEach(x => {

                x.id = counter
                setListsComponentItem((prov) => {
                    return [
                        ...prov,
                        x
                    ]
                })

                counter++

                // increment the id of the component supplies list
                setAmountListItemId(amountListItemId + 1)

            })


            setIsParams(true);
        }
    })

    const addNewComponentItem = () => {

        const id = amountListItemId + "_item_id_" + title;

        setListsComponentItem((provItem) => {
            return [...provItem, { id }]
        })

        setAmountListItemId(prov => {
            return prov + 1;
        })

        console.log("amountListItemId" + amountListItemId)

        counter++;

    }

    // this function will be used to add a new item into list of the certification 
    const addItemIntoListCertification = (element) => {

        console.log("listCertification", listCertification)

        console.log("element", element)

        const newItem = listCertification.find(item => {
            return item.id === element.id
        })
        console.log('newItem', newItem)

        /*
        setSuppliesJson( prov => {
            return [
                ...prov.filter(item => {
                    return 
                }),
            ]
        })*/


        if (!newItem) {
            console.log(2)
            setListCertification((provItems) => {
                return [
                    ...provItems,
                    element
                ]
            })

        } else {
            console.log(3)

            setListCertification((prov) => {
                return prov.map(item => {
                    if (item.id === element.id) {
                        return element
                    } else {
                        return item
                    }
                })
            })
        }

    }

    // this function will be used to remove a item into list of the certification
    const removeItemIntoListCertification = (element) => {

        const deleteItem = listCertification.find(item => {
            return item.id === element
        })

        console.log('deleteItem', deleteItem)
        console.log('listCertification', listCertification)
        console.log('element', element)

        if (deleteItem) {
            setListCertification((prov) => {
                return prov.filter(item => {
                    return item.id !== element
                })
            })
        }
    }

    // rendered the list of the item list part number
    const renderedItemList = Object.values(listsComponentItem).map((item, index) => {
        console.log(item)
        return (
            <div key={index}>
                < Item
                    addItemIntoListCertification={addItemIntoListCertification}
                    removeItemIntoListCertification={removeItemIntoListCertification}
                    id={item.id}
                    partno={item.partno}
                    modelo={item.model}
                    descripcion={item.descripcion}
                    cantidad={item.cantidad}
                />
            </div>
        )
    })

    const renderedItemListAdminView = Object.values(suministros).map((item, index) => {
        <div key={index}>
            < Item
                item={item}
                addItemIntoListCertification={addItemIntoListCertification}
                removeItemIntoListCertification={removeItemIntoListCertification}
                id={amountListItemId}
            />
        </div>
    })

    return (
        <>
            <Col xl="12" style={{ width: '100%', marginTop: 20 }}  >
                <Card style={{ height: '100%' }}>
                    <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="d-flex justify-content-between" style={{ marginTop: 20 }}>
                            <h4 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                {title}
                            </h4>
                            <div>
                                <button
                                    className="btn btn-primary-lexmark"
                                    onClick={addNewComponentItem}
                                >
                                    <BsPlusCircleFill style={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>
                        <div id="list-supplies">
                            {amountListItemId === 0 ?
                                (
                                    <h6 style={{
                                        textAlign: 'center'

                                    }}>INGRESAR LISTA DE {title} </h6>
                                ) :
                                renderedItemList
                            }

                        </div>
                    </CardBody>
                </Card>
            </Col>

        </>
    )
}

export default CertificadoASP;